import { Box, Grid, Typography } from '@mui/material';

import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';
import CustomStepper from 'components/CustomStepper/CustomStepper';

import GraphSpaceDetails from './components/GraphSpaceDetails';
import GraphSpaceTemplate from './components/GraphSpaceTemplate';

import { Container } from '../ui';
import { useGraphSpaceSetUp } from './hooks/useGraphSpaceSetUp';

export default function GraphSpaceSetUp() {
  const { steps, addGraphSpaceSetup, graphSpace } = useGraphSpaceSetUp();

  const stepContent = [
    <GraphSpaceDetails key="graph-space-details" />,
    <GraphSpaceTemplate key="graph-space-template" />,
  ];

  const addNewGraphSpaceSetup = () => {
    console.log('before', graphSpace);
    addGraphSpaceSetup();
    console.log('after', graphSpace);
  };

  return (
    <Container
      sx={{
        gap: 2,
        display: 'grid',
        gridTemplateRows: '84px auto',
      }}
    >
      <Grid container sx={{ backgroundColor: 'neutral.white', padding: '24px 32px' }}>
        <Grid item xs={12}>
          <Grid sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography variant="h2" sx={{ color: 'neutral.main', marginRight: 2 }}>
              Graph Space Set-Up
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ overflow: 'auto' }}>
        <CustomStepper
          type="setup"
          steps={steps}
          stepContent={stepContent}
          onFinalRoute={addNewGraphSpaceSetup}
          finalRoute={PRIVATE_ABS_ROUTE_PATHS.salesTeamSpace}
        />
      </Box>
    </Container>
  );
}
