import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

const NoteFooter = () => {
  return (
    <Stack sx={{ height: '30px' }} direction="row" alignItems="center" justifyContent="center" gap={0.5} py={0.5}>
      <Stack direction="row" alignItems="center" gap={0.5} sx={{ cursor: 'pointer', mx: 1 }}>
        <EditRoundedIcon sx={{ fontSize: '16px', color: 'primary.main' }} />
        <Typography variant="p14" color="primary.main">
          Edit
        </Typography>
      </Stack>

      <Divider orientation="vertical" />

      <Stack direction="row" alignItems="center" gap={0.5} sx={{ cursor: 'pointer', mx: 1 }}>
        <DeleteOutlineRoundedIcon sx={{ fontSize: '16px', color: 'primary.main' }} />
        <Typography variant="p12" color="primary.main">
          Delete
        </Typography>
      </Stack>
    </Stack>
  );
};

export default NoteFooter;
