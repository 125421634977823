import { GridItem } from 'components/ui';
import { OperatorDropdown } from './inputFields/AnomalyOperatorDropdown';
import { DeleteComponentButton } from '../../DataValidation/components/shared/DeleteComponentButton';
import { FC, useEffect, useMemo } from 'react';
import { RuleWhereArray, RootCondition } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { Option } from 'store/ruleForm/types';
import { PrimitiveValueField } from './inputFields/PrimitiveBooleanValueField';
import { ValidationTypeDropdown } from './inputFields/ValidationTypeDropdown';
import { FieldSelectorDropdown } from './inputFields/FieldSelectorDropdown';
import { ANOMALY_VALIDATION_ENUM } from './shared/Constants';
import { Box } from '@mui/material';
import { RegexArraySelector } from './inputFields/RegexArraySelector';
import { BOOLEAN_OPTIONS } from './shared/Constants';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { ServiceValidatorSelector, ServiceValidatorSelectorOption } from './inputFields/ServiceValidatorSelector';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { useCallback } from 'react';
import { getAnomalyEmptyRootConditional } from 'pages/RuleForm/utils';
import theme from 'core/theme';
import { VerticalDivider } from 'components/VerticalDivider';
import { FormErrorMessage } from 'pages/RuleLibrary/components/ui/FormErrorMessage';
import _ from 'lodash';

export interface ConditionalRecordFormProps {
  index: number;
  field: string;
  conditionComponent: RootCondition;
  conditionPath: string;
  where: RuleWhereArray;
  setWhere: (where: { value: RuleWhereArray }) => void;
  fieldOptions: Option[];
  tableName: string;
}

const AnomalyConditionalRecordForm: FC<ConditionalRecordFormProps> = (props) => {
  const { index, conditionComponent, where, setWhere, conditionPath, fieldOptions } = props;
  const { ruleValidationPatterns, setFieldValue, formError } = useRuleForm();
  const operator = conditionComponent?.operator || null;

  const selectedFieldOption = useMemo(
    () => fieldOptions.find((item) => item.value === (conditionComponent?.field as any).fieldName),
    [conditionComponent?.field, fieldOptions],
  );

  const fieldType = useMemo(() => {
    return selectedFieldOption?.type;
  }, [selectedFieldOption?.type]);

  const validationType = (where[index] as RootCondition).field.type;

  const validationPatternsFiltered = useMemo(() => {
    const allowedDatatypes = ['*'];
    const allowedValidationType = [validationType.toString()];
    if (fieldType) {
      allowedDatatypes.push(fieldType.toLocaleLowerCase());
      return ruleValidationPatterns?.filter(
        (pattern) =>
          allowedDatatypes.includes(pattern.dataType.toLowerCase()) && allowedValidationType.includes(pattern.type),
      );
    }
    return ruleValidationPatterns;
  }, [fieldType, ruleValidationPatterns, validationType]);

  const ServiceValidatorOptions = useMemo(() => {
    const options: ServiceValidatorSelectorOption[] = [];
    validationPatternsFiltered?.forEach((pattern) => {
      const schema = pattern.returnValueSchema || {};
      Object.keys(schema).forEach((key) => {
        options.push({
          label: `${schema[key].label}`,
          value: `${pattern.validationPatternId}#${schema[key].keyName}`,
          patternId: pattern.validationPatternId,
          fieldName: schema[key].keyName,
        });
      });
    });
    return options;
  }, [validationPatternsFiltered]);

  const ServiceValidationPropertyOptions = useMemo(() => {
    if (conditionComponent.field.type === RuleComponentType.SERVICE) {
      const patternId = (where[index] as RootCondition).field.validation;
      const property = (where[index] as RootCondition).field.property;
      const pattern = ruleValidationPatterns?.find((pattern) => pattern.validationPatternId === patternId);
      const propertySelected = pattern?.returnValueSchema[property];
      const schemaValueOptions = propertySelected?.options || [];
      if (schemaValueOptions.length <= 0) {
        return BOOLEAN_OPTIONS;
      }
      return schemaValueOptions;
    } else {
      return BOOLEAN_OPTIONS;
    }
  }, [conditionComponent.field.type, index, ruleValidationPatterns, where]);

  useEffect(() => {
    const value = conditionComponent.value.value;
    const valid = ServiceValidationPropertyOptions.find((option) => option.value === value);
    if (!valid) {
      setFieldValue({ path: `${conditionPath}.value`, value: '' });
    }
  }, [ServiceValidationPropertyOptions, conditionComponent.value.value, conditionPath, setFieldValue]);

  const isRegexValidation = (where[index] as RootCondition).field.type === ANOMALY_VALIDATION_ENUM.REGEX.toString();

  const handleFieldValueChange = useCallback(
    (targetPath: string, value: string) => {
      const currentValue = (where[index] as RootCondition).field.fieldName;
      if (index > 0) {
        return setFieldValue({ path: targetPath, value: value });
      }
      if (index == 0 && currentValue != value) {
        const condition = getAnomalyEmptyRootConditional();
        condition.field.fieldName = value;
        setWhere({ value: [condition] });
      }
    },
    [index, setFieldValue, setWhere, where],
  );

  const conditionError = useMemo(
    () => formError && _.get(formError, `${conditionPath}.condition.error`),
    [formError, conditionPath],
  );

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      position={'relative'}
      gap={0.5}
      sx={{
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
        backgroundColor: theme.palette.lightBg.main,
        p: 1,
      }}
    >
      <FormErrorMessage errorMessage={conditionError} />
      <Box display={'flex'} gap={1} sx={{ my: 1, px: 1 }}>
        <GridItem flex={1}>
          <FieldSelectorDropdown
            value={(where[index] as any).field.fieldName}
            id={`FieldSelectorDropdown-${index}`}
            fieldOptions={fieldOptions}
            targetPath={`${conditionPath}.field.fieldName`}
            onChange={(value) => handleFieldValueChange(`${conditionPath}.field.fieldName`, value)}
          />
        </GridItem>
        <GridItem flex={1}>
          <ValidationTypeDropdown
            currentValue={(where[index] as RootCondition).field.type}
            targetPath={`${conditionPath}.field.type`}
            onChange={(value) => {
              setFieldValue({ path: `${conditionPath}.field.type`, value: value });
              setFieldValue({ path: `${conditionPath}.field.validationPatterns`, value: undefined });
              setFieldValue({ path: `${conditionPath}.field.validation`, value: undefined });
            }}
          />
        </GridItem>
      </Box>

      <Box display={'flex'} gap={1} sx={{ my: 1, px: 1 }}>
        {!isRegexValidation && (
          <GridItem flex={1}>
            <ServiceValidatorSelector
              currentValue={`${(where[index] as any).field.validation || ''}#${
                (where[index] as any).field.property || ''
              }`}
              targetPath={`${conditionPath}.field.validation`}
              id={`${conditionPath}.field.validation`}
              fieldOptions={ServiceValidatorOptions || []}
              onChange={(value) => {
                const valueComponents = value.split('#');
                setFieldValue({ path: `${conditionPath}.field.validation`, value: valueComponents[0] });
                setFieldValue({ path: `${conditionPath}.field.property`, value: valueComponents[1] });
              }}
            />
          </GridItem>
        )}
        {isRegexValidation && (
          <GridItem flex={2}>
            <RegexArraySelector
              currentValue={(where[index] as any).field.validationPatterns || []}
              targetPath={`${conditionPath}.field.validationPatterns`}
              patterns={validationPatternsFiltered || []}
            />
          </GridItem>
        )}
      </Box>

      <VerticalDivider height="20px" sx={{ marginLeft: 2 }} />

      <GridItem flex={0.5} sx={{ my: 1, px: 1 }} xs={6}>
        <OperatorDropdown operator={operator} targetPath={`${conditionPath}.operator`} />
      </GridItem>

      <VerticalDivider height="20px" sx={{ marginLeft: 2 }} />

      <GridItem flex={1} alignItems={'center'} xs={6}>
        <GridItem width={'100%'} sx={{ my: 1, px: 1 }}>
          <PrimitiveValueField
            targetPath={`${conditionPath}.value`}
            value={(where[index] as RootCondition).value.value}
            options={ServiceValidationPropertyOptions}
          />
        </GridItem>
      </GridItem>

      {index !== 0 && (
        <Box sx={{ position: 'absolute', top: '-12px', right: '-10px' }}>
          <DeleteComponentButton index={index} where={where} setWhere={setWhere} />
        </Box>
      )}
    </Box>
  );
};

export { AnomalyConditionalRecordForm };
