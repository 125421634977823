import { FC, useState, ReactNode } from 'react';
import { styled, Box, Typography, Grid, Button, BoxProps } from '@mui/material';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevronUp.svg';

export const Container = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

export const VerticalDivider = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  width: 1,
  height: 24,
  position: 'relative',
  '&::before': {
    content: '" "',
    height: 24,
    position: 'absolute',
    top: 8,
    width: 1,
    backgroundColor: theme.palette.neutral.n200,
  },
}));

export const CardSection = styled(Grid)(() => ({
  width: '100%',
  background: '#FFFFFF',
  borderRadius: '4px',
  padding: '16px',
}));

export const AccountsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.darkBg.main,
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export const NameTypography = styled(Typography)(({ theme }) => ({
  color: '#1E232C',
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '14px',
  marginRight: '8px',
}));

export const TimeTypography = styled(Typography)(({ theme }) => ({
  color: '#9DA1B5',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '12px',
}));

export const StatusTypography = styled(Typography, { shouldForwardProp: (prop) => prop !== 'status' })<{
  status?: boolean;
}>(({ theme, status = false }) => ({
  alignItems: 'center',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '12px',
  paddingTop: '4px',
  paddingBottom: '4px',
  paddingLeft: '8px',
  paddingRight: '8px',
  borderRadius: '4px',
  marginRight: '8px',
  backgroundColor: status ? '#0052D2  ' : '#F4F9FF',
  color: status ? '#FFFFFF' : '#1E232C',
}));

export const MessageTypography = styled(Typography)(({ theme }) => ({
  color: '#1E232C',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '14px',
}));

export const ReplyTypography = styled(Typography)(({ theme }) => ({
  color: '#1554FF',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '12px',
}));

export const TabButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  backgroundColor: '#F6F8FB',
  borderRadius: '4px',
  // padding: '16px',
  color: '#1554FF',
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '12px',
  // ':hover': {
  //   backgroundColor: '#F6F8FB',
  //   color: '#1E2320',
  // },
}));

interface Props extends BoxProps {
  children?: ReactNode;
  defaultOpen?: boolean;
  title: string;
}

export const InsightButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignContent: 'center',
  backgroundColor: '#F6F8FB',
  borderRadius: '4px',
  padding: '16px',

  color: '#1E232C',
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '12px',
  ':hover': {
    backgroundColor: '#F6F8FB',
    color: '#1E2320',
  },
}));

export const DateDropdownButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignContent: 'center',
  backgroundColor: '#F6F8FB',
  color: '#1E232C',
  padding: '4px',
  fontFamily: 'Inter',
  fontSize: '10px',
  ':hover': {
    backgroundColor: '#F6F8FB',
    color: '#1E2320',
  },
}));

export const FrontInsightBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'color' })<{
  color?: string;
}>(({ theme, color = '#29B6F6' }) => ({
  background: color,
  borderRadius: '8px',
  width: '10px',
  height: '10px',
}));

export const ExtButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignContent: 'center',
  border: '1px solid #E1E5ED',
  borderRadius: '4px',
  padding: '16px',

  color: '#1E232C',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '14px',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    color: '#1E232C',
  },
}));

export const SvgButton = styled(Grid, { shouldForwardProp: (prop) => prop !== 'mainColor' && prop !== 'hoverColor' })<{
  mainColor?: string;
  hoverColor?: string;
}>(({ theme, mainColor, hoverColor }) => ({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  stroke: mainColor && mainColor,
  justifyContent: 'center',
  cursor: 'pointer',
  '&:hover': {
    transition: 'all .5',
    transform: 'scale(1.1)',
    stroke: hoverColor && hoverColor,
  },
}));

export const CommandButton = styled(SvgButton)(({ theme }) => ({
  width: '24px',
  height: '24px',
  background: '#FFFFFF',
  boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
  borderRadius: '16px',
}));

export const SearchTag = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '12px',
  color: '#9DA1B5',
}));

export const SearchContent = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Inter',
}));
export const SearchMatchedContent = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Inter',
  color: '#1554FF',
}));

export const SearchRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'flex-end',
}));

export const SearchContainer = styled(Box)(({ theme }) => ({
  borderTop: '1px solid #E1E5ED',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  paddingTop: '16px',
  paddingBottom: '16px',
}));
