import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import {
  setError,
  setSuccess,
  setLoading,
  getDataByLabel,
  getDataByVertexId,
  getMigrationStatus,
  getDataByVertexIdArray,
} from './actions';
import { GraphDataState } from './types';
import _ from 'lodash';
import { resetApp } from 'store/actions';

export const initialState: GraphDataState = {
  loading: true,
  error: false,
  success: false,
  dataByLabel: { paths: [], accounts: [] },
  dataByVertexIdArray: { paths: [], accounts: [] },
  dataByVertexId: {},
  viewByLabel: [],
};

const graphDataReducer = createSlice({
  name: 'graphData',
  initialState: _.cloneDeep(initialState),
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<GraphDataState>): void => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(setLoading, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(getDataByLabel.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });

    builder.addCase(getDataByLabel.rejected, (state) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    });

    builder.addCase(getDataByLabel.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.dataByLabel = payload;
    });

    builder.addCase(getDataByVertexIdArray.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });

    builder.addCase(getDataByVertexIdArray.rejected, (state) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    });

    builder.addCase(getDataByVertexIdArray.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.dataByVertexIdArray = payload;
    });

    builder.addCase(getMigrationStatus.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });

    builder.addCase(getMigrationStatus.rejected, (state) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    });

    builder.addCase(getMigrationStatus.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.migrationStatus = payload;
    });

    builder.addCase(getDataByVertexId.pending, (state, { payload, meta }) => {
      const args = meta.arg;
      if (!state.dataByVertexId[args.migrationId]) {
        state.dataByVertexId[args.migrationId] = { data: {}, error: false, loading: false, success: false };
      }
      state.dataByVertexId[args.migrationId].loading = true;
      state.dataByVertexId[args.migrationId].error = false;
      state.dataByVertexId[args.migrationId].success = false;
    });

    builder.addCase(getDataByVertexId.rejected, (state, { meta }) => {
      const args = meta.arg;
      if (state.dataByVertexId[args.migrationId]) {
        state.dataByVertexId[args.migrationId].loading = false;
        state.dataByVertexId[args.migrationId].error = true;
        state.dataByVertexId[args.migrationId].success = false;
      }
    });

    builder.addCase(getDataByVertexId.fulfilled, (state, { payload, meta }) => {
      const args = meta.arg;
      if (state.dataByVertexId[args.migrationId]) {
        state.dataByVertexId[args.migrationId].loading = false;
        state.dataByVertexId[args.migrationId].error = false;
        state.dataByVertexId[args.migrationId].success = true;
        state.dataByVertexId[args.migrationId].data[args.vertexId] = payload.vertex;
      }
    });
    builder.addCase(resetApp, (state) => {
      const initialStateTmp = _.cloneDeep(initialState);
      Object.keys(state).forEach((key) => {
        // @ts-ignore
        state[key] = initialStateTmp[key];
      });
    });
  },
});

export default graphDataReducer.reducer;
