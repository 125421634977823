import { ReactComponent as SearchIcon } from 'assets/icons/searchGray.svg';
import { OptionValue } from 'core/types';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRuleLibrary } from 'store/ruleLibrary/hook';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { Box } from '@mui/material';
import { onChangeStringHandler } from '../../../utils/dom';

interface SearchRuleDropdownProps {
  focusOnRuleId?: string;
}

const SearchRuleDropDown: FC<SearchRuleDropdownProps> = ({ focusOnRuleId }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [focusDone, setFocusDone] = useState<boolean>(false);
  const {
    selectedSubPage,
    subPages: {
      library: {
        data: { filteredRules, renderedRules },
      },
      template: {
        data: { filteredTemplates },
      },
      temporalTable: {
        data: { tables: filteredTempTables },
      },
    },
    setRenderedRule,
    setRenderedTemplates,
    setRenderedTempTables,
  } = useRuleLibrary();

  const prevSelectedSubPage = useRef<string | undefined>(selectedSubPage);

  useEffect(() => {
    if (prevSelectedSubPage.current !== selectedSubPage) {
      setSearchTerm('');
      prevSelectedSubPage.current = selectedSubPage;
    }
  }, [selectedSubPage]);

  useEffect(() => {
    if (focusOnRuleId && !focusDone) {
      const rule = renderedRules.find((rule) => rule.ruleId === focusOnRuleId);
      if (rule) {
        setTimeout(() => {
          setRenderedRule([rule]);
          setSearchTerm(rule.name || '');
        }, 0);
        setFocusDone(true);
      }
    }
  }, [focusDone, focusOnRuleId, renderedRules, setRenderedRule]);

  useEffect(() => {
    setRenderedRule(filteredRules);
  }, [filteredRules, setRenderedRule]);

  useEffect(() => {
    setRenderedTemplates(filteredTemplates);
  }, [filteredTemplates, setRenderedTemplates]);

  useEffect(() => {
    setRenderedTempTables(filteredTempTables);
  }, [filteredTempTables, setRenderedTempTables]);

  const options: OptionValue<string>[] = useMemo(() => {
    let options = [];
    if (selectedSubPage === 'library') {
      options = filteredRules.map((rule) => ({ label: rule.name, value: rule.ruleId }));
    } else if (selectedSubPage === 'template') {
      options = filteredTemplates.map((template) => ({ label: template.name, value: template.ruleTemplateId }));
    } else {
      options = filteredTempTables.map((table) => ({ label: table.name, value: table.ruleTempTableId }));
    }
    return options;
  }, [filteredRules, filteredTempTables, filteredTemplates, selectedSubPage]);

  const barPlaceHolder = useMemo(() => {
    if (selectedSubPage === 'library') {
      return 'Find a Rule';
    } else if (selectedSubPage === 'template') {
      return 'Find a Rule Template';
    } else {
      return 'Find a Temporal Table';
    }
  }, [selectedSubPage]);

  const handleOnChange = useCallback(
    (value: string) => {
      setSearchTerm(value);
      if (selectedSubPage === 'library') {
        const rulesTmp = filteredRules.filter((rule) => rule.name?.match(new RegExp(value, 'i')));
        if (rulesTmp?.length > 0) {
          setRenderedRule(rulesTmp);
        } else {
          setRenderedRule(filteredRules);
        }
      } else if (selectedSubPage === 'template') {
        const templateTmp = filteredTemplates.filter((template) => template.name?.match(new RegExp(value, 'i')));
        if (templateTmp?.length > 0) {
          setRenderedTemplates(templateTmp);
        } else {
          setRenderedTemplates(filteredTemplates);
        }
      } else {
        const tableTmp = filteredTempTables.filter((table) => table.name?.match(new RegExp(value, 'i')));
        if (tableTmp?.length > 0) {
          setRenderedTempTables(tableTmp);
        } else {
          setRenderedTempTables(filteredTempTables);
        }
      }
    },
    [
      selectedSubPage,
      filteredRules,
      filteredTemplates,
      filteredTempTables,
      setRenderedTemplates,
      setRenderedRule,
      setRenderedTempTables,
    ],
  );

  const handleOnSelect = useCallback(
    (id: string) => {
      if (selectedSubPage === 'library') {
        const rulesTmp = filteredRules.find((rule) => rule.ruleId === id);
        if (rulesTmp) {
          setRenderedRule([rulesTmp]);
          setSearchTerm(rulesTmp.name || '');
        } else {
          setRenderedRule(filteredRules);
        }
      } else if (selectedSubPage === 'template') {
        const templateTmp = filteredTemplates.find((template) => template.ruleTemplateId === id);
        if (templateTmp) {
          setRenderedTemplates([templateTmp]);
          setSearchTerm(templateTmp.name || '');
        } else {
          setRenderedTemplates(filteredTemplates);
        }
      } else {
        const tableTmp = filteredTempTables.find((table) => table.ruleTempTableId === id);
        if (tableTmp) {
          setRenderedTempTables([tableTmp]);
          setSearchTerm(tableTmp.name || '');
        } else {
          setRenderedTempTables(filteredTempTables);
        }
      }
    },
    [
      selectedSubPage,
      filteredRules,
      setRenderedRule,
      filteredTemplates,
      setRenderedTemplates,
      filteredTempTables,
      setRenderedTempTables,
    ],
  );

  return (
    <>
      <ControlledCustomDropdown<string>
        id={'search-rule-library'}
        labelSx={{ color: 'neutral.main' }}
        value={searchTerm}
        placeholder={barPlaceHolder}
        withPopupIcon={false}
        disableClearable={true}
        options={options}
        onSelect={(value) => handleOnSelect(value)}
        InputProps={{
          startAdornment: (
            <Box display="flex" justifyContent="center" alignItems="center" marginLeft="6px" marginRight="3px">
              <SearchIcon />
            </Box>
          ),
          onChange: onChangeStringHandler(handleOnChange),
        }}
        fallbackOption={{ label: searchTerm, value: searchTerm }}
      />
    </>
  );
};

export { SearchRuleDropDown };
