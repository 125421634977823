import { GridItem } from 'components/ui';
import { DeleteComponentButton } from '../shared/DeleteComponentButton';
import { RootCondition } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { FC } from 'react';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { ConditionType } from 'pages/RuleForm/types';
import { ConditionalValueInput } from '../shared/ConditionalValueInput/ConditionalValueInput';
import { FUNCTION_ENUM } from '../shared/Constants';
import { OperatorDropdown } from '../shared/OperatorDropdown';
import _ from 'lodash';
import { Typography } from '@mui/material';

export interface HavingRecordFormProps {
  index: number;
  condition: RootCondition;
}

const HavingRecordForm: FC<HavingRecordFormProps> = (props) => {
  const { index, condition } = props;

  const { having, setHaving, selectedTable, formError } = useRuleForm();
  const allowedFunctions = [FUNCTION_ENUM.COUNT, FUNCTION_ENUM.MAX, FUNCTION_ENUM.MIN, FUNCTION_ENUM.SUM];

  return (
    <>
      <GridItem container display={'flex'} alignItems={'center'} xs={12} md={5} sx={{ my: 1, px: 1 }}>
        <GridItem width={'100%'}>
          <ConditionalValueInput
            targetPath={`having[${index}].field`}
            rootConditionalPath={`having[${index}]`}
            conditionalValueComponent={condition.field}
            conditionType={ConditionType.HAVING}
            index={index}
            allowedValueTypes={[RuleComponentType.FUNCTION_VALUE, RuleComponentType.PRIMITIVE_VALUE]}
            allowedValueFunctions={allowedFunctions}
            fieldOptions={[]}
            tableName={selectedTable}
          />
        </GridItem>
        {formError && _.get(formError, `having[${index}].field`) && (
          <GridItem flex={1}>
            <Typography variant="caption" sx={{ color: 'red.main' }}>
              {_.get(formError, `having[${index}].field`)}
            </Typography>
          </GridItem>
        )}
      </GridItem>

      <GridItem xs={12} md={1.5} sx={{ my: 1, px: 1 }}>
        <OperatorDropdown
          operator={condition?.operator || null}
          condition={having[index] as RootCondition}
          targetPath={`having[${index}].operator`}
          tableName={selectedTable}
        />
      </GridItem>

      <GridItem container display={'flex'} alignItems={'center'} xs={12} md={5} sx={{ my: 1, px: 1 }}>
        <GridItem width={'100%'}>
          <ConditionalValueInput
            targetPath={`having[${index}].value`}
            rootConditionalPath={`having[${index}]`}
            conditionalValueComponent={condition.value}
            conditionType={ConditionType.HAVING}
            index={index}
            allowedValueTypes={[RuleComponentType.FUNCTION_VALUE, RuleComponentType.PRIMITIVE_VALUE]}
            allowedValueFunctions={allowedFunctions}
            fieldOptions={[]}
            tableName={selectedTable}
          />
        </GridItem>
        {formError && _.get(formError, `having[${index}].value`) && (
          <GridItem flex={1}>
            <Typography variant="caption" sx={{ color: 'red.main' }}>
              {_.get(formError, `having[${index}].value`)}
            </Typography>
          </GridItem>
        )}
      </GridItem>

      <GridItem xs={1} md={0.5} sx={{ my: 1, px: 1 }}>
        <DeleteComponentButton index={index} where={having} setWhere={setHaving} />
      </GridItem>
    </>
  );
};

export { HavingRecordForm };
