import { Box } from '@mui/material';
import { FC, useMemo } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { ReactComponent as LeadsIcon } from 'assets/icons/graph/graphOpportunityIconInverted.svg';
import theme from 'core/theme';
import { ComponentDesign } from '../../../types';
import { useGraphRender } from 'store/graphRender/hooks';
import { motion } from 'framer-motion';

export const LeadViewCenterNode: FC<NodeProps> = (props: NodeProps) => {
  const { id } = props;
  const HandlerStyle = { display: 'none' };

  const {
    data: { customDesignMap, defaultDesign },
  } = useGraphRender();

  let design = defaultDesign ? defaultDesign : ComponentDesign.STANDARD;

  if (customDesignMap[id]) {
    design = customDesignMap[id];
  }

  const borderColor = useMemo(() => {
    if (design === ComponentDesign.SEARCHING || design === ComponentDesign.DUPLICATE_INACTIVE) {
      return theme.palette.neutral.subtone3;
    }
    return theme.palette.primary.darkBlueHigh;
  }, [design]);

  const icon = useMemo(() => <LeadsIcon stroke="white" width={'100%'} height={'100%'} />, []);

  return (
    <motion.div
      style={{
        border: '7px solid',
        borderColor: theme.palette.neutral.white,
        backgroundColor: borderColor,
        borderRadius: '100%',
        width: '100%',
        height: '100%',
      }}
    >
      <Box sx={{ padding: 0, margin: 0, width: '100%', height: '100%' }}>{icon}</Box>
      <Handle position={Position.Right} type="source" style={HandlerStyle} />
      <Handle position={Position.Left} type="target" style={HandlerStyle} />
    </motion.div>
  );
};
