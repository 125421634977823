import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { format } from 'date-fns';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface IProps {
  data: {
    date: Date;
    data: {
      calls: number;
      emails: number;
      docs: number;
    };
  }[];
}

const AccountEngagementChart = ({ data = [] }: IProps) => {
  const options: ChartOptions<'bar'> = {
    plugins: {
      title: {
        display: false,
        text: 'Engagement',
      },
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          callback: function (_: any, index: any) {
            const label = this.getLabelForValue(index);
            return label.split(' ').join('\n');
          },
          font: {
            size: 10,
          },
        },
      },
      y: {
        display: false,
        stacked: true,
      },
    },
  };

  const chartData = React.useMemo(
    () => ({
      labels: data.map((entry) => format(entry.date, 'MMM yy')),

      datasets: [
        {
          label: 'Emails',
          data: data.map((entry) => entry.data.emails),
          backgroundColor: '#60c67c',
          barThickness: 10,
        },
        {
          label: 'Calls',
          data: data.map((entry) => entry.data.calls),
          backgroundColor: '#ff9960',
          barThickness: 10,
        },
        {
          label: 'Docs',
          data: data.map((entry) => entry.data.docs),
          backgroundColor: '#fb4e6d',
          barThickness: 10,
          borderRadius: {
            topLeft: 3,
            topRight: 3,
          },
        },
      ],
    }),
    [data],
  );
  return (
    <Box p={1} bgcolor={'#F6F8FB'}>
      <Typography variant="labelMedium12" color="neutral.n400">
        Account engagement
      </Typography>
      <Box sx={{ height: '120px', width: '100%' }}>
        <Bar options={options} data={chartData} />
      </Box>
    </Box>
  );
};

export default AccountEngagementChart;
