import { Box, SxProps, Theme, Typography } from '@mui/material';
import theme from 'core/theme';
import { ChatMessage } from 'http/ai';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';

interface AIAssistantChatHistoryProps {
  chatHistory: ChatMessage[];
  aiThinking: boolean;
}

const aiMessageStyle: SxProps<Theme> = {
  maxWidth: '80%',
  minHeight: 'fit-content',
  alignSelf: 'flex-start',
  borderRadius: '0px 24px 24px 24px',
  padding: 1.5,
  backgroundColor: '#F2F4F5',
  '& .ai-message-text': {
    wordWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
};

const humanMessageStyle: SxProps<Theme> = {
  width: '80%',
  alignSelf: 'flex-end',
  borderRadius: '24px 24px 0px 24px',
  padding: 1.5,
  backgroundColor: theme.palette.primary.main,
  '& .human-message-text': {
    color: 'white',
    wordWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
};

export const AIAssistantChatHistory: FC<AIAssistantChatHistoryProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [props.chatHistory, scrollToBottom]);

  const renderAIMessage = (message: ChatMessage) => {
    return (
      <Box key={message.text} sx={aiMessageStyle}>
        <Typography className="ai-message-text">{message.text}</Typography>
      </Box>
    );
  };

  const renderHumanMessage = (message: ChatMessage) => {
    return (
      <Box key={message.text} sx={humanMessageStyle}>
        <Typography className="human-message-text">{message.text}</Typography>
      </Box>
    );
  };

  const ThinkingLabel = useMemo(() => {
    return (
      <Box sx={{ ...aiMessageStyle, px: 3 }}>
        <div className="dot-falling"></div>
      </Box>
    );
  }, []);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2} sx={{ py: 1 }}>
      {props.chatHistory.map((message) => {
        return message.type.toLowerCase() === 'ai' ? renderAIMessage(message) : renderHumanMessage(message);
      })}
      {props.aiThinking && ThinkingLabel}
      <div style={{ float: 'left', clear: 'both' }} ref={ref}></div>
    </Box>
  );
};
