import React from 'react';
import { Box, Grid, Typography, TextField, InputAdornment } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ShareButton from './components/ShareButton/ShareButton';
import { Container, PrimaryButton } from '../ui';
import { ReactFlowProvider } from 'reactflow';
import { GraphRender } from 'pages/DataGraph/components/GraphRender';
import { useGraphRender } from 'store/graphRender/hooks';
import { GraphFooterCounter } from 'pages/DataGraph/components/GraphFooterCounter';
import SettingsButton from './components/SettingsButton/SettingsButton';

const SingleGraphSpace = () => {
  const {
    data: { graphObjectCounter, view },
  } = useGraphRender();
  return (
    <Container
      sx={{
        gap: 2,
        display: 'grid',
        gridTemplateRows: '84px auto 40px',
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent={'space-between'}
        sx={{ backgroundColor: 'neutral.white', padding: '24px 32px' }}
      >
        <Grid item xs={6}>
          <Grid sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography variant="h2" sx={{ color: 'neutral.main', marginRight: 2 }}>
              Single Graph Space Name
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon sx={{ fontSize: '14px' }} />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiInputBase-input': {
                  padding: '8px 8px',
                },
              }}
              placeholder="Search record"
            />

            <PrimaryButton sx={{ ml: 2 }} variant="outlined">
              <FilterListIcon sx={{ color: 'primary', fontSize: '14px', mr: 1 }} />
              Filters
              <Box sx={{ backgroundColor: 'primary.main', color: 'white', mx: 1, px: '5px', borderRadius: '3px' }}>
                <Typography sx={{ fontSize: '12px' }}>1</Typography>
              </Box>
            </PrimaryButton>
            <ShareButton />

            <SettingsButton />
          </Grid>
        </Grid>
      </Grid>

      <Grid width={'100%'} height="100%">
        <ReactFlowProvider>
          <GraphRender />
        </ReactFlowProvider>
      </Grid>
      <Grid sx={{ display: 'flex', flexWrap: 'wrap', maxHeight: '50px' }}>
        <GraphFooterCounter viewName={view} graphObjectCounter={graphObjectCounter} />
      </Grid>
    </Container>
  );
};

export default SingleGraphSpace;
