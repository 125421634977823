import React from 'react';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';
import { isBefore, isAfter, eachMonthOfInterval, isSameMonth } from 'date-fns';

interface IProps {
  migrationId: string;
  node: any;
}
const useContactInsightsData = ({ migrationId, node }: IProps) => {
  const { data: eventsData = [], isFetching: eventsLoading } = useGetMigrationTableDataByIdQuery({
    migrationId: migrationId,
    tableId: 'Event',
    skip: 0,
    take: 0,
    conditions: [{ field: 'WhoId', operator: '=', value: `'${node.id}'` }],
  });

  const { data: tasksData = [], isFetching: tasksLoading } = useGetMigrationTableDataByIdQuery({
    migrationId: migrationId,
    tableId: 'Task',
    skip: 0,
    take: 0,
    conditions: [{ field: 'WhoId', operator: '=', value: `'${node.id}'` }],
  });

  const { data: emailsData = [], isFetching: emailsLoading } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'EmailMessage',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'ActivityId',
          operator: 'IN',
          value: `(${[...eventsData, ...tasksData]
            .filter((e) => e.EventSubtype === 'Email' || e.TaskSubtype === 'Email')
            .map((e) => `'${e.Id}'`)
            .join(', ')})`,
        },
      ],
    },
    {
      skip: [...eventsData, ...tasksData].length === 0,
    },
  );

  const isLoading = eventsLoading || tasksLoading || emailsLoading;

  const allActivities = React.useMemo(() => [...tasksData, ...eventsData], [tasksData, eventsData]);

  const activitiesCount = allActivities.length;
  const nextMeeting = React.useMemo(
    () =>
      allActivities?.length === 0
        ? null
        : allActivities
            .filter(
              (singleActivity) =>
                !!singleActivity &&
                !!singleActivity.ActivityDate &&
                isAfter(new Date(singleActivity.ActivityDate), new Date()),
            )
            .sort((a, b) => new Date(a.ActivityDate).getTime() - new Date(b.ActivityDate).getTime())[0],
    [allActivities],
  );

  const lastContacted = React.useMemo(
    () =>
      allActivities?.length === 0
        ? null
        : allActivities
            .filter(
              (singleActivity) =>
                !!singleActivity &&
                !!singleActivity.ActivityDate &&
                isBefore(new Date(singleActivity.ActivityDate), new Date()),
            )
            .sort((a, b) => new Date(b.ActivityDate).getTime() - new Date(a.ActivityDate).getTime())[0],
    [allActivities],
  );

  const preferredMethod = React.useMemo(() => {
    const emailsCount = allActivities.filter((e) => e.EventSubtype === 'Email' || e.TaskSubtype === 'Email').length;
    const callsCount = allActivities.filter((e) => e.EventSubtype === 'Call' || e.TaskSubtype === 'Call').length;

    if (callsCount > emailsCount) return 'call';
    return 'email';
  }, [allActivities]);

  const accountEngagementChartData = React.useMemo(() => {
    const oldestEntry = allActivities.reduce((oldest, current) => {
      if (!oldest) return current;
      return isBefore(new Date(current.ActivityDate), new Date(oldest.ActivityDate)) ? current : oldest;
    }, null);
    const latestEntry = allActivities.reduce((latest, current) => {
      if (!latest) return current;
      return isAfter(new Date(current.ActivityDate), new Date(latest.ActivityDate)) ? current : latest;
    }, null);
    if (!oldestEntry || !latestEntry) return null;
    const months = eachMonthOfInterval({
      start: new Date(oldestEntry.ActivityDate),
      end: new Date(latestEntry.ActivityDate),
    });

    const data = months.map((monthDate) => {
      const monthCalls = allActivities.filter(
        (singleActivity) =>
          isSameMonth(new Date(singleActivity.ActivityDate), monthDate) &&
          (singleActivity.EventSubtype === 'Call' || singleActivity.TaskSubtype === 'Call'),
      ).length;
      const monthEmails = allActivities.filter(
        (singleActivity) =>
          isSameMonth(new Date(singleActivity.ActivityDate), monthDate) &&
          (singleActivity.EventSubtype === 'Email' || singleActivity.TaskSubtype === 'Email'),
      ).length;
      const monthDocs = emailsData.filter(
        (singleEmail) => !!singleEmail.HasAttachment && isSameMonth(new Date(singleEmail.CreatedDate), monthDate),
      ).length;

      return {
        calls: monthCalls,
        emails: monthEmails,
        docs: monthDocs,
      };
    });
    return months.map((month, index) => ({
      date: month,
      data: data[index],
    }));
  }, [allActivities, emailsData]);

  return {
    isLoading,
    activitiesCount,
    nextMeeting,
    lastContacted,
    preferredMethod,
    accountEngagementChartData,
  };
};

export default useContactInsightsData;
