import { FC, useCallback, useMemo } from 'react';

import _ from 'lodash';
import theme from 'core/theme';

import { Box, Button, Typography } from '@mui/material';

import { Show } from '../../../../show';
import { SubQueryItem } from './components/SubQueryItem';
import { useRuleFormModalHook } from '../../../RuleFormModal.hook';

import { useRuleFormStore } from 'store/ruleForm/hook';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorExclamation.svg';

export const RuleFormAdvFilter: FC = () => {
  const { formError } = useRuleFormStore();

  const {
    data: { subQueries },
    handleAddSubQueryObject,
  } = useRuleFormModalHook();

  const onclickAddSubQuery = useCallback(() => {
    handleAddSubQueryObject();
  }, [handleAddSubQueryObject]);

  const areThereSubQueriesErrors = useMemo(() => formError && _.get(formError, `subQueries`), [formError]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <Typography sx={{ color: theme.palette.neutral.darkGray }} variant="labelRegular12">
          Create complex filtering conditions using dataset. For example, define a filter for opportunities with no
          recent activity in the last 7 days and reference this filter when creating a rule for accounts. This allows
          you to combine data from multiple objects (e.g., accounts, opportunities, activities) for more precise rule
          creation.
          <br />
          <br />
          Example: <br />
          Define an dataset called &quot;Opportunities with No Recent Activity&quot; to include opportunities where the
          status is &apos;Open&quot; but have had no activity in the last 7 days. This dataset can then be referenced
          when creating a rule for the Account object.
          <br />
          <br />
          This setup allows you to check for accounts in the Telecom industry that have open opportunities without
          recent activities, ensuring your rules can span across multiple related objects for comprehensive data quality
          checks
        </Typography>
      </Box>
      <Show when={areThereSubQueriesErrors}>
        <Box
          sx={{
            backgroundColor: theme.palette.red.light,
            p: 1,
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <ErrorIcon />
          <Typography variant="labelMedium14">
            Sorry, there was a problem. You&apos;ll find more details highlighted below.
          </Typography>
        </Box>
      </Show>

      <Box>
        <Button onClick={onclickAddSubQuery}>+ Add a New Dataset</Button>
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        {subQueries.map((subQuery, index) => (
          <SubQueryItem
            key={`sub-query-rule-${subQuery.tempId || index}`}
            open={true}
            handleToggleShow={() => console.log('toggleShow')}
            index={index}
          />
        ))}
      </Box>
    </Box>
  );
};
