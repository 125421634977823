import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress, Container, LinearProgress, Typography } from '@mui/material';

import { HomePage } from './pages/HomePage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import { useDefaultMigration } from 'pages/Dashboard/hooks/useDefaultMigration';
import { Show } from '../../../../components/show';

const pagesCount = 2;

const LoadingComponent = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
      <CircularProgress size={80} sx={{ width: '100%' }} />
      <Typography variant="b16">Loading</Typography>
    </Box>
  );
};

export const Admin: FC = () => {
  const [page, setPage] = useState(-1);
  const { defaultMigration, migrationsQuery } = useDefaultMigration();

  const hasMigration: boolean | null = useMemo(() => {
    if (migrationsQuery.isSuccess === true) {
      return !!defaultMigration.dataMigrationId;
    } else if (migrationsQuery.isError === true) {
      return false;
    } else {
      return null;
    }
  }, [defaultMigration.dataMigrationId, migrationsQuery.isError, migrationsQuery.isSuccess]);

  const handleNextTab = useCallback(() => {
    const nextPage = (page + 1) % pagesCount;
    setPage(nextPage);
  }, [page]);

  useEffect(() => {
    if (hasMigration === true) {
      setPage(1);
    } else if (hasMigration === false) {
      setPage(0);
    }
  }, [hasMigration]);

  const tabs = useMemo(
    () => [<HomePage key={'home'} onClickButton={handleNextTab} />, <DashboardPage key={'dashboard'} />],
    [handleNextTab],
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Show when={hasMigration != null && page != -1} fallback={<LoadingComponent />}>
        <Container fixed>{tabs[page]}</Container>
      </Show>
    </Box>
  );
};

export default Admin;
