import { FC, useCallback, useState } from 'react';

import { TabContext, TabPanel } from '@mui/lab';
import { Box, Grid, Tabs, Typography } from '@mui/material';

import { useIntegration } from 'store/integration/hooks';
import { IntegrationCategory } from 'store/integration/types';

import { SearchDropdown } from 'components/SearchDropdown';
import { IntegrationCard } from './components/IntegrationCard';

import { Container, IntegrationsSection, TabItem } from './ui';

const IntegrationPage: FC = () => {
  const [filterValue, setFilterValue] = useState<string>();

  const integration = useIntegration();
  const integrationsQuery = integration.getIntegrationsQuery();

  const [currentCategory, setCurrentCategory] = useState<IntegrationCategory>('All');

  const onIntegrationCategoryChange = useCallback(
    (_e: React.SyntheticEvent, integrationCategory: IntegrationCategory) => {
      setCurrentCategory(integrationCategory);
    },
    [],
  );

  const tabIntegrations = integrationsQuery.data?.filter((integration) => {
    return currentCategory === 'All' || integration.categories?.includes(currentCategory);
  });

  return (
    <Container>
      <TabContext value={currentCategory}>
        <Grid container spacing={2} sx={{ backgroundColor: 'neutral.white', padding: '24px 32px 16px' }}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h2" sx={{ color: 'neutral.main' }}>
              Integration
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop={2.5}>
              <Box width="75%" marginRight={2}>
                <Tabs
                  value={currentCategory}
                  aria-label="integration categories"
                  onChange={onIntegrationCategoryChange}
                >
                  <TabItem label="All" value={'All' as IntegrationCategory} />
                  <TabItem label="CRM" value={'CRM' as IntegrationCategory} />
                  <TabItem label="Sales" value={'Sales' as IntegrationCategory} />
                  <TabItem label="Support" value={'Support' as IntegrationCategory} />
                  <TabItem label="Database" value={'Database' as IntegrationCategory} />
                  <TabItem label="Marketing" value={'Marketing' as IntegrationCategory} />
                  <TabItem label="Accounting" value={'Accounting' as IntegrationCategory} />
                  <TabItem label="Productivity" value={'Productivity' as IntegrationCategory} />
                  <TabItem label="Data Provider" value={'Data Provider' as IntegrationCategory} />
                </Tabs>
              </Box>
              <Box width="25%" display="flex" flexDirection="row">
                <Box width="100%">
                  <SearchDropdown
                    id="search-integrations"
                    placeholder="Search all integrations..."
                    onSelect={(term) => setFilterValue(term)}
                    options={
                      tabIntegrations?.map((i) => ({
                        label: i.applicationName,
                        value: i.applicationName,
                      })) ?? []
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <IntegrationsSection>
          <TabPanel value={currentCategory}>
            <Grid container spacing={1} display={'flex'} direction={'row'} justifyContent={'start'}>
              {tabIntegrations?.flatMap((integration, index) => {
                if (filterValue?.length) {
                  if (integration.applicationName !== filterValue) {
                    return [];
                  }
                }

                return (
                  <Grid key={index} item>
                    <IntegrationCard
                      id={integration.id}
                      applicationId={integration.applicationId}
                      applicationName={integration.applicationName}
                      applicationIcon={integration.applicationIcon}
                      applicationStatus={integration.applicationStatus}
                      applicationDescription={integration.applicationDescription}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </TabPanel>
        </IntegrationsSection>
      </TabContext>
    </Container>
  );
};

export default IntegrationPage;
