import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { CARD_DIMENSIONS, IMAGE_DIMENSIONS } from './Constants';

type IntegrationCardProps = {
  logoSrc: string;
  name: string;
  isHighlighted: boolean;
};

const IntegrationCard: React.FC<IntegrationCardProps> = ({ logoSrc, name, isHighlighted }) => (
  <Box
    sx={{
      width: CARD_DIMENSIONS.width,
      backgroundColor: 'neutral.white',
      borderRadius: 1,
      border: `2px solid ${isHighlighted ? 'blue' : 'transparent'}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      p: 2,
    }}
  >
    <Box>
      <img src={logoSrc} alt={name} style={{ width: IMAGE_DIMENSIONS, height: IMAGE_DIMENSIONS, objectFit: 'cover' }} />
    </Box>
    <Typography variant="p16">{name}</Typography>
  </Box>
);

export default IntegrationCard;
