import React from 'react';
import { Box, Grid, Typography, IconButton, Stack, BoxProps } from '@mui/material';
import styled from '@mui/styles/styled';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallIcon from '@mui/icons-material/Call';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';

interface IProps {
  type: 'call' | 'email' | 'meeting' | 'event' | 'task' | 'note';
  title: string;
  path: string[];
  toggleOpen: (toggle: boolean) => void;
}

const IconBox = styled(Box)<BoxProps>({
  borderRadius: '100%',
  width: '30px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Header = ({ type, title, toggleOpen, path }: IProps) => {
  const icon = React.useMemo(() => {
    if (type === 'call')
      return (
        <IconBox bgcolor={'violet.main'}>
          <CallIcon sx={{ color: 'white', fontSize: '18px' }} />
        </IconBox>
      );

    if (type === 'email')
      return (
        <IconBox bgcolor={'violet.main'}>
          <MailOutlineIcon sx={{ color: 'white', fontSize: '18px' }} />
        </IconBox>
      );
    if (type === 'meeting' || type === 'event')
      return (
        <IconBox bgcolor={'green.main'}>
          <VideocamOutlinedIcon sx={{ color: 'white', fontSize: '18px' }} />
        </IconBox>
      );
    if (type === 'task')
      return (
        <IconBox bgcolor={'blue.main'}>
          <CheckBoxOutlinedIcon sx={{ color: 'white', fontSize: '18px' }} />
        </IconBox>
      );
    if (type === 'note')
      return (
        <IconBox bgcolor={'blue.light'}>
          <ChatBubbleOutlineRoundedIcon sx={{ color: 'white', fontSize: '18px' }} />
        </IconBox>
      );
  }, [type]);
  return (
    <Grid container display={'flex'} flexDirection={'column'}>
      <Grid container display={'flex'} width={'100%'} justifyContent={'space-between'} gap={1}>
        <Grid item xs={10}>
          <Stack direction="row" alignItems="center" gap={0.2}>
            {path.map((element) => (
              <>
                <Typography
                  variant="p12"
                  sx={{
                    color: 'blue.main',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    textTransform: 'capitalize',
                  }}
                >
                  {element}
                </Typography>
                <ChevronRightIcon sx={{ fontSize: '10px' }} />
              </>
            ))}
            <Typography variant="p12">{title}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={10}>
          <Stack direction="row" alignItems="center" gap={1}>
            {icon}
            <Typography variant="h3">{title}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={1}>
          <Box display={'flex'} justifyContent={'end'}>
            <IconButton sx={{ textAlign: 'left', px: 0 }} onClick={() => toggleOpen(false)}>
              <CrossIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Header;
