import { useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';

import { GridItem } from 'components/ui';
import { CustomSelect } from 'components/CustomSelect';
import { useDataRaptor } from 'store/dataRaptor/hooks';
import { useMigration } from 'store/migration/hooks';
import { DataValidation } from './DataValidation';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { QueryValueList } from './components/QueryValueList/QueryValueList';

const DataValidationRuleDefinition = () => {
  const { selectedTable } = useDataRaptor();
  const {
    data: { migratedTables },
  } = useMigration();

  const { where, tableOptions, setTableOptions, handleAddInitialWhere } = useRuleForm();

  useEffect(() => {
    const options = migratedTables.map((table) => {
      return { value: table.table_name, label: table.table_name };
    });
    setTableOptions(options);
  }, [migratedTables, setTableOptions]);

  const hasWhereItems = where?.length > 0;

  return (
    <Box>
      <Grid
        container
        display="flex"
        justifyContent="flex-start"
        sx={{ backgroundColor: 'darkBg.main', p: 1.5, rowGap: 2 }}
      >
        <Grid container>
          <QueryValueList />
        </Grid>
        <Grid container display="flex" justifyContent="center">
          <GridItem xs={1} textAlign={'center'}>
            <Typography variant="subtitle2" component="label" ml={1} my={'auto'} fontWeight={'bold'}>
              Where:
            </Typography>
          </GridItem>
          <GridItem xs={3}>
            <CustomSelect<string>
              id="where[0].table"
              name="where[0].table"
              variant="outlined"
              placeholder="Table"
              value={selectedTable}
              options={tableOptions}
              disabled={true}
              sx={{ backgroundColor: 'neutral.white', width: '90%' }}
            />
          </GridItem>
          {!hasWhereItems && (
            <GridItem xs={2}>
              <Button
                variant="outlined"
                sx={{ width: 'fit-content', display: 'inline', marginRight: 'auto', my: 'auto' }}
                onClick={() => handleAddInitialWhere('where[0]')}
              >
                +
              </Button>
            </GridItem>
          )}
        </Grid>
        <Grid container display="flex" justifyContent="flex-start" sx={{ rowGap: 1 }}>
          <DataValidation></DataValidation>
        </Grid>
      </Grid>
    </Box>
  );
};

export { DataValidationRuleDefinition };
