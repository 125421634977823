import { Box, Grid } from '@mui/material';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import theme from 'core/theme';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { useGraphRender } from 'store/graphRender/hooks';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import _ from 'lodash';
import { ConditionalRecordForm } from './ConditionalRecordForm';
import { LogicalOperator, RootCondition, RuleWhereArray } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { LogicalOperatorFormRow } from './LogicalOperatorFormRow';
import { AddLogicalOperatorButton } from './AddLogicalOperatorButton';
import { ConditionType } from './Constants';
import { useMigration } from 'store/migration/hooks';

export interface CustomFilterWhereListProps {
  availableObjectTypes: { label: string; value: string }[];
  index: number;
  showCloseButton: boolean;
  handleValueChange: (index: number, value: string) => void;
  handleDelete: (index: number) => void;
  objectTypeValue: string | null;
}

export const CustomFilterWhereList: FC<CustomFilterWhereListProps> = (props) => {
  const { availableObjectTypes, index, showCloseButton, handleValueChange, objectTypeValue, handleDelete } = props;

  const {
    data: { customFilterTmp },
    partialUpdateCustomGraphFilter,
  } = useGraphRender();

  const {
    data: { migrationId, migrationTableFieldsAndLookUps },
  } = useMigration();

  const selectedWhere: RuleWhereArray = useMemo(() => {
    if (objectTypeValue) {
      return (customFilterTmp as any)[objectTypeValue]?.where || [];
    }
    return [];
  }, [customFilterTmp, objectTypeValue]);

  useEffect(() => {
    if (!_.isEmpty(objectTypeValue) && _.isEmpty(selectedWhere)) {
      partialUpdateCustomGraphFilter({
        path: `${objectTypeValue}.where[0]`,
        value: {
          field: { type: RuleComponentType.FIELD_REFERENCE, value: '' },
          operator: '',
          value: { type: RuleComponentType.FIELD_REFERENCE, value: '' },
          type: RuleComponentType.ROOT_CONDITIONAL,
        },
      });
    }
  }, [objectTypeValue, partialUpdateCustomGraphFilter, selectedWhere]);

  const handleOnSelectedObjectType = useCallback(
    (value: string): void => {
      if (!_.isEmpty(objectTypeValue)) {
        partialUpdateCustomGraphFilter({
          path: `${objectTypeValue}`,
          value: undefined,
        });
      }
      handleValueChange(index, value);
    },
    [handleValueChange, index, objectTypeValue, partialUpdateCustomGraphFilter],
  );

  const handleOnDeleteCustomFilter = useCallback(() => {
    handleDelete(index);
  }, [handleDelete, index]);

  const showWhereList = useMemo(
    () => !_.isEmpty(objectTypeValue) && !_.isEmpty(selectedWhere),
    [objectTypeValue, selectedWhere],
  );

  const fieldOptions = useMemo(() => {
    const fields = migrationTableFieldsAndLookUps[`${migrationId}-${objectTypeValue}`]?.data?.['fields'] || [];
    return fields.map((field) => {
      return {
        label: field.fieldName,
        value: field.fieldName,
        xsd_type: field.xsd_type,
      };
    });
  }, [migrationId, migrationTableFieldsAndLookUps, objectTypeValue]);

  const getWhereList = useCallback(() => {
    return selectedWhere.map((conditional, index) => {
      const type = conditional.type;
      if (type === RuleComponentType.LOGICAL_OPERATOR) {
        return (
          <LogicalOperatorFormRow
            key={`where-list-custom-filter-${objectTypeValue}-${index}`}
            logicalOperatorRecord={conditional as LogicalOperator}
            targetPath={`${objectTypeValue}.where[${index}]`}
            setFieldFunction={partialUpdateCustomGraphFilter}
            width="25%"
          />
        );
      }

      return (
        <ConditionalRecordForm
          key={`where-list-custom-filter-${objectTypeValue}-${index}`}
          conditionComponent={conditional as RootCondition}
          conditionPath={`${objectTypeValue}.where[${index}]`}
          wherePath={`${objectTypeValue}.where`}
          index={index}
          where={selectedWhere}
          fieldOptions={fieldOptions}
          tableName={objectTypeValue || ''}
          setFieldFunction={partialUpdateCustomGraphFilter}
          firstFieldValidValueType={[RuleComponentType.FIELD_REFERENCE]}
          secondFieldValidValueType={[RuleComponentType.FIELD_REFERENCE, RuleComponentType.PRIMITIVE_VALUE]}
          showDeleteButton={index > 0}
        />
      );
    });
  }, [fieldOptions, objectTypeValue, partialUpdateCustomGraphFilter, selectedWhere]);

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          marginY: '1rem',
          marginX: '0.7rem',
          p: '1rem',
          border: `${theme.palette.primary.main} solid 1px`,
          borderRadius: '4px',
        }}
      >
        {showCloseButton && (
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              p: '2px',
              transform: 'translate(50%,-50%)',
              backgroundColor: theme.palette.neutral.white,
              border: `${theme.palette.neutral.darkGray} solid 1px`,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={handleOnDeleteCustomFilter}
          >
            <CrossIcon style={{ fill: theme.palette.neutral.darkGray }}></CrossIcon>
          </Box>
        )}
        <ControlledCustomDropdown
          value={objectTypeValue}
          options={availableObjectTypes}
          label="Object Type"
          labelSx={{ color: theme.palette.neutral.main }}
          fallbackOption={{ value: '', label: 'Select a object type' }}
          placeholder={'Select a object type'}
          id={`custom-object-type-selector-${index}`}
          onSelect={handleOnSelectedObjectType}
          listBoxProps={{ maxHeight: 200 }}
        />
        {showWhereList && (
          <>
            <Grid container display={'flex'} mt={2}>
              {getWhereList()}
              <Box mt={2}>
                <AddLogicalOperatorButton
                  index={selectedWhere.length - 1}
                  where={selectedWhere}
                  type={ConditionType.WHERE}
                  wherePath={`${objectTypeValue}.where`}
                  setFieldFunction={partialUpdateCustomGraphFilter}
                />
              </Box>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};
