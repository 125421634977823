import _ from 'lodash';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import {
  ConditionalValue,
  FieldReference,
  PrimitiveValue,
  RootCondition,
  RuleWhereArray,
} from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { GraphFilter, TableFilter } from 'store/graphData/types';

export const buildPrimitiveCondition = (
  fieldName: string,
  operator: string,
  value: string | any,
  format?: string,
): RootCondition => {
  return {
    type: RuleComponentType.ROOT_CONDITIONAL,
    field: { type: RuleComponentType.FIELD_REFERENCE, value: fieldName } as FieldReference,
    operator: operator,
    value: { type: RuleComponentType.PRIMITIVE_VALUE, value: value, format } as PrimitiveValue,
  };
};

export const formatGraphFilter = (filter: GraphFilter) => {
  const tables = Object.keys(filter);
  const formattedGraphFilter: any = {};
  tables.forEach((table) => {
    let processedWhere = [];
    const formattedTableFilter: TableFilter = { table: '', where: [] };
    const currentTable: TableFilter = (filter as any)[table];
    if (currentTable) {
      formattedTableFilter.table = _.capitalize(table);
      processedWhere = addMissingLogicalOperators(currentTable.where);
      formattedGraphFilter[table.toLocaleLowerCase()] = formattedTableFilter;
      formattedGraphFilter[table.toLocaleLowerCase()].where = processedWhere;
    }
  });
  return formattedGraphFilter;
};

export const validateWhereList = (where: RuleWhereArray = []) => {
  let valid = true;
  where.forEach((condition) => {
    const type = condition.type;
    if (type === RuleComponentType.ROOT_CONDITIONAL) {
      const { valid: validConditional } = validateConditional(condition as RootCondition);
      if (!validConditional) {
        valid = false;
      }
    }
  });
  return valid;
};

export const validateConditional = (conditional: RootCondition) => {
  const field = conditional.field;
  const operator = conditional.operator;
  const value = conditional.value;

  const validOperator = validateOperator(operator);
  const validField = validateConditionalValue(field);
  const validValue = validateConditionalValue(value);

  const validConditional = validOperator && validField && validValue;

  return { valid: validConditional, validOperator, validField, validValue };
};

const validateFieldReference = (fieldReference: FieldReference): boolean => {
  return !!fieldReference && !!fieldReference.value && !_.isEmpty(fieldReference.value.trim());
};

const validatePrimitiveValue = (primitiveValue: PrimitiveValue): boolean => {
  return !!primitiveValue && !!primitiveValue.format && (!!primitiveValue.value || primitiveValue.value === '');
};

const validateConditionalValue = (conditionalValue: ConditionalValue): boolean => {
  const type = conditionalValue.type;
  if (type === RuleComponentType.FIELD_REFERENCE) {
    return validateFieldReference(conditionalValue as FieldReference);
  }
  if (type === RuleComponentType.PRIMITIVE_VALUE) {
    return validatePrimitiveValue(conditionalValue as PrimitiveValue);
  }
  return false;
};

const validateOperator = (operator: string): boolean => {
  return !!operator && !_.isEmpty(operator.trim());
};

const addMissingLogicalOperators = (where: RuleWhereArray = []) => {
  const formattedWhere: any[] = where.filter((x) => x);
  for (let i = 0; i < formattedWhere.length; i++) {
    if (i % 2 == 1 && formattedWhere[i].type !== RuleComponentType.LOGICAL_OPERATOR) {
      formattedWhere.splice(i, 0, { type: RuleComponentType.LOGICAL_OPERATOR, value: 'AND' });
      i = i + 1;
    }
  }
  return formattedWhere as RootCondition[];
};

export const removeConditional = (where: RuleWhereArray, index: number) => {
  const newWhere = [...where];
  if (newWhere.length <= 1) {
    return [];
  }
  if (newWhere.length > 1) {
    // Index is the last element delete the element and its previous logical operator
    if (index === newWhere.length - 1) {
      newWhere.splice(index - 1, 2);
    } else {
      // Index is not the last element delete the element and its next logical operator
      newWhere.splice(index, 2);
    }
  }
  return newWhere as RuleWhereArray;
};
