import { Button, Grid } from '@mui/material';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { FC } from 'react';
import { QueryCard } from './QueryCard';

export const QueryValueList: FC = () => {
  const { subQueries, handleAddSubQueryObject } = useRuleForm();
  return (
    <>
      <Grid container display={'flex'} gap={2}>
        {subQueries.map((query, index: number) => (
          <QueryCard key={`query-card-${index}`} subQuery={query} index={index} />
        ))}
        <Button
          variant="text"
          sx={{ width: 'fit-content', display: 'inline', marginRight: 'auto', my: 'auto' }}
          onClick={handleAddSubQueryObject}
        >
          + Add Sub Query
        </Button>
      </Grid>
    </>
  );
};
