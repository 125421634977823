import { useCallback } from 'react';
import { useDataRaptor } from 'store/dataRaptor/hooks';
import { useDataRaptorRule } from 'store/dataRaptorRule/hooks';
import { useRuleFormStore } from 'store/ruleForm/hook';
import { GetRuleDto } from 'store/dataRaptorRule/dto/getRuleDto';
import { useMigration } from 'store/migration/hooks';
import { Option } from 'store/ruleForm/types';
import {
  ConditionalValue,
  FieldReference,
  FrontEndRuleDto,
  FunctionValue,
  LookUpTable,
  LookUpValue,
  PrimitiveValue,
} from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { SubQuery } from 'store/ruleForm/types';
import { TableField, TableLookup } from 'store/migration/types';
import _ from 'lodash';
import { RuleCategory } from 'store/dataRaptorRule/types';
import { ConditionType } from 'pages/RuleLibrary/types';
import {
  LOOK_UP_FUNCTION_OPTIONS,
  PRIMITIVE_OPTIONS,
} from '../components/RuleFormComponents/DataValidation/components/shared/Constants';
import { getAnomalyEmptyRootConditional, getDataValidationEmptyRootConditional } from '../utils';

const useRuleForm = () => {
  const {
    ruleName,
    violationScore,
    description,
    category,
    riskLevel,
    department,
    where,
    having,
    formError,
    ruleTemplate,
    lookUpIndex,
    lookUpComponents,
    showLookUpSelector,
    fieldOptions,
    fieldForeignRefOptions,
    lookUpConditionType,
    lookUpTargetPath,
    subQueries,
    showSubQueryModal,
    subQueryModalIndex,
    tableOptions,
    subQueryForm,
    setSubQueryFormWhere,
    setSubQueriesWhere,
    setTableOptions,
    setSubQueryModalIndex,
    setShowSubQueryModal,
    setSubQueries,
    setFieldForeignRefOptions,
    setFieldOptions,
    setRuleName,
    setViolationScore,
    setDescription,
    setCategory,
    setRiskLevel,
    setDepartment,
    setWhere,
    setFormError,
    resetAll,
    setFieldValue,
    setHaving,
    setRuleTemplate,
  } = useRuleFormStore();

  const { selectedTable, selectedMigration, setSelectedMigration, setSelectedTable } = useDataRaptor();

  const {
    loading,
    getRuleDepartments,
    getRuleRisks,
    getRuleValidationPatterns,
    getRuleTypes,
    getRuleByMigrationAndRuleId,
    setSelectedRuleId,
    setFormMode,
    updateRuleByIdLoading,
    getRuleDepartmentsState,
    getRuleRisksState,
    getRuleValidationPatternsState,
    getRuleTypesState,
    getRuleByMigrationAndRuleIdState,
    data: {
      ruleRisks,
      ruleTypes,
      ruleDepartments,
      ruleByMigrationAndRuleId,
      selectedRuleId,
      formMode,
      ruleValidationPatterns,
    },
  } = useDataRaptorRule();

  const {
    getMigrationTableFieldsAndLookUpsById,
    getMigrationTableForeignReferencesById,
    data: { migrationTableFieldsAndLookUps, migrationTableForeignReferences },
  } = useMigration();

  const updateDataFromRule = useCallback(
    async (migrationId: string, ruleId: string, onError?: (error: any) => void, onSuccess?: () => void) => {
      getRuleByMigrationAndRuleId({
        migrationId: migrationId,
        ruleId,
        onSuccess: (rule: GetRuleDto) => {
          const { frontEndObject } = rule;
          setSelectedTable(rule.table);
          setSelectedRuleId(rule.ruleId);
          setRuleName(rule.name);
          setDescription(rule.description || '');
          setCategory(rule.type);
          setRiskLevel(rule.risk);
          setDepartment(rule.department);
          setViolationScore(rule.violationScore);
          setWhere({ value: frontEndObject.where });
          setHaving({ value: frontEndObject.having });
          rule.tableDependencies?.forEach((tableName) => {
            const key = `${migrationId}-${tableName}`;
            if (!migrationTableFieldsAndLookUps[key]?.data?.fields) {
              getMigrationTableFieldsAndLookUpsById({ migrationId, tableId: tableName });
            }
          });
          getMigrationTableForeignReferencesById({ migrationId, tableId: selectedTable });
          onSuccess && onSuccess();
        },
        onError: onError,
      });
    },
    [
      getRuleByMigrationAndRuleId,
      setSelectedTable,
      setSelectedRuleId,
      setRuleName,
      setDescription,
      setCategory,
      setRiskLevel,
      setDepartment,
      setViolationScore,
      setWhere,
      setHaving,
      getMigrationTableForeignReferencesById,
      selectedTable,
      migrationTableFieldsAndLookUps,
      getMigrationTableFieldsAndLookUpsById,
    ],
  );

  const updateDataFromRuleTemplate = useCallback(
    async (ruleTemplateProvided?: FrontEndRuleDto) => {
      const template = ruleTemplateProvided || ruleTemplate;
      if (template) {
        setRuleName(template.ruleName);
        setDescription(template.description || '');
        setCategory(template.category);
        setRiskLevel(template.riskLevel);
        setDepartment(template.department);
        setViolationScore(template.violationScore);
        setWhere({ value: template.where });
        setHaving({ value: template.having });
        setSubQueries({ value: template.subQueries || [] });
        // rule.tableDependencies?.forEach((tableName) => {
        //   const key = `${migrationId}-${tableName}`;
        //   if (!migrationTableFieldsAndLookUps[key]?.data?.fields) {
        //     getMigrationTableFieldsAndLookUpsById({ migrationId, tableId: tableName });
        //   }
        // });
        getMigrationTableForeignReferencesById({ migrationId: selectedMigration, tableId: selectedTable });
      }
    },
    [
      getMigrationTableForeignReferencesById,
      ruleTemplate,
      selectedMigration,
      selectedTable,
      setCategory,
      setDepartment,
      setDescription,
      setHaving,
      setRiskLevel,
      setRuleName,
      setSubQueries,
      setViolationScore,
      setWhere,
    ],
  );

  const handleAddSubQueryObject = useCallback(() => {
    const subQueriesLength = subQueries.length;
    const initialSubQuery: SubQuery = {
      tempId: _.uniqueId('subQuery_'),
      alias: '',
      table: '',
      where: [getDataValidationEmptyRootConditional()],
    };
    setFieldValue({
      path: `subQueries[${subQueriesLength}]`,
      value: initialSubQuery,
    });
  }, [setFieldValue, subQueries.length]);

  const updateInitialLookUpFieldByFieldNameValue = useCallback(
    (value: string) => {
      const option = fieldOptions?.find((option) => option.value === value);
      if (option?.relationshipName) {
        const fieldsKey = `${selectedMigration}-${selectedTable}`;
        const lookUpObject = migrationTableFieldsAndLookUps[fieldsKey]?.data?.lookUps ?? [];
        const optionLookUpObject = lookUpObject.find((lookUp) => lookUp.relationshipName === option.relationshipName);
        if (optionLookUpObject) {
          const initialLookUp: LookUpTable = {
            type: RuleComponentType.LOOKUP_TABLE,
            table: optionLookUpObject.tableId,
            relationShipName: optionLookUpObject.relationshipName,
            referenceTable: optionLookUpObject.referenceTo,
            joinField: optionLookUpObject.fieldName,
            referenceJoinField: 'Id',
          };
          return initialLookUp;
        } else {
          return null;
        }
      }
    },
    [fieldOptions, migrationTableFieldsAndLookUps, selectedMigration, selectedTable],
  );

  const updateInitialLookUpFieldByForeignReference = useCallback(
    (value: string) => {
      const option = fieldForeignRefOptions?.find((option) => option.value === value);
      if (option) {
        const fieldsKey = `${selectedMigration}-${selectedTable}`;
        const foreignReferences = migrationTableForeignReferences[fieldsKey]?.data ?? [];
        const reference = foreignReferences.find(
          (foreignReference) => value === `${foreignReference.tableId}(${foreignReference.relationshipName})`,
        );
        if (reference) {
          //Initial LookUp on Foreign reference has to be inverted as they are foreign
          const initialLookUp: LookUpTable = {
            type: RuleComponentType.LOOKUP_TABLE,
            table: reference.referenceTo,
            relationShipName: reference.relationshipName,
            referenceTable: reference.tableId,
            joinField: 'Id',
            referenceJoinField: reference.fieldName,
          };
          const initialComponents = [initialLookUp, { type: RuleComponentType.FIELD_REFERENCE, value: 'Id' }];
          return initialComponents;
        } else {
          return null;
        }
      }
    },
    [fieldForeignRefOptions, migrationTableForeignReferences, selectedMigration, selectedTable],
  );

  const handleOnSelectLookUpField = useCallback(
    (exactPath: string, value: string, type: ConditionType = ConditionType.WHERE) => {
      const lookUpComponent =
        type === ConditionType.WHERE
          ? updateInitialLookUpFieldByFieldNameValue(value)
          : updateInitialLookUpFieldByForeignReference(value);
      if (lookUpComponent) {
        const finalLookUpComponent = type === ConditionType.WHERE ? [lookUpComponent, {}] : lookUpComponent;
        setFieldValue({
          path: exactPath,
          value: { type: RuleComponentType.LOOKUP_VALUE, value: finalLookUpComponent },
        });
      } else {
        setFieldValue({ path: exactPath, value: { type: RuleComponentType.FIELD_REFERENCE, value } });
      }
    },
    [setFieldValue, updateInitialLookUpFieldByFieldNameValue, updateInitialLookUpFieldByForeignReference],
  );

  const toggleSubQueryModal = useCallback(
    (subQueryIndex = 0) => {
      if (showSubQueryModal) {
        // Insert clean up logic
      } else {
        setSubQueryModalIndex(subQueryIndex);
      }
      setShowSubQueryModal(!showSubQueryModal);
    },
    [setShowSubQueryModal, setSubQueryModalIndex, showSubQueryModal],
  );

  const handleAddInitialWhere = useCallback(
    (wherePath: string, categoryPreferred?: RuleCategory) => {
      let initialCondition;
      if (
        category === RuleCategory.DataValidation.toString() ||
        categoryPreferred === RuleCategory.DataValidation.toString()
      ) {
        initialCondition = getDataValidationEmptyRootConditional();
      } else {
        initialCondition = getAnomalyEmptyRootConditional();
      }
      setFieldValue({ path: wherePath, value: initialCondition });
    },
    [category, setFieldValue],
  );

  const getFieldOptionsByMigrationAndTable = useCallback(
    (migrationId: string, table: string, includeLookUps = true) => {
      const fieldsKey = `${migrationId}-${table}`;
      if (table && migrationTableFieldsAndLookUps[fieldsKey]?.data?.fields?.length > 0) {
        const fields: TableField[] = migrationTableFieldsAndLookUps[fieldsKey].data.fields || [];
        let lookUps: TableLookup[] = [];
        if (includeLookUps) {
          lookUps = migrationTableFieldsAndLookUps[fieldsKey].data.lookUps || [];
        }
        const mergeFields = [...fields, ...lookUps].map((item: any) => {
          const label = item.relationshipName ? `${item.relationshipName}` : item.fieldName;
          const fieldName = item.relationshipName ? item.relationshipName : item.fieldName;
          return {
            fieldName: fieldName,
            label,
            relationshipName: item.relationshipName,
            xsd_type: item.xsd_type,
            type: item.type,
          };
        });
        const arrayCopy = JSON.parse(JSON.stringify(mergeFields));
        const sorted = arrayCopy?.sort((a: any, b: any) => a.label.localeCompare(b.label));
        const options: Option[] = sorted?.map((field: any) => {
          return {
            value: field.fieldName,
            label: field.label,
            xsd_type: field.xsd_type,
            type: field.type,
            relationshipName: field.relationshipName,
          };
        });
        return options;
      }
      return [];
    },
    [migrationTableFieldsAndLookUps],
  );

  const getXmlTypeFilterFromValueAndOperator = useCallback(
    (value: ConditionalValue, operator: string, tableName: string) => {
      let result: (string | undefined)[] = [];
      if (value.type === RuleComponentType.FUNCTION_VALUE) {
        const functionValue = value as FunctionValue;
        const functionName = functionValue.function;
        const functionOption = LOOK_UP_FUNCTION_OPTIONS.find((option) => option.value === functionName);
        if (functionOption) {
          result = [...functionOption.resultType];
        }
      }
      if (value.type === RuleComponentType.PRIMITIVE_VALUE) {
        const primitiveValue = value as PrimitiveValue;
        const formatSelected = primitiveValue.format;
        const primitiveFormatOption = PRIMITIVE_OPTIONS.find((option) => option.value == formatSelected);
        if (primitiveFormatOption) {
          result = primitiveFormatOption.validTypes;
        }
      }
      if (value.type === RuleComponentType.FIELD_REFERENCE) {
        const fieldReference = value as FieldReference;
        const key = `${selectedMigration}-${tableName}`;
        const field = migrationTableFieldsAndLookUps[key]?.data?.fields?.find(
          (field) => field.fieldName == fieldReference.value,
        );
        result = [field?.xsd_type];
      }
      if (value.type === RuleComponentType.LOOKUP_VALUE) {
        const lookUpValue = value as LookUpValue;
        if (lookUpValue.value.length > 1) {
          const fieldReference = lookUpValue.value[lookUpValue.value.length - 1] as FieldReference;
          const fieldLookUpTable = lookUpValue.value[lookUpValue.value.length - 2] as LookUpTable;
          const key = `${selectedMigration}-${fieldLookUpTable.table}`;
          const field = migrationTableFieldsAndLookUps[key]?.data?.fields?.find(
            (field) => field.fieldName == fieldReference.value,
          );
          result = [field?.xsd_type];
        }
      }
      if (['=', '!='].includes(operator)) {
        if (result.includes('xsd:boolean')) {
          result = ['xsd:boolean', 'xsd:null'];
        } else {
          result.push('xsd:null');
        }
      }

      return result.filter((x) => x) as string[];
    },
    [migrationTableFieldsAndLookUps, selectedMigration],
  );

  const handleSubQueryDeletion = useCallback(
    (indexToDelete: number) => {
      const subQueriesTmp = _.cloneDeep(subQueries);
      subQueriesTmp.splice(indexToDelete, 1);
      setShowSubQueryModal(false);
      setSubQueryModalIndex(0);
      setTimeout(() => setSubQueries({ value: subQueriesTmp }), 0);
    },
    [setShowSubQueryModal, setSubQueries, setSubQueryModalIndex, subQueries],
  );

  return {
    ruleName,
    selectedMigration,
    selectedTable,
    violationScore,
    description,
    category,
    riskLevel,
    department,
    where,
    having,
    formError,
    loading,
    ruleRisks,
    ruleDepartments,
    ruleTypes,
    getRuleDepartmentsState,
    getRuleRisksState,
    getRuleValidationPatternsState,
    ruleValidationPatterns,
    getRuleTypesState,
    ruleByMigrationAndRuleId,
    getRuleByMigrationAndRuleIdState,
    selectedRuleId,
    formMode,
    updateRuleByIdLoading,
    lookUpComponents,
    lookUpIndex,
    showLookUpSelector,
    fieldOptions,
    fieldForeignRefOptions,
    lookUpConditionType,
    lookUpTargetPath,
    subQueries,
    showSubQueryModal,
    subQueryModalIndex,
    tableOptions,
    migrationTableFieldsAndLookUps,
    subQueryForm,
    setSubQueryFormWhere,
    handleSubQueryDeletion,
    getFieldOptionsByMigrationAndTable,
    handleAddInitialWhere,
    setSubQueriesWhere,
    setTableOptions,
    toggleSubQueryModal,
    setShowSubQueryModal,
    setSubQueries,
    setFieldForeignRefOptions,
    updateInitialLookUpFieldByFieldNameValue,
    handleOnSelectLookUpField,
    setFieldOptions,
    setHaving,
    setFormMode,
    setSelectedRuleId,
    setFieldValue,
    setFormError,
    setRuleName,
    setViolationScore,
    setDescription,
    setCategory,
    setRiskLevel,
    setDepartment,
    setWhere,
    resetAll,
    getRuleDepartments,
    getRuleRisks,
    getRuleValidationPatterns,
    getRuleTypes,
    setSelectedMigration,
    setSelectedTable,
    setRuleTemplate,
    updateDataFromRule,
    updateDataFromRuleTemplate,
    handleAddSubQueryObject,
    getMigrationTableFieldsAndLookUpsById,
    getXmlTypeFilterFromValueAndOperator,
  };
};

export default useRuleForm;
