import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useGraphRender } from 'store/graphRender/hooks';
import { GraphObjectCounter, GraphRenderView } from 'store/graphRender/types';
import { getRecordGroupCount } from 'http/migration';
import { FunctionValue, FunctionValueEnum } from 'http/migration/dto';
import { useMigration } from 'store/migration/hooks';
import { BridgeNode, BridgeNodeTypeEnum } from '../../../classes/BridgeNode';
import { Edge, EdgeType } from '../../../classes/Edge';
import { useGraphRenderDataHandlerUtils } from '../../utils/useGraphRenderDataHandlerUtils';
import { sortOptionsMap } from '../../../utils/LeadViewSortingOptions';
import { useGraphRenderLayoutUtils } from '../../utils/useGraphRenderLayoutUtils';
import { useLeadViewFunctions } from './LeadView.functions';
import { setGraphObjectCounter } from '../../../../../../../store/graphRender/actions';

const desiredEdgedLength = 150;

const useLeadView = () => {
  const { getSearchPathToOpen } = useLeadViewFunctions();
  const [triggerFirstRender, setTriggerFirstRender] = useState(false);
  const {
    data: { migrationId, migrationTableFieldsAndLookUps },
  } = useMigration();
  const { handleGraphComponentsLoad } = useGraphRenderDataHandlerUtils();
  const { onHide } = useGraphRenderLayoutUtils();
  const {
    loading,
    data: {
      view: graphRenderView,
      leadViewState: { initialGroupData, groupByFieldsSelected },
      searching,
      searchFilterResult,
    },
    setGraphObjectCounter,
    leadViewReducers: {
      setLeadViewInitialGroupData,
      setLeadViewGroupByFields,
      setLeadViewSortByField,
      setLeadViewGroupByFieldsSelected,
      clearLeadViewLoadedBridgeNode,
      setLeadViewDateFields,
      setLeadViewSetExpandSearchNodes,
    },
    setLoading,
  } = useGraphRender();

  const renderInitialGroupData = useCallback(() => {
    if (
      graphRenderView === GraphRenderView.LEADS &&
      groupByFieldsSelected.length > 0 &&
      (initialGroupData || []).length > 0
    ) {
      const mainBridgeNode = new BridgeNode('lead-center', '', {
        data: {},
        properties: { hidden: false, type: BridgeNodeTypeEnum.LeadViewCenterNode, draggable: false },
      });
      clearLeadViewLoadedBridgeNode();

      const firstKey = groupByFieldsSelected[0] as string;
      const bridgeNodes = (initialGroupData || []).map((group) => {
        const fieldCategoryUsed = group[firstKey] || 'null';
        const id = `bridge-lead-view-${fieldCategoryUsed.replace(/ /g, '')}`;
        const label = fieldCategoryUsed;
        const bridgeNode = new BridgeNode(id, label, {
          data: { connectionsCount: group.count },
          metaData: { groupByConditions: [{ field: firstKey, value: fieldCategoryUsed }], categoryLevel: 0 },
          properties: { hidden: true, type: BridgeNodeTypeEnum.LeadViewCategoryNode, draggable: true },
        });
        return bridgeNode;
      });

      const edges = bridgeNodes.map((node) => {
        const id = `lead-center-edge-${node.data.id}`;
        return new Edge(id, EdgeType.floating, mainBridgeNode.data.id, node.data.id, { hidden: true });
      });

      handleGraphComponentsLoad([], [], [], {
        formattedAdditionalNode: JSON.parse(JSON.stringify([mainBridgeNode, ...bridgeNodes])),
        formattedAdditionalEdges: JSON.parse(JSON.stringify(edges)),
      });
      setLoading(false);
      setTriggerFirstRender(true);
    }
  }, [
    graphRenderView,
    groupByFieldsSelected,
    initialGroupData,
    clearLeadViewLoadedBridgeNode,
    handleGraphComponentsLoad,
    setLoading,
  ]);

  useLayoutEffect(() => {
    if (graphRenderView === GraphRenderView.LEADS && triggerFirstRender === true) {
      onHide('lead-center', false, { recursionLevel: 0, piMultiplier: 2, desiredEdgedLength });
      setTimeout(() => {
        setTriggerFirstRender(false);
      }, 1000);
    }
  }, [graphRenderView, onHide, triggerFirstRender]);

  useEffect(() => {
    renderInitialGroupData();
  }, [renderInitialGroupData]);

  useEffect(() => {
    if (graphRenderView === GraphRenderView.LEADS && groupByFieldsSelected && groupByFieldsSelected.length > 0) {
      setLoading(true);
      getRecordGroupCount(migrationId, 'Lead', { groupBy: [groupByFieldsSelected[0]] }).then((res) => {
        setLeadViewInitialGroupData(res);
      });
    }
  }, [graphRenderView, groupByFieldsSelected, migrationId, setLeadViewInitialGroupData, setLoading]);

  useEffect(() => {
    if (graphRenderView === GraphRenderView.LEADS) {
      const fieldKey = `${migrationId}-Lead`;
      const pickListField = migrationTableFieldsAndLookUps[fieldKey]?.data.fields.filter(
        (field) => field.type === 'picklist',
      );
      const dateFieldList = migrationTableFieldsAndLookUps[fieldKey]?.data.fields.filter(
        (field) => field.type === 'date' || field.type === 'datetime',
      );
      const pickListFieldNames = pickListField.map((field) => field.fieldName);
      const dateListFieldNames = dateFieldList.map((field) => field.fieldName);
      const fallbackPickList = pickListFieldNames[0];
      const defaultPickListFound = pickListFieldNames.find((field) => field === 'Status');
      const fallBackDateField = dateListFieldNames[0];
      const defaultDateFieldFound = dateListFieldNames.find((field) => field === 'CreatedDate');
      const calculatedDateField = defaultDateFieldFound || fallBackDateField;
      const calculatedPickListUsed = [
        defaultPickListFound || fallbackPickList,
        { function: FunctionValueEnum.getYear, field: calculatedDateField, label: 'year' } as FunctionValue,
        { function: FunctionValueEnum.getMonth, field: calculatedDateField, label: 'month' } as FunctionValue,
        { function: FunctionValueEnum.getDay, field: calculatedDateField, label: 'day' } as FunctionValue,
      ];
      setLeadViewGroupByFields(pickListFieldNames);
      setLeadViewSortByField(sortOptionsMap.sortByNewest.value);
      setLeadViewDateFields(dateListFieldNames);
      setLeadViewGroupByFieldsSelected(calculatedPickListUsed);
      setTriggerFirstRender(false);
    }
  }, [
    graphRenderView,
    migrationId,
    migrationTableFieldsAndLookUps,
    setLeadViewDateFields,
    setLeadViewGroupByFields,
    setLeadViewGroupByFieldsSelected,
    setLeadViewInitialGroupData,
    setLeadViewSortByField,
  ]);

  useEffect(() => {
    if (graphRenderView === GraphRenderView.LEADS) {
      if (searching === false) {
        setLeadViewSetExpandSearchNodes({});
        clearLeadViewLoadedBridgeNode();
        setLoading(false);
      }
    }
  }, [clearLeadViewLoadedBridgeNode, graphRenderView, searching, setLeadViewSetExpandSearchNodes, setLoading]);

  //CountObject
  useEffect(() => {
    if (graphRenderView === GraphRenderView.LEADS) {
      const graphObjectCounter: GraphObjectCounter = {};
      if (loading === false && initialGroupData && initialGroupData.length > 0) {
        let count = 0;
        for (const object of initialGroupData) {
          count += object.count;
        }
        graphObjectCounter['lead'] = count;
      }
      setGraphObjectCounter(graphObjectCounter);
    }
  }, [graphRenderView, initialGroupData, loading, setGraphObjectCounter]);

  useEffect(() => {
    if (graphRenderView === GraphRenderView.LEADS) {
      if (searching && searchFilterResult) {
        const leadFilterName = 'Leads';
        const recordIds = searchFilterResult[leadFilterName]?.map((record) => record.Id);
        if (recordIds?.length > 0) {
          onHide('lead-center', false, { recursionLevel: 0, piMultiplier: 2, desiredEdgedLength });
          getSearchPathToOpen(recordIds).then((res) => {
            if (Object.keys(res || {}).length > 0) {
              setLeadViewSetExpandSearchNodes(res);
            }
            setLoading(false);
          });
        }
      } else {
        onHide('lead-center', false, { recursionLevel: 0, piMultiplier: 2, desiredEdgedLength });
      }
    }
  }, [
    clearLeadViewLoadedBridgeNode,
    getSearchPathToOpen,
    graphRenderView,
    onHide,
    searchFilterResult,
    searching,
    setLeadViewSetExpandSearchNodes,
    setLoading,
  ]);
  return {};
};

export default useLeadView;
