import { useEffect } from 'react';
import { useNodes, useReactFlow } from 'reactflow';
import { useGraphRender } from 'store/graphRender/hooks';
import { useGraphRenderLayoutUtils } from '../utils/useGraphRenderLayoutUtils';

export const useComponentsEffects = () => {
  const nodes = useNodes();
  const { getNode } = useReactFlow();
  const {
    data: { whiteEdgeBlockNodeId },
    setWhiteEdgeBlockedNodeId,
  } = useGraphRender();
  const { applyWhiteEdge } = useGraphRenderLayoutUtils();

  useEffect(() => {
    // Whenever There is a change in nodes, check if the whiteEdgeBlockNodeId is still valid
    if (whiteEdgeBlockNodeId) {
      const node = getNode(whiteEdgeBlockNodeId);
      if (!node || node.hidden === true) {
        setWhiteEdgeBlockedNodeId('');
      }
    }
  }, [applyWhiteEdge, getNode, nodes, setWhiteEdgeBlockedNodeId, whiteEdgeBlockNodeId]);
};
