import { FC, MouseEvent, useMemo, useState } from 'react';
import { Box, Menu, Button, MenuItem } from '@mui/material';
import { DataRaptorRule } from 'store/dataRaptorRule/types';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';

export interface TableMenuOptionProps {
  rule: DataRaptorRule;
}

export const TableMenuButton: FC<TableMenuOptionProps> = ({ rule }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const stateObject = useMemo(() => {
    const { dataMigrationId, ruleId } = rule;
    return { migrationId: dataMigrationId, ruleId: ruleId, type: 'rule', action: 'update' };
  }, [rule]);

  const queryString = useMemo(() => {
    return qs.stringify(stateObject);
  }, [stateObject]);

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Button variant="text" size="small" onClick={handleClick}>
        ...
      </Button>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem>
          <Link style={{ all: 'unset' }} to={`${PRIVATE_ABS_ROUTE_PATHS.ruleLibrary}?${queryString}`} target="_blank">
            Edit
          </Link>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default TableMenuButton;
