import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { PrimaryButton, SecondaryButton } from 'components/ui';
import theme from 'core/theme';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useGraphRender } from 'store/graphRender/hooks';
import { MergeTableHeader } from './components/MergeTableHeader';
import { MergeTableFields } from './components/MergeTableFields';
import {
  getDeduplicationResultByIds as getDeduplicationResultByIdsApi,
  updateDeduplicationStatus as updateDeduplicationStatusApi,
  mergeRecords as mergeRecordsApi,
} from 'http/deduplicationResult';
import { getMigrationTableDataById as getMigrationTableDataByIdApi } from 'http/migration';
import { useMigration } from 'store/migration/hooks';
import _ from 'lodash';
import { DeduplicationResult, DeduplicationResultStatus } from 'store/deduplicationResult/types';
import { getMasterRecordIds } from '../GraphRender/hooks/utils/useDuplicationDataHandlerUtils';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { getSmartMerge as getSmartMergeApi } from 'http/ai';

export const USER_REFERENCING_FIELD = ['LastModifiedById', 'OwnerId'];

const FIELDS_TO_OMIT_ON_SUBMIT = ['LastModifiedById', 'OwnerId', 'LastModifiedDate'];

const fieldNamesToOmit = [
  'Id',
  'IsDeleted',
  'confidence_score',
  'rules_applied',
  'bookmark',
  'SystemModstamp',
  'ParentId',
  'CreatedById',
  'CreatedDate',
  'AccountId',
];

const fieldNamesToOmitByTableType: any = {
  Contact: ['Name', 'IsEmailBounced'],
  Lead: ['Name'],
};

const globalPrioritizedFieldNames: string[] = ['Name', 'FirstName', 'LastName'];

const MergeModal: FC = () => {
  const {
    data: { openMergeModal, recordsToMerge, tableRecords },
    setOpenMergeModal,
    getMigrationTableRecord,
  } = useGraphRender();
  const {
    data: { migrationId },
  } = useMigration();

  const [formState, setFormState] = useState<any>({});
  const [duplicationResult, setDuplicationResult] = useState<DeduplicationResult[]>([]);
  const [records, setRecords] = useState<any[]>([]);
  const [renderRecords, setRenderRecords] = useState<any[]>([]);
  const [aiRecommendation, setAIRecommendation] = useState<any>({});
  const [masterRecordId, setMasterRecordId] = useState<string>('');
  const [tableObjectType, setTableObjectType] = useState<string>('');
  const [duplicateRecordIds, setDuplicateRecordIds] = useState<string[]>([]);
  const [loadingAiRecommendation, setLoadingAiRecommendation] = useState<boolean>(false);
  const [disableButtons, setDisableButtons] = useState<any>({});
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('Loading...');
  const [idReferencesMap, setIdReferencesMap] = useState<{ [x: string]: any }>({});

  useEffect(() => {
    if (!_.isEmpty(error) || loading === true) {
      setDisableButtons(true);
    } else {
      setDisableButtons(false);
    }
  }, [error, loading]);

  const cleanState = useCallback(() => {
    setError('');
    setLoading(false);
    setLoadingMessage('Loading...');
    setRecords([]);
    setRenderRecords([]);
    setAIRecommendation({});
    setDisableButtons(false);
    setFormState({});
    setLoadingAiRecommendation(false);
    setMasterRecordId('');
    setDuplicateRecordIds([]);
  }, []);

  useEffect(() => {
    if (recordsToMerge && recordsToMerge.length > 0) {
      let recordsToProcess = recordsToMerge;
      let errorSet = false;
      const tablesNameSet = new Set<string>();
      recordsToMerge.forEach((record) => {
        tablesNameSet.add(record.tableName);
      });
      let tableName = Array.from(tablesNameSet)[0];
      if (tablesNameSet.size > 1) {
        const tableTypeCount: any = {};
        recordsToMerge.forEach((record) => {
          if (!tableTypeCount[record.tableName]) {
            tableTypeCount[record.tableName] = 0;
          }
          tableTypeCount[record.tableName] += 1;
        });
        let selectedTable = '';
        Object.keys(tableTypeCount).forEach((key) => {
          if (tableTypeCount[key] > 1 && key !== 'Opportunity') {
            selectedTable = key;
          }
        });
        console.log('selectedTable', selectedTable);
        if (_.isEmpty(selectedTable)) {
          setError('Cross Object merge is not available yet, please select records from the same table');
          errorSet = true;
        } else {
          recordsToProcess = recordsToMerge.filter((record) => record.tableName === selectedTable);
          tableName = selectedTable;
          console.log('recordsToProcess', recordsToProcess);
        }
      }

      if (tableName === 'Opportunity') {
        setError('Opportunity records cannot be merged, please select (Account, Contact, Lead) records');
        errorSet = true;
      }
      setTableObjectType(tableName);
      if (!_.isEmpty(migrationId) && !errorSet) {
        const recordIds = recordsToProcess.map((record) => record.recordId);
        getDeduplicationResultByIdsApi(migrationId, { ids: recordIds })
          .then((res) => {
            if (res && res.length > 0) {
              setDuplicationResult(res);
            } else {
              setLoading(false);
              setError('Records not found, please refresh the page and try again');
            }
          })
          .catch(() => {
            setError('Unexpected Error while retrieving duplicate information please try again later');
            setLoading(false);
          });
        getMigrationTableDataByIdApi(migrationId, tableName, 0, recordIds.length, [
          {
            field: 'Id',
            operator: 'in',
            value: `(${recordIds.map((id) => `'${id}'`).join(',')})`,
          },
        ])
          .then((res) => {
            if (res && res.length > 0) {
              setRecords(res);
            } else {
              setLoading(false);
              setError('Error records not found please try again later');
            }
          })
          .catch(() => {
            setError('Unexpected error while retrieving records please try again later');
            setLoading(false);
          });
      }
      if (errorSet) {
        setLoading(false);
      } else {
        setLoading(true);
        setLoadingMessage('Retrieving records data...');
      }
    }
  }, [migrationId, recordsToMerge]);

  useEffect(() => {
    if (
      _.isEmpty(error) &&
      duplicationResult.length > 0 &&
      records.length > 0 &&
      !_.isEmpty(migrationId) &&
      recordsToMerge
    ) {
      const recordDataKey = `${migrationId}-User`;
      const missingKeys: string[] = [];
      if (!tableRecords[recordDataKey] || tableRecords[recordDataKey]?.loading === false) {
        records.forEach((record) => {
          USER_REFERENCING_FIELD.forEach((key) => {
            if (!tableRecords[recordDataKey]?.data[record[key]] && record[key]) {
              missingKeys.push(`'${record[key]}'`);
            }
          });
        });

        if (missingKeys.length > 0) {
          if (!_.isEmpty(migrationId)) {
            getMigrationTableRecord({
              migrationId,
              tableId: 'User',
              skip: 0,
              take: 0,
              conditions: [
                {
                  field: 'Id',
                  operator: 'in',
                  value: `(${missingKeys.join(',')})`,
                  type: RuleComponentType.ROOT_CONDITIONAL,
                },
              ],
            });
          }
        } else {
          const referencedUserTmp: any = {};
          records.forEach((record) => {
            USER_REFERENCING_FIELD.forEach((key) => {
              if (tableRecords[recordDataKey]?.data[record[key]]) {
                referencedUserTmp[record[key]] = tableRecords[recordDataKey]?.data[record[key]];
              }
            });
          });
          setIdReferencesMap(referencedUserTmp);
        }
      }
    }
  }, [duplicationResult.length, error, getMigrationTableRecord, migrationId, records, recordsToMerge, tableRecords]);

  useEffect(() => {
    if (
      _.isEmpty(error) &&
      duplicationResult.length > 0 &&
      records.length > 0 &&
      !_.isEmpty(migrationId) &&
      recordsToMerge
    ) {
      const recordsIds = records.map((record) => record.Id);
      const aiRecommendationKey = recordsIds.sort().join('-');
      let existsAiRecommendation = true;
      duplicationResult.forEach((result) => {
        if (!result.ai_recommendation || !result.ai_recommendation[aiRecommendationKey]) {
          existsAiRecommendation = false;
        }
      });
      const masterRecordIds = getMasterRecordIds(duplicationResult);
      const masterRecordId = masterRecordIds[0] || recordsIds[0];
      setMasterRecordId(masterRecordId);
      const masterRecord = records.find((record) => record.Id === masterRecordId);
      const otherRecords = records.filter((record) => record.Id !== masterRecordId);
      const otherRecordsIds = otherRecords.map((record) => record.Id);
      setDuplicateRecordIds(otherRecordsIds);
      if (existsAiRecommendation) {
        const recommendation = duplicationResult[0].ai_recommendation[aiRecommendationKey];
        console.log('Assigning existing AI recommendation', recommendation);
        setAIRecommendation(recommendation);
        setLoadingAiRecommendation(false);
      } else {
        console.log('Getting new AI recommendation');
        setLoadingAiRecommendation(true);
        getSmartMergeApi(migrationId, { ids: recordsIds, tableName: recordsToMerge[0].tableName })
          .then((res) => {
            if (res) {
              setAIRecommendation(res.aiSmartMerge);
            } else {
              setAIRecommendation({});
            }
          })
          .catch((err: any) => {
            console.log(err);
            setAIRecommendation({});
          })
          .finally(() => {
            setLoadingAiRecommendation(false);
          });
      }
      setRenderRecords([masterRecord, ...otherRecords].filter((record) => record));
      setLoading(false);
    }
  }, [duplicationResult, error, migrationId, records, recordsToMerge]);

  const fieldNames = useMemo(() => {
    const fieldNameSet = new Set<string>();
    records.forEach((record) => {
      Object.keys(record).forEach((key) => {
        if (key.length > 3 && key.slice(-3) === '__c') {
          return;
        }
        fieldNameSet.add(key);
      });
    });

    if (fieldNameSet.has('Id')) {
      fieldNameSet.delete('Id');
    }

    fieldNamesToOmit.forEach((fieldName) => {
      fieldNameSet.delete(fieldName);
    });

    if (fieldNamesToOmitByTableType[tableObjectType]) {
      fieldNamesToOmitByTableType[tableObjectType].forEach((fieldName: string) => {
        fieldNameSet.delete(fieldName);
      });
    }

    const fieldNames = Array.from(fieldNameSet);
    const prioritizedFieldNames: string[] = [];

    globalPrioritizedFieldNames.forEach((fieldName) => {
      if (fieldNames.includes(fieldName)) {
        prioritizedFieldNames.push(fieldName);
      }
    });

    const removeSameNullValues: string[] = [];

    for (const fieldName of fieldNames) {
      const values = records.map((record: any) => record[fieldName]);
      const isAllSameValue = new Set(values).size == 1;
      if (!isAllSameValue && !prioritizedFieldNames.includes(fieldName)) {
        prioritizedFieldNames.push(fieldName);
      }
      if (isAllSameValue && values[0] == null && !removeSameNullValues.includes(fieldName)) {
        removeSameNullValues.push(fieldName);
      }
    }

    removeSameNullValues.forEach((fieldName) => {
      const index = fieldNames.indexOf(fieldName);
      if (index > -1) {
        fieldNames.splice(index, 1);
      }
    });

    prioritizedFieldNames.forEach((fieldName) => {
      const index = fieldNames.indexOf(fieldName);
      if (index > -1) {
        fieldNames.splice(index, 1);
      }
    });

    return [...prioritizedFieldNames, ...fieldNames];
  }, [records, tableObjectType]);

  useEffect(() => {
    const selectedValues: any = {};
    fieldNames.forEach((fieldName) => {
      selectedValues[fieldName] = `${fieldName}-radio-value-smart`;
    });
    setFormState(selectedValues);
  }, [fieldNames]);

  const handleCloseMergeModal = useCallback(() => {
    setOpenMergeModal(false);
    cleanState();
  }, [cleanState, setOpenMergeModal]);

  const handleKeepRecords = useCallback(() => {
    if (recordsToMerge && recordsToMerge.length > 0) {
      const filteredDeduplicationResult = duplicationResult.filter((result) => {
        return recordsToMerge.some((record) => {
          return result.record_a_id === record.recordId || result.record_b_id === record.recordId;
        });
      });
      const deduplicationResultIds = filteredDeduplicationResult.map((result) => result.id);
      const newStatus = DeduplicationResultStatus.IGNORED;
      setLoading(true);
      setLoadingMessage('Updating record status...');
      updateDeduplicationStatusApi(migrationId, { resultIds: deduplicationResultIds, status: newStatus })
        .then(() => {
          handleCloseMergeModal();
        })
        .catch(() => {
          setError('Unexpected Error while updating records status please try again later');
        })
        .finally(() => setLoading(false));
    }
  }, [duplicationResult, handleCloseMergeModal, migrationId, recordsToMerge]);

  const handleMergeRecords = useCallback(() => {
    if (!_.isEmpty(migrationId) && masterRecordId && duplicateRecordIds.length > 0) {
      const overWriteValues: any = {};
      fieldNames.forEach((fieldName) => {
        if (FIELDS_TO_OMIT_ON_SUBMIT.includes(fieldName)) return;
        const values = records.map((record: any) => record[fieldName]);
        const isAllSameValue = new Set(values).size == 1;
        if (!isAllSameValue) return;
        const id = formState[fieldName];
        const input: any = document.querySelector(`#${id}`);
        if (!input) return;
        const value = input.value;
        overWriteValues[fieldName] = value;
      });
      setLoading(true);
      setLoadingMessage('Merging Records...');
      mergeRecordsApi(migrationId, { masterRecordId, duplicateRecordIds, overWriteValues, objectType: tableObjectType })
        .then(() => {
          handleCloseMergeModal();
        })
        .catch(() => {
          setError('There has been an unexpected error while merging records, please try again later');
        })
        .finally(() => setLoading(true));
    }
  }, [
    duplicateRecordIds,
    fieldNames,
    formState,
    handleCloseMergeModal,
    masterRecordId,
    migrationId,
    records,
    tableObjectType,
  ]);

  return (
    <Modal
      sx={{ p: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={openMergeModal}
      onClose={handleCloseMergeModal}
    >
      <Box
        sx={{
          maxHeight: '90%',
          maxWidth: '90%',
          width: '100%',
          backgroundColor: theme.palette.neutral.white,
          borderRadius: '4px',
          margin: 4,
        }}
      >
        {/* Title */}
        <Box sx={{ p: '1rem' }} pb={2} display={'flex'} justifyContent={'space-between'}>
          <Typography sx={{ fontWeight: 'bold' }} variant="h3">
            {`Merge Potential Duplicate ${tableObjectType?.length > 0 ? `${tableObjectType} ` : ''}Records`}
          </Typography>
          <IconButton onClick={handleCloseMergeModal}>
            <CrossIcon />
          </IconButton>
        </Box>
        {/* Records Table */}

        {loading == true && _.isEmpty(error) && (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress></CircularProgress>
            <Typography>{loadingMessage}</Typography>
          </Box>
        )}

        {error && (
          <Box sx={{ textAlign: 'center' }}>
            <Typography>{error}</Typography>
          </Box>
        )}

        {_.isEmpty(error) && !loading && (
          <Box
            display={'flex'}
            flexDirection={'column'}
            maxHeight={'70vh'}
            sx={{ overflowY: 'scroll', backgroundColor: theme.palette.neutral.lightGray }}
          >
            <Box sx={{ p: '1rem 2rem 1rem' }}>
              <Typography variant="labelBold14" mr={1}>
                Identified fields
              </Typography>
              <Typography color={theme.palette.neutral.n400} variant="labelRegular10">
                You can select the field manually or the AI recommendation
              </Typography>
            </Box>
            <Box sx={{ p: '1rem', backgroundColor: 'white', height: '100%' }}>
              <TableContainer component={Paper} sx={{ maxHeight: '55vh', overflowY: 'scroll' }}>
                <Table stickyHeader={true} sx={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow>
                      <MergeTableHeader
                        records={renderRecords}
                        deduplicationResult={duplicationResult}
                        loadingAiRecommendation={loadingAiRecommendation}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <MergeTableFields
                      records={renderRecords}
                      aiRecommendation={aiRecommendation}
                      fieldNames={fieldNames}
                      formState={formState}
                      setFormState={setFormState}
                      loadingAiRecommendation={loadingAiRecommendation}
                      idReferencesMap={idReferencesMap}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        )}

        {/* Footer */}
        <Box display={'flex'} justifyContent={'end'} flexWrap={'nowrap'} gap={1.5} sx={{ p: '1rem' }}>
          <Button variant="text" sx={{ color: 'black' }} onClick={handleCloseMergeModal}>
            Cancel
          </Button>
          <SecondaryButton onClick={handleKeepRecords} disabled={disableButtons}>
            Keep Records
          </SecondaryButton>
          <PrimaryButton disabled={disableButtons} onClick={handleMergeRecords}>
            Merge Records
          </PrimaryButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default MergeModal;
