import { Grid, MenuItem } from '@mui/material';
import { FC, useCallback } from 'react';

import { ItemSorter } from '../ItemSorter';
import { RuleFilter } from '../RuleFilter';

import { SubPageOption } from './components/SubPageOptions';
import ItemView from '../RuleLibraryList/components/ItemView';

import theme from 'core/theme';
import { Show } from 'components/show';

import { SubPages } from 'store/ruleLibrary/types';
import { useRuleLibrary } from 'store/ruleLibrary/hook';
import { useTemporalTableForm } from 'store/temporalTableForm/hooks';

import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { ReactComponent as VectorUpIcon } from 'assets/icons/vectorUp.svg';
import { ReactComponent as LibraryIcon } from 'assets/icons/libraryIcon.svg';

const optionIcons: Record<
  keyof SubPages,
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >
> = {
  library: LibraryIcon,
  template: VectorUpIcon,
  temporalTable: ClockIcon,
};

const SubPageList: FC = () => {
  const { subPages, selectedSubPage } = useRuleLibrary();
  const { setOpen: setTempTableModalOpen } = useTemporalTableForm();

  const getSubPageOptionButtons = useCallback(() => {
    return Object.keys(subPages).map((key) => {
      const optionKey = key as keyof SubPages;

      const option = subPages[optionKey];
      const OptionIcon = optionIcons[optionKey];

      return (
        <SubPageOption
          label={option.label}
          counter={option.counter}
          name={option.name as keyof SubPages}
          key={`sub-page-option-${option.name}`}
          renderIcon={(active) => (
            <OptionIcon
              width={16}
              height={16}
              stroke={active ? theme.palette.primary.main : theme.palette.primary.gray}
            />
          )}
          renderMenuItems={
            optionKey !== 'temporalTable'
              ? undefined
              : (closeMenu) => (
                  <MenuItem
                    onClick={() => {
                      setTempTableModalOpen(true);
                      closeMenu();
                    }}
                  >
                    Create Dataset
                  </MenuItem>
                )
          }
        />
      );
    });
  }, [subPages.library, subPages.template, subPages.temporalTable]);

  return (
    <Grid
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{ backgroundColor: 'neutral.white' }}
    >
      <Grid display={'flex'} sx={{ paddingLeft: '16px', pt: 1 }}>
        {getSubPageOptionButtons()}
      </Grid>
      <Grid display={'flex'} sx={{ paddingRight: '16px' }}>
        <Show when={selectedSubPage !== 'temporalTable'}>
          <ItemSorter />
          <RuleFilter />
        </Show>
        <Show when={selectedSubPage === 'library'}>
          <ItemView />
        </Show>
      </Grid>
    </Grid>
  );
};

export default SubPageList;
