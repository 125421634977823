import React from 'react';
import { Button, Divider, Stack, Typography, Menu, MenuItem } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

const EventFooter = () => {
  const [status, setStatus] = React.useState<string>('no');
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  return (
    <Stack sx={{ height: '30px' }} direction="row" alignItems="center" justifyContent="center" gap={0.5} py={0.5}>
      <Typography sx={{ mx: 2 }}>Going ?</Typography>

      <Button
        sx={{
          color: status === 'yes' ? 'primary.main' : 'common.black',
          backgroundColor: status === 'yes' ? 'primary.subtone2' : '#00000010',
          p: 1,
          borderRadius: '5px',
        }}
        onClick={() => setStatus('yes')}
      >
        <Typography variant="p14">Yes</Typography>
      </Button>
      <Button
        sx={{
          color: status === 'no' ? 'primary.main' : 'common.black',
          backgroundColor: status === 'no' ? 'primary.subtone2' : '#00000010',
          p: 1,
          borderRadius: '5px',
        }}
        onClick={() => setStatus('no')}
      >
        <Typography variant="p14">No</Typography>
      </Button>
      <Button
        sx={{
          color: status === 'maybe' ? 'primary.main' : 'common.black',
          backgroundColor: status === 'maybe' ? 'primary.subtone2' : '#00000010',
          p: 1,
          borderRadius: '5px',
        }}
        onClick={() => setStatus('maybe')}
      >
        <Typography variant="p14">Maybe</Typography>
      </Button>
      <Divider orientation="vertical" />

      <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
        sx={{ cursor: 'pointer', mx: 1 }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <DragIndicatorIcon sx={{ color: 'primary.main', rotate: '90deg' }} />
        <Typography variant="p14" color="primary.main">
          More
        </Typography>
      </Stack>

      <Menu open={!!anchorEl} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        <MenuItem>
          <Stack direction="row" alignItems="center" gap={1}>
            <EditRoundedIcon sx={{ fontSize: '14px' }} />
            <Typography variant="p12">Edit</Typography>
          </Stack>
        </MenuItem>
        <MenuItem>
          <Stack direction="row" alignItems="center" gap={1}>
            <DeleteOutlineRoundedIcon sx={{ fontSize: '14px' }} />
            <Typography variant="p12">Delete</Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </Stack>
  );
};

export default EventFooter;
