import { useEffect, useRef, useState } from 'react';
import { Box, Chip, Link, Tooltip } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { faker } from '@faker-js/faker';

import AddIcon from '@mui/icons-material/Add';

import { ColumnSortedAscendingIcon, ColumnSortedDescendingIcon, ColumnUnsortedIcon } from 'pages/DataRaptor/ui';

import AISuggestedDrawer from './AISuggestedDrawer';

const dataRows = Array.from({ length: 23 }, (_, index) => ({
  id: index + 1,
  name: 'Duplicate Contact',
  category: 'Duplicate Detection',
  section: 'Marketing',
  impact: faker.number.int({ min: -100, max: 100 }),
}));

export interface AISuggestedTableData {
  id: number;
  name: string;
  category: string;
  section: string;
  impact: number;
}

function getColor(minValue: number, mediunValue: number, maxValue: number, value: number) {
  if (minValue <= value && mediunValue > value) {
    return '#FB4E6D';
  } else if (mediunValue <= value && maxValue > value) {
    return '#FF9960';
  } else if (maxValue <= value) {
    return '#60C67C';
  }

  return 'white';
}

const getActiveRulesColumns: (
  width: number,
  toggleDrawerData: (name: string, category: string, section: string, impact: number) => void,
) => GridColumns<AISuggestedTableData> = (width, toggleDrawerData) => [
  {
    width: 0.3 * width,
    field: 'Name',
    renderCell: ({ row }) => (
      <Box gap={1} display="flex" justifyContent="space-between" alignItems="center">
        <AddIcon />
        <Tooltip title={'View Rule'}>
          <Link
            onClick={() => toggleDrawerData(row.name, row.category, row.section, row.impact)}
            fontSize={14}
            underline="none"
          >
            {row.name}
          </Link>
        </Tooltip>
      </Box>
    ),
  },
  {
    align: 'right',
    field: 'Category',
    width: 0.3 * width,
    renderCell: ({ row }) => (
      <Chip
        size="small"
        label={row.category}
        sx={{
          color: 'white',
          borderRadius: '4px',
          backgroundColor: '#FF9960',
        }}
      />
    ),
  },
  {
    align: 'right',
    field: 'Section',
    width: 0.25 * width,
    renderCell: ({ row }) => (
      <Chip
        size="small"
        label={row.section}
        sx={{
          borderRadius: '4px',
          backgroundColor: '#DFF4E5',
        }}
      />
    ),
  },
  {
    align: 'right',
    field: 'Impact',
    width: 0.15 * width,
    renderCell: ({ row }) => (
      <Chip
        size="small"
        label={row.impact}
        sx={{
          color: 'white',
          backgroundColor: getColor(-100, 0, 50, row.impact),
        }}
      />
    ),
  },
];

const pageSize = 3;

export default function AISuggestedTable() {
  const ref = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({ name: '', category: '', section: '', impact: 0 });

  const toggleDrawerData = (name: string, category: string, section: string, impact: number) => (
    setDrawerData({ name, category, section, impact }), setOpen(!open)
  );

  const toggleOpen = () => setOpen((prevState) => !prevState);

  const [columns, setColumns] = useState(getActiveRulesColumns(0, toggleDrawerData));
  const handleResize = () => {
    const width = ref.current?.clientWidth ?? 100;
    setColumns(getActiveRulesColumns(width, toggleDrawerData));
  };

  useEffect(() => {
    handleResize();
    ref.current?.addEventListener('resize', handleResize);
    return () => {
      ref.current?.removeEventListener('resize', handleResize);
    };
  }, [ref.current]);

  return (
    <Box ref={ref} width={'100%'} height={'100%'}>
      <DataGrid
        disableColumnFilter
        disableColumnSelector
        disableSelectionOnClick
        rows={dataRows}
        columns={[...columns]}
        rowHeight={56}
        headerHeight={0}
        rowsPerPageOptions={[pageSize]}
        pageSize={pageSize}
        getRowId={(r) => r.id}
        density="comfortable"
        components={{
          ColumnUnsortedIcon,
          ColumnSortedAscendingIcon,
          ColumnSortedDescendingIcon,
        }}
      />
      <AISuggestedDrawer open={open} drawerData={drawerData} toggleOpen={toggleOpen} />
    </Box>
  );
}
