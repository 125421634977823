import { useState, useEffect } from 'react';
import { Card, CardContent, Box, Typography, Checkbox } from '@mui/material';
import { isUndefined } from 'lodash';
import BusinessIcon from '@mui/icons-material/Business';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import WorkIcon from '@mui/icons-material/Work';
import ContactsIcon from '@mui/icons-material/Contacts';
import HexagonIcon from '@mui/icons-material/Hexagon';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

interface ObjectSelectionProps {
  onSelectionChange: React.Dispatch<
    React.SetStateAction<{
      records: string[];
      activities: string[];
    }>
  >;
}

export const ObjectSelection = ({ onSelectionChange }: ObjectSelectionProps) => {
  const [selectedItems, setSelectedItems] = useState<{ records: string[]; activities: string[] }>({
    records: [],
    activities: [],
  });

  useEffect(() => {
    onSelectionChange(selectedItems);
  }, [selectedItems, onSelectionChange]);

  const records = [
    {
      title: 'Accounts',
      icon: <BusinessIcon fontSize="large" color="primary" />,
      subtitle: 'Businesses you work with',
    },
    { title: 'Contacts', icon: <ContactsIcon fontSize="large" color="primary" />, subtitle: 'People you work with' },
    { title: 'Leads', icon: <LightbulbIcon fontSize="large" color="primary" />, subtitle: 'Prospects' },
    { title: 'Opportunities', icon: <WorkIcon fontSize="large" color="primary" />, subtitle: 'Potential deals' },
    { title: 'Cases', icon: <HexagonIcon fontSize="large" color="primary" />, subtitle: 'Issues created by customers' },
  ];

  const activities = [
    { title: 'Meetings', icon: <VideocamOutlinedIcon fontSize="large" color="primary" /> },
    { title: 'Tasks', icon: <CheckBoxOutlinedIcon fontSize="large" color="primary" /> },
    { title: 'Calls', icon: <LocalPhoneOutlinedIcon fontSize="large" color="primary" /> },
    { title: 'Emails', icon: <EmailOutlinedIcon fontSize="large" color="primary" /> },
    { title: 'Notes', icon: <ChatBubbleOutlineOutlinedIcon fontSize="large" color="primary" /> },
  ];

  const handleSelect = (type: 'records' | 'activities', title: string) => {
    setSelectedItems((prevSelected) => {
      const updatedSelection = prevSelected[type].includes(title)
        ? prevSelected[type].filter((item) => item !== title)
        : [...prevSelected[type], title];
      return { ...prevSelected, [type]: updatedSelection };
    });
  };

  const renderCard = (type: 'records' | 'activities', title: string, icon: JSX.Element, subtitle?: string) => {
    const isSelected = selectedItems[type].includes(title);
    return (
      <Card
        sx={{
          width: '211px',
          height: '120px',
          textAlign: 'center',
          position: 'relative',
          border: isSelected ? '2px solid blue' : '2px solid transparent',
        }}
        key={title}
        onClick={() => handleSelect(type, title)}
      >
        <Checkbox checked={isSelected} sx={{ position: 'absolute', top: 8, right: 8 }} />
        <CardContent sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>{icon}</Box>
          <Typography variant="labelMedium14" gutterBottom>
            {title}
          </Typography>
          {!isUndefined(subtitle) && (
            <Typography variant="labelMedium12" color="neutral.n400" gutterBottom>
              {subtitle}
            </Typography>
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <Box sx={{ mb: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h2" mt={4} mb={2} textAlign="center">
        Select the record type you&apos;d like to import
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
        {records.map((record) => renderCard('records', record.title, record.icon, record.subtitle))}
      </Box>
      <Typography variant="h2" mt={4} mb={2} textAlign="center">
        Activities
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
        {activities.map((activity) => renderCard('activities', activity.title, activity.icon))}
      </Box>
    </Box>
  );
};
