import { FC, useState } from 'react';

import { Box, Chip, IconButton, Menu, Typography } from '@mui/material';

import { SubPages } from 'store/ruleLibrary/types';
import { useRuleLibrary } from 'store/ruleLibrary/hook';

import theme from 'core/theme';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export interface SubPageOptionProps {
  label: string;
  counter: number;
  name: keyof SubPages;
  renderIcon: (active: boolean) => JSX.Element;
  renderMenuItems?: (closeMenu: () => void) => React.ReactNode;
}

const SubPageOption: FC<SubPageOptionProps> = ({ name, label, counter, renderIcon, renderMenuItems }) => {
  const [hover, setHover] = useState(false);
  const { selectedSubPage, setSelectedSubPage } = useRuleLibrary();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const active = selectedSubPage === name;
  const closeMenu = () => setAnchorEl(null);

  return (
    <Box
      flex={1}
      padding={'0px 12px'}
      maxWidth={'250px'}
      sx={{
        ':hover': {
          cursor: 'pointer',
        },
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => setSelectedSubPage(name)}
    >
      <Box
        height={40}
        width={'100%'}
        borderRadius={1}
        display={'flex'}
        bgcolor={'white'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Box
            width={24}
            height={24}
            borderRadius={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            bgcolor={active ? theme.palette.primary.subtone2 : theme.palette.lightBg.main}
          >
            {renderIcon(active)}
          </Box>
          <Typography
            width={75}
            variant="caption"
            color={active ? theme.palette.primary.main : theme.palette.primary.gray}
          >
            {label}
          </Typography>
        </Box>
        <Chip
          size="small"
          sx={{
            padding: 0,
            backgroundColor: active ? theme.palette.primary.subtone2 : theme.palette.neutral.lightGray,
          }}
          label={
            <Typography
              variant="p12"
              fontSize={12}
              color={active ? theme.palette.primary.main : theme.palette.neutral.darkGray}
            >
              {counter}
            </Typography>
          }
        />
        {!!renderMenuItems && (
          <>
            <IconButton disabled={!active} onClick={(e) => setAnchorEl(e.currentTarget)}>
              <KeyboardArrowDownIcon fontSize="small" />
            </IconButton>
            <Menu elevation={1} open={!!anchorEl} anchorEl={anchorEl} onClose={closeMenu}>
              {renderMenuItems(closeMenu)}
            </Menu>
          </>
        )}
      </Box>
      <Box
        width={'100%'}
        height={'4px'}
        bgcolor={'primary.main'}
        display={hover || active ? 'block' : 'none'}
        sx={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}
      />
    </Box>
  );
};

export { SubPageOption };
