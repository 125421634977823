import { DeduplicationResult } from 'store/deduplicationResult/types';
import { RecordReference } from 'store/graphRender/types';

export const getDuplicateRecordReferences = (duplicationResult: DeduplicationResult[]) => {
  const recordsToMergePayload: RecordReference[] = [];
  duplicationResult.forEach((result) => {
    recordsToMergePayload.push({ recordId: result.record_a_id, tableName: result.record_a_table });
    recordsToMergePayload.push({ recordId: result.record_b_id, tableName: result.record_b_table });
  });
  return recordsToMergePayload;
};
