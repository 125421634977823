import { FC, useState } from 'react';
import { Typography, Button, Box, TextField } from '@mui/material';
import CustomDialog from 'components/CustomDialog/CustomDialog';

interface NewFieldModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: (objectField: string) => void;
}

const NewFieldMappingModal: FC<NewFieldModalProps> = ({ open, onClose, onCreate }) => {
  const [objectField, setObjectField] = useState('');

  const handleCreate = () => {
    onCreate(objectField);
    setObjectField('');
    onClose();
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title="New Field Mapping"
      actions={
        <>
          <Button onClick={onClose} color="inherit">
            Cancel
          </Button>
          <Button variant="contained" sx={{ backgroundColor: 'primary.main' }} onClick={handleCreate}>
            Create Field Mapping
          </Button>
        </>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography>Object Field</Typography>
        <TextField value={objectField} onChange={(e) => setObjectField(e.target.value)} fullWidth />
      </Box>
    </CustomDialog>
  );
};

export default NewFieldMappingModal;
