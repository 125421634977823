import { apiCall } from '../index';

import { RuleTemporalTable } from 'store/dataRaptorRule/types';
import { RuleWhereArray } from 'store/dataRaptorRule/dto/front-end-rule.dto';

export interface postRuleTemporalTableParams {
  table: string;
  name: string;
  definition: { where: RuleWhereArray };
  migrationId: string;
}

export const postRuleTemporalTable = ({ migrationId, ...body }: postRuleTemporalTableParams): Promise<void> => {
  return apiCall({
    method: 'post',
    url: `/ruleTempTable/migration/${migrationId}`,
    data: body,
  });
};

export const putRuleTemporalTable = (
  tableId: string,
  { migrationId, ...data }: postRuleTemporalTableParams,
): Promise<void> => {
  return apiCall({
    method: 'PUT',
    url: `/ruleTempTable/migration/${migrationId}/table/${tableId}`,
    data,
  });
};

export const getRuleTemporalTables = ({ migrationId }: { migrationId: string }): Promise<RuleTemporalTable[]> =>
  apiCall({
    method: 'get',
    url: `/ruleTempTable/migration/${migrationId}`,
  });

export const getTemporalTableCheckNameAvailability = ({
  migrationId,
  name,
}: {
  migrationId: string;
  name: string;
}): Promise<{ available: boolean }> =>
  apiCall({
    method: 'get',
    url: `/ruleTempTable/migration/${migrationId}/checkName/${name}`,
  });

export const deleteRuleTemporalTable = (migrationId: string, tableId: string): Promise<any> => {
  return apiCall({
    method: 'delete',
    url: `/ruleTempTable/migration/${migrationId}/table/${tableId}`,
  });
};
