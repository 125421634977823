import { Box, Grid, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import { ConfidenceScore } from 'components/ConfidenceScore';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as ContactIcon } from 'assets/icons/graph/contact.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { FC, useCallback, useMemo, useState } from 'react';
import theme from 'core/theme';

const customGray = '#F5F5F5';

const buttonHovered = {
  borderColor: 'primary.main',
  cursor: 'pointer',
  '& .innerIcon': {
    fill: theme.palette.primary.main,
  },
};

export const Header: FC<any> = (props) => {
  const { node, confidence_score, toggleOpen } = props;
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const nodeData = node.data;
  const nodeName = useMemo(() => {
    if (nodeData && nodeData.Name) {
      return nodeData.Name;
    }
    return nodeData.FirstName + ' ' + nodeData.LastName;
  }, [nodeData]);

  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  return (
    <>
      <Grid container display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} justifyContent={'end'}>
          <IconButton sx={{ textAlign: 'left', px: 0 }} onClick={() => toggleOpen(false)}>
            <CrossIcon />
          </IconButton>
        </Box>

        <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Grid xs={10} container display={'flex'} gap={2}>
            <Grid
              container
              position={'relative'}
              flex={1}
              maxWidth={'80px'}
              display={'flex'}
              width={'80px'}
              height={'80px'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{ backgroundColor: customGray, borderRadius: '100%' }}
            >
              <Box
                width={'24px'}
                height={'24px'}
                sx={{
                  borderRadius: '100%',
                  border: `1px ${theme.palette.neutral.lightGray} solid`,
                  backgroundColor: theme.palette.neutral.white,
                  ':hover': { ...buttonHovered },
                }}
                position={'absolute'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                right={-1}
                bottom={-1}
              >
                <EditIcon className="innerIcon" />
              </Box>
              <ContactIcon style={{ transform: 'scale(2)', stroke: theme.palette.neutral.darkGray }} />
            </Grid>

            <Grid container flex={1} display={'flex'} flexDirection={'column'} gap={0.4}>
              <Typography variant="h3">{nodeName}</Typography>
              <Typography variant="labelRegular10" sx={{ color: 'neutral.n400' }}>
                CONFIDENCE SCORE
              </Typography>
              <ConfidenceScore confidenceScore={confidence_score} />
            </Grid>
          </Grid>

          <Grid
            xs={2}
            container
            display={'flex'}
            flexDirection={'column'}
            alignContent={'flex-end'}
            justifyContent={'space-around'}
            gap={1}
          >
            <IconButton
              sx={{
                borderRadius: '4px',
                border: `1px ${theme.palette.neutral.lightGray} solid`,
                ':hover': { ...buttonHovered },
                width: '32px',
                height: '32px',
              }}
            >
              <PhoneIcon
                className="innerIcon"
                fill={theme.palette.neutral.darkGray}
                stroke={'none'}
                transform="scale(1.2)"
              />
            </IconButton>
            <IconButton
              component="div"
              sx={{
                borderRadius: '4px',
                border: `1px ${theme.palette.neutral.lightGray} solid`,
                ':hover': { ...buttonHovered },
                width: '32px',
                height: '32px',
              }}
              onClick={handleOpenMenu}
            >
              <DotsIcon className="innerIcon" transform="scale(1.2)" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        title="More options"
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem disabled>More options:</MenuItem>
        <MenuItem>Send Email</MenuItem>
        <MenuItem>Schedule Meeting</MenuItem>
        <MenuItem>Request LinkedIn Connection</MenuItem>
      </Menu>
    </>
  );
};
