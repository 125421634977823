import { SecondaryButton } from 'components/ui';
import { ReactComponent as SortIcon } from 'assets/icons/graph/sort.svg';
import { useGraphRender } from 'store/graphRender/hooks';
import { useCallback } from 'react';
import { GraphRenderView } from 'store/graphRender/types';

const LeadViewSortButton = () => {
  const {
    data: { view: graphRenderView },
    leadViewReducers: { setLeadViewSortPanelShow },
  } = useGraphRender();

  const openLeadSortPanel = useCallback(() => {
    setLeadViewSortPanelShow(true);
  }, [setLeadViewSortPanelShow]);

  if (graphRenderView !== GraphRenderView.LEADS) {
    return null;
  }

  return (
    <>
      <SecondaryButton onClick={openLeadSortPanel} startIcon={<SortIcon />}>
        Sort
      </SecondaryButton>
    </>
  );
};

export default LeadViewSortButton;
