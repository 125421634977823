import { GridItem } from 'components/ui';
import { DeleteComponentButton } from '../shared/DeleteComponentButton';
import { RootCondition } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { FC } from 'react';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { ConditionType } from 'pages/RuleLibrary/types';
import { ConditionalValueInput } from '../shared/ConditionalValueInput/ConditionalValueInput';
import { FUNCTION_ENUM } from '../shared/Constants';
import { OperatorDropdown } from '../shared/OperatorDropdown';
import { Box } from '@mui/material';
import theme from 'core/theme';
import { VerticalDivider } from 'components/VerticalDivider';

export interface HavingRecordFormProps {
  index: number;
  condition: RootCondition;
}

const HavingRecordForm: FC<HavingRecordFormProps> = (props) => {
  const { index, condition } = props;

  const { having, setHaving, selectedTable, fieldOptions } = useRuleForm();
  const allowedFunctions = [FUNCTION_ENUM.COUNT, FUNCTION_ENUM.MAX, FUNCTION_ENUM.MIN, FUNCTION_ENUM.SUM];

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      position={'relative'}
      gap={0.5}
      sx={{
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
        backgroundColor: theme.palette.lightBg.main,
        p: 1,
      }}
    >
      <GridItem container display={'flex'} alignItems={'center'} xs={12}>
        <GridItem width={'100%'}>
          <ConditionalValueInput
            targetPath={`having[${index}].field`}
            rootConditionalPath={`having[${index}]`}
            conditionalValueComponent={condition.field}
            conditionType={ConditionType.HAVING}
            index={index}
            allowedValueTypes={[RuleComponentType.FUNCTION_VALUE, RuleComponentType.PRIMITIVE_VALUE]}
            allowedValueFunctions={allowedFunctions}
            fieldOptions={fieldOptions || []}
            tableName={selectedTable}
          />
        </GridItem>
      </GridItem>

      <VerticalDivider height="20px" sx={{ marginLeft: 2 }} />

      <GridItem xs={4}>
        <OperatorDropdown
          conditionType={ConditionType.HAVING}
          operator={condition?.operator || null}
          condition={having[index] as RootCondition}
          targetPath={`having[${index}].operator`}
          tableName={selectedTable}
        />
      </GridItem>

      <VerticalDivider height="20px" sx={{ marginLeft: 2 }} />

      <GridItem container display={'flex'} alignItems={'center'} xs={12}>
        <GridItem width={'100%'}>
          <ConditionalValueInput
            targetPath={`having[${index}].value`}
            rootConditionalPath={`having[${index}]`}
            conditionalValueComponent={condition.value}
            conditionType={ConditionType.HAVING}
            index={index}
            allowedValueTypes={[RuleComponentType.FUNCTION_VALUE, RuleComponentType.PRIMITIVE_VALUE]}
            allowedValueFunctions={allowedFunctions}
            fieldOptions={fieldOptions || []}
            tableName={selectedTable}
          />
        </GridItem>
      </GridItem>
      <Box sx={{ position: 'absolute', top: '-12px', right: '-10px' }}>
        <DeleteComponentButton index={index} where={having} setWhere={setHaving} />
      </Box>
    </Box>
  );
};

export { HavingRecordForm };
