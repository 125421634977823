import React, { FC, useEffect } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { GridItem } from 'components/ui';
import { CustomInput } from 'components/CustomInput';

import { RuleFormMode } from '../../../RuleLibrary/types';
import { CustomDropdown } from 'components/CustomDropdown';

import { RuleCategory } from 'store/dataRaptorRule/types';
import { useDataRaptorRule } from 'store/dataRaptorRule/hooks';

import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { getEmptyRootConditional } from 'pages/RuleForm/utils';

interface RuleTypeInterface {
  [key: string]: any;
}

const RuleType: FC<RuleTypeInterface> = (props: RuleTypeInterface) => {
  const {
    ruleName,
    violationScore,
    description,
    category,
    riskLevel,
    department,
    formError,
    ruleDepartments,
    ruleRisks,
    ruleTypes,
    setWhere,
    setRuleName,
    setViolationScore,
    setDescription,
    setCategory,
    setRiskLevel,
    setDepartment,
  } = useRuleForm();
  const {
    data: { formMode },
  } = useDataRaptorRule();

  useEffect(() => {
    if (ruleTypes && ruleTypes.length > 0 && !category) {
      setCategory(ruleTypes[0].name as RuleCategory);
    }
    if (ruleDepartments && ruleDepartments.length > 0 && !department) {
      setDepartment(ruleDepartments[0].name);
    }
    if (ruleRisks && ruleRisks.length > 0 && !riskLevel) {
      setRiskLevel(ruleRisks[0].name);
    }
  }, [
    category,
    department,
    riskLevel,
    ruleDepartments,
    ruleRisks,
    ruleTypes,
    setCategory,
    setDepartment,
    setRiskLevel,
  ]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.id === 'ruleName') {
      setRuleName(e.target.value);
    }
    if (e.target.id === 'violationScore') {
      setViolationScore(Number(e.target.value as unknown));
    }
    if (e.target.id === 'description') {
      setDescription(e.target.value);
    }
  };

  return (
    <>
      <Box
        {...props}
        sx={{
          m: 2.5,
          bgcolor: 'white',
          borderRadius: 1,
          p: 3,
          display: 'flex',
          flex: 3,
        }}
      >
        <Box flex={2} mr={3}>
          <Grid container display="flex" justifyContent="flex-start">
            <GridItem item xs={12} sx={{ rowGap: '2px' }}>
              <CustomInput
                id="ruleName"
                name="ruleName"
                label="Rule name"
                labelSx={{ fontWeight: 'bold' }}
                placeholder="Full Name Rule"
                fullWidth
                value={ruleName}
                onChange={handleChange}
              />
              {!!formError.ruleName && (
                <Typography variant="caption" sx={{ color: 'red.main' }}>
                  {formError.ruleName}
                </Typography>
              )}
            </GridItem>
          </Grid>
          <Grid container display="flex" mt={2}>
            <GridItem item xs={3} sx={{ rowGap: '2px' }} paddingRight={1}>
              <CustomInput
                id="violationScore"
                name="violationScore"
                label="Violation Score"
                labelSx={{ fontWeight: 'bold' }}
                placeholder="1-100"
                fullWidth
                type="number"
                value={violationScore}
                onChange={handleChange}
              />
              {!!formError.violationScore && (
                <Typography variant="caption" sx={{ color: 'red.main' }}>
                  {formError.violationScore}
                </Typography>
              )}
            </GridItem>
            <GridItem item xs={3} paddingX={1}>
              {category && category.length > 0 && ruleTypes && (
                <>
                  <CustomDropdown<string>
                    id="category"
                    placeholder="Rule Category"
                    disabled={formMode !== RuleFormMode.CREATE}
                    label="Rule Type"
                    labelSx={{ fontWeight: 'bold' }}
                    value={category}
                    options={ruleTypes.map((item) => ({
                      label: item.label,
                      value: item.name,
                    }))}
                    sx={{ backgroundColor: 'neutral.white', width: '100%' }}
                    onSelect={async (value) => {
                      setCategory(value as RuleCategory);
                      setWhere({ value: [getEmptyRootConditional(value as RuleCategory)] });
                    }}
                  />

                  {!!formError.category && (
                    <Typography variant="caption" sx={{ color: 'red.main' }}>
                      {formError.category}
                    </Typography>
                  )}
                </>
              )}
            </GridItem>
            <GridItem item xs={3} paddingX={1}>
              {riskLevel && riskLevel.length > 0 && ruleRisks && (
                <>
                  <CustomDropdown<string>
                    id="riskLevel"
                    label="Risk Level"
                    labelSx={{ fontWeight: 'bold' }}
                    placeholder="Rule RiskLevel"
                    value={riskLevel}
                    options={ruleRisks.map((item) => ({
                      label: item.label,
                      value: item.name,
                    }))}
                    sx={{ backgroundColor: 'neutral.white', width: '100%' }}
                    onSelect={async (value) => {
                      setRiskLevel(value);
                    }}
                  />
                  {!!formError.riskLevel && (
                    <Typography variant="caption" sx={{ color: 'red.main' }}>
                      {formError.riskLevel}
                    </Typography>
                  )}
                </>
              )}
            </GridItem>
            <GridItem item xs={3} paddingLeft={1}>
              {department && department.length > 0 && ruleDepartments && (
                <>
                  <CustomDropdown<string>
                    id="department"
                    label="Department"
                    labelSx={{ fontWeight: 'bold' }}
                    placeholder="Department"
                    value={department}
                    options={ruleDepartments.map((item) => ({
                      label: item.label,
                      value: item.name,
                    }))}
                    sx={{ backgroundColor: 'neutral.white', width: '100%' }}
                    onSelect={async (value) => {
                      setDepartment(value);
                    }}
                  />
                  {!!formError.department && (
                    <Typography variant="caption" sx={{ color: 'red.main' }}>
                      {formError.department}
                    </Typography>
                  )}
                </>
              )}
            </GridItem>
          </Grid>
        </Box>
        <Box flex={1}>
          <Grid>
            <GridItem item xs={6} sx={{ rowGap: '2px' }}>
              <Typography variant="p12" color="neutral.main" mb={1} fontWeight={700} sx={{ opacity: 0.6 }}>
                Rule Description
              </Typography>
              <textarea
                id="description"
                name="description"
                placeholder="Type rule description here..."
                style={{
                  resize: 'none',
                  height: 128,
                  borderColor: '#E1E5ED',
                  padding: 8,
                }}
                value={description || ''}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  setDescription(e.target.value);
                }}
              />
              {!!formError.description && (
                <Typography variant="caption" sx={{ color: 'red.main' }}>
                  {formError.description}
                </Typography>
              )}
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default RuleType;
