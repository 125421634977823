import { PostProcessingInterface } from './PostProcessing.Interface';

export class HideEventRelatedPostProcessing implements PostProcessingInterface {
  static nodeFilterFn = (node: any, id: string, nodeHashMap: { [key: string]: any }) => {
    if (node.data?.label === 'Event' || node.data?.label === 'Task') {
      return true;
    }
    return false;
  };

  static nodePostProcessingFn = (node: any, id: string, nodeHashMap: { [key: string]: any }) => {
    nodeHashMap[id].hidden = true;
  };

  static edgeFilterFn = (
    edge: any,
    id: string,
    edgeHashMap: { [key: string]: any },
    nodeHashMap: { [key: string]: any },
  ) => {
    if (
      nodeHashMap[edge.source]?.data?.label === 'Event' ||
      nodeHashMap[edge.target]?.data?.label === 'Event' ||
      nodeHashMap[edge.source]?.data?.label === 'Task' ||
      nodeHashMap[edge.target]?.data?.label === 'Task' ||
      nodeHashMap[edge.source]?.hidden ||
      nodeHashMap[edge.target]?.hidden
    ) {
      return true;
    }
    return false;
  };

  static edgePostProcessingFn = (
    edge: any,
    id: string,
    edgeHashMap: { [key: string]: any },
    nodeHashMap: { [key: string]: any },
  ) => {
    edgeHashMap[id].hidden = true;
  };
}
