import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getDataByVertexId as getDataByVertexIdApi,
  getDataByLabel as getDataByLabelApi,
  getMigrationStatus as getMigrationStatusApi,
  getDataByIds as getDataByIdsApi,
} from 'http/graphData';
import {
  GraphDataRecord,
  GetDataByLabelParams,
  GetDataByVertexIdParams,
  GraphPathRecord,
  GetMigrationStatusParams,
  MigrationStatus,
  GetDataByIdsParams,
} from './types';

const SET_ERROR = 'graphData/SET_ERROR';
const SET_SUCCESS = 'graphData/SET_SUCCESS';
const SET_LOADING = 'graphData/SET_LOADING';
const GET_DATA_BY_VERTEX_ID = 'graphData/GET_DATA_BY_VERTEX_ID';
const GET_DATA_BY_VERTEX_ID_ARRAY = 'graphData/GET_DATA_BY_VERTEX_ID_ARRAY';
const GET_DATA_BY_LABEL = 'graphData/GET_DATA_BY_LABEL';
const GET_MIGRATION_STATUS = 'graphData/GET_MIGRATION_STATUS';
const CLEAN_STATE = 'graphData/CLEAN_STATE';

export const setError = createAction<string | boolean>(SET_ERROR);

export const setSuccess = createAction<boolean>(SET_SUCCESS);

export const setLoading = createAction<boolean>(SET_LOADING);

export const cleanState = createAction(CLEAN_STATE);

export const getDataByVertexId = createAsyncThunk<GraphDataRecord, GetDataByVertexIdParams>(
  GET_DATA_BY_VERTEX_ID,
  async ({ migrationId, vertexId, onError, onSuccess }) => {
    try {
      const res = await getDataByVertexIdApi(migrationId, vertexId);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const getDataByVertexIdArray = createAsyncThunk<GraphPathRecord, GetDataByIdsParams>(
  GET_DATA_BY_VERTEX_ID_ARRAY,
  async ({ migrationId, accountIdArray, individualNodeIdArray, filter, onError, onSuccess }) => {
    try {
      const res = await getDataByIdsApi(migrationId, accountIdArray, individualNodeIdArray, filter);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const getMigrationStatus = createAsyncThunk<MigrationStatus, GetMigrationStatusParams>(
  GET_MIGRATION_STATUS,
  async ({ migrationId, onError, onSuccess }) => {
    try {
      const res = await getMigrationStatusApi(migrationId);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const getDataByLabel = createAsyncThunk<GraphPathRecord, GetDataByLabelParams>(
  GET_DATA_BY_LABEL,
  async ({ migrationId, label, options, filter, onError, onSuccess }) => {
    try {
      const res = await getDataByLabelApi(migrationId, label, options, filter);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);
