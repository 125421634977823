import { apiCall } from '../index';
import {
  GraphDataRecord,
  GraphFilter,
  GraphObject,
  GraphPathRecord,
  MigrationStatus,
  QueryOptions,
} from 'store/graphData/types';

const baseURL = process.env.REACT_APP_GATEWAY_URL;

export interface GetOpenLeadsOptions {
  limit: number;
  offset: number;
  projectionValues: string[];
}

export enum CustomTemplateTypes {
  SALESFORCE_OPEN_LEADS = 'SALESFORCE_OPEN_LEADS',
}

export const getMigrationStatus = (migrationId: string): Promise<MigrationStatus> =>
  apiCall({ method: 'get', url: `/graph-data/migration/${migrationId}/status`, baseURL });

export const getDataByLabel = (
  migrationId: string,
  label: string,
  options: QueryOptions = {},
  filter?: GraphFilter,
): Promise<GraphPathRecord> => {
  let optionsTmp: QueryOptions = { limit: 1, levels: 1 };
  optionsTmp = { ...optionsTmp, ...options };
  return apiCall({
    method: 'post',
    url: `/graph-data/migration/${migrationId}/view/by-label/${label}?limit=${optionsTmp.limit}&levels=${optionsTmp.levels}`,
    baseURL,
    data: { filter },
  });
};

export const getDataByVertexId = (migrationId: string, vertexId: string): Promise<GraphDataRecord> =>
  apiCall({ method: 'get', url: `/graph-data/migration/${migrationId}/data/by-vertex/${vertexId}`, baseURL });

export const getOpenLeads = (migrationId: string, options: GetOpenLeadsOptions): Promise<GraphObject[]> => {
  return apiCall({
    method: 'post',
    url: `/graph-data/migration/${migrationId}/template/query`,
    baseURL,
    data: { query: { type: CustomTemplateTypes.SALESFORCE_OPEN_LEADS, params: options } },
  });
};

export const getDataByIds = (
  migrationId: string,
  accountIds: string[],
  individualNodeIds: string[],
  filter?: GraphFilter,
): Promise<GraphPathRecord> =>
  apiCall({
    method: 'post',
    url: `/graph-data/migration/${migrationId}/data/bulk-vertex-ids`,
    baseURL,
    data: { ids: accountIds, individualNodeIds, filter },
  });
