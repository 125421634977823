import { Box, Grid, Button, Typography } from '@mui/material';
import { FC } from 'react';
import { getLabelAndField } from './utils';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import theme from 'core/theme';
import { ActivityDataList, EventFieldOption } from '../../../components/ActivityDataList';
interface ActionMenuViewProps {
  node: any;
  referencedUsers: any;
}

export const ActionMenuView: FC<ActionMenuViewProps> = (props) => {
  const { node, referencedUsers } = props;
  const { Status, id } = node;

  return (
    <>
      <Grid display={'flex'} direction={'column'} gap={1}>
        <Box>{getLabelAndField('Status', Status, referencedUsers)}</Box>
        <Box display={'flex'} alignItems={'center'} sx={{ width: 'fit-content', ':hover': { cursor: 'pointer' } }}>
          <PlusIcon />
          <Typography variant="labelMedium14" sx={{ padding: 1, color: theme.palette.primary.main }}>
            Add interaction
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'}>
          <ActivityDataList recordId={id} eventField={EventFieldOption.whoId} />
        </Box>
      </Grid>
    </>
  );
};

export default ActionMenuView;
