import { Box, Button, Checkbox, Divider, FormControlLabel, InputAdornment, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useCallback, useMemo, useState } from 'react';

interface Option<T> {
  label: string;
  value: T;
  [x: string]: any;
}

export interface AutoCompleteMultipleSelectorProps<T> {
  options: Option<T>[];
  itemLabel: string;
  pluralItemLabel: string;
  searchInputPlaceholder: string;
  initialValuesSelected?: T[];
  onChange: (value: T[]) => void;
}

export function AutoCompleteMultipleSelector<T>({
  itemLabel,
  pluralItemLabel,
  onChange,
  options,
  searchInputPlaceholder,
  initialValuesSelected = [],
}: AutoCompleteMultipleSelectorProps<T>) {
  const [itemsSelected, setItemsSelected] = useState<T[]>(initialValuesSelected);
  const [searchTeam, setSearchTeam] = useState<string>('');

  const calculatedOptions = useMemo(
    () => options.filter((option) => option.label.toLowerCase().includes(searchTeam.toLowerCase())),
    [options, searchTeam],
  );

  const handleApply = useCallback(() => {
    onChange(itemsSelected);
  }, [itemsSelected, onChange]);

  return (
    <Box sx={{ px: 2, pt: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box>
        <TextField
          label={searchInputPlaceholder}
          variant="outlined"
          value={searchTeam}
          onChange={(event) => setSearchTeam(event.target.value)}
          id="outlined-start-adornment"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ maxHeight: '40vh', overflowY: 'auto' }}>
        {calculatedOptions.map((option) => (
          <Box
            key={option.label}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '5px 0',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={itemsSelected.includes(option.value)}
                  onChange={() => {
                    if (itemsSelected.includes(option.value)) {
                      setItemsSelected(itemsSelected.filter((item) => item !== option.value));
                    } else {
                      setItemsSelected([...itemsSelected, option.value]);
                    }
                  }}
                />
              }
              label={option.label}
            />
          </Box>
        ))}
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
        <Typography>{`${itemsSelected.length} ${
          itemsSelected.length > 1 ? pluralItemLabel : itemLabel
        } selected`}</Typography>
        <Button onClick={handleApply}>Apply</Button>
      </Box>
    </Box>
  );
}

export default AutoCompleteMultipleSelector;
