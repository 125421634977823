import { ChartOptions } from 'chart.js';

export const chartOptions = (getTooltip?: (position: any, data: any, hide?: boolean) => void): ChartOptions<'bar'> => ({
  plugins: {
    title: {
      display: false,
      text: 'Engagement',
    },
    legend: {
      display: false,
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      enabled: false,
      external: (context) => {
        const { tooltip, chart } = context;

        if (tooltip.opacity === 0) {
          if (getTooltip) {
            getTooltip({ x: 0, y: 0 }, {}, true);
          }
          return;
        }
        let left = tooltip.x + 10;
        let top = tooltip.y + 20;
        if (left + 250 > chart.width) {
          left -= left + 250 - chart.width;
        }
        if (top + 120 > chart.height) {
          top -= 140;
        }

        const data = tooltip.dataPoints.reduce(
          (acc, point, index) => {
            return {
              ...acc,
              [point.dataset.label as string]: { value: point.raw, color: tooltip.labelColors[index].backgroundColor },
            };
          },
          {
            period: tooltip.dataPoints[0].label,
          },
        );
        if (getTooltip) getTooltip({ x: left, y: top }, data);
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        maxRotation: 0,
        callback: function (_: any, index: any) {
          const label = this.getLabelForValue(index);
          return label.split(' ').join('\n');
        },
        font: {
          size: 10,
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        callback: function (value, index, ticks) {
          const minValue = ticks[0].value; // First tick value
          const maxValue = ticks[ticks.length - 1].value; // Last tick value

          // Show only min and max labels
          if (value === minValue || value === maxValue) {
            return value; // Show the label
          }
          return ''; // Hide other labels
        },
      },
      grid: {
        display: false, // Hide grid lines
        lineWidth: 0,
      },
      border: {
        display: false,
      },
    },
  },
});
