import { Box, CircularProgress, SxProps, Theme } from '@mui/material';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { FC, useEffect, useMemo, useState } from 'react';
import { Condition } from 'store/dataRaptorRule/dto/rule.dto';
// import { useMigration } from 'store/migration/hooks';
import { getMigrationTableDataById } from 'http/migration';

export interface MigrationFieldValueTableDropDownProps {
  label?: string;
  id: string;
  labelSx?: SxProps<Theme>;
  value: string;
  placeholder: string;
  migrationId: string;
  table: string;
  conditions?: Condition[];
  fields?: string[];
  optionValueFieldName: string;
  optionLabelFieldNames: string[];
  onChange: (value: string) => void;
}

export const MigrationFieldValueTableDropDown: FC<MigrationFieldValueTableDropDownProps> = (props) => {
  const {
    label,
    migrationId,
    onChange,
    table,
    value,
    labelSx,
    placeholder,
    id,
    optionLabelFieldNames,
    optionValueFieldName,
    conditions,
    fields,
  } = props;

  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const fallBackOption = useMemo(() => ({ label: placeholder || 'Select a Value', value: '' }), [placeholder]);

  useEffect(() => {
    setLoading(true);
    getMigrationTableDataById(migrationId, table, 0, 100, conditions || [], fields || []).then((data) => {
      const options = data.map((row) => {
        const label = optionLabelFieldNames.map((field) => row[field]).join(' ');
        return { label, value: row[optionValueFieldName] };
      });
      setOptions(options);
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading === true ? (
        <Box display={'flex'} justifyContent={'center'}>
          <CircularProgress></CircularProgress>
        </Box>
      ) : (
        <ControlledCustomDropdown<string>
          label={label}
          labelSx={labelSx}
          id={id}
          value={value || ''}
          onSelect={onChange}
          listBoxProps={{ maxHeight: 200 }}
          options={options || []}
          fallbackOption={fallBackOption}
          placeholder={placeholder}
        />
      )}
    </>
  );
};
