import { ControlledCustomDropdown } from 'components/CustomDropdown';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { FC, useCallback } from 'react';
import { RuleValidationPattern } from 'store/dataRaptorRule/types';
import { ValidationPatternTag } from './ValidationPatternTag';
import { Grid } from '@mui/material';
import { GridItem } from '../../../../../../components/ui/modal';
import _ from 'lodash';
import { useMemo } from 'react';

export interface RegexArraySelectorProps {
  currentValue: string[];
  patterns: RuleValidationPattern[];
  targetPath: string;
}

export const RegexArraySelector: FC<RegexArraySelectorProps> = (props) => {
  const { targetPath, patterns, currentValue } = props;
  const { setFieldValue } = useRuleForm();

  const patternFiltered = useMemo(() => {
    return patterns.filter((pattern) => !currentValue.includes(pattern.validationPatternId));
  }, [currentValue, patterns]);

  const patternOptions = patternFiltered.map((pattern) => {
    return { label: `${pattern.label} \n ${pattern.example || ''}`, value: pattern.validationPatternId };
  });

  const selectedPatterns = patterns.filter((pattern) => currentValue.includes(pattern.validationPatternId));

  const handleAddPattern = useCallback(
    (id: string) => {
      const newValues = _.cloneDeep(currentValue);
      newValues.push(id);
      setFieldValue({ path: targetPath, value: newValues });
    },
    [currentValue, setFieldValue, targetPath],
  );

  const handleDeletePattern = useCallback(
    (id: string) => {
      const newValues = currentValue.filter((value) => value !== id);
      setFieldValue({ path: targetPath, value: newValues });
    },
    [currentValue, setFieldValue, targetPath],
  );

  const PatternTagArray = selectedPatterns.map((pattern) => {
    return (
      <ValidationPatternTag
        key={pattern.validationPatternId}
        label={pattern.label}
        patternId={pattern.validationPatternId}
        handleDelete={(id) => handleDeletePattern(id)}
      />
    );
  });

  return (
    <>
      <Grid display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
        <GridItem flex={2} sx={{ minWidth: '200px' }}>
          <ControlledCustomDropdown<string>
            id={`${targetPath}-field`}
            label="Format Selector"
            placeholder="value"
            value={''}
            options={patternOptions || []}
            sx={{ backgroundColor: 'neutral.white' }}
            fallbackOption={{ label: 'Add a format', value: '' }}
            onSelect={(value) => handleAddPattern(value as string)}
          />
        </GridItem>
        <GridItem flex={2}>
          <Grid container display={'flex'} justifyContent={'center'} alignContent={'flex-end'} gap={1}>
            {PatternTagArray}
          </Grid>
        </GridItem>
      </Grid>
    </>
  );
};
