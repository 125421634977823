import { Typography } from '@mui/material';
import { GridItem } from 'components/ui';

export const FormErrorMessage = ({ errorMessage }: { errorMessage: string }) => {
  if (!errorMessage) {
    return null;
  }
  return (
    <GridItem flex={1}>
      <Typography variant="caption" sx={{ color: 'red.main' }}>
        {errorMessage}
      </Typography>
    </GridItem>
  );
};
