import { Box, Grid, Typography } from '@mui/material';
import theme from 'core/theme';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { FC, useCallback } from 'react';
import { useGraphRender } from 'store/graphRender/hooks';
import { TableFilter } from 'store/graphData/types';
import { removeConditional } from '../../FilterPanel/utils';

export interface ResultDetail {
  tableLabel?: string;
  tableName?: string;
  detail: string;
  allowElimination?: boolean;
  index: number;
}

export interface FilterBarTagProps {
  resultDetail: ResultDetail;
  index: number;
}

export const FilterBarTag: FC<FilterBarTagProps> = (props) => {
  const { resultDetail } = props;
  const { detail, allowElimination, tableLabel, tableName, index: detailWhereIndex } = resultDetail;
  const {
    data: { graphFilter },
    partialUpdateGraphFilter,
  } = useGraphRender();

  const handleDeleteCondition = useCallback(() => {
    if (graphFilter && tableName) {
      const tableFilter: TableFilter = (graphFilter as any)[tableName];
      if (tableFilter && tableFilter.where) {
        const newWhere = removeConditional(tableFilter.where, detailWhereIndex);
        partialUpdateGraphFilter({ path: `${tableName}.where`, value: newWhere });
      }
    }
  }, [graphFilter, detailWhereIndex, partialUpdateGraphFilter, tableName]);

  return (
    <Grid
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      gap={1}
      sx={{ backgroundColor: theme.palette.neutral.lightGray, px: '8px', py: '3px', borderRadius: '4px' }}
    >
      <Typography variant="p14" sx={{ whiteSpace: 'nowrap' }} display={'inline-block'}>
        {tableLabel || ''} {detail}
      </Typography>
      {allowElimination === true && (
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          sx={{ ':hover': { cursor: 'pointer', stroke: theme.palette.red.main } }}
          onClick={handleDeleteCondition}
        >
          <CrossIcon />
        </Box>
      )}
    </Grid>
  );
};

export default FilterBarTag;
