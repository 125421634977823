import { Chat, Thread } from 'store/chat/types';
import { apiCall } from '../index';

export const createChat = (data: Partial<Chat>): Promise<Chat> => apiCall({ method: 'post', url: '/chat', data });

export const getChats = (): Promise<Chat[]> => apiCall({ method: 'get', url: '/chat' });

export const getChat = (id: number): Promise<Chat> => apiCall({ method: 'get', url: `/chat/${id}` });

export const updateChat = (id: number, data: Partial<Chat>): Promise<Chat> =>
  apiCall({ method: 'put', url: `/chat/${id}`, data });

export const deleteChat = (id: number): Promise<null> => apiCall({ method: 'delete', url: `/chat/${id}` });

///////////////////thread

export const createThread = (chatId: number, data: Partial<Thread>): Promise<Thread> =>
  apiCall({ method: 'post', url: `/chat/${chatId}/thread`, data });

export const getThreads = (chatId: number): Promise<Thread[]> =>
  apiCall({ method: 'get', url: `/chat/${chatId}/thread` });

export const getThread = (chatId: number, threadId: number): Promise<Thread> =>
  apiCall({ method: 'get', url: `/chat/${chatId}/thread/${threadId}` });

export const updateThread = (chatId: number, threadId: number, data: Partial<Thread>): Promise<Thread> =>
  apiCall({ method: 'put', url: `/chat/${chatId}/thread/${threadId}`, data });

export const deleteThread = (chatId: number, threadId: number): Promise<null> =>
  apiCall({ method: 'delete', url: `/chat/${chatId}/thread/${threadId}` });
