import { CircularProgress, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import { ICON_BUTTON_DIMENSIONS, ICON_SIZE } from './Constants';

type ProgressIconButtonProps = {
  isComplete: boolean;
};

const ProgressIconButton: React.FC<ProgressIconButtonProps> = ({ isComplete }) => (
  <IconButton
    sx={{
      borderRadius: '50%',
      backgroundColor: 'primary.main',
      width: ICON_BUTTON_DIMENSIONS,
      height: ICON_BUTTON_DIMENSIONS,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      mx: 2,
    }}
  >
    {isComplete ? (
      <CheckIcon sx={{ color: 'neutral.white', fontSize: ICON_SIZE }} />
    ) : (
      <CircularProgress size={ICON_SIZE} sx={{ color: 'neutral.white' }} />
    )}
  </IconButton>
);

export default ProgressIconButton;
