import { ActionPromiseArgs } from 'store/types';

import { RuleHavingArray, RuleWhereArray } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { SubQuery } from 'store/ruleForm/types';

export interface TableFilter {
  table: string;
  subQueries?: SubQuery[];
  where: RuleWhereArray;
  having?: RuleHavingArray;
}

export interface GraphFilter {
  account?: TableFilter;
  contact?: TableFilter;
  opportunity?: TableFilter;
  lead?: TableFilter;
  event?: TableFilter;
}

export interface GraphObject {
  id: string;
  label: string;
  data?: any;
  properties?: any;
  [x: string]: any;
}

export interface FormattedEdge {
  data: {
    id: string;
    type?: string;
    [x: string]: any;
  };
  properties: {
    source: string;
    target: string;
    hidden: boolean;
  };
}

export interface GraphEdge extends GraphObject {
  relationship: string;
  from: string;
  to: string;
}

export interface GraphPathRecord {
  paths: GraphObject[][];
  accounts: GraphObject[];
  individualNodes?: GraphObject[];
}

export interface GraphDataRecord {
  vertex: GraphObject;
}

export interface GetDataByVertexIdParams extends ActionPromiseArgs<GraphDataRecord> {
  migrationId: string;
  vertexId: string;
}

export interface GetDataByIdsParams extends ActionPromiseArgs<GraphPathRecord> {
  migrationId: string;
  accountIdArray: string[];
  individualNodeIdArray: string[];
  filter?: GraphFilter;
}

export interface GetMigrationStatusParams extends ActionPromiseArgs<MigrationStatus> {
  migrationId: string;
}

export interface MigrationStatus {
  status: string;
  feedCount: any[];
  progress: number;
}

export interface QueryOptions {
  limit?: number;
  levels?: number;
}

export interface GetDataByLabelParams extends ActionPromiseArgs<GraphPathRecord> {
  migrationId: string;
  label: string;
  options?: QueryOptions;
  filter?: GraphFilter;
}

export interface GraphDataState {
  loading: boolean;
  error: string | boolean;
  success: boolean;
  viewByLabel: GraphObject[];
  dataByLabel: GraphPathRecord;
  dataByVertexIdArray: GraphPathRecord;
  dataByVertexId: ObjectMap<stateObjectMap<GraphObject>>;
  migrationStatus?: MigrationStatus;
}

export interface stateObjectMap<T> {
  loading: boolean;
  error: boolean | string;
  success: boolean;
  data: ObjectMap<T>;
}

export interface ObjectMap<T> {
  [x: string]: T;
}

export enum MIGRATION_STATUS_END_STATE {
  failed = 'LOAD_FAILED',
  completed = 'LOAD_COMPLETED',
}

export enum MIGRATION_STATUS_PROGRESS_STATE {
  progress = 'LOAD_IN_PROGRESS',
  notStarted = 'LOAD_NOT_STARTED',
}

export interface GraphDataHook extends GraphDataState {
  setError: (error: string | boolean) => void;
  setSuccess: (success: boolean) => void;
  setLoading: (loading: boolean) => void;
  getDataByVertexId: (params: GetDataByVertexIdParams) => void;
  getDataByVertexIdArray: (params: GetDataByIdsParams) => void;
  getDataByLabel: (params: GetDataByLabelParams) => void;
  cleanState: () => void;
  getMigrationStatus: (params: GetMigrationStatusParams) => void;
}
