import { useEffect, useMemo, useRef, useState } from 'react';

import * as d3 from 'd3';
import { zoom } from 'd3-zoom';

import ZoomControls from './ZoomControls';
import { InteractionData } from './DataHealthPanel';
import { getConfidenceScoreColor } from 'core/utils';

const MARGIN = { top: 0, right: 0, bottom: 0, left: 0 };

type RendererProps = {
  setHoveredCell: (hoveredCell: InteractionData | null) => void;
  numCells: number;
  width: number;
  elements: {
    [x: string]: any;
    confidence_score: number;
  }[];
};

type HeatmapData = {
  [x: string]: any;
  confidence_score: number;
  x: string;
  y: string;
}[];

const SMALLEST_CELL_SIZE = 24;
const MAX_NUM_COLS = 45;

const getCellSize = (cWidth: number, cHeight: number, itemLen: number) => {
  let rWidth = cWidth;
  let rHeight = cHeight;
  let colsCounter = 1;
  let rowCounter = 1;
  let cPositions = 1;
  if (itemLen <= 1) {
    return { cellSizeX: rWidth, cellSizeY: cHeight, numCols: 1, numRows: 1 };
  }
  let toggler = 0;
  while (cPositions < itemLen) {
    if (toggler % 2 === 0) {
      colsCounter = colsCounter * 2;
      rWidth = rWidth / 2;
    } else {
      rowCounter = rowCounter * 2;
      rHeight = rHeight / 2;
    }
    cPositions = cPositions * 2;
    toggler++;
  }

  const unusedRows = rowCounter - Math.ceil(itemLen / colsCounter);
  const unusedIndex = unusedRows / rowCounter;

  if (unusedIndex > 0.1) {
    rowCounter = rowCounter - unusedRows;
    rHeight = cHeight / rowCounter;
  }

  if (rWidth < SMALLEST_CELL_SIZE || rHeight < SMALLEST_CELL_SIZE || colsCounter > MAX_NUM_COLS) {
    return {
      cellSizeX: SMALLEST_CELL_SIZE,
      cellSizeY: SMALLEST_CELL_SIZE,
      numCols: MAX_NUM_COLS,
      numRows: Math.ceil(itemLen / MAX_NUM_COLS),
    };
  }

  return { cellSizeX: rWidth, cellSizeY: rHeight, numCols: colsCounter, numRows: rowCounter };
};

export const Renderer = ({ setHoveredCell, numCells, elements, width }: RendererProps) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const boundsRef = useRef<SVGGElement>(null);
  const [zoomTransform, setZoomTransform] = useState<d3.ZoomTransform>(d3.zoomIdentity);
  const [isExpanded, setIsExpanded] = useState(false);
  const [boundsHeight, setBoundsHeight] = useState(260);

  const { cellSizeX, cellSizeY, numCols, numRows } = useMemo(
    () => getCellSize(width, boundsHeight, numCells),
    [boundsHeight, numCells, width],
  );

  const [boundsCellsWidth, boundsCellsHeight] = useMemo(
    () => [numCols * cellSizeX, numRows * cellSizeY],
    [cellSizeX, cellSizeY, numCols, numRows],
  );

  const allYGroups = Array.from(new Set(Array.from({ length: numRows }, (_, i) => 'cont' + i)));
  const allXGroups = Array.from(new Set(Array.from({ length: numCols }, (_, i) => 'cont' + i)));

  const xScale = d3.scaleBand().domain(allXGroups).range([0, boundsCellsWidth]).padding(0.000001);
  const yScale = d3.scaleBand().domain(allYGroups).range([0, boundsCellsHeight]).padding(0.000001);

  useEffect(() => {
    if (!svgRef.current) return;

    const svgElement = d3.select<SVGSVGElement, unknown>(svgRef.current);
    const initialTransform = d3.zoomIdentity.translate(MARGIN.left, MARGIN.top).scale(zoomTransform.k);

    const zoomHandler = zoom<SVGSVGElement, unknown>()
      .scaleExtent([1, 8])
      .on('zoom', (event) => {
        if (!boundsRef.current) return;
        const { transform } = event;
        const { x, y, k } = transform;

        const maxX = Math.max(0, boundsCellsWidth - width + boundsCellsWidth * k - boundsCellsWidth);
        const maxY = Math.max(0, boundsCellsHeight - boundsHeight + boundsCellsHeight * k - boundsCellsHeight);
        const clampedX = Math.min(0, Math.max(-maxX, x));
        const clampedY = Math.min(0, Math.max(-maxY, y));

        const clampedTransform = d3.zoomIdentity.translate(clampedX, clampedY).scale(k);
        setZoomTransform(clampedTransform);
        boundsRef.current.setAttribute('transform', clampedTransform.toString());
      })
      .filter((event) => !event.ctrlKey && event.type !== 'wheel');

    if (svgElement) {
      svgElement.call(zoomHandler.transform, initialTransform);
      svgElement.call(zoomHandler as any);
    }
  }, [zoomTransform.k, width, boundsHeight, boundsCellsWidth, boundsCellsHeight]);

  useEffect(() => {
    setData(() => {
      return elements.map((element, index) => ({
        ...element,
        x: 'cont' + (index % numCols),
        y: 'cont' + Math.floor(index / numCols),
      }));
    });
  }, [elements, numCols, numRows]);

  const [data, setData] = useState<HeatmapData>(() => {
    const limitedElements = elements.slice(0, 450);
    return limitedElements.map((element, index) => ({
      ...element,
      x: 'cont' + (index % numCols),
      y: 'cont' + Math.floor(index / numCols),
    }));
  });

  const handleZoom = (comp: (...values: number[]) => number, zoom: number, limit: number) => {
    setZoomTransform((prevZoomTransform) => {
      const newZoomLevel = comp(prevZoomTransform.k * zoom, limit);
      const newZoomTransform = prevZoomTransform.scale(newZoomLevel / prevZoomTransform.k);
      boundsRef.current?.setAttribute('transform', newZoomTransform.toString());
      return newZoomTransform;
    });
  };

  const handleZoomIn = () => {
    handleZoom(Math.min, 1.2, 8);
  };

  const handleZoomOut = () => {
    handleZoom(Math.max, 0.8, 1);
  };

  const handleResetZoom = () => {
    setZoomTransform(d3.zoomIdentity);
    if (boundsRef.current) {
      boundsRef.current.setAttribute('transform', d3.zoomIdentity.toString());
    }
  };

  const handleExpand = () => {
    if (!isExpanded) {
      setBoundsHeight(30 * SMALLEST_CELL_SIZE);
      setIsExpanded(true);
    } else {
      setBoundsHeight(10 * SMALLEST_CELL_SIZE);
      setIsExpanded(false);
    }
  };

  const allShapes = data.map((d, i) => {
    const x = xScale(d.x);
    const y = yScale(d.y);

    if (d.confidence_score === null || !x || !y) {
      return null;
    }

    return (
      <rect
        key={i}
        x={x}
        y={y}
        width={cellSizeX}
        height={cellSizeY}
        opacity={1}
        fill={getConfidenceScoreColor(d.confidence_score)}
        rx={6}
        stroke={'white'}
        onMouseEnter={() => {
          const transformedX = (x + 15) * zoomTransform.k + cellSizeX / 2 + zoomTransform.x + MARGIN.left;
          const transformedY = (y + 15) * zoomTransform.k + cellSizeY / 2 + zoomTransform.y + MARGIN.top;

          setHoveredCell({
            ...d,
            confidence_score: d.confidence_score,
            xLabel: d.x,
            yLabel: d.y,
            xPos: transformedX,
            yPos: transformedY - 30,
          });
        }}
        onMouseLeave={() => setHoveredCell(null)}
        cursor="pointer"
      />
    );
  });

  return (
    <div>
      <svg ref={svgRef} width={width} height={boundsHeight + MARGIN.top + MARGIN.bottom}>
        <g ref={boundsRef} transform={`translate(${MARGIN.left},${MARGIN.top})`}>
          {allShapes}
        </g>
      </svg>
      <ZoomControls
        zoom={zoomTransform.k}
        handleExpand={handleExpand}
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        handleResetZoom={handleResetZoom}
      />
    </div>
  );
};
