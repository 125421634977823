import moment from 'moment';
import { ReactComponent as ContactIcon } from 'assets/icons/graph/contact.svg';
import { ReactComponent as BellIcon } from 'assets/icons/graph/bell.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/graph/clock.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/graph/calendar.svg';

export const necessaryFieldsForSorting = ['CreatedDate', 'LastActivityDate', 'LastViewedDate', 'ConvertedDate'];

export const sortOptionsMap: any = {
  openLeadsFirst: {
    label: 'Open Leads First',
    value: 'openLeadsFirst',
    icon: ContactIcon,
  },
  sortByNewest: { label: 'Sort By Newest', value: 'sortByNewest', icon: BellIcon },
  recentlyLeadFirst: { label: 'Recently Lead First', value: 'recentlyLeadFirst', icon: ClockIcon },
  todaysLeadFirst: { label: "Today's Lead First", value: 'todaysLeadFirst', icon: CalendarIcon },
};

export const sortOptionArray = Object.keys(sortOptionsMap).map((key) => sortOptionsMap[key]);

export const openLeadsFirst = (nodeA: any, nodeB: any) => {
  const dateA = nodeA?.data?.['ConvertedDate'];
  const dateB = nodeB?.data?.['ConvertedDate'];
  return moment(dateA).diff(moment(dateB));
};

export const sortByNewest = (nodeA: any, nodeB: any) => {
  const dateA = nodeA?.data?.['CreatedDate'];
  const dateB = nodeB?.data?.['CreatedDate'];
  return moment(dateA).diff(moment(dateB));
};

export const recentlyLeadFirst = (nodeA: any, nodeB: any) => {
  const dateA = nodeA?.data?.['LastActivityDate'];
  const dateB = nodeB?.data?.['LastActivityDate'];
  return moment(dateA).diff(moment(dateB));
};

export const todaysLeadFirst = (nodeA: any, nodeB: any) => {
  const dateA = nodeA?.data?.['LastViewedDate'];
  const dateB = nodeB?.data?.['LastViewedDate'];
  return moment(dateA).diff(moment(dateB));
};

export const getSortingFunction = (value: string) => {
  if (value === 'openLeadsFirst') return openLeadsFirst;
  if (value === 'sortByNewest') return sortByNewest;
  if (value === 'recentlyLeadFirst') return recentlyLeadFirst;
  if (value === 'todaysLeadFirst') return todaysLeadFirst;
};
