import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import { ReactComponent as ExternalOpenIcon } from 'assets/icons/externalOpen.svg';
import { ReactComponent as OpenModalIcon } from 'assets/icons/openModal.svg';
import theme from 'core/theme';
import { FC, useCallback, useMemo, useState } from 'react';
import { NodeToolbar, Position } from 'reactflow';
import { useGraphRender } from 'store/graphRender/hooks';
import { useDataGraphUtils } from '../../hooks/utils/useDataGraphUtils';
import qs from 'qs';
import _ from 'lodash';
import { useDuplicationDataHandlerUtils } from '../../hooks/utils/useDuplicationDataHandlerUtils';
import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';
import { Link } from 'react-router-dom';
import { useMigration } from 'store/migration/hooks';

interface DuplicateToolTipProps {
  id: string;
  open: boolean;
  record: any;
}

export const DuplicateToolTip: FC<DuplicateToolTipProps> = (props) => {
  const { open, record, id } = props;
  const {
    data: { masterRecordIds, schemaName, duplicationCounterMap, nodeHashMap },
    setRecordsToMerge,
    setOpenMergeModal,
  } = useGraphRender();

  const {
    data: { migrationId },
  } = useMigration();

  const { getTraversedNodesAndEdgesOnHover } = useDuplicationDataHandlerUtils();

  const { findRecordRelatedAccount } = useDataGraphUtils();
  const CustomDivider = () => <Divider sx={{ backgroundColor: theme.palette.blue.light }} />;
  const MinWidthComponent = () => <Box sx={{ minWidth: '50px' }} />;
  const [hoverState, setHoverState] = useState(false);
  const openModal = open || hoverState;

  const duplicateNumber = useMemo(() => {
    const idWithoutSchema = id.replace(`${schemaName}.`, '');
    return duplicationCounterMap[idWithoutSchema] || 0;
  }, [duplicationCounterMap, id, schemaName]);

  const name = useMemo(() => {
    if (!_.isEmpty(record.Name)) {
      return record.Name;
    } else {
      return `${record.FirstName || ''} ${record.LastName || ''}`;
    }
  }, [record.FirstName, record.LastName, record.Name]);

  const relatedAccountName = useMemo(() => {
    const accountId = findRecordRelatedAccount(id);
    if (accountId) {
      const account = nodeHashMap[accountId];
      return account.data.Name;
    } else {
      return 'None';
    }
  }, [findRecordRelatedAccount, id, nodeHashMap]);

  const isItMasterRecord = useMemo(() => {
    const idWithoutSchema = id.replace(`${schemaName}.`, '');
    return masterRecordIds.includes(`${idWithoutSchema}`);
  }, [id, masterRecordIds, schemaName]);

  const duplicateState = useMemo(() => (isItMasterRecord ? 'Master Record' : 'Duplicate Record'), [isItMasterRecord]);

  const getPropertiesGrid = (label: string, value: string) => {
    return (
      <Grid item display={'flex'} justifyContent={'space-between'}>
        <Typography>{label}</Typography>
        <MinWidthComponent />
        <Typography sx={{ fontWeight: 'bold' }}>{value}</Typography>
      </Grid>
    );
  };

  const handleSetHoverState = (value: boolean) => {
    setHoverState(value);
  };

  const handleOpenMergeModal = useCallback(() => {
    const { visitedNodes } = getTraversedNodesAndEdgesOnHover(id);
    const visitedNodeObjects = visitedNodes.map((nodeId) => nodeHashMap[nodeId]);
    const recordsToMergePayload = visitedNodeObjects.map((node) => {
      return {
        recordId: node.data.id,
        tableName: node.data.label,
      };
    });
    setOpenMergeModal(true);
    setRecordsToMerge(recordsToMergePayload);
  }, [getTraversedNodesAndEdgesOnHover, id, nodeHashMap, setOpenMergeModal, setRecordsToMerge]);

  const openRecordInNewTabParameters = useMemo(() => {
    return qs.stringify({ nodeId: id, migrationId, searchTerm: name, schemaName });
  }, [id, migrationId, name, schemaName]);

  return (
    <NodeToolbar
      onMouseEnter={() => handleSetHoverState(true)}
      onMouseLeave={() => handleSetHoverState(false)}
      isVisible={openModal}
      position={Position.Left}
    >
      <Card sx={{ backgroundColor: theme.palette.blue.dark, color: 'white', zIndex: 999 }}>
        <Grid container display={'flex'} flexDirection={'column'} gap={1} padding={1}>
          <Grid item>
            <Box sx={{ cursor: 'pointer', width: 'fit-content' }} display={'flex'} alignItems={'center'} gap={1}>
              <Link
                style={{ all: 'unset' }}
                to={`${PRIVATE_ABS_ROUTE_PATHS.dataGraph}?${openRecordInNewTabParameters}`}
                target="_blank"
              >
                <Typography sx={{ fontWeight: 'bold', textDecoration: 'underline' }}>{name}</Typography>
              </Link>
              <ExternalOpenIcon />
            </Box>
          </Grid>
          <CustomDivider />
          <Grid container display={'flex'} flexDirection={'column'} gap={1}>
            {getPropertiesGrid('Record type', record.label)}
            {getPropertiesGrid('Duplicate State', duplicateState)}
            {getPropertiesGrid('No. of duplicates', duplicateNumber.toString())}
            {getPropertiesGrid('Account:', relatedAccountName)}
          </Grid>
          <CustomDivider />
          <Grid item>
            <Box
              sx={{ cursor: 'pointer', width: 'fit-content' }}
              display={'flex'}
              alignItems={'center'}
              gap={1}
              onClick={handleOpenMergeModal}
            >
              <OpenModalIcon />
              <Typography sx={{ fontWeight: 'bolder', textDecoration: 'underline' }}>
                Analyze and Merge Duplicate
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </NodeToolbar>
  );
};
