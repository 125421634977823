import { Box, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { ReactComponent as LeadsIcon } from 'assets/icons/graph/graphLeadIcon.svg';
import { ReactComponent as CollapseIcon } from 'assets/icons/graph/graphCollapsedIcon.svg';
import { ReactComponent as UnCollapseIcon } from 'assets/icons/graph/graphUncollapsedIcon.svg';
import { ReactComponent as GraphCircleIcon } from 'assets/icons/graph/graphCircle.svg';
import theme from 'core/theme';
import { ComponentDesign } from '../../types';
import { useGraphRender } from 'store/graphRender/hooks';
import { GraphRenderView } from 'store/graphRender/types';
import { motion } from 'framer-motion';

export const LeadOpenNode: FC<NodeProps> = (props: NodeProps) => {
  const { data, id } = props;
  const { bridgeLabel, _collapsed, connectionsCount } = data;
  const collapsed = _collapsed == null ? true : _collapsed;
  const HandlerStyle = { display: 'none' };

  const calculateConnectionCounts = useMemo(
    () => (connectionsCount <= 99 ? connectionsCount : '+99'),
    [connectionsCount],
  );

  const {
    data: { customDesignMap, defaultDesign },
    setGraphView,
  } = useGraphRender();

  const handleChangeToLeadView = useCallback(() => {
    setGraphView(GraphRenderView.LEADS);
  }, [setGraphView]);

  let design = defaultDesign ? defaultDesign : ComponentDesign.STANDARD;

  if (customDesignMap[id]) {
    design = customDesignMap[id];
  }

  const borderColor = useMemo(() => {
    if (design === ComponentDesign.SEARCHING || design === ComponentDesign.DUPLICATE_INACTIVE) {
      return theme.palette.neutral.subtone3;
    }
    return theme.palette.primary.darkBlueHigh;
  }, [design]);

  const shouldRenderBridgeLabel = useMemo(() => {
    if (design === ComponentDesign.DUPLICATE_INACTIVE) {
      return false;
    }
    return true;
  }, [design]);

  const shouldRenderIcon = useMemo(() => {
    if (design === ComponentDesign.SEARCHING || design === ComponentDesign.DUPLICATE_INACTIVE) {
      return false;
    }
    return true;
  }, [design]);

  const shouldRenderConnectionCount = useMemo(() => {
    if (
      design === ComponentDesign.SEARCHING ||
      design === ComponentDesign.HIGHLIGH ||
      design === ComponentDesign.DUPLICATE_INACTIVE
    ) {
      return false;
    }
    return true;
  }, [design]);

  const shouldRenderExpandableIcon = useMemo(() => {
    if (
      design === ComponentDesign.SEARCHING ||
      design === ComponentDesign.HIGHLIGH ||
      ComponentDesign.DUPLICATE_INACTIVE
    ) {
      return false;
    }
    return true;
  }, [design]);

  const icon = useMemo(() => <LeadsIcon fill="white" width={'100%'} height={'100%'} />, []);

  return (
    <motion.div
      layout={true}
      style={{
        border: '5px solid',
        borderColor: theme.palette.neutral.white,
        backgroundColor: borderColor,
        borderRadius: '100%',
        width: '100%',
        height: '100%',
      }}
      onClick={handleChangeToLeadView}
    >
      {shouldRenderIcon && <Box sx={{ padding: 0, margin: 0, width: '100%', height: '100%' }}>{icon}</Box>}
      {shouldRenderBridgeLabel && (
        <Box
          position={'absolute'}
          top={'-45%'}
          left={'-50%'}
          width={'200%'}
          sx={{ color: theme.palette.primary.gray, textAlign: 'center' }}
        >
          <Typography variant="labelRegular12">{bridgeLabel}</Typography>
        </Box>
      )}
      {shouldRenderExpandableIcon && (
        <Box position={'absolute'} bottom={'-20%'} left={'-2%'} sx={{ color: theme.palette.primary.gray }}>
          {collapsed === true ? (
            <CollapseIcon width={'10px'} height={'10px'} />
          ) : (
            <UnCollapseIcon width={'10px'} height={'10px'} />
          )}
        </Box>
      )}
      {collapsed && shouldRenderConnectionCount && (
        <>
          <GraphCircleIcon
            style={{ position: 'absolute', top: '-10%', right: '-10%' }}
            width={'22px'}
            height={'22px'}
          />
          <Box
            position={'absolute'}
            top={'-10%'}
            right={'-10%'}
            width={'22px'}
            height={'22px'}
            sx={{
              color: theme.palette.primary.darkBlueHigh,
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="labelRegular10" sx={{ fontWeight: 'bold', fontSize: '8px' }}>
              {calculateConnectionCounts}
            </Typography>
          </Box>
        </>
      )}
      <Handle position={Position.Right} type="source" style={HandlerStyle} />
      <Handle position={Position.Left} type="target" style={HandlerStyle} />
    </motion.div>
  );
};
