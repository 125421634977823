import { Box, CircularProgress, Typography, styled, Button as MuiButton } from '@mui/material';
import theme from 'core/theme';

export const VerticalDivider = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  width: 1,
  height: 16,
  position: 'relative',
  '&::before': {
    content: '" "',
    height: 16,
    position: 'absolute',
    top: '-50%',
    width: 1,
    backgroundColor: theme.palette.neutral.n200,
    marginBottom: -4,
  },
}));

export const LoadingComponent = () => (
  <Box display={'flex'} flex={2} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap={2}>
    <CircularProgress />
    <Typography sx={{ color: theme.palette.neutral.n400 }}>Loading...</Typography>
  </Box>
);

export const SecondaryButton = styled(MuiButton)(({ theme }) => ({
  padding: '8px 16px',
  backgroundColor: theme.palette.neutral.white,
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  ':hover': {
    backgroundColor: theme.palette.primary.subtone3,
    color: theme.palette.primary.main,
  },
  ':active': {
    backgroundColor: theme.palette.primary.subtone2,
  },
  ':focused': {
    borderWidth: 2,
  },
  '& .MuiButton-startIcon': {
    marginRight: 8,
    '& svg, & svg path': {
      fill: theme.palette.primary.main,
    },
  },
}));
