import { Grid, Typography } from '@mui/material';
import { TextButton } from '../../../../ui';
import { FC } from 'react';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { ConditionType } from 'pages/RuleForm/types';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { LogicalOperator, RuleWhereArray } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { getAnomalyEmptyRootConditional } from 'pages/RuleForm/utils';

type AddLogicalOperatorButtonProps = {
  index: number;
  where: RuleWhereArray;
  type: ConditionType;
  wherePath: string;
};

const AddLogicalOperatorButton: FC<AddLogicalOperatorButtonProps> = (props) => {
  const { index, where, wherePath } = props;
  const { setFieldValue } = useRuleForm();

  const addLogicalOperator = (index: number) => {
    const logicalOperatorIndex = index + 1;
    const conditionIndex = index + 2;

    const initialLogicalOperator: LogicalOperator = {
      type: RuleComponentType.LOGICAL_OPERATOR,
      value: 'OR',
    };

    const initialCondition = getAnomalyEmptyRootConditional();
    setFieldValue({ path: `${wherePath}[${logicalOperatorIndex}]`, value: initialLogicalOperator });
    setFieldValue({ path: `${wherePath}[${conditionIndex}]`, value: initialCondition });
  };

  return (
    <>
      {!where[index + 1] && (
        <Grid container display="flex" justifyContent="flex-start" sx={{ my: 'auto', marginLeft: '1rem' }}>
          <TextButton
            onClick={addLogicalOperator.bind(this, index)}
            sx={{
              mt: 0,
              p: 0,
              height: '100%',
            }}
          >
            <Typography sx={{ mt: '0.5rem' }} variant="labelRegular12">
              Add &quot;or/and&quot;
            </Typography>
          </TextButton>
        </Grid>
      )}
    </>
  );
};

export { AddLogicalOperatorButton };
