export enum EdgeType {
  floating = 'floating',
  duplicate = 'duplicate',
  EventEdge = 'EventEdge',
}

export class Edge {
  public data: { id: string; type: string };
  public properties: { source: string; target: string; hidden: boolean; focusable: boolean };
  constructor(
    id: string,
    type: EdgeType,
    source: string,
    target: string,
    options: { hidden?: boolean; focusable?: boolean } = { hidden: false, focusable: false },
  ) {
    this.data = { id, type };
    this.properties = { source, target, hidden: options.hidden || false, focusable: options.focusable || false };
  }
}
