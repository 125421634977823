import React from 'react';
import { SvgIconTypeMap, SxProps, Theme, Tooltip } from '@mui/material';

import LowRiskIcon from 'assets/ruleLibrary/ruleForm/lowRisk.png';
import HighRiskIcon from 'assets/ruleLibrary/ruleForm/highRisk.png';
import MediumRiskIcon from 'assets/ruleLibrary/ruleForm/mediumRisk.png';

import ErrorIcon from '@mui/icons-material/Error';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import { OverridableComponent } from '@mui/material/OverridableComponent';

export type CircleIconProps = {
  size: string | number;
  color?: React.CSSProperties['backgroundColor'];
} & Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'width' | 'height'>;

export const CircleIcon = ({ size, color, style, ...props }: CircleIconProps) => (
  <div
    style={{
      display: 'flex',
      borderRadius: '50%',
      alignItems: 'center',
      backgroundColor: color,
      justifyContent: 'center',
      width: size,
      height: size,
      ...style,
    }}
    {...props}
  />
);

export type RiskLevel = 'low' | 'medium' | 'high';

const riskIconsSrc: Record<RiskLevel, string> = {
  low: LowRiskIcon,
  high: HighRiskIcon,
  medium: MediumRiskIcon,
};

export type RiskIconProps = {
  title?: string;
  level: RiskLevel;
};

export const RiskIcon = ({ level, title }: RiskIconProps) => {
  const iconSrc = riskIconsSrc[level];
  const icon = iconSrc ? <img src={iconSrc} alt={level} /> : <ReportProblemOutlinedIcon fontSize="small" />;

  return !title ? icon : <Tooltip title={title}>{icon}</Tooltip>;
};

type RuleStatusSxMuiIconArray = [
  SxProps<Theme>,
  SxProps<Theme>,
  OverridableComponent<SvgIconTypeMap<any, 'svg'>> & { muiName: string },
];

export type RuleStatus = 'completed' | 'failed' | 'requested' | 'processing';

const failSx: SxProps<Theme> = { color: 'red.main' };
const processSx: SxProps<Theme> = { color: 'orange.main' };

const ruleStatusIcons: Record<RuleStatus, RuleStatusSxMuiIconArray> = {
  failed: [failSx, failSx, ErrorIcon],
  requested: [processSx, processSx, PendingIcon],
  processing: [processSx, processSx, PendingIcon],
  completed: [{ color: 'green.main' }, { color: 'neutral.n200' }, CheckCircleIcon],
};

export type RuleStatusIconProps = {
  active: boolean;
  status: RuleStatus;
};

export const RuleStatusIcon = ({ active, status }: RuleStatusIconProps) => {
  const ruleStatusIcon = ruleStatusIcons[status];
  const title = `${status}, ${active ? 'active' : 'inactive'}`;

  if (!ruleStatusIcon) {
    return (
      <Tooltip title="status not found">
        <ReportProblemOutlinedIcon fontSize="small" sx={{ p: 0.2, ...failSx }} />
      </Tooltip>
    );
  } else {
    const [activeSx, inactiveSx, StatusIcon] = ruleStatusIcon;

    return (
      <Tooltip title={title}>
        <StatusIcon
          fontSize="small"
          sx={{
            p: 0.2,
            ...(active ? activeSx : inactiveSx),
          }}
        />
      </Tooltip>
    );
  }
};
