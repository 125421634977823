import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { getRecordAnomalies, getRuleByIds } from './actions';
import { DataAnomalyState } from './types';
import _ from 'lodash';

export const initialState: DataAnomalyState = {
  loading: false,
  error: false,
  success: false,
  data: {
    getRecordAnomaliesState: { loading: false, error: false, success: false },
    getRuleByIdsState: { loading: false, error: false, success: false },
    recordAnomaliesCache: {},
  },
};

const dataAnomalyReducer = createSlice({
  name: 'dataAnomaly',
  initialState: _.cloneDeep(initialState),
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<DataAnomalyState>): void => {
    builder.addCase(getRecordAnomalies.pending, (state) => {
      state.data.getRecordAnomaliesState.loading = true;
      state.data.getRecordAnomaliesState.error = false;
      state.data.getRecordAnomaliesState.success = false;
    });

    builder.addCase(getRecordAnomalies.fulfilled, (state, { payload, meta }) => {
      state.data.getRecordAnomaliesState.loading = false;
      state.data.getRecordAnomaliesState.error = false;
      state.data.getRecordAnomaliesState.data = payload;
      state.data.getRecordAnomaliesState.success = true;
      const key = `${meta.arg.migrationId}-${meta.arg.tableName}-${meta.arg.recordId}`;
      state.data.recordAnomaliesCache[key] = payload;
    });

    builder.addCase(getRecordAnomalies.rejected, (state, { error }) => {
      state.data.getRecordAnomaliesState.loading = false;
      state.data.getRecordAnomaliesState.error = error?.message ?? true;
      state.data.getRecordAnomaliesState.success = false;
    });

    builder.addCase(getRuleByIds.pending, (state) => {
      state.data.getRuleByIdsState.loading = true;
      state.data.getRuleByIdsState.error = false;
      state.data.getRuleByIdsState.success = false;
    });

    builder.addCase(getRuleByIds.fulfilled, (state, { payload }) => {
      state.data.getRuleByIdsState.loading = false;
      state.data.getRuleByIdsState.error = false;
      state.data.getRuleByIdsState.data = payload;
      state.data.getRuleByIdsState.success = true;
    });

    builder.addCase(getRuleByIds.rejected, (state, { error }) => {
      state.data.getRuleByIdsState.loading = false;
      state.data.getRuleByIdsState.error = error?.message ?? true;
      state.data.getRuleByIdsState.success = false;
    });
  },
});

export default dataAnomalyReducer.reducer;
