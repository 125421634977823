import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from 'store/types';
import { useActionCreator } from 'hooks';

import {
  setError,
  setSuccess,
  updateName,
  addDataSource,
  setDataSource,
  initDataSource,
  updateTemplate,
  resetGraphSpace,
  toggleIsArchived,
  updateDataSource,
  updateObjectStart,
  updateDescription,
  addGraphSpaceSetup,
  updateGraphSpaceValidations,
  mergeDataSource,
} from './actions';

import { GraphSpaceReturnHook } from './types';

export const useGraphSpace = (): GraphSpaceReturnHook => {
  const graphSpaceState = useSelector((state: RootState) => state.graphSpace, shallowEqual);

  const currentDataSource = (() => {
    const dataSources = graphSpaceState.data.graphSpace.dataSources;

    if (!!dataSources && dataSources.length > 0) {
      return dataSources[dataSources.length - 1];
    }

    return undefined;
  })();

  return {
    currentDataSource,
    ...graphSpaceState,
    // graphSpaceHome
    updateName: useActionCreator(updateName),
    toggleIsArchived: useActionCreator(toggleIsArchived),
    updateDescription: useActionCreator(updateDescription),
    addGraphSpaceSetup: useActionCreator(addGraphSpaceSetup),
    updateGraphSpaceValidations: useActionCreator(updateGraphSpaceValidations),

    // createNewGraphSpace
    updateTemplate: useActionCreator(updateTemplate),
    resetGraphSpace: useActionCreator(resetGraphSpace),
    updateObjectStart: useActionCreator(updateObjectStart),

    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    updateDataSource: useActionCreator(updateDataSource),

    // addDataSource
    setDataSource: useActionCreator(setDataSource),
    addDataSource: useActionCreator(addDataSource),
    initDataSource: useActionCreator(initDataSource),
    mergeDataSource: useActionCreator(mergeDataSource),
  };
};
