import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { FC } from 'react';

export interface ServiceValidatorSelectorOption {
  label: string;
  value: string;
  patternId: string;
  fieldName: string;
}

export interface ServiceValidatorSelector {
  id: string;
  currentValue: string;
  fieldOptions: ServiceValidatorSelectorOption[];
  onChange: (value: string) => void;
}

export const ServiceValidatorSelector: FC<ServiceValidatorSelector> = (props: ServiceValidatorSelector) => {
  const { id, currentValue, fieldOptions, onChange } = props;
  return (
    <ControlledCustomDropdown<string>
      id={id}
      placeholder="Validation"
      label="Validation Selection"
      value={currentValue || ''}
      options={fieldOptions || []}
      sx={{ backgroundColor: 'neutral.white' }}
      fallbackOption={{ label: 'Select a Validation', value: '' }}
      onSelect={(value) => onChange(value)}
    />
  );
};
