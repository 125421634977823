import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useFileUploader } from './useFileUploader';
import { FileCard } from './components/FileCard';

const UploadFiles = () => {
  const { files, uploadProgress, processFiles, togglePreview, deleteFile } = useFileUploader();

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files);
    processFiles(newFiles);
    event.dataTransfer.clearData();
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || []);
    processFiles(newFiles);
  };

  return (
    <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" mb={1}>
        Upload your files
      </Typography>
      <Typography variant="body2" color="neutral.n400" align="center" mb={4}>
        Before uploading your files, make sure they are ready to be imported.
      </Typography>

      <Box
        sx={{
          border: '1px dashed #9DA1B5',
          borderRadius: '4px',
          width: '840px',
          height: files.length > 0 ? '96px' : '316px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          textAlign: 'center',
          flexDirection: files.length > 0 ? 'row' : 'column',
          backgroundColor: '#F4F9FF',
          p: 2,
        }}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <FolderOpenIcon sx={{ fontSize: 50, color: 'primary.main', mb: files.length === 0 ? 2 : 0 }} />
        <Typography variant="p16" mb={files.length === 0 ? 1 : 0}>
          Select a file or drag and drop here
        </Typography>
        <Typography variant="p14" color="neutral.n400" display={'block'}>
          All .csv, .xlsx, and .xls file types are supported
        </Typography>
        <input
          type="file"
          accept=".csv, .xlsx, .xls"
          style={{ display: 'none' }}
          id="file-upload"
          onChange={handleFileSelect}
        />
        <label htmlFor="file-upload">
          <Button
            component="span"
            variant="outlined"
            sx={{ mt: files.length === 0 ? 2 : 0 }}
            startIcon={<CloudUploadIcon />}
          >
            Upload a file
          </Button>
        </label>
      </Box>

      {files.length !== 0 && (
        <Grid container direction="column" sx={{ justifyContent: 'center', alignItems: 'center', my: 4 }}>
          {files.map((file, index) => (
            <FileCard
              key={index}
              file={file}
              uploadProgress={uploadProgress[index]}
              onDelete={() => deleteFile(index)}
              onTogglePreview={() => togglePreview(index)}
            />
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default UploadFiles;
