import React from 'react';
import NoteCard from '../ActivityCard/NoteCard';
import InteractionCard from '../ActivityCard/InteractionCard';
import EventTaskCard from './EventTaskCard';

interface ActivityCardProps {
  activity: any;
  node: any;
  contact: any;
}
const ActivityCard: React.FC<ActivityCardProps> = ({ activity, node, contact }: ActivityCardProps) => {
  const components = [<NoteCard key="noteCard" />];

  if (activity?.TaskSubtype === 'Task') return <EventTaskCard type="task" activity={activity} node={contact ?? node} />;
  if (activity?.EventSubtype === 'Event')
    return <EventTaskCard type="event" activity={activity} node={contact ?? node} />;
  if (activity?.EventSubtype === 'Call' || activity?.TaskSubtype === 'Call')
    return <InteractionCard type="call" activity={activity} node={contact ?? node} />;
  if (activity?.EventSubtype === 'Email' || activity?.TaskSubtype === 'Email')
    return <InteractionCard type="email" activity={activity} node={contact ?? node} />;

  return <></>;
};

export default ActivityCard;
