import { Box, Grid, InputAdornment } from '@mui/material';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { GridItem } from 'components/ui';
import { ConditionType } from 'pages/RuleLibrary/types';
import { Option } from 'store/ruleForm/types';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { FieldReference, FunctionValue, LookUpTable } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { FC, useCallback, useMemo, useState } from 'react';
import { LOOK_UP_FUNCTION_OPTIONS, LookUpFunctionOptions } from '../../Constants';
import { useRuleFormStore } from 'store/ruleForm/hook';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorExclamation.svg';
import _ from 'lodash';
import { FormErrorMessage } from '../../../../../../ui/FormErrorMessage';
import { FieldSelector } from './LookUpValueForm/FieldSelector';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';

interface FunctionValueProps {
  targetPath: string;
  index: number;
  conditionType: ConditionType;
  conditionalValueComponent: FunctionValue;
  fieldOptions: Option[];
  fieldForeignRefOptions: Option[];
  setFieldValue: (payload: { path: string; value: any }) => void;
  getConditionTypeDropdown: () => JSX.Element;
  conditionalFunctionOptionsFiltered: LookUpFunctionOptions[];
  handleOnSelectLookUpField: (exactPath: string, value: string, type?: ConditionType) => void;
}

export const FunctionValueForm: FC<FunctionValueProps> = (props) => {
  const {
    conditionType,
    conditionalValueComponent,
    fieldForeignRefOptions,
    fieldOptions,
    targetPath,
    conditionalFunctionOptionsFiltered,
    handleOnSelectLookUpField,
    setFieldValue,
    getConditionTypeDropdown,
  } = props;
  const { selectedMigration: migrationId } = useRuleForm();

  const { formError } = useRuleFormStore();
  const [paramValue, setParamValue] = useState<string>('');
  const nameError = useMemo(() => formError && _.get(formError, `${targetPath}.functionName`), [formError, targetPath]);
  const valueError = useMemo(() => formError && _.get(formError, `${targetPath}.value`), [formError, targetPath]);
  const lookUpValueComponent = conditionalValueComponent as FunctionValue;
  let options = conditionType === ConditionType.WHERE ? fieldOptions : fieldForeignRefOptions;
  const functionOption = LOOK_UP_FUNCTION_OPTIONS.find((option) => option.value === lookUpValueComponent.function);
  if (functionOption && conditionType === ConditionType.WHERE) {
    const functionAllowType = functionOption.acceptType;
    options = options?.filter(
      (option) => functionAllowType.includes(option.xsd_type || '') || functionAllowType.includes('*'),
    );
  }

  const handleFunctionChange = useCallback(
    (value: string) => {
      setFieldValue({ path: `${targetPath}.function`, value });
      setFieldValue({ path: `${targetPath}.value`, value: [] });
    },
    [setFieldValue, targetPath],
  );

  return (
    <Grid display={'flex'} width={'100%'} flexDirection={'column'} gap={1}>
      <Box display={'flex'}>
        <GridItem xs={6}>{getConditionTypeDropdown()}</GridItem>
      </Box>
      <Box display={'flex'} gap={1}>
        <GridItem xs={6}>
          <Box>
            <ControlledCustomDropdown<string>
              id={`${targetPath}.function`}
              placeholder="Function"
              fallbackOption={{ label: 'Select a Value', value: '' }}
              options={conditionalFunctionOptionsFiltered.map((item) => ({ value: item.value, label: item.label }))}
              sx={{
                backgroundColor: 'neutral.white',
                width: '100%',
                ...(nameError ? { border: '1px solid red', borderRadius: '4px' } : {}),
              }}
              onSelect={handleFunctionChange}
              value={lookUpValueComponent.function || null}
              InputProps={{
                startAdornment: nameError && <InputAdornment position="start">{<ErrorIcon />}</InputAdornment>,
              }}
            />
            <FormErrorMessage errorMessage={nameError} />
          </Box>
        </GridItem>
        <GridItem xs={6}>
          {lookUpValueComponent.value[0]?.value &&
          lookUpValueComponent.value[0]?.type === RuleComponentType.LOOKUP_VALUE ? (
            <Grid display={'flex'}>
              <GridItem flex={10}>
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  <ControlledCustomDropdown<string>
                    id={`${targetPath}.value[0]`}
                    placeholder="Field Name"
                    value={paramValue || ''}
                    options={options || []}
                    sx={{
                      backgroundColor: 'neutral.white',
                      ...(valueError ? { border: '1px solid red', borderRadius: '4px' } : {}),
                    }}
                    fallbackOption={{ label: 'Select a Value', value: '' }}
                    onSelect={(value) => {
                      handleOnSelectLookUpField(`${targetPath}.value[0]`, value, conditionType);
                      setParamValue(value);
                    }}
                    InputProps={{
                      startAdornment: valueError && <InputAdornment position="start">{<ErrorIcon />}</InputAdornment>,
                    }}
                  />
                  <FieldSelector
                    ignoreRelationshipFields
                    migrationId={migrationId}
                    tableId={lookUpValueComponent.value[0]?.value[0]?.referenceTable || ''}
                    index={1}
                    value={lookUpValueComponent.value[0]?.value[1].value || undefined}
                    handleSelectComponent={(component: LookUpTable | FieldReference) => {
                      setFieldValue({ path: `${targetPath}.value[0].value[1]`, value: component });
                    }}
                  />
                  <FormErrorMessage errorMessage={valueError} />
                </Box>
              </GridItem>
            </Grid>
          ) : (
            <Box>
              <ControlledCustomDropdown<string>
                id={`${targetPath}.value[0]`}
                placeholder="Field Name"
                value={lookUpValueComponent?.value[0]?.value || ''}
                options={options || []}
                sx={{
                  backgroundColor: 'neutral.white',
                  ...(valueError ? { border: '1px solid red', borderRadius: '4px' } : {}),
                }}
                fallbackOption={{ label: 'Select a Value', value: '' }}
                onSelect={(value) => {
                  handleOnSelectLookUpField(`${targetPath}.value[0]`, value, conditionType);
                  setParamValue(value);
                }}
                InputProps={{
                  startAdornment: valueError && <InputAdornment position="start">{<ErrorIcon />}</InputAdornment>,
                }}
              />
              <FormErrorMessage errorMessage={valueError} />
            </Box>
          )}
        </GridItem>
      </Box>
    </Grid>
  );
};
