import { Box, Card, Icon, Typography } from '@mui/material';
import React from 'react';

type ReviewDataCardProps = {
  title: string;
  value: number;
  icon?: React.ReactNode;
};

const getColor = (title: string): string => {
  const colors: { [key: string]: string } = {
    'Total records': 'neutral.main',
    'Mapped fields': 'green.main',
    'Unmapped fields': 'orange.main',
  };

  return colors[title] || 'neutral.main';
};

const ReviewDataCard: React.FC<ReviewDataCardProps> = ({ title, value, icon }) => {
  return (
    <Card
      elevation={0}
      sx={{
        height: '104px',
        width: '207px',
        backgroundColor: 'lightBg.main',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      <Box display={'flex'} alignItems={'center'} gap={0.5}>
        <Typography variant="p12" color={'neutral.main'}>
          {title}
        </Typography>
        {icon}
      </Box>
      <Typography variant="h1" color={getColor(title)}>
        {value}
      </Typography>
    </Card>
  );
};

export default ReviewDataCard;
