import React, { useState, useEffect, FC } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { ColumnSortedAscendingIcon, ColumnSortedDescendingIcon, ColumnUnsortedIcon } from 'pages/DataRaptor/ui';
import { RuleTemporalTable } from 'store/dataRaptorRule/types';
import { ConfidenceScore } from 'components/ConfidenceScore';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';

export interface WhereConditionRecord {
  id: number;
  office: string;
  recordId: string;
  confidenceScore: number;
}

const IGNORED_COLUMNS = ['confidence_score', 'rules_applied'];
const PRIORITIZED_COLUMNS = [
  'Id',
  'Name',
  'LastName',
  'Email',
  'Phone',
  'Mobile',
  'Address',
  'City',
  'State',
  'ZipCode',
];

const gridRecordColumns: (width: number, sampleRecord: any) => GridColumns<any> = (
  width: number,
  sampleRecord: any,
) => {
  const columns = [];
  const processedColumns = new Set<string>();

  columns.push({
    width: width * 0.2,
    field: 'confidence_score',
    headerName: 'confidence score',
    renderCell: (props: any) => {
      const row: any = props.row;
      return (
        <>
          <ConfidenceScore confidenceScore={row.confidence_score || 0} />
        </>
      );
    },
  });

  PRIORITIZED_COLUMNS.forEach((key) => {
    if (sampleRecord[key]) {
      columns.push({
        width: width * 0.2,
        field: key,
        headerName: key,
      });
      processedColumns.add(key);
    }
  });

  Object.keys(sampleRecord || {}).forEach((key) => {
    if (IGNORED_COLUMNS.includes(key) || processedColumns.has(key)) return;
    columns.push({
      width: width * 0.2,
      field: key,
      headerName: key,
    });
  });

  return columns;
};

interface WhereConditionTableProps {
  tempTable: RuleTemporalTable;
}

export const WhereConditionTable: FC<WhereConditionTableProps> = ({ tempTable }) => {
  const ref = React.useRef<HTMLDivElement>();
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [columns, setColumns] = useState<GridColumns<WhereConditionRecord>>([]);

  const tableData = useGetMigrationTableDataByIdQuery({
    migrationId: tempTable.dataMigrationId,
    tableId: tempTable.table,
    skip: 0,
    take: 5,
    conditions: [
      {
        field: 'Id',
        operator: 'in',
        value: `(${tempTable.sampleIds?.map((id) => `'${id}'`).join(',')})`,
      },
    ],
  });

  useEffect(() => {
    if (tableData.isSuccess || tableData.isError) {
      setLoading(false);
    }
  }, [tableData.isError, tableData.isSuccess]);

  useEffect(() => {
    if (tableData.data) {
      setData(tableData.data);
    }
  }, [tableData.data]);

  useEffect(() => {
    if (ref.current && data.length > 0) {
      setColumns(gridRecordColumns(ref.current.clientWidth, data[0]));
    }
  }, [data]);

  return (
    <Box ref={ref} height={250} component={'div'}>
      {loading && (
        <Box display="flex" flexDirection={'column'} alignItems={'center'} justifyContent={'center'} height={250}>
          <CircularProgress />
          <Typography>Loading...</Typography>
        </Box>
      )}
      {!loading && (
        <DataGrid
          hideFooter
          density="compact"
          rows={data}
          columns={columns}
          getRowId={(r) => r.Id}
          disableSelectionOnClick
          components={{
            ColumnUnsortedIcon,
            ColumnSortedAscendingIcon,
            ColumnSortedDescendingIcon,
          }}
        />
      )}
    </Box>
  );
};
