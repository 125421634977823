import React, { FC, useState } from 'react';

import { Collapse, List, ListItemButton, ListItemButtonProps, ListItemIcon, ListProps } from '@mui/material';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export type ListItemButtonMenuProps = {
  defaultOpen?: boolean;
  children: JSX.Element[];
} & ListItemButtonProps;

export const ListItemButtonMenu: FC<ListItemButtonMenuProps> = ({ onClick, children, defaultOpen, ...props }) => {
  const items = [...children];
  const menuList = items.pop();
  const [open, setOpen] = useState(defaultOpen ?? false);

  return (
    <>
      <ListItemButton
        {...props}
        onClick={(e) => {
          setOpen(!open);
          onClick?.(e);
        }}
      >
        {items}
        <ListItemIcon>
          {open ? (
            <KeyboardArrowUpIcon fontSize="small" htmlColor="#1554FF" />
          ) : (
            <KeyboardArrowDownIcon fontSize="small" htmlColor="#1554FF" />
          )}
        </ListItemIcon>
      </ListItemButton>
      <Collapse in={open} timeout={'auto'} unmountOnExit>
        {menuList}
      </Collapse>
    </>
  );
};

export type ListCollapseButtonProps = {
  opened?: boolean;
  listProps?: ListProps;
  children: React.ReactNode;
  renderButton: (open: boolean) => React.ReactNode;
} & ListItemButtonProps;

export const ListCollapseButton: FC<ListCollapseButtonProps> = ({
  listProps = {},
  opened,
  children,
  onClick,
  renderButton,
  ...props
}) => {
  listProps = listProps ?? {};
  const [open, setOpen] = useState(opened ?? false);

  return (
    <>
      <ListItemButton
        {...props}
        onClick={(e) => {
          setOpen(!open);
          onClick?.(e);
        }}
      >
        {renderButton(open)}
      </ListItemButton>
      <Collapse in={open} timeout={'auto'} unmountOnExit>
        <List disablePadding {...listProps}>
          {children}
        </List>
      </Collapse>
    </>
  );
};
