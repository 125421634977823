export const generateCustomEdgePath = (
  sourceX: number,
  sourceY: number,
  targetX: number,
  targetY: number,
  sourceWidth: number,
  targetWidth: number,
): [string, number] => {
  const dx = targetX - sourceX;
  const dy = targetY - sourceY;
  const rad = Math.atan2(dy, dx);
  const dist = Math.sqrt(dx ** 2 + dy ** 2);
  const sourceWidthRadius = ((sourceWidth || 0) / 2) * 1;
  const targetWidthRadius = ((targetWidth || 0) / 2) * 1;
  const xMiddle = (dist - sourceWidthRadius - targetWidthRadius) / 2 + sourceWidthRadius;
  const ankleMultiplier = 1;
  const yRadiusMultiplier = 0.82;
  const xRadiusMultiplier = 0.5;
  let minWidth = sourceWidthRadius > targetWidthRadius ? targetWidthRadius : sourceWidthRadius;
  minWidth = minWidth / (dist / 40);

  const points = [
    ['M', sourceX, sourceY],
    ['L', sourceX + sourceWidthRadius * xRadiusMultiplier, sourceY - sourceWidthRadius * yRadiusMultiplier],
    ['Q', sourceX + sourceWidthRadius * ankleMultiplier, sourceY - minWidth, sourceX + xMiddle, sourceY - minWidth],
    [
      'Q',
      sourceX + dist - targetWidthRadius * ankleMultiplier,
      sourceY - minWidth,
      sourceX + dist - targetWidthRadius * xRadiusMultiplier,
      sourceY - targetWidthRadius * yRadiusMultiplier,
    ],
    ['L', sourceX + dist, sourceY],
    ['L', sourceX + dist - targetWidthRadius * xRadiusMultiplier, sourceY + targetWidthRadius * yRadiusMultiplier],
    [
      'Q',
      sourceX + dist - targetWidthRadius * ankleMultiplier,
      sourceY + minWidth,
      sourceX + xMiddle,
      sourceY + minWidth,
    ],
    [
      'Q',
      sourceX + sourceWidthRadius * ankleMultiplier,
      sourceY + minWidth,
      sourceX + sourceWidthRadius * xRadiusMultiplier,
      sourceY + sourceWidthRadius * yRadiusMultiplier,
    ],
    ['L', sourceX, sourceY],
  ];
  const path = points.map((tuple) => tuple.join(' ')).join(' ');
  return [path, rad];
};

export const generateCustomEdgePath2 = (
  sourceX: number,
  sourceY: number,
  targetX: number,
  targetY: number,
  sourceWidth: number,
  targetWidth: number,
): [string, number] => {
  const dx = targetX - sourceX;
  const dy = targetY - sourceY;
  const rad = Math.atan2(dy, dx);
  const dist = Math.sqrt(dx ** 2 + dy ** 2);
  const sourceWidthRadius = ((sourceWidth || 0) / 2) * 1;
  const targetWidthRadius = ((targetWidth || 0) / 2) * 1;
  const xMiddle = (dist - sourceWidthRadius - targetWidthRadius) / 2 + sourceWidthRadius;
  const ankleMultiplier = 1.3;
  const yRadiusMultiplier = 0.42;
  const xRadiusMultiplier = 0.5;
  let minWidth = sourceWidthRadius > targetWidthRadius ? targetWidthRadius : sourceWidthRadius;
  minWidth = minWidth / (dist / 2);

  const points = [
    ['M', sourceX, sourceY],
    ['L', sourceX + sourceWidthRadius * xRadiusMultiplier, sourceY - sourceWidthRadius * yRadiusMultiplier],
    ['Q', sourceX + sourceWidthRadius * ankleMultiplier, sourceY - minWidth, sourceX + xMiddle, sourceY - minWidth],
    [
      'Q',
      sourceX + dist - targetWidthRadius * ankleMultiplier,
      sourceY - minWidth,
      sourceX + dist - targetWidthRadius * xRadiusMultiplier,
      sourceY - targetWidthRadius * yRadiusMultiplier,
    ],
    ['L', sourceX + dist, sourceY],
    ['L', sourceX + dist - targetWidthRadius * xRadiusMultiplier, sourceY + targetWidthRadius * yRadiusMultiplier],
    [
      'Q',
      sourceX + dist - targetWidthRadius * ankleMultiplier,
      sourceY + minWidth,
      sourceX + xMiddle,
      sourceY + minWidth,
    ],
    [
      'Q',
      sourceX + sourceWidthRadius * ankleMultiplier,
      sourceY + minWidth,
      sourceX + sourceWidthRadius * xRadiusMultiplier,
      sourceY + sourceWidthRadius * yRadiusMultiplier,
    ],
    ['L', sourceX, sourceY],
  ];
  const path = points.map((tuple) => tuple.join(' ')).join(' ');
  return [path, rad];
};
