import useShareModal from '../../hooks/useShareModal';
import { PrimaryButton } from 'components/ui';
import ShareIcon from '@mui/icons-material/Share';
import ShareModal from '../ShareModal/ShareModal';

const ShareButton = () => {
  const { isModalOpen, openModal, closeModal } = useShareModal();

  return (
    <>
      <PrimaryButton sx={{ ml: 2 }} variant="outlined" onClick={openModal}>
        <ShareIcon sx={{ color: 'primary', fontSize: '14px', mr: 1 }} />
        Share
      </PrimaryButton>
      <ShareModal closeModal={closeModal} isModalOpen={isModalOpen} />
    </>
  );
};

export default ShareButton;
