import { FC, useMemo, useState } from 'react';
import { SecondaryButton } from '../../ui';
import { ReactComponent as FilterIcon } from 'assets/icons/filterBlue.svg';
import { Grid, Popper, Typography } from '@mui/material';
import theme from 'core/theme';
import { useRuleLibrary } from 'store/ruleLibrary/hook';
import { TemplateFilterList } from './components/TemplateFilterList';
import { RuleFilterList } from './components/RuleFilterList';
import { useFilterHook } from './RuleFilterHook';

const RuleFilter: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { selectedSubPage } = useRuleLibrary();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useFilterHook();

  const filterComponent = useMemo(() => {
    if (selectedSubPage === 'template') {
      return <TemplateFilterList />;
    } else {
      return <RuleFilterList />;
    }
  }, [selectedSubPage]);

  const open = Boolean(anchorEl);
  const id = open ? 'rule-library-rule-filter-popper' : undefined;

  return (
    <>
      <SecondaryButton sx={{ border: 'none' }} variant="outlined" onClick={handleClick} startIcon={<FilterIcon />}>
        <Typography variant="labelMedium14" sx={{ color: 'neutral.main' }}>
          Filters
        </Typography>
      </SecondaryButton>
      <Popper id={id} open={open} anchorEl={anchorEl} sx={{ maxHeight: '70vh', overflowY: 'scroll' }}>
        <Grid
          container
          display={'flex'}
          flexDirection={'column'}
          sx={{
            p: '12px',
            mt: 1,
            backgroundColor: theme.palette.neutral.white,
            width: 'fit-content',
            borderRadius: '4px',
          }}
          gap={0.5}
        >
          <Grid item>{filterComponent}</Grid>
        </Grid>
      </Popper>
    </>
  );
};

export default RuleFilter;
