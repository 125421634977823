import { ControlledCustomDropdown } from 'components/CustomDropdown';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { FC, useCallback } from 'react';
import { RuleValidationPattern } from 'store/dataRaptorRule/types';
import { ValidationPatternTag } from './ValidationPatternTag';
import { Box, Grid, InputAdornment } from '@mui/material';
import _ from 'lodash';
import { useMemo } from 'react';
import { GridItem } from 'components/ui';
import { FormErrorMessage } from 'pages/RuleLibrary/components/ui/FormErrorMessage';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorExclamation.svg';

export interface RegexArraySelectorProps {
  currentValue: string[];
  patterns: RuleValidationPattern[];
  targetPath: string;
}

export const RegexArraySelector: FC<RegexArraySelectorProps> = (props) => {
  const { targetPath, patterns, currentValue } = props;
  const { setFieldValue, formError } = useRuleForm();

  const error = useMemo(() => formError && _.get(formError, targetPath), [formError, targetPath]);

  const patternFiltered = useMemo(() => {
    return patterns.filter((pattern) => !currentValue.includes(pattern.validationPatternId));
  }, [currentValue, patterns]);

  const patternOptions = patternFiltered.map((pattern) => {
    return { label: `${pattern.label} \n ${pattern.example || ''}`, value: pattern.validationPatternId };
  });

  const selectedPatterns = patterns.filter((pattern) => currentValue.includes(pattern.validationPatternId));

  const handleAddPattern = useCallback(
    (id: string) => {
      const newValues = _.cloneDeep(currentValue);
      newValues.push(id);
      setFieldValue({ path: targetPath, value: newValues });
    },
    [currentValue, setFieldValue, targetPath],
  );

  const handleDeletePattern = useCallback(
    (id: string) => {
      const newValues = currentValue.filter((value) => value !== id);
      setFieldValue({ path: targetPath, value: newValues });
    },
    [currentValue, setFieldValue, targetPath],
  );

  const PatternTagArray = selectedPatterns.map((pattern) => {
    return (
      <ValidationPatternTag
        key={pattern.validationPatternId}
        label={pattern.label}
        patternId={pattern.validationPatternId}
        handleDelete={(id) => handleDeletePattern(id)}
      />
    );
  });

  return (
    <>
      <Grid display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
        <GridItem flex={2} sx={{ minWidth: '200px' }}>
          <Box>
            <ControlledCustomDropdown<string>
              id={`${targetPath}-field`}
              label=""
              placeholder="value"
              value={''}
              options={patternOptions || []}
              sx={{ backgroundColor: 'neutral.white' }}
              fallbackOption={{ label: 'Add a format', value: '' }}
              onSelect={(value) => handleAddPattern(value as string)}
              InputProps={{
                startAdornment: error && <InputAdornment position="start">{<ErrorIcon />}</InputAdornment>,
              }}
            />
            <FormErrorMessage errorMessage={error} />
          </Box>
        </GridItem>
        <GridItem flex={2}>
          <Grid container display={'flex'} justifyContent={'center'} alignContent={'flex-end'} gap={1}>
            {PatternTagArray}
          </Grid>
        </GridItem>
      </Grid>
    </>
  );
};
