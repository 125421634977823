import backendApi from 'store/api/backendApi';

import { Migration, MigrationTable, TableDataParameters, TableField } from './types';
import {
  GetRecordStatsScoreDiffParams,
  GetRuleStatParams,
  GetTableStatsParams,
  RecordStatScoreDiff,
  RuleStat,
  TableStat,
} from 'http/migration/dto';
import { getRecordAnomaliesArgs, GetRecordAnomaliesResponse } from 'store/dataAnomaly/types';

export type WithIcon<T> = {
  icon?: JSX.Element;
} & T;

export const migrationApi = backendApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getMigrations: builder.query<Migration[], void>({
        query: () => ({
          method: 'get',
          url: '/dataMigration',
        }),
      }),
      getMigrationTablesById: builder.query<MigrationTable[], { migrationId: string }>({
        query: ({ migrationId }) => ({
          method: 'get',
          url: `dataMigration/${migrationId}/tables`,
        }),
      }),
      getMigrationTableFieldsById: builder.query<TableField[], { migrationId: string; tableId: string }>({
        query: ({ migrationId, tableId }) => ({
          method: 'get',
          url: `dataMigration/${migrationId}/table/${tableId}/fields`,
        }),
      }),
      getMigrationTableStats: builder.query<TableStat[], GetTableStatsParams>({
        query: ({ migrationId, tableId, type, interval, startDate }) => ({
          method: 'get',
          url: `dataMigration/${migrationId}/table/${tableId}/stats`,
          params: { type, interval, startDate },
        }),
      }),
      getRecordStatsScoreDiff: builder.query<RecordStatScoreDiff[], GetRecordStatsScoreDiffParams>({
        query: ({ migrationId, tableId, type, interval, startDate1, startDate2 }) => ({
          method: 'get',
          url: `/dataMigration/${migrationId}/table/${tableId}/record-stats/score-diff`,
          params: { type, interval, startDate1, startDate2 },
        }),
      }),
      getRuleStatsByTable: builder.query<RuleStat[], GetRuleStatParams>({
        query: ({ migrationId, tableId, date, interval }) => ({
          method: 'get',
          url: `/dataMigration/${migrationId}/table/${tableId}/rule-stats`,
          params: { date, interval },
        }),
      }),
      getMigrationTableDataById: builder.query<any[], TableDataParameters>({
        query: ({
          migrationId,
          tableId,
          skip,
          take,
          conditions = [],
          fields = [],
          orderBy = [],
          action = 'retrieve',
        }) => {
          return {
            method: 'post',
            url: `/dataMigration/${migrationId}/table/${tableId}/data?skip=${skip}&take=${take}&action=${action}`,
            body: {
              fields,
              conditions,
              orderBy,
            },
          };
        },
      }),
      getRecordAnomalies: builder.query<GetRecordAnomaliesResponse, getRecordAnomaliesArgs>({
        query: ({ migrationId, tableName, recordId }) => {
          return {
            method: 'get',
            url: `/data-raptor-rule-data-anomaly/migration/${migrationId}/table/${tableName}/record/${recordId}`,
          };
        },
      }),
    };
  },
});

export const {
  usePrefetch,
  useGetMigrationsQuery,
  useLazyGetMigrationsQuery,
  useGetMigrationTablesByIdQuery,
  useLazyGetMigrationTablesByIdQuery,
  useGetMigrationTableDataByIdQuery,
  useLazyGetMigrationTableDataByIdQuery,
  useGetMigrationTableFieldsByIdQuery,
  useLazyGetMigrationTableFieldsByIdQuery,
  useGetMigrationTableStatsQuery,
  useLazyGetMigrationTableStatsQuery,
  useGetRecordStatsScoreDiffQuery,
  useLazyGetRecordStatsScoreDiffQuery,
  useGetRecordAnomaliesQuery,
  useLazyGetRecordAnomaliesQuery,
  useGetRuleStatsByTableQuery,
  useLazyGetRuleStatsByTableQuery,
} = migrationApi;
