import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';

import { ColumnSortedAscendingIcon, ColumnSortedDescendingIcon, ColumnUnsortedIcon } from 'pages/DataRaptor/ui';
import theme from 'core/theme';
import { useDashboard } from 'store/dashboard/hook';
import { useGetRulesByTableQuery } from 'store/dataRaptorRule/api';
import { Show } from '../../../../../../../../../../../../components/show';
import { LoadingComponent } from 'pages/RuleLibrary/ui';
import { getHumanReadableDate } from '../../../../../../../../../../../../core/utils/time';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';

export interface ActiveRulesAndScoreImpactData {
  id: string;
  name: string;
  updatedAt: string;
  impactPercentage: number;
}

const getActiveRulesColumns: (width: number) => GridColumns<ActiveRulesAndScoreImpactData> = (width) => [
  {
    width: 0.4 * width,
    field: 'name',
    renderCell: ({ row }) => (
      <Box display="flex" justifyContent="space-between" alignItems="center" textAlign={'center'}>
        <Tooltip title={'View Rule'}>
          <Link to={`${PRIVATE_ABS_ROUTE_PATHS.ruleLibrary}?searchRuleId=${row.id}`} target={'_blank'}>
            <Typography fontSize={14} sx={{ textUnderlinePosition: 'none' }}>
              {row.name}
            </Typography>
          </Link>
        </Tooltip>
      </Box>
    ),
  },
  {
    align: 'center',
    field: 'updatedAt',
    width: 0.4 * width,
    renderCell: ({ row }) => (
      <Box display={'flex'} justifyContent={'center'}>
        <Typography fontSize={12} color={'#898EA1'}>
          {getHumanReadableDate(row.updatedAt)}
        </Typography>
      </Box>
    ),
  },
  {
    align: 'right',
    width: 0.2 * width,
    field: 'impactPercentage',
    renderCell: ({ row }) => (
      <Chip
        size="small"
        label={`${row.impactPercentage}%`}
        sx={{
          color: 'white',
          backgroundColor: theme.palette.green.main,
        }}
      />
    ),
  },
];

const pageSize = 3;

export default function ActiveRulesTable() {
  const ref = useRef<HTMLDivElement>();
  const [columns, setColumns] = useState(getActiveRulesColumns(0));
  const {
    data: { selectedTable, migrationTableInfo, selectedMigrationId },
  } = useDashboard();

  const tableData = useMemo(() => {
    if (selectedTable) {
      return migrationTableInfo.find((table) => table.table_name === selectedTable);
    }
    return null;
  }, [migrationTableInfo, selectedTable]);

  const data = useGetRulesByTableQuery({ migrationId: selectedMigrationId, table: selectedTable });

  const formattedData: ActiveRulesAndScoreImpactData[] = useMemo(() => {
    if (!data.data) {
      return [];
    }
    const rules = data.data;
    const filteredRules = rules.filter((rule) => rule.active);
    const row = filteredRules.map((rule) => {
      let impactPercentage = rule.violationScore;
      const affectedRowCount = rule.violatedRowCount || 0;
      if (tableData && affectedRowCount > 0) {
        const totalScore = tableData.row_count * 100;
        const impactedScore = affectedRowCount * rule.violationScore;
        impactPercentage = _.round((impactedScore * 100) / totalScore, 2);
      }
      if (affectedRowCount === 0) {
        impactPercentage = 0;
      }
      return {
        id: rule.ruleId,
        name: rule.name,
        updatedAt: rule.updatedAt || '',
        impactPercentage: impactPercentage,
      };
    });
    return row.sort((a, b) => b.impactPercentage - a.impactPercentage);
  }, [data.data, tableData]);

  const handleResize = () => {
    const width = ref.current?.clientWidth ?? 100;
    setColumns(getActiveRulesColumns(width));
  };

  useEffect(() => {
    handleResize();
    ref.current?.addEventListener('resize', handleResize);
    const refTmp = ref.current;
    return () => {
      refTmp?.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box ref={ref} width={'100%'} height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Show when={!data.isFetching} fallback={<LoadingComponent />}>
        <DataGrid
          disableColumnFilter
          disableColumnSelector
          disableSelectionOnClick
          rows={formattedData ?? []}
          columns={[...columns]}
          rowHeight={56}
          headerHeight={0}
          rowsPerPageOptions={[pageSize]}
          pageSize={pageSize}
          getRowId={(r) => r.id}
          density="comfortable"
          components={{
            ColumnUnsortedIcon,
            ColumnSortedAscendingIcon,
            ColumnSortedDescendingIcon,
          }}
        />
      </Show>
    </Box>
  );
}
