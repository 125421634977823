import React from 'react';
import { Stack, Typography, Popover, Box, Divider, TextField } from '@mui/material';
import { format, add, isSameDay, isBefore, isAfter } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { CustomPickersDay } from './StyledPickerDay';

const PeriodSelector = () => {
  const [selectedPeriod, setSelectedPeriod] = React.useState<{
    from: Date;
    to: Date;
  }>({
    from: add(new Date(), { months: -6 }),
    to: new Date(),
  });
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [customSelecting, setCustomSelecting] = React.useState<boolean>(false);
  const [currentSelectingIndex, setCurrentSelectingIndex] = React.useState<0 | 1>(0);

  const periods = [
    {
      label: 'Last 12 Month',
      value: {
        from: add(new Date(), { months: -12 }),
        to: new Date(),
      },
    },
    {
      label: 'Last 6 Month',
      value: {
        from: add(new Date(), { months: -6 }),
        to: new Date(),
      },
    },
    {
      label: 'Last 30 Days',
      value: {
        from: add(new Date(), { days: -30 }),
        to: new Date(),
      },
    },
    {
      label: 'Last 7 Days',
      value: {
        from: add(new Date(), { days: -7 }),
        to: new Date(),
      },
    },
  ];

  const selectedExistingPeriod = React.useMemo(
    () =>
      periods.find(
        (singlePeriod) =>
          isSameDay(selectedPeriod.from, singlePeriod.value.from) &&
          isSameDay(selectedPeriod.to, singlePeriod.value.to),
      ),
    [selectedPeriod],
  );

  const renderWeekPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>,
  ) => {
    const start = selectedPeriod.from;
    const end = selectedPeriod.to;

    const dayIsBetween = isBefore(date, end) && isAfter(date, start);
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
        sx={{ cursor: 'pointer' }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {selectedExistingPeriod ? (
          <Typography fontSize="11px" color="neutral.n400">
            {selectedExistingPeriod.label}
          </Typography>
        ) : (
          <>
            <Typography fontSize="11px" color="neutral.n400">
              {format(selectedPeriod.from, 'MMM dd, yyyy')}
            </Typography>
            <Typography fontSize="11px" color="neutral.n400">
              -
            </Typography>
            <Typography fontSize="11px" color="neutral.n400">
              {format(selectedPeriod.to, 'MMM dd, yyyy')}
            </Typography>
          </>
        )}
      </Stack>
      <Popover open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <Stack sx={{ maxWidth: '350px', p: 2 }} spacing={2}>
          <Stack spacing={1}>
            <Typography variant="p12" color="neutral.n400">
              Show activities for
            </Typography>

            <Stack direction="row" alignItems="center" flexWrap={'wrap'} gap={1}>
              {periods.map((period) => (
                <Box
                  key={`period_${period.label.trim()}`}
                  onClick={() => {
                    setSelectedPeriod(period.value);
                  }}
                  sx={{
                    px: 2,
                    py: 1,
                    borderRadius: 1,
                    backgroundColor: '#00000010',
                    cursor: 'pointer',
                    width: '48%',
                    textAlign: 'center',
                    ...(isSameDay(period.value.from, selectedPeriod.from) &&
                    isSameDay(period.value.to, selectedPeriod.to)
                      ? {
                          backgroundColor: 'primary.subtone2',
                          color: 'primary.main',
                        }
                      : {}),
                  }}
                >
                  <Typography variant="p12">{period.label}</Typography>
                </Box>
              ))}
              <Box
                onClick={() => {
                  setCustomSelecting(true);
                }}
                sx={{
                  px: 2,
                  py: 1,
                  borderRadius: 1,
                  backgroundColor: '#00000010',
                  cursor: 'pointer',
                  width: '48%',
                  textAlign: 'center',
                  ...(customSelecting
                    ? {
                        backgroundColor: 'primary.subtone2',
                        color: 'primary.main',
                      }
                    : {}),
                }}
              >
                <Typography variant="p12">Custom</Typography>
              </Box>
            </Stack>
            <Divider />
            <Stack spacing={1}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" gap={0.5}>
                <Stack
                  gap={0.5}
                  sx={{
                    ...(currentSelectingIndex === 0
                      ? {
                          borderBottom: '1px solid',
                          borderColor: 'primary.main',
                        }
                      : {}),
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentSelectingIndex(0)}
                >
                  <Typography variant="p12" color="neutral.n400">
                    Start date
                  </Typography>
                  <Typography variant="p14" color={currentSelectingIndex === 0 ? 'primary.main' : ''}>
                    {format(selectedPeriod.from, 'MMM dd, yyyy')}
                  </Typography>
                </Stack>
                <Typography variant="p14" color="neutral.n400">
                  To
                </Typography>
                <Stack
                  gap={0.5}
                  sx={{
                    ...(currentSelectingIndex === 1
                      ? {
                          borderBottom: '1px solid',
                          borderColor: 'primary.main',
                        }
                      : {}),
                    cursor: 'pointer',
                  }}
                  onClick={() => setCurrentSelectingIndex(1)}
                >
                  <Typography variant="p12" color="neutral.n400">
                    End date
                  </Typography>
                  <Typography variant="p14" color={currentSelectingIndex === 1 ? 'primary.main' : ''}>
                    {format(selectedPeriod.to, 'MMM dd, yyyy')}
                  </Typography>
                </Stack>
              </Stack>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={new Date()}
                  minDate={currentSelectingIndex === 1 ? selectedPeriod.from : undefined}
                  maxDate={currentSelectingIndex === 0 ? selectedPeriod.to : undefined}
                  onChange={(value) => {
                    if (!value) return;
                    if (currentSelectingIndex === 0) {
                      setSelectedPeriod((prev) => ({ ...prev, from: value }));
                      setCurrentSelectingIndex(1);
                    }
                    if (currentSelectingIndex === 1) {
                      setSelectedPeriod((prev) => ({ ...prev, to: value }));
                      setCurrentSelectingIndex(0);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  showDaysOutsideCurrentMonth
                  showToolbar={false}
                  components={{}}
                  renderDay={renderWeekPickerDay}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default PeriodSelector;
