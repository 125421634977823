import React from 'react';
import { Stack, Typography, Grid, Avatar } from '@mui/material';
import { differenceInMinutes, format, isBefore } from 'date-fns';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';

interface IProps {
  type: 'event' | 'task';
  nodeData: any;
  migrationId: string;
}

const TaskEventDetailsContainer = ({ type, nodeData, migrationId }: IProps) => {
  const {
    Status,
    ActivityDate,
    StartDateTime,
    EndDateTime,
    ActivityDateTime,
    Location,
    Description,
    OwnerId,
    CreatedDate,
  } = nodeData;

  const { data: usersData = [] } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'User',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'Id',
          operator: '=',
          value: `'${OwnerId}'`,
        },
      ],
    },
    {
      skip: !OwnerId,
    },
  );
  return (
    <Grid direction="row" container columns={2} rowGap={2} p={1}>
      <Grid item xs={1}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Status
          </Typography>
          <Stack
            sx={{
              px: 4,
              py: 1,
              borderRadius: '20px',
              backgroundColor: 'primary.subtone1',
              width: 'fit-content',
            }}
          >
            {type === 'event' && (
              <Typography variant="p12" color="common.white">
                {isBefore(new Date(ActivityDateTime), new Date()) ? 'Past' : 'Upcoming'}
              </Typography>
            )}
            {type === 'task' && (
              <Typography variant="p12" color="common.white">
                {Status}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={1}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            {type === 'event' ? 'Start' : 'Due on'}
          </Typography>
          {type === 'event' && (
            <Typography variant="p14">
              {StartDateTime ? format(new Date(StartDateTime), 'MMM dd, hh:mm aa') : ''}
            </Typography>
          )}
          {type === 'task' && (
            <Typography variant="p14">
              {ActivityDate ? format(new Date(ActivityDate), 'MMM dd, hh:mm aa') : ''}
            </Typography>
          )}
        </Stack>
      </Grid>
      {type === 'event' && (
        <Grid item xs={1}>
          <Stack gap={0.5}>
            <Typography variant="p12" color="neutral.n400">
              Duration
            </Typography>
            {EndDateTime && StartDateTime && (
              <Typography variant="p14">
                {differenceInMinutes(new Date(EndDateTime), new Date(StartDateTime))} minutes
              </Typography>
            )}
          </Stack>
        </Grid>
      )}
      <Grid item xs={1}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Attendees
          </Typography>

          <Avatar sx={{ width: '25px', height: '25px' }}>J</Avatar>
        </Stack>
      </Grid>
      {type === 'event' && (
        <Grid item xs={2}>
          <Stack gap={0.5}>
            <Typography variant="p12" color="neutral.n400">
              Location
            </Typography>

            <Typography variant="p14" color="blue.main">
              {Location}
            </Typography>
          </Stack>
        </Grid>
      )}
      <Grid item xs={2}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Notes
          </Typography>

          <Typography variant="p14">{Description}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Follow-up required
          </Typography>

          <Typography variant="p14">Yes</Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            {type === 'event' ? 'Organizer' : 'Created by'}
          </Typography>

          <Typography variant="p14">
            {usersData[0]?.Name} on {CreatedDate ? format(new Date(CreatedDate), 'MMM dd, hh:mm aa') : ''}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TaskEventDetailsContainer;
