import { FC } from 'react';
import { Box, ListItem, Typography } from '@mui/material';

import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ActiveRulesTable from './components/ActiveRulesTable';
import { ListCollapseButton } from '../../../../ListItemButtonMenu';

const ActiveRulesCategory: FC = () => {
  return (
    <Box>
      <ListCollapseButton
        sx={{ borderRadius: 1, backgroundColor: '#F6F8FB' }}
        opened={true}
        renderButton={(open) => (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TopicOutlinedIcon fontSize="small" sx={{ color: '#1554FF' }} />
              <Typography color="primary.main">Active Rules</Typography>
            </Box>
            {open ? (
              <KeyboardArrowDownIcon fontSize="small" htmlColor="#1554FF" />
            ) : (
              <KeyboardArrowUpIcon fontSize="small" htmlColor="#1554FF" />
            )}
          </Box>
        )}
      >
        <ListItem disablePadding>
          <Box width="100%" height={274}>
            <ActiveRulesTable />
          </Box>
        </ListItem>
      </ListCollapseButton>
    </Box>
  );
};

export default ActiveRulesCategory;
