import { Box, Divider, Typography, InputAdornment, CircularProgress, Button, IconButton, Tooltip } from '@mui/material';
import theme from 'core/theme';
import { FC, useMemo } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MinimizeIcon from '@mui/icons-material/Minimize';
import { ReactComponent as DeleteChatIcon } from 'assets/icons/AIChat/deleteChat.svg';
import { ReactComponent as TitleWindowIcon } from 'assets/icons/AIChat/tileWindow.svg';
import { ReactComponent as SentMessageIcon } from 'assets/icons/AIChat/sentMessage.svg';
import { CustomInput } from 'components/CustomInput';
import { AIAssistantChatHistory } from './AIAssistantChatHistory';

const buttonStyle = {
  p: 0,
  m: 0,
};

export interface AIAssistantContentProps {
  tiledWindow: boolean;
  aiAssistantData: any;
  aiAssistantActions: any;
  userInput: string;
  handleToggle: () => void;
  handleTitleWindowToggle: () => void;
  handleUserInputUpdate: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: any) => void;
}

export const AIAssistantContent: FC<AIAssistantContentProps> = (props) => {
  const {
    handleToggle,
    handleTitleWindowToggle,
    tiledWindow,
    aiAssistantData,
    aiAssistantActions,
    userInput,
    handleUserInputUpdate,
    handleSubmit,
  } = props;

  const SubmitButton = useMemo(() => {
    return (
      <Button
        type="submit"
        sx={{
          minWidth: '32px',
          maxHeight: '32px',
          backgroundColor: theme.palette.lightBg.main,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px',
          ':hover': {
            backgroundColor: theme.palette.primary.main,
            'svg path': { stroke: theme.palette.neutral.white },
          },
        }}
      >
        <SentMessageIcon />
      </Button>
    );
  }, []);

  const StopButton = useMemo(() => {
    return (
      <Button
        type="submit"
        sx={{
          minWidth: '32px',
          maxHeight: '32px',
          backgroundColor: theme.palette.primary.main,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px',
          position: 'relative',
        }}
      >
        <CircularProgress
          variant="indeterminate"
          size={20}
          style={{ position: 'absolute', color: 'white', zIndex: 1 }}
        />
        <Box
          sx={{
            position: 'absolute',
            minWidth: '8px',
            minHeight: '8px',
            backgroundColor: theme.palette.neutral.white,
            borderRadius: '2px',
            zIndex: 3,
          }}
        />
      </Button>
    );
  }, []);

  return (
    <Box
      sx={{
        zIndex: 2,
        position: 'relative',
        height: '90%',
        top: '5%',
        left: '1rem',
        width: '420px',
        px: 4,
        pt: 2,
        pb: 0.75,
        backgroundColor: theme.palette.neutral.white,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box display={'flex'} pb={2} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Box display={'flex'} gap={0.5} alignItems={'center'} justifyContent={'center'}>
          {!tiledWindow && <DragIndicatorIcon id="dragIndicatorIcon" sx={{ ':hover': { cursor: 'move' } }} />}
          <Typography variant="b16">Smart Assistant</Typography>
        </Box>
        <Box display={'flex'} gap={1.5} alignItems={'center'} justifyContent={'center'}>
          <Tooltip title="Clear Chat" placement="bottom">
            <IconButton sx={buttonStyle}>
              <DeleteChatIcon onClick={aiAssistantActions.cleanChatHistory} />
            </IconButton>
          </Tooltip>
          <Tooltip title={tiledWindow ? 'Exit Title Windows' : 'Title Windows'} placement="bottom">
            <IconButton sx={buttonStyle} onClick={handleTitleWindowToggle}>
              <TitleWindowIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Minimize" placement="bottom">
            <IconButton sx={buttonStyle}>
              <MinimizeIcon onClick={handleToggle} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Divider />
      <Box flex={1} sx={{ py: 1, px: 1, overflowY: 'auto' }}>
        <AIAssistantChatHistory chatHistory={aiAssistantData.chatHistory} aiThinking={aiAssistantData.thinking} />
      </Box>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={1}>
        <form onSubmit={handleSubmit}>
          <Box width={'100%'} display={'flex'}>
            <CustomInput
              id={'ai-assistant-input'}
              name={'aiAssistantInput'}
              fullWidth
              disabled={aiAssistantData.thinking}
              value={userInput}
              onChange={handleUserInputUpdate}
              placeholder="Ask Me Anything"
              sx={{ border: 0 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{aiAssistantData.thinking ? StopButton : SubmitButton}</InputAdornment>
                ),
              }}
            />
          </Box>
        </form>
        <Typography variant="labelRegular10" sx={{ color: theme.palette.primary.gray, textAlign: 'center' }}>
          AI assistant can sometimes generate incorrect or misleading information
        </Typography>
      </Box>
    </Box>
  );
};
