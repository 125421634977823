import { Divider, Grid, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { formatDate } from '../../../../../utils/DateUtils';
import { getCurrencyAbbreviated } from 'core/utils';

interface DetailMenuViewProps {
  node: any;
  referencedUsers: any;
}

const DetailMenuView: FC<DetailMenuViewProps> = (props: DetailMenuViewProps) => {
  const { node, referencedUsers } = props;
  const industry = node.Industry || '';
  const revenue = getCurrencyAbbreviated(node.AnnualRevenue || 0, '$');
  const employees = node.NumberOfEmployees;
  const location = node.BillingCity;
  const accountOwner = node.OwnerId;
  const notes = node.Description;
  const lastContactDate = formatDate(node.LastModifiedDate);

  const getLabelAndField = useCallback(
    (label: string, value: string) => {
      let valueTmp = value;

      if (referencedUsers && referencedUsers[valueTmp]) {
        valueTmp = referencedUsers[valueTmp].Name;
      }

      return (
        <>
          <Grid item>
            <Typography variant="labelRegular12" sx={{ color: 'neutral.n400' }}>
              {label}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="p14">{valueTmp}</Typography>
          </Grid>
        </>
      );
    },
    [referencedUsers],
  );

  return (
    <>
      <Grid item display={'flex'} flexDirection={'column'} gap={1}>
        <Grid item display={'flex'}>
          <Grid item xs={6}>
            {getLabelAndField('Industry', industry)}
          </Grid>
          <Grid item xs={6}>
            {getLabelAndField('Revenue', revenue)}
          </Grid>
        </Grid>
        <Grid item display={'flex'}>
          <Grid item xs={6}>
            {getLabelAndField('Employees', employees)}
          </Grid>
          <Grid item xs={6}>
            {getLabelAndField('Location', location)}
          </Grid>
        </Grid>
        <Grid item>{getLabelAndField('Account Owner', accountOwner)}</Grid>
        <Divider />
        <Grid item>{getLabelAndField('Notes', notes)}</Grid>
        <Divider />
        <Grid item>{getLabelAndField('Last Contact Date', lastContactDate)}</Grid>
      </Grid>
    </>
  );
};

export default DetailMenuView;
