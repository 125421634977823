import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { TemporalTableFormCreateMenuItem, TemporalTableFormMode, TemporalTableFormState } from './types';

import { setMenuIndex, setOpen, setTableOptions, setAlias, setTable } from './actions';

export const initialState: TemporalTableFormState = {
  data: {
    open: false,
    menuIndex: 0,
    mode: TemporalTableFormMode.CREATE,
    menuItems: [TemporalTableFormCreateMenuItem.Table, TemporalTableFormCreateMenuItem.Conditions],
    table: '',
    alias: '',
  },
};

const temporalTableFormStore = createSlice({
  name: 'temporalTableForm',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<TemporalTableFormState>): void => {
    builder.addCase(setAlias, (state, { payload }) => {
      state.data.alias = payload;
    });
    builder.addCase(setTable, (state, { payload }) => {
      state.data.table = payload;
    });
    builder.addCase(setMenuIndex, (state, { payload }) => {
      state.data.menuIndex = payload;
    });
    builder.addCase(setOpen, (state, { payload }) => {
      state.data.open = payload;
    });
    builder.addCase(setTableOptions, (state, { payload }) => {
      state.data.tableOptions = payload;
    });
  },
});

export default temporalTableFormStore.reducer;
