import { FC, HTMLAttributes, useCallback, useMemo } from 'react';
import { PrimitiveValue } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { ConditionType } from 'pages/RuleLibrary/types';
import { Box, Grid, InputAdornment } from '@mui/material';
import { GridItem } from 'components/ui';
import { Option } from 'store/ruleForm/types';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { useRuleFormStore } from 'store/ruleForm/hook';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorExclamation.svg';
import _ from 'lodash';
import { FormErrorMessage } from 'pages/RuleLibrary/components/ui/FormErrorMessage';
import { ReactComponent as RelationshipIcon } from 'assets/icons/relationship.svg';

interface FieldReferenceValueForm {
  targetPath: string;
  index: number;
  conditionalValueComponent: PrimitiveValue;
  conditionType: ConditionType;
  fieldOptions: Option[];
  fieldForeignRefOptions: Option[];
  xmlFilterType?: string[];
  getConditionTypeDropdown: () => JSX.Element;
  handleOnSelectLookUpField: (exactPath: string, value: string, type?: ConditionType) => void;
}

export const FieldReferenceValueForm: FC<FieldReferenceValueForm> = (props) => {
  const {
    conditionType,
    conditionalValueComponent,
    fieldForeignRefOptions,
    fieldOptions,
    handleOnSelectLookUpField,
    getConditionTypeDropdown,
    targetPath,
    xmlFilterType,
  } = props;

  const { formError } = useRuleFormStore();

  const valueError = useMemo(() => formError && _.get(formError, `${targetPath}.value`), [formError, targetPath]);

  const fieldReference = conditionalValueComponent as PrimitiveValue;
  let options = conditionType === ConditionType.WHERE ? fieldOptions : fieldForeignRefOptions;
  if (xmlFilterType && options) {
    options = options.filter((option) => xmlFilterType.includes(option?.xsd_type || '') || option.relationshipName);
  }

  const renderOption = useCallback((props: HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <Box component="li" {...props} display={'flex'} gap={1}>
        {option.relationshipName && <RelationshipIcon />}
        {option.label}
      </Box>
    );
  }, []);

  return (
    <Grid display={'flex'} flexDirection={'column'} width={'100%'} gap={1}>
      <Box display={'flex'}>
        <GridItem xs={6}>{getConditionTypeDropdown()}</GridItem>
      </Box>
      <GridItem flex={1}>
        <Box>
          <ControlledCustomDropdown<string>
            id={`${targetPath}`}
            placeholder="Field Name"
            value={fieldReference?.value || ''}
            options={options || []}
            renderOption={renderOption}
            sx={{
              backgroundColor: 'neutral.white',
              ...(valueError ? { border: '1px solid red', borderRadius: '4px' } : {}),
            }}
            fallbackOption={{ label: 'Select a Value', value: '' }}
            onSelect={(value) => handleOnSelectLookUpField(`${targetPath}`, value, conditionType)}
            InputProps={{
              startAdornment: valueError && <InputAdornment position="start">{<ErrorIcon />}</InputAdornment>,
            }}
          />
          <FormErrorMessage errorMessage={valueError} />
        </Box>
      </GridItem>
    </Grid>
  );
};
