import { Menu, MenuItem, Typography } from '@mui/material';
import { sortOptionsMap } from '../../../../utils/LeadViewSortingOptions';
import { FC, useCallback } from 'react';
import { useGraphRender } from 'store/graphRender/hooks';
import theme from 'core/theme';

interface SortMenuProps {
  sortMenuAnchorEl: null | HTMLElement;
  handleSortClose: () => void;
}

const selectedItemStyle = {
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  color: theme.palette.primary.main,
};

const selectedIconStyle = {
  stroke: theme.palette.primary.main,
};

export const SortMenu: FC<SortMenuProps> = (props) => {
  const { handleSortClose, sortMenuAnchorEl } = props;
  const {
    data: {
      leadViewState: { sortByField },
    },
    leadViewReducers: { setLeadViewSortByField },
  } = useGraphRender();

  const handleSetViewSortByField = useCallback(
    (value: string) => {
      setLeadViewSortByField(value);
    },
    [setLeadViewSortByField],
  );

  const getMenuItems = () => {
    return Object.keys(sortOptionsMap).map((key) => {
      const sortOption = sortOptionsMap[key];
      const Icon = sortOption.icon;
      const isSelected = sortByField === sortOption.value;
      return (
        <MenuItem
          key={sortOption.value}
          selected={isSelected}
          style={isSelected ? selectedItemStyle : {}}
          onClick={() => handleSetViewSortByField(sortOption.value)}
        >
          <Icon style={isSelected ? selectedIconStyle : {}} />
          <Typography variant="p14">{sortOption.label}</Typography>
        </MenuItem>
      );
    });
  };

  return (
    <Menu
      anchorEl={sortMenuAnchorEl}
      open={Boolean(sortMenuAnchorEl)}
      onClose={handleSortClose}
      title="Sort by:"
      elevation={2}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 0,
        horizontal: 150,
      }}
    >
      <MenuItem disabled>Sort by:</MenuItem>
      {getMenuItems()}
    </Menu>
  );
};
