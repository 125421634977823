import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography, Grid, Divider, Skeleton } from '@mui/material';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { Tooltip } from './Tooltip';
import { Renderer } from './Renderer';

import ScoreFilterButtons from './ScoreFilterButtons';
import useResizeObserver from './useResizeObserver';
import { useLazyGetMigrationTableDataByIdQuery } from 'store/migration/api';
import { AutoCompleteMultipleSelector } from 'components/AutoCompleteMultipleSelector';
import { Show } from '../../../../../../../../RuleLibrary/components/show';
import { TableField } from 'store/migration/types';

export type InteractionData = {
  [x: string]: any;
  confidence_score: number;
  xLabel: string;
  yLabel: string;
  xPos: number;
  yPos: number;
};

type DataHealthTabPanelProps = {
  setOrderByOption: (option: OrderByOption) => void;
  setUserFilterIds: (ids: string[]) => void;
  setScoreFilterIndex: (index: number) => void;
  dataMigrationId: string | undefined;
  tableFields: TableField[] | undefined;
  scoreFilterIndex: number;
  dataLoading: boolean;
  defaultOrderByOption: OrderByOption;
  defaultUserIds: string[];
  elements: {
    [x: string]: any;
    confidence_score: number;
  }[];
};

export type OrderByOption = {
  name: string;
  icon: React.ElementType;
  value: { fieldName: string };
};

const orderByOptions: OrderByOption[] = [
  { name: 'Last Added', icon: AccessTimeIcon, value: { fieldName: 'CreatedDate' } },
  { name: 'Last Updated', icon: CalendarTodayIcon, value: { fieldName: 'LastModifiedDate' } },
];

const DataHealthTabPanel: React.FC<DataHealthTabPanelProps> = ({
  elements,
  defaultOrderByOption,
  defaultUserIds,
  dataLoading,
  scoreFilterIndex,
  tableFields,
  dataMigrationId,
  setOrderByOption,
  setUserFilterIds,
  setScoreFilterIndex,
}) => {
  const [hoveredCell, setHoveredCell] = useState<InteractionData | null>(null);
  const containerRef = useRef(null);
  const { width: parentWidth } = useResizeObserver(containerRef);

  const [anchorElOrderBy, setAnchorElOrderBy] = useState<null | HTMLElement>(null);
  const [selectedOrderByOption, setSelectedOrderByOption] = useState<OrderByOption | undefined>(defaultOrderByOption);

  const [anchorElUserFilter, setAnchorElUserFilter] = useState<null | HTMLElement>(null);
  const [selectedUsersIdsFilter, setSelectedUsersIdsFilter] = useState<string[]>(defaultUserIds);

  const [getMigrationTableDataByIdQuery, userRecords] = useLazyGetMigrationTableDataByIdQuery();

  const handleSetSelectedScoreFilterButton = useCallback(
    (index: number) => {
      const selectedIndex = index === scoreFilterIndex ? 0 : index;
      setScoreFilterIndex(selectedIndex);
    },
    [scoreFilterIndex, setScoreFilterIndex],
  );

  const handleClickOrderByButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOrderBy(event.currentTarget);
  };

  const handleMenuCloseOrderByMenu = () => {
    setAnchorElOrderBy(null);
  };

  const handleChangeOrderBy = (event: React.MouseEvent<HTMLElement>, optionName: string) => {
    const option = orderByOptions.find((o) => o.name === optionName);
    if (option) {
      setSelectedOrderByOption(option);
      setOrderByOption(option);
      setAnchorElOrderBy(null);
    }
  };

  useEffect(() => {
    if (dataMigrationId) {
      getMigrationTableDataByIdQuery({
        migrationId: dataMigrationId || '',
        tableId: 'User',
        skip: 0,
        take: 1000,
        conditions: [],
        fields: [],
        orderBy: [{ fieldName: 'Name', order: 'ASC' }],
      });
    }
  }, [dataMigrationId, getMigrationTableDataByIdQuery]);

  const userOptions = useMemo(() => {
    if (userRecords.isSuccess && userRecords.data) {
      return userRecords.data.map((record) => {
        return {
          label: record.Name as string,
          value: record.Id as string,
        };
      });
    }
    return [];
  }, [userRecords.data, userRecords.isSuccess]);

  const handleClickUserFilterButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUserFilter(event.currentTarget);
  };

  const handleMenuCloseUserFilterMenu = () => {
    setAnchorElUserFilter(null);
  };

  const handleChangeUserFilter = useCallback(
    (usersIdSelected: string[]) => {
      setSelectedUsersIdsFilter(usersIdSelected || []);
      setUserFilterIds(usersIdSelected || []);
      setAnchorElUserFilter(null);
    },
    [setUserFilterIds],
  );

  const supportUserFilter = useMemo(() => !!tableFields?.find((field) => field.fieldName === 'OwnerId'), [tableFields]);
  const supportedOrderByOptions = useMemo(
    () => orderByOptions.filter((option) => tableFields?.find((field) => field.fieldName === option.value.fieldName)),
    [tableFields],
  );

  useEffect(() => {
    if (supportUserFilter === false) {
      handleChangeUserFilter([]);
    }
  }, [handleChangeUserFilter, supportUserFilter]);

  const selectedUserNames = useMemo(() => {
    return userOptions.filter((user) => selectedUsersIdsFilter.includes(user.value)).map((user) => user.label);
  }, [selectedUsersIdsFilter, userOptions]);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 4 }}>
          <Typography fontSize={12} sx={{ color: '#898EA1' }}>
            Order by:{' '}
          </Typography>
          <Button
            variant="text"
            aria-controls={anchorElOrderBy ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={anchorElOrderBy ? 'true' : undefined}
            sx={{
              color: 'black',
              fontSize: '12px',
              '&:hover': {
                backgroundColor: 'initial',
                boxShadow: 'none',
                color: 'black',
              },
            }}
            onClick={handleClickOrderByButton}
            endIcon={anchorElOrderBy ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          >
            {selectedOrderByOption?.name}
          </Button>
          <Menu
            id="sort-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorElOrderBy}
            open={Boolean(anchorElOrderBy)}
            onClose={handleMenuCloseOrderByMenu}
          >
            {supportedOrderByOptions.map((option) => (
              <MenuItem
                key={option.name}
                selected={orderByOptions.find((o) => o.name === selectedOrderByOption?.name) === option}
                onClick={(event) => handleChangeOrderBy(event, option.name)}
                sx={{ marginBottom: '5px' }}
              >
                <option.icon sx={{ marginRight: '12px' }} />
                {option.name}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Show when={supportUserFilter}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontSize={12} sx={{ color: '#898EA1' }}>
              Assigned to:{' '}
            </Typography>
            <Button
              variant="text"
              aria-controls={anchorElUserFilter ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={anchorElUserFilter ? 'true' : undefined}
              sx={{
                color: 'black',
                fontSize: '12px',
                '&:hover': {
                  backgroundColor: 'initial',
                  boxShadow: 'none',
                  color: 'black',
                },
              }}
              onClick={handleClickUserFilterButton}
              endIcon={anchorElUserFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            >
              {selectedUserNames.length > 0 ? selectedUserNames.join(', ') : 'All'}
            </Button>
            <Menu
              id="user-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorElUserFilter}
              open={Boolean(anchorElUserFilter)}
              onClose={handleMenuCloseUserFilterMenu}
            >
              <AutoCompleteMultipleSelector<string>
                options={userOptions}
                itemLabel={'member'}
                pluralItemLabel={'members'}
                searchInputPlaceholder={'Search team member'}
                initialValuesSelected={selectedUsersIdsFilter}
                onChange={handleChangeUserFilter}
              />
            </Menu>
          </Box>
        </Show>
      </Box>
      <Grid container alignItems="center">
        <Grid item>
          <Typography sx={{ color: '#898EA1', paddingRight: 1, fontSize: '10px' }}>
            {selectedOrderByOption?.name}
          </Typography>
        </Grid>
        <Grid item xs>
          <Box sx={{ width: '100%' }}>
            <Divider
              sx={{
                borderStyle: 'dashed',
                borderWidth: '1px 0 0 0',
                borderColor: 'rgba(0, 0, 0, 0.12)',
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box position={'relative'} ref={containerRef} width="100%">
        <Show when={!dataLoading} fallback={<Skeleton height={286} width={'100%'} />}>
          <Renderer
            width={parentWidth}
            elements={elements}
            setHoveredCell={setHoveredCell}
            numCells={elements.length}
          />
        </Show>
        <Tooltip interactionData={hoveredCell} width={1400} height={400} />
        <Show when={elements.length === 0 && !dataLoading}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
            }}
          >
            <Typography>No data to display</Typography>
          </Box>
        </Show>
      </Box>
      <ScoreFilterButtons selectedButton={scoreFilterIndex} setSelectedButton={handleSetSelectedScoreFilterButton} />
    </>
  );
};
export default DataHealthTabPanel;
