import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, IconButton, Menu, MenuItem, Chip } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';

interface MappingCardProps {
  cardData: {
    name: string;
    owner: string;
    date: string;
    chips: string[];
  };
  isSelected: boolean;
  onSelect: (name: string) => void;
  onDelete: (name: string) => void;
}

const MappingCard: React.FC<MappingCardProps> = ({ cardData, isSelected, onSelect, onDelete }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete(cardData.name);
    handleMenuClose();
  };

  const getChipColor = (label: string) => {
    switch (label) {
      case 'Accounts':
        return '#F8E6DF';
      case 'Contacts':
        return '#E9E4FB';
      case 'Leads':
        return '#F3DEFB';
      case 'Opportunities':
        return '#DFF4E5';
      default:
        return '#E1F9FF';
    }
  };

  return (
    <Card
      sx={{
        width: '100%',
        my: 2,
        boxShadow: 0,
        position: 'relative',
        border: isSelected ? '1px solid blue' : '',
        cursor: 'pointer',
      }}
      onClick={() => onSelect(cardData.name)}
    >
      <CardContent>
        <Typography variant="p14" component="div">
          {cardData.name}
        </Typography>
        <Typography variant="p12" color="neutral.n400">
          Created by {cardData.owner} on {cardData.date}
        </Typography>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
          {cardData.chips.map((chip) => (
            <Chip
              key={chip}
              label={chip}
              sx={{
                backgroundColor: getChipColor(chip),
                borderRadius: '4px',
              }}
            />
          ))}
        </Box>

        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          sx={{ position: 'absolute', top: 8, right: 8 }}
          onClick={handleMenuOpen}
        >
          <MoreHorizIcon />
        </IconButton>

        <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleDelete}>
            <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
            Delete
          </MenuItem>
        </Menu>
      </CardContent>
    </Card>
  );
};

export default MappingCard;
