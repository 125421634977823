import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { chartOptions } from './utils';
import CustomTooltip from './components/CustomTooltip';
import PeriodSelector from './components/PeriodSelector';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface IProps {
  data: {
    date: Date | string;
    data: {
      successfulCalls: number;
      unsuccessfulCalls: number;
      missedCalls: number;

      completedMeetings: number;
      scheduledMeetings: number;
      canceledMeetings: number;
    };
  }[];
}
const CallsAndMeetingsChart = ({ data }: IProps) => {
  const [tooltipData, setTooltipData] = React.useState<any>(null);

  const renderTooltip = (position: { x: number; y: number }, data: any, hide = false) => {
    if (hide) {
      setTooltipData(null);
      return;
    }
    setTooltipData({
      position,
      data,
    });
  };
  const categoryPercentage = 0.6;
  const barPercentage = 0.6;
  const chartData: ChartData<'bar'> = React.useMemo(
    () => ({
      labels: data.map((entry) => entry.date),

      datasets: [
        {
          label: 'Missed Calls',
          data: data.map((entry) => entry.data.missedCalls),
          backgroundColor: '#fb4e6d',
          // barThickness: 10,
          barPercentage,
          stack: 'stack 0',
          categoryPercentage,
        },
        {
          label: 'Successful Calls',
          data: data.map((entry) => entry.data.successfulCalls),
          backgroundColor: '#60C67C',
          // barThickness: 10,
          barPercentage,
          stack: 'stack 0',
          categoryPercentage,
        },
        {
          label: 'Unsuccessful Calls',
          data: data.map((entry) => entry.data.unsuccessfulCalls),
          backgroundColor: '#ff9960',
          stack: 'stack 0',
          // barThickness: 10,
          categoryPercentage,
          barPercentage,
          borderRadius: {
            topLeft: 3,
            topRight: 3,
          },
        },
        {
          label: 'Canceled Meetings',
          data: data.map((entry) => entry.data.canceledMeetings),
          backgroundColor: '#fb4e6d',
          categoryPercentage,
          barPercentage,
          // barThickness: 10,
          stack: 'stack 1',
        },
        {
          label: 'Completed Meetings',
          data: data.map((entry) => entry.data.completedMeetings),
          backgroundColor: '#60c67c',
          categoryPercentage,
          barPercentage,
          // barThickness: 10,
          stack: 'stack 1',
        },
        {
          label: 'Scheduled Meetings',
          data: data.map((entry) => entry.data.scheduledMeetings),
          backgroundColor: '#5990E4',
          barPercentage,
          categoryPercentage,
          // barThickness: 10,
          stack: 'stack 1',
          borderRadius: {
            topLeft: 3,
            topRight: 3,
          },
        },
      ],
    }),
    [],
  );
  return (
    <Box p={1} bgcolor={'#F6F8FB'}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="labelMedium12" color="neutral.n400">
          Calls and Mettings - Engagement history
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
          <PeriodSelector />
          <ExpandMoreIcon sx={{ fontSize: '12px' }} />
        </Stack>
      </Stack>
      <Box sx={{ height: '120px', width: '100%', my: 1, position: 'relative' }}>
        <Bar options={chartOptions(renderTooltip)} data={chartData} />
        {tooltipData && <CustomTooltip position={tooltipData.position} data={tooltipData.data} />}
      </Box>
    </Box>
  );
};

export default CallsAndMeetingsChart;
