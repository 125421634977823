import { Box, Tooltip, styled } from '@mui/material';
import { FC } from 'react';

import theme from 'core/theme';

type Props = {
  tooltip?: string;
  highlight: boolean;
};

const HighLight: FC<Props> = ({ highlight, tooltip = '', ...props }) => {
  return (
    <Box {...props}>
      <Tooltip arrow title={tooltip}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill={highlight ? theme.palette.primary.main : theme.palette.primary.gray}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8.79922 2.39961C8.79922 2.18744 8.71493 1.98395 8.5649 1.83392C8.41488 1.68389 8.21139 1.59961 7.99922 1.59961C7.78705 1.59961 7.58356 1.68389 7.43353 1.83392C7.2835 1.98395 7.19922 2.18744 7.19922 2.39961V3.19961C7.19922 3.41178 7.2835 3.61527 7.43353 3.76529C7.58356 3.91532 7.78705 3.99961 7.99922 3.99961C8.21139 3.99961 8.41488 3.91532 8.5649 3.76529C8.71493 3.61527 8.79922 3.41178 8.79922 3.19961V2.39961Z" />
          <path d="M12.5249 4.60479C12.6706 4.45391 12.7512 4.25183 12.7494 4.04207C12.7476 3.83231 12.6635 3.63166 12.5151 3.48334C12.3668 3.33501 12.1662 3.25088 11.9564 3.24905C11.7466 3.24723 11.5446 3.32787 11.3937 3.47359L10.8281 4.03919C10.6824 4.19007 10.6017 4.39216 10.6035 4.60191C10.6054 4.81167 10.6895 5.01232 10.8378 5.16065C10.9862 5.30897 11.1868 5.39311 11.3966 5.39493C11.6063 5.39675 11.8084 5.31612 11.9593 5.17039L12.5249 4.60479Z" />
          <path d="M14.4 7.99922C14.4 8.21139 14.3157 8.41488 14.1657 8.56491C14.0157 8.71493 13.8122 8.79922 13.6 8.79922H12.8C12.5878 8.79922 12.3843 8.71493 12.2343 8.56491C12.0843 8.41488 12 8.21139 12 7.99922C12 7.78705 12.0843 7.58356 12.2343 7.43353C12.3843 7.2835 12.5878 7.19922 12.8 7.19922H13.6C13.8122 7.19922 14.0157 7.2835 14.1657 7.43353C14.3157 7.58356 14.4 7.78705 14.4 7.99922Z" />
          <path d="M4.04017 5.17039C4.11397 5.2468 4.20224 5.30775 4.29985 5.34967C4.39745 5.3916 4.50242 5.41367 4.60865 5.41459C4.71487 5.41552 4.82022 5.39527 4.91853 5.35505C5.01685 5.31483 5.10617 5.25542 5.18129 5.18031C5.2564 5.10519 5.3158 5.01587 5.35603 4.91756C5.39625 4.81924 5.41649 4.71389 5.41557 4.60767C5.41465 4.50145 5.39258 4.39647 5.35065 4.29887C5.30872 4.20127 5.24778 4.11299 5.17137 4.03919L4.60577 3.47359C4.45489 3.32787 4.25281 3.24723 4.04305 3.24905C3.83329 3.25088 3.63264 3.33501 3.48431 3.48334C3.33599 3.63166 3.25185 3.83231 3.25003 4.04207C3.24821 4.25183 3.32884 4.45391 3.47457 4.60479L4.04017 5.17039Z" />
          <path d="M3.99961 7.99922C3.99961 8.21139 3.91532 8.41488 3.76529 8.56491C3.61527 8.71493 3.41178 8.79922 3.19961 8.79922H2.39961C2.18744 8.79922 1.98395 8.71493 1.83392 8.56491C1.68389 8.41488 1.59961 8.21139 1.59961 7.99922C1.59961 7.78705 1.68389 7.58356 1.83392 7.43353C1.98395 7.2835 2.18744 7.19922 2.39961 7.19922H3.19961C3.41178 7.19922 3.61527 7.2835 3.76529 7.43353C3.91532 7.58356 3.99961 7.78705 3.99961 7.99922Z" />
          <path d="M6.40039 12.8V12H9.60039V12.8C9.60039 13.2243 9.43182 13.6313 9.13176 13.9314C8.8317 14.2314 8.42474 14.4 8.00039 14.4C7.57604 14.4 7.16908 14.2314 6.86902 13.9314C6.56896 13.6313 6.40039 13.2243 6.40039 12.8Z" />
          <path d="M9.59883 11.1996C9.61083 10.9276 9.76523 10.6828 9.98043 10.5124C10.5026 10.1006 10.8835 9.5364 11.0701 8.89815C11.2568 8.2599 11.24 7.57935 11.0221 6.95109C10.8042 6.32282 10.3959 5.77806 9.8541 5.39252C9.31229 5.00697 8.66382 4.7998 7.99883 4.7998C7.33384 4.7998 6.68537 5.00697 6.14355 5.39252C5.60173 5.77806 5.19349 6.32282 4.97555 6.95109C4.75762 7.57935 4.74083 8.2599 4.92751 8.89815C5.1142 9.5364 5.49508 10.1006 6.01723 10.5124C6.23323 10.6828 6.38683 10.9276 6.39803 11.1996H9.59963H9.59883Z" />
        </svg>
      </Tooltip>
    </Box>
  );
};

export default HighLight;
