import * as React from 'react';
import { styled } from '@mui/material/styles';
import theme from 'core/theme';
import { getConfidenceScoreColor, getConfidenceScoreStatus } from 'core/utils';

const Root = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  width: 150,
  maxWidth: '100%',
  height: 26,
  borderRadius: 2,
}));
const Value = styled('div')({
  position: 'absolute',
  lineHeight: '24px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});
const Bar = styled('div')({
  height: '100%',
  '&.low': {
    backgroundColor: theme.palette.red.main,
  },
  '&.medium': {
    backgroundColor: theme.palette.orange.main,
  },
  '&.high': {
    backgroundColor: theme.palette.green.main,
  },
});

const renderProgress = (value: number) => {
  const text = getConfidenceScoreStatus(value);
  const color = getConfidenceScoreColor(value);

  return (
    <Root>
      <Value>
        {text} {value}%
      </Value>
      <Bar style={{ maxWidth: `${value}%`, backgroundColor: color }}></Bar>
    </Root>
  );
};

export default renderProgress;
