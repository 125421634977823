import { GridItem } from 'components/ui';
import { OperatorDropdown } from '../shared/OperatorDropdown';
import { DeleteComponentButton } from '../shared/DeleteComponentButton';
import { FC } from 'react';
import { RuleWhereArray, RootCondition } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { ConditionalValueInput } from '../shared/ConditionalValueInput/ConditionalValueInput';
import { ConditionType } from 'pages/RuleForm/types';
import { CONDITIONAL_VALUE_OPTIONS, FUNCTION_ENUM, OPERATOR_OPTIONS } from '../shared/Constants';
import { Option } from 'store/ruleForm/types';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { Typography } from '@mui/material';
import _ from 'lodash';

export interface ConditionalRecordFormProps {
  index: number;
  field: string;
  conditionComponent: RootCondition;
  conditionPath: string;
  where: RuleWhereArray;
  setWhere: (where: { value: RuleWhereArray }) => void;
  fieldOptions: Option[];
  tableName: string;
}

const ConditionalRecordForm: FC<ConditionalRecordFormProps> = (props) => {
  const { getXmlTypeFilterFromValueAndOperator, formError } = useRuleForm();
  const { index, conditionComponent, where, setWhere, conditionPath, fieldOptions, tableName } = props;
  const operator = conditionComponent?.operator || null;
  const allowedFunctions = [FUNCTION_ENUM.DAYS_SINCE];

  const firstFieldValidValueType = CONDITIONAL_VALUE_OPTIONS.map((option) => option.value)?.filter(
    (option) => option != RuleComponentType.SUB_QUERY_VALUE && option != RuleComponentType.PRIMITIVE_VALUE,
  );

  let secondFieldValidValueType = CONDITIONAL_VALUE_OPTIONS.map((option) => option.value);
  const operatorOption = OPERATOR_OPTIONS.find((option) => option.value === operator);

  if (operatorOption) {
    secondFieldValidValueType = operatorOption.rightValueType;
  }

  const xmlTypeFilter = getXmlTypeFilterFromValueAndOperator(conditionComponent.field, operator || '', tableName);

  return (
    <>
      <GridItem container display={'flex'} alignItems={'center'} xs={12} md={5} sx={{ my: 1, px: 1 }}>
        <GridItem width={'100%'}>
          <ConditionalValueInput
            targetPath={`${conditionPath}.field`}
            rootConditionalPath={conditionPath}
            conditionalValueComponent={conditionComponent.field}
            conditionType={ConditionType.WHERE}
            index={index}
            allowedValueTypes={firstFieldValidValueType}
            allowedValueFunctions={allowedFunctions}
            fieldOptions={fieldOptions}
            tableName={tableName}
          />
        </GridItem>
        {formError && _.get(formError, `${conditionPath}.field.error`) && (
          <GridItem flex={1}>
            <Typography variant="caption" sx={{ color: 'red.main' }}>
              {_.get(formError, `${conditionPath}.field.error`)}
            </Typography>
          </GridItem>
        )}
      </GridItem>

      <GridItem xs={12} md={1.5} sx={{ my: 1, px: 1 }}>
        <OperatorDropdown
          operator={operator}
          condition={where[index] as RootCondition}
          targetPath={`${conditionPath}.operator`}
          tableName={tableName}
        />
      </GridItem>

      <GridItem container display={'flex'} alignItems={'center'} xs={12} md={5} sx={{ my: 1, px: 1 }}>
        <GridItem width={'100%'}>
          <ConditionalValueInput
            targetPath={`${conditionPath}.value`}
            rootConditionalPath={conditionPath}
            previousOperator={operator}
            previousValueComponent={conditionComponent.field}
            conditionalValueComponent={conditionComponent.value}
            conditionType={ConditionType.WHERE}
            index={index}
            allowedValueTypes={secondFieldValidValueType}
            allowedValueFunctions={allowedFunctions}
            fieldOptions={fieldOptions}
            xmlFilterType={xmlTypeFilter || []}
            tableName={tableName}
          />
        </GridItem>
        {formError && _.get(formError, `${conditionPath}.value.error`) && (
          <GridItem flex={1}>
            <Typography variant="caption" sx={{ color: 'red.main' }}>
              {_.get(formError, `${conditionPath}.value.error`)}
            </Typography>
          </GridItem>
        )}
      </GridItem>

      <GridItem xs={0.5} md={0.5} sx={{ my: 1, px: 1 }}>
        <DeleteComponentButton index={index} where={where} setWhere={setWhere} />
      </GridItem>
    </>
  );
};

export { ConditionalRecordForm };
