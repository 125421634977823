import React from 'react';
import { Stack, Typography, Grid } from '@mui/material';

interface IProps {
  nodeData: any;
}

const NoteDetailsContainer = ({ nodeData = {} }: IProps) => {
  const { Description } = nodeData;
  return (
    <Grid direction="row" container columns={2} rowGap={2} p={1}>
      <Grid item xs={2}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Description
          </Typography>

          <Typography variant="p14">{Description}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default NoteDetailsContainer;
