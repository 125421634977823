import { Grid, Typography } from '@mui/material';
import { TextButton } from 'pages/RuleForm/ui';
import { FC } from 'react';
import { LOOK_UP_FUNCTION_OPTIONS, OPERATOR_OPTIONS } from '../shared/Constants';
import { useMigration } from 'store/migration/hooks';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { ConditionType } from 'pages/RuleLibrary/types';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { RootCondition, LogicalOperator, RuleWhereArray } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { getDataValidationEmptyRootConditional } from 'pages/RuleForm/utils';

type AddLogicalOperatorButtonProps = {
  index: number;
  where: RuleWhereArray;
  type: ConditionType;
  wherePath: string;
};

const AddLogicalOperatorButton: FC<AddLogicalOperatorButtonProps> = (props) => {
  const { index, where, type, wherePath } = props;
  const { setFieldValue } = useRuleForm();
  const {
    data: { migrationTableForeignReferences },
  } = useMigration();
  const { selectedMigration, selectedTable } = useRuleForm();
  const key = `${selectedMigration}-${selectedTable}`;

  const addLogicalOperator = (index: number) => {
    const logicalOperatorIndex = index + 1;
    const conditionIndex = index + 2;

    const initialLogicalOperator: LogicalOperator = {
      type: RuleComponentType.LOGICAL_OPERATOR,
      value: 'AND',
    };

    let initialCondition: RootCondition;
    if (type === ConditionType.WHERE) {
      initialCondition = getDataValidationEmptyRootConditional();
    } else {
      if (migrationTableForeignReferences[key]?.data?.length <= 0) return;
      // const initialReference = migrationTableForeignReferences[key].data[0];
      initialCondition = {
        type: RuleComponentType.ROOT_CONDITIONAL,
        field: {
          type: RuleComponentType.FUNCTION_VALUE,
          function: LOOK_UP_FUNCTION_OPTIONS[0].value,
          numberOfParams: LOOK_UP_FUNCTION_OPTIONS[0].paramNumber,
          value: [],
        },
        operator: OPERATOR_OPTIONS[3].value,
        value: { type: RuleComponentType.PRIMITIVE_VALUE, value: 0 },
      } as any;
    }
    setFieldValue({ path: `${wherePath}[${logicalOperatorIndex}]`, value: initialLogicalOperator });
    setFieldValue({ path: `${wherePath}[${conditionIndex}]`, value: initialCondition });
  };

  if (where[index + 1]) return null;

  return (
    <Grid container display="flex" justifyContent="flex-start" sx={{ my: 'auto', marginLeft: '1rem' }}>
      <TextButton
        onClick={addLogicalOperator.bind(this, index)}
        sx={{
          mt: 0,
          p: 0,
          height: '100%',
        }}
      >
        <Typography sx={{ mt: '0.5rem' }} variant="labelRegular12">
          + Add &apos;And/Or&apos;
        </Typography>
      </TextButton>
    </Grid>
  );
};

export { AddLogicalOperatorButton };
