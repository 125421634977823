import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, IconButton, Popover } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface TemplateCardWithPreviewProps {
  image: string;
  previewImage: string;
  description: string;
  title: string;
  isSelected: boolean;
}

const TemplateCardWithPreview: React.FC<TemplateCardWithPreviewProps> = ({
  image,
  previewImage,
  description,
  title,
  isSelected,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Card
      sx={{
        width: 256,
        height: 200,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        border: isSelected ? '2px solid #1554FF' : '',
        transition: '0.3s',
        '&:hover': {
          border: '2px solid #1554FF',
        },
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <img
          src={image}
          alt={title}
          style={{
            padding: 10,
            height: '100px',
            width: '100%',
            objectFit: 'cover',
          }}
        />
        <IconButton
          sx={{
            position: 'absolute',
            bottom: '16.5px',
            right: 10,
            backgroundColor: 'white',
            borderRadius: 0,
            padding: '4px',
            opacity: 0.6,
            '&:hover': {
              opacity: 0.8,
            },
          }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <SearchIcon fontSize="small" />
        </IconButton>

        <Popover sx={{ pointerEvents: 'none' }} open={open} anchorEl={anchorEl} disableRestoreFocus>
          <img src={previewImage} alt="Preview" style={{ width: '720px', height: '384px', objectFit: 'cover' }} />
        </Popover>
      </Box>

      <CardContent sx={{ py: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="labelMedium14">{title}</Typography>
        <Typography variant="labelRegular12" color="neutral.n400">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TemplateCardWithPreview;
