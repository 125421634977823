import { Box, Card, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import theme from 'core/theme';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { NodeToolbar, Position } from 'reactflow';
import { useGraphRender } from 'store/graphRender/hooks';
import { ReactComponent as ArrowOpenIcon } from 'assets/icons/chevronRight.svg';
import { getCurrencyAbbreviated } from 'core/utils';
import _ from 'lodash';
import { ConfidenceScore } from 'components/ConfidenceScore/ConfidenceScore';
import { useGraphData } from 'store/graphData/hooks';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { getDeduplicationResultByIds } from 'http/deduplicationResult';
import { DeduplicationResult } from 'store/deduplicationResult/types';
import { useDataAnomaly } from 'store/dataAnomaly/hook';
import { DataAnomalyRecord } from 'store/dataAnomaly/types';
import { getDuplicateRecordReferences } from '../../utils/DuplicateUtils';
import { Button } from '../../../../../../components/ui/buttons';
import { AccountPanelMenuViewsOld } from 'store/graphRender/types';

interface AccountToolTipProps {
  id: string;
  open: boolean;
  record: any;
  migrationId: string;
}

const customDarkBackgroundColor = '#2B3F67';
const customLightBackgroundColor = '#8892a6';

export const AccountToolTip: FC<AccountToolTipProps> = (props) => {
  const { open, record, id, migrationId } = props;
  const [recordData, setRecordData] = useState<any>();
  const [possibleDuplicateRecords, setPossibleDuplicateRecords] = useState<DeduplicationResult[]>();
  const [possibleDuplicateRecordsCounter, setPossibleDuplicateRecordsCounter] = useState<number>();
  const [recordAnomalies, setRecordAnomalies] = useState<DataAnomalyRecord[]>();
  const [recordAnomaliesCounter, setRecordAnomaliesCounter] = useState<number>();
  const [ownerData, setOwnerData] = useState<any>();
  const [loadingOwnerData, setLoadingOwnerData] = useState<any>();

  const {
    data: { nodeHashMap, tableRecords },
    getMigrationTableRecord,
    setOpenMergeModal,
    setRecordsToMerge,
    setSelectedNode,
    setShowRecordPanel,
    setSelectedAccountPanelView,
  } = useGraphRender();

  const { getRecordAnomalies } = useDataAnomaly();
  const { getDataByVertexId } = useGraphData();

  const handleOpenMergeModal = useCallback(() => {
    setOpenMergeModal(true);
    const recordReferences = getDuplicateRecordReferences(possibleDuplicateRecords || []);
    setRecordsToMerge(recordReferences);
  }, [possibleDuplicateRecords, setOpenMergeModal, setRecordsToMerge]);

  const handleOpenAnomalyPanel = useCallback(() => {
    // Open Panel for the current account record
    const node = nodeHashMap[id];
    setSelectedNode(node);
    setSelectedAccountPanelView(AccountPanelMenuViewsOld.DataAnomaly as any);
    setShowRecordPanel(true);
    // Pass signal to the panel to pre-select anomaly menu
  }, [id, nodeHashMap, setSelectedAccountPanelView, setSelectedNode, setShowRecordPanel]);

  const relatedContactCount = useMemo(() => {
    const bridgeId = `${id}-Contact-bridge`;
    const bridge = nodeHashMap[bridgeId];
    return bridge?.data.connectionsCount;
  }, [id, nodeHashMap]);

  const relatedOpportunitiesCount = useMemo(() => {
    const bridgeId = `${id}-Opportunity-bridge`;
    const bridge = nodeHashMap[bridgeId];
    return bridge?.data.connectionsCount;
  }, [id, nodeHashMap]);

  const relatedEventCount = useMemo(() => {
    const bridgeId = `${id}-Event-bridge`;
    const bridge = nodeHashMap[bridgeId];
    return bridge?.data.connectionsCount;
  }, [id, nodeHashMap]);

  useEffect(() => {
    if (open === true && id && migrationId && _.isEmpty(recordData)) {
      const recordId = id.split('.')[1];
      getDataByVertexId({
        migrationId: migrationId,
        vertexId: recordId,
        onSuccess: (data) => {
          setRecordData({ ...data.vertex, id });
        },
      });
    }
  }, [getDataByVertexId, id, migrationId, open, recordData]);

  useEffect(() => {
    if (open === true && id && migrationId && recordAnomalies == null) {
      const recordId = id.split('.')[1];
      getRecordAnomalies({
        migrationId,
        tableName: record.label,
        recordId: recordId,
        onSuccess: (data) => {
          if (data && data.anomalies && data.anomalies.length > 0) {
            setRecordAnomalies(data.anomalies);
            setRecordAnomaliesCounter(data.anomalies.length || 0);
          } else {
            setRecordAnomalies([]);
            setRecordAnomaliesCounter(0);
          }
        },
        onError: () => {
          setRecordAnomalies([]);
          setRecordAnomaliesCounter(0);
        },
      });
    }
  }, [getRecordAnomalies, id, migrationId, open, record.label, recordAnomalies]);

  useEffect(() => {
    if (open === true && id && migrationId && possibleDuplicateRecords == null) {
      const recordId = id.split('.')[1];
      getDeduplicationResultByIds(migrationId, { ids: [recordId] })
        .then((data) => {
          setPossibleDuplicateRecords(data);
          setPossibleDuplicateRecordsCounter(data?.length || 0);
        })
        .catch(() => {
          setPossibleDuplicateRecords([]);
          setPossibleDuplicateRecordsCounter(0);
        });
    }
  }, [id, migrationId, open, possibleDuplicateRecords, recordData]);

  const valuesPayload = useMemo(() => {
    const revenue = getCurrencyAbbreviated(recordData?.revenue, '$');
    const ownerId = recordData?.OwnerId;
    const lastActivityDate = recordData?.LastActivityDate;
    return { revenue, ownerId, lastActivityDate };
  }, [recordData]);

  useEffect(() => {
    if (valuesPayload.ownerId && ownerData == null && migrationId && open === true && loadingOwnerData != true) {
      const recordDataKey = `${migrationId}-User`;
      const ownerId = valuesPayload.ownerId;
      let foundData = false;
      if (tableRecords[recordDataKey]?.data[ownerId] != null) {
        foundData = true;
      }
      if (foundData === false) {
        setLoadingOwnerData(true);
        getMigrationTableRecord({
          migrationId,
          tableId: 'User',
          skip: 0,
          take: 0,
          conditions: [
            {
              field: 'Id',
              operator: 'in',
              value: `('${ownerId}')`,
              type: RuleComponentType.ROOT_CONDITIONAL,
            },
          ],
          onSuccess: (data) => {
            const ownerData = data[0];
            setOwnerData(ownerData);
            setLoadingOwnerData(false);
          },
          onError: () => {
            setOwnerData({});
            setLoadingOwnerData(false);
          },
        });
      } else {
        let ownerData: any = {};
        if (tableRecords[recordDataKey]?.data[ownerId]) {
          ownerData = tableRecords[recordDataKey]?.data[ownerId];
        }
        setOwnerData(ownerData);
      }
    }
  }, [getMigrationTableRecord, id, loadingOwnerData, migrationId, open, ownerData, tableRecords, valuesPayload]);

  const confidenceScore = useMemo(() => record?.confidence_score, [record]);

  const CustomDivider = useCallback(() => <Divider sx={{ backgroundColor: theme.palette.blue.light }} />, []);
  const MinWidthComponent = useCallback(() => <Box sx={{ minWidth: '50px' }} />, []);
  const [hoverState, setHoverState] = useState(false);
  const openModal = useMemo(() => open || hoverState, [hoverState, open]);

  const handleSetHoverState = useCallback((value: boolean) => {
    setHoverState(value);
  }, []);

  const name = useMemo(() => {
    if (!_.isEmpty(record.Name)) {
      return record.Name;
    } else {
      return `${record.FirstName || ''} ${record.LastName || ''}`;
    }
  }, [record.FirstName, record.LastName, record.Name]);

  const getPropertiesGrid = useCallback(
    (label: string, value = '', options: { alwaysRender?: boolean } = {}) => {
      if (!value && options.alwaysRender !== true) return null;
      return (
        <Grid item display={'flex'} justifyContent={'space-between'}>
          <Typography variant="labelRegular12">{label}</Typography>
          <MinWidthComponent />
          <Typography variant="labelRegular12" sx={{ fontWeight: 'bold' }}>
            {value || <CircularProgress size={'8px'} sx={{ color: theme.palette.neutral.white }} />}
          </Typography>
        </Grid>
      );
    },
    [MinWidthComponent],
  );

  return (
    <NodeToolbar
      onMouseEnter={() => handleSetHoverState(true)}
      onMouseLeave={() => handleSetHoverState(false)}
      isVisible={openModal}
      position={Position.Left}
    >
      <Card sx={{ backgroundColor: theme.palette.blue.dark, color: 'white', zIndex: 999 }}>
        <Grid container display={'flex'} flexDirection={'column'} gap={1}>
          <Box sx={{ p: '1rem 1rem 0.5rem 1rem' }}>
            <Grid container>
              <Box sx={{ width: 'fit-content' }} display={'flex'} alignItems={'center'}>
                <Typography variant="labelRegular12" sx={{ fontWeight: 'bold' }}>
                  {name}
                </Typography>
              </Box>
            </Grid>
            <Box sx={{ height: '0.5rem' }}></Box>
            <Grid container display={'flex'} flexDirection={'column'}>
              {getPropertiesGrid('Revenue', valuesPayload.revenue || '')}
              {getPropertiesGrid('Account Owner', ownerData?.Name || valuesPayload.ownerId, { alwaysRender: true })}
              {getPropertiesGrid('Last Contact Date', valuesPayload.lastActivityDate)}
              <Box sx={{ height: '0.5rem' }}></Box>

              {getPropertiesGrid('Cases', relatedEventCount)}
              {getPropertiesGrid('Opportunities', relatedOpportunitiesCount)}
              {getPropertiesGrid('Contacts', relatedContactCount)}
            </Grid>
          </Box>

          <CustomDivider />

          <Box sx={{ p: '0.5rem 1rem' }}>
            <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant="labelRegular12">Confidence Score</Typography>
              <MinWidthComponent />
              <ConfidenceScore confidenceScore={confidenceScore} />
            </Grid>
          </Box>

          <Grid container display={'flex'} flexDirection={'column'}>
            <Button
              disabled={recordAnomaliesCounter == null || recordAnomaliesCounter <= 0}
              sx={{
                display: 'flex',
                width: '100%',
                p: 0,
                m: 0,
                color: 'unset',
                ':disabled': { backgroundColor: 'unset' },
              }}
              onClick={handleOpenAnomalyPanel}
            >
              <Grid
                item
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{ width: '100%', backgroundColor: customDarkBackgroundColor, p: '0.3rem 1rem' }}
              >
                <Typography variant="labelRegular12">Data Anomalies</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Box
                    sx={{
                      borderRadius: '100%',
                      backgroundColor: customLightBackgroundColor,
                      width: '24px',
                      height: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="labelRegular12" sx={{ fontWeight: 'bold' }}>
                      {recordAnomaliesCounter == null ? (
                        <CircularProgress size={'8px'} sx={{ color: theme.palette.neutral.white }} />
                      ) : (
                        recordAnomaliesCounter
                      )}
                    </Typography>
                  </Box>
                  <ArrowOpenIcon stroke={theme.palette.neutral.white} />
                </Box>
              </Grid>
            </Button>

            <Button
              disabled={possibleDuplicateRecordsCounter == null || possibleDuplicateRecordsCounter <= 0}
              sx={{
                display: 'flex',
                width: '100%',
                p: 0,
                m: 0,
                color: 'unset',
                ':disabled': { backgroundColor: 'unset' },
              }}
              onClick={handleOpenMergeModal}
            >
              <Grid
                item
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{ width: '100%', backgroundColor: customDarkBackgroundColor, p: '0.3rem 1rem' }}
              >
                <Typography variant="labelRegular12">Possible Duplicate</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Box
                    sx={{
                      borderRadius: '100%',
                      backgroundColor: customLightBackgroundColor,
                      width: '24px',
                      height: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="labelRegular12" sx={{ fontWeight: 'bold' }}>
                      {possibleDuplicateRecordsCounter == null ? (
                        <CircularProgress size={'8px'} sx={{ color: theme.palette.neutral.white }} />
                      ) : (
                        possibleDuplicateRecordsCounter
                      )}
                    </Typography>
                  </Box>
                  <ArrowOpenIcon stroke={theme.palette.neutral.white} />
                </Box>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Card>
    </NodeToolbar>
  );
};
