import { Box, Typography } from '@mui/material';
import CardRadioGroups from 'components/CardRadioGroups/CardRadioGroups';
import SourcePriorityOptions from './components/SourcePriorityOptions';
import UserDefinedOptions from './components/UserDefinedOptions';

interface MergePoliciesProps {
  onPolicySelect: (policyId: string) => void;
}

const MergePolicies = ({ onPolicySelect }: MergePoliciesProps) => {
  const policies = [
    {
      id: 'policy1',
      title: 'Timestamp ordered merge',
      subtitle: 'The system will prioritize fields with the latest updates.',
    },
    {
      id: 'policy2',
      title: 'Source priority merge',
      subtitle:
        'Assign priority rankings to each data source. The system automatically prefers data from higher-ranked sources in case of conflicts.',
      content: <SourcePriorityOptions />,
    },
    {
      id: 'policy3',
      title: 'User defined merged rules',
      subtitle:
        'Specify the preferred data sources for different fields. The system applies these rules when merging data.',
      content: <UserDefinedOptions />,
    },
  ];

  return (
    <Box sx={{ mb: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h2" mt={4} mb={2} textAlign="center">
        Merge Policies
      </Typography>

      <CardRadioGroups
        boxStyle={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'neutral.white',
          p: 2,
        }}
        cardStyle={{ mb: 2, width: '100%' }}
        items={policies}
        onChangePolicy={onPolicySelect}
      />
    </Box>
  );
};

export default MergePolicies;
