import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  createDeduplicationConfig as createDeduplicationConfigApi,
  updateDeduplicationConfig as updateDeduplicationConfigApi,
  getDeduplicationConfigByMigration as getDeduplicationConfigByMigrationApi,
  getDeduplicationConfigByMigrationAndTable as getDeduplicationConfigByMigrationAndTableApi,
  deleteDeduplicationConfigById as deleteDeduplicationConfigByIdApi,
} from 'http/deduplicationConfig';
import {
  CreateDeduplicationConfigArgs,
  UpdateDeduplicationConfigArgs,
  GetDeduplicationConfigByMigrationArgs,
  GetDeduplicationConfigByMigrationAndTableArgs,
  DeleteDeduplicationConfigByIdArgs,
  DeduplicationConfig,
} from './types';

const CREATE_DEDUPLICATION_CONFIG = 'deduplicationConfig/CREATE_DEDUPLICATION_CONFIG';
const UPDATE_DEDUPLICATION_CONFIG = 'deduplicationConfig/UPDATE_DEDUPLICATION_CONFIG';
const GET_DEDUPLICATION_CONFIG_BY_MIGRATION = 'deduplicationConfig/GET_DEDUPLICATION_CONFIG_BY_MIGRATION';
const GET_DEDUPLICATION_CONFIG_BY_MIGRATION_AND_TABLE =
  'deduplicationConfig/GET_DEDUPLICATION_CONFIG_BY_MIGRATION_AND_TABLE';
const DELETE_DEDUPLICATION_CONFIG_BY_ID = 'deduplicationConfig/DELETE_DEDUPLICATION_CONFIG_BY_ID';

export const createDeduplicationConfig = createAsyncThunk<DeduplicationConfig, CreateDeduplicationConfigArgs>(
  CREATE_DEDUPLICATION_CONFIG,
  async ({ data, onError, onSuccess }: CreateDeduplicationConfigArgs) => {
    try {
      const res = await createDeduplicationConfigApi(data);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const updateDeduplicationConfig = createAsyncThunk<DeduplicationConfig, UpdateDeduplicationConfigArgs>(
  UPDATE_DEDUPLICATION_CONFIG,
  async ({ deduplicationConfigId, data, onError, onSuccess }: UpdateDeduplicationConfigArgs) => {
    try {
      const res = await updateDeduplicationConfigApi(deduplicationConfigId, data);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const getDeduplicationConfigByMigration = createAsyncThunk<
  DeduplicationConfig[],
  GetDeduplicationConfigByMigrationArgs
>(
  GET_DEDUPLICATION_CONFIG_BY_MIGRATION,
  async ({ migrationId, onError, onSuccess }: GetDeduplicationConfigByMigrationArgs) => {
    try {
      const res = await getDeduplicationConfigByMigrationApi(migrationId);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const getDeduplicationConfigByMigrationAndTable = createAsyncThunk<
  DeduplicationConfig,
  GetDeduplicationConfigByMigrationAndTableArgs
>(
  GET_DEDUPLICATION_CONFIG_BY_MIGRATION_AND_TABLE,
  async ({ migrationId, table, onError, onSuccess }: GetDeduplicationConfigByMigrationAndTableArgs) => {
    try {
      const res = await getDeduplicationConfigByMigrationAndTableApi(migrationId, table);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const deleteDeduplicationConfigById = createAsyncThunk<null, DeleteDeduplicationConfigByIdArgs>(
  DELETE_DEDUPLICATION_CONFIG_BY_ID,
  async ({ migrationId, id, onError, onSuccess }: DeleteDeduplicationConfigByIdArgs) => {
    try {
      const res = await deleteDeduplicationConfigByIdApi(migrationId, id);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);
