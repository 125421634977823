import { Box, CircularProgress, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Handle, Position, NodeProps, useReactFlow } from 'reactflow';
import { ReactComponent as CollapseIcon } from 'assets/icons/graph/graphCollapsedIcon.svg';
import { ReactComponent as UnCollapseIcon } from 'assets/icons/graph/graphUncollapsedIcon.svg';
import { ReactComponent as GraphCircleIcon } from 'assets/icons/graph/graphCircle.svg';
import { getRecordGroupCount, getMigrationTableDataById } from 'http/migration';
import { FunctionValue, FunctionValueEnum } from 'http/migration/dto';
import { ReactComponent as GraphCategoryIcon } from 'assets/icons/graph/leadViewCategory.svg';
import { ReactComponent as GraphArrowIcon } from 'assets/icons/graph/leadViewArrow.svg';
import theme from 'core/theme';
import { ComponentDesign } from '../../../types';
import { useGraphRender } from 'store/graphRender/hooks';
import { useGraphRenderDataHandlerUtils } from '../../../hooks/utils/useGraphRenderDataHandlerUtils';
import { BridgeNode, BridgeNodeTypeEnum } from '../../../classes/BridgeNode';
import { Edge, EdgeType } from '../../../classes/Edge';
import { useMigration } from 'store/migration/hooks';
import { addDaySuffix, getMonthName } from 'core/utils/time';
import { RecordNode, NodeRecordTypeEnum } from '../../../classes/RecordNode';
import { motion } from 'framer-motion';
import { useGraphRenderLayoutUtils } from '../../../hooks/utils/useGraphRenderLayoutUtils';
import { getSortingFunction, necessaryFieldsForSorting } from '../../../utils/LeadViewSortingOptions';
import _ from 'lodash';

const desiredEdgedLength = 170;
const recursionLevel = 0;

const necessaryFieldForCardList = ['Phone', 'MobilePhone', 'Company', 'Title'];

export const LeadViewCategoryNode: FC<NodeProps> = (props: NodeProps) => {
  const { data, id, selected } = props;
  const reactFlow = useReactFlow();
  const [loading, setLoading] = useState<boolean>(false);
  const [renderFirstTime, setRenderFirstTime] = useState(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const {
    data: {
      leadViewState: { groupByFieldsSelected, maxNodeCountToExpand, sortByField, expandSearchNodes },
      schemaName,
    },
  } = useGraphRender();
  const {
    data: { migrationId },
  } = useMigration();
  const { addComponentsToHashMap } = useGraphRenderDataHandlerUtils();
  const { onHide } = useGraphRenderLayoutUtils();

  const {
    bridgeLabel,
    _collapsed,
    connectionsCount,
    metaData: { groupByConditions, categoryLevel },
  } = data;
  const collapsed = _collapsed == null ? true : _collapsed;
  const HandlerStyle = { display: 'none' };

  const dateIsNull = useMemo(() => {
    return (
      typeof groupByConditions[groupByConditions.length - 1].field === 'object' &&
      groupByConditions[groupByConditions.length - 1].value === 'null' &&
      categoryLevel >= 1
    );
  }, [categoryLevel, groupByConditions]);

  const {
    data: {
      customDesignMap,
      defaultDesign,
      leadViewState: { loadedBridgeNodes },
    },
    leadViewReducers: { setLeadViewLoadedBridgeNode },
    setSelectedNode,
    setSelectedNodeFromSearchBar,
  } = useGraphRender();

  useEffect(() => {
    if (selected) {
      const node = reactFlow.getNode(id);
      const nodeTmp = _.cloneDeep(node);
      setSelectedNode(nodeTmp);
      setSelectedNodeFromSearchBar(nodeTmp);
    }
  }, [id, reactFlow, selected, setSelectedNode, setSelectedNodeFromSearchBar]);

  const calculatedConnectionCount: number = useMemo(() => parseInt(connectionsCount) || 0, [connectionsCount]);

  const shouldTriggerRecords = useMemo(() => {
    return (
      calculatedConnectionCount <= maxNodeCountToExpand ||
      categoryLevel >= groupByFieldsSelected.length - 1 ||
      dateIsNull
    );
  }, [calculatedConnectionCount, categoryLevel, dateIsNull, groupByFieldsSelected, maxNodeCountToExpand]);

  const sortingFunction = useMemo(() => {
    if (shouldTriggerRecords !== true) {
      return undefined;
    }
    return getSortingFunction(sortByField);
  }, [shouldTriggerRecords, sortByField]);

  const calculateConnectionCountString = useMemo(
    () => ((connectionsCount || 0) <= 99 ? connectionsCount || 0 : '+99'),
    [connectionsCount],
  );

  const loadRecords = useCallback(() => {
    setLoading(true);
    const conditions = groupByConditions.map((condition: { field: string | FunctionValue; value: string }) => {
      if (condition.value === 'null') return { field: condition.field, operator: 'IS', value: 'NULL' };
      return { field: condition.field, operator: '=', value: `'${condition.value}'` };
    });

    getMigrationTableDataById(migrationId, 'Lead', 0, 1000, conditions, [
      'Id',
      'Name',
      'confidence_score',
      'FirstName',
      'LastName',
      ...necessaryFieldsForSorting,
      ...necessaryFieldForCardList,
    ])
      .then((res) => {
        const nodes = res.map((record) => {
          const nodeId = `${schemaName}.${record.Id}`;
          const node = new RecordNode(nodeId, 'Lead', {
            data: { ...record, id: record.Id, confidence_score: record.confidence_score, Name: record.Name },
            properties: { hidden: false, type: NodeRecordTypeEnum.RecordNode, draggable: true },
            metaData: { previousNodePrefix: id },
          });
          return node;
        });

        const edges = nodes.map((node) => {
          const edgeId = `${id}#${node.data.id}`;
          return new Edge(edgeId, EdgeType.floating, id, node.data.id, { hidden: false });
        });
        const nodesMapped = nodes.reduce((acc: any, node: RecordNode) => {
          acc[node.data.id] = node;
          return acc;
        }, {});
        const edgesMapped = edges.reduce((acc: any, edge: Edge) => {
          acc[edge.data.id] = edge;
          return acc;
        }, {});
        addComponentsToHashMap(nodesMapped, edgesMapped);
        setRenderFirstTime(true);
        setDataLoaded(true);
        setLeadViewLoadedBridgeNode({ key: id, value: true });
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [addComponentsToHashMap, groupByConditions, id, migrationId, schemaName, setLeadViewLoadedBridgeNode]);

  const loadNextCategory = useCallback(() => {
    setLoading(true);
    const conditions = groupByConditions.map((condition: { field: string | FunctionValue; value: string }) => {
      if (condition.value === 'null') return { field: condition.field, operator: 'IS', value: 'NULL' };
      return { field: condition.field, operator: '=', value: `'${condition.value}'` };
    });
    const groupByFieldNext = groupByFieldsSelected[categoryLevel + 1] as FunctionValue;
    const groupByNextLevel = groupByFieldsSelected.filter((field, index) => index <= categoryLevel + 1);
    getRecordGroupCount(migrationId, 'Lead', {
      groupBy: groupByNextLevel,
      conditions,
    })
      .then((res) => {
        const key = groupByFieldNext.label || 'null';
        const nodes = res.map((group) => {
          const fieldCategoryUsed = group[key] || 'null';
          const nodeId = `${id}-#-${fieldCategoryUsed.replace(/ /g, '')}`;
          let label = fieldCategoryUsed;
          if ((groupByFieldsSelected[categoryLevel + 1] as FunctionValue).function === FunctionValueEnum.getMonth) {
            label = getMonthName(parseInt(fieldCategoryUsed));
          }
          if ((groupByFieldsSelected[categoryLevel + 1] as FunctionValue).function === FunctionValueEnum.getDay) {
            label = addDaySuffix(parseInt(fieldCategoryUsed));
          }
          const bridgeNode = new BridgeNode(nodeId, label, {
            data: { connectionsCount: group.count },
            metaData: {
              groupByConditions: [...groupByConditions, { field: groupByFieldNext, value: fieldCategoryUsed }],
              categoryLevel: categoryLevel + 1,
              previousNodePrefix: id,
            },
            properties: { hidden: false, type: BridgeNodeTypeEnum.LeadViewCategoryNode, draggable: true },
          });
          return bridgeNode;
        });

        const edges = nodes.map((node) => {
          const edgeId = `lead-view-edge-${node.data.id}`;
          return new Edge(edgeId, EdgeType.floating, id, node.data.id, { hidden: false });
        });

        const nodesMapped = nodes.reduce((acc: any, node: BridgeNode) => {
          acc[node.data.id] = node;
          return acc;
        }, {});
        const edgesMapped = edges.reduce((acc: any, edge: Edge) => {
          acc[edge.data.id] = edge;
          return acc;
        }, {});
        addComponentsToHashMap(nodesMapped, edgesMapped);
        setRenderFirstTime(true);
        setDataLoaded(true);
        setLeadViewLoadedBridgeNode({ key: id, value: true });
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [
    addComponentsToHashMap,
    categoryLevel,
    groupByConditions,
    groupByFieldsSelected,
    id,
    migrationId,
    setLeadViewLoadedBridgeNode,
  ]);

  useEffect(() => {
    if (loadedBridgeNodes[id] === true) {
      if (dataLoaded != true) {
        setDataLoaded(true);
      }
    } else {
      if (dataLoaded != false) {
        setDataLoaded(false);
      }
    }
  }, [dataLoaded, id, loadedBridgeNodes]);

  useEffect(() => {
    if (renderFirstTime === true) {
      setRenderFirstTime(false);
      onHide(id, false, {
        desiredEdgedLength: desiredEdgedLength,
        checkPrefix: id,
        recursionLevel: recursionLevel,
        sortingFunction: sortingFunction,
      });
    }
  }, [id, onHide, renderFirstTime, sortingFunction]);

  useEffect(() => {
    if (_collapsed === false && sortingFunction) {
      onHide(id, false, {
        desiredEdgedLength: desiredEdgedLength,
        checkPrefix: id,
        recursionLevel: recursionLevel,
        sortingFunction: sortingFunction,
      });
    }
  }, [_collapsed, id, onHide, sortingFunction]);

  const expandChildNodes = useCallback(() => {
    if (shouldTriggerRecords) {
      loadRecords();
    } else {
      loadNextCategory();
    }
  }, [loadNextCategory, loadRecords, shouldTriggerRecords]);

  const triggerCollapse = useCallback(() => {
    if (id != null) {
      if (dataLoaded != true) {
        expandChildNodes();
      } else {
        onHide(id, !collapsed, {
          desiredEdgedLength: desiredEdgedLength,
          checkPrefix: id,
          recursionLevel: recursionLevel,
          sortingFunction: sortingFunction,
        });
      }
    }
  }, [collapsed, dataLoaded, expandChildNodes, id, onHide, sortingFunction]);

  const expandSearchNodeIds = useMemo(() => {
    return Object.keys(expandSearchNodes || {}).reduce((prev: any[], current: string) => {
      return [...prev, ...expandSearchNodes[current]];
    }, []);
  }, [expandSearchNodes]);

  const shouldExpand = useMemo(() => {
    return expandSearchNodeIds.includes(id) && collapsed;
  }, [collapsed, expandSearchNodeIds, id]);

  useEffect(() => {
    if (shouldExpand) {
      triggerCollapse();
    }
  }, [shouldExpand]);

  let design = defaultDesign ? defaultDesign : ComponentDesign.STANDARD;

  if (customDesignMap[id]) {
    design = customDesignMap[id];
  }

  const borderColor = useMemo(() => {
    if (design === ComponentDesign.SEARCHING || design === ComponentDesign.DUPLICATE_INACTIVE) {
      return theme.palette.neutral.subtone3;
    }
    return theme.palette.primary.darkBlueHigh;
  }, [design]);

  const shouldRenderBridgeLabel = useMemo(() => {
    if (design === ComponentDesign.DUPLICATE_INACTIVE) {
      return false;
    }
    return true;
  }, [design]);

  const shouldRenderIcon = useMemo(() => {
    if (design === ComponentDesign.SEARCHING || design === ComponentDesign.DUPLICATE_INACTIVE) {
      return false;
    }
    return true;
  }, [design]);

  const icon = useMemo(() => <GraphCategoryIcon width={'100%'} height={'100%'} />, []);

  return (
    <motion.div
      style={{
        border: '3px solid',
        borderColor: borderColor,
        backgroundColor: theme.palette.neutral.white,
        borderRadius: '100%',
        width: '100%',
        height: '100%',
      }}
      onClick={triggerCollapse}
    >
      {shouldRenderIcon && loading != true && (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {icon}
        </Box>
      )}

      {loading == true && (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={'20px'} />
        </Box>
      )}

      <Box
        position={'absolute'}
        top={'-10%'}
        left={'-95%'}
        width={'200%'}
        sx={{ color: theme.palette.neutral.main, textAlign: 'center' }}
      >
        <GraphArrowIcon />
      </Box>

      {shouldRenderBridgeLabel && (
        <Box
          position={'absolute'}
          top={'90%'}
          left={'-20%'}
          width={'200%'}
          sx={{ color: theme.palette.neutral.main, textAlign: 'center' }}
        >
          <Typography variant="labelRegular12">{bridgeLabel}</Typography>
        </Box>
      )}
      <Box position={'absolute'} bottom={'-20%'} left={'-2%'} sx={{ color: theme.palette.primary.gray }}>
        {collapsed ? (
          <CollapseIcon width={'10px'} height={'10px'} />
        ) : (
          <UnCollapseIcon width={'10px'} height={'10px'} />
        )}
      </Box>
      {collapsed && (
        <>
          <GraphCircleIcon
            style={{ position: 'absolute', top: '-10%', right: '-10%' }}
            width={'22px'}
            height={'22px'}
          />
          <Box
            position={'absolute'}
            top={'-10%'}
            right={'-10%'}
            width={'22px'}
            height={'22px'}
            sx={{
              color: theme.palette.primary.darkBlueHigh,
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="labelRegular10" sx={{ fontWeight: 'bold', fontSize: '8px' }}>
              {calculateConnectionCountString}
            </Typography>
          </Box>
        </>
      )}
      <Handle position={Position.Right} type="source" style={HandlerStyle} />
      <Handle position={Position.Left} type="target" style={HandlerStyle} />
    </motion.div>
  );
};
