import React from 'react';
import { Menu, Box, Typography, MenuItem, Checkbox, Stack } from '@mui/material';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

interface FilterButtonProps {
  handleChange: (key: 'type' | 'status', field: string) => void;
  excludedStatuses: string[];
  excludedTypes: string[];
}
const FilterButton = ({ excludedStatuses, excludedTypes, handleChange }: FilterButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const types = [
    {
      label: 'Tasks',
      value: 'task',
    },
    {
      label: 'Events',
      value: 'event',
    },
    {
      label: 'Notes',
      value: 'note',
    },
    {
      label: 'Calls',
      value: 'call',
    },
    {
      label: 'Emails',
      value: 'email',
    },
  ];

  const stages = [
    {
      label: 'Done',
      value: 'done',
    },
    {
      label: 'In progress',
      value: 'in_progress',
    },
    {
      label: 'Upcoming',
      value: 'upcoming',
    },
    {
      label: 'Past',
      value: 'past',
    },
  ];
  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        gap={0.5}
        sx={{ m: 'auto', cursor: 'pointer' }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <FilterListOutlinedIcon display={'inline'} sx={{ color: 'primary.main' }} />
        <Typography sx={{ color: 'primary.main', display: 'inline' }}>Filter</Typography>
      </Box>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        sx={{
          '& .MuiPaper-root': {
            px: 2,
          },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Typography variant="caption" color="neutral.n400" mx={2}>
          Filter By Stage
        </Typography>
        {stages.map((stage) => (
          <MenuItem key={stage.value}>
            <Stack alignItems="center" direction="row" gap={0.5}>
              <Checkbox
                checked={!excludedStatuses.includes(stage.value)}
                onChange={() => handleChange('status', stage.value)}
              />
              <Typography variant="p14">{stage.label}</Typography>
            </Stack>
          </MenuItem>
        ))}
        <Typography variant="caption" color="neutral.n400" mx={2}>
          Filter By Type
        </Typography>
        {types.map((type) => (
          <MenuItem key={type.value}>
            <Stack alignItems="center" direction="row" gap={0.5}>
              <Checkbox
                checked={!excludedTypes.includes(type.value)}
                onChange={() => handleChange('type', type.value)}
              />
              <Typography variant="p14">{type.label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default FilterButton;
