import { Box, Button, Menu, Typography } from '@mui/material';
import { AutoCompleteMultipleSelector } from 'components/AutoCompleteMultipleSelector';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDashboard } from 'store/dashboard/hook';
import { useLazyGetMigrationTablesByIdQuery } from 'store/migration/api';
import { useSnackbar } from 'notistack';

export interface AdditionalTableSelectorProps {
  anchorElTableSelector: HTMLElement | null;
  handleClickTableSelector: (event: React.MouseEvent<HTMLElement>) => void;
  handleMenuCloseTableSelector: () => void;
}

export const AdditionalTableSelector: FC<AdditionalTableSelectorProps> = ({
  anchorElTableSelector,
  handleClickTableSelector,
  handleMenuCloseTableSelector,
}) => {
  const {
    data: {
      selectedMigrationId,
      selectedTable,
      dataQualityTimeline: { additionalTables },
    },
    methods: { setQualityTimelineAdditionalTables },
  } = useDashboard();
  const { enqueueSnackbar } = useSnackbar();

  const [getMigrationTablesById, migrationTables] = useLazyGetMigrationTablesByIdQuery();

  const handleChangeAdditionalTables = useCallback(
    (tables: string[]) => {
      if (tables.length > 5) {
        enqueueSnackbar('You can only select up to 5 tables', { variant: 'error' });
        return;
      }
      setQualityTimelineAdditionalTables(tables);
      handleMenuCloseTableSelector();
    },
    [enqueueSnackbar, handleMenuCloseTableSelector, setQualityTimelineAdditionalTables],
  );

  useEffect(() => {
    if (selectedMigrationId) {
      getMigrationTablesById({ migrationId: selectedMigrationId });
    }
  }, [selectedMigrationId, getMigrationTablesById]);

  const tableOptions = useMemo(() => {
    if (!migrationTables.data) return [];
    const filteredTable = migrationTables.data?.filter((table) => table.table_name !== selectedTable) || [];
    return filteredTable
      .map((table) => ({ label: table.table_name, value: table.table_name }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [migrationTables.data, selectedTable]);

  const tablesSelectedText = useMemo(() => {
    if (additionalTables.length === 0) return 'None';
    if (additionalTables.length === 1) return '1 table';
    return `${additionalTables.length} tables`;
  }, [additionalTables.length]);

  return (
    <Box width={'fit-content'} display={'flex'} gap={1} alignItems={'center'}>
      <Typography fontSize={12} sx={{ color: '#898EA1' }}>
        Add Table Data:
      </Typography>
      <Button
        variant="text"
        aria-controls={anchorElTableSelector ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorElTableSelector ? 'true' : undefined}
        sx={{
          color: 'black',
          fontSize: '12px',
          '&:hover': {
            backgroundColor: 'initial',
            boxShadow: 'none',
            color: 'black',
          },
        }}
        onClick={handleClickTableSelector}
        endIcon={anchorElTableSelector ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {tablesSelectedText}
      </Button>
      <Menu
        id="user-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorElTableSelector}
        open={Boolean(anchorElTableSelector)}
        onClose={handleMenuCloseTableSelector}
      >
        <AutoCompleteMultipleSelector<string>
          options={tableOptions}
          itemLabel={'table'}
          pluralItemLabel={'tables'}
          searchInputPlaceholder={'Search table'}
          initialValuesSelected={additionalTables}
          onChange={handleChangeAdditionalTables}
        />
      </Menu>
    </Box>
  );
};
