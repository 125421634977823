import React from 'react';
import { Grid, Box, Typography, CircularProgress, Stack, Divider, Tooltip } from '@mui/material';
import format from 'date-fns/format';
import AccountEngagementChart from './components/AccountEngagementChart';
import ClientResponsiveness from './components/ClientResponsiveness';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CallIcon from '@mui/icons-material/Call';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import useContactInsightsData from './hooks/useContactInsightsData';

interface IProps {
  migrationId: string;
  node: any;
}
const EngagementMenuView = ({ migrationId, node }: IProps) => {
  const { isLoading, activitiesCount, lastContacted, nextMeeting, preferredMethod, accountEngagementChartData } =
    useContactInsightsData({
      migrationId,
      node,
    });
  return (
    <Grid display={'flex'} direction={'column'} gap={2} p={1}>
      <Stack sx={{ height: '100%' }} gap={2}>
        {isLoading ? (
          <Box display={'flex'} alignItems={'center'} height={'50px'} gap={1}>
            <CircularProgress size={'16px'} color="primary" />
            <Typography>Loading Engagement...</Typography>
          </Box>
        ) : (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" p={1}>
              <Stack gap={0.5}>
                <Typography variant="caption" color="neutral.n400">
                  Next meeting
                </Typography>
                <Typography variant="p12">
                  {nextMeeting ? format(new Date(nextMeeting?.ActivityDate), 'd MMM yyyy') : 'N/A'}
                </Typography>
              </Stack>
              <Stack gap={0.5}>
                <Typography variant="caption" color="neutral.n400">
                  Last contacted
                </Typography>
                <Typography variant="p12">
                  {lastContacted ? format(new Date(lastContacted?.ActivityDate), 'd MMM yyyy') : 'N/A'}
                </Typography>
              </Stack>
              <Stack gap={0.5}>
                <Typography variant="caption" color="neutral.n400">
                  Activities
                </Typography>
                <Typography variant="p12">{activitiesCount}</Typography>
              </Stack>
            </Stack>
            <AccountEngagementChart data={accountEngagementChartData || []} />
            <ClientResponsiveness value={node.Probability} />

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ border: '1px solid', borderColor: 'neutral.n300', p: 1 }}
            >
              <Typography variant="caption">PREFERRED COMMUNICAITON METHOD</Typography>
              <Box
                sx={{
                  borderRadius: '50%',
                  backgroundColor: 'violet.main',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '30px',
                  height: '30px',
                }}
              >
                {
                  [
                    <CallIcon key={'call_icon'} sx={{ color: 'white', fontSize: '16px' }} />,
                    <EmailOutlinedIcon key={'email_icon'} sx={{ color: 'white', fontSize: '16px' }} />,
                  ][['call', 'email'].indexOf(preferredMethod)]
                }
              </Box>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" p={1}>
              <Stack gap={0.5} sx={{ backgroundColor: '#00000008', width: '25%' }} p={1}>
                <Stack direction="row" alignItems="center" justifyContent={'center'} gap={0.5}>
                  <EmailOutlinedIcon sx={{ fontSize: '16px', color: 'blue.main' }} />
                  <Typography variant="p14">Emails</Typography>
                </Stack>
                <Divider />
                <Stack direction="row" alignItems="center" justifyContent="space-around" gap={1}>
                  <Tooltip title="Sent" arrow>
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <Typography variant="p12">8</Typography>
                      <CallMadeIcon sx={{ color: 'neutral.n400', fontSize: '14px' }} />
                    </Stack>
                  </Tooltip>
                  <Tooltip title="Received" arrow>
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <Typography variant="p12">8</Typography>
                      <CallReceivedIcon sx={{ color: 'neutral.n400', fontSize: '14px' }} />
                    </Stack>
                  </Tooltip>
                </Stack>
              </Stack>

              <Stack gap={0.5} sx={{ backgroundColor: '#00000008', width: '25%' }} p={1}>
                <Stack direction="row" alignItems="center" justifyContent={'center'} gap={0.5}>
                  <PhoneOutlinedIcon sx={{ fontSize: '16px' }} />
                  <Typography variant="p14">Calls</Typography>
                </Stack>
                <Divider />
                <Stack direction="row" alignItems="center" justifyContent="space-around" gap={1}>
                  <Tooltip title="Sent" arrow>
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <Typography variant="p12">8</Typography>
                      <CallMadeIcon sx={{ color: 'neutral.n400', fontSize: '14px' }} />
                    </Stack>
                  </Tooltip>
                  <Tooltip title="Received" arrow>
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <Typography variant="p12">8</Typography>
                      <CallReceivedIcon sx={{ color: 'neutral.n400', fontSize: '14px' }} />
                    </Stack>
                  </Tooltip>
                </Stack>
              </Stack>

              <Stack gap={0.5} sx={{ backgroundColor: '#00000008', width: '25%' }} p={1}>
                <Stack direction="row" alignItems="center" justifyContent={'center'} gap={0.5}>
                  <TextSnippetOutlinedIcon sx={{ fontSize: '16px', color: 'red.main' }} />
                  <Typography variant="p14">Docs</Typography>
                </Stack>
                <Divider />
                <Stack direction="row" alignItems="center" justifyContent="space-around" gap={1}>
                  <Tooltip title="Sent" arrow>
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <Typography variant="p12">8</Typography>
                      <CallMadeIcon sx={{ color: 'neutral.n400', fontSize: '14px' }} />
                    </Stack>
                  </Tooltip>

                  <Tooltip title="Received" arrow>
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <Typography variant="p12">8</Typography>
                      <CallReceivedIcon sx={{ color: 'neutral.n400', fontSize: '14px' }} />
                    </Stack>
                  </Tooltip>
                </Stack>
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </Grid>
  );
};

export default EngagementMenuView;
