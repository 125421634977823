import React from 'react';
import { PrimaryButton } from 'components/ui';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Menu, MenuItem, Typography, Stack, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

const SettingsButton = () => {
  const [anchEl, setAnchEl] = React.useState<HTMLElement | null>(null);
  return (
    <>
      <PrimaryButton sx={{ ml: 2, minWidth: 'auto' }} onClick={(e) => setAnchEl(e.currentTarget)}>
        <SettingsOutlinedIcon sx={{ fontSize: '16px' }} />
      </PrimaryButton>
      <Menu
        open={!!anchEl}
        anchorEl={anchEl}
        onClose={() => setAnchEl(null)}
        sx={{
          '& .MuiPaper-root': {
            px: 2,
            py: 1,
          },
        }}
      >
        <Typography color="neutral.n400" variant="p14">
          Graph space settings
        </Typography>
        <MenuItem>
          <Stack direction="row" alignItems={'center'} spacing={2}>
            <AddIcon sx={{ fontSize: '16px' }} />
            <Typography variant="body2">Add new data source</Typography>
          </Stack>
        </MenuItem>
        <MenuItem>
          <Stack direction="row" alignItems={'center'} spacing={2}>
            <BorderColorOutlinedIcon sx={{ fontSize: '16px' }} />
            <Typography variant="body2">Merge policies</Typography>
          </Stack>
        </MenuItem>
        <Divider />
        <MenuItem>
          <Stack direction="row" alignItems={'center'} spacing={2}>
            <InsertDriveFileOutlinedIcon sx={{ fontSize: '16px' }} />
            <Typography variant="body2">Archive Graph Space</Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SettingsButton;
