export function calculateDirectionBetweenPoints(x1: number, y1: number, x2: number, y2: number) {
  const dx = x2 - x1;
  const dy = y2 - y1;
  return Math.atan2(dy, dx);
}

export function getDistanceBetweenPoints(x1: number, y1: number, x2: number, y2: number) {
  const dx = x2 - x1;
  const dy = y2 - y1;
  return Math.sqrt(dx ** 2 + dy ** 2);
}

export function incrementDistanceToAPointBasedOnADirection(x: number, y: number, radDirection: number, increment = 10) {
  const dx = Math.cos(radDirection) * increment;
  const dy = Math.sin(radDirection) * increment;
  const newX = x + dx;
  const newY = y + dy;
  return { x: newX, y: newY };
}

export function getEdgePositionPayload(
  fatherNode: any,
  bridgeNode: any,
  childNodes: any[],
  desiredEdgeLength = 50,
  expandPiMultiplier = 1,
) {
  const nodePayload: any = {};
  const piMultiplier = expandPiMultiplier;
  let childNodeSpacingIncrement = (Math.PI * piMultiplier) / childNodes.length;
  if (piMultiplier <= 1) {
    childNodeSpacingIncrement = childNodeSpacingIncrement > Math.PI / 4 ? Math.PI / 4 : childNodeSpacingIncrement;
  }
  const currentIncrementValue = childNodeSpacingIncrement;
  const { x: accountXTmp, y: accountYTmp } = fatherNode.position || { x: 0, y: 0 };
  const { height: fatherHeight = 0, width: fatherWidth = 0 } = fatherNode;
  const { x: bridgeXTmp, y: bridgeYTmp } = bridgeNode.position || { x: 0, y: 0 };
  const { height: bridgeHeight = 0, width: bridgeWidth = 0 } = bridgeNode;

  const accountX = accountXTmp + fatherWidth / 4;
  const accountY = accountYTmp + fatherHeight / 4;
  const bridgeX = bridgeXTmp + bridgeWidth / 4;
  const bridgeY = bridgeYTmp + bridgeHeight / 4;

  const pivotDirection = calculateDirectionBetweenPoints(accountX, accountY, bridgeX, bridgeY);

  getNodeCircleLayout({
    childNodes,
    pivotDirection,
    desiredEdgeLength,
    bridgeX,
    bridgeY,
    currentIncrementValue,
    childNodeSpacingIncrement,
    nodePayload,
    layerLevelThreshold: 8 * piMultiplier,
  });

  return nodePayload;
}

const getNodeCircleIntercalatedLayout = ({
  childNodes,
  pivotDirection,
  desiredEdgeLength,
  bridgeX,
  bridgeY,
  currentIncrementValue,
  childNodeSpacingIncrement,
  nodePayload,
  layerLevelThreshold,
}: any) => {
  let newCurrentIncrementValue = currentIncrementValue;
  let layerLevels = 1;
  let verticalDiff = 0;
  let initialPositionPivot = 1;
  if (childNodes.length > layerLevelThreshold) {
    layerLevels = Math.floor(childNodes.length / layerLevelThreshold) + 1;
    verticalDiff = desiredEdgeLength / layerLevels;
  }

  const middleIndex = Math.floor(childNodes.length / 2);
  if (childNodes.length > 0) {
    const dir = pivotDirection;
    const dx = Math.cos(dir) * desiredEdgeLength;
    const dy = Math.sin(dir) * desiredEdgeLength;
    const newX = bridgeX + dx;
    const newY = bridgeY + dy;
    nodePayload[childNodes[middleIndex]] = { x: newX, y: newY, dir };
  }

  //Process from the middle node down
  for (let i = middleIndex - 1; i >= 0; i--) {
    let dx = 0;
    let dy = 0;
    const dir = pivotDirection - newCurrentIncrementValue;
    dx = Math.cos(dir) * (desiredEdgeLength - verticalDiff * (initialPositionPivot % layerLevels));
    dy = Math.sin(dir) * (desiredEdgeLength - verticalDiff * (initialPositionPivot % layerLevels));
    const newX = bridgeX + dx;
    const newY = bridgeY + dy;
    nodePayload[childNodes[i]] = { x: newX, y: newY, dir };
    newCurrentIncrementValue += childNodeSpacingIncrement;
    initialPositionPivot++;
  }

  newCurrentIncrementValue = childNodeSpacingIncrement;
  initialPositionPivot = 1;

  for (let i = middleIndex + 1; i < childNodes.length; i++) {
    let dx = 0;
    let dy = 0;
    const dir = pivotDirection + newCurrentIncrementValue;
    dx = Math.cos(dir) * (desiredEdgeLength - verticalDiff * (initialPositionPivot % layerLevels));
    dy = Math.sin(dir) * (desiredEdgeLength - verticalDiff * (initialPositionPivot % layerLevels));
    const newX = bridgeX + dx;
    const newY = bridgeY + dy;
    nodePayload[childNodes[i]] = { x: newX, y: newY, dir };
    newCurrentIncrementValue += childNodeSpacingIncrement;
    initialPositionPivot++;
  }
};

const getNodeCircleLayout = ({
  childNodes,
  pivotDirection,
  desiredEdgeLength,
  bridgeX,
  bridgeY,
  currentIncrementValue,
  childNodeSpacingIncrement,
  nodePayload,
}: any) => {
  let newCurrentIncrementValue = currentIncrementValue;
  const middleIndex = Math.floor(childNodes.length / 2);
  if (childNodes.length > 0) {
    const dir = pivotDirection;
    const dx = Math.cos(dir) * desiredEdgeLength;
    const dy = Math.sin(dir) * desiredEdgeLength;
    const newX = bridgeX + dx;
    const newY = bridgeY + dy;
    nodePayload[childNodes[middleIndex]] = { x: newX, y: newY, dir };
  }

  //Process from the middle node down
  for (let i = middleIndex - 1; i >= 0; i--) {
    let dx = 0;
    let dy = 0;
    const dir = pivotDirection - newCurrentIncrementValue;
    dx = Math.cos(dir) * desiredEdgeLength;
    dy = Math.sin(dir) * desiredEdgeLength;
    const newX = bridgeX + dx;
    const newY = bridgeY + dy;
    nodePayload[childNodes[i]] = { x: newX, y: newY, dir };
    newCurrentIncrementValue += childNodeSpacingIncrement;
  }

  newCurrentIncrementValue = childNodeSpacingIncrement;

  for (let i = middleIndex + 1; i < childNodes.length; i++) {
    let dx = 0;
    let dy = 0;
    const dir = pivotDirection + newCurrentIncrementValue;
    dx = Math.cos(dir) * desiredEdgeLength;
    dy = Math.sin(dir) * desiredEdgeLength;
    const newX = bridgeX + dx;
    const newY = bridgeY + dy;
    nodePayload[childNodes[i]] = { x: newX, y: newY, dir };
    newCurrentIncrementValue += childNodeSpacingIncrement;
  }
};
