import { Grid, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { TableFilter } from 'store/graphData/types';
import { useGraphRender } from 'store/graphRender/hooks';
import {
  ConditionalValue,
  FieldReference,
  LogicalOperator,
  PrimitiveValue,
  RootCondition,
} from 'store/dataRaptorRule/dto/front-end-rule.dto';
import FilterBarTag, { ResultDetail } from './components/FilterBarTag';
import { GraphRenderView } from 'store/graphRender/types';

export const FilterBar: FC = () => {
  const {
    data: { graphFilter, view: graphView, searching },
  } = useGraphRender();

  const getConditionalValuesDetail = useCallback((value: ConditionalValue) => {
    if (value.type === RuleComponentType.PRIMITIVE_VALUE) {
      return (value as PrimitiveValue).value;
    }
    if (value.type === RuleComponentType.FIELD_REFERENCE) {
      return (value as FieldReference).value;
    }
  }, []);

  const processGraphFilterTableDetails = useCallback(
    (filter: TableFilter, tableLabel: string, tableName: string, resultDetails: ResultDetail[]) => {
      if (filter.where) {
        filter.where.forEach((where, index) => {
          if (where.type == RuleComponentType.ROOT_CONDITIONAL) {
            const conditional = where as RootCondition;
            resultDetails.push({
              tableLabel,
              tableName,
              detail: `${getConditionalValuesDetail(conditional.field)} ${
                conditional.operator
              } ${getConditionalValuesDetail(conditional.value)}`,
              allowElimination: true,
              index,
            });
          }
          if (where.type == RuleComponentType.LOGICAL_OPERATOR) {
            const logicalOperator = where as LogicalOperator;
            resultDetails.push({
              detail: logicalOperator.value,
              allowElimination: false,
              index,
            });
          }
        });
      }
    },
    [getConditionalValuesDetail],
  );

  const getGraphFilterDetails = useCallback(() => {
    if (graphFilter) {
      const resultDetail: ResultDetail[] = [];
      graphFilter.account && processGraphFilterTableDetails(graphFilter.account, 'Account', 'account', resultDetail);
      graphFilter.contact && processGraphFilterTableDetails(graphFilter.contact, 'Contact', 'contact', resultDetail);
      graphFilter.opportunity &&
        processGraphFilterTableDetails(graphFilter.opportunity, 'Opportunity', 'opportunity', resultDetail);
      graphFilter.lead && processGraphFilterTableDetails(graphFilter.lead, 'Lead', 'lead', resultDetail);
      graphFilter.event && processGraphFilterTableDetails(graphFilter.event, 'Activity', 'event', resultDetail);
      const components = resultDetail.map((detail, index) => {
        return (
          <FilterBarTag key={`${detail.tableLabel}-${index}-filter-detail-bar`} resultDetail={detail} index={index} />
        );
      });
      return components;
    }
    return [];
  }, [graphFilter, processGraphFilterTableDetails]);

  const showFilterBar = useMemo(() => {
    return graphFilter && searching !== true && graphView === GraphRenderView.ACCOUNT ? true : false;
  }, [graphFilter, graphView, searching]);

  return (
    <>
      {showFilterBar && (
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'auto',
            height: '48px',
            justifyContent: 'left',
            alignItems: 'center',
            flexWrap: 'nowrap',
            '&::-webkit-scrollbar': {
              height: '8px',
            },
            px: '1rem',
          }}
          gap={2}
        >
          <Grid>
            <Typography>Filters</Typography>
          </Grid>
          {getGraphFilterDetails()}
        </Grid>
      )}
    </>
  );
};

export default FilterBar;
