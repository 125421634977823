import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';

interface IProps {
  position: {
    x: number;
    y: number;
  };
  data?: any;
}
const CustomTooltip = ({ position, data }: IProps) => {
  if (!data) return <></>;

  return (
    <Stack
      sx={{
        position: 'absolute',
        top: position.y,
        left: position.x,
        p: 1,
        backgroundColor: 'common.black',
        borderRadius: '5px',
        color: 'common.white',
        width: '200px',
      }}
      spacing={0.5}
    >
      <Typography variant="labelBold14" color="common.white">
        {data.period}
      </Typography>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Stack
            sx={{
              width: '12px',
              height: '12px',
              borderRadius: '2px',
              backgroundColor: data['Opened sent emails'].color,
            }}
          />
          <Typography variant="p12">Opened sent emails</Typography>
        </Stack>
        <Typography variant="labelMedium12">{data['Opened sent emails'].value}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Stack
            sx={{
              width: '12px',
              height: '12px',
              borderRadius: '2px',
              backgroundColor: data['Not opened sent emails'].color,
            }}
          />
          <Typography variant="p12">Not opened sent emails</Typography>
        </Stack>
        <Typography variant="labelMedium12">{data['Not opened sent emails'].value}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Stack
            sx={{
              width: '12px',
              height: '12px',
              borderRadius: '2px',
              backgroundColor: data['Click through rate'].color,
            }}
          />
          <Typography variant="p12">Click through rate</Typography>
        </Stack>
        <Typography variant="labelMedium12">{data['Click through rate'].value}%</Typography>
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="p12">Total Sent Emails</Typography>
        <Typography variant="labelMedium12">
          {[data['Opened sent emails'].value, data['Not opened sent emails'].value].reduce((acc, val) => acc + val, 0)}
        </Typography>
      </Stack>

      <Divider />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Stack
            sx={{
              borderRadius: '2px',
              width: '12px',
              height: '12px',
              backgroundColor: data['Received emails'].color,
            }}
          />
          <Typography variant="p12">Received emails</Typography>
        </Stack>
        <Typography variant="labelMedium12">{Math.abs(data['Received emails'].value)}</Typography>
      </Stack>
    </Stack>
  );
};

export default CustomTooltip;
