import { List } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback } from 'react';
import { useRuleLibrary } from 'store/ruleLibrary/hook';
import { FilterOptionView } from './FilterOptionsView';

const TemplateFilterList: FC = () => {
  const {
    subPages: {
      template: {
        data: {
          filters: { department: departmentFilter, type: typeFilter, table: tableFilter },
        },
      },
    },
    setTemplateDepartmentFilterAllowedValues,
    setTemplateTypeFilterAllowedValues,
    setTemplateTableFilterAllowedValues,
  } = useRuleLibrary();

  const handleDepartmentCheckboxClicked = useCallback(
    (event: any) => {
      const value: string = event.target.value;
      const removeAction = departmentFilter.allowedValues.includes(value);
      let newAllowedValues = _.cloneDeep(departmentFilter.allowedValues);
      if (removeAction) {
        newAllowedValues = departmentFilter.allowedValues.filter((allowedValue) => allowedValue !== value);
      } else {
        newAllowedValues.push(value);
      }
      setTemplateDepartmentFilterAllowedValues(newAllowedValues);
    },
    [departmentFilter.allowedValues, setTemplateDepartmentFilterAllowedValues],
  );

  const handleTypeCheckboxClicked = useCallback(
    (event: any) => {
      const value: string = event.target.value;
      const removeAction = typeFilter.allowedValues.includes(value);
      let newAllowedValues = _.cloneDeep(typeFilter.allowedValues);
      if (removeAction) {
        newAllowedValues = typeFilter.allowedValues.filter((allowedValue) => allowedValue !== value);
      } else {
        newAllowedValues.push(value);
      }
      setTemplateTypeFilterAllowedValues(newAllowedValues);
    },
    [setTemplateTypeFilterAllowedValues, typeFilter.allowedValues],
  );

  const handleTableCheckboxClicked = useCallback(
    (event: any) => {
      const value: string = event.target.value;
      const removeAction = tableFilter.allowedValues.includes(value);
      let newAllowedValues = _.cloneDeep(tableFilter.allowedValues);
      if (removeAction) {
        newAllowedValues = tableFilter.allowedValues.filter((allowedValue) => allowedValue !== value);
      } else {
        newAllowedValues.push(value);
      }
      setTemplateTableFilterAllowedValues(newAllowedValues);
    },
    [setTemplateTableFilterAllowedValues, tableFilter.allowedValues],
  );

  const activateAllOptionsOnTableFilter = useCallback(
    (activated: boolean) => {
      if (activated) {
        setTemplateTableFilterAllowedValues([]);
      } else {
        setTemplateTableFilterAllowedValues(tableFilter.options.map((option) => option.value));
      }
    },
    [setTemplateTableFilterAllowedValues, tableFilter.options],
  );

  return (
    <List>
      <FilterOptionView
        filterLabel={departmentFilter.label}
        filterOptions={departmentFilter.options}
        filterAllowedValues={departmentFilter.allowedValues}
        onChangeCallBack={handleDepartmentCheckboxClicked}
      />

      <FilterOptionView
        filterLabel={typeFilter.label}
        filterOptions={typeFilter.options}
        filterAllowedValues={typeFilter.allowedValues}
        onChangeCallBack={handleTypeCheckboxClicked}
      />

      <FilterOptionView
        filterLabel={tableFilter.label}
        filterOptions={tableFilter.options}
        filterAllowedValues={tableFilter.allowedValues}
        onChangeCallBack={handleTableCheckboxClicked}
        enableAllOption
        limitOptions={4}
        activateAllValues={activateAllOptionsOnTableFilter}
      />
    </List>
  );
};

export { TemplateFilterList };
