import backendApi from 'store/api/backendApi';
import { DataRaptorRule } from './types';

interface getMigrationByTableParams {
  migrationId: string;
  table: string;
}

export const migrationApi = backendApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getRulesByTable: builder.query<DataRaptorRule[], getMigrationByTableParams>({
        query: ({ migrationId, table }) => ({
          method: 'get',
          url: `/dataRaptorRule/migration/${migrationId}/table/${table}`,
        }),
      }),
    };
  },
});

export const { usePrefetch, useGetRulesByTableQuery, useLazyGetRulesByTableQuery } = migrationApi;
