import {
  DeduplicationResult,
  DeduplicationResultByIdsRequest,
  DeduplicationResultStatus,
  GetDeduplicationResultByMigrationOptions,
} from 'store/deduplicationResult/types';
import { apiCall } from '../index';

export const getDeduplicationResultByIds = (
  migrationId: string,
  data: DeduplicationResultByIdsRequest,
): Promise<DeduplicationResult[]> =>
  apiCall({ method: 'post', url: `/deduplication-result/migration/${migrationId}/resultByIds`, data });

export const getDeduplicationResultByMigration = (
  migrationId: string,
  options: GetDeduplicationResultByMigrationOptions,
): Promise<DeduplicationResult[]> => {
  options.limit = options.limit || 100;
  return apiCall({ method: 'get', url: `/deduplication-result/migration/${migrationId}/result`, params: options });
};

interface UpdateDeduplicationResultStatusRequest {
  status: DeduplicationResultStatus;
  resultIds: string[];
}

export const updateDeduplicationStatus = (
  migrationId: string,
  data: UpdateDeduplicationResultStatusRequest,
): Promise<DeduplicationResult[]> => {
  return apiCall({
    method: 'put',
    url: `/deduplication-result/migration/${migrationId}/updateStatus`,
    data,
  });
};

interface MergeRecordRequest {
  masterRecordId: string;
  duplicateRecordIds: string[];
  objectType: string;
  overWriteValues: any;
}

export const mergeRecords = (migrationId: string, data: MergeRecordRequest) => {
  return apiCall({
    method: 'put',
    url: `/deduplication-result/migration/${migrationId}/mergeRecords`,
    data,
  });
};
