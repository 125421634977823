import { ChatMessage, RequestCompletionBody, ruleAssistantChat } from 'http/ai';
import { useCallback, useEffect, useState } from 'react';
import { useRuleFormStore } from 'store/ruleForm/hook';
import { useRuleFormModalHook } from '../../RuleFormModal.hook';

export const useAIAssistant = (params: { initialAIMessage: string; migrationId: string }) => {
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([{ type: 'AI', text: params.initialAIMessage }]);
  const [abortController, setAbortController] = useState(new AbortController());
  const ruleForm = useRuleFormStore();
  const {
    data: { selectedTable },
  } = useRuleFormModalHook();
  const [thinking, setThinking] = useState(false);
  const {
    setAiAssistantSuggestion,
    aiAssistant: { suggestions },
  } = useRuleFormStore();

  const signalAbort = useCallback(() => {
    abortController.abort();
    setAbortController(new AbortController());
  }, [abortController]);

  const setInitialChatHistory = useCallback(() => {
    setChatHistory([{ type: 'AI', text: params.initialAIMessage }]);
  }, [params.initialAIMessage]);

  const cleanChatHistory = useCallback(() => {
    setInitialChatHistory();
  }, [setInitialChatHistory]);

  const [prevSuggestedRuleName, setPrevSuggestedRuleName] = useState<string | null>(null);
  const [prevSuggestedDescription, setPrevSuggestedDescription] = useState<string | null>(null);
  const [prevSuggestedDepartment, setPrevSuggestedDepartment] = useState<string | null>(null);
  const [prevSuggestedRiskLevel, setPrevSuggestedRiskLevel] = useState<string | null>(null);
  const [prevSuggestedType, setPrevSuggestedType] = useState<string | null>(null);
  const [prevSuggestedViolationScore, setPrevSuggestedViolationScore] = useState<number | null>(null);

  useEffect(() => {
    if (suggestions?.ruleName && suggestions.ruleName !== prevSuggestedRuleName) {
      setPrevSuggestedRuleName(suggestions.ruleName);
      ruleForm.setRuleName(suggestions.ruleName);
    }
    if (suggestions?.description && suggestions.description !== prevSuggestedDescription) {
      setPrevSuggestedDescription(suggestions.description);
      ruleForm.setDescription(suggestions.description);
    }
    if (suggestions?.department && suggestions.department !== prevSuggestedDepartment) {
      setPrevSuggestedDepartment(suggestions.department);
      ruleForm.setDepartment(suggestions.department);
    }
    if (suggestions?.riskLevel && suggestions.riskLevel !== prevSuggestedRiskLevel) {
      setPrevSuggestedRiskLevel(suggestions.riskLevel);
      ruleForm.setRiskLevel(suggestions.riskLevel);
    }
    if (suggestions?.ruleType && suggestions.ruleType !== prevSuggestedType) {
      setPrevSuggestedType(suggestions.ruleType);
      ruleForm.setCategory(suggestions.ruleType);
    }
    if (suggestions?.violationScore && suggestions.violationScore !== prevSuggestedViolationScore) {
      setPrevSuggestedViolationScore(suggestions.violationScore);
      ruleForm.setViolationScore(suggestions.violationScore);
    }
  }, [
    suggestions,
    prevSuggestedRuleName,
    prevSuggestedDescription,
    prevSuggestedDepartment,
    prevSuggestedRiskLevel,
    prevSuggestedType,
    ruleForm,
    prevSuggestedViolationScore,
  ]);

  const requestCompletion = useCallback(
    (userInput: string) => {
      if (!params.migrationId || !userInput) return;
      setThinking(true);
      const body: RequestCompletionBody = {
        userInput,
        chatHistory,
        suggestions: suggestions || {},
        rule: {
          table: selectedTable,
          ruleName: ruleForm.ruleName,
          description: ruleForm.description,
          department: ruleForm.department,
          riskLevel: ruleForm.riskLevel,
          ruleType: ruleForm.category,
          violationScore: ruleForm.violationScore,
        },
      };
      const currentChatState = chatHistory;
      setChatHistory((prev) => [...prev, { type: 'human', text: userInput }]);
      ruleAssistantChat({ migrationId: params.migrationId, body, abortController: abortController })
        .then((response) => {
          setChatHistory(response.chat);
          setAiAssistantSuggestion(response.suggestions);
        })
        .catch((err) => {
          console.error('useAiAssistant:', err);
          setChatHistory(currentChatState);
        })
        .finally(() => {
          setThinking(false);
        });
    },
    [
      params.migrationId,
      chatHistory,
      suggestions,
      selectedTable,
      ruleForm.ruleName,
      ruleForm.description,
      ruleForm.department,
      ruleForm.riskLevel,
      ruleForm.category,
      ruleForm.violationScore,
      abortController,
      setAiAssistantSuggestion,
    ],
  );

  return {
    data: { chatHistory, thinking },
    actions: { setInitialChatHistory, cleanChatHistory, requestCompletion, signalAbort },
  };
};
