import { FC, useCallback, useEffect, useState } from 'react';

import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { Grid, TablePagination, Typography } from '@mui/material';

import theme from 'core/theme';
import { getHumanReadableDate } from 'core/utils/time';

import { DataRaptorRule } from 'store/dataRaptorRule/types';
import { LibrarySubPageData, SubPageState } from 'store/ruleLibrary/types';
import { ColumnSortedAscendingIcon, ColumnSortedDescendingIcon, ColumnUnsortedIcon } from 'pages/DataRaptor/ui';

import { getRuleStatusLabel } from './RuleCard';
import { RuleMenu } from './RuleMenu';

const getDataRaptorColumns: (width: number) => GridColumns<DataRaptorRule> = (windowWidth) => [
  {
    field: 'name',
    width: windowWidth * 0.3,
  },
  {
    field: 'table',
    width: windowWidth * 0.1,
  },
  {
    field: 'status',
    width: windowWidth * 0.1,
    renderCell: ({ row }) => getRuleStatusLabel(row.status, row.active),
  },
  {
    field: 'department',
    width: windowWidth * 0.1,
    renderCell: (args) => {
      const rule = args.row;
      return (
        <Typography
          variant="p12"
          sx={{
            px: 1.2,
            py: 0.5,
            borderRadius: '4px',
            backgroundColor: rule.DepartmentObject?.color ?? theme.palette.neutral.n400,
          }}
        >
          {rule.DepartmentObject?.label}
        </Typography>
      );
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    width: windowWidth * 0.15,
    valueFormatter: (args) => (args.value ? getHumanReadableDate(args.value) : ''),
  },
  {
    field: 'updatedAt',
    width: windowWidth * 0.15,
    headerName: 'Last Updated',
    valueFormatter: (args) => (args.value ? getHumanReadableDate(args.value) : ''),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: windowWidth * 0.1,
    renderCell: (args) => <RuleMenu rule={args.row} />,
  },
];

interface RuleLibraryTableProps {
  library: SubPageState<LibrarySubPageData>;
}

export interface PaginationData {
  page: number;
  perPage: number;
}

export const RuleLibraryTable: FC<RuleLibraryTableProps> = ({ library }) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(7);
  const [columns, setColumns] = useState(getDataRaptorColumns(window.innerWidth));

  const getPaginationDataRows = useCallback((): DataRaptorRule[] => {
    const startIndex = page * limit;
    return library.data.renderedRules.slice(startIndex, startIndex + limit);
  }, [page, limit, library.data.renderedRules]);

  useEffect(() => {
    setColumns(getDataRaptorColumns(window.innerWidth));
  }, []);

  return (
    <Grid
      item
      flex={1}
      display={'flex'}
      sx={{
        width: '100%',
        backgroundColor: 'white',
      }}
    >
      <DataGrid
        pageSize={limit}
        columns={columns}
        headerHeight={40}
        density="comfortable"
        disableSelectionOnClick
        getRowId={(item) => item.ruleId}
        rows={getPaginationDataRows()}
        sx={{ display: 'flex', flexGrow: 1 }}
        components={{
          ColumnUnsortedIcon,
          ColumnSortedAscendingIcon,
          ColumnSortedDescendingIcon,
          Footer: (props) => (
            <TablePagination
              {...props}
              page={page}
              component="div"
              rowsPerPage={limit}
              labelRowsPerPage="per page"
              onRowsPerPageChange={(e) => {
                const newLimit = Number.parseInt(e.target.value);
                setLimit(newLimit);
              }}
              rowsPerPageOptions={[7, 15, 30]}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
              onPageChange={(_, page) => setPage(page)}
              count={library.data.renderedRules.length ?? 0}
            />
          ),
        }}
      />
    </Grid>
  );
};
