import * as React from 'react';
import { Card, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { useState } from 'react';
import { upperCase } from 'lodash';
import ReviewDataCard from './ReviewDataCard';

type ExpandableSectionProps = {
  title: string;
  fields: { name: string; value: number; icon?: React.ReactNode }[];
};

const ExpandableSection: React.FC<ExpandableSectionProps> = ({ title, fields }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <>
      <Card
        sx={{
          width: '100%',
          backgroundColor: 'lightBg.main',
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        elevation={0}
      >
        <Typography variant="p12" color={'blue.main'} p={1}>
          {upperCase(title)}
        </Typography>
        <IconButton onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Card>

      <Collapse in={isExpanded} sx={{ width: '100%' }}>
        <Grid container direction={'row'} justifyContent={'space-between'}>
          {fields.map((field) => (
            <Grid key={field.name} item xs={4}>
              <ReviewDataCard title={field.name} value={field.value} icon={field.icon} />
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </>
  );
};

export default ExpandableSection;
