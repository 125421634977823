import { FC, useCallback, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import Header from './components/Header/Header';
import { useNavigate, useLocation } from 'react-router-dom';
import RuleType from './components/RuleType/RuleType';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import DuplicateDetectionRuleDefinition from './components/DuplicateDetection/DuplicateDetection';
import { useMigration } from 'store/migration/hooks';
import { RuleCategory } from 'store/dataRaptorRule/types';
import { DataValidationRuleDefinition } from './components/DataValidation';
import { AnomalyDetectionRuleDefinition } from './components/AnomalyDetection';
import { RuleFormMode } from '../RuleLibrary/types';
interface RuleFormProps {
  [key: string]: any;
}

const RuleForm: FC<RuleFormProps> = (props) => {
  const {
    getMigrationTableFieldsAndLookUpsById,
    getMigrationTableForeignReferencesById,
    getMigrationTablesById,
    getMigrationTableFieldsById,
  } = useMigration();
  const {
    category,
    resetAll,
    getRuleDepartments,
    getRuleRisks,
    getRuleValidationPatterns,
    getRuleTypes,
    updateDataFromRule,
    updateDataFromRuleTemplate,
    setFormMode,
    setSelectedRuleId,
    ruleTypes,
    ruleRisks,
    ruleDepartments,
    ruleValidationPatterns,
    selectedMigration,
    selectedTable,
    getRuleByMigrationAndRuleIdState,
  } = useRuleForm();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const handleBack = useCallback(() => {
    navigate(-1);
    resetAll();
  }, [navigate, resetAll]);

  useEffect(() => {
    if (selectedMigration && selectedTable) {
      getMigrationTablesById(selectedMigration);
      getMigrationTableFieldsById({ migrationId: selectedMigration, tableId: selectedTable });
      getMigrationTableFieldsAndLookUpsById({ migrationId: selectedMigration, tableId: selectedTable });
      getMigrationTableForeignReferencesById({ migrationId: selectedMigration, tableId: selectedTable });
    }
  }, [
    getMigrationTableFieldsAndLookUpsById,
    getMigrationTableFieldsById,
    getMigrationTableForeignReferencesById,
    getMigrationTablesById,
    selectedMigration,
    selectedTable,
  ]);

  const checkPageRequirements = useCallback(() => {
    const mode = searchParams.get('mode') || RuleFormMode.CREATE;

    if (!(mode === RuleFormMode.CREATE || mode === RuleFormMode.EDIT || mode === RuleFormMode.CREATE_FROM_TEMPLATE)) {
      navigate(`/d/data-raptor?migration=${selectedMigration}&table=${selectedTable}`);
    }
    if (!selectedMigration || !selectedTable) {
      navigate(`/d/data-raptor?migration=${selectedMigration}&table=${selectedTable}`);
    }
    setFormMode(mode);
  }, [navigate, searchParams, selectedMigration, selectedTable, setFormMode]);

  //Load Rule mode Edit
  useEffect(() => {
    checkPageRequirements();
    const mode = searchParams.get('mode') || RuleFormMode.CREATE;
    const ruleId = searchParams.get('rule');
    if (mode === RuleFormMode.EDIT) {
      if (!ruleId) {
        return navigate(`/d/data-raptor?migration=${selectedMigration}&table=${selectedTable}`);
      }
      if (getRuleByMigrationAndRuleIdState.loading !== true) {
        setSelectedRuleId(ruleId);
        updateDataFromRule(selectedMigration, ruleId, (error) => {
          navigate(`/d/data-raptor?migration=${selectedMigration}&table=${selectedTable}`);
        });
      }
    } else if (mode === RuleFormMode.CREATE_FROM_TEMPLATE) {
      updateDataFromRuleTemplate();
    }
  }, []);

  useEffect(() => {
    checkPageRequirements();
  }, [checkPageRequirements]);

  useEffect(() => {
    if (!ruleDepartments || (ruleDepartments && ruleDepartments.length === 0)) {
      getRuleDepartments();
    }
  }, [getRuleDepartments, ruleDepartments]);

  useEffect(() => {
    if (!ruleRisks || (ruleRisks && ruleRisks.length === 0)) {
      getRuleRisks();
    }
  }, [getRuleRisks, ruleRisks]);

  useEffect(() => {
    if (!ruleTypes || (ruleTypes && ruleTypes.length === 0)) {
      getRuleTypes();
    }
  }, [getRuleTypes, ruleTypes]);

  useEffect(() => {
    if (!ruleValidationPatterns || (ruleValidationPatterns && ruleValidationPatterns.length === 0)) {
      getRuleValidationPatterns();
    }
  }, [getRuleValidationPatterns, ruleValidationPatterns]);

  return (
    <Box {...props}>
      <Header handleBack={handleBack}></Header>
      <RuleType></RuleType>

      {category === RuleCategory.DataValidation && <DataValidationRuleDefinition></DataValidationRuleDefinition>}
      {category === RuleCategory.AnomalyDetection && <AnomalyDetectionRuleDefinition></AnomalyDetectionRuleDefinition>}
      {category === RuleCategory.DuplicateDetection && (
        <DuplicateDetectionRuleDefinition></DuplicateDetectionRuleDefinition>
      )}
    </Box>
  );
};

export default RuleForm;
