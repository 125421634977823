import { FC } from 'react';
import { ModalTemplate } from 'components/ModalTemplate';
import { Grid, Typography } from '@mui/material';
import { LinearProgressWithLabel } from 'components/LinearProgressWithLabel';
import { MigrationStatus } from 'store/graphData/types';

export interface NeptuneMigrationModalStatusProps {
  open: boolean;
  status: MigrationStatus;
}

export const NeptuneMigrationModalStatus: FC<NeptuneMigrationModalStatusProps> = (props) => {
  const { open, status } = props;

  return (
    <ModalTemplate
      open={open}
      toggleOpen={() => undefined}
      title={`Loading...`}
      showChevronDown={false}
      showCloseButton={false}
    >
      <Grid container display={'flex'} flexDirection={'column'} spacing={3} py={5} px={5}>
        <Grid item sx={{ textAlign: 'center' }}>
          <Typography variant="h3">
            <strong>{'Weaving your graph view... Just a moment!'}</strong>
          </Typography>
        </Grid>
        <Grid item>
          <LinearProgressWithLabel value={status.progress || 0} />
        </Grid>
      </Grid>
    </ModalTemplate>
  );
};

export default NeptuneMigrationModalStatus;
