import { useState, useEffect, createRef, useCallback, useMemo } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { ReactComponent as AISuggestionIcon } from 'assets/icons/AIChat/aiSuggestion.svg';
import ToolTipTexts from '../TooltipTexts';

import { Box, Link, Slider, Tooltip, Container, FormHelperText, Typography, InputAdornment } from '@mui/material';

import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro';

import { useRuleFormModalHook } from '../../../../RuleFormModal.hook';
import { RuleStatus, RuleStatusIcon } from '../RuleFormRuleDetailsIcons';

import { useMigration } from 'store/migration/hooks';

import { DataRaptorRule } from 'store/dataRaptorRule/types';
import { useGetRulesByMigrationAndTableNameQuery } from 'store/ruleForm/api';

import { GridItem } from 'components/ui';
import { getHumanReadableDate } from 'core/utils/time';

import { Show } from 'pages/RuleLibrary/components/show';
import { ColumnSortedAscendingIcon, ColumnSortedDescendingIcon, ColumnUnsortedIcon } from 'pages/DataRaptor/ui';
import { LabelComponent } from '../../../../../ui/LabelComponent';
import TableMenuButton from './TableMenuButton';
import { useDataRaptorRule } from 'store/dataRaptorRule/hooks';
import { RuleFormMode } from 'pages/RuleLibrary/types';

const getRuleTableColumns: (width: number) => GridColumns<DataRaptorRule> = (width) => [
  {
    field: 'status',
    width: width * 0.4,
    headerName: 'status and name',
    renderCell: ({ row }) => {
      const status = row.status?.toLowerCase() as RuleStatus;

      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <RuleStatusIcon status={status} active={row.active} />
          <Tooltip title={row.name}>
            <div>{row.name}</div>
          </Tooltip>
        </Box>
      );
    },
  },
  {
    headerName: 'score',
    field: 'violationScore',
    width: width * 0.1,
    valueFormatter: (args) => parseInt(args.value),
  },
  {
    width: width * 0.2,
    field: 'createdAt',
    headerName: 'created',
    valueFormatter: (args) => (args.value ? getHumanReadableDate(args.value) : ''),
  },
  {
    width: width * 0.2,
    field: 'updatedAt',
    headerName: 'last updated',
    valueFormatter: (args) => (args.value ? getHumanReadableDate(args.value) : ''),
  },
  {
    width: width * 0.1,
    headerName: '',
    sortable: false,
    field: 'actions',
    filterable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => <TableMenuButton rule={row} />,
  },
];

export type RuleFormDetailsRelatedTablesProps = {
  value: number;
  error?: FieldError;
  setValue: (name: string, value: number) => void;
  usingSuggestedAIValue?: boolean;
} & Partial<UseFormRegisterReturn>;

const defaultLimit = 4;
const limitViolationScore = 100;
export const RuleFormDetailsRelatedTables = ({
  error,
  value,
  setValue,
  usingSuggestedAIValue,
}: RuleFormDetailsRelatedTablesProps) => {
  const [open, setOpen] = useState(true);
  const {
    data: { selectedRuleId, formMode },
  } = useDataRaptorRule();
  const ref = createRef<HTMLDivElement>();
  const [columns, setColumns] = useState(getRuleTableColumns(0));

  const {
    data: { migrationId },
  } = useMigration();

  const {
    data: { selectedTable: tableName },
  } = useRuleFormModalHook();

  const rulesQuery = useGetRulesByMigrationAndTableNameQuery(
    {
      tableName,
      migrationId,
    },
    { pollingInterval: 10000, refetchOnMountOrArgChange: true },
  );

  const handleResize = useCallback(() => {
    const width = ref.current?.clientWidth ?? 715;
    setColumns(getRuleTableColumns(width));
  }, [ref]);

  useEffect(() => {
    handleResize();
    ref.current?.addEventListener('resize', handleResize);
    return () => ref.current?.removeEventListener('resize', handleResize);
  }, [handleResize, ref]);

  const totalScore = useMemo(() => {
    return (
      rulesQuery.data?.reduce((prev, curr) => {
        if (curr.ruleId === selectedRuleId && formMode === RuleFormMode.EDIT) {
          return prev;
        }
        return prev + curr.violationScore;
      }, 0) ?? 100
    );
  }, [formMode, rulesQuery.data, selectedRuleId]);

  const handleClick = () => setOpen(!open);
  const rulesCount = useMemo(() => rulesQuery.data?.length ?? 0, [rulesQuery.data?.length]);
  const limitSliderScore = limitViolationScore - totalScore;

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box display={'flex'} alignItems={'center'}>
        <GridItem
          item
          xs={10}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box display={'flex'}>
            <LabelComponent label={'Violation Score'} toolTipText={ToolTipTexts.violationScore} />
            <Show when={!!error}>
              <FormHelperText sx={{ color: 'red.main', pl: 1, m: 0 }}>{error?.message}</FormHelperText>
            </Show>
          </Box>
          <Container>
            <Slider
              min={0}
              step={1}
              size="small"
              defaultValue={0}
              value={value ?? 0}
              max={limitSliderScore}
              valueLabelDisplay="auto"
              color={!error ? 'primary' : 'secondary'}
              onChange={(_, value) => setValue('violationScore', value as number)}
              disabled={rulesQuery.isError || rulesQuery.isLoading || limitSliderScore === 0}
              marks={[
                { value: 0, label: 0 },
                { value: limitSliderScore, label: limitSliderScore },
              ]}
            />
          </Container>
        </GridItem>
        <GridItem item xs={2} sx={{ display: 'flex' }}>
          <Box sx={{ backgroundColor: 'lightBg.main', width: 'fit-content', px: 2, py: 1, borderRadius: '4px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {usingSuggestedAIValue && (
                <InputAdornment position="start">
                  <AISuggestionIcon />
                </InputAdornment>
              )}
              <Typography variant="labelMedium14">{value || 0}</Typography>
            </Box>
          </Box>
        </GridItem>
      </Box>
      <GridItem item>
        <FormHelperText
          sx={{
            textAlign: 'justify',
            color: limitSliderScore === 0 && !rulesQuery.isLoading ? 'red.main' : null,
          }}
        >
          <Show
            when={rulesCount > 0}
            fallback={
              <>
                There are not <strong>rules</strong> created for <strong>{tableName} table</strong>
                <br />
              </>
            }
          >
            There are <strong>{rulesCount} rules</strong> created for <strong>{tableName} table</strong>
            {' with a '}
            <strong>total score of {totalScore}</strong>. If you want to add a higher score for this rule, you can
            choose to edit and lower other scores.
            <br />
            <Link onClick={handleClick} sx={{ cursor: 'pointer' }}>
              {!open ? 'View' : 'Hide'} Rules
            </Link>
          </Show>
        </FormHelperText>
        <Show when={open && rulesCount > 0}>
          <Box
            ref={ref}
            id="rule-table-container"
            sx={{
              p: 0,
              height: 292,
              width: '100%',
            }}
          >
            <DataGridPro
              hideFooterSelectedRowCount
              hideFooter={rulesCount < defaultLimit}
              rowHeight={30}
              headerHeight={30}
              density="comfortable"
              columns={columns}
              sx={{ fontSize: 12 }}
              disableSelectionOnClick
              rowCount={rulesCount}
              pageSize={defaultLimit}
              error={rulesQuery.error}
              rows={rulesQuery.data ?? []}
              loading={rulesQuery.isLoading}
              getRowId={(item) => item.ruleId}
              components={{
                ColumnUnsortedIcon,
                ColumnSortedAscendingIcon,
                ColumnSortedDescendingIcon,
              }}
            />
          </Box>
        </Show>
      </GridItem>
    </Box>
  );
};
