import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { getConfidenceScoreColor, getConfidenceScoreStatus } from 'core/utils';
import theme from 'core/theme';

interface ConfidenceScoreProps {
  confidenceScore: number;
}

export const ConfidenceScore: FC<ConfidenceScoreProps> = (props) => {
  const { confidenceScore } = props;
  const confidenceScoreColor = getConfidenceScoreColor(confidenceScore);
  const confidenceScoreStatus = getConfidenceScoreStatus(confidenceScore);

  return (
    <Box
      position={'relative'}
      sx={{
        width: '6.5rem',
        borderRadius: '5px',
        height: '30px',
        backgroundColor: 'lightBg.main',
        textAlign: 'center',
        border: '1px solid',
        borderColor: 'neutral.lightGray',
        color: theme.palette.blue.dark,
      }}
    >
      <Box
        position={'absolute'}
        sx={{
          width: `${confidenceScore}%`,
          borderRadius: '5px',
          backgroundColor: confidenceScoreColor,
          height: '100%',
          border: '1px solid',
          borderColor: confidenceScoreColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      ></Box>
      <Typography position={'relative'} variant="labelMedium14">
        {`${confidenceScoreStatus} ${confidenceScore}%`}
      </Typography>
    </Box>
  );
};

export default ConfidenceScore;
