import { FC, useEffect, useState, useCallback } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import theme from 'core/theme';
import { stringToDate } from 'core/utils';
import { DataRaptorRule } from 'store/dataRaptorRule/types';
import { RuleMenu } from './RuleMenu';

export const getRuleStatusLabel = (status: string, active: boolean) => {
  const commonSX = {
    width: 'fit-content',
    fontWeight: 'bold',
    px: 2,
    py: 0.5,
    borderRadius: '100px',
  };
  let color = '';
  let backgroundColor = '';
  let text = '';
  if (status === 'completed' && active) {
    color = 'neutral.white';
    backgroundColor = 'green.main';
    text = 'Active';
  } else if (status === 'completed' && !active) {
    color = 'neutral.main';
    backgroundColor = 'neutral.n200';
    text = 'Inactive';
  } else if (status === 'processing' || status == 'requested') {
    color = 'neutral.white';
    backgroundColor = 'orange.main';
    text = 'Processing';
  } else {
    color = 'neutral.white';
    backgroundColor = 'red.main';
    text = 'Failed';
  }
  return (
    <Typography
      variant="p12"
      sx={{
        color,
        backgroundColor,
        ...commonSX,
      }}
    >
      {text}
    </Typography>
  );
};

export interface RuleCardProps {
  rule: DataRaptorRule;
}

const CHAR_LIMIT_ON_DESCRIPTION = 75;

const RuleCard: FC<RuleCardProps> = (props) => {
  const { rule } = props;
  const [descriptionRendered, setDescriptionRendered] = useState<string>('');
  const [hideDescription, setHideDescription] = useState<boolean>(true);

  useEffect(() => {
    setDescriptionRendered(rule.description.substring(0, CHAR_LIMIT_ON_DESCRIPTION));
  }, [rule.description]);

  const handleSeeMoreOrLessDescription = useCallback(() => {
    if (hideDescription) {
      setDescriptionRendered(rule.description);
    } else {
      setDescriptionRendered(rule.description.substring(0, CHAR_LIMIT_ON_DESCRIPTION));
    }
    setHideDescription(!hideDescription);
  }, [hideDescription, rule.description]);

  const shouldShowReadMoreOrLess = rule.description.length > CHAR_LIMIT_ON_DESCRIPTION;

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'white',
          width: '100%',
          display: 'flex',
          borderRadius: '4px',
          alignItems: 'center',
          p: '8px 8px 8px 12px',
          justifyContent: 'space-between',
        }}
      >
        <Grid container display={'flex'} flexDirection={'column'}>
          <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Grid item xs={6}>
              {getRuleStatusLabel(rule.status, rule.active)}
            </Grid>
            <Grid item xs={6}>
              <RuleMenu rule={rule} />
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }}></Divider>
          <Grid container display={'flex'} flexDirection={'column'}>
            <Grid item>
              <Typography variant="labelMedium14" sx={{ fontWeight: 'bold' }}>
                {rule.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="p12" sx={{ color: theme.palette.neutral.n400 }}>
                {descriptionRendered + ' '}
              </Typography>
              {shouldShowReadMoreOrLess && (
                <Typography
                  display={'inline'}
                  variant="labelRegular12"
                  sx={{ color: theme.palette.primary.main, textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={handleSeeMoreOrLessDescription}
                >
                  {hideDescription ? 'See more' : 'See less'}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }}></Divider>
          <Grid container display={'flex'} flexDirection={'column'}>
            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant="p12" sx={{ color: theme.palette.neutral.n400 }}>
                  Department
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="p12"
                  sx={{
                    backgroundColor: rule.DepartmentObject?.color || theme.palette.neutral.n400,
                    px: 1.2,
                    py: 0.5,
                    borderRadius: '4px',
                  }}
                >
                  {rule.DepartmentObject?.label}
                </Typography>
              </Grid>
            </Grid>
            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant="p12" sx={{ color: theme.palette.neutral.n400 }}>
                  Table:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="p12">{rule.table}</Typography>
              </Grid>
            </Grid>
            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant="p12" sx={{ color: theme.palette.neutral.n400 }}>
                  Type:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="p12">{rule.TypeObject?.label}</Typography>
              </Grid>
            </Grid>
            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant="p12" sx={{ color: theme.palette.neutral.n400 }}>
                  Upvote:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="p12">{'Missing info'}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }}></Divider>
          <Grid container display={'flex'} flexDirection={'column'}>
            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant="p12" sx={{ color: theme.palette.neutral.n400 }}>
                  Created:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="p12">{stringToDate(rule.createdAt || '')}</Typography>
              </Grid>
            </Grid>
            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant="p12" sx={{ color: theme.palette.neutral.n400 }}>
                  Last updated:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="p12">{stringToDate(rule.updatedAt || '')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export { RuleCard };
