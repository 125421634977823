import { Box, Button } from '@mui/material';
import { SecondaryButton } from 'components/ui';
import { FC } from 'react';
import { useRuleFormModalHook } from '../../../RuleFormModal.hook';

export interface RuleFormRecordTypeFooterProps {
  handleClose: () => void;
  handleNext: () => void;
}

export const RuleFormRecordTypeFooter: FC<RuleFormRecordTypeFooterProps> = (props) => {
  const { handleClose, handleNext } = props;

  const {
    data: { selectedTable },
  } = useRuleFormModalHook();

  const isTableSelected = selectedTable && selectedTable.trim().length > 0;

  return (
    <Box
      display={'flex'}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <SecondaryButton variant="text" sx={{ color: 'black', ':hover': { color: 'black' } }} onClick={handleClose}>
        Cancel
      </SecondaryButton>
      <Button variant="contained" onClick={handleNext} disabled={!isTableSelected}>
        Next
      </Button>
    </Box>
  );
};
