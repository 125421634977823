import { FC } from 'react';
import {
  Drawer,
  ClickAwayListener,
  Box,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Divider,
  Chip,
  Button,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { useRuleLibrary } from 'store/ruleLibrary/hook';

import { whereConditionComponent } from 'pages/RuleLibrary/components/TemporaryTableList/components/WhereCondition';

interface AISuggestedDrawerProps {
  open: boolean;
  drawerData: {
    name: string;
    category: string;
    section: string;
    impact: number;
  };
  toggleOpen: () => void;
}

function getColor(minValue: number, mediunValue: number, maxValue: number, value: number) {
  if (minValue <= value && mediunValue > value) {
    return '#FB4E6D';
  } else if (mediunValue <= value && maxValue > value) {
    return '#FF9960';
  } else if (maxValue <= value) {
    return '#60C67C';
  }

  return 'white';
}

const AISuggestedDrawer: FC<AISuggestedDrawerProps> = ({ open, drawerData, toggleOpen }) => {
  const {
    subPages: { temporalTable },
  } = useRuleLibrary();
  const table = temporalTable?.data?.renderedTables?.[0];

  const toggleDrawer = () => {
    toggleOpen();
  };

  return (
    <Drawer elevation={0} anchor="right" open={open} onClose={toggleOpen}>
      <ClickAwayListener onClickAway={toggleDrawer}>
        <Box px={3} pb={3} pt={10} width={'40vw'} height={'100%'}>
          <Grid container width={'100%'} display={'flex'} flexDirection={'row'} alignItems={'stretch'}>
            <Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Typography variant="h2" fontSize={24}>
                AI Suggested - {drawerData.name}
              </Typography>
              <Tooltip arrow title="close">
                <IconButton sx={{ transform: 'scale(.9)' }} onClick={toggleDrawer}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ py: 1 }} />
            </Grid>

            <Grid container pt={2} spacing={2}>
              <Grid item xs={10}>
                <Typography fontSize={14} color={'#9DA1B5'}>
                  This rule identifies potential duplicate contacts based on matching first name, last name, and email
                  address with a 90% match threshold.
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" size="large">
                  Activate
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ py: 1 }} />
            </Grid>

            <Grid container pt={2} spacing={2}>
              <Grid item xs={4}>
                <Typography fontSize={12} color={'#9DA1B5'}>
                  Impact:
                </Typography>
                <Chip
                  label={drawerData.impact}
                  sx={{
                    color: 'white',
                    backgroundColor: getColor(-100, 0, 50, drawerData.impact),
                    width: '48px',
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography fontSize={12} color={'#9DA1B5'}>
                  Type:
                </Typography>
                <Chip
                  label={drawerData.category}
                  sx={{
                    color: 'white',
                    borderRadius: '4px',
                    backgroundColor: '#FF9960',
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography fontSize={12} color={'#9DA1B5'}>
                  Department:
                </Typography>
                <Chip
                  label={drawerData.section}
                  sx={{
                    borderRadius: '4px',
                    backgroundColor: '#DFF4E5',
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ py: 2 }} />
            </Grid>

            <Grid container pt={2}>
              <Grid item pb={2} xs={12}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Rule Details
                </Typography>
              </Grid>
              {table && table.definition ? (
                <Box bgcolor="#EDF0F5" padding={1} borderRadius={3} width={'100%'}>
                  {table.definition.where.map((condition, key) => {
                    const whereComponent = whereConditionComponent[condition.type];
                    return (
                      <Box key={key} paddingY={0.5}>
                        {whereComponent(condition, table.table)}
                      </Box>
                    );
                  })}
                </Box>
              ) : (
                <Typography color="error">No rule details available.</Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </ClickAwayListener>
    </Drawer>
  );
};

export default AISuggestedDrawer;
