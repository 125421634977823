import { ControlledCustomDropdown } from 'components/CustomDropdown';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { FC } from 'react';
import { OPERATOR_OPTIONS } from '../shared/Constants';

export interface OperatorDropdownProps {
  operator: string | null;
  targetPath: string;
}

const OperatorDropdown: FC<OperatorDropdownProps> = (props) => {
  const { operator, targetPath } = props;

  const { setFieldValue } = useRuleForm();

  return (
    <>
      <ControlledCustomDropdown<string>
        id={targetPath}
        placeholder="Operator"
        label="Operator"
        value={operator}
        options={OPERATOR_OPTIONS}
        sx={{ backgroundColor: 'neutral.white', width: '100%' }}
        onSelect={async (value) => setFieldValue({ path: targetPath, value: value })}
        fallbackOption={{ label: 'Select a Value', value: '' }}
      />
    </>
  );
};

export { OperatorDropdown };
