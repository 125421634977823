import { Box, Typography, IconButton, Button, Divider, LinearProgress } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataTable } from './DataTable';

interface FileCardProps {
  file: {
    name: string;
    size: string;
    showPreview: boolean;
    content: any[];
  };
  uploadProgress: number;
  onDelete: () => void;
  onTogglePreview: () => void;
}

export const FileCard = ({ file, uploadProgress, onDelete, onTogglePreview }: FileCardProps) => {
  const columns = ['Column 1', 'Column 2', 'Column 3'];

  return (
    <Box sx={{ width: '100%', maxWidth: '840px', mb: 2, backgroundColor: 'white', px: 4, py: 2, boxShadow: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <InsertDriveFileIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="body1">{file.name}</Typography>
          <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          <Typography variant="body2">{file.size}</Typography>
        </Box>
        <Box>
          {uploadProgress === 100 && (
            <Button
              variant="text"
              onClick={onTogglePreview}
              sx={{ height: '24px' }}
              startIcon={file.showPreview ? <VisibilityOffIcon /> : <VisibilityIcon />}
            >
              {file.showPreview ? 'Hide Preview' : 'View Preview'}
            </Button>
          )}
          <IconButton disabled={uploadProgress !== 100} onClick={onDelete} sx={{ mr: 1 }}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
      <LinearProgress
        color={uploadProgress === 100 ? 'success' : 'primary'}
        variant="determinate"
        value={uploadProgress}
        sx={{ mt: 1 }}
      />

      {file.showPreview && <DataTable columns={columns} rows={file.content} />}
    </Box>
  );
};
