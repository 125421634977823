import { useGraphSpace } from 'store/graphSpace/hooks';

export const useGraphSpaceSetUp = () => {
  const { data, addGraphSpaceSetup } = useGraphSpace();

  const {
    graphSpace,
    validations: { graphSpaceSetUp },
  } = data;

  const steps = [
    {
      label: 'Graph Space Details',
      validate: () => graphSpaceSetUp.isGraphSpaceDetailsValid,
    },
    {
      label: 'Set-up Graph Space',
      button: 'Set-up Graph Space',
      validate: () => graphSpaceSetUp.isTemplateValid && graphSpaceSetUp.isObjectStartValid,
    },
  ];

  return {
    steps,
    graphSpace,
    addGraphSpaceSetup,
  };
};
