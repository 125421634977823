import { useCallback } from 'react';
import { useGraphData } from 'store/graphData/hooks';
import { MIGRATION_STATUS_END_STATE, MIGRATION_STATUS_PROGRESS_STATE } from 'store/graphData/types';
import { useGraphRender } from 'store/graphRender/hooks';
import { useMigration } from 'store/migration/hooks';

export const useNeptuneDataHandlerUtils = () => {
  const {
    data: { migrationId },
  } = useMigration();
  const { setShowNeptuneStatusModal, setLoading, setError } = useGraphRender();
  const { getMigrationStatus: getNeptuneMigrationStatus, migrationStatus: neptuneMigrationStatus } = useGraphData();

  const getRecursiveNeptuneMigrationStatus = useCallback(() => {
    if (migrationId && neptuneMigrationStatus == null) {
      getNeptuneMigrationStatus({
        migrationId,
        onSuccess: (res) => {
          if (Object.values(MIGRATION_STATUS_END_STATE).includes(res.status as MIGRATION_STATUS_END_STATE)) {
            setShowNeptuneStatusModal(false);
          } else if (
            Object.values(MIGRATION_STATUS_PROGRESS_STATE).includes(res.status as MIGRATION_STATUS_PROGRESS_STATE)
          ) {
            setShowNeptuneStatusModal(true);
            setTimeout(() => {
              getRecursiveNeptuneMigrationStatus();
            }, 1000);
          } else {
            setShowNeptuneStatusModal(false);
            setLoading(false);
            setError(`Unknown Neptune migration status: ${res.status}`);
          }
        },
      });
    }
  }, [getNeptuneMigrationStatus, migrationId, neptuneMigrationStatus, setError, setLoading, setShowNeptuneStatusModal]);

  return {
    getRecursiveNeptuneMigrationStatus,
  };
};
