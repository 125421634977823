import { useCallback } from 'react';

import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { getDataValidationEmptyRootConditional } from 'pages/RuleForm/utils';

import { useMigration } from 'store/migration/hooks';
import { useTemporalTableForm } from 'store/temporalTableForm/hooks';

export const useTemporalTableModalHook = () => {
  const { getMigrationTableFieldsAndLookUpsById } = useMigration();

  const { subQueryForm, selectedMigration, setFieldValue, setSubQueryFormWhere, getFieldOptionsByMigrationAndTable } =
    useRuleForm();

  const { table, alias, where } = subQueryForm;
  const { setOpen, setMenuIndex } = useTemporalTableForm();

  const handleCloseModal = useCallback(() => {
    setOpen(false);
    setMenuIndex(0);

    setFieldValue({ path: `subQueryForm.alias`, value: '' });
    setFieldValue({ path: `subQueryForm.table`, value: '' });

    setFieldValue({ path: `subQueryForm.where`, value: [getDataValidationEmptyRootConditional()] });
  }, [setFieldValue, setMenuIndex, setOpen]);

  const handleUpdateTable = useCallback(
    (value: string) => {
      if (!selectedMigration) return;

      setFieldValue({ path: `subQueryForm.table`, value });
      setFieldValue({ path: `subQueryForm.where`, value: [getDataValidationEmptyRootConditional()] });

      getMigrationTableFieldsAndLookUpsById({ migrationId: selectedMigration, tableId: value });
    },
    [getMigrationTableFieldsAndLookUpsById, selectedMigration, setFieldValue],
  );

  const handleNameChange = useCallback(
    (e: any) => {
      let value = String(e.target.value);
      value = value.replace(/[^a-zA-Z0-9 ]/g, '');
      setFieldValue({
        path: `subQueryForm.alias`,
        value: value,
      });
    },
    [setFieldValue],
  );

  return {
    data: {
      table,
      alias,
      where,
      selectedMigration,
    },
    // todo: add setData
    handleCloseModal,
    // step1
    handleNameChange,
    handleUpdateTable,
    // step2
    setSubQueryFormWhere,
    getFieldOptionsByMigrationAndTable,
  };
};
