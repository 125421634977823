import { Box, Button, Grid, Typography } from '@mui/material';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { FC, useCallback, useEffect, useState } from 'react';
import { HavingRecordForm } from './HavingRecordForm';
import { useMigration } from 'store/migration/hooks';
import { ReactComponent as UpIcon } from 'assets/icons/chevronUpBlue.svg';
import { ReactComponent as DownIcon } from 'assets/icons/chevronDownBlue.svg';
import { LOOK_UP_FUNCTION_OPTIONS, OPERATOR_OPTIONS } from '../shared/Constants';
import { AddLogicalOperatorButton } from '../shared/AddLogicalOperatorButton';
import { LogicalOperatorFormRow } from '../shared/LogicalOperatorFormRow';
import { RootCondition, LogicalOperator } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { RuleComponentType, RuleComponent } from 'store/dataRaptorRule/dto/Enums';
import { VerticalDivider } from 'components/VerticalDivider';
import theme from 'core/theme';
import { motion } from 'framer-motion';
import { ConditionType } from 'pages/RuleLibrary/types';

const HavingList: FC = () => {
  const [open, setOpen] = useState(true);

  const { having, setFieldValue, selectedMigration, selectedTable, setFieldForeignRefOptions } = useRuleForm();
  const {
    data: { migrationTableForeignReferences },
  } = useMigration();
  const key = `${selectedMigration}-${selectedTable}`;

  const handleAddInitialHaving = () => {
    if (migrationTableForeignReferences[key]?.data?.length <= 0) return;
    const initialHavingClause: RootCondition = {
      type: RuleComponentType.ROOT_CONDITIONAL,
      field: {
        type: RuleComponentType.FUNCTION_VALUE,
        function: LOOK_UP_FUNCTION_OPTIONS[0].value,
        numberOfParams: LOOK_UP_FUNCTION_OPTIONS[0].paramNumber,
        value: [],
      },
      operator: OPERATOR_OPTIONS[3].value,
      value: { type: RuleComponentType.PRIMITIVE_VALUE, value: 0 },
    };
    setFieldValue({ path: `having[0]`, value: initialHavingClause });
  };

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  //Load External LookUps
  useEffect(() => {
    const key = `${selectedMigration}-${selectedTable}`;
    const fieldOptions = migrationTableForeignReferences[key]?.data?.map((foreignReference) => ({
      label: `${foreignReference.tableId}(${foreignReference.relationshipName})`,
      value: `${foreignReference.tableId}(${foreignReference.relationshipName})`,
    }));
    setFieldForeignRefOptions(fieldOptions);
  }, [migrationTableForeignReferences, selectedMigration, selectedTable, setFieldForeignRefOptions]);

  const hasHavingItems = having?.length > 0;

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'lightBg.main',
          py: 1,
          px: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: '4px',
          ':hover': {
            cursor: 'pointer',
          },
        }}
        onClick={toggleOpen}
        display={'flex'}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography color={'primary.main'} variant="labelRegular12">
            AND HAVING
          </Typography>
        </Box>
        <Box>
          {open ? <UpIcon style={{ stroke: theme.palette.primary.main }} /> : null}
          {!open ? <DownIcon style={{ stroke: theme.palette.primary.main }} /> : null}
        </Box>
      </Box>
      <motion.div
        initial={{ opacity: open ? 0 : 1 }}
        animate={{
          opacity: open ? 1 : 0,
        }}
        transition={{ duration: 1 }}
        style={{ display: open ? 'flex' : 'none', flexDirection: 'column' }}
      >
        {!hasHavingItems && (
          <Grid container display="flex" justifyContent="center">
            <Button
              variant="outlined"
              sx={{ width: 'fit-content', display: 'inline', mx: 'auto', my: 'auto' }}
              onClick={handleAddInitialHaving}
            >
              +
            </Button>
          </Grid>
        )}
        <Grid sx={{ width: '100%', mt: '1rem' }}>
          {migrationTableForeignReferences[key]?.data &&
            having?.map((havingValue: LogicalOperator | RuleComponent, index) => {
              const condition = havingValue as RootCondition;
              if (havingValue.type === RuleComponentType.ROOT_CONDITIONAL) {
                return (
                  <Grid container display="flex" alignItems="center" key={`rule-having-form-record-${index}`} gap={0.5}>
                    {index !== 0 && <VerticalDivider height="20px" sx={{ marginLeft: 3 }} />}
                    <>
                      <HavingRecordForm index={index} condition={condition} />
                    </>

                    <VerticalDivider height="20px" sx={{ marginLeft: 3 }} />

                    <AddLogicalOperatorButton
                      index={index}
                      where={having}
                      type={ConditionType.HAVING}
                      wherePath={'having'}
                    />
                  </Grid>
                );
              } else if (havingValue.type === RuleComponentType.LOGICAL_OPERATOR) {
                return (
                  <Box key={`rule-form-logicalOperatorFormRow-${index}`} sx={{ my: 0.5, width: 'fit-content' }}>
                    <LogicalOperatorFormRow
                      key={`logical-operator-form-row-having-${index}`}
                      logicalOperatorRecord={havingValue as LogicalOperator}
                      targetPath={`having[${index}]`}
                    />
                  </Box>
                );
              }
            })}
        </Grid>
      </motion.div>
    </>
  );
};

export { HavingList };
