import { createAction } from '@reduxjs/toolkit';

import { RuleWhereArray, FrontEndRuleDto, RuleHavingArray } from 'store/dataRaptorRule/dto/front-end-rule.dto';

import { AISuggestions, Option, SubQuery } from 'store/ruleForm/types';
import { RuleTemporalTable } from 'store/dataRaptorRule/types';

const RESET_ALL = 'ruleForm/RESET_ALL';
const SET_ERROR = 'ruleForm/SET_ERROR';
const SET_SUCCESS = 'ruleForm/SET_SUCCESS';
const SET_LOADING = 'ruleForm/SET_LOADING';
const SET_RULE_NAME = 'ruleForm/SET_RULE_NAME';
const SET_DESCRIPTION = 'ruleForm/SET_DESCRIPTION';
const SET_VIOLATION_SCORE = 'ruleForm/SET_VIOLATION_SCORE';
const SET_CATEGORY = 'ruleForm/SET_CATEGORY';
const SET_RISK_LEVEL = 'ruleForm/SET_RISK_LEVEL';
const SET_DEPARTMENT = 'ruleForm/SET_DEPARTMENT';
const SET_WHERE = 'ruleForm/SET_WHERE';
const SET_HAVING = 'ruleForm/SET_HAVING';
const SET_FIELD_VALUE = 'ruleForm/SET_FIELD_VALUE';
const SET_FORM_ERRORS = 'ruleForm/SET_FORM_ERRORS';
const SET_FORM_ERRORS_OBJECT = 'ruleForm/SET_FORM_ERRORS_OBJECT';
const SET_RULE_TEMPLATE = 'ruleForm/SET_RULE_TEMPLATE';
const SET_FIELD_OPTIONS_MAP = 'ruleForm/SET_FIELD_OPTIONS';
const SET_FIELD_FOREIGN_REF_OPTIONS = 'ruleForm/SET_FIELD_FOREIGN_REF_OPTIONS';
const SET_SUB_QUERIES_ARRAY = 'ruleForm/SET_SUB_QUERIES_ARRAY';
const SET_SUB_QUERIES_WHERE = 'ruleForm/SET_SUB_QUERIES_WHERE';
const SET_SUB_QUERY_MODAL = 'ruleForm/SET_SUB_QUERY_MODAL';
const SET_SUB_QUERY_MODAL_INDEX = 'ruleForm/SET_SUB_QUERY_MODAL_INDEX';
const SET_TABLE_OPTIONS = 'ruleForm/SET_TABLE_OPTIONS';
const SET_SUB_QUERY_FORM_WHERE = 'ruleForm/SET_SUB_QUERY_FORM_WHERE';
const SET_EDIT_RULE_TEMPORAL = 'ruleForm/SET_EDIT_RULE_TEMPORAL';

export const setEditRuleTemporal = createAction<RuleTemporalTable | null>(SET_EDIT_RULE_TEMPORAL);

export const setError = createAction<boolean>(SET_ERROR);

export const setRuleTemplate = createAction<FrontEndRuleDto>(SET_RULE_TEMPLATE);

export const setSuccess = createAction<boolean>(SET_SUCCESS);

export const setLoading = createAction<boolean>(SET_LOADING);

export const setViolationScore = createAction<number>(SET_VIOLATION_SCORE);

export const setRuleName = createAction<string>(SET_RULE_NAME);

export const setDescription = createAction<string>(SET_DESCRIPTION);

export const setCategory = createAction<string>(SET_CATEGORY);

export const setRiskLevel = createAction<string>(SET_RISK_LEVEL);

export const setDepartment = createAction<string>(SET_DEPARTMENT);

export const setTableOptions = createAction<Option[]>(SET_TABLE_OPTIONS);

export const setSubQueriesWhere = createAction<{ value: RuleWhereArray }>(SET_SUB_QUERIES_WHERE);

export const setSubQueryFormWhere = createAction<{ value: RuleWhereArray }>(SET_SUB_QUERY_FORM_WHERE);

export const setWhere = createAction<{ value: RuleWhereArray }>(SET_WHERE);

export const setHaving = createAction<{ value: RuleHavingArray }>(SET_HAVING);

export const setSubQueries = createAction<{ value: SubQuery[] }>(SET_SUB_QUERIES_ARRAY);

export const setFieldValue = createAction<{ path: string; value: any }>(SET_FIELD_VALUE);

export const setFormError = createAction<{ path: string; value: any }>(SET_FORM_ERRORS);

export const setFormErrorObject = createAction<any>(SET_FORM_ERRORS_OBJECT);

export const resetAll = createAction(RESET_ALL);

export const setShowSubQueryModal = createAction<boolean>(SET_SUB_QUERY_MODAL);

export const setSubQueryModalIndex = createAction<number>(SET_SUB_QUERY_MODAL_INDEX);

export const setFieldOptions = createAction<Option[] | undefined>(SET_FIELD_OPTIONS_MAP);

export const setFieldForeignRefOptions = createAction<Option[] | undefined>(SET_FIELD_FOREIGN_REF_OPTIONS);

// Rule Library Rule Form Actions
const SET_RULE_LIBRARY_MODAL_OPEN = 'ruleForm/SET_RULE_LIBRARY_MODAL_OPEN';
const SET_RULE_LIBRARY_MODAL_MENU_INDEX = 'temporalTableForm/SET_RULE_LIBRARY_MODAL_MENU_INDEX';
const SET_RULE_LIBRARY_MODAL_LOADING = 'ruleForm/SET_RULE_FORM_LIBRARY_MODAL_LOADING';
const SET_RULE_LIBRARY_MODAL_LOADING_MESSAGE = 'ruleForm/SET_RULE_FORM_LIBRARY_MODAL_LOADING_MESSAGE';

export const setRuleLibraryModalOpen = createAction<boolean>(SET_RULE_LIBRARY_MODAL_OPEN);
export const setRuleLibraryModalMenuIndex = createAction<number>(SET_RULE_LIBRARY_MODAL_MENU_INDEX);
export const setRuleLibraryModalLoading = createAction<boolean>(SET_RULE_LIBRARY_MODAL_LOADING);
export const setRuleLibraryModalLoadingMessage = createAction<string>(SET_RULE_LIBRARY_MODAL_LOADING_MESSAGE);

// AI Assistant Actions
const SET_AI_ASSISTANT_OPEN = 'ruleForm/SET_AI_ASSISTANT_OPEN';
const SET_AI_ASSISTANT_TILED_WINDOW = 'ruleForm/SET_AI_ASSISTANT_TILED_WINDOW';
const SET_AI_ASSISTANT_SUGGESTION = 'ruleForm/SET_AI_ASSISTANT_SUGGESTION';

export const setAiAssistantOpen = createAction<boolean>(SET_AI_ASSISTANT_OPEN);
export const setAiAssistantTiledWindow = createAction<boolean>(SET_AI_ASSISTANT_TILED_WINDOW);
export const setAiAssistantSuggestion = createAction<Partial<AISuggestions>>(SET_AI_ASSISTANT_SUGGESTION);
