import React from 'react';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';
import { isBefore, isAfter, eachMonthOfInterval, isSameMonth, format } from 'date-fns';

interface IProps {
  migrationId: string;
  node: any;
}
const useOpportunityInsightsData = ({ migrationId, node }: IProps) => {
  const { data: eventsData = [], isFetching: eventsLoading } = useGetMigrationTableDataByIdQuery({
    migrationId: migrationId,
    tableId: 'Event',
    skip: 0,
    take: 0,
    conditions: [{ field: 'WhatId', operator: '=', value: `'${node.id}'` }],
  });

  const { data: tasksData = [], isFetching: tasksLoading } = useGetMigrationTableDataByIdQuery({
    migrationId: migrationId,
    tableId: 'Task',
    skip: 0,
    take: 0,
    conditions: [{ field: 'WhatId', operator: '=', value: `'${node.id}'` }],
  });

  const { data: emailsData = [], isFetching: emailsLoading } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'EmailMessage',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'ActivityId',
          operator: 'IN',
          value: `(${[...eventsData, ...tasksData]
            .filter((e) => e.EventSubtype === 'Email' || e.TaskSubtype === 'Email')
            .map((e) => `'${e.Id}'`)
            .join(', ')})`,
        },
      ],
    },
    {
      skip:
        [...eventsData, ...tasksData].filter((e) => e.EventSubtype === 'Email' || e.TaskSubtype === 'Email').length ===
        0,
    },
  );
  const { data: users = [], isFetching: usersLoading } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'User',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'Id',
          operator: 'IN',
          value: `(${[...eventsData, ...tasksData]
            .filter((e) => !!e.OwnerId)
            .map((e) => `'${e.OwnerId}'`)
            .join(', ')})`,
        },
      ],
    },
    {
      skip: [...eventsData, ...tasksData].length === 0,
    },
  );

  const getEngagementHistoryTooltipInfos = React.useCallback(
    (activityId: string, type: 'email' | 'call' | 'meeting') => {
      if (type === 'email') {
        const email = tasksData.find((task) => task.Id === activityId);
        if (email) {
          const to = node.Name;
          const attendee = users.find((user) => user.Id === email.OwnerId)?.Name;
          const date = email.CreatedDate;
          const type = 'sent';
          const title = email.Subject;
          return {
            title,
            type,
            attendee,
            date,
            to,
          };
        }
      }
      if (type === 'call') {
        const call = tasksData.find((task) => task.Id === activityId);
        if (call) {
          const to = node.Name;
          const attendee = users.find((user) => user.Id === call.OwnerId)?.Name;
          const date = call.CreatedDate;
          const type = 'sent';
          const title = call.Subject;
          return {
            title,
            type,
            attendee,
            date,
            to,
          };
        }
      }

      if (type === 'meeting') {
        const meeting = eventsData.find((event) => event.Id === activityId);
        if (meeting) {
          const to = node.Name;
          const attendee = users.find((user) => user.Id === meeting.OwnerId)?.Name;
          const date = meeting.CreatedDate;
          const type = 'sent';
          const title = meeting.Subject;
          return {
            title,
            type,
            attendee,
            date,
            to,
          };
        }
      }
      return {
        title: '',
        type: '',
        attendee: '',
        date: '',
        to: '',
      };
    },
    [eventsData, tasksData, users],
  );

  const isLoading = eventsLoading || tasksLoading || usersLoading || emailsLoading;

  const engagementHistoryChartData = React.useMemo(() => {
    const emails = [...eventsData, ...tasksData].filter(
      (singleActivity) => singleActivity.EventSubtype === 'Email' || singleActivity.TaskSubtype === 'Email',
    );
    const calls = [...eventsData, ...tasksData].filter(
      (singleActivity) => singleActivity.EventSubtype === 'Call' || singleActivity.TaskSubtype === 'Call',
    );
    const meetings = eventsData;

    const emailsData = emails.map((singleActivity) => {
      const contact = users.find((singleContact) => singleContact.Id === singleActivity.OwnerId);
      return {
        x: format(new Date(singleActivity.ActivityDate), 'dd-MM-yyyy'),
        y: contact?.Name,
        r: 8,
        id: singleActivity.Id,
      };
    });
    const callsData = calls.map((singleActivity) => {
      const contact = users.find((singleContact) => singleContact.Id === singleActivity.OwnerId);
      return {
        x: format(new Date(singleActivity.ActivityDate), 'dd-MM-yyyy'),
        y: contact?.Name,
        r: 8,
        id: singleActivity.Id,
      };
    });
    const meetingsData = meetings.map((singleActivity) => {
      const contact = users.find((singleContact) => singleContact.Id === singleActivity.OwnerId);
      return {
        x: format(new Date(singleActivity.ActivityDate), 'dd-MM-yyyy'),
        y: contact?.Name,
        r: 8,
        id: singleActivity.Id,
      };
    });

    const xLabels = [...callsData, ...emailsData, ...meetingsData].map((activity) => activity.x);

    return {
      yLabels: users.map((contact) => contact?.Name),
      xLabels,
      meetings: meetingsData,
      calls: callsData,
      emails: emailsData,
    };
  }, [users, tasksData, eventsData]);

  const callsMeetingsEngagementChartData = React.useMemo(() => {
    const calls = [...tasksData].filter((singleActivity) => singleActivity.TaskSubtype === 'Email');
    const meetings = eventsData;

    const oldestEntry = [...calls, ...meetings].reduce((oldest, current) => {
      if (!oldest) return current;
      return isBefore(new Date(current.CreatedDate), new Date(oldest.CreatedDate)) ? current : oldest;
    }, null);
    const latestEntry = [...calls, ...meetings].reduce((latest, current) => {
      if (!latest) return current;
      return isAfter(new Date(current.CreatedDate), new Date(latest.CreatedDate)) ? current : latest;
    }, null);
    if (!oldestEntry || !latestEntry) return [];
    const months = eachMonthOfInterval({
      start: new Date(oldestEntry.CreatedDate),
      end: new Date(latestEntry.CreatedDate),
    });

    const data = months.map((month) => {
      const monthCalls = calls.filter((singleCall) => isSameMonth(new Date(singleCall?.CreatedDate), month));
      const monthMeetings = meetings.filter((singleMeeting) =>
        isSameMonth(new Date(singleMeeting?.CreatedDate), month),
      );

      return {
        date: format(month, 'dd-MM-yyyy'),
        data: {
          successfulCalls: monthCalls.length,
          unsuccessfulCalls: 0,
          missedCalls: 0,

          completedMeetings: monthMeetings.length,
          scheduledMeetings: 0,
          canceledMeetings: 0,
        },
      };
    });

    return data;
  }, [tasksData, eventsData]);

  const emailsEngagementChartData = React.useMemo(() => {
    const emails = [...tasksData].filter(
      (singleActivity) => singleActivity.EventSubtype === 'Email' || singleActivity.TaskSubtype === 'Email',
    );
    const emailsMessages = emailsData.filter((singleEmail) =>
      emails.some((email) => email.Id === singleEmail.ActivityId),
    );

    const oldestEntry = emailsMessages.reduce((oldest, current) => {
      if (!oldest) return current;
      return isBefore(new Date(current.CreatedDate), new Date(oldest.CreatedDate)) ? current : oldest;
    }, null);
    const latestEntry = emailsMessages.reduce((latest, current) => {
      if (!latest) return current;
      return isAfter(new Date(current.CreatedDate), new Date(latest.CreatedDate)) ? current : latest;
    }, null);
    if (!oldestEntry || !latestEntry) return [];
    const months = eachMonthOfInterval({
      start: new Date(oldestEntry.CreatedDate),
      end: new Date(latestEntry.CreatedDate),
    });

    const data = months.map((month) => {
      const monthEmails = emailsMessages.filter((singleEmail) =>
        isSameMonth(new Date(singleEmail?.CreatedDate), month),
      );
      const receivedEmails = monthEmails.filter((singleEmail) => !!singleEmail.Incoming).length;
      const openedSentEmails = monthEmails.filter(
        (singleEmail) => !singleEmail.Incoming && !!singleEmail.IsOpened,
      ).length;
      const unopenedSentEmails = monthEmails.filter(
        (singleEmail) => !singleEmail.Incoming && !singleEmail.IsOpened,
      ).length;

      return {
        date: format(month, 'dd-MM-yyyy'),
        data: {
          received: receivedEmails,
          opened: openedSentEmails,
          notOpened: -unopenedSentEmails,
        },
      };
    });

    return data;
  }, [tasksData, emailsData]);

  return {
    isLoading,
    engagementHistoryChartData,
    emailsEngagementChartData,
    callsMeetingsEngagementChartData,
    getEngagementHistoryTooltipInfos,
  };
};

export default useOpportunityInsightsData;
