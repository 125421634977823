import { Box, Button, ButtonGroup, Input, Typography } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartData } from 'chart.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RuleStat, TableStatInterval } from 'http/migration/dto';
import theme from 'core/theme';
import { useDashboard } from 'store/dashboard/hook';
import { useLazyGetRuleStatsByTableQuery } from 'store/migration/api';
import _ from 'lodash';
import { convertISOStringToLabelInterval } from '../../DataQualityTimeline/components/TimeLinearChart/hooks/TableStats';
import { Show } from '../../../../../../../../../../components/show';
import { LoadingComponent } from 'pages/RuleLibrary/ui';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const padName = (name: string, length: number) => {
  const paddedName = name.padEnd(length);
  return paddedName.length > length ? `${paddedName.substring(0, length - 3)}...` : paddedName;
};

const options = {
  indexAxis: 'y' as const,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      border: {
        dash: [5, 5],
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
      ticks: {
        color: '#898EA1',
        font: {
          size: 12,
          weight: 500,
        },
        callback: function (val: string, index: number): any {
          return index % 2 === 0 ? val : '1';
        },
      },
    },
  },
};

const formatToChartData = (data: RuleStat[]): ChartData<'bar'> => {
  return {
    labels: data.map((item) => item.name),
    datasets: [
      {
        label: 'Avg affected record count',
        data: data.map((item) => item.avg_affected_row_count),
        borderRadius: 4,
        backgroundColor: '#1554FF',
      },
    ],
  };
};

// @ts-ignore
const periods = TableStatInterval;

export const TopAnomaliesDetected = () => {
  const {
    data: { selectedTable, selectedMigrationId },
  } = useDashboard();
  const [date, setDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [interval, setInterval] = useState<TableStatInterval>(TableStatInterval.MONTH);

  const [getRuleStatsByTable, { data: ruleStats, isFetching }] = useLazyGetRuleStatsByTableQuery();

  const injectedOptions = useMemo(() => {
    const optTmp = _.cloneDeep(options);
    optTmp.scales.y.ticks.callback = function (val: string, index: number): any {
      return ruleStats?.[index]?.name ? padName(ruleStats[index].name, 20) : '';
    };
    return optTmp;
  }, [ruleStats]);

  useEffect(() => {
    if (date && interval && selectedMigrationId && selectedTable) {
      getRuleStatsByTable(
        {
          date,
          interval,
          migrationId: selectedMigrationId,
          tableId: selectedTable,
        },
        true,
      );
    }
  }, [date, getRuleStatsByTable, interval, selectedMigrationId, selectedTable]);

  const topRulesOrdered = useMemo(() => {
    const ruleTmp = _.cloneDeep(ruleStats);
    return ruleTmp?.sort((a, b) => b.avg_affected_row_count - a.avg_affected_row_count).slice(0, 10) || [];
  }, [ruleStats]);

  const data = useMemo(() => {
    return formatToChartData(topRulesOrdered || []);
  }, [topRulesOrdered]);

  const handleStartDateChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
  }, []);

  const handleIntervalChange = useCallback((period: TableStatInterval) => {
    setInterval(period);
  }, []);

  return (
    <Box p={2}>
      <Typography fontSize={20} fontWeight={600}>
        Top 10 Anomalies Detected
      </Typography>
      <Box sx={{ px: 0 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display={'flex'} gap={1} alignItems={'center'}>
            <Input type="date" value={date} onChangeCapture={handleStartDateChange} />
          </Box>
          <ButtonGroup
            sx={{
              '& .MuiButtonGroup-grouped': {
                height: '16px',
                bgcolor: 'white',
                color: '#898EA1',
                border: 'none',
                fontSize: '12px',
                fontWeight: 400,
                '&:hover': { bgcolor: 'white', color: '#898EA1', fontWeight: 600, border: 'none' },
              },
            }}
          >
            {Object.values(periods).map((period) => {
              let style: any = { textTransform: 'capitalize' };
              if (period === interval) {
                style = { ...style, color: theme.palette.blue.main, fontWeight: 'bold' };
              }
              return (
                <Button
                  key={period}
                  onClick={() => {
                    handleIntervalChange(period);
                  }}
                >
                  <Typography sx={style}>{period}</Typography>
                </Button>
              );
            })}
          </ButtonGroup>
        </Box>
        <Show
          when={!!ruleStats && !isFetching}
          fallback={
            <Box sx={{ mt: 4 }}>
              <LoadingComponent />
            </Box>
          }
        >
          <Bar style={{ paddingTop: 4 }} options={injectedOptions as any} data={data} />
          <Box display={'flex'} justifyContent={'center'} mt={1}>
            <Typography variant="labelRegular12"> ({convertISOStringToLabelInterval(date, interval)})</Typography>
          </Box>
        </Show>
      </Box>
    </Box>
  );
};
