import { Box, Typography, CircularProgress } from '@mui/material';
import theme from 'core/theme';
import { getHumanReadableDate } from 'core/utils/time';
import { getMigrationTableDataById } from 'http/migration';
import { FC, useCallback, useEffect, useState } from 'react';
import { useMigration } from 'store/migration/hooks';

export enum EventFieldOption {
  whoId = 'WhoId',
  whatId = 'WhatId',
}

interface ActivityDataListProps {
  recordId: string;
  eventField: EventFieldOption;
}

export const ActivityDataList: FC<ActivityDataListProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState<any[]>([]);
  const [showRecordCount, setShowRecordCount] = useState<number>(2);

  const {
    data: { migrationId },
  } = useMigration();
  const { recordId, eventField } = props;

  const getActivityData = useCallback(
    (recordId: string) => {
      setLoading(true);
      if (!migrationId) return;

      const conditions = [{ field: eventField, operator: '=', value: `'${recordId}'` }];

      getMigrationTableDataById(migrationId, 'Event', 0, 100, conditions)
        .then((data) => {
          setActivities(data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [eventField, migrationId],
  );

  useEffect(() => {
    getActivityData(recordId);
  }, [getActivityData, recordId]);

  //ActivityDateTime
  //Description

  const getActivityRecord = useCallback((activity: any) => {
    return (
      <Box display={'flex'} flexDirection={'column'}>
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Box
            width={'15px'}
            height={'15px'}
            sx={{ borderRadius: '100%', backgroundColor: theme.palette.primary.main }}
          />
          <Typography variant="labelRegular12" sx={{ color: 'neutral.n400' }}>
            {getHumanReadableDate(activity.ActivityDateTime)}
          </Typography>
        </Box>
        <Box pl={2} ml={'7px'} my={'4px'} sx={{ borderLeft: `1px solid ${theme.palette.neutral.n300}` }}>
          <Typography variant="p14">{activity.Description || activity.Subject || activity.Type}</Typography>
        </Box>
      </Box>
    );
  }, []);

  const setShowRecordCountToMax = useCallback(() => {
    setShowRecordCount(activities.length);
  }, [activities.length]);

  if (loading)
    return (
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <CircularProgress size={'18px'} />
        <Typography>Searching activity data...</Typography>
      </Box>
    );

  if (loading === false && activities.length === 0) return <Typography>No activities Found!</Typography>;

  return (
    <>
      <Box>{activities.slice(0, showRecordCount).map((activity) => getActivityRecord(activity))}</Box>
      {activities.length > showRecordCount && (
        <Box
          onClick={setShowRecordCountToMax}
          display={'flex'}
          alignItems={'center'}
          width={'fit-content'}
          gap={1}
          sx={{ ':hover': { cursor: 'pointer' } }}
        >
          <Box
            width={'15px'}
            height={'15px'}
            sx={{
              borderRadius: '100%',
              backgroundColor: 'transparent',
              borderColor: theme.palette.primary.main,
              borderWidth: '1px',
              borderStyle: 'solid',
            }}
          />
          <Typography variant="labelMedium14" sx={{ color: theme.palette.primary.main }}>
            Show more ({activities.length - showRecordCount})
          </Typography>
        </Box>
      )}
    </>
  );
};
