import React from 'react';
import { Stack, Typography, Avatar } from '@mui/material';
import { format } from 'date-fns';

interface IProps {
  position: {
    x: number;
    y: number;
  };
  data?: any;
  type?: 'call' | 'email' | 'meeting';
}
const CustomTooltip = ({ position, data, type = 'meeting' }: IProps) => {
  const emailSent = React.useMemo(() => Math.max(['sent', 'received'].indexOf(data.type), 0), [data]);

  if (type === 'meeting')
    return (
      <Stack
        sx={{
          position: 'absolute',
          top: position.y,
          left: position.x,
          p: 1,
          backgroundColor: 'blue.dark',
          borderRadius: '5px',
          color: 'common.white',
          width: '230px',
        }}
        gap={0.5}
      >
        <Typography variant="b12" mb={1} color="common.white">
          {data?.title}
        </Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Date</Typography>
          <Typography variant="labelMedium12">{format(new Date(data.date), 'MMM dd, h:mm aa')}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Organizer</Typography>
          <Typography variant="labelMedium12">{data?.to}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Attendee</Typography>
          <Typography variant="labelMedium12">
            <Avatar sx={{ width: '18px', height: '18px', fontSize: '12px' }} key={`avatar_${data.attendee}`}>
              {data.attendee[0]}
            </Avatar>
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Meeting status</Typography>
          <Typography variant="labelMedium12" color="green.main">
            Completed
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Meeting Impact</Typography>
          <Typography variant="labelMedium12" color="red.main">
            ↓ Low
          </Typography>
        </Stack>
      </Stack>
    );

  if (type === 'call')
    return (
      <Stack
        sx={{
          position: 'absolute',
          top: position.y,
          left: position.x,
          //zIndex: 10,
          p: 1,
          backgroundColor: 'blue.dark',
          borderRadius: '5px',
          color: 'common.white',
          width: '230px',
        }}
        gap={0.5}
      >
        <Typography variant="b12" mb={1} color="common.white">
          {data?.title} {['↗', '↙'][emailSent]}
        </Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Started on</Typography>
          <Typography variant="labelMedium12">{format(new Date(data?.date), 'MMM dd, h:mm aa')}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Organizer</Typography>
          <Typography variant="labelMedium12">{data?.to}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Attendee</Typography>
          <Typography variant="labelMedium12">
            <Avatar sx={{ width: '18px', height: '18px', fontSize: '12px' }} key={`avatar_${data?.attendee}`}>
              {data?.attendee[0]}
            </Avatar>
          </Typography>
        </Stack>
      </Stack>
    );
  if (type === 'email')
    return (
      <Stack
        sx={{
          position: 'absolute',
          top: position.y,
          left: position.x,
          //zIndex: 10,
          p: 1,
          backgroundColor: 'blue.dark',
          borderRadius: '5px',
          color: 'common.white',
          width: '230px',
        }}
        gap={0.5}
      >
        <Typography variant="b12" color="common.white" mb={1}>
          {data?.title}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">{emailSent ? 'Sent' : 'Received'} on</Typography>
          <Typography variant="labelMedium12">{format(new Date(data?.date), 'MMM dd, h:mm aa')}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">{emailSent ? 'To' : 'From'}</Typography>
          <Typography variant="labelMedium12">{data?.to}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Attendee</Typography>
          <Typography variant="labelMedium12">
            <Avatar sx={{ width: '18px', height: '18px', fontSize: '12px' }} key={`avatar_${data?.attendee}`}>
              {data?.attendee[0]}
            </Avatar>
          </Typography>
        </Stack>
      </Stack>
    );
  return <></>;
};

export default CustomTooltip;
