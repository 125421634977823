import { Box, Grid, Typography } from '@mui/material';
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-no-bg.svg';
import { FC } from 'react';

export const Footer: FC = () => {
  return (
    <>
      <Grid
        item
        xs={6}
        sx={{ borderRight: '1px solid', borderColor: 'neutral.lightGray', height: '100%' }}
        display={'flex'}
        alignItems={'center'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={0.5}
          sx={{ width: 'fit-content', m: 'auto', cursor: 'pointer' }}
        >
          <BellIcon display={'inline'} />
          <Typography sx={{ color: 'primary.main', display: 'inline' }}>Set Reminder</Typography>
        </Box>
      </Grid>
      <Grid item xs={6} sx={{ height: '100%' }} display={'flex'} alignItems={'center'}>
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={0.5}
          sx={{ width: 'fit-content', m: 'auto', cursor: 'pointer' }}
        >
          <EditIcon display={'inline'} />
          <Typography sx={{ color: 'primary.main', display: 'inline' }}>All Reminders</Typography>
        </Box>
      </Grid>
    </>
  );
};
