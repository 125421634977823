import { FC, useState } from 'react';
import { Typography, Button, Box, TextField } from '@mui/material';
import CustomDialog from 'components/CustomDialog/CustomDialog';

interface SaveMappingModalProps {
  open: boolean;
  onClose: () => void;
}

const SaveMappingModal: FC<SaveMappingModalProps> = ({ open, onClose }) => {
  const [nameFieldMapping, setNameFieldMapping] = useState('');

  const handleCreate = () => {
    setNameFieldMapping('');
    onClose();
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title="Save Mapping"
      actions={
        <>
          <Button onClick={onClose} color="inherit">
            Cancel
          </Button>
          <Button variant="contained" sx={{ backgroundColor: 'primary.main' }} onClick={handleCreate}>
            Save Mapping
          </Button>
        </>
      }
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography>Name</Typography>
        <TextField value={nameFieldMapping} onChange={(e) => setNameFieldMapping(e.target.value)} fullWidth />
      </Box>
    </CustomDialog>
  );
};

export default SaveMappingModal;
