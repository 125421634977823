import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';

export interface Option {
  value: string;
  label: string;
}

export enum ANOMALY_VALIDATION_ENUM {
  REGEX = 'REGEX',
  SERVICE = 'SERVICE',
}

export const ANOMALY_VALIDATION_OPTIONS: Option[] = [
  {
    value: 'REGEX',
    label: 'Format',
  },
  {
    value: 'SERVICE',
    label: 'Validity',
  },
];

export const OPERATOR_OPTIONS: Option[] = [
  {
    value: '===',
    label: 'Equal',
  },
  {
    value: '!==',
    label: 'Not Equal',
  },
];

export const CONDITIONAL_VALUE_OPTIONS = [
  { label: 'Function', value: RuleComponentType.FUNCTION_VALUE },
  { label: 'Value', value: RuleComponentType.PRIMITIVE_VALUE },
  { label: 'Field', value: RuleComponentType.FIELD_REFERENCE },
  { label: 'Sub Query', value: RuleComponentType.SUB_QUERY_VALUE },
];

export const BOOLEAN_OPTIONS = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];
export const NULL_OPTIONS = [{ label: 'Null', value: 'Null' }];
