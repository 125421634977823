import React from 'react';
import { Stack, Typography } from '@mui/material';

interface IProps {
  position: {
    x: number;
    y: number;
  };
  data?: any;
}
const CustomTooltip = ({ position, data }: IProps) => {
  if (!data) return <></>;
  const calls = ['Successful Calls', 'Unsuccessful Calls', 'Missed Calls'];
  const meetings = Object.keys(data).filter((e) => e.includes('Meeting'));
  return (
    <Stack
      sx={{
        position: 'absolute',
        top: position.y,
        left: position.x,
        p: 1,
        backgroundColor: 'common.black',
        borderRadius: '5px',
        color: 'common.white',
        width: '200px',
      }}
      spacing={0.5}
    >
      <Typography variant="labelBold14" color="common.white">
        {data.period}
      </Typography>

      {calls.map((key) => (
        <Stack direction="row" alignItems="center" justifyContent="space-between" key={key}>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Stack
              sx={{
                width: '12px',
                height: '12px',
                backgroundColor: data[key].color,
                borderRadius: '2px',
              }}
            />
            <Typography variant="p12">{key}</Typography>
          </Stack>
          <Typography variant="labelMedium12">{data[key].value}</Typography>
        </Stack>
      ))}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="p12">Total calls</Typography>
        <Typography variant="labelMedium12">
          {calls.map((key) => data[key].value).reduce((acc, val) => acc + val, 0)}
        </Typography>
      </Stack>

      {meetings.map((key) => (
        <Stack direction="row" alignItems="center" justifyContent="space-between" key={key}>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Stack
              sx={{
                width: '12px',
                height: '12px',
                backgroundColor: data[key].color,
                borderRadius: '2px',
              }}
            />
            <Typography variant="labelMedium12">{key}</Typography>
          </Stack>
          <Typography variant="labelMedium12">{data[key].value}</Typography>
        </Stack>
      ))}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="labelMedium12">Total Meetings</Typography>
        <Typography variant="labelMedium12">
          {calls.map((key) => data[key].value).reduce((acc, val) => acc + val, 0)}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CustomTooltip;
