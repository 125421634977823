import { useMemo } from 'react';
import { useNodes, Node } from 'reactflow';
import { LeadRecordCard } from './LeadRecordCard';
import { useGraphRender } from 'store/graphRender/hooks';
import { getSortingFunction } from '../../../../utils/LeadViewSortingOptions';
export const LeadRecordList = () => {
  const {
    data: {
      leadViewState: { sortByField },
    },
  } = useGraphRender();
  const nodes: Node<any>[] = useNodes();
  const leadNodes: Node<any>[] = useMemo(() => {
    return nodes.filter((node: any) => node?.data?.bridge != true && node?.hidden != true);
  }, [nodes]);

  const sortingFunction = useMemo(() => {
    return getSortingFunction(sortByField);
  }, [sortByField]);

  const renderList = useMemo(() => {
    return leadNodes.sort(sortingFunction);
  }, [leadNodes, sortingFunction]);

  return (
    <>
      {renderList.map((node) => (
        <LeadRecordCard key={`lead-record-card-${node.id}`} node={node} />
      ))}
    </>
  );
};
