import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, SxProps } from '@mui/material';

interface DataTableProps {
  columns: string[];
  rows: { [key: string]: any }[];
  sx?: SxProps;
}

export const DataTable = ({ columns, rows, sx }: DataTableProps) => {
  return (
    <TableContainer component={Paper} sx={{ mt: 4, mb: 2, ...sx }}>
      <Table>
        <TableHead sx={{ backgroundColor: '#F6F8FB' }}>
          <TableRow>
            {columns.map((col, index) => (
              <TableCell key={index} sx={{ borderRight: '1px solid #ccc' }}>
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((col, colIndex) => (
                <TableCell key={colIndex}>{row[`column${colIndex + 1}`]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
