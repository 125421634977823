import { TableStatInterval, TableStatType } from 'http/migration/dto';
import { useEffect } from 'react';
import { useDashboard } from 'store/dashboard/hook';
import { useLazyGetMigrationTableStatsQuery } from 'store/migration/api';

export interface useTableStatsProps {
  migrationId: string;
  table: string;
}

const convertISOStringToYearLabel = (date: string) => {
  return new Date(date).getFullYear().toString();
};

const convertISOStringToMonthAndYear = (date: string) => {
  const d = new Date(date);
  return `${d.toLocaleString('default', { month: 'short' })} ${d.getFullYear()}`;
};

const convertISOStringToYearAndQuarter = (date: string) => {
  const d = new Date(date);
  const quarter = Math.floor(d.getMonth() / 3) + 1;
  return `${d.getFullYear()} Q${quarter}`;
};

const convertISOStringToMonthANDYearWeek = (date: string) => {
  const d = new Date(date);
  const currentDatePlus7Days = new Date(d);
  currentDatePlus7Days.setDate(d.getDate() + 7);
  return `${d.toLocaleString('default', {
    month: 'short',
    day: 'numeric',
  })} ${d.getFullYear()} - ${currentDatePlus7Days.toLocaleString('default', {
    month: 'short',
    day: 'numeric',
  })} ${currentDatePlus7Days.getFullYear()}`;
};

export const convertISOStringToLabelInterval = (date: string, interval: TableStatInterval) => {
  if (interval === TableStatInterval.YEAR) {
    return convertISOStringToYearLabel(date);
  }
  if (interval === TableStatInterval.MONTH) {
    return convertISOStringToMonthAndYear(date);
  }
  if (interval === TableStatInterval.QUARTER) {
    return convertISOStringToYearAndQuarter(date);
  }
  if (interval === TableStatInterval.WEEK) {
    return convertISOStringToMonthANDYearWeek(date);
  }
  return date;
};

export const useTableStats = ({ migrationId, table }: useTableStatsProps) => {
  const [getMigrationTableStats, response] = useLazyGetMigrationTableStatsQuery();
  const {
    data: {
      dataQualityTimeline: { interval, startDate },
    },
  } = useDashboard();

  useEffect(() => {
    if (!!migrationId && !!table) {
      getMigrationTableStats({
        migrationId,
        tableId: table,
        type: TableStatType.SCORE,
        interval,
        startDate: new Date(startDate),
      });
    }
  }, [getMigrationTableStats, interval, migrationId, startDate, table]);

  return { response };
};
