import { FC } from 'react';

import { Box, Button } from '@mui/material';
import { SecondaryButton } from 'components/ui';

export interface RuleFormRuleDetailsFooterProps {
  handleClose: () => void;
  handleNext: () => void;
}

export const RuleFormRuleDetailsFooter: FC<RuleFormRuleDetailsFooterProps> = (props) => {
  const { handleClose } = props;

  const handleFormRuleDetailsFooter = () => {
    const submit = document.getElementById('ruleDetailForm');
    submit?.click();
  };

  return (
    <Box
      display={'flex'}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <SecondaryButton variant="text" sx={{ color: 'black', ':hover': { color: 'black' } }} onClick={handleClose}>
        Cancel
      </SecondaryButton>
      <Button variant="contained" id="ruleFormDetailFooterButton" onClick={handleFormRuleDetailsFooter}>
        Next
      </Button>
    </Box>
  );
};
