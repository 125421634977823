export interface TemporalTableFormState {
  data: {
    open: boolean;
    menuIndex: number;
    mode: TemporalTableFormMode;
    menuItems: TemporalTableFormCreateMenuItem[];
    tableOptions?: TableOption[];
    table: string;
    alias: string;
  };
}

export interface TableOption {
  value: string;
  label: string;
}

export enum TemporalTableFormMode {
  CREATE = 'Create',
  UPDATE = 'Update',
}

export enum TemporalTableFormCreateMenuItem {
  Table = 'Table',
  Conditions = 'Conditions',
}

export interface TemporalTableFormReturnHook extends TemporalTableFormState {
  setOpen: (open: boolean) => void;
  setMenuIndex: (index: number) => void;
  setTableOptions: (options: TableOption[]) => void;
  setTable: (table: string) => void;
  setAlias: (alias: string) => void;
}
