import { Box, Typography } from '@mui/material';
import theme from 'core/theme';
import { getConfidenceScoreColor } from 'core/utils';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Handle, Position, NodeProps, useReactFlow } from 'reactflow';
import { ComponentDesign } from '../../types';
import { useGraphRender } from 'store/graphRender/hooks';
import DuplicateNameLabel from './components/DuplicateNameLabel';
import { DuplicateToolTip } from '../ToolTips/DuplicateToolTip';
import { AccountToolTip } from '../ToolTips/AccountToolTip';
import { GraphRenderView } from 'store/graphRender/types';
import { useMigration } from 'store/migration/hooks';
import { ToolBar } from './components/ToolBar';
import _ from 'lodash';

const HandlerStyle = { display: 'none' };

export const AccountNode: FC<NodeProps> = (props: NodeProps) => {
  const { data, id, selected } = props;
  const accountName = data.Name || 'undefined';
  const {
    data: { migrationId },
  } = useMigration();
  const reactFlow = useReactFlow();
  const [hoverState, setHoverState] = useState(false);
  const [timeoutRef, setTimeoutRef] = useState<any>();
  const {
    data: {
      customDesignMap,
      hoverAccountId,
      defaultDesign,
      hoverNodeId,
      duplicatedNodeIds,
      renderDuplicateNodesOnHover,
      view,
    },
    setAccountHoverId,
    setHoverNodeId,
    setSelectedNode,
    setSelectedNodeFromSearchBar,
  } = useGraphRender();

  useEffect(() => {
    if (selected) {
      const node = reactFlow.getNode(id);
      const nodeTmp = _.cloneDeep(node);
      setSelectedNode(nodeTmp);
      setSelectedNodeFromSearchBar(nodeTmp);
    }
  }, [id, reactFlow, selected, setSelectedNode, setSelectedNodeFromSearchBar]);

  const design = useMemo(() => {
    let design = defaultDesign ? defaultDesign : ComponentDesign.STANDARD;
    if (customDesignMap[id]) {
      design = customDesignMap[id];
    }
    return design;
  }, [customDesignMap, defaultDesign, id]);

  const shouldRenderOnDuplicateHover = useMemo(() => {
    if (hoverNodeId != null && duplicatedNodeIds.includes(hoverNodeId) && !renderDuplicateNodesOnHover.includes(id)) {
      return false;
    }
    return true;
  }, [duplicatedNodeIds, hoverNodeId, id, renderDuplicateNodesOnHover]);

  const shouldRenderName = useMemo(
    () =>
      design === ComponentDesign.STANDARD ||
      design === ComponentDesign.HIGHLIGH ||
      (design === ComponentDesign.DUPLICATE_ACTIVE && shouldRenderOnDuplicateHover),
    [design, shouldRenderOnDuplicateHover],
  );

  const shouldConfidenceScore = useMemo(() => design === ComponentDesign.STANDARD, [design]);

  const confidence_score = useMemo(() => data.confidence_score || 'undefined', [data.confidence_score]);

  const { confidenceScoreColor, backgroundColor } = useMemo(() => {
    let confidenceScoreColor = getConfidenceScoreColor(confidence_score);
    let backgroundColor = theme.palette.neutral.white;

    if (design === ComponentDesign.SEARCHING || design === ComponentDesign.DUPLICATE_INACTIVE) {
      confidenceScoreColor = theme.palette.neutral.subtone3;
      backgroundColor = theme.palette.neutral.subtone3;
    }

    if (design === ComponentDesign.HIGHLIGH) {
      confidenceScoreColor = theme.palette.primary.darkBlueHigh;
      backgroundColor = theme.palette.primary.darkBlueHigh;
    }

    if (design === ComponentDesign.DUPLICATE_ACTIVE) {
      if (shouldRenderOnDuplicateHover) {
        confidenceScoreColor = theme.palette.orange.main;
        backgroundColor = theme.palette.orange.main;
      } else {
        confidenceScoreColor = theme.palette.neutral.subtone3;
        backgroundColor = theme.palette.neutral.subtone3;
      }
    }
    return { confidenceScoreColor, backgroundColor };
  }, [confidence_score, design, shouldRenderOnDuplicateHover]);

  const handleOnHoverTrigger = useCallback(
    (value: string) => {
      if (hoverAccountId !== value) {
        setAccountHoverId(value);
        setHoverNodeId(value);
        const booleanValue = value !== '';
        if (timeoutRef) clearTimeout(timeoutRef);
        if (booleanValue === true) {
          setHoverState(booleanValue);
        } else {
          const timeoutRef = setTimeout(() => {
            setHoverState(booleanValue);
          }, 10);
          setTimeoutRef(timeoutRef);
        }
      }
    },
    [hoverAccountId, setAccountHoverId, setHoverNodeId, timeoutRef],
  );

  const getLabelComponent = useCallback(() => {
    if (design === ComponentDesign.DUPLICATE_ACTIVE) {
      return <DuplicateNameLabel name={accountName} nodeLabel={'Account'} nodeId={id} />;
    }
    return (
      <Typography variant="labelRegular10">
        <strong>{accountName}</strong>
      </Typography>
    );
  }, [accountName, design, id]);

  const shouldRenderDuplicateToolbar = useMemo(() => {
    return design === ComponentDesign.DUPLICATE_ACTIVE && hoverState === true;
  }, [design, hoverState]);

  const shouldRenderAccountToolbar = useMemo(() => {
    return view == GraphRenderView.ACCOUNT && hoverState === true;
  }, [hoverState, view]);

  const shouldRenderDuplicateHoverFlowEffect = useMemo(() => {
    return design === ComponentDesign.DUPLICATE_ACTIVE && hoverState === true;
  }, [design, hoverState]);

  const glowEffectCSS = useMemo(() => {
    if (shouldRenderDuplicateHoverFlowEffect) {
      return {
        '&::after': {
          content: "''",
          position: 'absolute',
          top: '-4px',
          left: '-4px',
          right: '-4px',
          bottom: '-4px',
          borderRadius: '50%',
          zIndex: -1,
          boxShadow: `0px 0px 5px 1.25px ${theme.palette.orange.subtone2}, 0px -0px 1.25px 5px ${theme.palette.orange.subtone2}`,
        },
      };
    }
    return {};
  }, [shouldRenderDuplicateHoverFlowEffect]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme.palette.neutral.white,
          borderRadius: '100%',
          boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`,
          ...glowEffectCSS,
        }}
        onMouseLeave={() => handleOnHoverTrigger('')}
        onMouseOver={() => handleOnHoverTrigger(id)}
      >
        <ToolBar nodeProps={props} renderLockUnlockWhiteEdgeButton />
        {/* Tool Tips */}
        <DuplicateToolTip open={shouldRenderDuplicateToolbar} record={data} id={id} />
        <AccountToolTip open={shouldRenderAccountToolbar} record={data} id={id} migrationId={migrationId} />

        {shouldConfidenceScore && (
          <Box position={'absolute'}>
            <Typography variant="labelBold14">
              <strong>{`${data.confidence_score || '80'}%`}</strong>
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            border: '10px solid',
            borderColor: confidenceScoreColor,
            background: backgroundColor,
            borderRadius: '100%',
            width: '82%',
            height: '82%',
          }}
        />
        {shouldRenderName && (
          <Box
            position={'absolute'}
            top={'0'}
            left={'100%'}
            width={'70px'}
            sx={{ color: theme.palette.blue.dark, lineHeight: '0.5' }}
          >
            {getLabelComponent()}
          </Box>
        )}
        <Handle position={Position.Right} type="source" style={HandlerStyle} />
        <Handle position={Position.Left} type="target" style={HandlerStyle} />
      </Box>
    </>
  );
};
