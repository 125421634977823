import { Box, InputAdornment } from '@mui/material';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import _ from 'lodash';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { FormErrorMessage } from 'pages/RuleLibrary/components/ui/FormErrorMessage';
import { FC, useMemo } from 'react';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorExclamation.svg';

export interface PrimitiveValueFieldProps {
  targetPath: string;
  value: string;
  options: { label: string; value: string }[];
}

export const PrimitiveValueField: FC<PrimitiveValueFieldProps> = (props) => {
  const { targetPath, value, options } = props;
  const { setFieldValue, formError } = useRuleForm();

  const formatError = useMemo(() => formError && _.get(formError, `${targetPath}.format`), [formError, targetPath]);
  const valueError = useMemo(() => formError && _.get(formError, `${targetPath}.value`), [formError, targetPath]);

  const error = formatError || valueError;

  return (
    <Box>
      <ControlledCustomDropdown<string>
        id={`${targetPath}.value`}
        placeholder="value"
        label=""
        value={value || ''}
        options={options || []}
        sx={{ backgroundColor: 'neutral.white' }}
        fallbackOption={{ label: 'Select Value', value: '' }}
        onSelect={(value) =>
          setFieldValue({
            path: `${targetPath}`,
            value: { type: RuleComponentType.PRIMITIVE_VALUE, value: value, format: 'text' },
          })
        }
        InputProps={{
          startAdornment: error && <InputAdornment position="start">{<ErrorIcon />}</InputAdornment>,
        }}
      />
      <FormErrorMessage errorMessage={error} />
    </Box>
  );
};
