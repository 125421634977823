import { FC, useRef, useState } from 'react';

import {
  Box,
  Grid,
  Paper,
  Table,
  Collapse,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableHead,
  Typography,
  IconButton,
  TableContainer,
  TableCellProps,
} from '@mui/material';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { getHumanReadableDate } from 'core/utils/time';
import { useRuleLibrary } from 'store/ruleLibrary/hook';
import { RuleTemporalTable } from 'store/dataRaptorRule/types';

import TableMenuButton from './components/TableMenu';
import { whereConditionComponent } from './components/WhereCondition';
import { WhereConditionTable } from './components/WhereConditionTable';

type RuleTemporalTableKey = 'name' | 'table' | 'createdAt' | 'updatedAt' | 'actions';

type MyTableCellProps = {
  headerName?: string;
  renderCell?: (args: RuleTemporalTable) => JSX.Element;
} & Partial<TableCellProps>;

const ColumnDefinitions: Record<RuleTemporalTableKey, MyTableCellProps> = {
  name: {
    width: '20%',
    headerName: 'Alias',
  },
  table: {
    width: '20%',
    headerName: 'Table',
  },
  createdAt: {
    width: '20%',
    headerName: 'Created',
    renderCell({ createdAt }) {
      return <>{createdAt ? getHumanReadableDate(createdAt.toString()) : ''}</>;
    },
  },
  updatedAt: {
    width: '20%',
    headerName: 'Last Updated',
    renderCell({ updatedAt }) {
      return <>{updatedAt ? getHumanReadableDate(updatedAt.toString()) : ''}</>;
    },
  },
  actions: {
    width: '5%',
    headerName: 'Actions',
    renderCell: (args) => <TableMenuButton task={args} />,
  },
};

const columnKeys = Object.keys(ColumnDefinitions) as RuleTemporalTableKey[];

const MyTableRow: FC<{ table: RuleTemporalTable }> = ({ table }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell width={'5%'}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columnKeys.map((key) => {
          const { renderCell, ...props } = ColumnDefinitions[key];
          return (
            <TableCell key={key} {...props}>
              {key === 'actions' ? (
                <TableMenuButton task={table} />
              ) : (
                renderCell?.(table) ?? <>{table[key as keyof RuleTemporalTable]}</>
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={columnKeys.length}
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            backgroundColor: '#F0F3F8',
          }}
        >
          <Collapse in={open} unmountOnExit timeout="auto">
            <Container
              sx={{
                p: 2,
                my: 2,
                borderRadius: 3,
                backgroundColor: 'white',
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                Rule Details
                <br />
              </Typography>
              <Box bgcolor="#EDF0F5" padding={1} borderRadius={3}>
                {table.definition.where.map((condition, key) => {
                  const whereComponent = whereConditionComponent[condition.type];
                  return (
                    <Box key={key} paddingY={0.5}>
                      {whereComponent(condition, table.table)}
                    </Box>
                  );
                })}
              </Box>
            </Container>
            <Container
              sx={{
                p: 2,
                my: 2,
                borderRadius: 3,
                backgroundColor: 'white',
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                Preview Records
              </Typography>
              <WhereConditionTable tempTable={table} />
            </Container>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const TemporaryTableList: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    subPages: { temporalTable },
  } = useRuleLibrary();
  const tables = temporalTable.data.renderedTables;

  return (
    <Grid
      item
      flex={1}
      display={'flex'}
      sx={{
        width: '100%',
        backgroundColor: 'white',
      }}
    >
      <TableContainer ref={ref} component={Paper}>
        <Table size="small" aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell width={'5%'} />
              {columnKeys.map((key) => {
                const { headerName, ...props } = ColumnDefinitions[key];
                return (
                  <TableCell key={key} {...props}>
                    {headerName ?? key}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tables?.map((table) => <MyTableRow key={table.ruleTempTableId} table={table} />) ?? []}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default TemporaryTableList;
