interface GeneratePositionParams {
  currentWord: string;
  wordsPositions: {
    word: string;
    position: {
      left: number;
      top: number;
    };
    fontSize: number;
  }[];
  fontSize: number;
  containerSize: {
    width: number;
    height: number;
  };
}

const isOverlapping = (
  position: {
    left: number;
    top: number;
  },
  otherPositions: {
    left: number;
    top: number;
  }[],
  height: number,
  width: number,
) => {
  return otherPositions.some(
    (singlePosition) =>
      singlePosition.left < position.left + width ||
      singlePosition.left + 20 > position.left ||
      singlePosition.top < position.top + height ||
      singlePosition.top + 20 > position.top,
  );
};

export const generateNonOverlappingPosition = (options: GeneratePositionParams) => {
  const { wordsPositions, fontSize, containerSize, currentWord } = options;

  const wordHeight = fontSize * 2;
  const wordWidth = currentWord.length * (fontSize * 0.6);

  let top = Math.random() * (containerSize.height - wordHeight);
  let left = Math.random() * (containerSize.width - wordWidth);
  let isOverlap = isOverlapping(
    { top, left },
    wordsPositions.map((e) => e.position),
    wordHeight,
    wordWidth,
  );
  if (isOverlap) {
    for (let i = 0; i < 50; i++) {
      top = Math.random() * (containerSize.height - wordHeight);
      left = Math.random() * (containerSize.width - wordWidth);

      isOverlap = isOverlapping(
        { top, left },
        wordsPositions.map((e) => e.position),
        wordHeight,
        wordWidth,
      );
      if (!isOverlap) break;
    }
  }
  /*  while (isOverlap){
  } */

  return { top, left };
};
