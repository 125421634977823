import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { ReactComponent as FilterIcon } from 'assets/icons/filterBlue.svg';
import { PrimaryButton, SecondaryButton } from 'components/ui';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as ResetIcon } from 'assets/icons/reset.svg';
import theme from 'core/theme';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TabSelector } from './components/TabSelector';
import { QuickFilterPanel } from './components/QuickFilterPanel/QuickFilterPanel';
import { CustomFilterPanel } from './components/CustomFilterPanel/CustomFilterPanel';
import { SavedFilterPanel } from './components/SavedFilterPanel/SavedFilterPanel';
import { useGraphRender } from 'store/graphRender/hooks';
import { formatGraphFilter, validateWhereList } from './utils';
import { useMigration } from 'store/migration/hooks';
import { GraphRenderView } from 'store/graphRender/types';
import { Show } from '../../../../components/show';
import { initialDuplicateQuickFilter } from 'store/graphRender/reducers';

const initialTabOptions = ['quick', 'custom'];

const FilterPanel: FC = () => {
  const {
    data: { quickFilterTmp, customFilterTmp, view },
    setGraphFilter,
    setQuickGraphFilter,
    setGraphCustomFilter,
    duplicateQuickFilterReducers: { duplicateQuickFilterSetSelectedTables },
  } = useGraphRender();
  const {
    data: { migrationId },
    getMigrationTableFieldsAndLookUpsById,
  } = useMigration();
  const [open, setOpen] = useState<boolean>(false);
  const [tabOptions, setTabOptions] = useState<string[]>(initialTabOptions);
  const [selectedTab, setSelectedTab] = useState<string>('quick');
  const toggleOpen = () => setOpen(!open);

  const applyFilterButtonActive = useMemo(() => {
    if (selectedTab === 'quick') {
      return true;
    } else if (selectedTab === 'custom') {
      const keys = Object.keys(customFilterTmp);
      let valid = true;
      keys.forEach((key) => {
        const where = (customFilterTmp as any)[key]?.where || [];
        console.log('validate custom filter where list', where);
        console.log('key:::', key);
        const validResponse = validateWhereList(where);
        if (validResponse !== true) {
          valid = false;
        }
      });
      return valid;
    }
    return false;
  }, [customFilterTmp, selectedTab]);

  useEffect(() => {
    if (migrationId) {
      getMigrationTableFieldsAndLookUpsById({ migrationId: migrationId, tableId: 'Account' });
      getMigrationTableFieldsAndLookUpsById({ migrationId: migrationId, tableId: 'Contact' });
      getMigrationTableFieldsAndLookUpsById({ migrationId: migrationId, tableId: 'Opportunity' });
      getMigrationTableFieldsAndLookUpsById({ migrationId: migrationId, tableId: 'Lead' });
      getMigrationTableFieldsAndLookUpsById({ migrationId: migrationId, tableId: 'Event' });
    }
  }, [getMigrationTableFieldsAndLookUpsById, migrationId]);

  const getCurrentPanel = useCallback(() => {
    let SelectedPanel = QuickFilterPanel;
    if (selectedTab === 'quick') {
      SelectedPanel = QuickFilterPanel;
    }
    if (selectedTab === 'custom') {
      SelectedPanel = CustomFilterPanel;
    }
    if (selectedTab === 'saved') {
      SelectedPanel = SavedFilterPanel;
    }
    return (
      <Box height={'55vh'} sx={{ overflowY: 'auto' }}>
        <SelectedPanel></SelectedPanel>
      </Box>
    );
  }, [selectedTab]);

  const handleApplyFilter = useCallback(() => {
    if (view === GraphRenderView.ACCOUNT) {
      if (selectedTab === 'quick') {
        setGraphFilter(formatGraphFilter(quickFilterTmp));
        setOpen(false);
      }
      if (selectedTab === 'custom') {
        setGraphFilter(formatGraphFilter(customFilterTmp));
        setOpen(false);
      }
    }
  }, [customFilterTmp, quickFilterTmp, selectedTab, setGraphFilter, view]);

  const handleResetFilters = useCallback(() => {
    if (view === GraphRenderView.ACCOUNT) {
      setGraphFilter({});
      setQuickGraphFilter({});
      setGraphCustomFilter({});
    }
    if (view === GraphRenderView.DUPLICATES) {
      duplicateQuickFilterSetSelectedTables(initialDuplicateQuickFilter.selectedTables);
    }
  }, [view, setGraphFilter, setQuickGraphFilter, setGraphCustomFilter, duplicateQuickFilterSetSelectedTables]);

  useEffect(() => {
    if (view === GraphRenderView.ACCOUNT) {
      setTabOptions(initialTabOptions);
    } else {
      setTabOptions(initialTabOptions.filter((option) => option == 'quick'));
      setSelectedTab('quick');
    }
  }, [view]);

  return (
    <Box>
      <SecondaryButton onClick={toggleOpen} startIcon={<FilterIcon />}>
        Filters
      </SecondaryButton>
      {open && (
        <Box
          sx={{
            position: 'absolute',
            width: `550px`,
            backgroundColor: 'neutral.white',
            maxHeight: '78vh',
            marginTop: '0.5rem',
            right: '10rem',
            px: 2,
            pb: 2,
            pt: 1,
            zIndex: 9999,
            borderRadius: '4px',
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '1px',
              background: theme.palette.neutral.darkBlueMedium,
            },
          }}
        >
          <Grid
            position={'relative'}
            container
            display={'flex'}
            flexDirection={'column'}
            sx={{ paddingTop: 1 }}
            gap={1}
          >
            <Grid flex={0} container display={'flex'} alignContent={'center'} justifyContent={'space-between'}>
              <Typography fontWeight={'bold'} variant="subtitle1">
                Filters
              </Typography>
              <Box display={'flex'} flexDirection={'row'} gap={2.5} alignItems={'start'}>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  gap={0.2}
                  alignItems={'center'}
                  sx={{ userSelect: 'none', ':hover': { cursor: 'pointer' } }}
                  onClick={handleResetFilters}
                >
                  <ResetIcon />
                  <Typography variant="p14" sx={{ color: theme.palette.neutral.darkGray }}>
                    Reset Filters
                  </Typography>
                </Box>
                <Box sx={{ userSelect: 'none', ':hover': { cursor: 'pointer' } }}>
                  <CloseIcon onClick={() => setOpen(false)} />
                </Box>
              </Box>
            </Grid>
            <Divider />
            <TabSelector tabs={tabOptions} selectedTab={selectedTab} onChange={setSelectedTab} />
            {getCurrentPanel()}
            <Show when={view !== GraphRenderView.DUPLICATES}>
              <Divider />
              <Box display={'flex'} justifyContent={'end'} flexWrap={'nowrap'} gap={1.5} mt={1.5}>
                <Button variant="text" sx={{ color: 'black' }} onClick={toggleOpen}>
                  Cancel
                </Button>
                <PrimaryButton onClick={handleApplyFilter} disabled={!applyFilterButtonActive}>
                  Apply Filters
                </PrimaryButton>
              </Box>
            </Show>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default FilterPanel;
