import { shallowEqual, useSelector } from 'react-redux';

import { RootState } from 'store/types';
import { useActionCreator } from 'hooks';

import { IntegrationState, IntegrationReturnHook } from './types';

import {
  setError,
  uninstall,
  authorize,
  setSuccess,
  authCallback,
  getIntegration,
  getIntegrations,
  setIntegrationStatus,
} from './actions';
import {
  useAuthorizeIntegrationMutation,
  useGetIntegrationQuery,
  useGetIntegrationsQuery,
  usePostAuthCallbackMutation,
  useUninstallIntegrationMutation,
} from './api';

export const useIntegration = (): IntegrationReturnHook => {
  const integrationState: IntegrationState = useSelector((state: RootState) => state.integration, shallowEqual);

  return {
    ...integrationState,
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),

    authCallback: useActionCreator(authCallback),
    postAuthCallback: usePostAuthCallbackMutation,

    authorize: useActionCreator(authorize),
    authorizeIntegration: useAuthorizeIntegrationMutation,

    uninstall: useActionCreator(uninstall),
    uninstallIntegration: useUninstallIntegrationMutation,

    getIntegrationQuery: useGetIntegrationQuery,
    getIntegration: useActionCreator(getIntegration),

    getIntegrationsQuery: useGetIntegrationsQuery,
    getIntegrations: useActionCreator(getIntegrations),

    setIntegrationStatus: useActionCreator(setIntegrationStatus),
  };
};
