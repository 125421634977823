import theme from 'core/theme';

import { FC, useCallback, useMemo } from 'react';
import { Box, Divider, Grid, IconButton, Modal, SxProps, Theme, Typography, CircularProgress } from '@mui/material';

import { useRuleFormStore } from 'store/ruleForm/hook';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';

import { useRuleFormModalHook } from './RuleFormModal.hook';
import { useRuleFormModalEffect } from './RuleFormModal.effect';

import { RuleFormMenu } from './components/RuleFormMenu';
import { RuleFormAdvFilter, RuleFormAdvFilterFooter } from './components/RuleFormContent/TempTable';
import { RuleFormRuleDetails, RuleFormRuleDetailsFooter } from './components/RuleFormContent/RuleDetails';

import { RuleFormConditions, RuleFormConditionsFooter } from './components/RuleFormContent/Conditions';
import { RuleFormRecordType, RuleFormRecordTypeFooter } from './components/RuleFormContent/RecordType';
import { Show } from '../show';
import { useDataRaptorRule } from 'store/dataRaptorRule/hooks';
import { RuleFormMode } from 'pages/RuleLibrary/types';
import { RuleFormModalCreateMenuItem } from 'store/ruleForm/types';
import { AIAssistant } from './components/AIAssistant/AIAssistant';

const generalStyle = {
  height: '90%',
  boxShadow: 10,
  minHeight: '600px',
  borderRadius: '8px',
  backgroundColor: theme.palette.background.paper,
};

const normalStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '90%',
  maxWidth: '1200px',
  transform: 'translate(-50%, -50%)',
};

const tiledWindowsStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '5%',
  right: '1rem',
  width: 'calc(100vw - 420px - 3rem )',
};

const hideScrollbarStyle: SxProps<Theme> = {
  overflow: 'hidden',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none', // Firefox
  msOverflowStyle: 'none', // IE and Edge
};

interface StepUiData {
  title: RuleFormModalCreateMenuItem;
  footer: JSX.Element;
  content: JSX.Element;
}

export const RuleFormModal: FC = () => {
  useRuleFormModalEffect();
  const {
    ruleFormLibraryModal: { menuItems, menuIndex, open, loading, loadingMessage },
    category: ruleType,
    aiAssistant,
  } = useRuleFormStore();

  const modalBoxStyle = useMemo(() => {
    const style = { ...generalStyle };
    if (aiAssistant.open && aiAssistant.tiledWindow) {
      return { ...style, ...tiledWindowsStyle };
    }
    return { ...style, ...normalStyle };
  }, [aiAssistant.open, aiAssistant.tiledWindow]);

  const {
    data: { formMode },
  } = useDataRaptorRule();

  const { handleCloseModal, setRuleLibraryModalMenuIndex } = useRuleFormModalHook();

  const modalTitle = useMemo(() => {
    if (formMode === RuleFormMode.CREATE) return 'Create Rule';
    if (formMode === RuleFormMode.EDIT) return 'Edit Rule';
    if (formMode === RuleFormMode.CREATE_FROM_TEMPLATE) return 'Create Rule From Template';
  }, [formMode]);

  const handleNext = useCallback(() => {
    setRuleLibraryModalMenuIndex(menuIndex + 1);
  }, [menuIndex, setRuleLibraryModalMenuIndex]);

  const uiSteps: StepUiData[] = useMemo(() => {
    const steps = [];
    steps.push({
      title: menuItems[menuIndex],
      content: <RuleFormRecordType />,
      footer: <RuleFormRecordTypeFooter handleClose={handleCloseModal} handleNext={handleNext} />,
    });
    steps.push({
      title: menuItems[menuIndex],
      content: <RuleFormRuleDetails handleNext={handleNext} />,
      footer: <RuleFormRuleDetailsFooter handleClose={handleCloseModal} handleNext={handleNext} />,
    });
    if (ruleType === 'data-validation' && formMode == RuleFormMode.CREATE) {
      steps.push({
        title: menuItems[menuIndex],
        content: <RuleFormAdvFilter />,
        footer: <RuleFormAdvFilterFooter handleClose={handleCloseModal} handleNext={handleNext} />,
      });
    }
    steps.push({
      title: menuItems[menuIndex],
      content: <RuleFormConditions />,
      footer: <RuleFormConditionsFooter handleClose={handleCloseModal} handleNext={handleNext} />,
    });
    return steps;
  }, [formMode, handleCloseModal, handleNext, menuIndex, menuItems, ruleType]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={modalBoxStyle}>
            <Show when={loading == false}>
              <Grid
                container
                display={'flex'}
                direction={'row'}
                sx={{
                  backgroundColor: 'yellow',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Grid item xs={3} sx={{ height: '100%' }}>
                  <Box
                    sx={{
                      px: 1,
                      py: 2,
                      width: '100%',
                      height: '100%',
                      borderRadius: '8px',
                      backgroundColor: theme.palette.lightBg.main,
                    }}
                  >
                    <RuleFormMenu />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={9}
                  container
                  display={'flex'}
                  direction={'row'}
                  alignItems={'stretch'}
                  justifyContent={'stretch'}
                  sx={{ px: 6, pt: 3, pb: 1, width: '100%', height: '100%' }}
                >
                  <Grid
                    item
                    xs={12}
                    height={'9%'}
                    display={'flex'}
                    justifyContent={'space-between'}
                    sx={{ flexDirection: 'column' }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography variant="h2">
                        {uiSteps[menuIndex].title === 'Dataset (Optional)' ? 'Create Dataset' : modalTitle}
                      </Typography>
                      <IconButton onClick={handleCloseModal}>
                        <CrossIcon />
                      </IconButton>
                    </Box>
                    <div>
                      <Divider />
                      <br />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      height: '82%',
                      overflow: 'scroll',
                    }}
                    sx={hideScrollbarStyle}
                  >
                    <Box sx={{ px: 2, py: 1 }}>{uiSteps[menuIndex].content}</Box>
                  </Grid>
                  <Grid item xs={12} height={'9%'}>
                    <Divider sx={{ mb: 3 }} />
                    <Box>{uiSteps[menuIndex].footer}</Box>
                  </Grid>
                </Grid>
              </Grid>
            </Show>
            <Show when={loading != false}>
              <Box
                display={'flex'}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                }}
                flexDirection={'column'}
                gap={3}
              >
                <CircularProgress />
                <Typography>{loadingMessage}</Typography>
              </Box>
            </Show>
          </Box>
          <AIAssistant />
        </>
      </Modal>
    </>
  );
};
