import _ from 'lodash';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import {
  ConditionalValue,
  FieldReference,
  FunctionValue,
  LookUpTable,
  LookUpValue,
  PrimitiveValue,
  RegexValue,
  ServiceValue,
  SubQueryValue,
} from 'store/dataRaptorRule/dto/front-end-rule.dto';

export class DuplicateValidator {
  static _getPrimitiveValueHash(primitiveValue: PrimitiveValue) {
    const type = primitiveValue.type;
    const value = primitiveValue.value;
    return `${type}-${value}`;
  }

  static _getFieldReferenceValueHash(fieldReference: FieldReference) {
    const type = fieldReference.type;
    const value = fieldReference.value;
    return `${type}-${value}`;
  }

  static _getRegexValueHash(regexValue: RegexValue) {
    const type = regexValue.type;
    const fieldName = regexValue.fieldName;
    const validationPatternsCopy = _.cloneDeep(regexValue.validationPatterns);
    const expressionsIds = validationPatternsCopy?.sort().join('-');
    return `${type}-${fieldName}-${expressionsIds}`;
  }

  static _getServiceValueHash(serviceValue: ServiceValue) {
    const type = serviceValue.type;
    const fieldName = serviceValue.fieldName;
    const validation = serviceValue.validation;
    const property = serviceValue.property;
    return `${type}-${fieldName}-${validation}-${property}`;
  }

  static _getSubQueryValueHash(subQueryValue: SubQueryValue) {
    const type = subQueryValue.type;
    const alias = subQueryValue.subQueryId;
    const value = subQueryValue.value;
    return `${type}-${alias}-${value}`;
  }

  static _getFunctionValueHash(functionValue: FunctionValue) {
    const type = functionValue.type;
    const functionName = functionValue.function;
    const parameters: string = functionValue.value
      .map((condition) => DuplicateValidator.getConditionValueHash(condition))
      .join('#');
    return `${type}-${functionName}-${parameters}`;
  }

  static _getLookUpTableHash(lookUpTable: LookUpTable) {
    const { joinField, referenceJoinField, referenceTable, relationShipName, table, type } = lookUpTable;
    return `${type}-${joinField}-${referenceJoinField}-${referenceTable}-${relationShipName}-${table}`;
  }

  static _getLookUpValueHash(lookUpValue: LookUpValue) {
    const type = lookUpValue.type;
    const hash = lookUpValue.value
      .map((value: LookUpTable | FieldReference) => {
        if (value.type === RuleComponentType.LOOKUP_TABLE) {
          return DuplicateValidator._getLookUpTableHash(value as LookUpTable);
        }
        if (value.type === RuleComponentType.FIELD_REFERENCE) {
          return DuplicateValidator._getFieldReferenceValueHash(value as FieldReference);
        }
      })
      .join('#');
    return `${type}-${hash}`;
  }

  static getConditionValueHash(condition: ConditionalValue) {
    if (!condition) {
      return '';
    }
    if (condition.type === RuleComponentType.PRIMITIVE_VALUE) {
      return DuplicateValidator._getPrimitiveValueHash(condition as PrimitiveValue);
    } else if (condition.type === RuleComponentType.FIELD_REFERENCE) {
      return DuplicateValidator._getFieldReferenceValueHash(condition as FieldReference);
    } else if (condition.type === RuleComponentType.REGEX) {
      return DuplicateValidator._getRegexValueHash(condition as RegexValue);
    } else if (condition.type === RuleComponentType.SERVICE) {
      return DuplicateValidator._getServiceValueHash(condition as ServiceValue);
    } else if (condition.type === RuleComponentType.SUB_QUERY_VALUE) {
      return DuplicateValidator._getSubQueryValueHash(condition as SubQueryValue);
    } else if (condition.type === RuleComponentType.FUNCTION_VALUE) {
      return DuplicateValidator._getFunctionValueHash(condition as FunctionValue);
    } else if (condition.type === RuleComponentType.LOOKUP_VALUE) {
      return DuplicateValidator._getLookUpValueHash(condition as LookUpValue);
    }
    return '';
  }
}
