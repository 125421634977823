import { Box, SxProps, Theme } from '@mui/material';
import theme from 'core/theme';
import { FC } from 'react';

export interface VerticalDividerProps {
  height?: string;
  width?: string;
  color?: string;
  sx?: SxProps<Theme>;
}

const VerticalDivider: FC<VerticalDividerProps> = (props) => {
  const { color = theme.palette.grey[300], sx = {}, height = '16px', width = '2px' } = props;
  return <Box sx={{ height: height, borderLeft: `${width} solid ${color}`, ...sx }} />;
};

export default VerticalDivider;
