import { Box, CircularProgress, Typography } from '@mui/material';
import theme from 'core/theme';
import { getDeduplicationResultByIds } from 'http/deduplicationResult';
import _ from 'lodash';
import { FC, useCallback, useEffect, useState } from 'react';
import { DeduplicationResult } from 'store/deduplicationResult/types';
import { useGraphRender } from 'store/graphRender/hooks';
import { RecordReference } from 'store/graphRender/types';
import { useMigration } from 'store/migration/hooks';
import { getDuplicateRecordReferences } from '../../../utils/DuplicateUtils';

interface PotentialDuplicatesAlertProps {
  node: any;
}

export const PotentialDuplicatesAlert: FC<PotentialDuplicatesAlertProps> = (props) => {
  const { node } = props;

  const [duplicationResult, setDuplicationResult] = useState<DeduplicationResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDuplicated, setIsDuplicated] = useState<boolean>(false);

  const { setRecordsToMerge, setOpenMergeModal } = useGraphRender();

  const getIsMergeAbleDuplicationResult = (recordsToMerge: RecordReference[]) => {
    const tablesNameSet = new Set<string>();
    recordsToMerge.forEach((record) => {
      tablesNameSet.add(record.tableName);
    });
    if (tablesNameSet.size > 1) {
      const tableTypeCount: any = {};
      recordsToMerge.forEach((record) => {
        if (!tableTypeCount[record.tableName]) {
          tableTypeCount[record.tableName] = 0;
        }
        tableTypeCount[record.tableName] += 1;
      });
      let selectedTable = '';
      Object.keys(tableTypeCount).forEach((key) => {
        if (tableTypeCount[key] > 1 && key !== 'Opportunity') {
          selectedTable = key;
        }
      });
      if (_.isEmpty(selectedTable)) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  const {
    data: { migrationId },
  } = useMigration();
  const recordId = node.id;
  useEffect(() => {
    if (!_.isEmpty(migrationId) && !_.isEmpty(recordId)) {
      setLoading(true);
      getDeduplicationResultByIds(migrationId, { ids: [recordId] })
        .then((data) => {
          if (data.length > 0) {
            const recordToMerge = getDuplicateRecordReferences(data);
            const isValidToMerge = getIsMergeAbleDuplicationResult(recordToMerge);
            if (isValidToMerge) {
              setDuplicationResult(data);
              setIsDuplicated(true);
            } else {
              setIsDuplicated(false);
            }
          } else {
            setIsDuplicated(false);
          }
        })
        .catch(() => {
          setIsDuplicated(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [migrationId, recordId]);

  const handleOpenMergeModal = useCallback(() => {
    if (duplicationResult.length > 0) {
      const recordsToMergePayload: RecordReference[] = getDuplicateRecordReferences(duplicationResult);
      setOpenMergeModal(true);
      setRecordsToMerge(recordsToMergePayload);
    }
  }, [duplicationResult, setOpenMergeModal, setRecordsToMerge]);

  return (
    <>
      {loading === true && (
        <Box sx={{ textAlign: 'center', my: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }} gap={1}>
          <CircularProgress size={'20px'} />
          <Typography>Searching for duplicates...</Typography>
        </Box>
      )}

      {loading !== true && isDuplicated && (
        <Box
          sx={{
            backgroundColor: theme.palette.yellow.main,
            px: 1,
            py: 0.5,
            my: 1,
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <>
            <Box>
              <Typography variant="labelMedium12">Potential duplicates detected for this record.</Typography>
            </Box>
            <Box sx={{ ':hover': { cursor: 'pointer', textDecoration: 'underline' } }} onClick={handleOpenMergeModal}>
              <Typography variant="labelMedium12" sx={{ color: theme.palette.primary.main }}>
                Review
              </Typography>
            </Box>
          </>
        </Box>
      )}
    </>
  );
};

export default PotentialDuplicatesAlert;
