import { useSelector } from 'react-redux';

import { RootState } from 'store/types';
import { useActionCreator } from 'hooks';

import {
  setSelectedSubPage,
  postEmbedDDLSchema,
  deleteRuleTemporalTable,
  updateRulesByMigrationId,
  getAIPoweredRuleTemplate,
  getRuleTemplatesByDataSourceId,
  getAllRulesByMigrationId,
  setFilteredRules,
  setFilteredTemplates,
  setRenderedRule,
  setRenderedTemplates,
  setRuleTypeFilterAllowedValues,
  setRuleStatusFilterAllowedValues,
  setRuleDepartmentFilterAllowedValues,
  setTemplateTypeFilterAllowedValues,
  setTemplateDepartmentFilterAllowedValues,
  getAllTemporaryTableByMigrationId,
  setRuleTableFilterAllowedValues,
  setTemplateTableFilterAllowedValues,
  setRuleSortStrategy,
  setTemplateSortStrategy,
  addRuleTemporalTable,
  setFilteredTempTable,
  setRenderedTempTables,
} from './actions';
import { RuleLibraryHook, RuleLibraryState } from './types';

export const useRuleLibrary = (): RuleLibraryHook => {
  const data: RuleLibraryState = useSelector((state: RootState) => state.ruleLibrary);

  return {
    ...data,
    setSelectedSubPage: useActionCreator(setSelectedSubPage),

    addRuleTemporalTable: useActionCreator(addRuleTemporalTable),
    setFilteredTempTable: useActionCreator(setFilteredTempTable),
    setRenderedTempTables: useActionCreator(setRenderedTempTables),
    postEmbedDDLSchema: useActionCreator(postEmbedDDLSchema),
    deleteRuleTemporaltable: useActionCreator(deleteRuleTemporalTable),
    updateRulesByMigrationId: useActionCreator(updateRulesByMigrationId),

    getAIPoweredRuleTemplate: useActionCreator(getAIPoweredRuleTemplate),
    setRuleSortStrategy: useActionCreator(setRuleSortStrategy),
    setTemplateSortStrategy: useActionCreator(setTemplateSortStrategy),
    getAllTemporaryTableByMigrationId: useActionCreator(getAllTemporaryTableByMigrationId),
    getAllRulesByMigrationId: useActionCreator(getAllRulesByMigrationId),
    getRuleTemplatesByDataSourceId: useActionCreator(getRuleTemplatesByDataSourceId),

    setRenderedRule: useActionCreator(setRenderedRule),
    setRenderedTemplates: useActionCreator(setRenderedTemplates),

    setFilteredRules: useActionCreator(setFilteredRules),
    setFilteredTemplates: useActionCreator(setFilteredTemplates),

    setRuleTypeFilterAllowedValues: useActionCreator(setRuleTypeFilterAllowedValues),
    setRuleDepartmentFilterAllowedValues: useActionCreator(setRuleDepartmentFilterAllowedValues),
    setRuleTableFilterAllowedValues: useActionCreator(setRuleTableFilterAllowedValues),
    setTemplateTableFilterAllowedValues: useActionCreator(setTemplateTableFilterAllowedValues),
    setRuleStatusFilterAllowedValues: useActionCreator(setRuleStatusFilterAllowedValues),

    setTemplateTypeFilterAllowedValues: useActionCreator(setTemplateTypeFilterAllowedValues),
    setTemplateDepartmentFilterAllowedValues: useActionCreator(setTemplateDepartmentFilterAllowedValues),
  };
};
