import { FC, useMemo } from 'react';
import { Box, Typography } from '@mui/material';

import { CustomInput } from 'components/CustomInput';
import { ControlledCustomDropdown } from 'components/CustomDropdown';

import theme from 'core/theme';

import { useMigration } from 'store/migration/hooks';
import { useTemporalTableModalHook } from '../../../TemporalTableModal.hook';
import { LabelComponent } from 'pages/RuleLibrary/components/ui/LabelComponent';

const labelSx = { color: theme.palette.neutral.main };

const aliasToolTipText =
  'Name your Temp Table clearly and descriptively. For example, ‘Account without Website’ or ‘Account without revenue’';

export const TableSelection: FC = () => {
  const {
    data: { migratedTables },
  } = useMigration();

  const {
    handleNameChange,
    handleUpdateTable,
    data: { table, alias },
  } = useTemporalTableModalHook();

  const tableOptions = useMemo(
    () =>
      migratedTables.map((table) => ({
        label: table.table_name,
        value: table.table_name,
      })),
    [migratedTables],
  );

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <ControlledCustomDropdown
        label="Table"
        value={table}
        labelSx={labelSx}
        options={tableOptions || []}
        placeholder={'Select Table'}
        fallbackOption={{ label: 'Select Table', value: '' }}
        id={`create-temporal-table-table-dropdown`}
        onSelect={handleUpdateTable}
      />
      <Typography sx={{ color: theme.palette.neutral.darkGray }} variant="labelRegular12">
        Select the table from which you want to create the temporary table.
      </Typography>
      <CustomInput
        id={`create-temporal-table-alias-dropdown`}
        name={'alias'}
        labelComponent={<LabelComponent label={'Alias'} toolTipText={aliasToolTipText} />}
        value={alias}
        onChange={handleNameChange}
      />
    </Box>
  );
};
