import { Button, Grid, Typography } from '@mui/material';
import { GridItem } from 'components/ui';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { FC, useEffect } from 'react';
import { HavingRecordForm } from './HavingRecordForm';
import { useMigration } from 'store/migration/hooks';
import { LOOK_UP_FUNCTION_OPTIONS, OPERATOR_OPTIONS } from '../shared/Constants';
import { AddLogicalOperatorButton } from '../shared/AddLogicalOperatorButton';
import { LogicalOperatorFormRow } from '../shared/LogicalOperatorFormRow';
import { ConditionType } from '../shared/Constants';
import { RootCondition, LogicalOperator } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { RuleComponentType, RuleComponent } from 'store/dataRaptorRule/dto/Enums';

const HavingList: FC = () => {
  const { having, setFieldValue, selectedMigration, selectedTable, setFieldForeignRefOptions } = useRuleForm();
  const {
    data: { migrationTableForeignReferences },
  } = useMigration();
  const key = `${selectedMigration}-${selectedTable}`;

  const handleAddInitialHaving = () => {
    if (migrationTableForeignReferences[key]?.data?.length <= 0) return;
    // const initialReference = migrationTableForeignReferences[key].data[0];
    const initialHavingClause: RootCondition = {
      type: RuleComponentType.ROOT_CONDITIONAL,
      field: {
        type: RuleComponentType.FUNCTION_VALUE,
        function: LOOK_UP_FUNCTION_OPTIONS[0].value,
        numberOfParams: LOOK_UP_FUNCTION_OPTIONS[0].paramNumber,
        value: [],
      },
      operator: OPERATOR_OPTIONS[3].value,
      value: { type: RuleComponentType.PRIMITIVE_VALUE, value: 0 },
    };
    setFieldValue({ path: `having[0]`, value: initialHavingClause });
  };

  //Load External LookUps
  useEffect(() => {
    const key = `${selectedMigration}-${selectedTable}`;
    const fieldOptions = migrationTableForeignReferences[key]?.data?.map((foreignReference) => ({
      label: `${foreignReference.tableId}(${foreignReference.relationshipName})`,
      value: `${foreignReference.tableId}(${foreignReference.relationshipName})`,
    }));
    setFieldForeignRefOptions(fieldOptions);
  }, [migrationTableForeignReferences, selectedMigration, selectedTable, setFieldForeignRefOptions]);

  const hasHavingItems = having?.length > 0;

  return (
    <>
      <Grid container display="flex" justifyContent="center">
        <GridItem xs={6}>
          <Typography
            variant="subtitle2"
            component="label"
            margin={hasHavingItems ? 'auto' : 'auto 0px auto auto'}
            fontWeight={'bold'}
            display={'inline'}
          >
            And Having:
          </Typography>
        </GridItem>
        {!hasHavingItems && (
          <GridItem xs={6}>
            <Button
              variant="outlined"
              sx={{ width: 'fit-content', display: 'inline', marginRight: 'auto', marginLeft: '1em', my: 'auto' }}
              onClick={handleAddInitialHaving}
            >
              +
            </Button>
          </GridItem>
        )}
      </Grid>
      <Grid sx={{ width: '100%', mt: '1rem' }}>
        {migrationTableForeignReferences[key]?.data &&
          having?.map((havingValue: LogicalOperator | RuleComponent, index) => {
            const condition = havingValue as RootCondition;
            if (havingValue.type === RuleComponentType.ROOT_CONDITIONAL) {
              return (
                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  key={`rule-form-having-record-${index}`}
                  sx={{ my: 'auto' }}
                >
                  <>
                    <HavingRecordForm index={index} condition={condition} />
                  </>
                  <AddLogicalOperatorButton
                    index={index}
                    where={having}
                    type={ConditionType.HAVING}
                    wherePath={'having'}
                  />
                </Grid>
              );
            } else if (havingValue.type === RuleComponentType.LOGICAL_OPERATOR) {
              return (
                <>
                  <LogicalOperatorFormRow
                    key={`logical-operator-form-row-having-${index}`}
                    logicalOperatorRecord={havingValue as LogicalOperator}
                    targetPath={`having[${index}]`}
                  />
                </>
              );
            }
          })}
      </Grid>
    </>
  );
};

export { HavingList };
