import { Chip, Grid, Typography } from '@mui/material';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { FC, useCallback } from 'react';
import { TableField } from 'store/migration/types';

interface SegmentationFormProps {
  selectedSegmentationField: string[];
  setSelectedSegmentationField: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  migratedTableField: TableField[];
}

export const SegmentationForm: FC<SegmentationFormProps> = (props) => {
  const { selectedSegmentationField, setSelectedSegmentationField, migratedTableField } = props;

  const handleDeleteSegmentationField = useCallback(
    (field: string) => {
      setSelectedSegmentationField((prev) => {
        const set = new Set<string>(prev);
        set.delete(field);
        return Array.from(set);
      });
    },
    [setSelectedSegmentationField],
  );

  const handleAddSegmentationField = useCallback(
    (field: string) => {
      setSelectedSegmentationField((prev) => {
        const set = new Set<string>(prev);
        set.add(field);
        return Array.from(set);
      });
    },
    [setSelectedSegmentationField],
  );

  const getSegmentationFieldChipList = useCallback(() => {
    return selectedSegmentationField.map((field, index) => (
      <Chip
        key={`chip-segmentation-${field}-${index}`}
        label={field}
        variant="outlined"
        onDelete={() => handleDeleteSegmentationField(field)}
        sx={{ marginRight: '5px', marginBottom: '10px' }}
      />
    ));
  }, [handleDeleteSegmentationField, selectedSegmentationField]);

  return (
    <Grid container display={'flex'} gap={2}>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Typography variant="h3">Segmentation Fields</Typography>
      </Grid>
      <Grid item xs={12}>
        {getSegmentationFieldChipList()}
      </Grid>
      <Grid container justifyContent={'center'}>
        <Grid item xs={10} sm={8} md={4}>
          <ControlledCustomDropdown
            value={''}
            options={migratedTableField.map((field) => ({ label: field.fieldName, value: field.fieldName }))}
            fallbackOption={{ label: 'Add a Segmentation Field', value: '' }}
            placeholder={'Add a Segmentation Field'}
            id={'field-segmentation-input-add'}
            onSelect={handleAddSegmentationField}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
