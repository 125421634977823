import { Box, Grid } from '@mui/material';
import { FC } from 'react';
import { useRuleLibrary } from 'store/ruleLibrary/hook';
import { RuleTemplateCard } from './components/RuleTemplateCard';

const RuleTemplateList: FC = () => {
  const {
    subPages: { template },
  } = useRuleLibrary();

  const {
    data: { renderedTemplates },
  } = template;

  const rulesComponents = renderedTemplates?.map((template) => {
    return (
      <Grid item key={template.ruleTemplateId} md={3} sm={6} xs={12}>
        <RuleTemplateCard template={template} />
      </Grid>
    );
  });

  return (
    <Grid container spacing={1}>
      {rulesComponents}
    </Grid>
  );
};

export default RuleTemplateList;
