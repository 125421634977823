import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { Option } from '../shared/Constants';
import { FC } from 'react';

export interface FieldSelectorDropdownProps {
  value: string;
  fieldOptions: Option[];
  id: string;
  onChange: (value: string) => void;
}

export const FieldSelectorDropdown: FC<FieldSelectorDropdownProps> = (props: FieldSelectorDropdownProps) => {
  const { id, value, fieldOptions, onChange } = props;
  return (
    <ControlledCustomDropdown<string>
      id={id}
      placeholder="value"
      label="Field Name"
      value={value || ''}
      options={fieldOptions || []}
      sx={{ backgroundColor: 'neutral.white' }}
      fallbackOption={{ label: 'Select Value', value: '' }}
      onSelect={onChange}
    />
  );
};
