import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';

import WorkIcon from '@mui/icons-material/Work';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import TemplateCardWithPreview from './TemplateCardWithPreview';
import { useGraphSpace } from 'store/graphSpace/hooks';

const templates = [
  {
    title: 'Account View',
    image: 'https://i.ibb.co/MDHpSLz/account-view.png',
    previewImage: 'https://i.ibb.co/MDHpSLz/account-view.png',
    description: 'This is a description of Template 1.',
  },
  {
    title: 'Pipeline View',
    image: 'https://i.ibb.co/k5S4sKd/pipeline-view.png',
    previewImage: 'https://i.ibb.co/k5S4sKd/pipeline-view.png',
    description: 'This is a description of Template 2.',
  },
  {
    title: 'Duplicate View',
    image: 'https://i.ibb.co/ZfBps6H/duplicate-view.png',
    previewImage: 'https://i.ibb.co/ZfBps6H/duplicate-view.png"',
    description: 'This is a description of Template 3.',
  },
  {
    title: 'Customer Journey View',
    image: 'https://i.ibb.co/MDHpSLz/account-view.png',
    previewImage: 'https://i.ibb.co/MDHpSLz/account-view.png',
    description: 'This is a description of Template 4.',
  },
  {
    title: 'Object in Focus View',
    image: 'https://i.ibb.co/VC9MFjg/focus-view.png',
    previewImage: 'https://i.ibb.co/VC9MFjg/focus-view.png',
    description: 'This is a description of Template 5.',
  },
];

const templateTypes = [
  {
    title: 'Accounts',
    icon: <BusinessIcon fontSize="large" sx={{ color: 'primary.main' }} />,
    description: 'Businesses you work with',
  },
  {
    title: 'Contacts',
    icon: <PersonIcon fontSize="large" sx={{ color: 'primary.main' }} />,
    description: 'People in your network',
  },
  {
    title: 'Leads',
    icon: <EmojiPeopleIcon fontSize="large" sx={{ color: 'primary.main' }} />,
    description: 'Potential clients or customers',
  },
  {
    title: 'Opportunities',
    icon: <WorkIcon fontSize="large" sx={{ color: 'primary.main' }} />,
    description: 'Sales opportunities you are tracking',
  },
  {
    title: 'Cases',
    icon: <SupportAgentIcon fontSize="large" sx={{ color: 'primary.main' }} />,
    description: 'Customer service issues and tickets',
  },
];

const GraphSpaceTemplate: React.FC = () => {
  const { data, updateTemplate, updateObjectStart } = useGraphSpace();

  const { template, objectStart } = data.graphSpace;
  const handleTemplateSelect = (template: string) => {
    updateTemplate({ template });
  };

  const handleTypeSelect = (objectStart: string) => {
    updateObjectStart({ objectStart });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h2" component="h1" gutterBottom align="center" sx={{ my: 5 }}>
        Select Template
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {templates.map((t, index) => (
          <Grid item key={index} onClick={() => handleTemplateSelect(t.title)}>
            <TemplateCardWithPreview
              image={t.image}
              title={t.title}
              description={t.description}
              previewImage={t.previewImage}
              isSelected={template === t.title}
            />
          </Grid>
        ))}
      </Grid>
      {template && (
        <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h4" mb={1}>
            Select Object as Start Node
          </Typography>
          <Typography
            align="center"
            color="neutral.n400"
            variant="labelRegular12"
            sx={{
              mb: 3,
              width: '570px',
              display: 'block',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
            }}
          >
            The start node in a graph view is central node where paths or relationships begin, connecting to and
            arranging other related nodes or records around them.
          </Typography>

          <Grid container spacing={3} justifyContent="center">
            {templateTypes.map((type, index) => (
              <Grid item key={index}>
                <Card
                  onClick={() => handleTypeSelect(type.title)}
                  sx={{
                    width: 256,
                    height: 150,
                    display: 'flex',
                    cursor: 'pointer',
                    transition: '0.3s',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    '&:hover': {
                      border: '2px solid #1554FF',
                    },
                    border: objectStart === type.title ? '2px solid #1554FF' : '',
                  }}
                >
                  <CardContent sx={{ py: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {type.icon}
                    <Typography variant="labelMedium14">{type.title}</Typography>
                    <Typography variant="labelRegular12" color="neutral.n400">
                      {type.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default GraphSpaceTemplate;
