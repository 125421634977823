import { FC } from 'react';
import { Grid } from '@mui/material';

import { MenuItem } from './MenuItem';
import { useTemporalTableForm } from 'store/temporalTableForm/hooks';

export const TemplateTableMenu: FC = () => {
  const {
    data: { menuItems, menuIndex },
  } = useTemporalTableForm();
  return (
    <Grid sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
      {menuItems.map((menuItem, index) => (
        <MenuItem
          index={index}
          label={menuItem}
          currentIndex={menuIndex}
          active={menuIndex === index}
          key={`menu-item-template-table-modal-${index}`}
        />
      ))}
    </Grid>
  );
};
