import { Box, Button } from '@mui/material';
import { SecondaryButton } from 'components/ui';
import { useSnackbar } from 'notistack';
import { useRuleFormSubmissionHook } from 'pages/RuleLibrary/hooks/RuleFormSubmissionHook';
import { RuleFormMode } from 'pages/RuleLibrary/types';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDataRaptorRule } from 'store/dataRaptorRule/hooks';
import { useMigration } from 'store/migration/hooks';
import { useRuleLibrary } from 'store/ruleLibrary/hook';

export interface RuleFormConditionsFooterProps {
  handleClose: () => void;
  handleNext: () => void;
}

export const RuleFormConditionsFooter: FC<RuleFormConditionsFooterProps> = (props) => {
  const { handleClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const { handleCreateOrUpdateRule } = useRuleFormSubmissionHook();

  const {
    data: { formMode },
  } = useDataRaptorRule();

  const {
    data: { migrationId },
  } = useMigration();
  const { getAllRulesByMigrationId, getAllTemporaryTableByMigrationId } = useRuleLibrary();

  const showSnackBar = useCallback(
    (message?: string, variant = 'success') => {
      enqueueSnackbar(message || 'Please review the errors encountered', { variant: variant as any });
    },
    [enqueueSnackbar],
  );

  const handleSubmit = useCallback(() => {
    setLoading(true);
    handleCreateOrUpdateRule()
      .then(() => {
        getAllRulesByMigrationId({ migrationId });
        getAllTemporaryTableByMigrationId({ migrationId });
        showSnackBar('Rule Created Successfully', 'success');
        handleClose();
      })
      .catch((error) => {
        console.log('error', error.message);
        showSnackBar(error.message, 'error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    getAllRulesByMigrationId,
    getAllTemporaryTableByMigrationId,
    handleClose,
    handleCreateOrUpdateRule,
    migrationId,
    showSnackBar,
  ]);

  const buttonText = useMemo(() => {
    if (formMode === RuleFormMode.CREATE || formMode === RuleFormMode.CREATE_FROM_TEMPLATE)
      return loading ? 'Creating Rule...' : 'Create Rule';
    if (formMode === RuleFormMode.EDIT) return loading ? 'Updating Rule...' : 'Update Rule';
  }, [formMode, loading]);

  return (
    <Box
      display={'flex'}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <SecondaryButton variant="text" sx={{ color: 'black', ':hover': { color: 'black' } }} onClick={handleClose}>
        Cancel
      </SecondaryButton>
      <Button disabled={loading} variant="contained" onClick={handleSubmit}>
        {buttonText}
      </Button>
    </Box>
  );
};
