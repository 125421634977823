import React from 'react';
import EmailFooter from './Footers/EmailFooter';
import EventFooter from './Footers/EventFooter';
import NoteFooter from './Footers/NoteFooter';
import TaskFooter from './Footers/TaskFooter';

interface IProps {
  type: 'call' | 'meeting' | 'event' | 'email' | 'task' | 'note';
}
const Footer = ({ type }: IProps) => {
  if (type === 'email') return <EmailFooter />;
  if (['meeting', 'event'].includes(type)) return <EventFooter />;
  if (type === 'note') return <NoteFooter />;
  if (type === 'task') return <TaskFooter />;

  return <></>;
};

export default Footer;
