import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';
import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';
import { useCallback } from 'react';

import { Show } from 'components/show';
import { useGraphSpace } from 'store/graphSpace/hooks';

type ProgressActionsProps = {
  isComplete: boolean;
};

const ProgressActions: React.FC<ProgressActionsProps> = ({ isComplete }) => {
  const navigate = useNavigate();
  const { mergeDataSource } = useGraphSpace();

  const goToSalesTeamSpace = useCallback(() => {
    mergeDataSource({ continueMerge: true });
    navigate(PRIVATE_ABS_ROUTE_PATHS.salesTeamSpace);
  }, []);

  const goToGraphSpaceHome = useCallback(() => {
    mergeDataSource({ continueMerge: false });
    navigate(PRIVATE_ABS_ROUTE_PATHS.graphSpace);
  }, []);

  return (
    <Box gap={2} display="flex" alignContent="center" flexDirection="column">
      <Show when={isComplete}>
        <Button variant="contained" onClick={goToGraphSpaceHome}>
          View Graph Spaces
        </Button>
        <Typography color="primary.gray" align="center">
          Or
        </Typography>
      </Show>
      <Button
        variant="contained"
        disabled={!isComplete}
        onClick={goToSalesTeamSpace}
        sx={{ backgroundColor: 'neutral.white', border: '1px solid blue', color: 'primary.main' }}
      >
        Integrate New Data Source
      </Button>
    </Box>
  );
};

export default ProgressActions;
