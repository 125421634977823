import { ControlledCustomDropdown } from 'components/CustomDropdown';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { getLastLookUp } from 'pages/RuleForm/utils';
import { FC, useCallback, useEffect, useState } from 'react';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import {
  RootCondition,
  FieldReference,
  LookUpTable,
  LookUpValue,
  FunctionValue,
} from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { useMigration } from 'store/migration/hooks';
import { LOOK_UP_FUNCTION_OPTIONS, OPERATOR_OPTIONS, OperatorOption } from '../shared/Constants';

export interface OperatorDropdownProps {
  operator: string | null;
  condition: RootCondition;
  targetPath: string;
  tableName: string;
}

const OperatorDropdown: FC<OperatorDropdownProps> = (props) => {
  const { operator, condition, targetPath, tableName } = props;

  const { setFieldValue } = useRuleForm();
  const {
    data: { migrationTableFieldsAndLookUps, migrationId },
  } = useMigration();

  const { selectedMigration } = useRuleForm();

  const [operatorList, setOperatorList] = useState<OperatorOption[]>([]);

  const getOperatorByField = useCallback(
    (fieldName: string) => {
      if (!fieldName) {
        return [];
      }
      const key = `${migrationId}-${tableName}`;
      const field = migrationTableFieldsAndLookUps[key]?.data?.fields?.find(
        (field) => field?.fieldName?.trim() === fieldName?.trim(),
      );
      if (!field) return [];
      return OPERATOR_OPTIONS.filter(
        (operator) => operator.validType.includes(field.xsd_type) || operator.validType.includes('*'),
      );
    },
    [migrationId, migrationTableFieldsAndLookUps, tableName],
  );

  const getOperator = useCallback(() => {
    if (condition.field.type === RuleComponentType.PRIMITIVE_VALUE) {
      return OPERATOR_OPTIONS;
    }
    if (condition.field.type === RuleComponentType.FUNCTION_VALUE) {
      const functionValue = condition.field as FunctionValue;
      const functionName = functionValue.function;
      const functionOption = LOOK_UP_FUNCTION_OPTIONS.find((functionOption) => functionOption.value === functionName);
      const functionResultType = functionOption?.resultType || '';
      return OPERATOR_OPTIONS.filter(
        (operator) =>
          operator.validType.some((op) => functionResultType.includes(op)) || operator.validType.includes('*'),
      );
    }
    if (condition.field.type === RuleComponentType.LOOKUP_VALUE && (condition.field as LookUpValue).value.length > 0) {
      const lookUpComponents = (condition.field as LookUpValue).value;
      const nonEmptyLookup = lookUpComponents?.filter((lookup) => Object.keys(lookup).length > 0);
      const lookUpLength = nonEmptyLookup.length;
      const isLastItemLookUp = getLastLookUp(lookUpComponents);
      if (isLastItemLookUp) {
        return OPERATOR_OPTIONS.filter((operator) => operator.validType.includes('*'));
      }
      const lookUpObject = nonEmptyLookup[lookUpLength - 2] as LookUpTable;
      const fieldReference = nonEmptyLookup[lookUpLength - 1] as FieldReference;
      const referencedTable = lookUpObject.referenceTable;
      const key = `${selectedMigration}-${referencedTable}`;
      if (!migrationTableFieldsAndLookUps[key]?.data?.fields) return [];
      const originField = migrationTableFieldsAndLookUps[key].data.fields.find(
        (field) => field.fieldName === fieldReference.value,
      );
      if (!originField) return [];
      return OPERATOR_OPTIONS.filter(
        (operator) => operator.validType.includes(originField.xsd_type) || operator.validType.includes('*'),
      );
    } else {
      const field =
        condition.field.type === RuleComponentType.FIELD_REFERENCE ? (condition.field as FieldReference).value : '';
      return getOperatorByField(field);
    }
  }, [condition.field, getOperatorByField, migrationTableFieldsAndLookUps, selectedMigration]);

  useEffect(() => {
    const operatorsList = getOperator();
    if (operatorsList.length <= 0) {
      return setOperatorList([]);
    }
    const foundOption = operatorsList.find((option) => option.value === operator);
    setOperatorList(operatorsList);
    if (!foundOption && operatorsList.length > 0) {
      setFieldValue({ path: targetPath, value: operatorsList[0].value });
    }
  }, [getOperator, operator, setFieldValue, targetPath]);

  return (
    <>
      <ControlledCustomDropdown<string>
        id={targetPath}
        placeholder="Operator"
        value={operator}
        options={operatorList}
        sx={{ backgroundColor: 'neutral.white', width: '100%' }}
        onSelect={async (value) => setFieldValue({ path: targetPath, value: value })}
        fallbackOption={{ label: 'Select a Value', value: '' }}
      />
    </>
  );
};

export { OperatorDropdown };
