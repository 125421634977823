import { Divider, Menu, MenuItem } from '@mui/material';
import theme from 'core/theme';
import { FunctionValue } from 'http/migration/dto';
import { FC, useCallback, useMemo } from 'react';
import { useGraphRender } from 'store/graphRender/hooks';

interface CategorizeMenuProps {
  categorizeMenuAnchorEl: null | HTMLElement;
  handleCategorizeClose: () => void;
}

const selectedItemStyle = {
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '4px',
  color: theme.palette.primary.main,
};

export const CategorizeMenu: FC<CategorizeMenuProps> = (props) => {
  const { categorizeMenuAnchorEl, handleCategorizeClose } = props;
  const {
    data: {
      leadViewState: { groupByFields, groupByFieldsSelected, dateFields },
    },
    leadViewReducers: { setLeadViewGroupByFieldsSelected },
  } = useGraphRender();

  const firstFieldCategorizedBy = useMemo(() => groupByFieldsSelected[0], [groupByFieldsSelected]);
  const dateFieldSelected = useMemo(() => (groupByFieldsSelected[1] as FunctionValue).field, [groupByFieldsSelected]);

  const setFirstGroupByFieldSelected = useCallback(
    (field: string) => {
      const listCopy = [...groupByFieldsSelected];
      listCopy[0] = field;
      setLeadViewGroupByFieldsSelected(listCopy);
    },
    [groupByFieldsSelected, setLeadViewGroupByFieldsSelected],
  );

  const setFieldDate = useCallback(
    (field: string) => {
      const listCopy = [...groupByFieldsSelected];
      for (let i = 1; i < listCopy.length; i++) {
        if (typeof listCopy[i] === 'object') {
          const functionValue = listCopy[i] as FunctionValue;
          listCopy[i] = { ...functionValue, field: field } as FunctionValue;
        }
      }
      setLeadViewGroupByFieldsSelected(listCopy);
    },
    [groupByFieldsSelected, setLeadViewGroupByFieldsSelected],
  );

  const getDateFieldsMenuItem = useCallback(() => {
    return dateFields?.map((field) => {
      if (dateFieldSelected === field) {
        return (
          <MenuItem key={field} selected sx={selectedItemStyle}>
            {field}
          </MenuItem>
        );
      }
      return (
        <MenuItem key={field} onClick={() => setFieldDate(field)}>
          {field}
        </MenuItem>
      );
    });
  }, [dateFieldSelected, dateFields, setFieldDate]);

  const getPickListFieldsMenuItem = useCallback(() => {
    return groupByFields?.map((field) => {
      if (firstFieldCategorizedBy === field) {
        return (
          <MenuItem key={field} selected sx={selectedItemStyle}>
            {field}
          </MenuItem>
        );
      }
      return (
        <MenuItem key={field} onClick={() => setFirstGroupByFieldSelected(field)}>
          {field}
        </MenuItem>
      );
    });
  }, [firstFieldCategorizedBy, groupByFields, setFirstGroupByFieldSelected]);

  return (
    <Menu
      anchorEl={categorizeMenuAnchorEl}
      open={Boolean(categorizeMenuAnchorEl)}
      onClose={handleCategorizeClose}
      title="Categorize By:"
      elevation={2}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 0,
        horizontal: 0,
      }}
    >
      <MenuItem disabled>1st Field</MenuItem>
      {getPickListFieldsMenuItem()}
      <Divider sx={{ my: 0.5 }} />
      <MenuItem disabled>2nd Field</MenuItem>
      {getDateFieldsMenuItem()}
    </Menu>
  );
};
