import { Box, Button, Divider, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import theme from 'core/theme';
import { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import { RuleObjectTemplateRefType, RuleTemplate } from 'store/dataRaptorRule/types';
import { stringToDate } from 'core/utils';
import { useRuleLibrary } from 'store/ruleLibrary/hook';
import { useMigration } from 'store/migration/hooks';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { useRuleFormModalHook } from '../../RuleFormModal/RuleFormModal.hook';

export interface RuleTemplateCardProps {
  template: RuleTemplate;
}

const CHAR_LIMIT_ON_DESCRIPTION = 75;

const RuleTemplateCard: FC<RuleTemplateCardProps> = (props) => {
  const { getAIPoweredRuleTemplate, postEmbedDDLSchema } = useRuleLibrary();
  const {
    data: { migrationId },
  } = useMigration();
  const { setSelectedTable, setSelectedMigration, setRuleTemplate } = useRuleForm();
  const { openRuleFormModalForCreationFromTemplate } = useRuleFormModalHook();
  const { template } = props;
  const [descriptionRendered, setDescriptionRendered] = useState<string>('');
  const [hideDescription, setHideDescription] = useState<boolean>(true);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const numberOfFieldsInvolved = useMemo(() => {
    let counter = 0;
    template.ObjectReferences?.forEach((objectRef) => {
      if (objectRef.type === RuleObjectTemplateRefType.field) {
        counter++;
      }
    });
    return counter;
  }, [template.ObjectReferences]);

  const handleApplyTemplate = useCallback(() => {
    postEmbedDDLSchema({
      migrationId: migrationId,
      forceUpdate: false,
      onSuccess: () => {
        getAIPoweredRuleTemplate({
          migrationId: migrationId,
          templateId: template.ruleTemplateId,
          onSuccess: (response) => {
            setSelectedMigration(migrationId);
            setSelectedTable(response.table);
            setRuleTemplate(response.rule);
            setTimeout(() => openRuleFormModalForCreationFromTemplate(response.rule), 0);
          },
        });
      },
    });
  }, [
    getAIPoweredRuleTemplate,
    migrationId,
    openRuleFormModalForCreationFromTemplate,
    postEmbedDDLSchema,
    setRuleTemplate,
    setSelectedMigration,
    setSelectedTable,
    template.ruleTemplateId,
  ]);

  const handleMenuClose = () => {
    setMenuOpen(false);
    setTimeout(() => setAnchorEl(null), 0);
  };

  useEffect(() => {
    setDescriptionRendered(template.description.substring(0, CHAR_LIMIT_ON_DESCRIPTION));
  }, [template.description]);

  const handleSeeMoreOrLessDescription = useCallback(() => {
    if (hideDescription) {
      setDescriptionRendered(template.description);
    } else {
      setDescriptionRendered(template.description.substring(0, CHAR_LIMIT_ON_DESCRIPTION));
    }
    setHideDescription(!hideDescription);
  }, [hideDescription, template.description]);

  const shouldShowReadMoreOrLess = template.description.length > CHAR_LIMIT_ON_DESCRIPTION;

  const getMenuButton = (ruleId: string) => {
    return (
      <Box display={'flex'} justifyContent={'end'}>
        <IconButton
          color="primary"
          id={`menu|button|ruleLibrary|${ruleId}`}
          // onClick={}
          sx={{
            ':hover': {
              stroke: theme.palette.primary.main,
            },
          }}
        >
          <DotsIcon />
        </IconButton>
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'white',
          width: '100%',
          display: 'flex',
          borderRadius: '4px',
          alignItems: 'center',
          p: '8px',
          justifyContent: 'space-between',
        }}
      >
        <Grid container display={'flex'} flexDirection={'column'}>
          <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Grid item xs={6}>
              <Typography
                variant="p12"
                sx={{
                  backgroundColor: template.DepartmentObject?.color || theme.palette.neutral.n400,
                  px: 1.2,
                  py: 0.5,
                  borderRadius: '4px',
                }}
              >
                {template.DepartmentObject?.label}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {getMenuButton(template.ruleTemplateId)}
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }}></Divider>
          <Grid container display={'flex'} flexDirection={'column'}>
            <Grid item>
              <Typography variant="labelMedium14" sx={{ fontWeight: 'bold' }}>
                {template.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="p12" sx={{ color: theme.palette.neutral.n400 }}>
                {descriptionRendered + ' '}
              </Typography>
              {shouldShowReadMoreOrLess && (
                <Typography
                  display={'inline'}
                  variant="labelRegular12"
                  sx={{ color: theme.palette.primary.main, textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={handleSeeMoreOrLessDescription}
                >
                  {hideDescription ? 'See more' : 'See less'}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }}></Divider>
          <Grid container display={'flex'} flexDirection={'column'}>
            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant="p12" sx={{ color: theme.palette.neutral.n400 }}>
                  Table:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="p12">{template.table}</Typography>
              </Grid>
            </Grid>
            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant="p12" sx={{ color: theme.palette.neutral.n400 }}>
                  Type:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="p12">{template.type}</Typography>
              </Grid>
            </Grid>
            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant="p12" sx={{ color: theme.palette.neutral.n400 }}>
                  Number of Fields Involved:
                </Typography>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    height: '24px',
                    width: '24px',
                    borderRadius: '40px',
                    display: 'flex',
                    p: '4px',
                    gap: '10px',
                    backgroundColor: theme.palette.primary.subtone2,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    variant="p12"
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: '12px',
                    }}
                  >
                    {numberOfFieldsInvolved}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant="p12" sx={{ color: theme.palette.neutral.n400 }}>
                  Last Updated:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="p12">{stringToDate(template.updatedAt)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider sx={{ my: 1 }}></Divider>
          <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'} gap={1}>
            <Grid item flex={1}>
              <Button sx={{ width: '100%' }} variant="outlined">
                Details
              </Button>
            </Grid>
            <Grid item flex={1}>
              <Button sx={{ width: '100%', p: 1 }} variant="contained" onClick={() => handleApplyTemplate()}>
                Apply Template
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem>
          <Typography variant="p14" sx={{ color: 'neutral.main' }}>
            Placeholder
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export { RuleTemplateCard };
