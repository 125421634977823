import { Box } from '@mui/material';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { FC, useEffect } from 'react';
import { AnomalyWhereList } from './components/AnomalyWhereList';

export const AnomalyDetection: FC = () => {
  const {
    selectedTable,
    selectedMigration,
    fieldOptions,
    setFieldOptions,
    where,
    setWhere,
    getFieldOptionsByMigrationAndTable,
  } = useRuleForm();

  useEffect(() => {
    const typeFilter = ['phone', 'email'];
    const options = getFieldOptionsByMigrationAndTable(selectedMigration, selectedTable, false);
    if (options.length > 0) {
      setFieldOptions(options.filter((option) => typeFilter.includes(option.type || '')));
    }
  }, [getFieldOptionsByMigrationAndTable, selectedMigration, selectedTable, setFieldOptions]);

  return (
    <>
      {fieldOptions && (
        <Box sx={{ width: '100%', marginBottom: '1rem' }}>
          <AnomalyWhereList
            wherePath={'where'}
            where={where}
            setWhere={setWhere}
            fieldOptions={fieldOptions}
            tableName={selectedTable}
          />
        </Box>
      )}
    </>
  );
};
