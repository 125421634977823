import useGeneralViewEffects from './hooks/ViewEffects/useGeneralView.effects';
import useAccountViewEffects from './hooks/ViewEffects/useAccountView.effects';
import useLeadViewEffects from './hooks/ViewEffects/LeadView/useLeadView.effects';
import useDuplicateViewEffects from './hooks/ViewEffects/useDuplicateView.effects';
import { useGraphRender } from 'store/graphRender/hooks';
import { useGraphData } from 'store/graphData/hooks';
import { useReactFlow } from 'reactflow';
import { useMigration } from 'store/migration/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDataGraphUtils } from './hooks/utils/useDataGraphUtils';
import { useComponentsEffects } from './hooks/ViewEffects/useComponents.effects';

export const useGraphRenderHook = (containerRef: React.MutableRefObject<HTMLObjectElement | undefined>) => {
  const location = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [paramsProcessed, setParamsProcessed] = useState<any>({});
  const reactFlow = useReactFlow();
  const { findRecordRelatedAccount } = useDataGraphUtils();

  const {
    data: {
      selectedNode,
      selectedNodeFromSearchBar,
      showDuplicationSnackBar,
      showNeptuneStatusModal,
      nodeHashMap,
      showCreateActivityModal,
      AccountPanelView: { open: showAccountRecordPanel },
      leadViewState: {
        sortPanelState: { open: showLeadViewSortPanel },
      },
    },
    error,
    loading,
    setShowDuplicationSnackBar,
    setSelectedNode,
    setSearchTerm,
    setShowRecordPanel,
    setShowSearchDetail,
    setSchemaName,
    setShowCreateActivityModal,
    leadViewReducers: { setLeadViewSortPanelShow },
  } = useGraphRender();

  const {
    data: { migrationId },
    setMigrationId,
  } = useMigration();

  useEffect(() => {
    const nodeId = searchParams.get('nodeId');
    const migrationId = searchParams.get('migrationId');
    const searchTerm = searchParams.get('searchTerm');
    const schemaName = searchParams.get('schemaName');
    if (nodeId) {
      const node = nodeHashMap[nodeId];
      if (node && paramsProcessed['nodeId'] == null) {
        setSelectedNode(node);
        setParamsProcessed((prevState: any) => ({ ...prevState, nodeId: true }));
        setShowRecordPanel(true);
        setTimeout(() => {
          let zoomNodeId = nodeId;
          const accountId = findRecordRelatedAccount(nodeId);
          if (accountId) {
            zoomNodeId = accountId;
          }
          reactFlow.fitView({ nodes: [{ id: zoomNodeId }], padding: 4, duration: 500 });
        }, 2000);
      }
    }
    if (migrationId && paramsProcessed['migrationId'] == null) {
      setMigrationId(migrationId);
      setParamsProcessed((prevState: any) => ({ ...prevState, migrationId: true }));
    }
    if (schemaName && paramsProcessed['schemaName'] == null) {
      setSchemaName(schemaName);
      setParamsProcessed((prevState: any) => ({ ...prevState, schemaName: true }));
    }
    if (searchTerm && paramsProcessed['searchTerm'] == null) {
      setSearchTerm(searchTerm);
      setParamsProcessed((prevState: any) => ({ ...prevState, searchTerm: true }));
      setTimeout(() => {
        setShowSearchDetail(false);
      }, 0);
    }
  }, [
    findRecordRelatedAccount,
    nodeHashMap,
    paramsProcessed,
    reactFlow,
    searchParams,
    setMigrationId,
    setSchemaName,
    setSearchTerm,
    setSelectedNode,
    setShowRecordPanel,
    setShowSearchDetail,
  ]);

  // Graph Hooks Declarations
  useGeneralViewEffects(containerRef);
  useAccountViewEffects();
  useComponentsEffects();
  useDuplicateViewEffects();
  useLeadViewEffects();
  // End of Graph Hooks Declarations

  const { migrationStatus: neptuneMigrationStatus } = useGraphData();

  return {
    setShowDuplicationSnackBar,
    setShowRecordPanel,
    setShowCreateActivityModal,
    setLeadViewSortPanelShow,
    showLeadViewSortPanel,
    showAccountRecordPanel,
    neptuneMigrationStatus,
    selectedNode,
    selectedNodeFromSearchBar,
    showDuplicationSnackBar,
    showNeptuneStatusModal,
    error,
    loading,
    migrationId,
    showCreateActivityModal,
  };
};
