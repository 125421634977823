import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { FC, useMemo } from 'react';
import { ANOMALY_VALIDATION_OPTIONS } from '../shared/Constants';
import _ from 'lodash';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { FormErrorMessage } from 'pages/RuleLibrary/components/ui/FormErrorMessage';
import { Box, InputAdornment } from '@mui/material';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorExclamation.svg';

export interface ValidationTypeDropdownProps {
  currentValue: string;
  targetPath: string;
  onChange: (value: string) => void;
}

export const ValidationTypeDropdown: FC<ValidationTypeDropdownProps> = (props: ValidationTypeDropdownProps) => {
  const { currentValue, targetPath, onChange } = props;
  const { formError } = useRuleForm();

  const error = useMemo(() => formError && _.get(formError, targetPath), [formError, targetPath]);

  return (
    <Box>
      <ControlledCustomDropdown<string>
        id={`${targetPath}.value`}
        label=""
        placeholder="value"
        value={currentValue || ''}
        options={ANOMALY_VALIDATION_OPTIONS || []}
        sx={{ backgroundColor: 'neutral.white' }}
        fallbackOption={{ label: 'Select Validation Type', value: '' }}
        onSelect={onChange}
        InputProps={{
          startAdornment: error && <InputAdornment position="start">{<ErrorIcon />}</InputAdornment>,
        }}
      />
      <FormErrorMessage errorMessage={error} />
    </Box>
  );
};
