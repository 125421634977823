import { FC, useMemo } from 'react';
import { Grid } from '@mui/material';

import { MenuItem } from './MenuItem';
import { useRuleFormStore } from 'store/ruleForm/hook';
import { RuleFormModalCreateMenuItem } from 'store/ruleForm/types';
import { useDataRaptorRule } from 'store/dataRaptorRule/hooks';
import { RuleFormMode } from 'pages/RuleLibrary/types';

export const RuleFormMenu: FC = () => {
  const {
    ruleFormLibraryModal: { menuItems, menuIndex },
    category: ruleType,
  } = useRuleFormStore();

  const {
    data: { formMode },
  } = useDataRaptorRule();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = useMemo(() => {
    if (ruleType === 'data-validation' && formMode == RuleFormMode.CREATE) {
      return menuItems;
    } else {
      return [
        RuleFormModalCreateMenuItem.RecordType,
        RuleFormModalCreateMenuItem.RuleDetails,
        RuleFormModalCreateMenuItem.Conditions,
      ];
    }
  }, [formMode, menuItems, ruleType]);

  return (
    <Grid sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
      {items.map((menuItem, index) => (
        <MenuItem
          index={index}
          label={menuItem}
          currentIndex={menuIndex}
          active={menuIndex === index}
          key={`menu-item-template-table-modal-${index}`}
        />
      ))}
    </Grid>
  );
};
