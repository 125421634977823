import { useCallback, useMemo } from 'react';
import { BaseEdge, EdgeProps, useReactFlow } from 'reactflow';

const defaultRadius = 160;

export function WhiteBackgroundEdge({ source, target, style, data, id }: EdgeProps) {
  const reactFlow = useReactFlow();
  const sourceNode = reactFlow.getNode(source);

  const radius = useMemo(() => {
    if (sourceNode != null && sourceNode.width) {
      if (sourceNode.type === 'AccountNode') {
        return sourceNode.width * 1.6;
      }
      if (sourceNode.data.bridge === true) {
        return sourceNode.width * 2.5;
      }
      return sourceNode.width * 3.5;
    }
    return defaultRadius;
  }, [sourceNode]);

  const { width: sourceWidth, height: sourceHeight } = sourceNode || { width: 0, height: 0 };

  const sourceNodePosition = sourceNode?.position || { x: 0, y: 0 };

  const sourceCenterNodePosition = useMemo(
    () => [sourceNodePosition.x + (sourceWidth || 0) / 2, sourceNodePosition.y + (sourceHeight || 0) / 2],
    [sourceHeight, sourceNodePosition.x, sourceNodePosition.y, sourceWidth],
  );

  const getFillColor = useCallback(() => {
    if (!sourceNode || sourceNode?.hidden) {
      return 'transparent';
    }
    return 'white';
  }, [sourceNode]);

  if (!sourceNode) {
    return null;
  }

  return (
    <>
      <BaseEdge
        interactionWidth={0}
        path={`m -${radius} 0 a 0 0 0 1 0 0 0 a ${radius} ${radius} 0 1 0 0 -1 Z`}
        markerEnd={undefined}
        style={{
          fill: getFillColor(),
          stroke: getFillColor(),
          zIndex: 10,
          translate: `${sourceCenterNodePosition[0]}px ${sourceCenterNodePosition[1]}px`,
        }}
      ></BaseEdge>
    </>
  );
}
