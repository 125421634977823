import React, { useState, useCallback } from 'react';
import { Box, Typography, Paper, List, ListItem, ListItemIcon, ListItemText, Avatar } from '@mui/material';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const ItemType = 'SOURCE_OPTION';

interface SourceOption {
  priority: number;
  icon: string;
  title: string;
}

const SourcePriorityOptions: React.FC = () => {
  const [sourceOptions, setSourceOptions] = useState<SourceOption[]>([
    { priority: 1, icon: 'https://cdn-icons-png.flaticon.com/128/5968/5968914.png', title: 'Salesforce' },
    {
      priority: 2,
      icon: 'https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png',
      title: 'LinkedIn',
    },
  ]);

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setSourceOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      const [movedItem] = updatedOptions.splice(dragIndex, 1);
      updatedOptions.splice(hoverIndex, 0, movedItem);

      return updatedOptions.map((option, index) => ({
        ...option,
        priority: index + 1,
      }));
    });
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
        <Typography variant="p12" color="neutral.n400" my={2}>
          Drag sources to assign importance.
        </Typography>

        <List sx={{ width: '100%' }}>
          {sourceOptions
            .sort((a, b) => a.priority - b.priority)
            .map((option, index) => (
              <DraggableItem key={option.priority} index={index} option={option} moveItem={moveItem} />
            ))}
        </List>
      </Box>
    </DndProvider>
  );
};

interface DraggableItemProps {
  option: SourceOption;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
}

const DraggableItem: React.FC<DraggableItemProps> = ({ option, index, moveItem }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const dragDropRef = (node: HTMLDivElement | null) => {
    ref(node);
    drop(node);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0.5, alignItems: 'center' }}>
      <Avatar sx={{ bgcolor: 'primary.main', width: 24, height: 24, fontSize: '12px' }}>{option.priority}</Avatar>
      <Typography color="primary.main">-</Typography>
      <ListItem
        ref={dragDropRef}
        sx={{
          m: 1,
          borderRadius: 1,
          width: '400px',
          height: '48px',
          backgroundColor: 'neutral.white',
          outline: '1px dashed #1554FF',
          outlineOffset: '4px',
        }}
        component={Paper}
        elevation={0}
      >
        <ListItemIcon>
          <img src={option.icon} alt={option.title} style={{ width: 32, height: 32 }} />
        </ListItemIcon>
        <ListItemText primary={option.title} />
      </ListItem>
    </Box>
  );
};

export default SourcePriorityOptions;
