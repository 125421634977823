import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import format from 'date-fns/format';
import { parseEmailText } from '../../utils/parseEmailText';

interface InteractionCardProps {
  type: 'email' | 'call';
  activity: any;
  node: any;
}
const InteractionCard = ({ type, activity, node }: InteractionCardProps) => {
  const { ActivityDate, Description } = activity;
  const { Name } = node;

  const emailData = React.useMemo(() => {
    if (!Description) return null;
    const data = parseEmailText(Description);
    return data;
  }, [Description]);
  return (
    <Stack direction="row">
      <Box sx={{ width: '1px', border: '1px solid #00000020', mx: 3, position: 'relative' }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            borderRadius: '100%',
            width: '30px',
            height: '30px',
            bgcolor: 'violet.main',
            position: 'absolute',
            top: -1,
            zIndex: 5,
            transform: 'translateX(-50%)',
          }}
        >
          {type === 'call' ? (
            <CallIcon sx={{ fontSize: '16px', color: 'white' }} />
          ) : (
            <EmailOutlinedIcon sx={{ fontSize: '16px', color: 'white' }} />
          )}
        </Stack>
      </Box>
      <Stack spacing={1.5} sx={{ border: '1px solid #00000020', backgroundColor: 'white', flex: '1' }} p={1}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" gap={'5px'} alignItems="baseline">
            <Typography variant="labelMedium14">{Name}</Typography>
            <Typography variant="p12" color="neutral.n400">
              {type === 'call' ? 'Accepted the call' : 'Replied to email'}
            </Typography>
          </Stack>

          <Typography variant="p12" color="neutral.n400">
            {ActivityDate ? format(new Date(ActivityDate), 'dd MMM, hh:mm aa') : null}
          </Typography>
        </Stack>
        <Stack gap={0.5} sx={{ bgcolor: '#00000005', p: 1 }}>
          {type === 'email' && emailData && (
            <>
              {emailData?.to && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <AccountCircleOutlinedIcon />
                  <Typography variant="labelBold14">{emailData.to}</Typography>
                </Stack>
              )}
              {emailData?.subject && <Typography variant="labelMedium14">{emailData.subject}</Typography>}
              {emailData?.body && <Typography variant="caption">{emailData?.body}</Typography>}
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default InteractionCard;
