import { GridItem } from 'components/ui';
import { OperatorDropdown } from '../shared/OperatorDropdown';
import { DeleteComponentButton } from '../shared/DeleteComponentButton';
import { FC, useMemo } from 'react';
import { RuleWhereArray, RootCondition } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { ConditionalValueInput } from '../shared/ConditionalValueInput/ConditionalValueInput';
import { ConditionType } from 'pages/RuleLibrary/types';
import { CONDITIONAL_VALUE_OPTIONS, FUNCTION_ENUM, OPERATOR_OPTIONS } from '../shared/Constants';
import { Option } from 'store/ruleForm/types';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { Box } from '@mui/material';
import theme from 'core/theme';
import { VerticalDivider } from 'components/VerticalDivider';
import _ from 'lodash';
import { FormErrorMessage } from '../../../../ui/FormErrorMessage';

export interface ConditionalRecordFormProps {
  index: number;
  field: string;
  conditionComponent: RootCondition;
  conditionPath: string;
  where: RuleWhereArray;
  setWhere: (where: { value: RuleWhereArray }) => void;
  conditionType: ConditionType;
  fieldOptions: Option[];
  tableName: string;
}

const ConditionalRecordForm: FC<ConditionalRecordFormProps> = (props) => {
  const { getXmlTypeFilterFromValueAndOperator, formError } = useRuleForm();
  const { index, conditionComponent, where, setWhere, conditionPath, fieldOptions, tableName, conditionType } = props;
  const operator = conditionComponent?.operator || null;
  const allowedFunctions = [FUNCTION_ENUM.DAYS_SINCE];

  const firstFieldValidValueType = CONDITIONAL_VALUE_OPTIONS.map((option) => option.value)?.filter(
    (option) => option != RuleComponentType.SUB_QUERY_VALUE && option != RuleComponentType.PRIMITIVE_VALUE,
  );

  const secondFieldValidValueType = useMemo(() => {
    let secondFieldValidValueType = CONDITIONAL_VALUE_OPTIONS.map((option) => option.value);
    const operatorOption = OPERATOR_OPTIONS.find((option) => option.value === operator);

    if (operatorOption) {
      secondFieldValidValueType = operatorOption.rightValueType;
    }

    return secondFieldValidValueType;
  }, [operator]);

  const conditionError = useMemo(
    () => formError && _.get(formError, `${conditionPath}.condition.error`),
    [formError, conditionPath],
  );

  const xmlTypeFilter = getXmlTypeFilterFromValueAndOperator(conditionComponent.field, operator || '', tableName);

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      position={'relative'}
      gap={0.5}
      sx={{
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
        backgroundColor: theme.palette.lightBg.main,
        p: 1,
      }}
    >
      <FormErrorMessage errorMessage={conditionError} />
      <GridItem container display={'flex'} alignItems={'center'} xs={12}>
        <GridItem width={'100%'}>
          <ConditionalValueInput
            targetPath={`${conditionPath}.field`}
            rootConditionalPath={conditionPath}
            conditionalValueComponent={conditionComponent.field}
            conditionType={ConditionType.WHERE}
            index={index}
            allowedValueTypes={firstFieldValidValueType}
            allowedValueFunctions={allowedFunctions}
            fieldOptions={fieldOptions}
            tableName={tableName}
          />
        </GridItem>
      </GridItem>

      <VerticalDivider height="20px" sx={{ marginLeft: 2 }} />

      <GridItem xs={4}>
        <OperatorDropdown
          conditionType={conditionType}
          operator={operator}
          condition={where[index] as RootCondition}
          targetPath={`${conditionPath}.operator`}
          tableName={tableName}
        />
      </GridItem>

      <VerticalDivider height="20px" sx={{ marginLeft: 2 }} />

      <GridItem container display={'flex'} alignItems={'center'} xs={12}>
        <GridItem width={'100%'}>
          <ConditionalValueInput
            targetPath={`${conditionPath}.value`}
            rootConditionalPath={conditionPath}
            previousOperator={operator}
            previousValueComponent={conditionComponent.field}
            conditionalValueComponent={conditionComponent.value}
            conditionType={ConditionType.WHERE}
            index={index}
            allowedValueTypes={secondFieldValidValueType}
            allowedValueFunctions={allowedFunctions}
            fieldOptions={fieldOptions}
            xmlFilterType={xmlTypeFilter || []}
            tableName={tableName}
          />
        </GridItem>
      </GridItem>
      {index !== 0 && (
        <Box sx={{ position: 'absolute', top: '-12px', right: '-10px' }}>
          <DeleteComponentButton index={index} where={where} setWhere={setWhere} />
        </Box>
      )}
    </Box>
  );
};

export { ConditionalRecordForm };
