import { CreateDeduplicationConfigRequest, DeduplicationConfig } from 'store/deduplicationConfig/types';
import { apiCall } from '../index';

export const createDeduplicationConfig = (data: CreateDeduplicationConfigRequest): Promise<DeduplicationConfig> =>
  apiCall({ method: 'post', url: '/deduplication-config', data });

export const updateDeduplicationConfig = (
  id: string,
  data: CreateDeduplicationConfigRequest,
): Promise<DeduplicationConfig> => apiCall({ method: 'put', url: `/deduplication-config/${id}`, data });

export const getDeduplicationConfigByMigration = (migrationId: string): Promise<DeduplicationConfig[]> =>
  apiCall({ method: 'get', url: `/deduplication-config/migration/${migrationId}` });

export const getDeduplicationConfigByMigrationAndTable = (
  migrationId: string,
  table: string,
): Promise<DeduplicationConfig> =>
  apiCall({ method: 'get', url: `deduplication-config/migration/${migrationId}/table/${table}` });

export const deleteDeduplicationConfigById = (migrationId: string, id: string): Promise<null> =>
  apiCall({ method: 'delete', url: `deduplication-config/migration/${migrationId}/id/${id}` });
