import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, InputAdornment, SxProps, Theme, Typography } from '@mui/material';
import theme from 'core/theme';
import Icon from 'assets/icons/RuleTableObject.png';
import { useRuleFormModalHook } from '../../../RuleFormModal.hook';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { useDataRaptorRule } from 'store/dataRaptorRule/hooks';
import { RuleFormMode } from 'pages/RuleLibrary/types';
import { useRuleFormStore } from 'store/ruleForm/hook';
import { ReactComponent as AISuggestionIcon } from 'assets/icons/AIChat/aiSuggestion.svg';

const labelSx: SxProps<Theme> = {
  fontWeight: 'bold',
  color: theme.palette.neutral.main,
};

export const RuleFormRecordType: FC = () => {
  const [previouslySuggestedTable, setPreviouslySuggestedTable] = useState<string | null>(null);
  const {
    data: { formMode },
  } = useDataRaptorRule();
  const {
    setSelectedTable,
    data: { selectedTable, tableOptions },
  } = useRuleFormModalHook();
  const {
    aiAssistant: { suggestions },
  } = useRuleFormStore();

  const handleUpdateTable = useCallback(
    (value: string) => {
      if (!value || value.trim().length == 0) return;
      setSelectedTable(value);
    },
    [setSelectedTable],
  );

  const tableSuggestion = useMemo(() => suggestions?.table, [suggestions]);
  const valueIsEqualAsAISuggestion = useMemo(() => tableSuggestion === selectedTable, [tableSuggestion, selectedTable]);

  useEffect(() => {
    if (tableSuggestion && tableSuggestion !== previouslySuggestedTable) {
      setPreviouslySuggestedTable(tableSuggestion);
      handleUpdateTable(tableSuggestion);
    }
  }, [handleUpdateTable, previouslySuggestedTable, tableSuggestion]);

  const disabled = useMemo(() => formMode !== RuleFormMode.CREATE, [formMode]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Typography sx={{ color: theme.palette.neutral.darkGray }} variant="labelRegular12">
        Select the table in which you want to create a rule
      </Typography>
      <ControlledCustomDropdown
        label="Record Type"
        labelSx={labelSx}
        value={selectedTable}
        options={tableOptions || []}
        disabled={disabled}
        fallbackOption={{ label: 'Select Table', value: '' }}
        placeholder={'Select Table'}
        id={`RuleFormRecordType-table-name-picker`}
        onSelect={handleUpdateTable}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {valueIsEqualAsAISuggestion && <AISuggestionIcon />}
              {!valueIsEqualAsAISuggestion && (
                <>
                  <img src={Icon} alt="Rule Table Object" />
                </>
              )}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
