import { FC, Fragment, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { DataHealthTab } from './components/DataHealthTab/DataHealth/DataHealthTab/DataHealthTab';

const tabs = [
  { label: 'Data Health', component: DataHealthTab },
  // { label: 'Sales Pipeline', component: SalesPipelineTab },
  // { label: 'New Business', component: NewBusinessTab },
  // { label: 'Expansion Revenue', component: ExpansionRevenueTab },
];

const TabButtonGroup: FC<{ currentTab: number; handleTab: (tabIndex: number) => () => void }> = ({
  currentTab,
  handleTab,
}) => (
  <ButtonGroup fullWidth size="small" disableElevation variant="outlined" sx={{ m: 0, p: 0 }}>
    {tabs.map((tab, index) => (
      <Button key={index} onClick={handleTab(index)} variant={currentTab === index ? 'contained' : 'outlined'}>
        {tab.label}
      </Button>
    ))}
  </ButtonGroup>
);

export const DashboardPageTabs: FC = () => {
  const [tab, setTab] = useState(0);
  const CurrentTab = tabs[tab].component;

  const handleTab = (tabIndex: number) => () => {
    if (tabIndex !== tab) setTab(tabIndex);
  };

  return (
    <Fragment>
      <TabButtonGroup currentTab={tab} handleTab={handleTab} />
      <Box mt={2}>
        <CurrentTab />
      </Box>
    </Fragment>
  );
};
