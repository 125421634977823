import { getRecordGroupCount } from 'http/migration';
import { useGraphRender } from 'store/graphRender/hooks';
import { useMigration } from 'store/migration/hooks';
import { useCallback } from 'react';
import { FunctionValue } from 'http/migration/dto';
import { BridgePathMapByRecordId } from 'store/graphRender/types';
import { useReactFlow } from 'reactflow';
import { useGraphRenderLayoutUtils } from '../../utils/useGraphRenderLayoutUtils';

export const useLeadViewFunctions = () => {
  const {
    data: { migrationId },
  } = useMigration();
  const { onHide } = useGraphRenderLayoutUtils();
  const { getNode } = useReactFlow();
  const {
    data: {
      leadViewState: { groupByFieldsSelected, expandSearchNodes },
    },
    leadViewReducers: { setLeadViewSetExpandSearchNodes },
  } = useGraphRender();

  const processExpandSearchNodes = useCallback(() => {
    if (Object.keys(expandSearchNodes).length > 0) {
      const tmpMap = { ...expandSearchNodes };
      console.log('processExpandSearchNodes');
      const recordIds = Object.keys(expandSearchNodes);
      for (const id of recordIds) {
        const currentPathIds = tmpMap[id];
        for (const pathId of currentPathIds) {
          const node = getNode(pathId);
          if (node && node.data && node.data._collapsed !== false) {
            console.log('Expanding', pathId);
            onHide(pathId, false, { desiredEdgedLength: 25 });
            tmpMap[id] = currentPathIds.filter((path) => path !== pathId);
            continue;
          } else {
            // Delete pathId
            tmpMap[id] = currentPathIds.filter((path) => path !== pathId);
          }
        }
        if (tmpMap[id].length === 0) {
          delete tmpMap[id];
        }
      }
      setLeadViewSetExpandSearchNodes(tmpMap);
    }
  }, [expandSearchNodes, getNode, onHide, setLeadViewSetExpandSearchNodes]);

  const getSearchPathToOpen = useCallback(
    async (recordIds: string[]): Promise<BridgePathMapByRecordId> => {
      if (recordIds.length === 0) {
        return {};
      }
      let recordIdsArrayFormatted = recordIds.map((id) => `'${id}'`).join(',');
      recordIdsArrayFormatted = `(${recordIdsArrayFormatted})`;
      const condition = { field: 'Id', operator: 'IN', value: recordIdsArrayFormatted };
      const res = await getRecordGroupCount(migrationId, 'Lead', {
        groupBy: ['Id', ...groupByFieldsSelected],
        conditions: [condition],
      });
      if (res.length === 0) {
        return {};
      }
      const resIndexByRecordId = res.reduce((acc: any, record: any) => {
        acc[record.Id] = record;
        return acc;
      }, {});
      const baseBridgeLeadPath = `bridge-lead-view`;

      // Calculate each possible bridge node id based on the configured group field or functions
      recordIds.forEach((recordId) => {
        const bridgeIdsPath: string[] = [];
        let bridgeIds = `${baseBridgeLeadPath}-${resIndexByRecordId[recordId]?.[groupByFieldsSelected[0] as string]}`;
        bridgeIdsPath.push(bridgeIds);
        for (let i = 1; i < groupByFieldsSelected.length; i++) {
          bridgeIds = `${bridgeIds}-#-${
            resIndexByRecordId[recordId]?.[(groupByFieldsSelected[i] as FunctionValue).label || 'null']
          }`;
          bridgeIdsPath.push(bridgeIds);
        }
        resIndexByRecordId[recordId].path = bridgeIdsPath;
      });

      const pathById: BridgePathMapByRecordId = {};
      Object.keys(resIndexByRecordId).forEach((recordId) => {
        pathById[recordId] = resIndexByRecordId[recordId].path;
      });
      return pathById;
    },
    [groupByFieldsSelected, migrationId],
  );

  return { getSearchPathToOpen, processExpandSearchNodes };
};
