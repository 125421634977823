import ELK from 'elkjs/lib/elk.bundled.js';

const elk = new ELK();

export const getLayoutedElements = (nodes: any, edges: any, layoutOptions: any = {}) => {
  const graph = {
    id: 'root',
    children: nodes.map((node: any) => {
      const nodeWidth = parseInt(node.style?.width || 75);
      const nodeHeight = parseInt(node.style?.height || 75);
      return {
        ...node,
        width: nodeWidth,
        height: nodeHeight,
      };
    }),
    edges: edges,
  };

  return elk
    .layout(graph, { layoutOptions })
    .then((layoutedGraph: any) => ({
      nodes: layoutedGraph.children.map((node: any) => ({
        ...node,
        position: { x: node.x, y: node.y },
      })),
      edges: layoutedGraph.edges,
    }))
    .catch(console.error);
};
