import { Grid, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { Show } from 'components/show';
import { useGraphSpace } from 'store/graphSpace/hooks';

import { WorkspaceCard } from './components/WorkspaceCard';

export default function HomeGraphSpace() {
  const { data } = useGraphSpace();

  const activeProjects = data.graphSpaces.filter((p) => !p.isArchived);
  const archivedProjects = data.graphSpaces.filter((p) => p.isArchived);

  return (
    <Grid container p={4} spacing={4}>
      <Show when={true}>
        {activeProjects.map((gs, index) => (
          <Grid item xs={3} key={index}>
            <Link to={'/d/graph-space/single-graph-space'}>
              <WorkspaceCard integratedGraphSpace={gs} />
            </Link>
          </Grid>
        ))}
      </Show>
      <Show when={!!archivedProjects.length}>
        <Grid item xs={12}>
          <Typography variant="h4" color={'primary.gray'} mb={1}>
            {' '}
            ARCHIVED
          </Typography>
          <Divider />
        </Grid>
        {archivedProjects.map((gs, index) => (
          <Grid item xs={3} key={index}>
            <WorkspaceCard integratedGraphSpace={gs} />
          </Grid>
        ))}
      </Show>
    </Grid>
  );
}
