import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { DataGrid, GridColumns, GridRowParams } from '@mui/x-data-grid';
import { Box, Chip, IconButton, LinearProgress, Tooltip, Typography } from '@mui/material';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import { ColumnSortedAscendingIcon, ColumnSortedDescendingIcon, ColumnUnsortedIcon } from 'pages/DataRaptor/ui';
import { DataAnomalyTable } from './components/DataAnomalyTable';
import { getConfidenceScoreColor } from 'core/utils';
import { useDashboard } from 'store/dashboard/hook';
import { RecordStatScoreDiff, TableStatType } from 'http/migration/dto';
import { useLazyGetRecordStatsScoreDiffQuery } from 'store/migration/api';
import { Show } from '../../../../../../../../../../../../../components/show';
import { LoadingComponent } from 'pages/RuleLibrary/ui';
import { convertISOStringToLabelInterval } from '../../../TimeLinearChart/hooks/TableStats';
import theme from 'core/theme';
import { MigrationTable } from 'store/migration/types';
import _ from 'lodash';
import { NameCell } from './components/NameCell';
import { DataAnomalyTableTitle } from './components/DataAnomalyTableTitle';
import { CallMade, CallReceived } from '@mui/icons-material';

const getScoreDiffColor = (score: number) => {
  if (score >= 0) {
    return theme.palette.green.main;
  }
  return theme.palette.red.main;
};

const getOverAllScore = (score: number, tableInfo?: MigrationTable) => {
  const multiplier = score >= 0 ? 1 : -1;
  if (tableInfo) {
    return _.round(
      ((Math.abs(score) * 100) / (tableInfo.row_count * 100)) * multiplier,
      tableInfo.row_count.toString().length,
    );
  }
  return 0;
};

const createColumns = (
  width: number,
  [previousMonth, currentMonth]: [string, string],
  currentTable: MigrationTable,
): GridColumns<RecordStatScoreDiff> => [
  {
    field: 'Id',
    headerName: 'Id',
    width: 0.3 * width,
    renderCell: ({ row }) => <NameCell recordId={row.record_id} table={currentTable.table_name} />,
  },
  {
    field: 'score1',
    headerName: previousMonth,
    width: 0.2 * width,
    renderCell: ({ row }) => (
      <Box position="relative" display="inline-flex" width="100%">
        <LinearProgress
          variant="determinate"
          value={row.score1}
          sx={{
            borderRadius: '8px',
            width: '100%',
            height: '24px',
            backgroundColor: '#F6F8FB',
            '& .MuiLinearProgress-bar': {
              backgroundColor: getConfidenceScoreColor(row.score1),
            },
          }}
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          bottom={0}
          right={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography sx={{ fontSize: '10px' }}>{`Average ${row.score1}%`}</Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: 'score2',
    headerName: `vs ${currentMonth}`,
    width: 0.2 * width,
    align: 'center',
    renderCell: ({ row }) => (
      <Chip
        size="small"
        label={row.score2}
        sx={{
          color: 'white',
          backgroundColor: getConfidenceScoreColor(row.score2),
        }}
      />
    ),
  },
  {
    field: 'score_diff',
    headerName: 'Impact on Overall Score',
    width: 0.2 * width,
    align: 'center',
    renderCell: ({ row }) => {
      let Icon;
      const overAllScore = getOverAllScore(row.score_diff, currentTable);
      if (overAllScore >= 0) {
        Icon = <CallMade fontSize="small" color="success" />;
      } else {
        Icon = <CallReceived fontSize="small" color="error" />;
      }
      return (
        <Box display={'flex'} alignItems={'center'}>
          {Icon}
          <Chip
            size="small"
            label={`${overAllScore}%`}
            sx={{
              color: 'white',
              backgroundColor: getScoreDiffColor(row.score_diff),
            }}
          />
        </Box>
      );
    },
  },
  {
    field: 'actions',
    headerName: '',
    width: 0.1 * width,
    align: 'center',
    disableColumnMenu: true,
    renderCell: () => (
      <Tooltip title={`View anomalies`}>
        <IconButton>
          <VisibilityOutlinedIcon fontSize="small" htmlColor="#1554FF" />
        </IconButton>
      </Tooltip>
    ),
  },
];

export type ConfidenceScoreBreakdownTableProps = {
  pushTable?: (title: JSX.Element, table: JSX.Element) => void;
};

export const ConfidenceScoreBreakdownTable: FC<ConfidenceScoreBreakdownTableProps> = ({ pushTable }) => {
  const {
    data: {
      selectedMigrationId,
      migrationTableInfo,
      dataQualityTimeline: { selectedPoint, interval },
    },
  } = useDashboard();
  const ref = useRef<HTMLDivElement>(null);
  const [columns, setColumns] = useState<GridColumns<RecordStatScoreDiff>>([]);
  const [intervalLabels, setIntervalLabels] = useState<[string, string]>(['', '']);
  const [dataRows, setDataRows] = useState<RecordStatScoreDiff[]>([]);
  const [currentTable, setCurrentTable] = useState<MigrationTable>();
  const [getRecordStatsScoreDiffQuery, statsData] = useLazyGetRecordStatsScoreDiffQuery();
  const prevTableRef = useRef<MigrationTable>();

  useEffect(() => {
    const selectedPointLabel = convertISOStringToLabelInterval((selectedPoint?.data as any).date, interval);
    const currentDateLabel = convertISOStringToLabelInterval(new Date().toISOString(), interval);
    setIntervalLabels([selectedPointLabel, currentDateLabel]);
  }, [interval, selectedPoint?.data]);

  useEffect(() => {
    if (selectedPoint) {
      const table = migrationTableInfo.find((table) => table.table_name === selectedPoint.serieId);
      if (table && prevTableRef.current?.table_name != table?.table_name) {
        setCurrentTable(table);
        prevTableRef.current = table;
      }
    }
  }, [migrationTableInfo, selectedPoint]);

  useEffect(() => {
    if (selectedPoint) {
      getRecordStatsScoreDiffQuery(
        {
          migrationId: selectedMigrationId,
          tableId: selectedPoint.serieId as string,
          type: TableStatType.SCORE,
          interval: interval,
          startDate1: (selectedPoint.data as any).date.split('T')[0],
          startDate2: new Date().toISOString().split('T')[0] as any,
        },
        true,
      );
    }
  }, [getRecordStatsScoreDiffQuery, interval, selectedMigrationId, selectedPoint]);

  useEffect(() => {
    if (statsData.isSuccess && !statsData.isFetching && statsData.data) {
      setDataRows(statsData.data);
    }
  }, [statsData.data, statsData.isFetching, statsData.isSuccess]);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current && currentTable) {
        setColumns(createColumns(ref.current.clientWidth, intervalLabels, currentTable));
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [intervalLabels, currentTable]);

  const handleSelectRowRecord = useCallback(
    (row: GridRowParams<RecordStatScoreDiff>) => {
      console.log('currenTable', currentTable);
      if (!currentTable) return;
      pushTable?.(
        <DataAnomalyTableTitle
          migrationId={selectedMigrationId}
          table={currentTable.table_name}
          recordId={row.row.record_id}
        />,
        <DataAnomalyTable row={row.row} tableName={currentTable.table_name} />,
      );
    },
    [currentTable, pushTable, selectedMigrationId],
  );

  return (
    <Box ref={ref} width="100%" height="100%" display={'flex'} alignContent={'center'}>
      <Show when={!statsData.isFetching && statsData.isSuccess} fallback={<LoadingComponent />}>
        <DataGrid
          disableColumnFilter
          disableColumnSelector
          disableSelectionOnClick
          rows={dataRows}
          columns={columns}
          rowHeight={49}
          headerHeight={30}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowId={(row) => row.record_id}
          onRowClick={handleSelectRowRecord}
          density="comfortable"
          components={{
            ColumnUnsortedIcon,
            ColumnSortedAscendingIcon,
            ColumnSortedDescendingIcon,
          }}
        />
      </Show>
    </Box>
  );
};
