import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  reset,
  setAssignTo,
  setDueDate,
  setNotes,
  setStatus,
  setSubject,
  setTaskType,
  setAIDetail,
  setRequestDetails,
  setRequestRecord,
  setRequestTableName,
  setRequestRecordId,
} from './actions';
import { CreateTaskFormState } from './types';
import _ from 'lodash';

export const initialState: CreateTaskFormState = {
  data: {
    subject: undefined,
    notes: undefined,
    dueDate: undefined,
    taskType: undefined,
    assignTo: undefined,
    status: undefined,
    aiDetail: undefined,
  },
  requestData: {
    details: undefined,
    record: undefined,
    tableName: undefined,
    recordId: '',
  },
};

const createTaskFormReducer = createSlice({
  name: 'create-task-form',
  initialState: _.cloneDeep(initialState),
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<CreateTaskFormState>): void => {
    builder.addCase(setRequestDetails, (state, { payload }) => {
      state.requestData.details = payload;
    });
    builder.addCase(setRequestRecordId, (state, { payload }) => {
      state.requestData.recordId = payload;
    });
    builder.addCase(setRequestRecord, (state, { payload }) => {
      state.requestData.record = payload;
    });
    builder.addCase(setRequestTableName, (state, { payload }) => {
      state.requestData.tableName = payload;
    });
    builder.addCase(setAssignTo, (state, { payload }) => {
      state.data.assignTo = payload;
    });
    builder.addCase(setDueDate, (state, { payload }) => {
      state.data.dueDate = payload;
    });
    builder.addCase(setNotes, (state, { payload }) => {
      state.data.notes = payload;
    });
    builder.addCase(setStatus, (state, { payload }) => {
      state.data.status = payload;
    });
    builder.addCase(setSubject, (state, { payload }) => {
      state.data.subject = payload;
    });
    builder.addCase(setTaskType, (state, { payload }) => {
      state.data.taskType = payload;
    });
    builder.addCase(setAIDetail, (state, { payload }) => {
      state.data.aiDetail = payload;
    });
    builder.addCase(reset, (state) => {
      state.data = _.cloneDeep(initialState.data);
    });
  },
});

export default createTaskFormReducer.reducer;
