import { Grid } from '@mui/material';
import { FC } from 'react';
import { AccountQuickFilter } from './components/AccountQuickFilter';
import { OpportunityQuickFilter } from './components/OpportunityQuickFilter';
import { ContactQuickFilter } from './components/ContactQuickFilter';
import { LeadQuickFilter } from './components/LeadQuickFilter';
import { useGraphRender } from 'store/graphRender/hooks';
import { GraphRenderView } from 'store/graphRender/types';
import { DuplicateQuickFilter } from './components/DuplicateQuickFilter';

export const QuickFilterPanel: FC = () => {
  const {
    data: { view },
  } = useGraphRender();

  return (
    <>
      <Grid display={'flex'} flexDirection={'column'} gap={1.5}>
        {view === GraphRenderView.ACCOUNT && (
          <>
            <AccountQuickFilter />
            <OpportunityQuickFilter />
            <ContactQuickFilter />
            <LeadQuickFilter />
            {/* <EventQuickFilter /> */}
          </>
        )}
        {view === GraphRenderView.DUPLICATES && <DuplicateQuickFilter />}
      </Grid>
    </>
  );
};
