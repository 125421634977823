import { Box, CircularProgress, TableCell, Typography } from '@mui/material';
import theme from 'core/theme';
import { FC } from 'react';
import { DeduplicationResult } from 'store/deduplicationResult/types';

interface MergeTableHeaderProps {
  records: any[];
  deduplicationResult: DeduplicationResult[];
  loadingAiRecommendation: boolean;
}

export const MergeTableHeader: FC<MergeTableHeaderProps> = (props) => {
  const { records, deduplicationResult, loadingAiRecommendation } = props;

  const getMasterRecordHeader = () => {
    return (
      <TableCell
        key={'duplicate-merge-master-record-header'}
        sx={{ backgroundColor: theme.palette.neutral.lightGray, verticalAlign: 'top' }}
      >
        <Box>
          <Typography variant="b12">Potential Master Record</Typography>
        </Box>
      </TableCell>
    );
  };

  const getDuplicateRecordHeader = (duplicateNumber: number, duplicationScore: number) => {
    return (
      <TableCell
        key={`duplicate-duplicate-${duplicateNumber}-header`}
        sx={{ backgroundColor: theme.palette.neutral.lightGray, verticalAlign: 'top' }}
      >
        <Box>
          <Typography variant="b12">Potential Duplicate {duplicateNumber}</Typography>
          <br />
          <Typography sx={{ fontWeight: 'bold' }} variant="labelRegular10">
            {`matching score ${duplicationScore}%`.toUpperCase()}
          </Typography>
        </Box>
      </TableCell>
    );
  };

  const getSmartMergeHeader = () => {
    return (
      <TableCell
        key={'duplicate-smart-merge-record-header'}
        sx={{
          backgroundColor: theme.palette.green.subtone1,
          verticalAlign: 'top',
          borderStartEndRadius: '4px',
        }}
      >
        <Box sx={{ backgroundColor: theme.palette.green.subtone1 }}>
          <Typography variant="b12">Smart Merge</Typography>
          <br />
          {loadingAiRecommendation === true ? (
            <>
              <Box display={'flex'} flexDirection={'column'} gap={1}>
                <CircularProgress sx={{ mx: 'auto' }} size={'20px'} />
                <Typography sx={{ fontWeight: 'bold' }} variant="labelRegular10">
                  {'Analyzing best merge values...'.toUpperCase()}
                </Typography>
              </Box>
            </>
          ) : (
            <Typography sx={{ fontWeight: 'bold' }} variant="labelRegular10">
              {'based on our analysis, these values are the most accurate and up-to-date.'.toUpperCase()}
            </Typography>
          )}
        </Box>
      </TableCell>
    );
  };

  const generateHeaders = (records: any) => {
    const headers = records.map((record: any, index: number) => {
      if (index === 0) {
        return getMasterRecordHeader();
      } else {
        const duplicationScore =
          deduplicationResult.find((result) => result.record_a_id === record.Id || result.record_b_id === record.Id)
            ?.duplication_score || 0;
        return getDuplicateRecordHeader(index, duplicationScore);
      }
    });
    headers.push(getSmartMergeHeader());
    return headers;
  };

  return (
    <>
      <TableCell
        sx={{
          backgroundColor: theme.palette.neutral.lightGray,
          borderStartStartRadius: '4px',
          borderEndStartRadius: '4px',
        }}
      ></TableCell>
      {generateHeaders(records)}
    </>
  );
};
