import { DataRaptorRule } from 'store/dataRaptorRule/types';
import { FC, useState, useCallback, useMemo } from 'react';
import { Box, CircularProgress, IconButton, MenuItem, Typography, Menu } from '@mui/material';
import theme from 'core/theme';
import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import { useMigration } from 'store/migration/hooks';
import { useDataRaptor } from 'store/dataRaptor/hooks';
import { useRuleLibrary } from 'store/ruleLibrary/hook';
import { useDataRaptorRule } from 'store/dataRaptorRule/hooks';
import { useRuleFormModalHook } from '../../RuleFormModal/RuleFormModal.hook';
import { useSnackbar } from 'notistack';

interface MenuProps {
  rule: DataRaptorRule;
}

export const RuleMenu: FC<MenuProps> = (props) => {
  const { rule } = props;
  const ruleId = rule.ruleId;
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { setSelectedRuleId } = useDataRaptorRule();
  const { setSelectedTable, setSelectedMigration } = useDataRaptor();
  const { updateRulesByMigrationId } = useRuleLibrary();
  const { openRuleFormModalForUpdate } = useRuleFormModalHook();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    data: { migrationId },
  } = useMigration();

  const { updateRuleById, deleteRuleById, updateRuleByIdLoading, deleteRuleByIdLoading } = useDataRaptorRule();
  const snackBar = useSnackbar();

  const handleActivationRule = () => {
    updateRuleById({
      ruleId: rule.ruleId,
      data: { active: !(rule.active || false) },
      onSuccess: () => {
        updateRulesByMigrationId({ migrationId });
        snackBar.enqueueSnackbar('Rule updated successfully', { variant: 'success' });
      },
      onError: () => {
        snackBar.enqueueSnackbar('Error updating rule', { variant: 'error' });
      },
    });
  };

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
    setTimeout(() => setMenuOpen(true), 0);
  };

  const handleDeleteRule = useCallback(() => {
    deleteRuleById({
      ruleId: rule.ruleId,
      onSuccess: () => {
        updateRulesByMigrationId({ migrationId });
        snackBar.enqueueSnackbar('Rule deleted successfully', { variant: 'success' });
      },
      onError: () => {
        snackBar.enqueueSnackbar('Error deleting rule', { variant: 'error' });
      },
    });
  }, [deleteRuleById, migrationId, rule.ruleId, snackBar, updateRulesByMigrationId]);

  const handleMenuEdit = useCallback(() => {
    setSelectedRuleId(rule.ruleId);
    setSelectedTable(rule.table);
    setSelectedMigration(migrationId);
    openRuleFormModalForUpdate(migrationId, rule.ruleId);
  }, [
    migrationId,
    openRuleFormModalForUpdate,
    rule.ruleId,
    rule.table,
    setSelectedMigration,
    setSelectedRuleId,
    setSelectedTable,
  ]);

  const handleMenuClose = () => {
    setMenuOpen(false);
    setTimeout(() => setAnchorEl(null), 0);
  };

  const transactionInProgress = useMemo(() => {
    return updateRuleByIdLoading || deleteRuleByIdLoading;
  }, [deleteRuleByIdLoading, updateRuleByIdLoading]);

  return (
    <>
      <Box display={'flex'} justifyContent={'end'}>
        <IconButton
          color="primary"
          id={`menu|button|ruleLibrary|${ruleId}`}
          onClick={handleMenuOpen}
          sx={{
            ':hover': {
              stroke: theme.palette.primary.main,
            },
          }}
        >
          <DotsIcon />
        </IconButton>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleActivationRule} disabled={transactionInProgress}>
          {updateRuleByIdLoading === true && <CircularProgress size={'15px'} sx={{ mr: '5px' }} />}
          <Typography variant="p14" sx={{ color: 'neutral.main' }}>
            {rule.active ? 'Deactivate' : 'Activate'}
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleMenuEdit} disabled={transactionInProgress}>
          <Typography variant="p14" sx={{ color: 'blue.main' }}>
            Edit
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleDeleteRule} disabled={transactionInProgress}>
          {deleteRuleByIdLoading === true && <CircularProgress size={'15px'} color="error" sx={{ mr: '5px' }} />}
          <Typography variant="p14" sx={{ color: 'red.main' }}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
