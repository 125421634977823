import { FC } from 'react';

import { Grid } from '@mui/material';

import { RuleCard } from './components/RuleCard';
import { RuleLibraryTable } from './components/RuleLibraryTable';

import { useRuleLibrary } from 'store/ruleLibrary/hook';

const RuleLibraryList: FC = () => {
  const {
    subPages: { library },
  } = useRuleLibrary();
  const {
    data: { renderedRules, gridView },
  } = library;

  return !gridView ? (
    <RuleLibraryTable library={library} />
  ) : (
    <Grid container spacing={1}>
      {renderedRules?.map((rule) => {
        return (
          <Grid key={rule.ruleId} item md={3} sm={6} xs={12}>
            <RuleCard rule={rule} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default RuleLibraryList;
