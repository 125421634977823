import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { LOOK_UP_FUNCTION_OPTIONS, OPERATOR_OPTIONS } from './Constants';
import { useMigration } from 'store/migration/hooks';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { ConditionType } from 'pages/RuleForm/types';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { RootCondition, LogicalOperator, RuleWhereArray } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import theme from 'core/theme';

type AddLogicalOperatorButtonProps = {
  index: number;
  where: RuleWhereArray;
  type: ConditionType;
  wherePath: string;
  setFieldFunction: (param: { path: string; value: any }) => void;
};

const AddLogicalOperatorButton: FC<AddLogicalOperatorButtonProps> = (props) => {
  const { index, where, type, wherePath, setFieldFunction } = props;
  const {
    data: { migrationTableForeignReferences },
  } = useMigration();
  const { selectedMigration, selectedTable } = useRuleForm();
  const key = `${selectedMigration}-${selectedTable}`;

  const addLogicalOperator = (index: number) => {
    const logicalOperatorIndex = index + 1;
    const conditionIndex = index + 2;

    const initialLogicalOperator: LogicalOperator = {
      type: RuleComponentType.LOGICAL_OPERATOR,
      value: 'AND',
    };

    let initialCondition: RootCondition;
    if (type === ConditionType.WHERE) {
      initialCondition = {
        field: { type: RuleComponentType.FIELD_REFERENCE, value: '' },
        operator: '',
        value: { type: RuleComponentType.FIELD_REFERENCE, value: '' },
        type: RuleComponentType.ROOT_CONDITIONAL,
      };
    } else {
      if (migrationTableForeignReferences[key]?.data?.length <= 0) return;
      initialCondition = {
        type: RuleComponentType.ROOT_CONDITIONAL,
        field: {
          type: RuleComponentType.FUNCTION_VALUE,
          function: LOOK_UP_FUNCTION_OPTIONS[0].value,
          numberOfParams: LOOK_UP_FUNCTION_OPTIONS[0].paramNumber,
          value: [],
        },
        operator: OPERATOR_OPTIONS[3].value,
        value: { type: RuleComponentType.PRIMITIVE_VALUE, value: 0 },
      } as any;
    }
    setFieldFunction({ path: `${wherePath}[${logicalOperatorIndex}]`, value: initialLogicalOperator });
    setFieldFunction({ path: `${wherePath}[${conditionIndex}]`, value: initialCondition });
  };

  return (
    <>
      {!where[index + 1] && (
        <Grid container display="flex" justifyContent="flex-start" sx={{ my: 'auto' }}>
          <Box
            onClick={addLogicalOperator.bind(this, index)}
            sx={{
              height: '100%',
              ':hover': {
                cursor: 'pointer',
              },
            }}
          >
            <Typography sx={{ mt: '0.5rem', color: theme.palette.primary.main }} variant="p14">
              + Add &quot;or/and&quot;
            </Typography>
          </Box>
        </Grid>
      )}
    </>
  );
};

export { AddLogicalOperatorButton };
