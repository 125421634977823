import React from 'react';
import { Divider, Stack, Typography, Menu, MenuItem, Checkbox } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReplyIcon from '@mui/icons-material/Reply';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

const TaskFooter = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  return (
    <Stack sx={{ height: '30px' }} direction="row" alignItems="center" justifyContent="center" gap={0.5} py={0.5}>
      <Stack direction="row" alignItems="center" gap={0.5} sx={{ cursor: 'pointer', mx: 1 }}>
        <Checkbox sx={{ color: 'primary.main' }} />
        <Typography variant="p16" color="primary.main">
          Mark completed
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={0.5} sx={{ cursor: 'pointer', mx: 1 }}>
        <EditRoundedIcon sx={{ fontSize: '16px', color: 'primary.main' }} />
        <Typography variant="p14" color="primary.main">
          Edit
        </Typography>
      </Stack>

      <Divider orientation="vertical" />

      <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
        sx={{ cursor: 'pointer', mx: 1 }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <DragIndicatorIcon sx={{ color: 'primary.main', rotate: '90deg' }} />
        <Typography variant="p14" color="primary.main">
          More
        </Typography>
      </Stack>

      <Menu open={!!anchorEl} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        <MenuItem>
          <Stack direction="row" alignItems="center" gap={1}>
            <DeleteOutlineRoundedIcon sx={{ fontSize: '14px' }} />
            <Typography variant="p12">Delete</Typography>
          </Stack>
        </MenuItem>
        <MenuItem>
          <Stack direction="row" alignItems="center" gap={1}>
            <ContentCopyIcon sx={{ fontSize: '14px', rotate: '180deg' }} />
            <Typography variant="p12">Duplicate</Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </Stack>
  );
};

export default TaskFooter;
