import { FC } from 'react';

import { Box, ListItem, Typography } from '@mui/material';

import TryOutlinedIcon from '@mui/icons-material/TryOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import AISuggestedTable from './ActiveRuleCategory/AiSuggestedTable';
import { ListCollapseButton } from '../../../ListItemButtonMenu';

const AISuggestedCategory: FC = () => {
  return (
    <Box>
      <ListCollapseButton
        sx={{ borderRadius: 1, backgroundColor: '#F6F8FB' }}
        renderButton={(open) => (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TryOutlinedIcon sx={{ color: '#1554FF' }} fontSize="small" />
              <Typography color="primary.main">AI Suggested</Typography>
            </Box>
            {open ? (
              <KeyboardArrowDownIcon fontSize="small" htmlColor="#1554FF" />
            ) : (
              <KeyboardArrowUpIcon fontSize="small" htmlColor="#1554FF" />
            )}
          </Box>
        )}
      >
        <ListItem disablePadding>
          <Box width="100%" height={274}>
            <AISuggestedTable />
          </Box>
        </ListItem>
      </ListCollapseButton>
    </Box>
  );
};

export default AISuggestedCategory;
