import { FC } from 'react';
import { Grid } from '@mui/material';
import { LogicalOperatorFormRow } from '../shared/LogicalOperatorFormRow';
import { AddLogicalOperatorButton } from '../shared/AddLogicalOperatorButton';
import { ConditionalRecordForm } from '../WhereList/ConditionalRecordForm';
import { ConditionType } from 'pages/RuleForm/types';
import { Option } from 'store/ruleForm/types';
import {
  FieldReference,
  RootCondition,
  LogicalOperator,
  RuleWhereArray,
} from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { RuleComponent, RuleComponentType } from 'store/dataRaptorRule/dto/Enums';

interface WhereListProps {
  wherePath: string;
  where: RuleWhereArray;
  setWhere: (payload: { value: RuleWhereArray }) => {
    payload: {
      value: RuleWhereArray;
    };
    type: string;
  };
  fieldOptions: Option[];
  tableName: string;
}

const WhereList: FC<WhereListProps> = (props) => {
  const { wherePath, where, setWhere, fieldOptions, tableName } = props;

  return (
    <>
      {fieldOptions &&
        where?.map((whereValue: RootCondition | LogicalOperator | RuleComponent, index) => {
          if (whereValue.type === RuleComponentType.ROOT_CONDITIONAL) {
            const conditionComponent = whereValue as RootCondition;
            let field;

            if (conditionComponent.field.type === RuleComponentType.FIELD_REFERENCE) {
              field = (conditionComponent.field as FieldReference).value || fieldOptions[0].value;
            }

            return (
              <Grid container display="flex" alignItems="center" key={`rule-form-record-${index}`}>
                <>
                  <ConditionalRecordForm
                    index={index}
                    field={field}
                    conditionComponent={conditionComponent}
                    where={where}
                    setWhere={setWhere}
                    conditionPath={`${wherePath}[${index}]`}
                    fieldOptions={fieldOptions}
                    tableName={tableName}
                  />
                </>
                <AddLogicalOperatorButton
                  index={index}
                  where={where}
                  type={ConditionType.WHERE}
                  wherePath={wherePath}
                />
              </Grid>
            );
          } else if (whereValue.type === RuleComponentType.LOGICAL_OPERATOR) {
            return (
              <>
                <LogicalOperatorFormRow
                  key={`logical-operator-form-row-where-${index}`}
                  logicalOperatorRecord={whereValue as LogicalOperator}
                  targetPath={`${wherePath}[${index}]`}
                />
              </>
            );
          }
        })}
    </>
  );
};

export { WhereList };
