import { Grid } from '@mui/material';
import { FC } from 'react';
import { LogicalOperator } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { TabSelector } from '../../TabSelector';

type LogicalOperatorFormRowProps = {
  logicalOperatorRecord: LogicalOperator;
  targetPath: string;
  setFieldFunction: (param: { path: string; value: any }) => void;
  width?: string;
};

const LogicalOperatorFormRow: FC<LogicalOperatorFormRowProps> = (props) => {
  const { logicalOperatorRecord, targetPath, setFieldFunction, width } = props;
  const conditionComponent = logicalOperatorRecord as LogicalOperator;

  return (
    <Grid
      container
      display="flex"
      justifyContent="flex-start"
      sx={{ my: '1rem', marginLeft: 1, width: width || '100%' }}
    >
      <TabSelector
        tabs={['AND', 'OR']}
        selectedTab={conditionComponent.value}
        onChange={(value) => {
          setFieldFunction({ path: `${targetPath}.value`, value: value });
        }}
      />
    </Grid>
  );
};

export { LogicalOperatorFormRow };
