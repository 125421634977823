import { Button, ButtonGroup, Typography } from '@mui/material';
import theme from 'core/theme';
import { TableStatInterval } from 'http/migration/dto';
import { useDashboard } from 'store/dashboard/hook';

export const IntervalSelector = () => {
  const {
    data: {
      dataQualityTimeline: { interval: selectedInterval },
    },
    methods: { setQualityTimelineInterval },
  } = useDashboard();

  return (
    <ButtonGroup
      sx={{
        '& .MuiButtonGroup-grouped': {
          height: '24px',
          bgcolor: 'white',
          color: '#898EA1',
          border: 'none',
          fontWeight: 400,
          '&:hover': { bgcolor: 'white', color: '#898EA1', fontWeight: 600, border: 'none' },
        },
      }}
    >
      {Object.values(TableStatInterval).map((interval, index) => {
        const selected = interval === selectedInterval;
        const color = selected ? theme.palette.primary.main : '#898EA1';
        const weight = selected ? 'bold' : 'normal';
        return (
          <Button key={index} onClick={() => setQualityTimelineInterval(interval)}>
            <Typography sx={{ color, fontWeight: weight, textTransform: 'capitalize' }}>{interval}</Typography>
          </Button>
        );
      })}
    </ButtonGroup>
  );
};
