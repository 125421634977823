import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from './ui';
import { CircularProgress, Divider, Grid } from '@mui/material';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { useMigration } from 'store/migration/hooks';
import { SegmentationForm } from './components/SegmentationForm';
import { INTERNAL_FIELDS } from 'core/constants';
import { useDeduplicationConfig } from 'store/deduplicationConfig/hook';
import { PrimaryButton, SecondaryRedLoadingButton } from 'components/ui/buttons';

interface Option {
  label: string;
  value: string;
}

const DeDuplication: FC = () => {
  const [selectedMigrationId, setSelectedMigrationId] = useState<string>();
  const [selectedTable, setSelectedTable] = useState<string>();
  const [migrationOptions, setMigrationOptions] = useState<Option[]>();
  const [tableOptions, setTableOptions] = useState<Option[]>();
  const [selectedSegmentationField, setSelectedSegmentationField] = useState<string[]>();
  const {
    getDeduplicationConfigByMigrationAndTable,
    data: {
      getDeduplicationConfigByMigrationAndTableState,
      updateDeduplicationConfigState,
      createDeduplicationConfigState,
    },
    createDeduplicationConfig,
    updateDeduplicationConfig,
  } = useDeduplicationConfig();

  const isRunningATransaction = useMemo(() => {
    return (
      updateDeduplicationConfigState.loading ||
      createDeduplicationConfigState.loading ||
      getDeduplicationConfigByMigrationAndTableState.loading
    );
  }, [
    createDeduplicationConfigState.loading,
    getDeduplicationConfigByMigrationAndTableState.loading,
    updateDeduplicationConfigState.loading,
  ]);

  const showSegmentationForm = useMemo(
    () => selectedMigrationId && selectedTable,
    [selectedMigrationId, selectedTable],
  );

  const {
    data: { migrations, migratedTables, migratedTableField },
    loading: migrationLoading,
    getMigrations,
    getMigrationTablesById,
    getMigrationTableFieldsById,
  } = useMigration();

  useEffect(() => {
    if (!migrations || migrations.length === 0) {
      getMigrations();
    }
  }, [getMigrations, migrations]);

  useEffect(() => {
    if (!selectedMigrationId && migrations && migrations.length > 0) {
      const migrationsCompleted = migrations.filter((migration) => migration.status === 'migration-completed');
      if (migrationsCompleted.length > 0) {
        setSelectedMigrationId(migrations[0].dataMigrationId);
        setMigrationOptions(
          migrationsCompleted.map((migration) => ({
            label: migration.dataSource?.name || '',
            value: migration.dataMigrationId,
          })),
        );
      }
    }
  }, [migrations, selectedMigrationId]);

  useEffect(() => {
    if (selectedMigrationId) {
      getMigrationTablesById(selectedMigrationId);
    }
  }, [getMigrationTablesById, selectedMigrationId]);

  useEffect(() => {
    if (migratedTables && migratedTables.length > 0) {
      setSelectedTable(migratedTables[0].table_name);
      setTableOptions(
        migratedTables.map((migratedTable) => ({
          label: migratedTable.table_name,
          value: migratedTable.table_name,
        })),
      );
    }
  }, [migratedTables]);

  const getSegmentationFields = useCallback(() => {
    if (selectedMigrationId && selectedTable) {
      getDeduplicationConfigByMigrationAndTable({ migrationId: selectedMigrationId, table: selectedTable });
    }
  }, [getDeduplicationConfigByMigrationAndTable, selectedMigrationId, selectedTable]);

  useEffect(() => {
    if (migratedTables.length && selectedMigrationId && selectedTable) {
      getMigrationTableFieldsById({ migrationId: selectedMigrationId, tableId: selectedTable });
      getSegmentationFields();
    }
  }, [
    getDeduplicationConfigByMigrationAndTable,
    getMigrationTableFieldsById,
    getSegmentationFields,
    migratedTables.length,
    selectedMigrationId,
    selectedTable,
  ]);

  useEffect(() => {
    if (getDeduplicationConfigByMigrationAndTableState.success && getDeduplicationConfigByMigrationAndTableState.data) {
      setSelectedSegmentationField(getDeduplicationConfigByMigrationAndTableState.data.fields);
    }
    if (getDeduplicationConfigByMigrationAndTableState.error) {
      setSelectedSegmentationField([]);
    }
  }, [getDeduplicationConfigByMigrationAndTableState]);

  const isThereFieldChanges = useMemo(() => {
    const set1 = new Set(selectedSegmentationField || []);
    const set2 = new Set(getDeduplicationConfigByMigrationAndTableState.data?.fields || []);
    return set1.size !== set2.size;
  }, [getDeduplicationConfigByMigrationAndTableState.data?.fields, selectedSegmentationField]);

  function handleDiscardChanges(): void {
    setSelectedSegmentationField(getDeduplicationConfigByMigrationAndTableState.data?.fields || []);
  }

  const handleSaveChanges = useCallback(() => {
    console.log('handleSaveChanges');
    if (
      getDeduplicationConfigByMigrationAndTableState.success &&
      getDeduplicationConfigByMigrationAndTableState.data?.DeDuplicationConfigId &&
      selectedMigrationId &&
      selectedTable &&
      selectedSegmentationField
    ) {
      updateDeduplicationConfig({
        deduplicationConfigId: getDeduplicationConfigByMigrationAndTableState.data?.DeDuplicationConfigId,
        data: {
          migrationId: selectedMigrationId,
          tableName: selectedTable,
          fields: selectedSegmentationField || [],
        },
        onSuccess: getSegmentationFields,
      });
    } else if (
      getDeduplicationConfigByMigrationAndTableState.success === false &&
      selectedMigrationId &&
      selectedTable &&
      selectedSegmentationField
    ) {
      createDeduplicationConfig({
        data: {
          migrationId: selectedMigrationId,
          tableName: selectedTable,
          fields: selectedSegmentationField || [],
        },
        onSuccess: getSegmentationFields,
      });
    }
  }, [
    createDeduplicationConfig,
    getDeduplicationConfigByMigrationAndTableState.data?.DeDuplicationConfigId,
    getDeduplicationConfigByMigrationAndTableState.success,
    getSegmentationFields,
    selectedMigrationId,
    selectedSegmentationField,
    selectedTable,
    updateDeduplicationConfig,
  ]);

  return (
    <Container position="relative" marginBottom={5} display={'flex'} flexDirection={'column'} gap={4}>
      <Grid container display={'flex'} justifyContent={'space-around'} gap={2}>
        <Grid item xs={5}>
          <ControlledCustomDropdown
            sx={{ width: '100%' }}
            labelSx={{ fontWeight: 'bold' }}
            label="Migration"
            value={selectedMigrationId || ''}
            options={migrationOptions || []}
            fallbackOption={{ label: 'Select Migration', value: '' }}
            placeholder={''}
            id={'field-segmentation-migration-input'}
            onSelect={(value: string) => setSelectedMigrationId(value)}
          />
        </Grid>
        <Grid item xs={5}>
          <ControlledCustomDropdown
            sx={{ width: '100%' }}
            labelSx={{ fontWeight: 'bold' }}
            label="Table"
            value={selectedTable || ''}
            options={tableOptions || []}
            fallbackOption={{ label: 'Select Table', value: '' }}
            placeholder={''}
            id={'field-segmentation-table-input'}
            onSelect={(value: string) => setSelectedTable(value)}
          />
        </Grid>
      </Grid>
      <Divider></Divider>
      {showSegmentationForm && (
        <>
          {migrationLoading || isRunningATransaction ? (
            <Grid container display={'flex'} justifyContent={'center'}>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              <SegmentationForm
                selectedSegmentationField={selectedSegmentationField || []}
                setSelectedSegmentationField={setSelectedSegmentationField}
                migratedTableField={
                  migratedTableField.filter(
                    (field) =>
                      !selectedSegmentationField?.includes(field.fieldName) &&
                      !INTERNAL_FIELDS.includes(field.fieldName),
                  ) || []
                }
              />
              {isThereFieldChanges && (
                <Grid container display={'flex'} justifyContent={'end'} gap={2}>
                  <Grid item>
                    <SecondaryRedLoadingButton onClick={handleDiscardChanges}>
                      Discard Changes
                    </SecondaryRedLoadingButton>
                  </Grid>
                  <Grid item>
                    <PrimaryButton onClick={handleSaveChanges}>Save Changes</PrimaryButton>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default DeDuplication;
