import React, { useState } from 'react';
import { TextField, List, ListItem, ListItemText, Box, Typography, Divider, Chip } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { upperCase } from 'lodash';

interface SearchableListProps {
  items: Array<{ groupField: string; fields: Array<{ name: string; type: string }> }>;
  onSelect: (schemaField: string, fieldGroup: string) => void;
  onClose: () => void;
}

const SearchableList: React.FC<SearchableListProps> = ({ items, onSelect, onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGroupField, setSelectedGroupField] = useState<string | null>(null);
  const [selectedField, setSelectedField] = useState<string | null>(null);
  const [filterBy, setFilterBy] = useState<'groupField' | 'field' | null>(null);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchTerm(value);

    if (value !== selectedGroupField && value !== selectedField) {
      setSelectedGroupField(null);
      setSelectedField(null);
    }
  };

  const handleGroupSelect = (groupField: string) => {
    setSelectedGroupField(groupField);
    setSelectedField(null);
    setSearchTerm(groupField);
  };

  const handleFieldSelect = (field: string, groupField: string) => {
    setSelectedField(field);
    setSelectedGroupField(groupField);
    setSearchTerm(field);
  };

  const filteredItems = items.reduce(
    (acc, item) => {
      const groupMatches = item.groupField?.toLowerCase()?.includes(searchTerm.toLowerCase()) || false;

      const matchedFields =
        item.fields?.filter((field) => field.name?.toLowerCase()?.includes(searchTerm.toLowerCase())) || [];

      if (groupMatches) {
        acc.groupFields.push(item.groupField);
      }

      if (matchedFields.length > 0) {
        acc.fields.push({
          groupField: item.groupField,
          names: matchedFields.map((field) => field.name),
        });
      }

      return acc;
    },
    { groupFields: [] as string[], fields: [] as Array<{ groupField: string; names: string[] }> },
  );

  const highlightText = (text: string, highlight: string) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} style={{ color: 'blue' }}>
              {part}
            </span>
          ) : (
            <span key={index}>{part}</span>
          ),
        )}
      </>
    );
  };

  const renderFields = () => {
    if (selectedField && selectedGroupField) {
      const selectedGroup = items.find((item) => item.groupField === selectedGroupField);
      return selectedGroup ? (
        <>
          <Typography variant="p12" color={'neutral.n400'}>
            PROFILE <ArrowForwardIcon sx={{ fontSize: '12px' }} /> {upperCase(selectedGroupField)}
          </Typography>
          <List dense>
            {selectedGroup.fields.map((field) => (
              <ListItem
                key={field.name}
                onClick={() => {
                  onSelect(field.name, selectedGroup.groupField);
                  onClose();
                }}
              >
                <ListItemText>
                  <Typography variant="p14">{highlightText(field.name, searchTerm)}</Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </>
      ) : null;
    }

    if (selectedGroupField) {
      const selectedGroup = items.find((item) => item.groupField === selectedGroupField);
      return selectedGroup ? (
        <>
          <Typography variant="p12" color={'neutral.n400'}>
            PROFILE <ArrowForwardIcon sx={{ fontSize: '12px' }} />
            <span style={{ color: 'blue' }}>{upperCase(selectedGroupField)}</span>
          </Typography>
          <List dense>
            {selectedGroup.fields.map((field) => (
              <ListItem key={field.name} onClick={() => handleFieldSelect(field.name, selectedGroupField)}>
                <ListItemText>
                  <Typography variant="p14">{highlightText(field.name, searchTerm)}</Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </>
      ) : null;
    }

    if (!searchTerm && filterBy === null) {
      return (
        <>
          <Typography variant="p12" color={'neutral.n400'}>
            PROFILE <ArrowForwardIcon sx={{ fontSize: '12px' }} /> {upperCase(items[0].groupField)}
          </Typography>
          <List dense>
            {items[0].fields.map((field) => (
              <ListItem
                key={field.name}
                onClick={() => {
                  onSelect(field.name, items[0].groupField);
                  onClose();
                }}
              >
                <ListItemText>
                  <Typography variant="p14">{field.name}</Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </>
      );
    }

    if (filterBy === 'groupField') {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, my: 1 }}>
          <Typography variant="p12" color={'neutral.n400'}>
            Field Groups
          </Typography>
          {filteredItems.groupFields.map((groupField) => (
            <Typography key={groupField} variant="p14" onClick={() => handleGroupSelect(groupField)}>
              {highlightText(groupField, searchTerm)}
            </Typography>
          ))}
        </Box>
      );
    }

    if (filterBy === 'field') {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, my: 1 }}>
          <Typography variant="p12" color={'neutral.n400'}>
            Fields
          </Typography>
          {filteredItems.fields.map((group) =>
            group.names.map((field) => (
              <Typography key={field} variant="p14" onClick={() => handleFieldSelect(field, group.groupField)}>
                {highlightText(field, searchTerm)}
              </Typography>
            )),
          )}
        </Box>
      );
    }

    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, my: 1 }}>
          <Typography variant="p12" color={'neutral.n400'}>
            Field Groups
          </Typography>
          {filteredItems.groupFields.map((groupField) => (
            <Typography key={groupField} variant="p14" onClick={() => handleGroupSelect(groupField)}>
              {highlightText(groupField, searchTerm)}
            </Typography>
          ))}
        </Box>
        <Divider />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, my: 1 }}>
          <Typography variant="p12" color={'neutral.n400'}>
            Fields
          </Typography>
          {filteredItems.fields.map((group) =>
            group.names.map((field) => (
              <Typography key={field} variant="p14" onClick={() => handleFieldSelect(field, group.groupField)}>
                {highlightText(field, searchTerm)}
              </Typography>
            )),
          )}
        </Box>
      </>
    );
  };

  return (
    <Box p={1}>
      <TextField
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search by Field Group or Field..."
        fullWidth
      />
      {searchTerm && !selectedGroupField && !selectedField && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, my: 1 }}>
            <Typography variant="p12" color={'neutral.n400'}>
              Filter by <ExpandMoreIcon sx={{ fontSize: '12px' }} />
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
              <Chip
                label="Field Group"
                variant={filterBy === 'groupField' ? 'filled' : 'outlined'}
                onClick={() => setFilterBy('groupField')}
              />
              <Chip
                label="Field"
                variant={filterBy === 'field' ? 'filled' : 'outlined'}
                onClick={() => setFilterBy('field')}
              />
              <Chip label="All" variant={filterBy === null ? 'filled' : 'outlined'} onClick={() => setFilterBy(null)} />
            </Box>
          </Box>
          <Divider />
        </>
      )}
      {renderFields()}
    </Box>
  );
};

export default SearchableList;
