import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormHelperText, Typography, Box, InputLabel, Divider, Snackbar } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';

import { PUBLIC_ABS_ROUTE_PATHS } from 'core/constants';
import { ReactComponent as SalesforceIcon } from 'assets/icons/salesforce.svg';
import { Form, AuthInput, LoadingButton, SecondaryLoadingButton } from 'components/ui';
import { useAuth } from 'store/auth/hooks';
import { AuthLayout } from 'components/AuthLayout';
import { CustomLink } from 'components/CustomLink';
import { validatePassword } from 'core/utils';
import { PasswordInput } from 'components/PasswordInput';
import { store } from 'store/index';
import { resetApp } from 'store/actions';
import { requestIntegrationAuth } from 'http/auth';
import { openAuthWindow } from 'pages/IntegrationRedirect/popup';
import { useSnackbar } from 'notistack';

interface FormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

const formSchema = yup.object({
  email: yup.string().required('Email is required').email('Invalid email.'),
  password: yup
    .string()
    .required('Password is required')
    .test(
      'isValidPassword',
      'Password must be at least 8 characters long, include at least one lowercase and one uppercase character, and one number, symbol, or whitespace character.',
      validatePassword,
    ),
  rememberMe: yup.boolean(),
});

const Login: FC = () => {
  const [searchParams] = useSearchParams();
  const { loading, error, login, setError, setSuccess, loginWithAccessToken } = useAuth();
  const snackbar = useSnackbar();

  useEffect(() => {
    const bc = new BroadcastChannel('integration-events');
    bc.onmessage = (event) => {
      if (event.data.type === 'auth' && event.data.data.accessToken) {
        loginWithAccessToken(event.data.data.accessToken);
      }
      if (event.data.type === 'error' && event.data.data) {
        setError(event.data.data);
        snackbar.enqueueSnackbar(event.data.data, { variant: 'error' });
      }
    };
    return () => {
      bc.close();
    };
  }, [loginWithAccessToken, setError, snackbar]);

  const handleSignInWithSalesforce = () => {
    const getOAuthUrl = async () => {
      const oauthUrl = await requestIntegrationAuth('salesforce');
      return oauthUrl;
    };
    getOAuthUrl().then((url) => {
      openAuthWindow(url, 'authpopup');
    });
  };

  useEffect(() => {
    return () => {
      setError(false);
      setSuccess(false);
      store.dispatch(resetApp());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues: FormValues = {
    email: searchParams.get('email') ?? '',
    password: '',
    rememberMe: false,
  };

  const onSubmit = (values: FormValues) => {
    login(values);
  };

  return (
    <AuthLayout>
      <Box>
        <Typography variant="h2" marginBottom={4}>
          Welcome Back!
        </Typography>

        <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={onSubmit}>
          {({ values, isValid, dirty, handleChange, handleSubmit, handleBlur }) => (
            <Form noValidate>
              <Box>
                <InputLabel htmlFor="email">Email address</InputLabel>

                <AuthInput
                  id="email"
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </Box>

              <Box marginTop="24px" marginBottom="4px">
                <InputLabel htmlFor="password">Password</InputLabel>

                <PasswordInput
                  id="password"
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </Box>

              <CustomLink to={PUBLIC_ABS_ROUTE_PATHS.resetPassword}>Forgot my password</CustomLink>

              <LoadingButton
                loading={loading}
                disabled={!(isValid && dirty)}
                sx={{ marginTop: 4 }}
                type="submit"
                onClick={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                Log in
              </LoadingButton>

              {!!error && (
                <FormHelperText sx={{ color: 'red.main', textAlign: 'center', marginTop: 2 }}>
                  {typeof error === 'string' ? error : 'Something went wrong!'}
                </FormHelperText>
              )}

              <Box marginTop="32px">
                <Divider>or</Divider>
              </Box>

              <SecondaryLoadingButton
                onClick={handleSignInWithSalesforce}
                variant="outlined"
                sx={{ marginTop: 3 }}
                endIcon={<SalesforceIcon />}
              >
                Enter with Salesforce
              </SecondaryLoadingButton>
            </Form>
          )}
        </Formik>
      </Box>
    </AuthLayout>
  );
};

export default Login;
