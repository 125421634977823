import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { CustomInput } from 'components/CustomInput';
import { ModalTemplate } from 'components/ModalTemplate';
import { GridItem } from 'components/ui';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { WhereList } from '../WhereList/WhereList';
import { Option } from 'store/ruleForm/types';

interface SubQueryModalPros {
  open: boolean;
  handleToggleShow: () => void;
}

export const SubQueryModal: FC<SubQueryModalPros> = (props) => {
  const { open, handleToggleShow } = props;
  const [fieldOptions, setFieldOptions] = useState<Option[]>([]);
  const {
    tableOptions,
    subQueryModalIndex,
    subQueries,
    selectedMigration,
    setFieldValue,
    setSubQueriesWhere,
    handleAddInitialWhere,
    getMigrationTableFieldsAndLookUpsById,
    getFieldOptionsByMigrationAndTable,
    handleSubQueryDeletion,
  } = useRuleForm();

  const subQuery = useMemo(() => subQueries[subQueryModalIndex], [subQueries, subQueryModalIndex]);
  const where = subQuery?.where;
  const hasWhereItems = where?.length > 0 || false;

  const handleUpdateTable = useCallback(
    (value: string) => {
      setFieldOptions([]);
      setFieldValue({ path: `subQueries[${subQueryModalIndex}].table`, value });
      getMigrationTableFieldsAndLookUpsById({ migrationId: selectedMigration, tableId: value });
      setFieldValue({ path: `subQueries[${subQueryModalIndex}].where`, value: [] });
    },
    [getMigrationTableFieldsAndLookUpsById, selectedMigration, setFieldValue, subQueryModalIndex],
  );

  const handleNameChange = (e: any) => {
    let value = String(e.target.value).trim();
    value = value.replace(/[^a-zA-Z0-9]/g, '');
    value = value.replace(/^[0-9]+/, '');
    setFieldValue({
      path: `subQueries[${subQueryModalIndex}].alias`,
      value: value,
    });
  };

  useEffect(() => {
    const options = getFieldOptionsByMigrationAndTable(
      selectedMigration,
      subQueries[subQueryModalIndex]?.table || '',
      false,
    );
    setFieldOptions(options || []);
  }, []);

  useEffect(() => {
    const options = getFieldOptionsByMigrationAndTable(
      selectedMigration,
      subQueries[subQueryModalIndex]?.table || '',
      false,
    );
    if (options.length > 0) {
      setFieldOptions(options);
    }
  }, [getFieldOptionsByMigrationAndTable, selectedMigration, subQueries, subQueryModalIndex]);

  return (
    <>
      {subQuery && (
        <ModalTemplate
          open={open}
          toggleOpen={handleToggleShow}
          title={'Sub Query builder'}
          showChevronDown={false}
          maxWidth="90vw"
        >
          <Grid container flexDirection={'row'} sx={{ maxWidth: '100%' }} px={2} py={4} gap={3}>
            <Grid item xs={12}>
              <Grid container display={'flex'} justifyContent={'center'}>
                <GridItem item xs={12} sx={{ textAlign: 'center' }}>
                  <Typography variant="h2">Sub Query</Typography>
                </GridItem>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container display={'flex'} gap={2} justifyContent={'space-around'}>
                <GridItem item xs={5}>
                  <CustomInput
                    label="Alias"
                    id={`subQueries[${subQueryModalIndex}].alias`}
                    name={`subQueries[${subQueryModalIndex}].alias`}
                    labelSx={{ fontWeight: 'bold' }}
                    placeholder="Sub Query Alias"
                    fullWidth
                    value={subQuery.alias}
                    onChange={handleNameChange}
                  />
                </GridItem>
                <GridItem item xs={5}>
                  <ControlledCustomDropdown<string>
                    id={`subQueries[${subQueryModalIndex}].table`}
                    placeholder="Table"
                    label="Table"
                    labelSx={{ fontWeight: 'bold' }}
                    value={subQuery.table}
                    options={tableOptions}
                    sx={{ backgroundColor: 'neutral.white', width: '100%' }}
                    fallbackOption={{ label: 'Select a table', value: '' }}
                    onSelect={(value) => handleUpdateTable(value)}
                  />
                </GridItem>
              </Grid>
            </Grid>
            {subQuery.table && (
              <>
                <Grid item xs={12}>
                  <Grid container display={'flex'} gap={2} alignItems={'center'}>
                    <GridItem item xs={6} sx={{ textAlign: 'right' }}>
                      <Typography variant="labelBold14">Where:</Typography>
                    </GridItem>
                    {!hasWhereItems && fieldOptions?.length > 0 && (
                      <GridItem xs={2}>
                        <Button
                          variant="outlined"
                          sx={{ width: 'fit-content', display: 'inline', marginRight: 'auto', my: 'auto' }}
                          onClick={() => handleAddInitialWhere(`subQueries[${subQueryModalIndex}].where[0]`)}
                        >
                          +
                        </Button>
                      </GridItem>
                    )}
                    {fieldOptions?.length <= 0 && (
                      <GridItem>
                        <CircularProgress />
                      </GridItem>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container display={'flex'}>
                    <WhereList
                      wherePath={`subQueries[${subQueryModalIndex}].where`}
                      where={subQueries[subQueryModalIndex].where}
                      setWhere={setSubQueriesWhere}
                      fieldOptions={fieldOptions}
                      tableName={subQueries[subQueryModalIndex].table}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Grid container xs={12} display={'flex'} justifyContent={'space-around'}>
                <GridItem>
                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    sx={{ width: 'fit-content', display: 'inline', marginRight: 'auto', my: 'auto' }}
                    onClick={() => handleSubQueryDeletion(subQueryModalIndex)}
                  >
                    Delete
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    variant="contained"
                    color="success"
                    size="large"
                    sx={{ width: 'fit-content', display: 'inline', marginRight: 'auto', my: 'auto' }}
                    onClick={handleToggleShow}
                  >
                    Done
                  </Button>
                </GridItem>
              </Grid>
            </Grid>
          </Grid>
        </ModalTemplate>
      )}
    </>
  );
};
