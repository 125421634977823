import { ActionPromiseArgs, ActionState } from 'store/types';

export interface DeduplicationResult {
  id: string;
  record_a_id: string;
  record_b_id: string;
  record_a_table: string;
  record_b_table: string;
  result: object;
  ai_recommendation: any;
  duplication_score: number;
  master_record_id: string;
  status: string;
}

export interface DeduplicationResultByIdsRequest {
  ids: string[];
}

export interface GetDeduplicationResultByMigrationOptions {
  limit: number;
  tables: string[];
}

export enum DeduplicationResultStatus {
  ACTIVE = 'A',
  IGNORED = 'I',
}

export interface GetDeduplicationResultByIdsArgs extends ActionPromiseArgs<DeduplicationResult[]> {
  migrationId: string;
  data: DeduplicationResultByIdsRequest;
}

export interface GetDeduplicationResultByMigrationArgs extends ActionPromiseArgs<DeduplicationResult[]> {
  migrationId: string;
  options: GetDeduplicationResultByMigrationOptions;
}

export interface DeduplicationResultState {
  error: boolean | string;
  success: boolean | string;
  loading: boolean;
  data: {
    getDeduplicationResultByIdsState: ActionState<DeduplicationResult[]>;
    getDeduplicationResultByMigrationState: ActionState<DeduplicationResult[]>;
  };
}

export interface DeduplicationResultHook extends DeduplicationResultState {
  getDeduplicationResultByIds: (args: GetDeduplicationResultByIdsArgs) => void;
  getDeduplicationResultByMigration: (args: GetDeduplicationResultByMigrationArgs) => void;
}
