import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';

export interface OperatorOption {
  value: string;
  label: string;
  validType: string[];
  rightValueType: RuleComponentType[];
}

export interface LookUpFunctionOptions {
  value: string;
  label: string;
  paramNumber: number;
  resultType: string[];
  acceptType: string[];
}

export enum FUNCTION_ENUM {
  COUNT = 'COUNT',
  MAX = 'MAX',
  MIN = 'MIN',
  SUM = 'SUM',
  DAYS_SINCE = 'DAYS_SINCE',
}

const NUMERICAL_TYPES_ARRAY = ['xsd:int', 'xsd:double', 'xsd:long'];

export const LOOK_UP_FUNCTION_OPTIONS: LookUpFunctionOptions[] = [
  {
    value: 'COUNT',
    label: 'Count',
    paramNumber: 1,
    resultType: [...NUMERICAL_TYPES_ARRAY],
    acceptType: ['*'],
  },
  {
    value: 'MAX',
    label: 'Max',
    paramNumber: 1,
    resultType: [...NUMERICAL_TYPES_ARRAY],
    acceptType: [...NUMERICAL_TYPES_ARRAY],
  },
  {
    value: 'MIN',
    label: 'Min',
    paramNumber: 1,
    resultType: [...NUMERICAL_TYPES_ARRAY],
    acceptType: [...NUMERICAL_TYPES_ARRAY],
  },
  {
    value: 'SUM',
    label: 'Sum',
    paramNumber: 1,
    resultType: [...NUMERICAL_TYPES_ARRAY],
    acceptType: [...NUMERICAL_TYPES_ARRAY],
  },
  {
    value: 'DAYS_SINCE',
    label: 'Days Since',
    paramNumber: 1,
    resultType: [...NUMERICAL_TYPES_ARRAY],
    acceptType: ['xsd:date', 'xsd:dateTime'],
  },
];

export const OPERATOR_OPTIONS: OperatorOption[] = [
  {
    value: '=',
    label: '= Equal',
    validType: ['*'],
    rightValueType: [
      RuleComponentType.PRIMITIVE_VALUE,
      RuleComponentType.FIELD_REFERENCE,
      RuleComponentType.FUNCTION_VALUE,
    ],
  },
  {
    value: '!=',
    label: '!= Not Equal',
    validType: ['*'],
    rightValueType: [
      RuleComponentType.PRIMITIVE_VALUE,
      RuleComponentType.FIELD_REFERENCE,
      RuleComponentType.FUNCTION_VALUE,
    ],
  },
  {
    value: '<',
    label: '< Less Than',
    validType: ['xsd:int', 'xsd:long', 'xsd:dateTime', 'xsd:time', 'xsd:date', 'xsd:double'],
    rightValueType: [
      RuleComponentType.PRIMITIVE_VALUE,
      RuleComponentType.FIELD_REFERENCE,
      RuleComponentType.FUNCTION_VALUE,
    ],
  },
  {
    value: '<=',
    label: '<= Less Than or Equal',
    validType: ['xsd:int', 'xsd:long', 'xsd:dateTime', 'xsd:time', 'xsd:date', 'xsd:double'],
    rightValueType: [
      RuleComponentType.PRIMITIVE_VALUE,
      RuleComponentType.FIELD_REFERENCE,
      RuleComponentType.FUNCTION_VALUE,
    ],
  },
  {
    value: '>',
    label: '> Greater Than',
    validType: ['xsd:int', 'xsd:long', 'xsd:dateTime', 'xsd:time', 'xsd:date', 'xsd:double'],
    rightValueType: [
      RuleComponentType.PRIMITIVE_VALUE,
      RuleComponentType.FIELD_REFERENCE,
      RuleComponentType.FUNCTION_VALUE,
    ],
  },
  {
    value: '>=',
    label: '>= Greater Than or Equal',
    validType: ['xsd:int', 'xsd:long', 'xsd:dateTime', 'xsd:time', 'xsd:date', 'xsd:double'],
    rightValueType: [
      RuleComponentType.PRIMITIVE_VALUE,
      RuleComponentType.FIELD_REFERENCE,
      RuleComponentType.FUNCTION_VALUE,
    ],
  },
  {
    value: 'IN',
    label: 'In',
    validType: ['xsd:string', 'xsd:int', 'xsd:long', 'xsd:dateTime', 'tns:ID', 'xsd:time', 'xsd:date', 'xsd:double'],
    rightValueType: [RuleComponentType.SUB_QUERY_VALUE],
  },
  {
    value: 'NOT IN',
    label: 'Not In',
    validType: ['xsd:string', 'xsd:int', 'xsd:long', 'xsd:dateTime', 'tns:ID', 'xsd:time', 'xsd:date', 'xsd:double'],
    rightValueType: [RuleComponentType.SUB_QUERY_VALUE],
  },
];

export const PRIMITIVE_OPTIONS = [
  { label: 'Text', value: 'text', validTypes: ['xsd:string'] },
  { label: 'Date', value: 'date', validTypes: ['xsd:date', 'xsd:dateTime'] },
  { label: 'Date Time', value: 'datetime-local', validTypes: ['xsd:dateTime'] },
  { label: 'Number', value: 'number', validTypes: [...NUMERICAL_TYPES_ARRAY] },
  { label: 'Boolean', value: 'boolean', validTypes: ['xsd:boolean'] },
  { label: 'Null', value: 'null', validTypes: ['xsd:null'] },
];

export const CONDITIONAL_VALUE_OPTIONS = [
  { label: 'Function', value: RuleComponentType.FUNCTION_VALUE },
  { label: 'Value', value: RuleComponentType.PRIMITIVE_VALUE },
  { label: 'Field', value: RuleComponentType.FIELD_REFERENCE },
  { label: 'Sub Query', value: RuleComponentType.SUB_QUERY_VALUE },
];

export const BOOLEAN_OPTIONS = [
  { label: 'True', value: 'True' },
  { label: 'False', value: 'False' },
];

export const NULL_OPTIONS = [{ label: 'Null', value: 'Null' }];
