export enum RuleComponentType {
  ROOT_CONDITIONAL = 'ROOT_CONDITIONAL',
  LOGICAL_OPERATOR = 'LOGICAL_OPERATOR',
  OPEN_PARENTHESIS = 'OPEN_PARENTHESIS',
  CLOSED_PARENTHESIS = 'CLOSED_PARENTHESIS',
  LOOKUP_VALUE = 'LOOKUP_VALUE',
  LOOKUP_TABLE = 'LOOKUP_TABLE',
  FIELD_REFERENCE = 'FIELD_REFERENCE',
  PRIMITIVE_VALUE = 'PRIMITIVE_VALUE',
  FUNCTION_VALUE = 'FUNCTION_VALUE',
  SUB_QUERY_VALUE = 'SUB_QUERY_VALUE',
  REGEX = 'REGEX',
  SERVICE = 'SERVICE',
}

export interface RuleComponent {
  type: RuleComponentType;
}
