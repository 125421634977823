import {
  Card,
  CardActions,
  IconButton,
  Typography,
  Divider,
  Checkbox,
  Collapse,
  Button,
  IconButtonProps,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import TableMapping from './TableMapping';

interface FieldCardProps {
  title: string;
  icon: JSX.Element;
  tableData: any[];
  expanded: boolean;
  removeUnmapped: boolean;
  onExpand: () => void;
  onNewFieldMapping: () => void;
  onRemoveUnmapped: () => void;
  onDeleteField: (objectField: string) => void;
  onUpdateFieldMapping: (objectField: string, schemaField: string, fieldGroup: string) => void;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ expand, theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
}));

export const FieldCard = ({
  title,
  icon,
  tableData,
  expanded,
  removeUnmapped,
  onExpand,
  onNewFieldMapping,
  onRemoveUnmapped,
  onDeleteField,
  onUpdateFieldMapping,
}: FieldCardProps) => (
  <Card sx={{ width: '100%', mb: 2, p: 1 }}>
    <CardActions disableSpacing>
      <IconButton sx={{ marginRight: 2 }}>{icon}</IconButton>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {title}
      </Typography>
      {expanded && (
        <Button variant="text" onClick={onNewFieldMapping}>
          + New Field Mapping
        </Button>
      )}
      <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
      <Checkbox checked={removeUnmapped} onChange={onRemoveUnmapped} />
      <Typography mr={2}>Don’t import data in unmapped fields</Typography>
      <ExpandMore expand={expanded} onClick={onExpand} aria-expanded={expanded} aria-label="show more">
        <ExpandMoreIcon />
      </ExpandMore>
    </CardActions>
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <TableMapping tableData={tableData} onDeleteField={onDeleteField} onUpdateFieldMapping={onUpdateFieldMapping} />
    </Collapse>
  </Card>
);
