import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { Option } from '../shared/Constants';
import { FC, useMemo } from 'react';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import _ from 'lodash';
import { FormErrorMessage } from 'pages/RuleLibrary/components/ui/FormErrorMessage';
import { Box, InputAdornment } from '@mui/material';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorExclamation.svg';

export interface FieldSelectorDropdownProps {
  value: string;
  fieldOptions: Option[];
  targetPath: string;
  id: string;
  onChange: (value: string) => void;
}

export const FieldSelectorDropdown: FC<FieldSelectorDropdownProps> = (props: FieldSelectorDropdownProps) => {
  const { id, value, fieldOptions, onChange, targetPath } = props;
  const { formError } = useRuleForm();

  const error = useMemo(() => formError && _.get(formError, targetPath), [formError, targetPath]);

  return (
    <Box>
      <ControlledCustomDropdown<string>
        id={id}
        placeholder="value"
        label=""
        value={value || ''}
        options={fieldOptions || []}
        sx={{ backgroundColor: 'neutral.white' }}
        fallbackOption={{ label: 'Select Field', value: '' }}
        onSelect={onChange}
        InputProps={{
          startAdornment: error && <InputAdornment position="start">{<ErrorIcon />}</InputAdornment>,
        }}
      />
      <FormErrorMessage errorMessage={error} />
    </Box>
  );
};
