import { ConditionType } from 'pages/RuleForm/types';

import {
  LookUpTable,
  RuleWhereArray,
  FieldReference,
  RuleHavingArray,
  FrontEndRuleDto,
} from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { RuleDepartment, RuleRisk, RuleTemporalTable } from 'store/dataRaptorRule/types';

export interface Option {
  value: string;
  label: string;
  xsd_type?: string;
  type?: string;
  relationshipName?: string;
}

export interface SubQuery {
  tempId?: string;
  alias: string;
  table: string;
  where: RuleWhereArray;
}

export enum RuleFormModalCreateMenuItem {
  RecordType = 'Record Type',
  RuleDetails = 'Rule Details',
  Dataset = 'Dataset (Optional)',
  Conditions = 'Conditions',
}

export interface RuleFormLibraryModal {
  open: boolean;
  menuIndex: number;
  menuItems: RuleFormModalCreateMenuItem[];
  loading: boolean;
  loadingMessage: string;
}

export interface AISuggestions {
  table?: string;
  ruleName?: string;
  description?: string;
  department?: string;
  riskLevel?: string;
  ruleType?: string;
  violationScore?: number;
}

export interface AIRuleContext {
  table?: string;
  ruleName?: string;
  description?: string;
  department?: string;
  riskLevel?: string;
  ruleType?: string;
  violationScore?: number;
}

export interface AIAssistantData {
  open: boolean;
  tiledWindow: boolean;
  suggestions: AISuggestions;
}

export interface RuleFormState {
  temporalTable?: RuleTemporalTable | null;
  aiAssistant: AIAssistantData;
  subQueries: SubQuery[];
  subQueryForm: SubQuery;
  ruleName: string;
  description: string;
  violationScore: number;
  category: string;
  riskLevel: string;
  department: string;
  where: RuleWhereArray;
  having: RuleHavingArray;
  formError: any;
  ruleTemplate?: FrontEndRuleDto;
  showLookUpSelector: boolean;
  showSubQueryModal: boolean;
  subQueryModalIndex: number;
  tableOptions: Option[];
  lookUpComponents: (LookUpTable | FieldReference | any)[];
  lookUpIndex: number | undefined;
  lookUpConditionType: ConditionType;
  lookUpTargetPath: string;
  fieldOptions?: Option[];
  fieldForeignRefOptions?: Option[];
  ruleFormLibraryModal: RuleFormLibraryModal;
}

export interface RuleDetail {
  name: string;
  description: string;
  violationScore: number;

  ruleRisk: RuleRisk;
  ruleType: string;
  ruleDepartment: RuleDepartment;
}
