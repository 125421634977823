import theme from 'core/theme';

import { FC, useCallback } from 'react';
import { Box, Divider, Grid, Modal, SxProps, Theme, Typography } from '@mui/material';

import { useRuleFormStore } from 'store/ruleForm/hook';
import { useTemporalTableForm } from 'store/temporalTableForm/hooks';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';

import { useTemporalTableModalHook } from './TemporalTableModal.hook';
import { useTemporalTableModalEffect } from './TemporalTableModal.effect';

import { TableSelection } from './components/TemporalTableContent.tsx/TableSelection/TableSelection';
import { TableCondition } from './components/TemporalTableContent.tsx/TableCondition/TableCondition';

import { TemplateTableMenu } from './components/TemporalTableMenu';
import { TableConditionFooter } from './components/TemporalTableContent.tsx/TableCondition/TableConditionFooter';
import { TableSelectionFooter } from './components/TemporalTableContent.tsx/TableSelection/TableSelectionFooter';

const style: SxProps<Theme> = {
  boxShadow: 10,
  borderRadius: '8px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '90%',
  height: '90%',
  maxWidth: '1200px',
  minHeight: '600px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
};

interface StepUiData {
  footer: JSX.Element;
  content: JSX.Element;
}

const numberOfSteps = 2;
export const TemporalTableModal: FC = () => {
  const {
    setMenuIndex,
    data: { open, menuIndex },
  } = useTemporalTableForm();

  useTemporalTableModalEffect();
  const { temporalTable } = useRuleFormStore();
  const { handleCloseModal } = useTemporalTableModalHook();

  const handleNext = useCallback(() => {
    if (menuIndex < numberOfSteps - 1) {
      setMenuIndex(menuIndex + 1);
    }
  }, [menuIndex, setMenuIndex]);

  const uiSteps: StepUiData[] = [
    {
      content: <TableSelection />,
      footer: <TableSelectionFooter handleClose={handleCloseModal} handleNext={handleNext} />,
    },
    {
      content: <TableCondition />,
      footer: <TableConditionFooter handleClose={handleCloseModal} />,
    },
  ];

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid sx={{ width: '100%', height: '100%', display: 'flex' }}>
          <Box
            sx={{
              width: '220px',
              height: '100%',
              px: 1,
              py: 2,
              borderRadius: '8px',
              backgroundColor: theme.palette.lightBg.main,
            }}
          >
            <TemplateTableMenu />
          </Box>
          <Box flexGrow={10} sx={{ px: 6, pt: 3, pb: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h2">{!temporalTable ? 'Create' : `Edit`} Temporary Table</Typography>
                <Box sx={{ ':hover': { cursor: 'pointer' } }} onClick={handleCloseModal}>
                  <CrossIcon />
                </Box>
              </Box>
              <Divider />
              <Box flexGrow={1} sx={{ overflowY: 'auto', overflowX: 'auto' }}>
                {uiSteps[menuIndex].content}
              </Box>
              <Divider />
              <Box height={'60px'}>{uiSteps[menuIndex].footer}</Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};
