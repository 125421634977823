import React from 'react';
import { generateNonOverlappingPosition } from './utils';
import { Stack, Typography, Box } from '@mui/material';

const words = [
  'Competitor',
  'Code',
  'Choose',
  'Backup',
  'Production',
  'Develop',
  'Choose',
  'Backup',
  'Production',
  'Develop',
  'Database',
  'Work',
];

const KeywordsContainer = () => {
  const containerRef = React.useRef<HTMLElement>(null);
  const [containerSize, setContainerSize] = React.useState(0);

  const generateNonOverlappingPositions = (words: string[]) => {
    const containerSize = {
      height: containerRef?.current?.clientHeight || 0,
      width: containerRef?.current?.clientWidth || 0,
    };
    const allPositions = words.reduce(
      (acc, currentWord) => {
        const fontSize = 8 + Math.floor(Math.random() * 12);
        const position = generateNonOverlappingPosition({
          containerSize,
          currentWord,
          fontSize,
          wordsPositions: acc,
        });

        return [
          ...acc,
          {
            word: currentWord,
            position,
            fontSize,
          },
        ];
      },
      [] as {
        word: string;
        position: {
          left: number;
          top: number;
        };
        fontSize: number;
      }[],
    );

    return allPositions;
  };

  React.useEffect(() => {
    const handleResize = (entries: ResizeObserverEntry[]) => {
      for (const entry of entries) {
        const { height } = entry.contentRect;
        setContainerSize(height);
      }
    };

    const observer = new ResizeObserver(handleResize);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const wordsPositionned = React.useMemo(() => generateNonOverlappingPositions(words), [containerSize]);

  return (
    <Stack sx={{ border: '1px solid', borderColor: 'neutral.n300', p: 1 }}>
      <Typography variant="p12">CALL KEY TOPICS</Typography>

      <Box sx={{ position: 'relative', width: '100%', minHeight: '150px' }} ref={containerRef}>
        {wordsPositionned.map(({ word, fontSize, position }) => (
          <Typography
            key={`cloud_word_${word}`}
            sx={{
              position: 'absolute',
              top: position.top,
              left: position.left,
              fontSize: `${fontSize}px`,
            }}
          >
            {word}
          </Typography>
        ))}
      </Box>
    </Stack>
  );
};

export default KeywordsContainer;
