import { Box, Grid, InputAdornment } from '@mui/material';
import { GridItem } from 'components/ui';
import { MigrationFieldValuesDropDown } from 'components/MigrationComponents/MigrationFieldValuesDropDown';
import { FC, useCallback, useMemo } from 'react';
import { PRIMITIVE_OPTIONS, BOOLEAN_OPTIONS, NULL_OPTIONS } from '../../Constants';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { PrimitiveValue, ConditionalValue } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { CustomInput } from 'components/CustomInput';
import { useRuleFormStore } from 'store/ruleForm/hook';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorExclamation.svg';
import _ from 'lodash';
import { FormErrorMessage } from '../../../../../../ui/FormErrorMessage';

interface PrimitiveValueFormProps {
  targetPath: string;
  conditionalValueComponent: PrimitiveValue;
  setFieldValue: (payload: { path: string; value: any }) => void;
  getConditionTypeDropdown: () => JSX.Element;
  xmlFilterType?: string[];
  shouldRenderPickListComponent: boolean;
  selectedMigration: string;
  tableName: string;
  previousValueComponent?: ConditionalValue;
}

export const PrimitiveValueForm: FC<PrimitiveValueFormProps> = (props) => {
  const {
    conditionalValueComponent,
    getConditionTypeDropdown,
    selectedMigration,
    setFieldValue,
    shouldRenderPickListComponent,
    tableName,
    targetPath,
    xmlFilterType,
    previousValueComponent,
  } = props;

  const primitiveValue = conditionalValueComponent as PrimitiveValue;

  const { formError } = useRuleFormStore();

  const formatError = useMemo(() => formError && _.get(formError, `${targetPath}.format`), [formError, targetPath]);
  const valueError = useMemo(() => formError && _.get(formError, `${targetPath}.value`), [formError, targetPath]);

  const allowedPrimitiveValues = useMemo(() => {
    let allowedPrimitiveValues = PRIMITIVE_OPTIONS;
    if (xmlFilterType) {
      allowedPrimitiveValues = allowedPrimitiveValues.filter((option) =>
        option.validTypes.some((type) => xmlFilterType.includes(type)),
      );
    }
    return allowedPrimitiveValues;
  }, [xmlFilterType]);

  const isNullOrBooleanFormat = useMemo(
    () => primitiveValue?.format === 'null' || primitiveValue?.format === 'boolean',
    [primitiveValue?.format],
  );

  const nullOrBooleanOptions = useMemo(
    () => (isNullOrBooleanFormat && primitiveValue?.format === 'boolean' ? BOOLEAN_OPTIONS : NULL_OPTIONS),
    [isNullOrBooleanFormat, primitiveValue?.format],
  );

  const isNullValue = useMemo(
    () => primitiveValue?.value === 'Null' && primitiveValue.format === 'null',
    [primitiveValue.format, primitiveValue?.value],
  );

  const handleFormatChange = useCallback(
    (value: string) => {
      setFieldValue({ path: `${targetPath}.format`, value });
      if (value === 'null') {
        setFieldValue({ path: `${targetPath}.value`, value: 'Null' });
      } else {
        setFieldValue({ path: `${targetPath}.value`, value: '' });
      }
    },
    [setFieldValue, targetPath],
  );

  if (shouldRenderPickListComponent) {
    return (
      <Grid display={'flex'} flexDirection={'column'} width={'100%'} gap={1}>
        <Box display={'flex'}>
          <GridItem xs={6}>{getConditionTypeDropdown()}</GridItem>
        </Box>
        <Box>
          <GridItem flex={1}>
            <Box>
              <MigrationFieldValuesDropDown
                id={`${targetPath}.value`}
                value={primitiveValue?.value}
                migrationId={selectedMigration}
                table={tableName}
                sx={{
                  backgroundColor: 'neutral.white',
                  ...(valueError ? { border: '1px solid red', borderRadius: '4px' } : {}),
                }}
                fieldName={previousValueComponent?.value}
                placeholder={'Select a Value'}
                onChange={(value) => {
                  setFieldValue({ path: `${targetPath}.format`, value: 'Text' });
                  setFieldValue({ path: `${targetPath}.value`, value });
                }}
                InputProps={{
                  startAdornment: valueError && <InputAdornment position="start">{<ErrorIcon />}</InputAdornment>,
                }}
              />
              <FormErrorMessage errorMessage={valueError} />
            </Box>
          </GridItem>
        </Box>
      </Grid>
    );
  }

  return (
    <Grid display={'flex'} flexDirection={'column'} width={'100%'} gap={1}>
      <Box display={'flex'}>
        <GridItem xs={6}>{getConditionTypeDropdown()}</GridItem>
      </Box>
      <Box display={'flex'} gap={1}>
        <GridItem xs={6}>
          <Box>
            <ControlledCustomDropdown<string>
              id={`${targetPath}.format`}
              placeholder="Format"
              value={primitiveValue?.format || ''}
              options={allowedPrimitiveValues || []}
              sx={{
                backgroundColor: 'neutral.white',
                ...(formatError ? { border: '1px solid red', borderRadius: '4px' } : {}),
              }}
              fallbackOption={{ label: 'Format', value: '' }}
              onSelect={handleFormatChange}
              InputProps={{
                startAdornment: formatError && <InputAdornment position="start">{<ErrorIcon />}</InputAdornment>,
              }}
            />
            <FormErrorMessage errorMessage={formatError} />
          </Box>
        </GridItem>
        <GridItem xs={6}>
          <Box>
            {isNullOrBooleanFormat && !isNullValue && (
              <ControlledCustomDropdown<string>
                id={`${targetPath}.value`}
                placeholder="value"
                value={primitiveValue?.value || ''}
                options={nullOrBooleanOptions || []}
                sx={{
                  backgroundColor: 'neutral.white',
                  ...(valueError ? { border: '1px solid red', borderRadius: '4px' } : {}),
                }}
                fallbackOption={{ label: 'Select Value', value: '' }}
                onSelect={(value) => setFieldValue({ path: `${targetPath}.value`, value })}
                InputProps={{
                  startAdornment: valueError && <InputAdornment position="start">{<ErrorIcon />}</InputAdornment>,
                }}
              />
            )}
            {!isNullOrBooleanFormat && (
              <CustomInput
                id={targetPath}
                name={targetPath}
                value={primitiveValue.value}
                type={primitiveValue?.format}
                sx={valueError ? { border: '1px solid red' } : {}}
                onChange={(e) => setFieldValue({ path: `${targetPath}.value`, value: e.target.value })}
              />
            )}
            <FormErrorMessage errorMessage={valueError} />
          </Box>
        </GridItem>
      </Box>
    </Grid>
  );
};
