import { Container } from '@mui/material';
import { resultIntegrationAuth } from 'http/auth';
import qs from 'qs';
import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { useIntegration } from 'store/integration/hooks';

const IntegrationRedirectPage: FC = () => {
  const { authCallback, success, error } = useIntegration();
  const [message, setMessage] = useState('Installing please wait ...');

  const bc = useMemo(() => new BroadcastChannel('integration-events'), []);

  const close = useCallback(() => {
    window.close();
  }, []);

  useEffect(() => {
    const params = window.location.search;
    const paths = window.location.pathname.split('/').filter((i) => !!i);
    const appId = paths[paths.length - 1];
    let state: any;
    let query: any = {};
    query = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    try {
      state = JSON.parse(query.state as string);
    } catch (err) {
      console.log('invalid json state');
    }

    if (state) {
      if (state.action === 'login') {
        setMessage('Logging in please wait ...');
        resultIntegrationAuth(appId, query)
          .then((data) => {
            bc.postMessage({ type: 'auth', data });
          })
          .catch((err) => {
            bc.postMessage({ type: 'error', data: err.message || 'An error has occurred please try again' });
          })
          .finally(() => {
            close();
          });
      }
    } else {
      if (appId === 'salesforce') {
        const calculatedAppIds = query.state.split('@')[1];
        authCallback(calculatedAppIds + params);
      } else {
        authCallback(appId + params);
      }
    }
  }, [authCallback, bc, close]);

  useEffect(() => {
    if (success) {
      bc.postMessage({ type: 'success', data: success });
      close();
    }
  }, [bc, close, success]);

  useEffect(() => {
    if (error) {
      bc.postMessage({ type: 'error', data: error });
      close();
    }
  }, [bc, close, error]);

  return (
    <Container maxWidth="lg">
      <h1 onClick={close}>{error || message}</h1>
    </Container>
  );
};

export default IntegrationRedirectPage;
