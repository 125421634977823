import React from 'react';
import { Grid, Box, CircularProgress, Typography, Divider } from '@mui/material';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';
import isSameMonth from 'date-fns/isSameMonth';
import PeriodSection from './components/PeriodSection';
import { format } from 'date-fns';
import { useGraphRender } from 'store/graphRender/hooks';

const Activity = ({ migrationId, node }: { migrationId: string; node: any }) => {
  const {
    data: {
      nodePanelState: {
        activityTab: { excludedStatuses = [], excludedTypes = [] },
      },
    },
  } = useGraphRender();
  const { data: eventsData = [], isFetching: eventsLoading } = useGetMigrationTableDataByIdQuery({
    migrationId: migrationId,
    tableId: 'Event',
    skip: 0,
    take: 0,
    conditions: [
      {
        field: node.label === 'Contact' ? 'WhoId' : node.label === 'Account' ? 'AccountId' : 'WhatId',
        operator: '=',
        value: `'${node.id}'`,
      },
    ],
    //conditions: [],
  });

  const { data: tasksData = [], isFetching: tasksLoading } = useGetMigrationTableDataByIdQuery({
    migrationId: migrationId,
    tableId: 'Task',
    skip: 0,
    take: 0,
    conditions: [
      {
        field: node.label === 'Contact' ? 'WhoId' : node.label === 'Account' ? 'AccountId' : 'WhatId',
        operator: '=',
        value: `'${node.id}'`,
      },
    ],
    // conditions: [],
  });

  const { data: contactsData = [], isFetching: contactsLoading } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'Contact',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'Id',
          operator: 'in',
          value: `(${[...tasksData, ...eventsData].map((activity) => `'${activity.WhoId}'`).join(' , ')})`,
        },
      ],
    },
    {
      skip: (!tasksData.length && !eventsData.length) || node.label === 'Contact',
    },
  );

  const { data: opportunitiesData = [], isFetching: opportunitiesLoading } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'Opportunity',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'Id',
          operator: 'in',
          value: `(${[...tasksData, ...eventsData]
            .filter((activity) => activity.WhatId)
            .map((activity) => `'${activity.WhatId}'`)
            .join(' , ')})`,
        },
      ],
    },
    {
      skip: (!tasksData.length && !eventsData.length) || node.label === 'Contact',
    },
  );

  const activitiesByPeriod = React.useMemo(() => {
    const allActivities = [...eventsData, ...tasksData];

    const periodsObject = allActivities
      .filter(
        (activity) =>
          !excludedStatuses.includes(activity.Status?.toLowerCase()) &&
          !excludedTypes.includes(activity.EventSubtype?.toLowerCase() || activity?.TaskSubtype?.toLowerCase()),
      )
      .reduce((acc, currentActivity) => {
        const activityDate = new Date(currentActivity.CreatedDate);
        if (isSameMonth(activityDate, new Date()))
          return {
            ...acc,
            currentMonth: acc.currentMonth ? [...acc.currentMonth, currentActivity] : [currentActivity],
          };
        const activityMonth = format(activityDate, '01-MM-yyyy');
        return {
          ...acc,
          [activityMonth]: acc[activityMonth] ? [...acc[activityMonth], currentActivity] : [currentActivity],
        };
      }, {});

    return periodsObject;
  }, [eventsData, tasksData, excludedStatuses, excludedTypes]);

  const isLoading = eventsLoading || tasksLoading || contactsLoading || opportunitiesLoading;

  return (
    <Grid display={'flex'} direction={'column'} gap={1} p={1} bgcolor={'#F6F8FB'}>
      <Box sx={{ height: '100%' }}>
        {isLoading ? (
          <Box display={'flex'} alignItems={'center'} height={'50px'} gap={1}>
            <CircularProgress size={'16px'} color="primary" />
            <Typography>Loading activities...</Typography>
          </Box>
        ) : (
          <>
            {activitiesByPeriod['currentMonth'] && (
              <PeriodSection
                key={`periodCOntainer_currentMonth`}
                isCurrentMonth
                activities={activitiesByPeriod['currentMonth']}
                node={node}
                contacts={[...contactsData, ...opportunitiesData]}
              />
            )}
            <Divider sx={{ width: '80%', mx: 'auto', my: 1 }} />
            {Object.keys(activitiesByPeriod)
              .filter((period) => period !== 'currentMonth')
              .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
              .map((period) => (
                <PeriodSection
                  key={`periodContainer_${period}`}
                  activities={activitiesByPeriod[period]}
                  node={node}
                  contacts={[...contactsData, ...opportunitiesData]}
                />
              ))}
          </>
        )}
      </Box>
    </Grid>
  );
};

export default Activity;
