import * as React from 'react';
import { Box, FormControl, ListItemIcon, MenuItem, Select, Typography } from '@mui/material';

interface DataSource {
  icon: string;
  name: string;
}

type SelectDataSourceProps = {
  value: string;
  dataSources: DataSource[];
  onChange: (value: string) => void;
};

const SelectDataSource: React.FC<SelectDataSourceProps> = ({ value, dataSources, onChange }) => {
  const renderValueWithIcon = (selected: string) => {
    const selectedSource = dataSources.find((source) => source.name === selected);

    if (!selectedSource) {
      return <Typography color={'neutral.n400'}>Select preferred data source</Typography>;
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {selectedSource.icon && (
          <img src={selectedSource.icon} alt={selectedSource.name} style={{ width: 24, height: 24, marginRight: 8 }} />
        )}
        {selectedSource.name}
      </Box>
    );
  };

  return (
    <FormControl
      fullWidth
      size="small"
      variant="outlined"
      sx={{ '& .MuiOutlinedInput-notchedOutline': { border: 'none' } }}
    >
      <Select
        displayEmpty
        value={value}
        onChange={(e) => onChange(e.target.value)}
        renderValue={(selected) => renderValueWithIcon(selected)}
      >
        <MenuItem value="">
          <em>Select preferred data source</em>
        </MenuItem>
        {dataSources.map((source) => (
          <MenuItem key={source.name} value={source.name}>
            <ListItemIcon>
              {source.icon ? <img src={source.icon} alt={source.name} style={{ width: 24, height: 24 }} /> : null}
            </ListItemIcon>
            {source.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectDataSource;
