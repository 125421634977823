import { FC, ReactNode } from 'react';
import { Dialog, DialogContent, DialogActions, IconButton, Typography, Divider, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  subtitle?: string;
  children: ReactNode;
  actions?: ReactNode;
}

const CustomDialog: FC<CustomDialogProps> = ({ open, onClose, title, subtitle, children, actions }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px 24px' }}>
        <Box>
          <Typography variant="h3">{title}</Typography>
          {subtitle && (
            <Typography variant="p12" sx={{ color: 'neutral.n400' }}>
              {subtitle}
            </Typography>
          )}
        </Box>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider variant="middle" />

      <DialogContent sx={{ my: 2 }}>{children}</DialogContent>

      {actions && <Divider variant="middle" />}

      {actions && <DialogActions sx={{ padding: '16px 24px', justifyContent: 'flex-end' }}>{actions}</DialogActions>}
    </Dialog>
  );
};

export default CustomDialog;
