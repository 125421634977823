import { useState, useEffect } from 'react';

import {
  Box,
  Link,
  Dialog,
  Button,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  LinearProgress,
  CircularProgress,
} from '@mui/material';

import LockIcon from '@mui/icons-material/Lock';
import CloseIcon from '@mui/icons-material/Close';
import CustomerCityLogo from 'assets/GraphSpace/logo192.png';
import { useGraphSpace } from 'store/graphSpace/hooks';

interface AuthModalProps {
  open: boolean;
  onClose: () => void;
  onAuthenticate: () => void;
}

const requirements = [
  'Access your basic profile information',
  'Read and manage your data integration settings',
  'Allow data synchronization between services',
  'Monitor ongoing data connections',
  'Modify existing connections when necessary',
];

const AuthModal = ({ open, onClose, onAuthenticate }: AuthModalProps) => {
  const [progress, setProgress] = useState(0);
  const [color, setColor] = useState('orange');

  const { currentDataSource } = useGraphSpace();
  const [loadingIcon, setLoadingIcon] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const handleAllowClick = () => {
    setLoadingIcon(true);
    setColor('blue');
    setShowProgress(true);
  };

  useEffect(() => {
    if (showProgress) {
      const interval = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return oldProgress + 10;
        });
      }, 1000);
    }
  }, [showProgress]);

  useEffect(() => {
    if (progress === 100) {
      setLoadingIcon(false);
    }
  }, [progress]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Source Authentication
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4, mx: 5 }}>
        <Box
          sx={{
            width: '178px',
            border: `2px dashed ${color}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            p: 2,
          }}
        >
          <Box>
            <img
              alt="Source 2"
              style={{ width: '48px', height: '48px', objectFit: 'cover' }}
              src={currentDataSource?.icon ?? 'https://cdn-icons-png.flaticon.com/128/5968/5968914.png'}
            />
          </Box>
          <Typography variant="p16">{currentDataSource?.name}</Typography>
        </Box>

        <IconButton
          sx={{
            border: `2px solid ${color}`,
            borderRadius: '50%',
            width: 60,
            height: 60,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mx: 2,
          }}
        >
          {loadingIcon ? <CircularProgress size={24} /> : <LockIcon />}
        </IconButton>
        <Box
          sx={{
            width: '178px',
            border: `2px dashed ${color}`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            p: 2,
          }}
        >
          <Box>
            <img src={CustomerCityLogo} alt="Source 2" style={{ width: '48px', height: '48px', objectFit: 'cover' }} />
          </Box>
          <Typography variant="p16">Customer City</Typography>
        </Box>
      </Box>

      {!showProgress ? (
        <>
          <DialogContent>
            <Typography>
              <strong>Customer City</strong> would like to:
            </Typography>
            <ul>
              {requirements.map((requirement, index) => (
                <li key={index}>
                  <Typography>{requirement}</Typography>
                </li>
              ))}
            </ul>
            <Typography color={'neutral.n400'}>
              You can stop or remove this integration at{' '}
              <Link
                href="#"
                sx={{
                  color: 'blue',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                ControlTower
              </Link>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button variant="contained" onClick={handleAllowClick} disabled={loadingIcon}>
              Allow
            </Button>
          </DialogActions>
        </>
      ) : (
        <Box
          sx={{
            mt: progress < 100 ? 4 : 0,
            mb: 2,
            mx: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {progress < 100 ? (
            <>
              <LinearProgress variant="determinate" value={progress} sx={{ width: '100%', mb: 2 }} />
              <Typography color={'neutral.n400'}>Authentication might take a few seconds. Please wait...</Typography>
            </>
          ) : (
            <DialogContent>
              <Typography mb={2}>You have now successfully authenticated your account *********oe@mail.com</Typography>
              <Typography color={'neutral.n400'}>
                You can stop or remove this integration at{' '}
                <Link
                  href="#"
                  sx={{
                    color: 'blue',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  ControlTower
                </Link>
              </Typography>
            </DialogContent>
          )}
        </Box>
      )}
    </Dialog>
  );
};

export default AuthModal;
