import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import {
  setError,
  setSuccess,
  setLoading,
  createRule,
  getRulesByMigrationAndTable,
  setSelectedRuleId,
  deleteRuleById,
  updateRuleById,
  getRuleDepartments,
  getRuleRisks,
  setFormMode,
  getRuleTypes,
  getRuleByMigrationAndRuleId,
  getRuleValidationPatterns,
} from './actions';
import { DataRaptorRuleState } from './types';
import { resetApp } from 'store/actions';
import _ from 'lodash';

const apiCallInitialState = {
  success: false,
  error: false,
  loading: false,
};

export const initialState: DataRaptorRuleState = {
  success: false,
  error: false,
  loading: false,
  deleteRuleByIdError: false,
  deleteRuleByIdSuccess: false,
  deleteRuleByIdLoading: false,
  updateRuleByIdError: false,
  updateRuleByIdSuccess: false,
  updateRuleByIdLoading: false,
  getRuleRisksState: apiCallInitialState,
  getRuleDepartmentsState: apiCallInitialState,
  getRuleTypesState: apiCallInitialState,
  getRuleByMigrationAndRuleIdState: apiCallInitialState,
  getRuleValidationPatternsState: apiCallInitialState,
  data: {},
};

const dataRaptorRuleReducer = createSlice({
  name: 'data-raptor-rule',
  initialState: _.cloneDeep(initialState),
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<DataRaptorRuleState>): void => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(setLoading, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(setSelectedRuleId, (state, { payload }) => {
      state.data.selectedRuleId = payload;
    });

    builder.addCase(setFormMode, (state, { payload }) => {
      state.data.formMode = payload;
    });

    builder.addCase(getRuleValidationPatterns.pending, (state) => {
      state.getRuleValidationPatternsState.loading = true;
      state.getRuleValidationPatternsState.error = false;
      state.getRuleValidationPatternsState.success = false;
    });

    builder.addCase(getRuleValidationPatterns.rejected, (state, { error }) => {
      state.getRuleValidationPatternsState.loading = false;
      state.getRuleValidationPatternsState.error = error?.message ?? true;
      state.getRuleValidationPatternsState.success = false;
    });

    builder.addCase(getRuleValidationPatterns.fulfilled, (state, { payload }) => {
      state.getRuleValidationPatternsState.loading = false;
      state.getRuleValidationPatternsState.error = false;
      state.getRuleValidationPatternsState.success = true;
      state.data.ruleValidationPatterns = payload;
    });

    builder.addCase(createRule.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });

    builder.addCase(createRule.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error?.message ?? true;
      state.success = false;
    });

    builder.addCase(createRule.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.data.createdRule = payload;
    });

    builder.addCase(getRulesByMigrationAndTable.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });

    builder.addCase(getRulesByMigrationAndTable.rejected, (state, { error }) => {
      state.loading = false;
      state.error = error?.message ?? true;
      state.success = false;
    });

    builder.addCase(getRulesByMigrationAndTable.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.success = true;
      state.data.rulesByMigrationAndTable = payload.sort((a, b) => a.name.localeCompare(b.name));
    });

    builder.addCase(deleteRuleById.pending, (state) => {
      state.deleteRuleByIdLoading = true;
      state.deleteRuleByIdError = false;
      state.deleteRuleByIdSuccess = false;
    });

    builder.addCase(deleteRuleById.rejected, (state, { error }) => {
      state.deleteRuleByIdLoading = false;
      state.deleteRuleByIdError = error?.message ?? true;
      state.deleteRuleByIdSuccess = false;
    });

    builder.addCase(deleteRuleById.fulfilled, (state) => {
      state.deleteRuleByIdLoading = false;
      state.deleteRuleByIdError = false;
      state.deleteRuleByIdSuccess = true;
    });

    builder.addCase(updateRuleById.pending, (state) => {
      state.updateRuleByIdLoading = true;
      state.updateRuleByIdError = false;
      state.updateRuleByIdSuccess = false;
    });

    builder.addCase(updateRuleById.rejected, (state, { error }) => {
      state.updateRuleByIdLoading = false;
      state.updateRuleByIdError = error?.message ?? true;
      state.updateRuleByIdSuccess = false;
    });

    builder.addCase(updateRuleById.fulfilled, (state) => {
      state.updateRuleByIdLoading = false;
      state.updateRuleByIdError = false;
      state.updateRuleByIdSuccess = true;
    });

    builder.addCase(getRuleRisks.pending, (state) => {
      state.getRuleRisksState.loading = true;
      state.getRuleRisksState.error = false;
      state.getRuleRisksState.success = false;
    });

    builder.addCase(getRuleRisks.rejected, (state, { error }) => {
      state.getRuleRisksState.loading = false;
      state.getRuleRisksState.error = error?.message ?? true;
      state.getRuleRisksState.success = false;
    });

    builder.addCase(getRuleRisks.fulfilled, (state, { payload }) => {
      state.getRuleRisksState.loading = false;
      state.getRuleRisksState.error = false;
      state.getRuleRisksState.success = true;
      state.data.ruleRisks = payload;
    });

    builder.addCase(getRuleDepartments.pending, (state) => {
      state.getRuleDepartmentsState.loading = true;
      state.getRuleDepartmentsState.error = false;
      state.getRuleDepartmentsState.success = false;
    });

    builder.addCase(getRuleDepartments.rejected, (state, { error }) => {
      state.getRuleDepartmentsState.loading = false;
      state.getRuleDepartmentsState.error = error?.message ?? true;
      state.getRuleDepartmentsState.success = false;
    });

    builder.addCase(getRuleDepartments.fulfilled, (state, { payload }) => {
      state.getRuleDepartmentsState.loading = false;
      state.getRuleDepartmentsState.error = false;
      state.getRuleDepartmentsState.success = true;
      state.data.ruleDepartments = payload;
    });

    builder.addCase(getRuleTypes.pending, (state) => {
      state.getRuleTypesState.loading = true;
      state.getRuleTypesState.error = false;
      state.getRuleTypesState.success = false;
    });

    builder.addCase(getRuleTypes.rejected, (state, { error }) => {
      state.getRuleTypesState.loading = false;
      state.getRuleTypesState.error = error?.message ?? true;
      state.getRuleTypesState.success = false;
    });

    builder.addCase(getRuleTypes.fulfilled, (state, { payload }) => {
      state.getRuleTypesState.loading = false;
      state.getRuleTypesState.error = false;
      state.getRuleTypesState.success = true;
      state.data.ruleTypes = payload;
    });

    builder.addCase(getRuleByMigrationAndRuleId.pending, (state) => {
      state.getRuleByMigrationAndRuleIdState.loading = true;
      state.getRuleByMigrationAndRuleIdState.error = false;
      state.getRuleByMigrationAndRuleIdState.success = false;
    });

    builder.addCase(getRuleByMigrationAndRuleId.rejected, (state, { error }) => {
      state.getRuleByMigrationAndRuleIdState.loading = false;
      state.getRuleByMigrationAndRuleIdState.error = error?.message ?? true;
      state.getRuleByMigrationAndRuleIdState.success = false;
    });

    builder.addCase(getRuleByMigrationAndRuleId.fulfilled, (state, { payload }) => {
      state.getRuleByMigrationAndRuleIdState.loading = false;
      state.getRuleByMigrationAndRuleIdState.error = false;
      state.getRuleByMigrationAndRuleIdState.success = true;
      state.data.ruleByMigrationAndRuleId = payload;
    });
    builder.addCase(resetApp, (state) => {
      const initialStateTmp = _.cloneDeep(initialState);
      Object.keys(state).forEach((key) => {
        // @ts-ignore
        state[key] = initialStateTmp[key];
      });
    });
  },
});

export default dataRaptorRuleReducer.reducer;
