import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { getDeduplicationResultByIds, getDeduplicationResultByMigration } from './actions';
import { DeduplicationResultState } from './types';
import { resetApp } from 'store/actions';
import _ from 'lodash';

export const initialState: DeduplicationResultState = {
  loading: false,
  error: false,
  success: false,
  data: {
    getDeduplicationResultByIdsState: { loading: false, error: false, success: false },
    getDeduplicationResultByMigrationState: { loading: false, error: false, success: false },
  },
};

const deduplicationResultReducer = createSlice({
  name: 'deduplication-result',
  initialState: _.cloneDeep(initialState),
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<DeduplicationResultState>): void => {
    builder.addCase(getDeduplicationResultByIds.pending, (state) => {
      state.data.getDeduplicationResultByIdsState.loading = true;
      state.data.getDeduplicationResultByIdsState.error = false;
      state.data.getDeduplicationResultByIdsState.success = false;
      state.data.getDeduplicationResultByIdsState.success = true;
    });

    builder.addCase(getDeduplicationResultByIds.fulfilled, (state, { payload }) => {
      state.data.getDeduplicationResultByIdsState.loading = false;
      state.data.getDeduplicationResultByIdsState.error = false;
      state.data.getDeduplicationResultByIdsState.data = payload;
      state.data.getDeduplicationResultByIdsState.success = true;
    });

    builder.addCase(getDeduplicationResultByIds.rejected, (state, { error }) => {
      state.data.getDeduplicationResultByIdsState.loading = false;
      state.data.getDeduplicationResultByIdsState.error = error?.message ?? true;
      state.data.getDeduplicationResultByIdsState.success = false;
    });

    builder.addCase(getDeduplicationResultByMigration.pending, (state) => {
      state.data.getDeduplicationResultByMigrationState.loading = true;
      state.data.getDeduplicationResultByMigrationState.error = false;
      state.data.getDeduplicationResultByMigrationState.success = false;
      state.data.getDeduplicationResultByMigrationState.success = true;
    });

    builder.addCase(getDeduplicationResultByMigration.fulfilled, (state, { payload }) => {
      state.data.getDeduplicationResultByMigrationState.loading = false;
      state.data.getDeduplicationResultByMigrationState.error = false;
      state.data.getDeduplicationResultByMigrationState.data = payload;
      state.data.getDeduplicationResultByMigrationState.success = true;
    });

    builder.addCase(getDeduplicationResultByMigration.rejected, (state, { error }) => {
      state.data.getDeduplicationResultByMigrationState.loading = false;
      state.data.getDeduplicationResultByMigrationState.error = error?.message ?? true;
      state.data.getDeduplicationResultByMigrationState.success = false;
    });
    builder.addCase(resetApp, (state) => {
      const initialStateTmp = _.cloneDeep(initialState);
      Object.keys(state).forEach((key) => {
        // @ts-ignore
        state[key] = initialStateTmp[key];
      });
    });
  },
});

export default deduplicationResultReducer.reducer;
