import React, { useState } from 'react';

import { Edit as EditIcon } from '@mui/icons-material';
import { Box, Typography, TextField, IconButton, SxProps } from '@mui/material';

interface EditableTextProps {
  value: string;
  onChange: (newValue: string) => void;
  variant?: 'h4' | 'p14';
  color?: string;
  sx?: SxProps;
}

const typographyStyles = {
  h4: {
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 500,
  },
  p14: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 400,
  },
};

export const EditableText: React.FC<EditableTextProps> = ({
  value,
  onChange,
  variant = 'p14',
  color = 'neutral.main',
  sx,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [showIcon, setShowIcon] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
    onChange(inputValue);
  };

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', ...sx }}
      onMouseEnter={() => setShowIcon(true)}
      onMouseLeave={() => setShowIcon(false)}
    >
      {isEditing ? (
        <TextField
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          autoFocus
          fullWidth
          variant="standard"
          InputProps={{
            disableUnderline: true,
            style: {
              fontSize: typographyStyles[variant].fontSize,
              fontWeight: typographyStyles[variant].fontWeight,
              color: color,
              padding: 0,
            },
          }}
        />
      ) : (
        <Typography variant={variant} sx={{ color: color }}>
          {inputValue}
        </Typography>
      )}
      {showIcon && !isEditing && (
        <IconButton onClick={() => setIsEditing(true)} sx={{ ml: 1, p: 0 }}>
          <EditIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};
