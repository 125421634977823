import React from 'react';
import { Menu, MenuItem, Typography, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

interface SettingsMenuProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onAddDataSource: () => void;
  onMergePolicies: () => void;
  onArchiveGraphSpace: () => void;
}

export default function SettingsMenu({
  anchorEl,
  onClose,
  onAddDataSource,
  onMergePolicies,
  onArchiveGraphSpace,
}: SettingsMenuProps) {
  const open = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: { width: '235px' },
      }}
    >
      <Typography variant="body2" sx={{ px: 2, py: 1, color: 'neutral.darkGray' }}>
        Graph Space Settings
      </Typography>
      <MenuItem onClick={onAddDataSource}>
        <AddIcon sx={{ mr: 1 }} />
        Add New Data Source
      </MenuItem>
      <MenuItem onClick={onMergePolicies}>
        <DriveFileRenameOutlineIcon sx={{ mr: 1 }} />
        Merge Policies
      </MenuItem>
      <Divider />
      <MenuItem onClick={onArchiveGraphSpace}>
        <TextSnippetIcon sx={{ mr: 1 }} />
        Archive Graph Space
      </MenuItem>
    </Menu>
  );
}
