import { Box, Grid, Typography, IconButton } from '@mui/material';
import { ConfidenceScore } from 'components/ConfidenceScore';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { FC, useMemo } from 'react';

export const Header: FC<any> = (props) => {
  const { node, confidence_score, toggleOpen } = props;
  const nodeData = node.data;
  const nodeName = useMemo(() => {
    if (nodeData && nodeData.Name) {
      return nodeData.Name;
    }
    return nodeData.FirstName + ' ' + nodeData.LastName;
  }, [nodeData]);

  return (
    <Grid container display={'flex'} flexDirection={'column'}>
      <Grid container display={'flex'} width={'100%'} justifyContent={'space-between'}>
        <Grid item xs={10}>
          <Grid container display={'flex'} flexDirection={'column'} gap={0.4}>
            <Typography variant="h3">{nodeName}</Typography>
            <Typography variant="labelRegular10" sx={{ color: 'neutral.n400' }}>
              CONFIDENCE SCORE
            </Typography>
            <ConfidenceScore confidenceScore={confidence_score} />
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <Box display={'flex'} justifyContent={'end'}>
            <IconButton sx={{ textAlign: 'left', px: 0 }} onClick={() => toggleOpen(false)}>
              <CrossIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
