import { Box, Grid, Typography } from '@mui/material';
import { ReactComponent as DownIcon } from 'assets/icons/chevronDownBlue.svg';
import { ReactComponent as UpIcon } from 'assets/icons/chevronUpBlue.svg';
import theme from 'core/theme';
import { FC, useMemo, useState } from 'react';
import { useGraphRender } from 'store/graphRender/hooks';
import { buildPrimitiveCondition } from '../../../utils';
import { getCurrencyAbbreviated } from 'core/utils';
import { RootCondition } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { useMigration } from 'store/migration/hooks';
import { TableField } from 'store/migration/types';
import { RangeSliderMinMax } from '../../InputFields/RangeSliderMinMax';
import { MigrationFieldValuesDropDown } from '../../InputFields/MigrationFieldValuesDropDown';

const opportunityRootPath = 'opportunity.where';

export const OpportunityQuickFilter: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(!open);
  const {
    partialUpdateQuickGraphFilter,
    data: { quickFilterTmp },
  } = useGraphRender();

  const {
    data: { migrationTableFieldsAndLookUps, migrationId },
  } = useMigration();
  const fieldList = useMemo(() => {
    return migrationTableFieldsAndLookUps[`${migrationId}-Opportunity`]?.data?.['fields'] || [];
  }, [migrationId, migrationTableFieldsAndLookUps]);

  const fieldIndexMap = useMemo(() => {
    const fieldMap: { [x: string]: TableField } = {};
    fieldList.forEach((field) => {
      fieldMap[field.fieldName] = field;
    });
    return fieldMap;
  }, [fieldList]);

  return (
    <>
      <Grid item sx={{ backgroundColor: theme.palette.lightBg.main, borderRadius: '4px' }}>
        <Box
          onClick={handleOpen}
          sx={{ ':hover': { cursor: 'pointer' } }}
          display={'flex'}
          justifyContent={'space-between'}
          px={2}
          alignItems={'center'}
          height={'32px'}
        >
          <Typography
            variant="p12"
            sx={{ textTransform: 'uppercase', color: theme.palette.primary.main, userSelect: 'none' }}
          >
            Opportunities
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>{open ? <UpIcon /> : <DownIcon />}</Box>
        </Box>
      </Grid>
      {open && (
        <Grid container sx={{ display: 'flex', flexDirection: 'column', p: 1, gap: 1 }}>
          {fieldIndexMap['StageName'] && (
            <MigrationFieldValuesDropDown
              label="Stage"
              labelSx={{ color: theme.palette.neutral.main }}
              id={'stage-opportunity-quick-filter'}
              value={(quickFilterTmp?.opportunity?.where?.[0] as RootCondition)?.value?.value || ''}
              onChange={(value: string) =>
                partialUpdateQuickGraphFilter({
                  path: `${opportunityRootPath}[0]`,
                  value: buildPrimitiveCondition('StageName', '=', value, 'string'),
                })
              }
              migrationId={migrationId}
              table={'Opportunity'}
              fieldName={'StageName'}
              placeholder={'Select a Stage'}
            />
          )}

          {fieldIndexMap['Amount'] && (
            <RangeSliderMinMax
              label={'Deal Size'}
              minDistance={1}
              values={(quickFilterTmp?.opportunity?.where?.[1] as RootCondition)?.value?.value}
              onChange={(e) =>
                partialUpdateQuickGraphFilter({
                  path: `${opportunityRootPath}[1]`,
                  value: buildPrimitiveCondition('Amount', 'between', e, 'array'),
                })
              }
              subLabelPrefix="$"
              processNumberLabel={getCurrencyAbbreviated}
              migrationId={migrationId}
              table={'Opportunity'}
              fieldName={'Amount'}
            />
          )}
        </Grid>
      )}
    </>
  );
};
