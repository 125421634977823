import { useCallback, useMemo } from 'react';
import { useDataRaptor } from 'store/dataRaptor/hooks';
import { useDataRaptorRule } from 'store/dataRaptorRule/hooks';
import { CreateDataRaptorRuleDto } from 'store/dataRaptorRule/dto/createRuleDto';
import { useRuleFormStore } from 'store/ruleForm/hook';
import {
  FieldReference,
  FunctionValue,
  LookUpValue,
  PrimitiveValue,
  RegexValue,
  RootCondition,
  RuleHavingArray,
  RuleWhereArray,
  ServiceValue,
  SubQueryValue,
  LogicalOperator,
} from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { createRule, updateRuleById } from 'http/dataRaptor/dataRaptorRules';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { DuplicateValidator } from './classes/DuplicateValidator';
import {
  postRuleTemporalTable,
  postRuleTemporalTableParams,
  putRuleTemporalTable,
} from 'http/dataRaptor/dataRaptorTemporalTables';
import { DataRaptorRule, RuleTemporalTable } from 'store/dataRaptorRule/types';
import { RuleFormMode } from 'pages/RuleLibrary/types';
import { useRuleLibrary } from 'store/ruleLibrary/hook';
import { SubQuery } from 'store/ruleForm/types';

export const useRuleFormSubmissionHook = () => {
  const {
    ruleName,
    violationScore,
    description,
    category,
    riskLevel,
    department,
    where,
    having,
    subQueries,
    subQueryForm,
    setFormError,
    setFormErrorObject,
  } = useRuleFormStore();
  const {
    subPages: {
      temporalTable: {
        data: { tables },
      },
    },
  } = useRuleLibrary();

  const allExistingTemporaryTables = useMemo(() => [...subQueries, ...tables], [subQueries, tables]);
  const { selectedTable, selectedMigration } = useDataRaptor();
  const {
    data: { selectedRuleId, formMode },
  } = useDataRaptorRule();

  const _validateForm = useCallback(() => {
    let valid = true;
    if (!ruleName) {
      setFormError({ path: 'ruleName', value: 'Rule name is required' });
      valid = false;
    }
    if (!selectedTable) {
      setFormError({ path: 'selectedTable', value: 'Table is required' });
      valid = false;
    }
    if (!category) {
      setFormError({ path: 'category', value: 'Category is required' });
      valid = false;
    }
    if (!riskLevel) {
      setFormError({ path: 'riskLevel', value: 'Risk level is required' });
      valid = false;
    }
    if (violationScore < 1 || violationScore > 100) {
      setFormError({ path: 'violationScore', value: 'Violation score must be between 1 and 100' });
      valid = false;
    }
    if (!department) {
      setFormError({ path: 'department', value: 'Department is required' });
      valid = false;
    }
    return valid;
  }, [category, department, riskLevel, ruleName, selectedTable, setFormError, violationScore]);

  const _validateFieldReference = useCallback(
    (fieldReference: FieldReference, targetPath: string) => {
      if (!fieldReference.value || String(fieldReference.value).trim().length <= 0) {
        setFormError({ path: `${targetPath}.value`, value: 'Field Reference value empty' });
        throw new Error('Field Reference value empty');
      }
    },
    [setFormError],
  );

  const _validatePrimitiveValue = useCallback(
    (primitiveValue: PrimitiveValue, targetPath: string) => {
      const errors = [];
      if (!primitiveValue.format || primitiveValue.format.trim().length <= 0) {
        setFormError({ path: `${targetPath}.format`, value: 'Format empty' });
        errors.push('Format empty');
      }
      if (!primitiveValue.value || String(primitiveValue.value).trim().length <= 0) {
        setFormError({ path: `${targetPath}.value`, value: 'Value empty' });
        errors.push('Value empty');
      }
      if (errors.length > 0) {
        throw new Error(errors.join(', '));
      }
    },
    [setFormError],
  );

  const _validateLookUpValue = useCallback(
    (LookUpValue: LookUpValue, targetPath: string) => {
      const lookUpComponents = LookUpValue.value;
      if (
        lookUpComponents.length <= 1 ||
        lookUpComponents[lookUpComponents.length - 1].type !== RuleComponentType.FIELD_REFERENCE
      ) {
        setFormError({ path: `${targetPath}.value`, value: 'Value empty' });
        throw new Error('Value not valid');
      }
    },
    [setFormError],
  );

  const _validateRegexValue = useCallback(
    (regexValue: RegexValue, targetPath: string) => {
      const { fieldName, validationPatterns } = regexValue;
      const errors = [];
      if (!fieldName || fieldName.trim().length <= 0) {
        setFormError({ path: `${targetPath}.fieldName`, value: 'Field Name not valid' });
        errors.push('Field Name not valid');
      }
      if (!validationPatterns || validationPatterns.length <= 0) {
        setFormError({ path: `${targetPath}.validationPatterns`, value: 'Validation Patterns empty' });
        errors.push('Validation Patterns empty');
      }
      if (errors.length > 0) {
        throw new Error(errors.join(', '));
      }
    },
    [setFormError],
  );

  const _validateServiceValue = useCallback(
    (serviceValue: ServiceValue, targetPath: string) => {
      const { fieldName, validation, property } = serviceValue;
      const errors = [];

      if (!fieldName || fieldName.trim().length <= 0) {
        setFormError({ path: `${targetPath}.fieldName`, value: 'Field Name not valid' });
        errors.push('Field Name not valid');
      }

      if (!validation || validation.trim().length <= 0) {
        setFormError({ path: `${targetPath}.validation`, value: 'Validation empty' });
        errors.push('Validation empty');
      }

      if (!property || property.trim().length <= 0) {
        setFormError({ path: `${targetPath}.validation`, value: 'Validation selection empty' });
        errors.push('Validation selection empty');
      }
      if (errors.length > 0) {
        throw new Error(errors.join(', '));
      }
    },
    [setFormError],
  );

  const _validateFunctionValue = useCallback(
    (functionValue: FunctionValue, targetPath: string) => {
      const { function: functionName, numberOfParams, value } = functionValue;
      const errors = [];
      if (!functionName || functionName?.trim().length <= 0) {
        setFormError({ path: `${targetPath}.functionName`, value: 'Function Name not valid' });
        errors.push('Function Name not valid');
      }
      if (numberOfParams !== value.length) {
        setFormError({ path: `${targetPath}.value`, value: 'Function value missing params' });
        errors.push('Function value missing params');
      }
      if (errors.length > 0) {
        throw new Error(errors.join(', '));
      }
      value.forEach((component, index) => {
        const valueTargetPath = `${targetPath}.value[${index}]`;
        try {
          if (component.type === RuleComponentType.FIELD_REFERENCE) {
            _validateFieldReference(component as FieldReference, valueTargetPath);
          } else if (component.type === RuleComponentType.LOOKUP_VALUE) {
            _validateLookUpValue(component as LookUpValue, valueTargetPath);
          } else if (component.type === RuleComponentType.PRIMITIVE_VALUE) {
            _validatePrimitiveValue(component as PrimitiveValue, valueTargetPath);
          } else {
            setFormError({ path: `${valueTargetPath}.type`, value: 'Type not valid' });
            throw new Error('Type not valid on function value param');
          }
        } catch (err: any) {
          setFormError({ path: `${valueTargetPath}`, value: err.message || '' });
        }
      });
    },
    [_validateFieldReference, _validateLookUpValue, _validatePrimitiveValue, setFormError],
  );

  const _validateSubQueryValue = useCallback(
    (subQueryParam: SubQueryValue, targetPath: string) => {
      const subQueryId = subQueryParam.subQueryId;
      const errors = [];
      const aliasExists = subQueryId && String(subQueryId).trim().length > 0;
      if (!aliasExists) {
        setFormError({ path: `${targetPath}.subQueryId`, value: 'Sub Query Alias empty' });
        errors.push('Sub Query Alias empty');
      }
      if (aliasExists) {
        const foundSubQuery = allExistingTemporaryTables.find(
          (subQuery) =>
            (subQuery as SubQuery).tempId === subQueryId ||
            (subQuery as RuleTemporalTable).ruleTempTableId === subQueryId,
        );
        if (!foundSubQuery) {
          setFormError({ path: `${targetPath}.subQueryId`, value: 'Sub Query Value alias does not exists' });
          errors.push('Sub Query Value alias does not exists');
        }
      }
      if (!subQueryParam.value || String(subQueryParam.value).trim().length <= 0) {
        setFormError({ path: `${targetPath}.value`, value: 'value empty' });
        errors.push('value empty');
      }
      if (errors.length > 0) {
        throw new Error(errors.join(', '));
      }
    },
    [allExistingTemporaryTables, setFormError],
  );

  const _validateRootConditionForm = useCallback(
    (rootCondition: RootCondition, targetPath: string) => {
      let valid = true;
      const fieldTargetPath = `${targetPath}.field`;
      try {
        if (rootCondition.field.type === RuleComponentType.FIELD_REFERENCE) {
          _validateFieldReference(rootCondition.field as FieldReference, fieldTargetPath);
        } else if (rootCondition.field.type === RuleComponentType.LOOKUP_VALUE) {
          _validateLookUpValue(rootCondition.field as LookUpValue, fieldTargetPath);
        } else if (rootCondition.field.type === RuleComponentType.FUNCTION_VALUE) {
          _validateFunctionValue(rootCondition.field as FunctionValue, fieldTargetPath);
        } else if (rootCondition.field.type === RuleComponentType.PRIMITIVE_VALUE) {
          _validatePrimitiveValue(rootCondition.field as PrimitiveValue, fieldTargetPath);
        } else if (rootCondition.field.type === RuleComponentType.SUB_QUERY_VALUE) {
          _validateSubQueryValue(rootCondition.field as SubQueryValue, fieldTargetPath);
        } else if (rootCondition.field.type === RuleComponentType.REGEX) {
          _validateRegexValue(rootCondition.field as RegexValue, fieldTargetPath);
        } else if (rootCondition.field.type === RuleComponentType.SERVICE) {
          _validateServiceValue(rootCondition.field as ServiceValue, fieldTargetPath);
        } else {
          setFormError({ path: `${fieldTargetPath}.type`, value: 'Type not valid' });
          throw new Error('Not recognize type of condition');
        }
      } catch (err: any) {
        console.log('err:::', err.message);
        setFormError({ path: `${fieldTargetPath}.error`, value: err.message || '' });
        valid = false;
      }

      const valueTargetPath = `${targetPath}.value`;
      try {
        if (rootCondition.value.type === RuleComponentType.FIELD_REFERENCE) {
          _validateFieldReference(rootCondition.value as FieldReference, valueTargetPath);
        } else if (rootCondition.value.type === RuleComponentType.LOOKUP_VALUE) {
          _validateLookUpValue(rootCondition.value as LookUpValue, valueTargetPath);
        } else if (rootCondition.value.type === RuleComponentType.FUNCTION_VALUE) {
          _validateFunctionValue(rootCondition.value as FunctionValue, valueTargetPath);
        } else if (rootCondition.value.type === RuleComponentType.PRIMITIVE_VALUE) {
          _validatePrimitiveValue(rootCondition.value as PrimitiveValue, valueTargetPath);
        } else if (rootCondition.value.type === RuleComponentType.SUB_QUERY_VALUE) {
          _validateSubQueryValue(rootCondition.value as SubQueryValue, valueTargetPath);
        } else if (rootCondition.value.type === RuleComponentType.REGEX) {
          _validateRegexValue(rootCondition.value as RegexValue, valueTargetPath);
        } else if (rootCondition.value.type === RuleComponentType.SERVICE) {
          _validateServiceValue(rootCondition.value as ServiceValue, valueTargetPath);
        } else {
          setFormError({ path: `${valueTargetPath}.type`, value: 'Type not valid' });
          throw new Error('Not recognize type of condition');
        }
      } catch (err: any) {
        valid = false;
        setFormError({ path: `${valueTargetPath}.error`, value: err.message || '' });
        console.log('err:::', err.message);
      }
      return valid;
    },
    [
      _validateFieldReference,
      _validateFunctionValue,
      _validateLookUpValue,
      _validatePrimitiveValue,
      _validateRegexValue,
      _validateServiceValue,
      _validateSubQueryValue,
      setFormError,
    ],
  );
  const _validateConditions = useCallback(
    (conditions: RuleWhereArray | RuleHavingArray = [], targetPath: string) => {
      let valid = true;
      try {
        conditions.forEach((component, index: number) => {
          if (component.type === RuleComponentType.ROOT_CONDITIONAL) {
            const conditionalValid = _validateRootConditionForm(component as RootCondition, `${targetPath}[${index}]`);
            if (conditionalValid === false) {
              valid = false;
            }
          }
        });
        return valid;
      } catch (err: any) {
        console.log('err:::', err.message);
        return false;
      }
    },
    [_validateRootConditionForm],
  );

  const _validateSubQueries = useCallback(() => {
    let valid = true;
    if (Array.isArray(subQueries) && subQueries.length > 0) {
      let subQueryWithOutTable = false;
      subQueries.forEach((query, index) => {
        if (!query.table || query.table.trim() === '') {
          setFormError({ path: `subQueries[${index}].table`, value: 'Please select a Table' });
          subQueryWithOutTable = true;
        }
        if (!query.alias || query.alias.trim() === '') {
          setFormError({ path: `subQueries[${index}].alias`, value: 'Please enter a valid alias' });
          subQueryWithOutTable = true;
        }
      });
      if (subQueryWithOutTable) {
        valid = false;
      }
      let subQueryErrors = false;
      subQueries.forEach((subQuery, index) => {
        if (subQuery.where) {
          const containErrors = _validateConditions(subQuery.where, `subQueries[${index}].where`) === false;
          if (containErrors) subQueryErrors = true;
        }
      });
      if (subQueryErrors) valid = false;
    }
    return valid;
  }, [_validateConditions, setFormError, subQueries]);

  const getWhereHash = useCallback((where: RuleWhereArray, tableName: string) => {
    let whereHash = tableName + '-';
    where.forEach((condition, index) => {
      let conditionHash = '';
      if (condition.type === RuleComponentType.LOGICAL_OPERATOR) {
        conditionHash = (condition as LogicalOperator).value;
      } else {
        const rootCondition = condition as RootCondition;
        const fieldHash = DuplicateValidator.getConditionValueHash(rootCondition.field);
        console.log({ index, field: rootCondition.field, hash: fieldHash });
        const operatorHash = rootCondition.operator;
        const valueHash = DuplicateValidator.getConditionValueHash(rootCondition.value);
        console.log({ index, value: rootCondition.value, hash: valueHash });
        conditionHash = `${fieldHash}-${operatorHash}-${valueHash}`;
      }
      whereHash += conditionHash;
    });
    return whereHash;
  }, []);

  const validateConditionalsRepeated = useCallback(
    (where: RuleWhereArray, targetPath: string) => {
      const conditionalSet = new Set<string>();
      let valid = true;
      where.forEach((condition, index) => {
        if (condition.type === RuleComponentType.LOGICAL_OPERATOR) {
          return;
        }
        const rootCondition = condition as RootCondition;
        const fieldHash = DuplicateValidator.getConditionValueHash(rootCondition.field);
        console.log({ index, field: rootCondition.field, hash: fieldHash });
        const operatorHash = rootCondition.operator;
        const valueHash = DuplicateValidator.getConditionValueHash(rootCondition.value);
        console.log({ index, value: rootCondition.value, hash: valueHash });
        const conditionHash = `${fieldHash}-${operatorHash}-${valueHash}`;
        if (conditionalSet.has(conditionHash)) {
          setFormError({ path: `${targetPath}[${index}].condition.error`, value: 'Condition Repeated' });
          valid = false;
        } else {
          conditionalSet.add(conditionHash);
        }
      });
      return valid;
    },
    [setFormError],
  );

  const handleCreateOrUpdateRule = useCallback(async (): Promise<DataRaptorRule> => {
    setFormErrorObject({});
    const isFormValid = _validateForm();
    const isWhereValid = _validateConditions(where, 'where');
    const isHavingValid = _validateConditions(having, 'having');
    const isWhereRepeatedValid = validateConditionalsRepeated(where, 'where');
    const isSubQueriesValid = _validateSubQueries();
    if (!isFormValid || !isWhereValid || !isHavingValid || !isSubQueriesValid || !isWhereRepeatedValid) {
      throw new Error('Please Check Conditions');
    }

    const havingClause = having;

    const data: CreateDataRaptorRuleDto = {
      name: ruleName,
      table: selectedTable,
      violationScore: +violationScore,
      active: true,
      description: description,
      type: category,
      department: department,
      risk: riskLevel,
      frontEndObject: {
        ruleName,
        violationScore: +violationScore,
        department: department,
        description,
        category,
        riskLevel,
        subQueries,
        where,
        having,
      },
      rule: {
        table: selectedTable,
        subQueries: subQueries || [],
        where: where,
      },
    };

    if (havingClause && havingClause.length) {
      data.rule.having = havingClause;
      data.rule.groupBy = [`${selectedTable}.Id`];
    }

    if (formMode === RuleFormMode.CREATE || formMode === RuleFormMode.CREATE_FROM_TEMPLATE) {
      const ruleCreated = await createRule(selectedMigration, data);
      return ruleCreated;
    } else {
      if (!selectedRuleId) {
        throw new Error('Selected Rule Id not found');
      }
      const ruleUpdated = await updateRuleById(selectedRuleId, data);
      return ruleUpdated;
    }
  }, [
    _validateConditions,
    _validateForm,
    _validateSubQueries,
    setFormErrorObject,
    validateConditionalsRepeated,
    category,
    department,
    description,
    formMode,
    having,
    riskLevel,
    ruleName,
    selectedMigration,
    selectedRuleId,
    selectedTable,
    subQueries,
    violationScore,
    where,
  ]);

  const handleCreateOrUpdateTemporalTable = useCallback(
    async (ruleTemporalTable?: RuleTemporalTable): Promise<void> => {
      setFormErrorObject({});
      const isWhereValid = _validateConditions(subQueryForm.where, 'subQueryForm.where');

      if (!isWhereValid) {
        throw new Error('Please Check Conditions');
      }

      const data: postRuleTemporalTableParams = {
        migrationId: selectedMigration,
        name: subQueryForm.alias.trim(),
        table: subQueryForm.table.trim(),
        definition: { where: subQueryForm.where },
      };

      return !ruleTemporalTable
        ? postRuleTemporalTable(data)
        : putRuleTemporalTable(ruleTemporalTable.ruleTempTableId, data);
    },
    [
      selectedMigration,
      subQueryForm.alias,
      subQueryForm.table,
      subQueryForm.where,
      setFormErrorObject,
      _validateConditions,
    ],
  );

  return {
    handleCreateOrUpdateRule,
    handleCreateOrUpdateTemporalTable,
    _validateSubQueries,
    validateConditionalsRepeated,
    getWhereHash,
  };
};
