import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteMigrationById as deleteMigrationByIdApi,
  getMigrationById as getMigrationByIdApi,
  getMigrationTableDataById as getMigrationTableDataByIdApi,
  getMigrationTableFieldsById as getMigrationTableFieldsByIdApi,
  getMigrationTablesById as getMigrationTablesByIdApi,
  getMigrationTableLookUpsById as getMigrationTableLookUpsByIdApi,
  getUserMigrations as getUserMigrationsApi,
  postMigration as postMigrationApi,
  getMigrationTableForeignReferencesById as getMigrationTableForeignReferencesByIdApi,
  getMigrationMinAndMaxFieldValue as getMigrationMinAndMaxFieldValueApi,
  getMigrationFieldValueOptions as getMigrationFieldValueOptionsApi,
} from '../../http/migration/index';
import {
  Migration,
  MigrationAndTableId,
  MigrationTable,
  TableDataParameters,
  TableField,
  TableLookup,
  MinMax,
  getMigrationMinAndMaxFieldValueParameters,
  getMigrationFieldValueOptionsParameters,
} from './types';
import { ActionPromiseArgs } from 'store/types';

const SET_ERROR = 'migration/SET_ERROR';
const SET_SUCCESS = 'migration/SET_SUCCESS';
const SET_LOADING = 'migration/SET_LOADING';
const GET_MIGRATIONS = 'migration/GET_MIGRATIONS';
const GET_MIGRATION_BY_ID = 'migration/GET_MIGRATION_BY_ID';
const POST_MIGRATION = 'migration/POST_MIGRATION';
const DELETE_MIGRATION_BY_ID = 'migration/DELETE_MIGRATION_BY_ID';
const GET_MIGRATION_TABLES_BY_ID = 'migration/GET_MIGRATION_TABLES_BY_ID';
const GET_MIGRATION_TABLE_FIELD_BY_ID = 'migration/GET_MIGRATION_TABLE_FIELD_BY_ID';
const GET_MIGRATION_MIN_MAX_FIELD_VALUE = 'migration/GET_MIGRATION_MIN_MAX_FIELD_VALUE';
const GET_MIGRATION_FIELD_VALUE_OPTIONS = 'migration/GET_MIGRATION_FIELD_VALUE_OPTIONS';
const GET_MIGRATION_TABLE_FOREIGN_REFERENCES_BY_ID = 'migration/GET_MIGRATION_TABLE_FOREIGN_REFERENCES_BY_ID';
const GET_MIGRATION_TABLE_FIELD_AND_LOOKUPS_BY_ID = 'migration/GET_MIGRATION_TABLE_FIELD_AND_LOOKUPS_BY_ID';
const GET_MIGRATION_TABLE_DATA_BY_ID = 'migration/GET_MIGRATION_TABLE_DATA_BY_ID';
const SET_MIGRATION_ID = 'migration/SET_MIGRATION_ID';
const SET_MIGRATION_TABLE_NAME = 'migration/SET_MIGRATION_TABLE_NAME';
const INIT_STATES = 'migration/INIT_STATES';
const CLEAN_STATE = 'migration/CLEAN_STATE';

export const setSuccess = createAction<Migration>(SET_SUCCESS);
export const setError = createAction<string | boolean>(SET_ERROR);
export const setLoading = createAction<string | boolean>(SET_LOADING);
export const cleanState = createAction(CLEAN_STATE);

export const getMigrations = createAsyncThunk<Migration[], ActionPromiseArgs<Migration[]> | undefined>(
  GET_MIGRATIONS,
  async ({ onError, onSuccess } = {}) => {
    try {
      const res = await getUserMigrationsApi();
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err ?? undefined);
      throw err;
    }
  },
);

export const getMigrationById = createAsyncThunk<Migration, string>(GET_MIGRATION_BY_ID, async (migrationId) => {
  return getMigrationByIdApi(migrationId);
});

export const postMigration = createAsyncThunk<Migration, string>(POST_MIGRATION, async (dataSourceId) => {
  return postMigrationApi(dataSourceId);
});

export const deleteMigrationById = createAsyncThunk<Migration, string>(DELETE_MIGRATION_BY_ID, async (migrationId) => {
  return deleteMigrationByIdApi(migrationId);
});

export const getMigrationTablesById = createAsyncThunk<MigrationTable[], string>(
  GET_MIGRATION_TABLES_BY_ID,
  async (migrationId) => {
    return getMigrationTablesByIdApi(migrationId);
  },
);
export const getMigrationTableFieldsAndLookUpsById = createAsyncThunk<
  [TableField[], TableLookup[]],
  MigrationAndTableId
>(GET_MIGRATION_TABLE_FIELD_AND_LOOKUPS_BY_ID, async (migrationAndTableId: MigrationAndTableId) => {
  const res = Promise.all([
    getMigrationTableFieldsByIdApi(migrationAndTableId.migrationId, migrationAndTableId.tableId),
    getMigrationTableLookUpsByIdApi(migrationAndTableId.migrationId, migrationAndTableId.tableId),
  ]);
  return res;
});

export const getMigrationTableFieldsById = createAsyncThunk<TableField[], MigrationAndTableId>(
  GET_MIGRATION_TABLE_FIELD_BY_ID,
  async (migrationAndTableId: MigrationAndTableId) => {
    return getMigrationTableFieldsByIdApi(migrationAndTableId.migrationId, migrationAndTableId.tableId);
  },
);

export const getMigrationTableForeignReferencesById = createAsyncThunk<TableLookup[], MigrationAndTableId>(
  GET_MIGRATION_TABLE_FOREIGN_REFERENCES_BY_ID,
  async (migrationAndTableId: MigrationAndTableId) => {
    return getMigrationTableForeignReferencesByIdApi(migrationAndTableId.migrationId, migrationAndTableId.tableId);
  },
);

export const getMigrationMinMaxFieldValue = createAsyncThunk<MinMax, getMigrationMinAndMaxFieldValueParameters>(
  GET_MIGRATION_MIN_MAX_FIELD_VALUE,
  async ({ migrationId, tableId, fieldName, onError, onSuccess }: getMigrationMinAndMaxFieldValueParameters) => {
    try {
      const res = await getMigrationMinAndMaxFieldValueApi(migrationId, tableId, fieldName);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const getMigrationFieldValueOptions = createAsyncThunk<string[], getMigrationFieldValueOptionsParameters>(
  GET_MIGRATION_FIELD_VALUE_OPTIONS,
  async ({ migrationId, tableId, fieldName, onError, onSuccess }: getMigrationFieldValueOptionsParameters) => {
    try {
      const res = await getMigrationFieldValueOptionsApi(migrationId, tableId, fieldName);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const getMigrationTableDataById = createAsyncThunk<any[], TableDataParameters>(
  GET_MIGRATION_TABLE_DATA_BY_ID,
  async ({
    migrationId,
    tableId,
    skip,
    take,
    conditions,
    fields,
    orderBy,
    onError,
    onSuccess,
  }: TableDataParameters) => {
    try {
      const res = await getMigrationTableDataByIdApi(migrationId, tableId, skip, take, conditions, fields, orderBy);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const setMigrationId = createAction<string>(SET_MIGRATION_ID);
export const setMigrationTableName = createAction<string>(SET_MIGRATION_TABLE_NAME);
export const initStates = createAction(INIT_STATES);
