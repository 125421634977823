import { Migration, MigrationTable, MinMax, OrderByOption, TableField, TableLookup } from 'store/migration/types';
import { apiCall } from '../index';
import {
  Condition,
  FunctionValue,
  GetRecordStatsScoreDiffParams,
  GetRuleStatParams,
  GetTableStatsParams,
  GroupCountResponse,
  RecordStatScoreDiff,
  RuleStat,
  TableStat,
} from './dto';
import qs from 'qs';

export const getUserMigrations = (): Promise<Migration[]> => {
  return apiCall({ method: 'get', url: '/dataMigration' });
};

export const getMigrationById = (migrationId: string): Promise<Migration> => {
  return apiCall({ method: 'get', url: `/dataMigration/${migrationId}` });
};

export const postMigration = (dataSourceId: string): Promise<Migration> =>
  apiCall({ method: 'post', url: `/dataMigration/`, data: { dataSourceId: dataSourceId } });

export const deleteMigrationById = (migrationId: string): Promise<Migration> =>
  apiCall({ method: 'get', url: `/dataMigration/${migrationId}` });

export const getMigrationTablesById = (migrationId: string): Promise<MigrationTable[]> => {
  return apiCall({ method: 'get', url: `/dataMigration/${migrationId}/tables` });
};

export const getMigrationTableFieldsById = (migrationId: string, tableId: string): Promise<TableField[]> =>
  apiCall({ method: 'get', url: `/dataMigration/${migrationId}/table/${tableId}/fields` });

export const getMigrationTableLookUpsById = (migrationId: string, tableId: string): Promise<TableLookup[]> =>
  apiCall({ method: 'get', url: `/dataMigration/${migrationId}/table/${tableId}/lookups` });

export const getMigrationTableForeignReferencesById = (migrationId: string, tableId: string): Promise<TableLookup[]> =>
  apiCall({ method: 'get', url: `/dataMigration/${migrationId}/table/${tableId}/foreignReferences` });

export const getMigrationMinAndMaxFieldValue = (
  migrationId: string,
  tableId: string,
  fieldName: string,
): Promise<MinMax> =>
  apiCall({ method: 'get', url: `/dataMigration/${migrationId}/table/${tableId}/field/${fieldName}/minAndMaxValue` });

export const getMigrationFieldValueOptions = (
  migrationId: string,
  tableId: string,
  fieldName: string,
): Promise<string[]> =>
  apiCall({ method: 'get', url: `/dataMigration/${migrationId}/table/${tableId}/field/${fieldName}/value-options` });

export const getMigrationTableFuzzySearch = (
  migrationId: string,
  tableId: string,
  options: { field: string; value: string; limit?: number; minPercentage?: number; fallBackSearchField?: string[] },
): Promise<any[]> => {
  const { field, value, minPercentage = 0.1, limit = 5, fallBackSearchField } = options;
  const queryString = qs.stringify({ field, value, minPercentage, limit, fallBackSearchField }, { skipNulls: true });
  console.log('queryString', queryString);
  return apiCall({
    method: 'get',
    url: `/dataMigration/${migrationId}/table/${tableId}/data/fuzzy-search?${queryString}`,
  });
};

export const getMigrationTableDataById = (
  migrationId: string,
  tableId: string,
  skip: number,
  take: number,
  conditions: Condition[] = [],
  fields: string[] = [],
  orderBy: OrderByOption[] = [],
  action = 'retrieve',
): Promise<any[]> =>
  apiCall({
    method: 'post',
    url: `/dataMigration/${migrationId}/table/${tableId}/data?skip=${skip}&take=${take}&action=${action}`,
    data: { conditions, fields, orderBy },
  });

export const getMigrationTableTotalDataCount = (migrationId: string, tableId: string): Promise<number> =>
  apiCall({ method: 'get', url: `/dataMigration/${migrationId}/table/${tableId}/totalCounts` });

export const updateRecordOnMigrationTable = (migrationId: string, tableId: string, data: any[]) =>
  apiCall({
    method: 'post',
    url: `/dataMigration/${migrationId}/table/${tableId}/recordUpdate`,
    data: { updates: data },
  });

export const getBookmarkedTableData = (migrationId: string, tableId: string): Promise<any[]> =>
  apiCall({ method: 'get', url: `/dataMigration/${migrationId}/table/${tableId}/bookmarkedTotal` });

export const createRecordOnMigration = (migrationId: string, tableId: string, recordData: any) =>
  apiCall({
    method: 'post',
    url: `/dataMigration/${migrationId}/table/${tableId}/create-record`,
    data: { record: recordData },
  });

export const getRecordGroupCount = (
  migrationId: string,
  tableId: string,
  payload: { conditions?: Condition[]; groupBy: (string | FunctionValue)[] } = { conditions: [], groupBy: [] },
): Promise<GroupCountResponse[]> =>
  apiCall({ method: 'post', url: `/dataMigration/${migrationId}/table/${tableId}/groupCount`, data: payload });

export const getTableStats = ({
  migrationId,
  tableId,
  type,
  interval,
  startDate,
}: GetTableStatsParams): Promise<TableStat[]> =>
  apiCall({
    method: 'post',
    url: `/dataMigration/${migrationId}/table/${tableId}/stats`,
    params: { type, interval, startDate },
  });

export const getRecordStatsScoreDiff = ({
  migrationId,
  tableId,
  type,
  interval,
  startDate1,
  startDate2,
}: GetRecordStatsScoreDiffParams): Promise<RecordStatScoreDiff[]> =>
  apiCall({
    method: 'get',
    url: `/dataMigration/${migrationId}/table/${tableId}/record-stats/score-diff`,
    params: { type, interval, startDate1, startDate2 },
  });

export const getRuleStats = ({ migrationId, tableId, date, interval }: GetRuleStatParams): Promise<RuleStat[]> =>
  apiCall({
    method: 'get',
    url: `/dataMigration/${migrationId}/table/${tableId}/rule-stats`,
    params: { date, interval },
  });
