import { useState } from 'react';

import {
  Box,
  Chip,
  Grid,
  Link,
  List,
  Menu,
  Stack,
  Avatar,
  Drawer,
  Tooltip,
  Divider,
  Checkbox,
  MenuItem,
  IconButton,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  ClickAwayListener,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import VideocamIcon from '@mui/icons-material/Videocam';
import EditNoteIcon from '@mui/icons-material/EditNote';

import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { ListItemButtonMenu } from './ListItemButtonMenu';

export const AiAssistantCard = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();
  const [open, setOpen] = useState(false);

  const closeMenu = () => setAnchorEl(undefined);
  const toggleDrawer = (value: boolean) => {
    return () => setOpen(value);
  };

  const menuOptions = (
    <Menu elevation={1} open={!!anchorEl} onClose={closeMenu} anchorEl={anchorEl} suppressHydrationWarning>
      <MenuItem onClick={closeMenu}>
        <EditNoteIcon sx={{ p: 0, pr: 1 }} htmlColor="#757575" />
        Edit
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        <ContentCopyIcon sx={{ p: 0, pr: 1 }} htmlColor="#757575" />
        Duplicate
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        <DeleteIcon sx={{ p: 0, pr: 1 }} htmlColor="#757575" />
        Delete
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <Grid
        p={2}
        container
        spacing={1}
        height={230}
        display={'flex'}
        borderRadius={1}
        bgcolor={'white'}
        alignItems={'center'}
      >
        <Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Typography variant="h2" fontSize={24}>
            AI Assistant
          </Typography>
          <Link fontSize={14} onClick={toggleDrawer(true)} sx={{ cursor: 'pointer', textDecoration: 'none' }}>
            View All
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <Avatar
              style={{
                transform: 'scale(.8)',
                backgroundColor: '#1959FF',
              }}
            >
              <VideocamIcon fontSize="small" style={{ color: 'white' }} />
            </Avatar>
            <Stack sx={{ minWidth: 0 }}>
              <Typography noWrap variant="p14">
                Demo meeting with Mr. Smith lorem ipsum here
                <IconButton size="small" onClick={console.log}>
                  <OpenInNewIcon fontSize="small" htmlColor="#1959FF" sx={{ transform: 'scale(.8)' }} />
                </IconButton>
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <Avatar style={{ backgroundColor: 'white' }}>
              <ReportProblemIcon style={{ color: '#FF9960' }} />
            </Avatar>
            <Stack sx={{ minWidth: 0 }}>
              <Typography noWrap variant="p14">
                Investigate Data Anomalies
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Divider />
        <Grid item xs={12} display={'flex'} alignItems={'center'}>
          <Stack direction="row" alignItems="center">
            <Avatar style={{ backgroundColor: 'white' }}>
              <ReportProblemIcon style={{ color: '#FF9960' }} />
            </Avatar>
            <Stack sx={{ minWidth: 0 }}>
              <Typography noWrap variant="p14">
                Update Customer Records
                <IconButton size="small" onClick={console.log}>
                  <OpenInNewIcon fontSize="small" htmlColor="#1959FF" sx={{ transform: 'scale(.8)' }} />
                </IconButton>
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Drawer open={open} elevation={0} anchor="right">
        <ClickAwayListener onClickAway={toggleDrawer(false)}>
          <Box px={3} pb={3} pt={10} width={'40vw'} height={'100%'}>
            <Grid container width={'100%'} display={'flex'} flexDirection={'row'} alignItems={'stretch'}>
              <Grid item xs={12} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                <Typography variant="h2" fontSize={24}>
                  AI Assistant
                </Typography>
                <Tooltip arrow title="close">
                  <IconButton sx={{ transform: 'scale(.9)' }} onClick={toggleDrawer(false)}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ py: 1 }} />
              </Grid>
              <Grid item xs={12}>
                <List disablePadding>
                  <ListItemButtonMenu sx={{ color: '#1554FF' }}>
                    <ListItemIcon>
                      <Avatar
                        sx={{
                          transform: 'scale(.8)',
                          backgroundColor: '#1554FF',
                        }}
                      >
                        <CheckCircleOutlineIcon />
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText>
                      {`today's tasks `.toUpperCase()}{' '}
                      <Chip
                        size="small"
                        label={'0 of 1'}
                        sx={{
                          color: '#1554FF',
                          fontWeight: 'bold',
                          backgroundColor: '#DBE9FF',
                        }}
                      />
                    </ListItemText>
                    <List disablePadding>
                      <ListItemButton>
                        <Grid container display={'flex'} flexDirection={'row'}>
                          <Grid item xs={2} p={0}>
                            <Box display={'flex'} alignItems={'center'}>
                              <Checkbox disableRipple edge="start" />
                              <Typography
                                variant="p14"
                                sx={{
                                  color: '#1554FF',
                                }}
                              >
                                Innar Deal
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6} p={0}>
                            <Box height={'100%'} display={'flex'} alignItems={'center'}>
                              <Typography variant="p14">Create presentation for next meeting wi...</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={1} p={0}>
                            <Box height={'100%'} display={'flex'} alignItems={'center'}>
                              <Chip
                                size="small"
                                label={'To do'}
                                sx={{
                                  color: 'white',
                                  backgroundColor: '#8CB9FF',
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={2} p={0}>
                            <Box height={'100%'} display={'flex'} alignItems={'center'}>
                              <Chip
                                size="small"
                                label={'Due 2:00 PM'}
                                sx={{
                                  backgroundColor: '#F6F8FB',
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={1} p={0}>
                            <Typography onClick={(e) => setAnchorEl(e.currentTarget)}>...</Typography>
                            {menuOptions}
                          </Grid>
                        </Grid>
                      </ListItemButton>
                    </List>
                  </ListItemButtonMenu>
                  <ListItemButtonMenu sx={{ color: '#1554FF' }}>
                    <ListItemIcon>
                      <Avatar
                        sx={{
                          transform: 'scale(.8)',
                          backgroundColor: '#60C67C',
                        }}
                      >
                        <VideocamIcon />
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText>
                      {`today's events `.toUpperCase()}{' '}
                      <Chip
                        size="small"
                        label={'1'}
                        sx={{
                          color: '#1554FF',
                          fontWeight: 'bold',
                          backgroundColor: '#DBE9FF',
                        }}
                      />
                    </ListItemText>
                    <List disablePadding>
                      <ListItemButton>
                        <Grid container display={'flex'} flexDirection={'row'}>
                          <Grid item xs={2} p={0}>
                            <Box display={'flex'} alignItems={'center'}>
                              <Typography variant="p14" sx={{ color: '#1554FF' }}>
                                Innar Deal
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={7} p={0}>
                            <Box height={'100%'} display={'flex'} alignItems={'center'}>
                              <Typography variant="p14">Demo meeting with Mr. Smith lorem ipsum here</Typography>
                              <OpenInNewIcon fontSize="small" sx={{ pl: 0.5 }} htmlColor="#1554FF" />
                            </Box>
                          </Grid>
                          <Grid item xs={2} p={0}>
                            <Box height={'100%'} display={'flex'} alignItems={'center'}>
                              <Chip
                                size="small"
                                label={'2:00 PM'}
                                sx={{
                                  backgroundColor: '#F6F8FB',
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={1} p={0}>
                            <Typography onClick={(e) => setAnchorEl(e.currentTarget)}>...</Typography>
                            {menuOptions}
                          </Grid>
                        </Grid>
                      </ListItemButton>
                    </List>
                  </ListItemButtonMenu>
                  <ListItemButtonMenu sx={{ color: '#1554FF' }}>
                    <ListItemIcon>
                      <Avatar
                        sx={{
                          transform: 'scale(.8)',
                          backgroundColor: '#FF9960',
                        }}
                      >
                        <ReportProblemIcon />
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText>
                      {`urgent data anomalies `.toUpperCase()}{' '}
                      <Chip
                        size="small"
                        label={'1'}
                        sx={{
                          color: '#1554FF',
                          fontWeight: 'bold',
                          backgroundColor: '#DBE9FF',
                        }}
                      />
                    </ListItemText>
                    <List disablePadding>
                      <ListItemButton>
                        <Grid container display={'flex'} flexDirection={'row'}>
                          <Grid item xs={2} p={0}>
                            <Box display={'flex'} alignItems={'center'}>
                              <Typography variant="p14" sx={{ color: '#1554FF' }}>
                                Innar Deal
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={7} p={0}>
                            <Box height={'100%'} display={'flex'} alignItems={'center'}>
                              <Typography variant="p14">Demo meeting with Mr. Smith lorem ipsum here</Typography>
                              <OpenInNewIcon fontSize="small" sx={{ pl: 0.5 }} htmlColor="#1554FF" />
                            </Box>
                          </Grid>
                          <Grid item xs={3} p={0}>
                            <Box height={'100%'} display={'flex'} alignItems={'center'}>
                              <Chip
                                size="small"
                                label={'Billing Address'}
                                sx={{
                                  backgroundColor: '#F6F8FB',
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItemButton>
                    </List>
                  </ListItemButtonMenu>
                </List>
              </Grid>
            </Grid>
          </Box>
        </ClickAwayListener>
      </Drawer>
    </>
  );
};
