import React from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

type ZoomControlsProps = {
  zoom: number;
  handleZoomIn: () => void;
  handleZoomOut: () => void;
  handleResetZoom: () => void;
  handleExpand: () => void;
};

const ZoomControls: React.FC<ZoomControlsProps> = ({
  zoom,
  handleZoomIn,
  handleZoomOut,
  handleResetZoom,
  handleExpand,
}) => (
  <Box position="absolute" bottom={16} right={0} p={2}>
    <ButtonGroup
      size="small"
      sx={{
        '& .MuiButtonGroup-grouped': {
          height: '36px',
          bgcolor: 'white',
          color: '#898EA1',
          border: 'none',
          '&:hover': { bgcolor: 'white', color: '#898EA1', border: 'none' },
        },
      }}
    >
      <Button onClick={handleZoomIn}>
        <AddIcon fontSize="small" />
      </Button>
      <Button onClick={handleResetZoom}>{Math.ceil(zoom * 100)}%</Button>
      <Button onClick={handleZoomOut}>
        <RemoveIcon fontSize="small" />
      </Button>
      <Button onClick={handleExpand}>
        <FullscreenIcon fontSize="small" />
      </Button>
    </ButtonGroup>
  </Box>
);

export default ZoomControls;
