import { useState } from 'react';

interface SelectedItems {
  records: string[];
  activities: string[];
}

export const useDataIntegration = () => {
  const [selectedSource, setSelectedSource] = useState<string | null>(null);
  const [selectedItems, setSelectedItems] = useState<SelectedItems>({ records: [], activities: [] });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCard, setSelectedCard] = useState<string>('Object Selection');
  const [selectedPolicy, setSelectedPolicy] = useState<string | null>(null);
  const [selectedMappingCards, setSelectedMappingCards] = useState<{ [key: string]: boolean }>({});

  const handleSourceSelect = (source: string) => {
    setSelectedSource(source);
    setIsAuthenticated(false);
  };

  const handleAuthenticate = () => {
    setIsAuthenticated(true);
  };

  const handleCompleteUpload = () => {
    setIsLoading(true);
  };

  const handleCardSelect = (card: string) => {
    setSelectedCard(card);
  };

  return {
    selectedSource,
    handleSourceSelect,
    selectedItems,
    setSelectedItems,
    isAuthenticated,
    handleAuthenticate,
    isLoading,
    handleCompleteUpload,
    selectedCard,
    handleCardSelect,
    selectedPolicy,
    setSelectedPolicy,
    selectedMappingCards,
    setSelectedMappingCards,
  };
};
