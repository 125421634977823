import React from 'react';

const useShareModal = () => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  return {
    isModalOpen,
    openModal,
    closeModal,
  };
};

export default useShareModal;
