export enum RuleFormMode {
  CREATE = 'create',
  EDIT = 'edit',
  CREATE_FROM_TEMPLATE = 'create-from-template',
}

export enum ConditionType {
  HAVING = 'having',
  SUB_QUERY = 'sub_query',
  WHERE = 'where',
}
