import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { LOOK_UP_FUNCTION_OPTIONS, PRIMITIVE_OPTIONS } from './components/Constants';
import {
  ConditionalValue,
  FieldReference,
  FunctionValue,
  LookUpTable,
  LookUpValue,
  PrimitiveValue,
} from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { MigrationTableAndLookUps } from 'store/migration/types';

export const getXmlTypeFilterFromValueAndOperator = (
  value: ConditionalValue,
  operator: string,
  tableName: string,
  migrationId: string,
  migrationTableFieldsAndLookUps: MigrationTableAndLookUps,
) => {
  let result: (string | undefined)[] = [];
  if (value.type === RuleComponentType.FUNCTION_VALUE) {
    const functionValue = value as FunctionValue;
    const functionName = functionValue.function;
    const functionOption = LOOK_UP_FUNCTION_OPTIONS.find((option) => option.value === functionName);
    if (functionOption) {
      result = [...functionOption.resultType];
    }
  }
  if (value.type === RuleComponentType.PRIMITIVE_VALUE) {
    const primitiveValue = value as PrimitiveValue;
    const formatSelected = primitiveValue.format;
    const primitiveFormatOption = PRIMITIVE_OPTIONS.find((option) => option.value == formatSelected);
    if (primitiveFormatOption) {
      result = primitiveFormatOption.validTypes;
    }
  }
  if (value.type === RuleComponentType.FIELD_REFERENCE) {
    const fieldReference = value as FieldReference;
    const key = `${migrationId}-${tableName}`;
    const field = migrationTableFieldsAndLookUps[key]?.data?.fields?.find(
      (field) => field.fieldName == fieldReference.value,
    );
    result = [field?.xsd_type];
  }
  if (value.type === RuleComponentType.LOOKUP_VALUE) {
    const lookUpValue = value as LookUpValue;
    if (lookUpValue.value.length > 1) {
      const fieldReference = lookUpValue.value[lookUpValue.value.length - 1] as FieldReference;
      const fieldLookUpTable = lookUpValue.value[lookUpValue.value.length - 2] as LookUpTable;
      const key = `${migrationId}-${fieldLookUpTable.table}`;
      const field = migrationTableFieldsAndLookUps[key]?.data?.fields?.find(
        (field) => field.fieldName == fieldReference.value,
      );
      result = [field?.xsd_type];
    }
  }
  if (['IS', 'IS NOT'].includes(operator)) {
    if (result.includes('xsd:boolean')) {
      result = ['xsd:boolean', 'xsd:null'];
    } else {
      result = ['xsd:null'];
    }
  }
  return result.filter((x) => x) as string[];
};
