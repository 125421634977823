import { Box, Divider, Grid, Typography } from '@mui/material';
import { SecondaryButton } from 'components/ui';
import { ReactComponent as TriangleUpIcon } from 'assets/icons/triangleUp.svg';
import { ReactComponent as TriangleDownIcon } from 'assets/icons/triangleDown.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import GraphAccountViewIcon from 'assets/icons/graph/graphAccountsView.png';
import GraphLeadViewIcon from 'assets/icons/graph/graphLeadView.png';
import GraphDuplicateViewIcon from 'assets/icons/graph/graphDuplicateRecordsView.png';
import theme from 'core/theme';
import { FC, useState, useCallback } from 'react';
import { useGraphRender } from 'store/graphRender/hooks';
import { GraphRenderView } from 'store/graphRender/types';

const selectedViewBorderStyle = `1px solid ${theme.palette.primary.main}`;

export const GraphRenderViewSelector: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const {
    data: { view },
    setGraphView,
  } = useGraphRender();

  const handleOnClick = useCallback(
    (view: GraphRenderView) => {
      setGraphView(view);
      setOpen(false);
    },
    [setGraphView],
  );

  return (
    <Box>
      <SecondaryButton sx={{ p: 0, overflow: 'hidden' }} onClick={() => setOpen(!open)}>
        <Box
          sx={{
            borderRightWidth: '0.5px',
            borderRightColor: theme.palette.primary.subtone1,
            borderRightStyle: 'solid',
            p: 1,
            ':hover': {
              borderColor: theme.palette.primary.main,
            },
          }}
        >
          <Typography variant="b12">Graph View</Typography>
        </Box>
        <Box
          sx={{
            borderLeftWidth: '0.5px',
            borderLeftColor: theme.palette.primary.subtone1,
            borderLeftStyle: 'solid',
            p: 1,
            ':hover': {
              borderColor: theme.palette.primary.main,
            },
          }}
        >
          {open ? <TriangleUpIcon className="blue-sort-icon" /> : <TriangleDownIcon className="blue-sort-icon" />}
        </Box>
      </SecondaryButton>
      <Box
        display={open ? 'block' : 'none'}
        sx={{
          position: 'absolute',
          width: `300px`,
          backgroundColor: 'neutral.white',
          maxHeight: '80vh',
          overflow: 'scroll',
          overflowX: 'hidden',
          right: '2rem',
          marginTop: '0.5rem',
          px: 2,
          pb: 2,
          pt: 1,
          zIndex: 10,
          borderRadius: '4px',
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '1px',
            background: theme.palette.neutral.darkBlueMedium,
          },
        }}
      >
        <Grid position={'relative'} container display={'flex'} flexDirection={'column'} sx={{ paddingTop: 3 }} gap={3}>
          <Grid container display={'flex'} alignContent={'center'} justifyContent={'space-between'}>
            <Typography variant="h3">Graph View</Typography>
            <Box sx={{ ':hover': { cursor: 'pointer' } }}>
              <CloseIcon onClick={() => setOpen(false)} />
            </Box>
          </Grid>
          <Divider />
          <Grid
            container
            display={'flex'}
            flexDirection={'column'}
            justifyItems={'center'}
            alignItems={'center'}
            gap={1}
            sx={{ ':hover': { cursor: 'pointer', color: theme.palette.primary.main } }}
            onClick={() => handleOnClick(GraphRenderView.ACCOUNT)}
          >
            <Box height={'56px'} sx={{ border: view === GraphRenderView.ACCOUNT ? selectedViewBorderStyle : '' }}>
              <img src={GraphAccountViewIcon} alt="GraphAccountViewIcon" style={{ maxWidth: '100%', height: 'auto' }} />
            </Box>
            <Box sx={{ width: 'fit-content' }}>
              <Typography>Account View</Typography>
            </Box>
          </Grid>
          <Grid
            container
            display={'flex'}
            flexDirection={'column'}
            justifyItems={'center'}
            alignItems={'center'}
            gap={1}
            sx={{ ':hover': { cursor: 'pointer', color: theme.palette.primary.main } }}
            onClick={() => handleOnClick(GraphRenderView.DUPLICATES)}
          >
            <Box height={'56px'} sx={{ border: view === GraphRenderView.DUPLICATES ? selectedViewBorderStyle : '' }}>
              <img
                src={GraphDuplicateViewIcon}
                alt="GraphDuplicateViewIcon"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </Box>
            <Box sx={{ width: 'fit-content' }}>
              <Typography>Duplicate Records View</Typography>
            </Box>
          </Grid>
          <Grid
            container
            display={'flex'}
            flexDirection={'column'}
            justifyItems={'center'}
            alignItems={'center'}
            gap={1}
            sx={{ ':hover': { cursor: 'pointer', color: theme.palette.primary.main } }}
            onClick={() => handleOnClick(GraphRenderView.LEADS)}
          >
            <Box
              height={'56px'}
              width={'220px'}
              sx={{ border: view === GraphRenderView.LEADS ? selectedViewBorderStyle : '' }}
            >
              <img
                src={GraphLeadViewIcon}
                alt="GraphDuplicateViewIcon"
                style={{ width: '100%', height: '100%', objectFit: 'fill' }}
              />
            </Box>
            <Box sx={{ width: 'fit-content' }}>
              <Typography>Leads View</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GraphRenderViewSelector;
