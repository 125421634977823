import * as React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import SelectDataSource from './SelectDataSource';

interface SchemaField {
  fieldCatergory: string;
  dataSource: string;
  fieldExamples: string;
}

interface DataSource {
  icon: string;
  name: string;
}

export type SchemaTableProps = {
  schema: SchemaField[];
  dataSources: DataSource[];
  setSchema: React.Dispatch<React.SetStateAction<SchemaField[]>>;
};

const SchemaTable: React.FC<SchemaTableProps> = ({ schema, setSchema, dataSources }) => {
  const handleDataSourceChange = (index: number, value: string) => {
    const updatedSchema = [...schema];
    updatedSchema[index] = { ...updatedSchema[index], dataSource: value };
    setSchema(updatedSchema);
  };

  return (
    <TableContainer elevation={0} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="labelRegular10" color={'primary.gray'}>
                FIELD CATEGORY
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="labelRegular10" color={'primary.gray'}>
                PREFERRED DATA SOURCE
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="labelRegular10" color={'primary.gray'}>
                FIELD EXAMPLES
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schema.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography variant="p14" color={'neutral.main'}>
                  {row.fieldCatergory}
                </Typography>
              </TableCell>
              <TableCell>
                <SelectDataSource
                  value={row.dataSource}
                  dataSources={dataSources}
                  onChange={(value) => handleDataSourceChange(index, value)}
                />
              </TableCell>
              <TableCell>
                <Typography variant="p14" color={'neutral.n400'}>
                  {row.fieldExamples}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SchemaTable;
