import {
  GetDataAnomalyFieldRecommendationRequest,
  GetDataAnomalySummaryRequest,
  GetDataAnomalySummaryResponse,
} from 'http/ai';
import backendApi from 'store/api/backendApi';

const baseURL = process.env.REACT_APP_GATEWAY_URL;

export const dataAnomalyApi = backendApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getAIRecommendation: builder.query<
        any[],
        { migrationId: string; payload: GetDataAnomalyFieldRecommendationRequest }
      >({
        query: ({ migrationId, payload }) => ({
          method: 'post',
          url: `/ai/migration/${migrationId}/completion/data-anomaly-field-recommendation`,
          baseURL: baseURL,
          body: payload,
        }),
      }),
      getDataAnomalySummary: builder.query<
        GetDataAnomalySummaryResponse,
        { migrationId: string; payload: GetDataAnomalySummaryRequest }
      >({
        query: ({ migrationId, payload }) => ({
          method: 'post',
          url: `${baseURL}/ai/migration/${migrationId}/completion/data-anomaly-summary`,
          baseURL: baseURL,
          body: payload,
        }),
      }),
    };
  },
});

export const {
  usePrefetch,
  useGetAIRecommendationQuery,
  useGetDataAnomalySummaryQuery,
  useLazyGetDataAnomalySummaryQuery,
} = dataAnomalyApi;
