import { Box, Button, Grid, Paper, Skeleton, Theme, Typography } from '@mui/material';
import { ResponsiveBullet } from '@nivo/bullet';

import { useRuleFormModalHook } from 'pages/RuleLibrary/components/RuleFormModal/RuleFormModal.hook';
import { RuleFormModal } from 'pages/RuleLibrary/components/RuleFormModal/RuleFormModal';

import ActiveRulesCategory from './components/ActiveRuleCategory/ActiveRuleCategory';
import AISuggestedCategory from './components/AISuggestedCategory';
import { TopAnomaliesDetected } from './components/TopAnomaliesDetected';
import { useDashboard } from 'store/dashboard/hook';
import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { Show } from 'components/show';
import theme from 'core/theme';

const gapStyle = (theme: Theme) => ({
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
});

export const ActiveRulesAndScoreImpact = () => {
  const { openRuleFormModalForCreation } = useRuleFormModalHook();
  const {
    data: { selectedTable, migrationTableInfo, selectedMigrationId },
  } = useDashboard();

  const handleCreateNewTable = useCallback(() => {
    openRuleFormModalForCreation({ migrationId: selectedMigrationId, table: selectedTable });
  }, [openRuleFormModalForCreation, selectedMigrationId, selectedTable]);

  const loading = !(migrationTableInfo && Array.isArray(migrationTableInfo) && migrationTableInfo.length > 0);

  const tableInfo = useMemo(() => {
    if (selectedTable) {
      return migrationTableInfo.find((table) => table.table_name === selectedTable);
    }
    return null;
  }, [migrationTableInfo, selectedTable]);

  const confidenceScore = tableInfo?.avg_confidence_score || 100;

  const data = useMemo(
    () => [
      {
        id: 'confidence',
        ranges: [0, 100],
        measures: [0, confidenceScore, 100],
        markers: [confidenceScore],
      },
    ],
    [confidenceScore],
  );

  const style = gapStyle(theme);

  return (
    <Grid container justifyContent="space-between" width="100%" sx={style}>
      <Grid item xs={12} lg={5.9}>
        <Paper elevation={0} sx={{ width: '100%' }}>
          <Box p={2}>
            <Box display="flex" alignItems="center" textTransform="capitalize" justifyContent="space-between">
              <Typography fontSize={20} fontWeight={600}>
                Active Rules and Impact on Scores
              </Typography>
              <Button
                variant="text"
                onClick={handleCreateNewTable}
                sx={{
                  ':hover': {
                    backgroundColor: 'transparent',
                    color: 'inherit',
                  },
                  ':focus': {
                    backgroundColor: 'transparent',
                    color: 'inherit',
                  },
                }}
              >
                + Create New Rule
              </Button>
            </Box>
            <Box sx={{ px: 0 }}>
              <Show when={!loading} fallback={<Skeleton height={300} width={'100%'} />}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography fontSize={12} fontWeight={500}>
                    OVERALL CONFIDENCE SCORE
                  </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="end" gap={2}>
                    <Typography fontSize={16} fontWeight={600}>
                      {_.round(confidenceScore, 2)}%
                    </Typography>
                  </Box>
                </Box>
                <Box height={70}>
                  <ResponsiveBullet
                    data={data}
                    margin={{ left: 2, right: 10, bottom: 50 }}
                    maxValue={100}
                    rangeColors={['white']}
                    measureColors={['#FF9960', '#D67E4C', '#F8E6DF']}
                    markerColors={['black']}
                    titleOffsetX={-40}
                    tooltip={() => <></>}
                  />
                </Box>
                <Box>
                  <ActiveRulesCategory />
                  <AISuggestedCategory />
                </Box>
              </Show>
            </Box>
            <>
              <RuleFormModal />
            </>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={5.9}>
        <Paper elevation={0} sx={{ width: '100%', height: '100%' }}>
          <TopAnomaliesDetected />
        </Paper>
      </Grid>
    </Grid>
  );
};
