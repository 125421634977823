import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import CreateCustomFieldModal from './CreateCustomFieldModal';

interface CreateCustomFieldProps {
  groupFields: string[];
  onCreateField: (newField: { schemaField: string; type: string; fieldGroup: string }) => void;
}

const CreateCustomField: React.FC<CreateCustomFieldProps> = ({ groupFields, onCreateField }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <Box sx={{ paddingLeft: 1, backgroundColor: 'transparent' }}>
      <Button
        onClick={handleOpenModal}
        variant="text"
        sx={{
          fontWeight: 400,
          fontSize: '12px',
          lineHeightL: '24px',
          '&:hover': {
            backgroundColor: 'transparent',
            color: 'primary.main',
          },
        }}
      >
        Create Custom Field
      </Button>

      <CreateCustomFieldModal
        open={openModal}
        groupFields={groupFields}
        onClose={handleCloseModal}
        onCreateField={(newField) => {
          onCreateField(newField);
          handleCloseModal();
        }}
      />
    </Box>
  );
};

export default CreateCustomField;
