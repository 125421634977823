import { Box, Grid, Typography } from '@mui/material';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import theme from 'core/theme';
import FilterButton from './FilterButton';
import AddNoteButton from './NoteButton';
import { useGraphRender } from 'store/graphRender/hooks';

const ActivityFooter = () => {
  const {
    data: {
      nodePanelState: {
        activityTab: { excludedStatuses = [], excludedTypes = [] },
      },
    },
    nodePanelReducer: { setExcludedStatuses, setExcludedTypes },
  } = useGraphRender();

  const handleChange = (key: 'type' | 'status', field: string) => {
    if (key === 'type') {
      if (excludedTypes.includes(field)) setExcludedTypes(excludedTypes.filter((type) => type !== field));
      else setExcludedTypes([...excludedTypes, field]);
    }
    if (key === 'status') {
      if (excludedStatuses.includes(field)) setExcludedStatuses(excludedStatuses.filter((status) => status !== field));
      else setExcludedStatuses([...excludedStatuses, field]);
    }
  };
  return (
    <Box display={'flex'} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
      <Grid item display={'flex'} alignItems={'center'}>
        <Box display={'flex'} alignItems={'center'} gap={0.5} sx={{ m: 'auto', cursor: 'pointer' }}>
          <Box
            width={'32px'}
            height={'32px'}
            sx={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CheckBoxOutlinedIcon display={'inline'} style={{ stroke: 'white', fill: 'white', fontSize: '16px' }} />
          </Box>
          <Typography sx={{ color: 'primary.main', display: 'inline' }}>Task</Typography>
        </Box>
      </Grid>

      <Grid item display={'flex'} alignItems={'center'}>
        <Box display={'flex'} alignItems={'center'} gap={0.5} sx={{ m: 'auto', cursor: 'pointer' }}>
          <Box
            width={'32px'}
            height={'32px'}
            sx={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <VideocamOutlinedIcon display={'inline'} style={{ stroke: 'white', fill: 'white', fontSize: '16px' }} />
          </Box>
          <Typography sx={{ color: 'primary.main', display: 'inline' }}>Event</Typography>
        </Box>
      </Grid>

      <Grid item sx={{ height: '100%' }} display={'flex'} alignItems={'center'}>
        <AddNoteButton />
      </Grid>
      <Grid item sx={{ height: '100%' }} display={'flex'} alignItems={'center'}>
        <FilterButton handleChange={handleChange} excludedStatuses={excludedStatuses} excludedTypes={excludedTypes} />
      </Grid>
    </Box>
  );
};

export default ActivityFooter;
