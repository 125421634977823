import { Box, Button, CircularProgress, Grid, InputAdornment, Typography } from '@mui/material';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { CustomInput } from 'components/CustomInput';
import { GridItem, TextButton } from 'components/ui';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { WhereList } from '../../../../../RuleFormComponents/DataValidation/components/WhereList/WhereList';
import { Option } from 'store/ruleForm/types';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as UpIcon } from 'assets/icons/chevronUpBlue.svg';
import { ReactComponent as DownIcon } from 'assets/icons/chevronDownBlue.svg';
import Icon from 'assets/icons/RuleTableObject.png';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorExclamation.svg';
import theme from 'core/theme';
import { getDataValidationEmptyRootConditional } from 'pages/RuleForm/utils';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { FormErrorMessage } from 'pages/RuleLibrary/components/ui/FormErrorMessage';
import { ConditionType } from 'pages/RuleLibrary/types';

interface SubQueryItemPros {
  open: boolean;
  handleToggleShow: () => void;
  index: number;
}

const labelSx = { fontWeight: 'bold', color: theme.palette.neutral.main };

export const SubQueryItem: FC<SubQueryItemPros> = (props) => {
  const { index } = props;
  const [open, setOpen] = useState(true);
  const [fieldOptions, setFieldOptions] = useState<Option[]>([]);
  const {
    tableOptions,
    subQueries,
    selectedMigration,
    formError,
    setFieldValue,
    setSubQueriesWhere,
    handleAddInitialWhere,
    getMigrationTableFieldsAndLookUpsById,
    getFieldOptionsByMigrationAndTable,
    handleSubQueryDeletion,
  } = useRuleForm();

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const subQuery = useMemo(() => subQueries[index], [subQueries, index]);
  const where = subQuery?.where;
  const hasWhereItems = where?.length > 0 || false;

  const subQueryError = useMemo(() => formError && _.get(formError, `subQueries[${index}].error`), [formError, index]);

  const tableError = useMemo(() => formError && _.get(formError, `subQueries[${index}].table`), [formError, index]);

  const aliasError = useMemo(() => formError && _.get(formError, `subQueries[${index}].alias`), [formError, index]);

  const handleUpdateTable = useCallback(
    (value: string) => {
      setFieldOptions([]);
      setFieldValue({ path: `subQueries[${index}].table`, value });
      getMigrationTableFieldsAndLookUpsById({ migrationId: selectedMigration, tableId: value });
      setFieldValue({ path: `subQueries[${index}].where`, value: [getDataValidationEmptyRootConditional()] });
    },
    [getMigrationTableFieldsAndLookUpsById, selectedMigration, setFieldValue, index],
  );

  const handleNameChange = (e: any) => {
    let value = String(e.target.value);
    value = value.replace(/[^a-zA-Z0-9 ]/g, '');
    setFieldValue({
      path: `subQueries[${index}].alias`,
      value: value,
    });
  };

  useEffect(() => {
    const options = getFieldOptionsByMigrationAndTable(selectedMigration, subQueries[index]?.table || '', false);
    setFieldOptions(options || []);
  }, []);

  useEffect(() => {
    const options = getFieldOptionsByMigrationAndTable(selectedMigration, subQueries[index]?.table || '', false);
    if (options.length > 0) {
      setFieldOptions(options);
    }
  }, [getFieldOptionsByMigrationAndTable, selectedMigration, subQueries, index]);

  return (
    <Box px={2}>
      <Box>
        <Box
          sx={{
            backgroundColor: 'lightBg.main',
            py: 1,
            px: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '4px',
            ':hover': {
              cursor: 'pointer',
            },
            ...(subQueryError ? { border: '1px solid red', borderRadius: '4px' } : {}),
          }}
          onClick={toggleOpen}
          display={'flex'}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {subQueryError && <ErrorIcon />}
            <Typography color={'primary.main'}>
              Dataset {index + 1} {subQuery.alias && `(${subQuery.alias})`}
            </Typography>
          </Box>
          <Box>
            {open ? <UpIcon style={{ stroke: theme.palette.primary.main }} /> : null}
            {!open ? <DownIcon style={{ stroke: theme.palette.primary.main }} /> : null}
          </Box>
        </Box>
        <FormErrorMessage errorMessage={subQueryError} />
      </Box>
      <motion.div
        initial={{ opacity: open ? 0 : 1 }}
        animate={{
          opacity: open ? 1 : 0,
        }}
        transition={{ duration: 1 }}
        style={{ display: open ? 'flex' : 'none' }}
      >
        <Grid container flexDirection={'row'} sx={{ maxWidth: '100%' }} py={4} gap={3}>
          <Grid item xs={12}>
            <Grid container display={'flex'} flexDirection={'column'} gap={2} justifyContent={'space-around'}>
              <GridItem item xs={12}>
                <Box>
                  <ControlledCustomDropdown
                    label="Table"
                    labelSx={labelSx}
                    value={subQuery.table}
                    options={tableOptions || []}
                    fallbackOption={{ label: 'Select Table', value: '' }}
                    placeholder={'Select Table'}
                    id={`subQueries[${index}].table`}
                    sx={tableError ? { border: '1px solid red', borderRadius: '4px' } : {}}
                    onSelect={handleUpdateTable}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {tableError && <ErrorIcon />}
                          {!tableError && <img src={Icon} alt="Rule Table Object" />}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormErrorMessage errorMessage={tableError} />
                </Box>
              </GridItem>
              <GridItem item xs={12}>
                <Box>
                  <CustomInput
                    label="Alias"
                    id={`subQueries[${index}].alias`}
                    name={`subQueries[${index}].alias`}
                    labelSx={{ fontWeight: 'bold' }}
                    sx={aliasError ? { border: '1px solid red' } : {}}
                    placeholder="Sub Query Alias"
                    fullWidth
                    value={subQuery.alias}
                    onChange={handleNameChange}
                  />
                  <FormErrorMessage errorMessage={aliasError} />
                </Box>
              </GridItem>
            </Grid>
          </Grid>
          <>
            <Grid item xs={12}>
              <Grid container display={'flex'} gap={2} alignItems={'center'}>
                {!hasWhereItems && fieldOptions?.length > 0 && (
                  <GridItem xs={2}>
                    <Button
                      variant="outlined"
                      sx={{ width: 'fit-content', display: 'inline', marginRight: 'auto', my: 'auto' }}
                      onClick={() => handleAddInitialWhere(`subQueries[${index}].where[0]`)}
                    >
                      +
                    </Button>
                  </GridItem>
                )}
                {subQuery.table && fieldOptions?.length <= 0 && (
                  <GridItem>
                    <CircularProgress />
                  </GridItem>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container display={'flex'}>
                <WhereList
                  conditionType={ConditionType.SUB_QUERY}
                  wherePath={`subQueries[${index}].where`}
                  where={subQueries[index].where}
                  setWhere={setSubQueriesWhere}
                  fieldOptions={fieldOptions}
                  tableName={subQueries[index].table}
                />
              </Grid>
            </Grid>
          </>
          <Grid item xs={12}>
            <Box>
              <TextButton
                onClick={() => handleSubQueryDeletion(index)}
                sx={{
                  mt: 0,
                  p: 0,
                  height: '100%',
                }}
              >
                <Box
                  display={'flex'}
                  sx={{
                    alignItems: 'center',
                    ':hover': {
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      textDecorationColor: theme.palette.error.main,
                    },
                  }}
                  onClick={() => handleSubQueryDeletion(index)}
                  gap={0.5}
                >
                  <DeleteIcon style={{ stroke: theme.palette.error.main, fill: theme.palette.error.main }} />
                  <Typography sx={{ color: 'error.main' }} variant="labelRegular12">
                    Delete Dataset
                  </Typography>
                </Box>
              </TextButton>
            </Box>
          </Grid>
        </Grid>
      </motion.div>
    </Box>
  );
};
