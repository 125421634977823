import React from 'react';
import { Divider, Stack, Typography, Menu, MenuItem } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import ReplyIcon from '@mui/icons-material/Reply';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';

const EmailFooter = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  return (
    <Stack sx={{ height: '30px' }} direction="row" alignItems="center" justifyContent="center" gap={0.5} py={0.5}>
      <Stack direction="row" alignItems="center" gap={0.5} sx={{ cursor: 'pointer', mx: 1 }}>
        <ReplyIcon sx={{ color: 'primary.main' }} />
        <Typography variant="p14" color="primary.main">
          Reply
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" gap={0.5} sx={{ cursor: 'pointer', mx: 1 }}>
        <ReplyAllIcon sx={{ color: 'primary.main' }} />
        <Typography variant="p14" color="primary.main">
          Reply All
        </Typography>
      </Stack>

      <Divider orientation="vertical" />

      <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
        sx={{ cursor: 'pointer', mx: 1 }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <DragIndicatorIcon sx={{ color: 'primary.main', rotate: '90deg' }} />
        <Typography variant="p14" color="primary.main">
          More
        </Typography>
      </Stack>

      <Menu open={!!anchorEl} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
        <MenuItem>
          <Stack direction="row" alignItems="center" gap={1}>
            <EditRoundedIcon sx={{ fontSize: '14px' }} />
            <Typography variant="p12">Edit</Typography>
          </Stack>
        </MenuItem>
        <MenuItem>
          <Stack direction="row" alignItems="center" gap={1}>
            <DeleteOutlineRoundedIcon sx={{ fontSize: '14px' }} />
            <Typography variant="p12">Delete</Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </Stack>
  );
};

export default EmailFooter;
