import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { Container } from '../ui';
import { stepsConfig } from './stepsConfig';
import { useDataIntegration } from './useDataIntegration';

import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';
import CustomStepper from 'components/CustomStepper/CustomStepper';

interface ExtraData {
  step: string;
  extraMessage?: React.ReactNode;
  extraButton?: React.ReactNode;
}

interface HiddenButtons {
  step: string;
  isHidden?: boolean;
}

const DataIntegration = () => {
  const {
    handleSourceSelect,
    selectedSource,
    isAuthenticated,
    handleAuthenticate,
    isLoading,
    selectedCard,
    handleCardSelect,
    selectedItems,
    setSelectedItems,
    selectedPolicy,
    setSelectedPolicy,
    selectedMappingCards,
    setSelectedMappingCards,
  } = useDataIntegration();

  const [extraData, setExtraData] = useState<ExtraData>({ step: '' });
  const [isHiddenButtons, setIsHiddenButtons] = useState<HiddenButtons>({ step: '' });

  const { steps, stepContent } = stepsConfig({
    handleSourceSelect,
    selectedSource,
    isAuthenticated,
    handleAuthenticate,
    isLoading,
    selectedCard,
    handleCardSelect,
    extraData,
    handleExtraDataUpdate: setExtraData,
    handleHiddenButtons: setIsHiddenButtons,
    selectedItems,
    setSelectedItems,
    selectedPolicy,
    handleSelectedPolicy: setSelectedPolicy,
    selectedMappingCards,
    handleSelectedMappingCards: setSelectedMappingCards,
  });

  return (
    <Container
      sx={{
        display: 'grid',
        gridTemplateRows: '84px auto',
        gap: 2,
      }}
    >
      <Grid container sx={{ backgroundColor: 'neutral.white', padding: '24px 32px' }}>
        <Grid item xs={12}>
          <Grid sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography
              variant="h2"
              sx={{
                marginRight: 2,
                color: 'neutral.main',
              }}
            >
              Data Integration
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ overflow: 'auto' }}>
        <CustomStepper
          steps={steps}
          stepContent={stepContent}
          extraData={extraData}
          isHiddenButtons={isHiddenButtons}
          finalRoute={PRIVATE_ABS_ROUTE_PATHS.salesTeamSpace}
        />
      </Box>
    </Container>
  );
};

export default DataIntegration;
