import { createSlice, ActionReducerMapBuilder, isAnyOf } from '@reduxjs/toolkit';

import {
  setError,
  setSuccess,
  getChats,
  getChat,
  deleteChat,
  getThreads,
  getThread,
  deleteThread,
  updateChat,
  updateThread,
} from './actions';
import { ChatState, ThreadState } from './types';

export const initialState: ChatState = {
  loading: false,
  error: false,
  success: false,
  chats: [],
  chat: null,
};

export const threadState: ThreadState = {
  loading: false,
  error: false,
  success: false,
  threads: [],
  thread: null,
};

const chatReducer = createSlice({
  name: 'chat',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ChatState>): void => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(getChats.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.chats = payload;
    });

    builder.addCase(getChat.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.chat = payload;
    });

    builder.addCase(updateChat.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.chat = payload;
    });

    builder.addCase(deleteChat.fulfilled, (state) => {
      state.loading = false;
      state.success = 'Chat deleted successfully!';
    });

    builder.addMatcher(isAnyOf(getChats.rejected, getChat.rejected, deleteChat.rejected), (state, { error }) => {
      state.loading = false;
      state.error = error?.message ?? true;
      state.success = false;
    });
  },
});

//thread
const threadReducerSlice = createSlice({
  name: 'thread',
  initialState: threadState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ThreadState>): void => {
    builder.addCase(setError, (state, { payload }) => {
      state.error = payload;
    });

    builder.addCase(setSuccess, (state, { payload }) => {
      state.success = payload;
    });

    builder.addCase(getThreads.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.threads = payload;
    });

    builder.addCase(getThread.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.thread = payload;
    });

    builder.addCase(updateThread.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.thread = payload;
    });

    builder.addCase(deleteThread.fulfilled, (state) => {
      state.loading = false;
      state.success = 'Thread deleted successfully!';
    });

    builder.addMatcher(isAnyOf(getThreads.rejected, getThread.rejected, deleteThread.rejected), (state, { error }) => {
      state.loading = false;
      state.error = error?.message ?? true;
      state.success = false;
    });
  },
});

export default chatReducer.reducer;
export const threadReducer = threadReducerSlice.reducer; // add default keyword to threadReducer export
