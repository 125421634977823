import { Grid } from '@mui/material';
import { ControlledCustomDropdown, CustomDropdown } from 'components/CustomDropdown';
import { GridItem } from 'components/ui';
import { FC, useCallback, useMemo } from 'react';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import {
  ConditionalValue,
  FieldReference,
  FunctionValue,
  PrimitiveValue,
  SubQueryValue,
} from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { ConditionType } from 'pages/RuleForm/types';
import {
  FUNCTION_ENUM,
  LOOK_UP_FUNCTION_OPTIONS,
  CONDITIONAL_VALUE_OPTIONS,
  PRIMITIVE_OPTIONS,
  BOOLEAN_OPTIONS,
  NULL_OPTIONS,
} from './Constants';
import { CustomInput } from 'components/CustomInput';
import { Option } from 'store/ruleForm/types';

interface ConditionalValueProps {
  targetPath: string;
  rootConditionalPath: string;
  conditionalValueComponent: ConditionalValue;
  conditionType: ConditionType;
  index: number;
  allowedValueTypes: RuleComponentType[];
  allowedValueFunctions: FUNCTION_ENUM[];
  fieldOptions: Option[];
  xmlFilterType?: string[];
  setFieldFunction: (param: { path: string; value: any }) => void;
  hideTypeSelector?: boolean;
}

export const ConditionalValueInput: FC<ConditionalValueProps> = (props) => {
  const { fieldForeignRefOptions, handleOnSelectLookUpField } = useRuleForm();

  const {
    targetPath,
    conditionalValueComponent,
    conditionType,
    allowedValueTypes,
    allowedValueFunctions,
    fieldOptions,
    xmlFilterType,
    hideTypeSelector,
    setFieldFunction,
  } = props;

  const conditionalFunctionOptionsFiltered = useMemo(() => {
    let values = LOOK_UP_FUNCTION_OPTIONS;
    if (allowedValueFunctions.length !== 0) {
      values = LOOK_UP_FUNCTION_OPTIONS.filter((option) =>
        allowedValueFunctions.includes(option.value as FUNCTION_ENUM),
      );
    }
    if (xmlFilterType && xmlFilterType.length > 0) {
      values = values?.filter((values) => {
        return values?.resultType.some((type) => xmlFilterType?.includes(type));
      });
    }
    return values;
  }, [allowedValueFunctions, xmlFilterType]);

  const conditionalValuesOptionsFiltered = useMemo(() => {
    let values = CONDITIONAL_VALUE_OPTIONS;
    if (allowedValueTypes.length !== 0) {
      values = CONDITIONAL_VALUE_OPTIONS.filter((option) => allowedValueTypes.includes(option.value));
    }
    if (!conditionalFunctionOptionsFiltered || conditionalFunctionOptionsFiltered.length === 0) {
      values = values.filter((option) => option.value != RuleComponentType.FUNCTION_VALUE);
    }

    return values;
  }, [allowedValueTypes, conditionalFunctionOptionsFiltered]);

  const getConditionTypeDropdown = useCallback(() => {
    return (
      <CustomDropdown
        label="Value type"
        value={conditionalValueComponent.type}
        options={conditionalValuesOptionsFiltered || []}
        placeholder={'Value type'}
        id={`value-type-${targetPath}`}
        onSelect={function (value: RuleComponentType): void {
          if (value === RuleComponentType.PRIMITIVE_VALUE) {
            const initialPrimitiveValue = { type: RuleComponentType.PRIMITIVE_VALUE, value: '' };
            setFieldFunction({ path: targetPath, value: initialPrimitiveValue });
          }
          if (value === RuleComponentType.FUNCTION_VALUE) {
            const initialFunctionValue = {
              type: RuleComponentType.FUNCTION_VALUE,
              function: LOOK_UP_FUNCTION_OPTIONS[0].value,
              numberOfParams: LOOK_UP_FUNCTION_OPTIONS[0].paramNumber,
              value: [],
            };
            setFieldFunction({ path: targetPath, value: initialFunctionValue });
          }
          if (value === RuleComponentType.FIELD_REFERENCE) {
            const initialFieldReference: FieldReference = {
              type: RuleComponentType.FIELD_REFERENCE,
              value: 'Id',
            };
            setFieldFunction({ path: targetPath, value: initialFieldReference });
          }
          if (value === RuleComponentType.SUB_QUERY_VALUE) {
            const initialSubQueryValue: SubQueryValue = {
              type: RuleComponentType.SUB_QUERY_VALUE,
              subQueryId: '',
              value: '',
            };
            setFieldFunction({ path: targetPath, value: initialSubQueryValue });
          }
        }}
      />
    );
  }, [conditionalValueComponent.type, conditionalValuesOptionsFiltered, setFieldFunction, targetPath]);

  if (conditionalValueComponent.type === RuleComponentType.FUNCTION_VALUE) {
    const lookUpValueComponent = conditionalValueComponent as FunctionValue;
    let options = conditionType === ConditionType.WHERE ? fieldOptions : fieldForeignRefOptions;
    const functionOption = LOOK_UP_FUNCTION_OPTIONS.find((option) => option.value === lookUpValueComponent.function);
    if (functionOption) {
      const functionAllowType = functionOption.acceptType;
      options = options?.filter(
        (option) => functionAllowType.includes(option.xsd_type || '') || functionAllowType.includes('*'),
      );
    }
    return (
      <Grid display={'flex'} width={'100%'} gap={1}>
        {hideTypeSelector !== true && <GridItem flex={3}>{getConditionTypeDropdown()}</GridItem>}
        <GridItem flex={3}>
          <ControlledCustomDropdown<string>
            id={`${targetPath}.function`}
            placeholder="Function"
            fallbackOption={{ label: 'Select a Value', value: '' }}
            options={conditionalFunctionOptionsFiltered.map((item) => ({ value: item.value, label: item.label }))}
            sx={{ backgroundColor: 'neutral.white', width: '100%' }}
            onSelect={async (value) => setFieldFunction({ path: `${targetPath}.function`, value: value })}
            value={lookUpValueComponent.function || null}
            listBoxProps={{ maxHeight: 200 }}
          />
        </GridItem>
        <GridItem flex={5}>
          {(lookUpValueComponent.value[0] as any)?.value && (
            <ControlledCustomDropdown<string>
              id={`${targetPath}.value[0]`}
              placeholder="Field Name"
              value={(lookUpValueComponent?.value[0] as any)?.value || ''}
              options={options || []}
              sx={{ backgroundColor: 'neutral.white' }}
              fallbackOption={{ label: 'Select a Value', value: '' }}
              listBoxProps={{ maxHeight: 200 }}
              onSelect={(value) => handleOnSelectLookUpField(`${targetPath}.value[0]`, value, conditionType)}
            />
          )}
        </GridItem>
      </Grid>
    );
  }
  if (conditionalValueComponent.type === RuleComponentType.PRIMITIVE_VALUE) {
    const primitiveValue = conditionalValueComponent as PrimitiveValue;
    let allowedPrimitiveValues = PRIMITIVE_OPTIONS;

    if (xmlFilterType && xmlFilterType.length > 0) {
      allowedPrimitiveValues = allowedPrimitiveValues.filter((option) =>
        option.validTypes.some((type) => xmlFilterType.includes(type)),
      );
    }

    const isNullOrBooleanFormat = primitiveValue?.format === 'null' || primitiveValue?.format === 'boolean';
    const nullOrBooleanOptions =
      isNullOrBooleanFormat && primitiveValue?.format === 'boolean' ? BOOLEAN_OPTIONS : NULL_OPTIONS;
    return (
      <Grid display={'flex'} width={'100%'} gap={1}>
        {hideTypeSelector !== true && <GridItem flex={4}>{getConditionTypeDropdown()}</GridItem>}
        <GridItem flex={5}>
          <ControlledCustomDropdown<string>
            label="Format"
            id={`${targetPath}.format`}
            placeholder="Format"
            value={primitiveValue?.format || ''}
            options={allowedPrimitiveValues || []}
            sx={{ backgroundColor: 'neutral.white' }}
            fallbackOption={{ label: 'Format', value: '' }}
            listBoxProps={{ maxHeight: 200 }}
            onSelect={(value) => setFieldFunction({ path: `${targetPath}.format`, value })}
          />
        </GridItem>
        <GridItem flex={7}>
          {isNullOrBooleanFormat ? (
            <ControlledCustomDropdown<string>
              label="Value"
              id={`${targetPath}.value`}
              placeholder="value"
              value={primitiveValue?.value || ''}
              options={nullOrBooleanOptions || []}
              sx={{ backgroundColor: 'neutral.white' }}
              listBoxProps={{ maxHeight: 200 }}
              fallbackOption={{ label: 'Select Value', value: '' }}
              onSelect={(value) => setFieldFunction({ path: `${targetPath}.value`, value })}
            />
          ) : (
            <CustomInput
              label="Value"
              id={targetPath}
              name={targetPath}
              value={primitiveValue.value}
              type={primitiveValue?.format}
              onChange={(e) => setFieldFunction({ path: `${targetPath}.value`, value: e.target.value })}
            />
          )}
        </GridItem>
      </Grid>
    );
  }
  if (conditionalValueComponent.type === RuleComponentType.FIELD_REFERENCE) {
    const fieldReference = conditionalValueComponent as PrimitiveValue;
    let options = conditionType === ConditionType.WHERE ? fieldOptions : fieldForeignRefOptions;

    if (xmlFilterType && options) {
      options = options.filter((option) => xmlFilterType.includes(option?.xsd_type || '') || option.relationshipName);
    }

    return (
      <Grid display={'flex'} width={'100%'} gap={1}>
        {hideTypeSelector !== true && <GridItem flex={3}>{getConditionTypeDropdown()}</GridItem>}
        <GridItem flex={10}>
          <ControlledCustomDropdown<string>
            label="Field"
            id={`${targetPath}`}
            placeholder="Field Name"
            value={fieldReference?.value || ''}
            options={options || []}
            listBoxProps={{ maxHeight: 200 }}
            sx={{ backgroundColor: 'neutral.white' }}
            fallbackOption={{ label: 'Select a Field', value: '' }}
            onSelect={(value) =>
              setFieldFunction({ path: `${targetPath}`, value: { type: RuleComponentType.FIELD_REFERENCE, value } })
            }
          />
        </GridItem>
      </Grid>
    );
  }

  return <></>;
};
