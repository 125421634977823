import _ from 'lodash';

import { useNavigate } from 'react-router-dom';
import React, { useState, MouseEvent } from 'react';

import {
  Box,
  Grid,
  Button,
  Avatar,
  Select,
  Divider,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  InputAdornment,
  SelectChangeEvent,
} from '@mui/material';

import ShareIcon from '@mui/icons-material/Share';
import FilterListIcon from '@mui/icons-material/FilterList';

import LinkIcon from '@mui/icons-material/Link';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';

import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';

import { useGraphSpace } from 'store/graphSpace/hooks';
import CustomDialog from 'components/CustomDialog/CustomDialog';
import CardRadioGroups from 'components/CardRadioGroups/CardRadioGroups';

import { Container } from '../ui';
import SettingsMenu from './components/SettingsMenu';

import UserDefinedOptions from '../DataIntegration/pages/MergePolicies/components/UserDefinedOptions';
import SourcePriorityOptions from '../DataIntegration/pages/MergePolicies/components/SourcePriorityOptions';

export default function SalesTeamSpace() {
  const navigate = useNavigate();

  const { data, initDataSource } = useGraphSpace();
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const [openMergePolicies, setOpenMergePolicies] = useState(false);
  const [openInviteMembers, setOpenInviteMembers] = useState(false);

  const { graphSpace } = data;
  const [selectedPolicy, setSelectedPolicy] = useState<string>('policy1');

  const [email, setEmail] = useState<string>('');
  const [publicAccess, setPublicAccess] = useState<string>('No access');
  const [invitePermission, setInvitePermission] = useState<string>('Can view');
  const [copyLinkPermission, setCopyLinkPermission] = useState<string>('Can view');

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleInvitePermissionChange = (event: SelectChangeEvent) => {
    setInvitePermission(event.target.value as string);
  };

  const handleCopyLinkPermissionChange = (event: SelectChangeEvent) => {
    setCopyLinkPermission(event.target.value as string);
  };

  const handlePublicAccessChange = (event: SelectChangeEvent) => {
    setPublicAccess(event.target.value as string);
  };

  const policies = [
    {
      id: 'policy1',
      title: 'Timestamp ordered merge',
      subtitle: 'The system will prioritize fields with the latest updates.',
    },
    {
      id: 'policy2',
      title: 'Source priority merge',
      subtitle: 'Assign priority rankings to each data source. The system automatically prefers data from higher-ra...',
      content: <SourcePriorityOptions />,
    },
    {
      id: 'policy3',
      title: 'User defined merged rules',
      subtitle: 'Specify the preferred data sources for different fields. The system applies these rules w...',
      content: <UserDefinedOptions />,
    },
  ];

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const openMergePoliciesModal = () => {
    closeMenu();
    setOpenMergePolicies(true);
  };

  const closeMergePoliciesModal = () => {
    setOpenMergePolicies(false);
  };

  const navigateToDataIntegration = () => {
    closeMenu();
    initDataSource();
    navigate(PRIVATE_ABS_ROUTE_PATHS.dataIntegration);
  };

  const onPolicySelect = (policyId: string) => {
    setSelectedPolicy(policyId);
  };

  return (
    <Container>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        sx={{ backgroundColor: 'neutral.white', padding: '24px 32px' }}
      >
        <Grid item xs={6}>
          <Grid sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography variant="h2" sx={{ color: 'neutral.main', marginRight: 2 }}>
              Sales Team Space
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <TextField
              variant="outlined"
              placeholder="Search Record"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: '300px' }}
            />

            <Button variant="outlined" startIcon={<FilterListIcon />} disabled>
              Filters
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpenInviteMembers(!openInviteMembers)}
              startIcon={<ShareIcon />}
            >
              Share
            </Button>

            <Button variant="contained" onClick={openMenu}>
              <SettingsIcon />
            </Button>

            <SettingsMenu
              anchorEl={menuAnchor}
              onClose={closeMenu}
              onArchiveGraphSpace={closeMenu}
              onMergePolicies={openMergePoliciesModal}
              onAddDataSource={navigateToDataIntegration}
            />
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ my: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" mb={1}>
          Welcome to your {graphSpace.name} Graph Space!
        </Typography>
        <Typography
          align="center"
          variant="body2"
          color="neutral.n400"
          sx={{
            mb: 3,
            width: '260px',
            display: 'block',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
          }}
        >
          You’ve successfully created your Graph Space. You can start by adding data sources.
        </Typography>

        <Box sx={{ m: 1, borderRadius: 1, height: '500px', outline: '1px dashed #1554FF', outlineOffset: '4px' }}>
          <img
            alt="Placeholder"
            src="https://i.ibb.co/qrHPWzq/group-jhon-does.png"
            style={{ width: 'auto', height: '100%' }}
          />
        </Box>

        <Button onClick={navigateToDataIntegration} sx={{ mt: 2 }} variant="contained">
          + Add Data Sources
        </Button>
      </Box>

      <CustomDialog
        open={openInviteMembers}
        onClose={() => setOpenInviteMembers(!openInviteMembers)}
        title="Share Space"
        subtitle="Welcome to you new graph space! Invite team members to start the collaboration."
      >
        <Box display={'flex'} flexDirection={'column'} gap={4} paddingBottom={1}>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Typography variant="p12" color={'neutral.main'}>
              Invite by email
            </Typography>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} gap={2}>
              <TextField
                fullWidth
                placeholder="Enter email"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Select
                        variant="standard"
                        value={invitePermission}
                        onChange={handleInvitePermissionChange}
                        disableUnderline
                        sx={{
                          color: 'blue.main',
                          fontSize: '12px',
                          textAlign: 'right',
                          '& .MuiSelect-select': {
                            textAlign: 'right',
                            color: 'blue.main',
                          },
                          '& .MuiSelect-icon': {
                            color: 'blue.main',
                          },
                        }}
                      >
                        <MenuItem value="Can edit">Can edit</MenuItem>
                        <MenuItem value="Can view">Can view</MenuItem>
                      </Select>
                    </InputAdornment>
                  ),
                }}
              />
              <Button variant="contained" sx={{ width: '128px' }} disabled>
                Send Invite
              </Button>
            </Box>
          </Box>

          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Typography variant="p12" color={'neutral.main'}>
              {' Copy Link '}
            </Typography>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} gap={2}>
              <TextField
                disabled
                fullWidth
                placeholder="qbsSrR4GY3wZQkd3Pu"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="copy link" sx={{ backgroundColor: 'blue.main', borderRadius: 0 }}>
                        <LinkIcon sx={{ color: 'neutral.white' }} />
                        <Typography variant="body2" sx={{ fontSize: '12px', color: 'neutral.white', ml: 0.5 }}>
                          Copy link
                        </Typography>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />{' '}
              <Select
                variant="standard"
                value={copyLinkPermission}
                onChange={handleCopyLinkPermissionChange}
                disableUnderline
                sx={{
                  width: '128px',
                  color: 'blue.main',
                  fontSize: '12px',
                  textAlign: 'right',
                  '& .MuiSelect-select': {
                    textAlign: 'right',
                    color: 'blue.main',
                  },
                  '& .MuiSelect-icon': {
                    color: 'blue.main',
                  },
                }}
              >
                <MenuItem value="Can view">Can view</MenuItem>
                <MenuItem value="Can edit">Can edit</MenuItem>
              </Select>
            </Box>
          </Box>

          <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} gap={2}>
            <Typography variant="p12" color={'neutral.main'}>
              {' Public view '}
            </Typography>
            <Select
              disableUnderline
              variant="standard"
              value={publicAccess}
              onChange={handlePublicAccessChange}
              sx={{
                width: '100px',
                fontSize: '12px',
                color: 'blue.main',
                textAlign: 'right',
                '& .MuiSelect-select': {
                  textAlign: 'right',
                  color: 'blue.main',
                },
                '& .MuiSelect-icon': {
                  color: 'blue.main',
                },
              }}
            >
              <MenuItem value="No access">No access</MenuItem>
              <MenuItem value="Access">Access</MenuItem>
            </Select>
          </Box>
        </Box>

        <Divider />

        <Box paddingTop={1}>
          <Typography color={'neutral.n400'}>GRAPH SPACE MEMBERS</Typography>
          <Box
            paddingTop={2}
            display={'flex'}
            alignItems={'center'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'}>
              <Avatar sx={{ width: '24px', height: '24px' }}></Avatar>
              <Typography>John Smith (you)</Typography>
              <Typography variant="p12" color={'neutral.n400'}>
                johnsmith@gmail.com
              </Typography>
            </Box>
            <Typography>owner</Typography>
          </Box>
        </Box>
      </CustomDialog>
      <CustomDialog
        title="Merge Policies"
        open={openMergePolicies}
        onClose={closeMergePoliciesModal}
        subtitle="Data merge policies for resolving conflicts that occur between data from multiple sources systems."
        actions={
          <>
            <Button onClick={closeMergePoliciesModal} color="inherit">
              Cancel
            </Button>
            <Button variant="contained" sx={{ backgroundColor: 'primary.main' }}>
              Update Policies
            </Button>
          </>
        }
      >
        <CardRadioGroups
          items={policies}
          onChangePolicy={onPolicySelect}
          cardStyle={{
            mb: 2,
            width: '100%',
          }}
          boxStyle={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'neutral.white',
          }}
        />
      </CustomDialog>
    </Container>
  );
}
