import React from 'react';
import { Modal, Box, Typography, TextField, Button, Stack } from '@mui/material';
import { ReactComponent as EditIcon } from 'assets/icons/edit-no-bg.svg';
import { PrimaryButton } from 'components/ui';

const AddNoteButton = () => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        gap={0.5}
        sx={{ m: 'auto', cursor: 'pointer' }}
        onClick={() => setModalOpen(true)}
      >
        <EditIcon display={'inline'} />
        <Typography sx={{ color: 'primary.main', display: 'inline' }}>Note</Typography>
      </Box>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            height: '40%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: ' space-between',
            backgroundColor: 'white',
          }}
          gap={2}
          p={2}
          pt={4}
        >
          <Stack gap={0.5}>
            <Typography variant="labelMedium12">Title</Typography>
            <TextField fullWidth />
          </Stack>
          <Stack gap={0.5}>
            <Typography variant="labelMedium12">Note</Typography>
            <TextField fullWidth multiline />
          </Stack>

          <Stack direction="row" justifyContent="end" alignItems={'center'} gap={1}>
            <Button onClick={() => setModalOpen(false)}>Cancel</Button>
            <PrimaryButton disabled>Add Note</PrimaryButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default AddNoteButton;
