import { GetRecordAnomaliesResponse } from 'store/dataAnomaly/types';
import { apiCall } from '../index';

export const getRecordAnomalies = (
  migrationId: string,
  tableName: string,
  recordId: string,
): Promise<GetRecordAnomaliesResponse> =>
  apiCall({
    method: 'get',
    url: `/data-raptor-rule-data-anomaly/migration/${migrationId}/table/${tableName}/record/${recordId}`,
  });
