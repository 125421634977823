import { GridItem } from 'components/ui';
import { OperatorDropdown } from './OperatorDropdown';
import { FC, useCallback, useMemo } from 'react';
import {
  RuleWhereArray,
  RootCondition,
  FieldReference,
  PrimitiveValue,
} from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { ConditionalValueInput } from './ConditionalValueInput';
import { ConditionType } from 'pages/RuleForm/types';
import { CONDITIONAL_VALUE_OPTIONS, FUNCTION_ENUM, OPERATOR_OPTIONS } from './Constants';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { Option } from 'store/ruleForm/types';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { Box, Grid, Typography } from '@mui/material';
import theme from 'core/theme';
import { getXmlTypeFilterFromValueAndOperator } from '../utils';
import { useMigration } from 'store/migration/hooks';
import { removeConditional, validateConditional } from '../../../utils';
import { MigrationFieldValuesDropDown } from '../../InputFields/MigrationFieldValuesDropDown';

export interface ConditionalRecordFormProps {
  index: number;
  conditionComponent: RootCondition;
  conditionPath: string;
  wherePath: string;
  where: RuleWhereArray;
  fieldOptions: Option[];
  tableName: string;
  setFieldFunction: (param: { path: string; value: any }) => void;
  firstFieldValidValueType?: RuleComponentType[];
  secondFieldValidValueType?: RuleComponentType[];
  showDeleteButton?: boolean;
}

const ConditionalRecordForm: FC<ConditionalRecordFormProps> = (props) => {
  const {
    index,
    conditionComponent,
    where,
    conditionPath,
    fieldOptions,
    tableName,
    setFieldFunction,
    firstFieldValidValueType,
    secondFieldValidValueType,
    showDeleteButton,
    wherePath,
  } = props;
  const {
    data: { migrationId, migrationTableFieldsAndLookUps },
  } = useMigration();
  const validationResponse = useMemo(() => validateConditional(conditionComponent), [conditionComponent]);
  const operator = conditionComponent?.operator || null;
  const allowedFunctions = [FUNCTION_ENUM.DAYS_SINCE];

  const firstFieldValidValueTypeCalculated =
    firstFieldValidValueType ||
    CONDITIONAL_VALUE_OPTIONS.map((option) => option.value)?.filter(
      (option) => option != RuleComponentType.SUB_QUERY_VALUE,
    );

  let secondFieldValidValueTypeCalculated =
    secondFieldValidValueType || CONDITIONAL_VALUE_OPTIONS.map((option) => option.value);
  const operatorOption = OPERATOR_OPTIONS.find((option) => option.value === operator);

  if (operatorOption) {
    const options = operatorOption.rightValueType;
    secondFieldValidValueTypeCalculated = options.filter((option) =>
      secondFieldValidValueTypeCalculated.includes(option),
    );
  }

  const xmlTypeFilter = getXmlTypeFilterFromValueAndOperator(
    conditionComponent.field,
    operator || '',
    tableName,
    migrationId,
    migrationTableFieldsAndLookUps,
  );

  const handleOnDeleteCustomFilter = useCallback(() => {
    const newWhere = removeConditional(where, index);
    setFieldFunction({ path: wherePath, value: newWhere });
  }, [index, setFieldFunction, where, wherePath]);

  const shouldRenderPickListValue = useMemo(() => {
    if (conditionComponent.field.type === RuleComponentType.FIELD_REFERENCE) {
      const fieldName = (conditionComponent.field as FieldReference).value;
      const key = `${migrationId}-${tableName}`;
      const field = migrationTableFieldsAndLookUps[key]?.data?.fields.find((field) => field.fieldName === fieldName);
      if (!field) {
        return false;
      }
      return field.type === 'picklist' && ['=', '!='].includes(conditionComponent.operator);
    }
    return false;
  }, [conditionComponent.field, conditionComponent.operator, migrationId, migrationTableFieldsAndLookUps, tableName]);

  return (
    <Grid
      container
      display={'flex'}
      flexDirection={'column'}
      gap={1}
      sx={{
        position: 'relative',
        backgroundColor: theme.palette.lightBg.main,
        py: 2,
        px: 1,
        borderRadius: '4px',
        mt: 1,
        border: `${theme.palette.primary.main} solid 1px`,
      }}
    >
      {showDeleteButton === true && (
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            p: '1px',
            transform: 'translate(50%,-50%)',
            backgroundColor: theme.palette.neutral.white,
            border: `${theme.palette.neutral.darkGray} solid 1px`,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={handleOnDeleteCustomFilter}
        >
          <CrossIcon style={{ fill: theme.palette.neutral.darkGray }}></CrossIcon>
        </Box>
      )}
      {validationResponse.valid === false && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            p: '1px',
            transform: 'translate(5%,-50%)',
            border: `${theme.palette.red.main} solid 1px`,
            backgroundColor: theme.palette.neutral.white,
            borderRadius: '4px',
            display: 'flex',
            px: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="labelRegular12" sx={{ color: theme.palette.red.main }}>
            Please fill empty inputs
          </Typography>
        </Box>
      )}

      <GridItem>
        <ConditionalValueInput
          targetPath={`${conditionPath}.field`}
          rootConditionalPath={conditionPath}
          conditionalValueComponent={conditionComponent.field}
          conditionType={ConditionType.WHERE}
          index={index}
          allowedValueTypes={firstFieldValidValueTypeCalculated}
          allowedValueFunctions={allowedFunctions}
          fieldOptions={fieldOptions}
          setFieldFunction={(value) => {
            setFieldFunction(value);
            setFieldFunction({
              path: `${conditionPath}.operator`,
              value: '=',
            });
            setFieldFunction({
              path: `${conditionPath}.value`,
              value: { type: RuleComponentType.PRIMITIVE_VALUE, value: '' },
            });
          }}
          hideTypeSelector={true}
        />
      </GridItem>
      <GridItem width={'35%'}>
        <OperatorDropdown
          operator={operator}
          condition={where[index] as RootCondition}
          targetPath={`${conditionPath}.operator`}
          tableName={tableName}
          setFieldFunction={(value) => {
            setFieldFunction(value);
            setFieldFunction({
              path: `${conditionPath}.value`,
              value: { type: RuleComponentType.PRIMITIVE_VALUE, value: '' },
            });
          }}
        />
      </GridItem>
      <GridItem>
        {shouldRenderPickListValue === true ? (
          <MigrationFieldValuesDropDown
            label={'Value'}
            id={`where-${index}-value}`}
            value={(conditionComponent.value as PrimitiveValue).value}
            migrationId={migrationId}
            table={tableName}
            fieldName={(conditionComponent.field as FieldReference).value}
            placeholder={'Select Value'}
            onChange={(value: string) =>
              setFieldFunction({
                path: `${conditionPath}.value`,
                value: { type: RuleComponentType.PRIMITIVE_VALUE, value: value, format: 'text' },
              })
            }
          />
        ) : (
          <ConditionalValueInput
            targetPath={`${conditionPath}.value`}
            rootConditionalPath={conditionPath}
            conditionalValueComponent={conditionComponent.value}
            conditionType={ConditionType.WHERE}
            index={index}
            allowedValueTypes={secondFieldValidValueTypeCalculated}
            allowedValueFunctions={allowedFunctions}
            fieldOptions={fieldOptions}
            xmlFilterType={xmlTypeFilter || []}
            setFieldFunction={setFieldFunction}
            // hideTypeSelector={true}
          />
        )}
      </GridItem>
    </Grid>
  );
};

export { ConditionalRecordForm };
