import { FC } from 'react';
import { Provider } from 'react-redux';

import { SnackbarProvider } from 'notistack';
import { ThemeProvider, CssBaseline } from '@mui/material';

import theme from 'core/theme';
import AppRouter from 'router/AppRouter';
import AxiosInterceptors from 'http/interceptors';

import { store } from 'store';
AxiosInterceptors.setup(store);

const App: FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider autoHideDuration={4000}>
          <CssBaseline />
          <AppRouter />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
