import React from 'react';
import { Box, Typography, TypographyProps } from '@mui/material';

interface IProps extends TypographyProps {
  width: string | number;
  children: React.ReactNode;
}
const ExpandableTypography = ({ width, children, ...props }: IProps) => {
  const { sx: additionalStyleProps, ...restOfProps } = props;

  const [isOverflow, setIsOverflow] = React.useState(false);

  const textRef = React.useRef<HTMLSpanElement>();

  React.useEffect(() => {
    const element = textRef.current;
    if (!element) return;
    setIsOverflow(element.scrollWidth > element.clientWidth);
  }, [textRef]);

  return (
    <Box
      sx={{
        maxWidth: typeof width === 'number' ? `${width}px` : width,
        display: 'flex',
        ...(isOverflow
          ? {
              ':hover': {
                width: typeof width === 'number' ? `${width}px` : width,
                position: 'relative',
                '& span': {
                  position: 'absolute',
                  top: -15,
                  bottom: 0,
                  overflow: 'visible',
                  textOverflow: 'clip',
                  whiteSpace: 'wrap',
                  zIndex: 5,
                  backgroundColor: 'white',
                  height: 'fit-content',
                  boxShadow: '2px 2px 20px -1px rgba(0,0,0,0.45)',
                  padding: '2px',
                  borderRadius: '5px',
                },
              },
            }
          : {}),
      }}
    >
      {
        // @ts-expect-error
        <Typography
          variant="labelMedium14"
          sx={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...additionalStyleProps,
          }}
          {...restOfProps}
          ref={textRef}
        >
          {children}
        </Typography>
      }
    </Box>
  );
};

export default ExpandableTypography;
