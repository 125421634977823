import React from 'react';

import { useForm } from 'react-hook-form';
import { useGraphSpace } from 'store/graphSpace/hooks';
import { Box, TextField, Typography, InputAdornment } from '@mui/material';

interface FormNameDescription {
  name: string;
  description: string;
}

const GraphSpaceDetails: React.FC = () => {
  const { data, updateName, updateDescription, updateGraphSpaceValidations } = useGraphSpace();

  const { graphSpace, validations } = data;
  const { watch, register, handleSubmit, formState } = useForm<FormNameDescription>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    values: {
      name: graphSpace.name,
      description: graphSpace.description,
    },
  });

  const { errors, isValid } = formState;

  const watchedName = watch('name');
  const watchedDescription = watch('description');

  const onSubmit = (form: FormNameDescription) => {
    const { graphSpaceSetUp, ...others } = validations;

    updateName({ name: form.name });
    updateDescription({ description: form.description });
    updateGraphSpaceValidations({
      ...others,
      graphSpaceSetUp: {
        ...graphSpaceSetUp,
        isGraphSpaceDetailsValid: isValid,
      },
    });
  };

  const onChangeValidations = () => {
    const { graphSpaceSetUp, ...others } = validations;

    updateGraphSpaceValidations({
      ...others,
      graphSpaceSetUp: {
        ...graphSpaceSetUp,
        isGraphSpaceDetailsValid: isValid,
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ my: 5 }}>
        Graph Space Details
      </Typography>

      <Box
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          p: 3,
          boxShadow: 3,
          width: '100%',
          display: 'flex',
          maxWidth: '550px',
          borderRadius: '8px',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: 'white',
        }}
        component="form"
      >
        <Typography variant="subtitle1" sx={{ alignSelf: 'flex-start' }}>
          Name
        </Typography>
        <Box sx={{ position: 'relative', width: '100%', mb: 3 }}>
          <TextField
            fullWidth
            margin="normal"
            error={!!errors.name}
            defaultValue={graphSpace.name}
            helperText={errors.name?.message}
            {...register('name', {
              required: 'Name required',
              onChange: onChangeValidations,
              maxLength: {
                value: 40,
                message: 'Maximum 40 characters allowed',
              },
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="caption">{`${watchedName.length}/40`}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Typography variant="subtitle1" sx={{ alignSelf: 'flex-start' }}>
          Description
        </Typography>
        <Box sx={{ position: 'relative', width: '100%' }}>
          <TextField
            fullWidth
            margin="normal"
            error={!!errors.description}
            defaultValue={graphSpace.description}
            helperText={errors.description?.message}
            {...register('description', {
              onChange: onChangeValidations,
              required: 'Description required',
              maxLength: {
                value: 40,
                message: 'Maximum 40 characters',
              },
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="caption">{`${watchedDescription?.length ?? 0}/40`}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <button hidden type="submit" id="graphSpaceDetailsButton">
          submit
        </button>
      </Box>
    </Box>
  );
};

export default GraphSpaceDetails;
