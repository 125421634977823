import { Box, Checkbox, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useGraphRender } from 'store/graphRender/hooks';
import { initialDuplicateQuickFilter } from 'store/graphRender/reducers';

export const DuplicateQuickFilter = () => {
  const {
    data: {
      duplicateQuickFilter: { selectedTables },
    },
    duplicateQuickFilterReducers: { duplicateQuickFilterSetSelectedTables },
  } = useGraphRender();

  const toggleTable = useCallback(
    (table: string) => {
      if (selectedTables.includes(table)) {
        duplicateQuickFilterSetSelectedTables(selectedTables.filter((t) => t !== table));
      } else {
        duplicateQuickFilterSetSelectedTables([...selectedTables, table]);
      }
    },
    [duplicateQuickFilterSetSelectedTables, selectedTables],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {initialDuplicateQuickFilter.selectedTables.map((table) => (
        <Box key={table} sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox checked={selectedTables.includes(table)} onClick={() => toggleTable(table)} />
          <Typography variant="labelMedium14">{table}</Typography>
        </Box>
      ))}
    </Box>
  );
};
