import { Box, Grid, Typography } from '@mui/material';
import theme from 'core/theme';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { FC } from 'react';

export interface ValidationPatternTagProps {
  label: string;
  patternId: string;
  handleDelete: (id: string) => void;
}

export const ValidationPatternTag: FC<ValidationPatternTagProps> = (props) => {
  const { label, patternId, handleDelete } = props;

  return (
    <Box
      display={'flex'}
      width={'fit-content'}
      flexDirection={'row'}
      alignItems={'center'}
      gap={1}
      sx={{ backgroundColor: theme.palette.primary.subtone2, px: '8px', py: '3px', borderRadius: '4px' }}
    >
      <Typography variant="p14" sx={{ whiteSpace: 'nowrap' }} display={'inline-block'}>
        {label}
      </Typography>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        sx={{ ':hover': { cursor: 'pointer', stroke: theme.palette.red.main } }}
        onClick={() => handleDelete(patternId)}
      >
        <CrossIcon />
      </Box>
    </Box>
  );
};
