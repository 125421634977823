import { Button, CircularProgress, Grid } from '@mui/material';
import { GridItem } from 'components/ui';
import { WhereList } from 'pages/RuleLibrary/components/RuleFormComponents/DataValidation/components/WhereList/WhereList';
import { FC, useEffect, useState } from 'react';
import { useTemporalTableModalHook } from '../../../TemporalTableModal.hook';
import { Option } from 'store/ruleForm/types';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { ConditionType } from 'pages/RuleLibrary/types';

export const TableCondition: FC = () => {
  const [fieldOptions, setFieldOptions] = useState<Option[]>([]);

  const { handleAddInitialWhere } = useRuleForm();

  const {
    data: { where, table, selectedMigration },
    setSubQueryFormWhere,
    getFieldOptionsByMigrationAndTable,
  } = useTemporalTableModalHook();

  useEffect(() => {
    const options = getFieldOptionsByMigrationAndTable(selectedMigration, table || '', false);
    if (options.length > 0) {
      const uniqueOptions = options.filter(
        (option, index, self) => self.findIndex((t) => t.value === option.value) === index,
      );
      setFieldOptions(uniqueOptions);
    }
  }, [getFieldOptionsByMigrationAndTable, selectedMigration, table]);

  const hasWhereItems = where?.length > 0 || false;
  return (
    <>
      <Grid container display={'flex'} gap={2} justifyContent={'center'} alignItems={'center'}>
        {!hasWhereItems && fieldOptions?.length > 0 && (
          <GridItem xs={2}>
            <Button
              variant="outlined"
              sx={{ width: 'fit-content', display: 'inline', marginRight: 'auto', my: 'auto' }}
              onClick={() => handleAddInitialWhere(`subQueryForm.where[0]`)}
            >
              +
            </Button>
          </GridItem>
        )}
        {fieldOptions?.length <= 0 && (
          <GridItem>
            <CircularProgress />
          </GridItem>
        )}
      </Grid>
      <Grid container display={'flex'} sx={{ p: 2 }}>
        <WhereList
          wherePath={`subQueryForm.where`}
          where={where}
          setWhere={setSubQueryFormWhere}
          fieldOptions={fieldOptions}
          tableName={table}
          conditionType={ConditionType.SUB_QUERY}
        />
      </Grid>
    </>
  );
};
