import { Box, Typography } from '@mui/material';
import theme from 'core/theme';
import { FC, useCallback } from 'react';
import { useGraphRender } from 'store/graphRender/hooks';
import { SearchFilterData } from 'store/graphRender/types';
import { useMigration } from 'store/migration/hooks';

export interface FilterChipProps {
  text: string;
  active: boolean;
  option: SearchFilterData;
}

const disabledButtonCSSOnHover = {};

const FilterChip: FC<FilterChipProps> = (props) => {
  const {
    setSearchFilterOption,
    getFuzzySearch,
    data: { searchTerm },
    setLoading,
  } = useGraphRender();

  const {
    data: { migrationId },
  } = useMigration();

  const { active, text, option } = props;
  const disabled = option.disabled;

  const handleToggleActive = useCallback(() => {
    if (disabled === true) {
      return;
    }
    setSearchFilterOption([{ option: text, value: !active, disabled: false }]);
    if (!active === true) {
      setLoading(true);
      getFuzzySearch({
        migrationId: migrationId,
        tableId: option.tableName,
        label: option.label,
        options: {
          field: option.searchField,
          minPercentage: 0.1,
          value: searchTerm,
          fallBackSearchField: option.fallBackSearchField,
        },
      });
    }
  }, [
    active,
    disabled,
    getFuzzySearch,
    migrationId,
    option.fallBackSearchField,
    option.label,
    option.searchField,
    option.tableName,
    searchTerm,
    setLoading,
    setSearchFilterOption,
    text,
  ]);

  return (
    <Box
      onClick={handleToggleActive}
      sx={{
        borderRadius: '20px',
        border: '1px solid',
        borderColor: active ? theme.palette.neutral.dark : theme.palette.neutral.n200,
        backgroundColor: active ? theme.palette.neutral.white : theme.palette.neutral.n200,
        color: active ? theme.palette.neutral.dark : theme.palette.neutral.darkGray,
        px: 2,
        py: 0.5,
        ':hover': disabled
          ? disabledButtonCSSOnHover
          : {
              cursor: 'pointer',
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
            },
      }}
    >
      <Typography variant="labelRegular12" sx={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
        {text}
      </Typography>
    </Box>
  );
};

export default FilterChip;
