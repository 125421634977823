import { Box, Grid } from '@mui/material';
import { FC, useMemo } from 'react';
import PotentialDuplicatesAlert from '../../../components/PotentialDuplicatesAlert';
import { ActivityDataList, EventFieldOption } from '../../../components/ActivityDataList';
interface ActionMenuViewProps {
  node: any;
  referencedUsers: any;
}

export const ActionMenuView: FC<ActionMenuViewProps> = (props) => {
  const { node } = props;

  const eventFieldCalculated = useMemo(() => {
    if (['Account', 'Opportunity'].includes(node.label)) {
      return EventFieldOption.whatId;
    }
    return EventFieldOption.whoId;
  }, [node.label]);

  return (
    <>
      <Grid display={'flex'} direction={'column'} gap={1}>
        <Grid item>
          <PotentialDuplicatesAlert node={node} />
        </Grid>
        <Box display={'flex'} flexDirection={'column'}>
          <ActivityDataList recordId={node.id} eventField={eventFieldCalculated} />
        </Box>
      </Grid>
    </>
  );
};

export default ActionMenuView;
