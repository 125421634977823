import { ReactComponent as SearchSVG } from 'assets/icons/search.svg';
import { PrimaryButton } from 'components/ui';
import { ConditionType } from 'pages/RuleForm/types';
import { FC } from 'react';
import { FieldReference, LookUpTable } from 'store/dataRaptorRule/dto/front-end-rule.dto';

export interface ToggleLookUpValueSelectorButtonProps {
  index: number;
  lookUpComponents: (LookUpTable | FieldReference)[];
  conditionType: ConditionType;
  targetPath: string;
}

export const ToggleLookUpValueSelectorButton: FC<ToggleLookUpValueSelectorButtonProps> = (props) => {
  return (
    <PrimaryButton>
      <SearchSVG />
    </PrimaryButton>
  );
};
