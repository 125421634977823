import { List, ListItem, Radio, RadioGroup, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useRuleLibrary } from 'store/ruleLibrary/hook';
import { SORT_OPTIONS } from 'store/ruleLibrary/types';

export const SortOptionsList: FC = () => {
  const {
    selectedSubPage,
    subPages: { library, template },
    setTemplateSortStrategy,
    setRuleSortStrategy,
  } = useRuleLibrary();

  const sortOptions = useMemo(() => {
    const options = Object.values(SORT_OPTIONS);
    return options.filter((option) => option.viewAvailable.includes(selectedSubPage));
  }, [selectedSubPage]);

  const selectedSortStrategy = useMemo(
    () => (selectedSubPage === 'library' ? library.data.sortStrategy : template.data.sortStrategy),
    [library.data.sortStrategy, selectedSubPage, template.data.sortStrategy],
  );

  const setSortStrategy = useMemo(() => {
    return selectedSubPage === 'library' ? setRuleSortStrategy : setTemplateSortStrategy;
  }, [selectedSubPage, setRuleSortStrategy, setTemplateSortStrategy]);

  const handleSortOptionChange = (event: any) => {
    const value = event.target.value;
    setSortStrategy(value);
  };

  return (
    <List>
      <RadioGroup>
        {sortOptions.map((option) => (
          <ListItem key={option.value} sx={{ p: 0 }}>
            <Radio
              value={option.value}
              checked={selectedSortStrategy === option.value}
              onChange={handleSortOptionChange}
            />
            <Typography variant="p12" sx={{ textTransform: 'capitalize' }}>
              {option.label}
            </Typography>
          </ListItem>
        ))}
      </RadioGroup>
    </List>
  );
};
