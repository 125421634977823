import { getRuleStatus } from 'core/utils';
import { useEffect } from 'react';
import { useRuleLibrary } from 'store/ruleLibrary/hook';

export const useFilterHook = () => {
  const {
    subPages: {
      template: {
        data: {
          filters: { department: templateDepartmentFilter, type: templateTypeFilter, table: templateTableFilter },
          templates,
        },
      },
      library: {
        data: {
          filters: {
            department: ruleDepartmentFilter,
            status: ruleStatusFilter,
            type: ruleTypeFilter,
            table: ruleTableFilter,
          },
          rules,
        },
      },
    },
    setFilteredRules,
    setFilteredTemplates,
  } = useRuleLibrary();

  //RuleFilterApplier
  useEffect(() => {
    const departmentAllowedValues = ruleDepartmentFilter.allowedValues;
    let filteredRules = rules.filter((rule) =>
      departmentAllowedValues.includes(rule.DepartmentObject?.ruleDepartmentId || ''),
    );
    const statusAllowedValues = ruleStatusFilter.allowedValues;
    filteredRules = filteredRules.filter((rule) =>
      statusAllowedValues.includes(getRuleStatus(rule.status, rule.active)),
    );
    const typeAllowedValues = ruleTypeFilter.allowedValues;
    filteredRules = filteredRules.filter((rule) => typeAllowedValues.includes(rule.type));

    const tableAllowedValues = ruleTableFilter.allowedValues;
    filteredRules = filteredRules.filter((rule) => tableAllowedValues.includes(rule.table));

    setFilteredRules(filteredRules);
  }, [
    ruleDepartmentFilter.allowedValues,
    ruleStatusFilter.allowedValues,
    ruleTableFilter.allowedValues,
    ruleTypeFilter.allowedValues,
    rules,
    setFilteredRules,
  ]);

  //TemplateFilterApplier
  useEffect(() => {
    const departmentAllowedValues = templateDepartmentFilter.allowedValues;
    let filteredTemplates = templates.filter((template) =>
      departmentAllowedValues.includes(template.DepartmentObject?.ruleDepartmentId || ''),
    );
    const typeAllowedValues = templateTypeFilter.allowedValues;
    filteredTemplates = filteredTemplates.filter((template) => typeAllowedValues.includes(template.type || ''));

    const tableAllowedValues = templateTableFilter.allowedValues;
    filteredTemplates = filteredTemplates.filter((template) => tableAllowedValues.includes(template.table));

    setFilteredTemplates(filteredTemplates);
  }, [
    setFilteredTemplates,
    templateDepartmentFilter.allowedValues,
    templateTableFilter.allowedValues,
    templateTypeFilter.allowedValues,
    templates,
  ]);
};
