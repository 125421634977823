import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import theme from 'core/theme';
import { FC, useCallback } from 'react';

export interface RangeSliderProps {
  label: string;
  subLabel?: string;
  min: number;
  max: number;
  values: number[];
  minDistance: number;
  onChange: (valueArray: number[]) => void;
}

export const RangeSlider: FC<RangeSliderProps> = (props) => {
  const { label, min, max, subLabel, values, onChange, minDistance } = props;

  const handleChange = useCallback(
    (event: Event, newValue: number | number[], activeThumb: number) => {
      if (!Array.isArray(newValue)) {
        return;
      }
      if (newValue[1] - newValue[0] < minDistance) {
        if (activeThumb === 0) {
          const clamped = Math.min(newValue[0], 100 - minDistance);
          onChange([clamped, clamped + minDistance]);
        } else {
          const clamped = Math.max(newValue[1], minDistance);
          onChange([clamped - minDistance, clamped]);
        }
      } else {
        onChange(newValue as number[]);
      }
    },
    [minDistance, onChange],
  );

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Typography variant="labelRegular12">{label}</Typography>
      {subLabel && (
        <Typography sx={{ color: theme.palette.neutral.n400 }} variant="labelRegular12">
          {subLabel}
        </Typography>
      )}
      <Box display={'flex'} justifyContent={'center'}>
        <Slider
          sx={{
            width: '82%',
            mx: 'auto',
            '& .MuiSlider-track': {
              borderColor: theme.palette.primary.subtone1,
              backgroundColor: theme.palette.primary.subtone1,
            },
            '& .MuiSlider-rail': {
              opacity: 0.5,
              backgroundColor: theme.palette.neutral.lightGray,
            },
          }}
          min={min}
          max={max}
          value={values}
          onChange={handleChange}
          valueLabelDisplay="auto"
          disableSwap
        />
      </Box>
    </Box>
  );
};

export default RangeSlider;
