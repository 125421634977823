import { Box, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { ReactComponent as AccountIcon } from 'assets/icons/menuAccounts.svg';
import { ReactComponent as ContactIcon } from 'assets/icons/menuContacts.svg';
import { ReactComponent as DealIcon } from 'assets/icons/menuDeal.svg';
import theme from 'core/theme';
import { FC, useCallback, useMemo } from 'react';
import { useGraphRender } from 'store/graphRender/hooks';
import { SearchFilterData } from 'store/graphRender/types';

export interface ResultListItem {
  record: any;
  option: SearchFilterData;
  setShowSearchDetail: (value: boolean) => void;
}

const ResultListItem: FC<ResultListItem> = (props) => {
  const { record, option, setShowSearchDetail } = props;

  const recordName = useMemo(() => {
    if (record[option.searchField] && record[option.searchField].length > 0) {
      return record[option.searchField];
    }
    // build record Name from the fallbacksearchfields
    let recordName = '';
    if (option.fallBackSearchField) {
      for (const field of option.fallBackSearchField) {
        if (record[field] && record[field].length > 0) {
          recordName += record[field] + ' ';
        }
      }
    }
    return recordName.trim();
  }, [option.fallBackSearchField, option.searchField, record]);

  const {
    setSelectedNodeFromSearchBar,
    setSelectedNode,
    data: { nodeHashMap, schemaName },
    loading,
  } = useGraphRender();

  let secondaryText = '';
  let Icon = null;

  if (option.label === 'Contacts') {
    secondaryText = record.Title;
    Icon = ContactIcon;
  } else if (option.label === 'Opportunity') {
    secondaryText = record.Title;
    Icon = DealIcon;
  } else if (option.label === 'Leads') {
    secondaryText = record.Type;
    Icon = DealIcon;
  } else if (option.label === 'Accounts') {
    Icon = AccountIcon;
  }

  const handleOnClick = useCallback(() => {
    if (schemaName) {
      const id = `${schemaName}.${record.Id}`;
      const node = nodeHashMap[id];
      setSelectedNode(undefined);
      setShowSearchDetail(false);
      setTimeout(() => setSelectedNodeFromSearchBar(node), 0);
    }
  }, [nodeHashMap, record.Id, schemaName, setSelectedNode, setSelectedNodeFromSearchBar, setShowSearchDetail]);

  return (
    <ListItem disablePadding>
      <ListItemButton sx={{ p: 0.2 }} onClick={handleOnClick} disabled={loading as boolean}>
        <ListItemText
          secondary={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {Icon && (
                <Box display={'inline-block'} sx={{ mr: 1 }}>
                  <Icon />
                </Box>
              )}
              <Box>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="labelMedium14"
                  color={theme.palette.neutral.main}
                >
                  {recordName}
                </Typography>
                <Typography
                  sx={{ display: 'inline', marginLeft: 1 }}
                  component="span"
                  variant="labelMedium12"
                  color={theme.palette.neutral.darkGray}
                >
                  {secondaryText}
                </Typography>
              </Box>
            </Box>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default ResultListItem;
