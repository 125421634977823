import { shallowEqual, useSelector } from 'react-redux';

import { useActionCreator } from 'hooks';
import { RootState } from 'store/types';
import { DeduplicationConfigHook } from './types';
import {
  deleteDeduplicationConfigById,
  createDeduplicationConfig,
  getDeduplicationConfigByMigration,
  getDeduplicationConfigByMigrationAndTable,
  updateDeduplicationConfig,
} from './actions';

export const useDeduplicationConfig = (): DeduplicationConfigHook => {
  const deduplicationState = useSelector((state: RootState) => state.deduplicationConfig, shallowEqual);

  return {
    ...deduplicationState,
    deleteDeduplicationConfigById: useActionCreator(deleteDeduplicationConfigById),
    createDeduplicationConfig: useActionCreator(createDeduplicationConfig),
    getDeduplicationConfigByMigration: useActionCreator(getDeduplicationConfigByMigration),
    getDeduplicationConfigByMigrationAndTable: useActionCreator(getDeduplicationConfigByMigrationAndTable),
    updateDeduplicationConfig: useActionCreator(updateDeduplicationConfig),
  };
};
