import { Link, Tooltip } from '@mui/material';
import { FC, useMemo } from 'react';
import { useDashboard } from 'store/dashboard/hook';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';

export type NameCellProps = {
  recordId: string;
  table: string;
};

export const NameCell: FC<NameCellProps> = ({ recordId, table }) => {
  const {
    data: { selectedMigrationId },
  } = useDashboard();

  const recordQuery = useGetMigrationTableDataByIdQuery({
    migrationId: selectedMigrationId,
    tableId: table,
    skip: 0,
    take: 1,
    conditions: [{ field: 'Id', operator: '=', value: `'${recordId}'` }],
  });

  const label = useMemo(() => {
    if (recordQuery.data && Array.isArray(recordQuery.data) && recordQuery.data.length > 0) {
      const record = recordQuery.data[0];
      const label =
        record['Name'] || `${record['FirstName'] || ''} ${record['LastName'] || ''}`.trim() || record['Email'];
      if (label && label?.trim() !== '') {
        return label;
      }
      return recordId;
    }
    return recordId;
  }, [recordId, recordQuery.data]);

  return (
    <Tooltip title={label}>
      <Link underline="none">{label}</Link>
    </Tooltip>
  );
};
