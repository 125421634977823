import * as React from 'react';

import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Box, Card, CardContent, Collapse, IconButton, Typography } from '@mui/material';

type SchemaCardProps = {
  title: string;
  isExpanded: boolean;
  children: React.ReactNode;
  onToggleExpand: () => void;
};

const SchemaCard: React.FC<SchemaCardProps> = ({ title, isExpanded, onToggleExpand, children }) => {
  return (
    <Box>
      <Card sx={{ mb: isExpanded ? 2 : 0 }} elevation={0}>
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 2,
            py: 0.5,
            '&:last-child': { paddingBottom: 0.5 },
          }}
        >
          <Typography variant="p12" color="primary.main">
            {title}
          </Typography>
          <IconButton onClick={onToggleExpand}>{isExpanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
        </CardContent>
      </Card>
      <Collapse in={isExpanded}>{children}</Collapse>
    </Box>
  );
};

export default SchemaCard;
