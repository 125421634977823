import { Box, Grid, SxProps, Theme, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { LogicalOperator } from 'store/dataRaptorRule/dto/front-end-rule.dto';

type LogicalOperatorFormRowProps = {
  logicalOperatorRecord: LogicalOperator;
  targetPath: string;
};

const activeStyle: SxProps<Theme> = {
  backgroundColor: 'primary.main',
  color: 'neutral.white',
};

const boxStyle: SxProps<Theme> = {
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  width: '40px',
  p: 0.5,
  ':hover': { ...activeStyle, cursor: 'pointer' },
};

const LogicalOperatorFormRow: FC<LogicalOperatorFormRowProps> = (props) => {
  const { logicalOperatorRecord, targetPath } = props;
  const { setFieldValue } = useRuleForm();
  const conditionComponent = logicalOperatorRecord as LogicalOperator;
  const currentValue = conditionComponent.value;

  const handleOnValueChange = useCallback(
    (value: string) => {
      setFieldValue({ path: `${targetPath}.value`, value: value });
    },
    [setFieldValue, targetPath],
  );

  const toggleAnd = useCallback(() => {
    handleOnValueChange('AND');
  }, [handleOnValueChange]);

  const toggleOr = useCallback(() => {
    handleOnValueChange('OR');
  }, [handleOnValueChange]);

  const andStyle = useMemo(() => {
    return { ...boxStyle, ...(currentValue == 'AND' ? activeStyle : {}) };
  }, [currentValue]);

  const orStyle = useMemo(() => {
    return { ...boxStyle, ...(currentValue == 'OR' ? activeStyle : {}) };
  }, [currentValue]);

  return (
    <Grid
      container
      display={'flex'}
      gap={0.6}
      sx={{
        backgroundColor: 'primary.subtone2',
        width: 'fit-width',
        borderRadius: '4px',
        color: 'primary.main',
        p: 0.6,
      }}
    >
      <Box sx={andStyle} onClick={toggleAnd}>
        <Typography>And</Typography>
      </Box>
      <Box sx={orStyle} onClick={toggleOr}>
        <Typography>Or</Typography>
      </Box>
    </Grid>
  );
};

export { LogicalOperatorFormRow };
