import { FC, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { WhereList } from 'pages/RuleLibrary/components/RuleFormComponents/DataValidation/components/WhereList/WhereList';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { HavingList } from 'pages/RuleLibrary/components/RuleFormComponents/DataValidation/components/HavingList/HavingList';
import { AnomalyWhereList } from 'pages/RuleLibrary/components/RuleFormComponents/AnomalyDetection/components/AnomalyWhereList';
import { RuleCategory } from 'store/dataRaptorRule/types';
import { ConditionType } from 'pages/RuleLibrary/types';

export const RuleFormConditions: FC = () => {
  const {
    selectedTable,
    selectedMigration,
    fieldOptions,
    where,
    category: ruleType,
    setWhere,
    setFieldOptions,
    getFieldOptionsByMigrationAndTable,
  } = useRuleForm();

  useEffect(() => {
    if (ruleType === RuleCategory.DataValidation) {
      const options = getFieldOptionsByMigrationAndTable(selectedMigration, selectedTable);
      if (options.length > 0) {
        setFieldOptions(options);
      }
    }
  }, [getFieldOptionsByMigrationAndTable, ruleType, selectedMigration, selectedTable, setFieldOptions]);

  useEffect(() => {
    if (ruleType === RuleCategory.AnomalyDetection) {
      const typeFilter = ['phone', 'email'];
      const options = getFieldOptionsByMigrationAndTable(selectedMigration, selectedTable, false);
      if (options.length > 0) {
        setFieldOptions(options.filter((option) => typeFilter.includes(option.type || '')));
      }
    }
  }, [getFieldOptionsByMigrationAndTable, ruleType, selectedMigration, selectedTable, setFieldOptions]);

  const conditionalListComponent = useMemo(() => {
    if (ruleType === 'data-validation') {
      return (
        <>
          <WhereList
            conditionType={ConditionType.WHERE}
            wherePath={'where'}
            where={where}
            setWhere={setWhere}
            fieldOptions={fieldOptions || []}
            tableName={selectedTable}
          />
          <HavingList />
        </>
      );
    } else {
      return (
        <>
          <AnomalyWhereList
            wherePath={'where'}
            where={where}
            setWhere={setWhere}
            fieldOptions={fieldOptions || []}
            tableName={selectedTable}
          />
        </>
      );
    }
  }, [fieldOptions, ruleType, selectedTable, setWhere, where]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      {conditionalListComponent}
    </Box>
  );
};
