import { useEffect } from 'react';
import { useDeduplicationResult } from 'store/deduplicationResult/hook';
import { useGraphRender } from 'store/graphRender/hooks';
import { GraphRenderView } from 'store/graphRender/types';
import { useMigration } from 'store/migration/hooks';
import { useGraphRenderDataHandlerUtils } from '../utils/useGraphRenderDataHandlerUtils';
import { useDuplicationDataHandlerUtils, getMasterRecordIds } from '../utils/useDuplicationDataHandlerUtils';
import { useGraphData } from 'store/graphData/hooks';
import _ from 'lodash';
import { HideEventRelatedPostProcessing } from '../../utils/ComponentPostProcessing/HideEventRelatedPostProcessing';

const useDuplicateViewEffects = () => {
  const {
    data: { getDeduplicationResultByMigrationState, getDeduplicationResultByIdsState },
    getDeduplicationResultByMigration,
  } = useDeduplicationResult();
  const {
    data: { migrationId },
  } = useMigration();
  const { handleGraphComponentsLoad } = useGraphRenderDataHandlerUtils();
  const {
    data: {
      searching,
      view: graphRenderView,
      deduplicationPreloadData,
      schemaName,
      duplicatedNodeIds,
      hoverNodeId,
      graphFilter,
      duplicateQuickFilter,
    },
    setLoading,
    setDuplicatedNodeIds,
    setMasterRecordIds,
    setDuplicationCounterMap,
    setRenderDuplicatedEdgesOnHover,
    setRenderDuplicatedNodesOnHover,
  } = useGraphRender();
  const { getDuplicationCountMap, getDuplicateEdges, getUniqueIdArray, getTraversedNodesAndEdgesOnHover } =
    useDuplicationDataHandlerUtils();
  const { getDataByVertexIdArray } = useGraphData();

  useEffect(() => {
    if (
      graphRenderView === GraphRenderView.DUPLICATES &&
      !_.isEmpty(migrationId) &&
      !_.isEmpty(schemaName) &&
      searching !== true
    ) {
      getDeduplicationResultByMigration({
        migrationId,
        options: {
          limit: 40,
          tables: duplicateQuickFilter.selectedTables,
        },
      });
    }
  }, [
    getDeduplicationResultByMigration,
    duplicateQuickFilter.selectedTables,
    graphRenderView,
    migrationId,
    schemaName,
    searching,
  ]);

  useEffect(() => {
    if (
      graphRenderView === GraphRenderView.DUPLICATES &&
      getDeduplicationResultByMigrationState.success === true &&
      !_.isEmpty(migrationId) &&
      !_.isEmpty(schemaName) &&
      searching !== true
    ) {
      console.log('useDuplicateViewEffects', 'getDeduplicationResultByMigrationState');
      const data = getDeduplicationResultByMigrationState.data || [];
      const duplicationCounterMap: { [x: string]: number } = getDuplicationCountMap(data);
      setDuplicationCounterMap(duplicationCounterMap);
      const masterRecordIds = getMasterRecordIds(data);
      setMasterRecordIds(masterRecordIds);
      const duplicateConnectedEdges = getDuplicateEdges(data, schemaName || '', false);
      const uniqueIds = getUniqueIdArray(data);
      setDuplicatedNodeIds(uniqueIds.map((id) => `${schemaName}.${id}`));
      setLoading(true);
      getDataByVertexIdArray({
        migrationId,
        accountIdArray: uniqueIds,
        filter: graphFilter,
        onSuccess: (data) => {
          handleGraphComponentsLoad(data.paths, data.accounts, data.individualNodes || [], {
            formattedAdditionalEdges: JSON.parse(JSON.stringify(duplicateConnectedEdges)),
            hideExtendedNodes: false,
            nodePostProcessing: {
              filterFn: HideEventRelatedPostProcessing.nodeFilterFn,
              postProcessingFn: HideEventRelatedPostProcessing.nodePostProcessingFn,
            },
            edgePostProcessing: {
              filterFn: HideEventRelatedPostProcessing.edgeFilterFn,
              postProcessingFn: HideEventRelatedPostProcessing.edgePostProcessingFn,
            },
          });
          setLoading(false);
        },
        onError: (err) => {
          console.error('useDuplicateViewEffects', 'getDeduplicationResultByMigrationState', err);
          setLoading(false);
        },
        individualNodeIdArray: uniqueIds,
      });
    }
  }, [
    deduplicationPreloadData,
    getDataByVertexIdArray,
    getDeduplicationResultByMigrationState.data,
    getDeduplicationResultByMigrationState.success,
    getDuplicateEdges,
    getDuplicationCountMap,
    getUniqueIdArray,
    graphFilter,
    graphRenderView,
    handleGraphComponentsLoad,
    migrationId,
    schemaName,
    searching,
    setDuplicatedNodeIds,
    setDuplicationCounterMap,
    setLoading,
    setMasterRecordIds,
  ]);

  useEffect(() => {
    if (
      graphRenderView === GraphRenderView.DUPLICATES &&
      getDeduplicationResultByIdsState.success === true &&
      !_.isEmpty(migrationId) &&
      !_.isEmpty(schemaName) &&
      searching === true
    ) {
      console.log('useDuplicateViewEffects', 'getDeduplicationResultByIdsState');
      const data = getDeduplicationResultByIdsState.data || [];
      const duplicationCounterMap: { [x: string]: number } = getDuplicationCountMap(data);
      setDuplicationCounterMap(duplicationCounterMap);
      const masterRecordIds = getMasterRecordIds(data);
      setMasterRecordIds(masterRecordIds);
      const duplicateConnectedEdges = getDuplicateEdges(data, schemaName || '', false);
      const uniqueIds = getUniqueIdArray(data);
      setDuplicatedNodeIds(uniqueIds.map((id) => `${schemaName}.${id}`));
      if (uniqueIds.length > 0) {
        setLoading(true);
        getDataByVertexIdArray({
          migrationId,
          accountIdArray: uniqueIds,
          onSuccess: (data) => {
            handleGraphComponentsLoad(data.paths, data.accounts, data.individualNodes || [], {
              formattedAdditionalEdges: JSON.parse(JSON.stringify(duplicateConnectedEdges)),
              hideExtendedNodes: false,
              nodePostProcessing: {
                filterFn: HideEventRelatedPostProcessing.nodeFilterFn,
                postProcessingFn: HideEventRelatedPostProcessing.nodePostProcessingFn,
              },
              edgePostProcessing: {
                filterFn: HideEventRelatedPostProcessing.edgeFilterFn,
                postProcessingFn: HideEventRelatedPostProcessing.edgePostProcessingFn,
              },
            });
            setLoading(false);
          },
          onError: (err) => {
            console.error('useDuplicateViewEffects', 'getDeduplicationResultByIdsState', err);
            setLoading(false);
          },
          individualNodeIdArray: uniqueIds,
        });
      }
    }
  }, [
    searching,
    schemaName,
    migrationId,
    graphRenderView,
    getDeduplicationResultByIdsState.data,
    getDeduplicationResultByIdsState.success,
    getDataByVertexIdArray,
    getDuplicateEdges,
    getDuplicationCountMap,
    getUniqueIdArray,
    handleGraphComponentsLoad,
    setDuplicatedNodeIds,
    setDuplicationCounterMap,
    setLoading,
    setMasterRecordIds,
  ]);

  useEffect(() => {
    if (
      graphRenderView === GraphRenderView.DUPLICATES &&
      hoverNodeId &&
      hoverNodeId.trim() != '' &&
      duplicatedNodeIds.includes(hoverNodeId)
    ) {
      const { traversedEdges, visitedNodes } = getTraversedNodesAndEdgesOnHover();
      setRenderDuplicatedEdgesOnHover(Array.from(traversedEdges));
      setRenderDuplicatedNodesOnHover(Array.from(visitedNodes));
    } else if (graphRenderView === GraphRenderView.DUPLICATES) {
      setRenderDuplicatedEdgesOnHover([]);
      setRenderDuplicatedNodesOnHover([]);
    }
  }, [
    duplicatedNodeIds,
    getTraversedNodesAndEdgesOnHover,
    graphRenderView,
    hoverNodeId,
    setRenderDuplicatedEdgesOnHover,
    setRenderDuplicatedNodesOnHover,
  ]);
};

export default useDuplicateViewEffects;
