import { useActionCreator } from 'hooks';
import { useSelector } from 'react-redux';
import { RootState } from 'store/types';
import {
  reset,
  setAssignTo,
  setDueDate,
  setNotes,
  setStatus,
  setSubject,
  setTaskType,
  setAIDetail,
  setRequestDetails,
  setRequestRecord,
  setRequestTableName,
  setRequestRecordId,
} from './actions';

export const useCreateTaskFormStore = () => {
  const data = useSelector((state: RootState) => state.createTaskForm);

  return {
    ...data,
    reset: useActionCreator(reset),
    setAssignTo: useActionCreator(setAssignTo),
    setDueDate: useActionCreator(setDueDate),
    setNotes: useActionCreator(setNotes),
    setStatus: useActionCreator(setStatus),
    setSubject: useActionCreator(setSubject),
    setTaskType: useActionCreator(setTaskType),
    setAIDetail: useActionCreator(setAIDetail),
    setRequestDetails: useActionCreator(setRequestDetails),
    setRequestRecord: useActionCreator(setRequestRecord),
    setRequestTableName: useActionCreator(setRequestTableName),
    setRequestRecordId: useActionCreator(setRequestRecordId),
  };
};
