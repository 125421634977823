import React from 'react';
import { Stack, Typography, LinearProgress } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
const ClientResponsiveness = ({ value }: { value: number }) => {
  return (
    <Stack sx={{ border: '1px solid', borderColor: 'neutral.n300', p: 1 }} gap={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography variant="p14">CLIENT&apos;S RESPONSIVENESS</Typography>
          <ErrorIcon sx={{ color: 'neutral.n400' }} />
        </Stack>
        <Typography variant="h6">{value}%</Typography>
      </Stack>
      <LinearProgress variant="determinate" value={75} />

      <Stack direction="row" alignItems="center" gap={0.5}>
        <WbIncandescentIcon sx={{ transform: 'rotate(180deg)', fontSize: '16px', color: 'neutral.n400' }} />
        <Typography variant="caption" color="neutral.n400">
          Next best action: Lorem ipsum
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ClientResponsiveness;
