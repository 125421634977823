export function formatDate(string: string): string {
  const date = new Date(string);
  if (isNaN(date.getTime())) {
    // Invalid date, return the original string
    return string;
  } else {
    // Valid date, format it as MM/DD/YYYY
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  }
}
