import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import KeywordsContainer from './KeywordsContainer';

interface IProps {
  type: 'call' | 'meeting' | 'event' | 'email' | 'task' | 'note';
}
const ActionsTab = ({ type }: IProps) => {
  return (
    <Stack p={1} gap={2}>
      <Stack gap={0.5}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p14" color="neutral.n300">
            Related interactions
          </Typography>
          <Typography variant="p12" color="blue.main" sx={{ cursor: 'pointer' }}>
            Show all
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={1}
          sx={{ backgroundColor: '#00000010' }}
        >
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Box
              sx={{
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'blue.main',
              }}
            >
              <CheckBoxOutlinedIcon sx={{ color: 'white', fontSize: '16px' }} />
            </Box>
            <Typography variant="p14">Create PitchDeck for demo meeting</Typography>
          </Stack>
          <ChevronRightIcon sx={{ fontSize: '14px', color: 'neutral.n400' }} />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          p={1}
          sx={{ backgroundColor: '#00000010' }}
        >
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Box
              sx={{
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'violet.main',
              }}
            >
              <MailOutlineIcon sx={{ fontSize: '16px', color: 'white' }} />
            </Box>
            <Typography variant="p14">Create PitchDeck for demo meeting</Typography>
          </Stack>
          <ChevronRightIcon sx={{ fontSize: '14px', color: 'neutral.n400' }} />
        </Stack>
      </Stack>
      {['event', 'meeting', 'call'].includes(type) && (
        <>
          <Stack gap={0.5}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="p14" color="neutral.n300">
                Follow-up tasks
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              p={1}
              sx={{ backgroundColor: '#00000010' }}
            >
              <Stack direction="row" alignItems="center" gap={0.5}>
                <Box
                  sx={{
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'blue.main',
                  }}
                >
                  <CheckBoxOutlinedIcon sx={{ color: 'white', fontSize: '16px' }} />
                </Box>
                <Typography variant="p14">Schedule demo meeting with technical team</Typography>
              </Stack>
              <ChevronRightIcon sx={{ fontSize: '14px', color: 'neutral.n400' }} />
            </Stack>
          </Stack>

          <KeywordsContainer />
        </>
      )}
    </Stack>
  );
};

export default ActionsTab;
