import { Checkbox, List, ListItem, Typography } from '@mui/material';
import { FC, useState, useMemo } from 'react';

interface Option {
  label: string;
  value: string;
}

interface FilterOptionViewProps {
  filterLabel: string;
  filterOptions: Option[];
  filterAllowedValues: string[];
  onChangeCallBack: (value: any) => void;
  activateAllValues?: (activated: boolean) => void;
  limitOptions?: number;
  enableAllOption?: boolean;
}

export const FilterOptionView: FC<FilterOptionViewProps> = ({
  filterLabel,
  filterOptions,
  filterAllowedValues,
  onChangeCallBack,
  activateAllValues,
  enableAllOption,
  limitOptions,
}) => {
  const [limit, setLimit] = useState(limitOptions);

  const optionsToShow = useMemo(() => {
    const optionsToShowTmp = limitOptions ? filterOptions.slice(0, limit) : filterOptions;
    if (enableAllOption) {
      optionsToShowTmp.unshift({ label: 'All', value: '__all__' });
    }
    return optionsToShowTmp;
  }, [enableAllOption, filterOptions, limit, limitOptions]);

  const areThereMoreOptions = useMemo(() => {
    if (limit) {
      if (filterOptions.length > limit) {
        return true;
      }
      return false;
    }
    return false;
  }, [filterOptions, limit]);

  const handleOnChange = (event: any) => {
    const value: string = event.target.value;
    if (value === '__all__') {
      const activate = filterOptions.length === filterAllowedValues.length && value === '__all__';
      if (activateAllValues) {
        activateAllValues(activate);
      }
    } else {
      onChangeCallBack(event);
    }
  };

  return (
    <>
      <Typography variant="labelMedium12" sx={{ color: 'neutral.darkGray', textTransform: 'capitalize' }}>
        {filterLabel}
      </Typography>

      <List component="div" disablePadding>
        {optionsToShow?.map((option) => {
          let checked = false;
          checked = filterAllowedValues?.includes(option.value) || false;
          if (filterOptions.length === filterAllowedValues.length && option.value === '__all__') {
            checked = true;
          }
          return (
            <ListItem key={`checkbox-rule-filter-list-${option.value}`} sx={{ p: 0 }}>
              <Checkbox sx={{ paddingLeft: 0 }} value={option.value} checked={checked} onChange={handleOnChange} />
              <Typography variant="p12" sx={{ textTransform: 'capitalize' }}>
                {option.label}
              </Typography>
            </ListItem>
          );
        })}
        {limitOptions && (
          <ListItem key="checkbox-rule-filter-list-show-more" sx={{ p: 0 }}>
            <Typography
              variant="p12"
              sx={{ color: 'primary.main', cursor: 'pointer' }}
              onClick={() => setLimit(limit ? undefined : limitOptions)}
            >
              {limit && areThereMoreOptions && 'Show more'}
              {limit == null && 'Show less'}
            </Typography>
          </ListItem>
        )}
      </List>
    </>
  );
};
