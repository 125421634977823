import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  createRule as createRuleApi,
  getRulesByMigrationAndTable as getRulesByMigrationAndTableApi,
  deleteRuleById as deleteRuleByIdApi,
  updateRuleById as updateRuleByIdApi,
  getRuleRisks as getRuleRisksApi,
  getRuleValidationPatterns as getRuleValidationPatternsApi,
  getRuleDepartment as getRuleDepartmentApi,
  getRuleTypes as getRuleTypesApi,
  getRuleByMigrationAndRuleId as getRuleByMigrationAndRuleIdApi,
} from 'http/dataRaptor/dataRaptorRules';
import {
  DataRaptorRule,
  createRuleArgs,
  deleteRuleByIdArgs,
  getRulesByMigrationAndTableArgs,
  updateRuleArgs,
  RuleDepartment,
  RuleRisk,
  RuleType,
  getRulesByMigrationAndRuleIdArgs,
  RuleValidationPattern,
} from './types';
import { GetRuleDto } from './dto/getRuleDto';

const SET_ERROR = 'dataRaptorRule/SET_ERROR';
const SET_SUCCESS = 'dataRaptorRule/SET_SUCCESS';
const SET_LOADING = 'dataRaptorRule/SET_LOADING';
const SET_SELECTED_RULE_ID = 'dataRaptorRule/SET_SELECTED_RULE_ID';
const SET_FORM_MODE = 'dataRaptorRule/SET_FORM_MODE';
const CREATE_RULE = 'dataRaptorRule/POST_RULE';
const UPDATE_RULE = 'dataRaptorRule/PUT_Rule';
const GET_RULES_BY_MIGRATION_AND_TABLE = 'dataRaptorRule/GET_RULES_BY_MIGRATION_AND_TABLE';
const GET_RULE_BY_MIGRATION_AND_RULE_ID = 'dataRaptorRule/GET_RULE_BY_MIGRATION_AND_RULE_ID';
const DELETE_RULE_BY_ID = 'dataRaptorRule/DELETE_RULE_BY_ID';
const GET_RULE_TYPES = 'dataRaptorRule/GET_RULE_TYPES';
const GET_RULE_DEPARTMENTS = 'dataRaptorRule/GET_RULE_DEPARTMENTS';
const GET_RULE_RISKS = 'dataRaptorRule/GET_RULE_RISKS';
const GET_RULE_VALIDATION_PATTERNS = 'dataRaptorRule/GET_RULE_VALIDATION_PATTERNS';

export const setError = createAction<string | boolean>(SET_ERROR);
export const setSuccess = createAction<string | boolean>(SET_SUCCESS);
export const setLoading = createAction<boolean>(SET_LOADING);
export const setSelectedRuleId = createAction<string>(SET_SELECTED_RULE_ID);
export const setFormMode = createAction<string>(SET_FORM_MODE);

export const deleteRuleById = createAsyncThunk<void, deleteRuleByIdArgs>(
  DELETE_RULE_BY_ID,
  async ({ ruleId, onSuccess, onError }) => {
    try {
      const rules = await deleteRuleByIdApi(ruleId);
      if (onSuccess) onSuccess();
      return rules;
    } catch (err) {
      if (onError) onError(err ?? undefined);
      throw err;
    }
  },
);

export const getRulesByMigrationAndTable = createAsyncThunk<DataRaptorRule[], getRulesByMigrationAndTableArgs>(
  GET_RULES_BY_MIGRATION_AND_TABLE,
  async ({ migrationId, table, onSuccess, onError }) => {
    try {
      const rules = await getRulesByMigrationAndTableApi(migrationId, table);
      if (onSuccess) onSuccess(rules);
      return rules;
    } catch (err) {
      if (onError) onError(err ?? undefined);
      throw err;
    }
  },
);

export const getRuleByMigrationAndRuleId = createAsyncThunk<GetRuleDto, getRulesByMigrationAndRuleIdArgs>(
  GET_RULE_BY_MIGRATION_AND_RULE_ID,
  async ({ migrationId, ruleId, onSuccess, onError }) => {
    try {
      const rule = await getRuleByMigrationAndRuleIdApi(migrationId, ruleId);
      if (onSuccess) onSuccess(rule);
      return rule;
    } catch (err) {
      if (onError) onError(err ?? undefined);
      throw err;
    }
  },
);

export const createRule = createAsyncThunk<DataRaptorRule, createRuleArgs>(
  CREATE_RULE,
  async ({ migrationId, rule, onSuccess, onError }) => {
    try {
      const createdRule = await createRuleApi(migrationId, rule);
      if (onSuccess) onSuccess(createdRule);
      return createdRule;
    } catch (err) {
      if (onError) onError(err ?? undefined);
      throw err;
    }
  },
);

export const updateRuleById = createAsyncThunk<DataRaptorRule, updateRuleArgs>(
  UPDATE_RULE,
  async ({ ruleId, data, onSuccess, onError }) => {
    try {
      const updatedRule = await updateRuleByIdApi(ruleId, data);
      if (onSuccess) onSuccess(updatedRule);
      return updatedRule;
    } catch (err) {
      if (onError) onError(err ?? undefined);
      throw err;
    }
  },
);

export const getRuleTypes = createAsyncThunk<RuleType[]>(GET_RULE_TYPES, async () => {
  return await getRuleTypesApi();
});

export const getRuleRisks = createAsyncThunk<RuleRisk[]>(GET_RULE_RISKS, async () => {
  return await getRuleRisksApi();
});

export const getRuleValidationPatterns = createAsyncThunk<RuleValidationPattern[]>(
  GET_RULE_VALIDATION_PATTERNS,
  async () => {
    const res = await getRuleValidationPatternsApi();
    return res;
  },
);

export const getRuleDepartments = createAsyncThunk<RuleDepartment[]>(GET_RULE_DEPARTMENTS, async () => {
  return await getRuleDepartmentApi();
});
