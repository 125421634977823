import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { GridItem } from 'components/ui';
import { SubQueryValue } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { Option } from 'store/ruleForm/types';
import { FC, useEffect, useMemo } from 'react';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorExclamation.svg';
import _ from 'lodash';
import { useRuleFormStore } from 'store/ruleForm/hook';
import { FormErrorMessage } from 'pages/RuleLibrary/components/ui/FormErrorMessage';
import { useRuleLibrary } from 'store/ruleLibrary/hook';
import { useMigration } from 'store/migration/hooks';

interface SubQueryValueFormProps {
  targetPath: string;
  conditionalValueComponent: SubQueryValue;
  setFieldValue: (payload: { path: string; value: any }) => void;
  selectedMigration: string;
  xmlFilterType?: string[];
  getConditionTypeDropdown: () => JSX.Element;
  getFieldOptionsByMigrationAndTable: (migrationId: string, table: string, includeLookUps?: boolean) => Option[];
}

export const SubQueryValueForm: FC<SubQueryValueFormProps> = (props) => {
  const {
    conditionalValueComponent,
    getConditionTypeDropdown,
    selectedMigration,
    setFieldValue,
    targetPath,
    xmlFilterType,
    getFieldOptionsByMigrationAndTable,
  } = props;

  const {
    data: { migrationTableFieldsAndLookUps, migrationId },
    getMigrationTableFieldsAndLookUpsById,
  } = useMigration();

  const { formError, subQueries } = useRuleFormStore();

  const {
    subPages: {
      temporalTable: {
        data: { tables: createSubQueries },
      },
    },
  } = useRuleLibrary();

  const subQueryValue = conditionalValueComponent as SubQueryValue;

  const allSubQueries: any[] = useMemo(() => [...subQueries, ...createSubQueries], [subQueries, createSubQueries]);

  const subQueryIdOptions = useMemo(() => {
    const aliasOptions = allSubQueries.map((subQuery) => ({
      label: subQuery.alias || subQuery.name,
      value: subQuery.tempId || subQuery.ruleTempTableId,
    }));
    return aliasOptions;
  }, [allSubQueries]);

  const aliasError = useMemo(() => formError && _.get(formError, `${targetPath}.subQueryId`), [formError, targetPath]);
  const valueError = useMemo(() => formError && _.get(formError, `${targetPath}.value`), [formError, targetPath]);

  const subQueryObject = useMemo(
    () =>
      allSubQueries.find(
        (query) => query.tempId === subQueryValue.subQueryId || query.ruleTempTableId === subQueryValue.subQueryId,
      ),
    [allSubQueries, subQueryValue.subQueryId],
  );

  const exportFieldOptions = useMemo(() => {
    let options = getFieldOptionsByMigrationAndTable(selectedMigration, subQueryObject?.table || '', false);
    if (xmlFilterType && options) {
      options = options.filter((option) => xmlFilterType.includes(option?.xsd_type || '') || option.relationshipName);
    }
    return options;
  }, [getFieldOptionsByMigrationAndTable, selectedMigration, subQueryObject?.table, xmlFilterType]);

  useEffect(() => {
    const key = `${migrationId}-${subQueryObject?.table}`;
    if (!migrationTableFieldsAndLookUps[key]) {
      getMigrationTableFieldsAndLookUpsById({ migrationId, tableId: subQueryObject?.table });
    }
  }, [getMigrationTableFieldsAndLookUpsById, migrationId, migrationTableFieldsAndLookUps, subQueryObject?.table]);

  return (
    <Grid display={'flex'} flexDirection={'column'} width={'100%'} gap={1}>
      <Box display={'flex'}>
        <GridItem xs={6}>{getConditionTypeDropdown()}</GridItem>
      </Box>
      <Box display={'flex'} gap={1}>
        <GridItem xs={6}>
          <Box>
            <ControlledCustomDropdown<string>
              id={`${targetPath}.subQueryId`}
              placeholder="Alias"
              value={subQueryValue.subQueryId}
              options={subQueryIdOptions || []}
              sx={{
                backgroundColor: 'neutral.white',
                ...(aliasError ? { border: '1px solid red', borderRadius: '4px' } : {}),
              }}
              fallbackOption={{ label: 'Select Alias', value: '' }}
              onSelect={(value: string) => setFieldValue({ path: `${targetPath}.subQueryId`, value })}
              InputProps={{
                startAdornment: (
                  <>
                    {!valueError && subQueryObject?.table && (
                      <InputAdornment position="start">
                        <Typography variant="labelRegular12">{subQueryObject?.table}:</Typography>
                      </InputAdornment>
                    )}
                    {valueError && <InputAdornment position="start">{<ErrorIcon />}</InputAdornment>}
                  </>
                ),
              }}
            />
            <FormErrorMessage errorMessage={aliasError} />
          </Box>
        </GridItem>
        <GridItem xs={6}>
          <Box>
            <ControlledCustomDropdown<string>
              id={`${targetPath}.value`}
              placeholder="Field"
              value={subQueryValue.value}
              options={exportFieldOptions || []}
              sx={{
                backgroundColor: 'neutral.white',
                ...(valueError ? { border: '1px solid red', borderRadius: '4px' } : {}),
              }}
              fallbackOption={{ label: 'Select Field', value: '' }}
              onSelect={(value) => setFieldValue({ path: `${targetPath}.value`, value })}
              InputProps={{
                startAdornment: <>{valueError && <InputAdornment position="start">{<ErrorIcon />}</InputAdornment>}</>,
              }}
            />
            <FormErrorMessage errorMessage={valueError} />
          </Box>
        </GridItem>
      </Box>
    </Grid>
  );
};
