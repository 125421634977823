import { Grid, Typography } from '@mui/material';

export const getLabelAndField = (label: string, value: string, referencedUsers: { [x: string]: any } = {}) => {
  let valueTmp = value;

  if (referencedUsers && referencedUsers[valueTmp]) {
    valueTmp = referencedUsers[valueTmp].Name;
  }

  return (
    <>
      <Grid item>
        <Typography variant="labelRegular12" sx={{ color: 'neutral.n400' }}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="p14">{valueTmp}</Typography>
      </Grid>
    </>
  );
};
