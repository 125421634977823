import { ControlledCustomDropdown } from 'components/CustomDropdown';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { FC } from 'react';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';

export interface PrimitiveValueFieldProps {
  targetPath: string;
  value: string;
  options: { label: string; value: string }[];
}

export const PrimitiveValueField: FC<PrimitiveValueFieldProps> = (props) => {
  const { targetPath, value, options } = props;
  const { setFieldValue } = useRuleForm();

  return (
    <ControlledCustomDropdown<string>
      id={`${targetPath}.value`}
      placeholder="value"
      label="Value"
      value={value || ''}
      options={options || []}
      sx={{ backgroundColor: 'neutral.white' }}
      fallbackOption={{ label: 'Select Value', value: '' }}
      onSelect={(value) =>
        setFieldValue({ path: `${targetPath}`, value: { type: RuleComponentType.PRIMITIVE_VALUE, value: value } })
      }
    />
  );
};
