import { createSlice, ActionReducerMapBuilder, isAnyOf } from '@reduxjs/toolkit';

import { APPLICATION_STATUS } from 'store/integration-status/types';

import {
  setError,
  uninstall,
  authorize,
  setSuccess,
  authCallback,
  getIntegration,
  getIntegrations,
  setIntegrationStatus,
} from './actions';

import { integrationApi } from './api';
import { IntegrationState } from './types';

export const initialState: IntegrationState = {
  error: false,
  success: false,
  loading: false,
  integrations: [],
  integration: null,
  authorizeRedirectUrl: '',
  integrationStatus: 'not-installed',
};

const integrationReducer = createSlice({
  initialState,
  reducers: {},
  name: 'integration',
  extraReducers: (builder: ActionReducerMapBuilder<IntegrationState>): void => {
    builder
      .addCase(setError, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(setSuccess, (state, { payload }) => {
        state.success = payload;
      })
      .addCase(setIntegrationStatus.fulfilled, (state, { payload }) => {
        state.error = false;
        state.success = true;
        state.loading = false;
        state.integrationStatus = payload;
      });

    builder
      .addMatcher(
        isAnyOf(getIntegration.fulfilled, integrationApi.endpoints.getIntegration.matchFulfilled),
        (state, action) => {
          state.error = false;
          state.success = true;
          state.loading = false;
          state.integration = action.payload;
        },
      )
      .addMatcher(
        isAnyOf(getIntegrations.fulfilled, integrationApi.endpoints.getIntegrations.matchFulfilled),
        (state, action) => {
          state.error = false;
          state.success = true;
          state.loading = false;
          state.integrations = action.payload;
        },
      )
      .addMatcher(
        isAnyOf(uninstall.fulfilled, integrationApi.endpoints.uninstallIntegration.matchFulfilled),
        (state) => {
          state.error = false;
          state.success = true;
          state.loading = false;
        },
      );

    builder
      .addMatcher(isAnyOf(authorize.pending, integrationApi.endpoints.authorizeIntegration.matchPending), (state) => {
        state.loading = true;
        state.success = false;
        state.authorizeRedirectUrl = '';
      })
      .addMatcher(
        isAnyOf(authorize.rejected, integrationApi.endpoints.authorizeIntegration.matchRejected),
        (state, action) => {
          state.success = false;
          state.loading = false;
          state.authorizeRedirectUrl = '';
          state.error = action.error?.message ?? true;
        },
      )
      .addMatcher(
        isAnyOf(authorize.fulfilled, integrationApi.endpoints.authorizeIntegration.matchFulfilled),
        (state, action) => {
          state.error = false;
          state.success = true;
          state.loading = false;
          state.authorizeRedirectUrl = action.payload;
        },
      );

    builder
      .addMatcher(
        isAnyOf(authCallback.rejected, integrationApi.endpoints.postAuthCallback.matchRejected),
        (state, { error }) => {
          state.error = error.message || true;
          state.success = false;
          state.loading = false;
          state.authorizeRedirectUrl = '';
        },
      )
      .addMatcher(
        isAnyOf(authCallback.fulfilled, integrationApi.endpoints.postAuthCallback.matchFulfilled),
        (state) => {
          state.error = false;
          state.success = true;
          state.loading = false;
          state.integrationStatus = APPLICATION_STATUS.INSTALLED;
        },
      );

    builder
      .addMatcher(
        isAnyOf(
          uninstall.pending,
          getIntegration.pending,
          getIntegrations.pending,
          integrationApi.endpoints.getIntegration.matchPending,
          integrationApi.endpoints.getIntegrations.matchPending,
          integrationApi.endpoints.uninstallIntegration.matchPending,
        ),
        (state) => {
          state.error = false;
          state.loading = true;
          state.success = false;
          state.authorizeRedirectUrl = '';
        },
      )
      .addMatcher(
        isAnyOf(
          uninstall.rejected,
          getIntegration.rejected,
          getIntegrations.rejected,
          integrationApi.endpoints.getIntegration.matchRejected,
          integrationApi.endpoints.getIntegrations.matchRejected,
          integrationApi.endpoints.uninstallIntegration.matchRejected,
        ),
        (state, action) => {
          state.success = false;
          state.loading = false;
          state.error = action.error.message ?? true;
        },
      );
  },
});

export default integrationReducer.reducer;
