import { FC } from 'react';

import { useGraphSpace } from 'store/graphSpace/hooks';
import { CheckCircle, Warning, Error, Refresh as RefreshIcon } from '@mui/icons-material';
import { Avatar, Box, Button, Card, CardMedia, Divider, Grid, Typography } from '@mui/material';

import { Show } from 'components/show';
import { EditableText } from './EditableText';
import { WorkspaceSetting } from './WorkspaceSetting';
import { IntegratedGraphSpace } from 'store/graphSpace/types';

interface WorkspaceCardProps {
  integratedGraphSpace: IntegratedGraphSpace;
}

export const WorkspaceCard: FC<WorkspaceCardProps> = ({ integratedGraphSpace }) => {
  const { updateName, updateDescription, toggleIsArchived } = useGraphSpace();

  const graphSpaceImage = integratedGraphSpace.image;
  const visibleMembers = integratedGraphSpace?.invitedMembers?.slice(0, 3);
  const remainingMembers = (integratedGraphSpace?.invitedMembers?.length ?? 0) - 3;

  const toggleIsArchivedValue = () => {
    toggleIsArchived(integratedGraphSpace.graphSpaceId);
  };

  const updateNameValue = (name: string) => {
    updateName({
      name: name,
      graphSpaceId: integratedGraphSpace.graphSpaceId,
    });
  };

  const updateDescriptionValue = (description: string) => {
    updateDescription({
      description,
      graphSpaceId: integratedGraphSpace.graphSpaceId,
    });
  };

  return (
    <Card sx={{ px: 4, py: 2, position: 'relative' }} elevation={0}>
      <Box sx={{ pointerEvents: integratedGraphSpace?.isArchived ? 'none' : 'auto' }}>
        <Grid container justifyContent={'space-between'} alignItems={'flex-end'}>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="labelRegular12" sx={{ color: 'neutral.n400', marginRight: 1 }}>
                Data Source:
              </Typography>
              {integratedGraphSpace?.dataSources?.map((ds, index) => {
                return (
                  <img
                    key={index}
                    src={ds.icon}
                    height={25}
                    style={{
                      marginRight: 3,
                      borderRadius: 100,
                    }}
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent={'flex-end'} alignItems="center">
              <Typography variant="labelRegular12" sx={{ color: 'neutral.n400', marginRight: 1 }}>
                Status:
              </Typography>
              {integratedGraphSpace?.statusIcon === 'bad' && <Error color="error" />}
              {integratedGraphSpace?.statusIcon === 'warning' && <Warning color="warning" />}
              {integratedGraphSpace?.statusIcon === 'good' && <CheckCircle color="success" />}
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ position: 'relative', height: '172px' }}>
          <CardMedia
            component="img"
            sx={{
              height: '172px',
              display: 'block',
              margin: '0 auto',
              objectFit: 'cover',
            }}
            alt="graphSpaceProject image"
            image={integratedGraphSpace?.image ?? 'https://i.ibb.co/hRNGtBJ/Screenshot-2024-10-04-120623.png'}
            src={
              graphSpaceImage?.length ? graphSpaceImage : 'https://i.ibb.co/hRNGtBJ/Screenshot-2024-10-04-120623.png'
            }
          />
          <Box
            sx={{
              right: 10,
              bottom: 10,
              display: 'flex',
              position: 'absolute',
            }}
          >
            {visibleMembers?.map((member, index) => (
              <Avatar
                key={index}
                alt={member.name}
                src={member.photo}
                sx={{
                  marginLeft: index > 0 ? '-10px' : 0,
                }}
              />
            ))}
            {remainingMembers > 0 && (
              <Avatar
                sx={{
                  marginLeft: '-10px',
                  color: 'neutral.main',
                  backgroundColor: 'neutral.white',
                }}
              >
                +{remainingMembers}
              </Avatar>
            )}
          </Box>
        </Box>
        <EditableText
          variant="h4"
          color="neutral.main"
          sx={{ mt: 2 }}
          onChange={updateNameValue}
          value={integratedGraphSpace.name ?? ''}
        />
        <Typography variant="p14" sx={{ color: 'neutral.main' }}>
          by {integratedGraphSpace?.createdBy}
        </Typography>
        <EditableText
          sx={{ mt: 1 }}
          variant="p14"
          color="neutral.n400"
          onChange={updateDescriptionValue}
          value={integratedGraphSpace.description ?? ''}
        />
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ color: 'neutral.n400', mt: 1 }}>
            Notes activity: {integratedGraphSpace?.lastModified} by {integratedGraphSpace?.modifiedBy}
          </Typography>
          <WorkspaceSetting onArchiveToggle={toggleIsArchivedValue} />
        </Box>
      </Box>
      <Show when={integratedGraphSpace?.isArchived ?? false}>
        <Box
          sx={{
            top: 0,
            left: 0,
            zIndex: 10,
            width: '100%',
            height: '100%',
            display: 'flex',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          }}
        >
          <Button
            variant="outlined"
            onClick={toggleIsArchivedValue}
            startIcon={<RefreshIcon />}
            sx={{ backgroundColor: 'neutral.white', color: 'primary.main', p: 3 }}
          >
            <Typography variant="h4">Restore</Typography>
          </Button>
        </Box>
      </Show>
    </Card>
  );
};
