export const parseEmailText = (input: string) => {
  const properties: Record<string, any> = {};

  const regex = /(\w+):\s*([^:]+)(?=\s+\w+:|$)/g;
  let match;

  while ((match = regex.exec(input)) !== null) {
    const key = match[1].toLowerCase();
    const value = match[2].trim();
    properties[key] = value || null;
  }

  return properties;
};
