import { CustomDropdown } from 'components/CustomDropdown';
import { ConditionType } from 'pages/RuleForm/types';
import { FC } from 'react';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { FieldReference, LookUpTable } from 'store/dataRaptorRule/dto/front-end-rule.dto';

export interface LookUpValueSelectorProps {
  lookUpComponents: (LookUpTable | FieldReference)[];
  index: number;
  conditionType: ConditionType;
}

export const LookUpValuePlaceholder: FC<LookUpValueSelectorProps> = (props) => {
  const { lookUpComponents, index, conditionType } = props;
  const getLookUpStringValue = (nonEmptyLookups: (LookUpTable | FieldReference)[]) => {
    const values: string[] = [];
    nonEmptyLookups.forEach((component, index) => {
      if (index === 0 && conditionType === ConditionType.HAVING) {
        const componentLookUpTable = component as LookUpTable;
        values.push(`${componentLookUpTable.referenceTable}(${componentLookUpTable.relationShipName})`);
      } else if (component.type === RuleComponentType.LOOKUP_TABLE) {
        const componentLookUpTable = component as LookUpTable;
        values.push(`${componentLookUpTable.table}(${componentLookUpTable.joinField})`);
      } else {
        const componentFieldReference = component as FieldReference;
        const previousLookUpTable = nonEmptyLookups[index - 1] as LookUpTable;
        values.push(`${previousLookUpTable.referenceTable}(${componentFieldReference.value})`);
      }
    });
    return values.join('->');
  };

  let nonEmptyLookups: any = [];
  if (Array.isArray(lookUpComponents)) {
    nonEmptyLookups = lookUpComponents?.filter((component) => Object.keys(component).length > 0);
  } else {
    console.log('LookUpComponents', lookUpComponents);
  }

  return (
    <CustomDropdown<string>
      id={`lookUpPlaceholder-${conditionType}[${index}]`}
      placeholder={getLookUpStringValue(nonEmptyLookups)}
      value={null}
      disabled={true}
      options={[]}
      onSelect={() => {
        return '';
      }}
    />
  );
};
