import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { createGenericSlice, GenericState } from '../../firebase-redux/generic';
import {
  deleteMigrationById,
  getMigrationById,
  getMigrations,
  getMigrationTableDataById,
  getMigrationTableFieldsById,
  getMigrationTablesById,
  initStates,
  postMigration,
  setError,
  setLoading,
  setMigrationId,
  setMigrationTableName,
  setSuccess,
  getMigrationTableFieldsAndLookUpsById,
  getMigrationTableForeignReferencesById,
  getMigrationMinMaxFieldValue,
  getMigrationFieldValueOptions,
} from './actions';
import { MigrationState } from './types';
import _ from 'lodash';
import { resetApp } from 'store/actions';

export const initialState: GenericState<MigrationState> = {
  error: false,
  loading: true,
  success: false,
  data: {
    migrations: [],
    migrationById: {},
    createdMigration: {},
    migrationId: '',
    migratedTableName: '',
    deletedMigration: {},
    migratedTables: [],
    migratedTableField: [],
    migratedTableData: [],
    migrationTableFieldsAndLookUps: {},
    migrationTableForeignReferences: {},
    migrationTableMinMaxFieldValue: {},
    migrationTableFieldValueOptions: {},
  },
};

const slice = createGenericSlice({
  name: 'migration',
  initialState: _.cloneDeep(initialState),
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<GenericState<MigrationState>>): void => {
    builder.addCase(setLoading, (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });
    builder.addCase(setError, (state) => {
      state.error = true;
      state.loading = false;
      state.success = false;
    });
    builder.addCase(setSuccess, (state) => {
      state.success = true;
      state.loading = false;
      state.error = false;
    });
    builder.addCase(getMigrationById.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(getMigrationById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.data.migrationById = payload;
    });
    builder.addCase(getMigrationById.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.data.migrationById = {};
    });
    builder.addCase(getMigrationTableFieldsAndLookUpsById.pending, (state, { meta }) => {
      const key = `${meta.arg.migrationId}-${meta.arg.tableId}`;
      if (!state.data.migrationTableFieldsAndLookUps[key]) {
        state.data.migrationTableFieldsAndLookUps[key] = {};
      }
      state.data.migrationTableFieldsAndLookUps[key].loading = true;
      state.data.migrationTableFieldsAndLookUps[key].success = false;
      state.data.migrationTableFieldsAndLookUps[key].error = false;
    });
    builder.addCase(getMigrationTableFieldsAndLookUpsById.fulfilled, (state, { payload, meta }) => {
      const key = `${meta.arg.migrationId}-${meta.arg.tableId}`;
      if (!state.data.migrationTableFieldsAndLookUps[key]) {
        state.data.migrationTableFieldsAndLookUps[key] = {};
      }
      state.data.migrationTableFieldsAndLookUps[key].loading = false;
      state.data.migrationTableFieldsAndLookUps[key].success = true;
      state.data.migrationTableFieldsAndLookUps[key].error = false;
      const data = {
        fields: payload[0],
        lookUps: payload[1],
      };
      state.data.migrationTableFieldsAndLookUps[key].data = data;
    });
    builder.addCase(getMigrationTableFieldsAndLookUpsById.rejected, (state, { meta }) => {
      const key = `${meta.arg.migrationId}-${meta.arg.tableId}`;
      if (state.data.migrationTableFieldsAndLookUps[key].loading) {
        state.data.migrationTableFieldsAndLookUps[key].loading = false;
        state.data.migrationTableFieldsAndLookUps[key].success = false;
        state.data.migrationTableFieldsAndLookUps[key].error = true;
        state.data.migrationTableFieldsAndLookUps[key].data = {};
      }
    });
    builder.addCase(getMigrationTableForeignReferencesById.pending, (state, { meta }) => {
      const key = `${meta.arg.migrationId}-${meta.arg.tableId}`;
      if (!state.data.migrationTableForeignReferences[key]) {
        state.data.migrationTableForeignReferences[key] = {};
      }
      state.data.migrationTableForeignReferences[key].loading = true;
      state.data.migrationTableForeignReferences[key].success = false;
      state.data.migrationTableForeignReferences[key].error = false;
    });
    builder.addCase(getMigrationTableForeignReferencesById.fulfilled, (state, { payload, meta }) => {
      const key = `${meta.arg.migrationId}-${meta.arg.tableId}`;
      if (!state.data.migrationTableForeignReferences[key]) {
        state.data.migrationTableForeignReferences[key] = {};
      }
      state.data.migrationTableForeignReferences[key].loading = false;
      state.data.migrationTableForeignReferences[key].success = true;
      state.data.migrationTableForeignReferences[key].error = false;
      state.data.migrationTableForeignReferences[key].data = payload;
    });
    builder.addCase(getMigrationTableForeignReferencesById.rejected, (state, { meta }) => {
      const key = `${meta.arg.migrationId}-${meta.arg.tableId}`;
      if (state.data.migrationTableForeignReferences[key]) {
        state.data.migrationTableForeignReferences[key].loading = false;
        state.data.migrationTableForeignReferences[key].success = false;
        state.data.migrationTableForeignReferences[key].error = true;
        state.data.migrationTableForeignReferences[key].data = {};
      }
    });
    builder.addCase(getMigrationMinMaxFieldValue.pending, (state, { meta }) => {
      const key = `${meta.arg.migrationId}-${meta.arg.tableId}-${meta.arg.fieldName}`;
      if (!state.data.migrationTableMinMaxFieldValue[key]) {
        state.data.migrationTableMinMaxFieldValue[key] = {};
      }
      state.data.migrationTableMinMaxFieldValue[key].loading = true;
      state.data.migrationTableMinMaxFieldValue[key].success = false;
      state.data.migrationTableMinMaxFieldValue[key].error = false;
    });
    builder.addCase(getMigrationMinMaxFieldValue.fulfilled, (state, { payload, meta }) => {
      const key = `${meta.arg.migrationId}-${meta.arg.tableId}-${meta.arg.fieldName}`;
      if (!state.data.migrationTableMinMaxFieldValue[key]) {
        state.data.migrationTableMinMaxFieldValue[key] = {};
      }
      state.data.migrationTableMinMaxFieldValue[key].loading = false;
      state.data.migrationTableMinMaxFieldValue[key].success = true;
      state.data.migrationTableMinMaxFieldValue[key].error = false;
      state.data.migrationTableMinMaxFieldValue[key].data = payload;
    });
    builder.addCase(getMigrationMinMaxFieldValue.rejected, (state, { meta }) => {
      const key = `${meta.arg.migrationId}-${meta.arg.tableId}-${meta.arg.fieldName}`;
      if (state.data.migrationTableMinMaxFieldValue[key]) {
        state.data.migrationTableMinMaxFieldValue[key].loading = false;
        state.data.migrationTableMinMaxFieldValue[key].success = false;
        state.data.migrationTableMinMaxFieldValue[key].error = true;
        state.data.migrationTableMinMaxFieldValue[key].data = {};
      }
    });
    builder.addCase(getMigrationFieldValueOptions.pending, (state, { meta }) => {
      const key = `${meta.arg.migrationId}-${meta.arg.tableId}-${meta.arg.fieldName}`;
      if (!state.data.migrationTableFieldValueOptions[key]) {
        state.data.migrationTableFieldValueOptions[key] = {};
      }
      state.data.migrationTableFieldValueOptions[key].loading = true;
      state.data.migrationTableFieldValueOptions[key].success = false;
      state.data.migrationTableFieldValueOptions[key].error = false;
    });
    builder.addCase(getMigrationFieldValueOptions.fulfilled, (state, { payload, meta }) => {
      const key = `${meta.arg.migrationId}-${meta.arg.tableId}-${meta.arg.fieldName}`;
      if (!state.data.migrationTableFieldValueOptions[key]) {
        state.data.migrationTableFieldValueOptions[key] = {};
      }
      state.data.migrationTableFieldValueOptions[key].loading = false;
      state.data.migrationTableFieldValueOptions[key].success = true;
      state.data.migrationTableFieldValueOptions[key].error = false;
      state.data.migrationTableFieldValueOptions[key].data = payload;
    });
    builder.addCase(getMigrationFieldValueOptions.rejected, (state, { meta }) => {
      const key = `${meta.arg.migrationId}-${meta.arg.tableId}-${meta.arg.fieldName}`;
      if (state.data.migrationTableMinMaxFieldValue[key]) {
        state.data.migrationTableFieldValueOptions[key].loading = false;
        state.data.migrationTableFieldValueOptions[key].success = false;
        state.data.migrationTableFieldValueOptions[key].error = true;
        state.data.migrationTableFieldValueOptions[key].data = {};
      }
    });
    builder.addCase(getMigrations.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(getMigrations.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.data.migrations = payload;
    });
    builder.addCase(getMigrations.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.data.migrations = [];
    });
    builder.addCase(postMigration.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(postMigration.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.data.createdMigration = payload;
    });
    builder.addCase(postMigration.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.data.createdMigration = {};
    });
    builder.addCase(deleteMigrationById.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(deleteMigrationById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.data.deletedMigration = payload;
    });
    builder.addCase(deleteMigrationById.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.data.deletedMigration = {};
    });
    builder.addCase(getMigrationTablesById.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(getMigrationTablesById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.data.migratedTables = payload
        .filter((pay) => pay.row_count > 0)
        .sort((a, b) => a.table_name.localeCompare(b.table_name));
    });
    builder.addCase(getMigrationTablesById.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.data.migratedTables = [];
    });
    builder.addCase(getMigrationTableFieldsById.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(getMigrationTableFieldsById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.data.migratedTableField = payload;
    });
    builder.addCase(getMigrationTableFieldsById.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.data.migratedTableField = [];
    });
    builder.addCase(getMigrationTableDataById.pending, (state) => {
      state.loading = true;
      state.success = false;
      state.error = false;
    });
    builder.addCase(getMigrationTableDataById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.data.migratedTableData = payload;
    });
    builder.addCase(getMigrationTableDataById.rejected, (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.data.migratedTableData = [];
    });
    builder.addCase(setMigrationId, (state, { payload }) => {
      state.data.migrationId = payload;
    });
    builder.addCase(setMigrationTableName, (state, { payload }) => {
      state.data.migratedTableName = payload;
    });
    builder.addCase(initStates, (state) => {
      state.data.migrations = [];
      state.data.migrationById = {};
      state.data.createdMigration = {};
      state.data.migrationId = '';
      state.data.migratedTableName = '';
      state.data.deletedMigration = {};
      state.data.migratedTables = [];
      state.data.migratedTableField = [];
      state.data.migratedTableData = [];
    });
    builder.addCase(resetApp, (state) => {
      const initialStateTmp = _.cloneDeep(initialState);
      Object.keys(state).forEach((key) => {
        // @ts-ignore
        state[key] = initialStateTmp[key];
      });
    });
  },
});
export const actions = slice.actions;
export default slice.reducer;
