import React from 'react';
import { Stack, Typography, Avatar, CircularProgress } from '@mui/material';
import format from 'date-fns/format';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';
import { parseEmailText } from '../../../NodePanel/AccountPanel/components/MenuViews/Activity/utils/parseEmailText';
import { NodeToolbar, Position } from 'reactflow';

interface IProps {
  migrationId: string;
  taskId: string;
  isOpen?: boolean;
}
const EmailTooltip = ({ taskId, migrationId, isOpen = false }: IProps) => {
  const [tooltipVisible, setTooltipVisible] = React.useState<boolean>(false);
  const { data: tasksData = [], isFetching: tasksLoading } = useGetMigrationTableDataByIdQuery({
    migrationId: migrationId,
    tableId: 'Task',
    skip: 0,
    take: 0,
    conditions: [{ field: 'Id', operator: '=', value: `'${taskId}'` }],
  });

  const data = React.useMemo(() => tasksData[0], [tasksData]);

  const { data: contacts = [], isFetching: contactsLoading } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'Contact',
      skip: 0,
      take: 0,
      conditions: [{ field: 'Id', operator: 'IN', value: `'('${data?.WhoId}','${data?.WhatId}','${data?.OwnerId}')'` }],
    },
    {
      skip: !data,
    },
  );
  const emailData = React.useMemo(() => (data ? parseEmailText(data?.Description) : null), [data]);

  const isVisible = React.useMemo(() => tooltipVisible || isOpen, [tooltipVisible, isOpen]);
  return (
    <NodeToolbar
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
      isVisible={isVisible}
      position={Position.Left}
    >
      <Stack
        sx={{
          zIndex: 999,
          p: 1,
          backgroundColor: 'blue.dark',
          borderRadius: '5px',
          color: 'common.white',
          width: '230px',
        }}
        gap={0.5}
      >
        <Typography variant="b12" color="common.white" mb={1}>
          {tasksLoading ? <CircularProgress sx={{ color: 'white', width: '25px', height: '25px' }} /> : data?.Subject}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Date</Typography>
          {tasksLoading ? (
            <CircularProgress sx={{ color: 'white', width: '25px', height: '25px' }} />
          ) : (
            <Typography variant="labelMedium12">
              {data?.CreatedDate ? format(new Date(data?.CreatedDate), 'MMM dd, h:mm aa') : null}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">To</Typography>
          <Typography variant="labelMedium12">{emailData?.to}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="p12">Assignees</Typography>
          {contactsLoading ? (
            <CircularProgress sx={{ color: 'white', width: '25px', height: '25px' }} />
          ) : (
            <Typography variant="labelMedium12">
              {contacts.map((singleContact) => (
                <Avatar sx={{ width: '18px', height: '18px', fontSize: '12px' }} key={`avatar_${singleContact?.Id}`}>
                  {singleContact?.Name[0]}
                </Avatar>
              ))}
            </Typography>
          )}
        </Stack>
      </Stack>
    </NodeToolbar>
  );
};

export default EmailTooltip;
