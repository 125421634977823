const nodeDefaults = {
  style: {
    borderRadius: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export enum BridgeNodeTypeEnum {
  LeadOpenNode = 'LeadOpenNode',
  BridgeNode = 'BridgeNode',
  LeadViewCategoryNode = 'LeadViewCategoryNode',
  LeadViewCenterNode = 'LeadViewCenterNode',
  EventBridgeNode = 'EventBridgeNode',
}

export interface NodeBridgeData {
  connectionsCount?: number;
  bridgeLabel?: string;
  id?: string;
}
export interface NodeBridgeProperties {
  position?: { x: number; y: number };
  type?: BridgeNodeTypeEnum;
  hidden: boolean;
  draggable?: boolean;
}

export class BridgeNode {
  public data: any;
  public properties: any;

  constructor(
    id: string,
    label: string,
    options: {
      data?: NodeBridgeData;
      bridgeLabel?: string;
      metaData?: any;
      properties?: NodeBridgeProperties;
      style?: React.CSSProperties;
    } = {},
  ) {
    const { data = {}, bridgeLabel, metaData = {}, properties = { hidden: false }, style } = options;
    const styleByType = this.getNodeTypeStyle(properties.type || BridgeNodeTypeEnum.BridgeNode);
    this.data = {
      bridgeLabel: bridgeLabel || label,
      bridge: true,
      ...data,
      metaData: metaData,
      label: label,
      id: id,
    };
    this.properties = {
      type: 'BridgeNode',
      style: { ...nodeDefaults.style, ...styleByType, ...style },
      ...properties,
    };
  }

  getNodeTypeStyle(type: BridgeNodeTypeEnum) {
    switch (type) {
      case BridgeNodeTypeEnum.EventBridgeNode:
        return { width: '15px', height: '15px' };
      case BridgeNodeTypeEnum.LeadOpenNode:
        return { width: '50px', height: '50px' };
      case BridgeNodeTypeEnum.BridgeNode:
        return { width: '50px', height: '50px' };
      case BridgeNodeTypeEnum.LeadViewCategoryNode:
        return { width: '40px', height: '40px' };
      case BridgeNodeTypeEnum.LeadViewCenterNode:
        return { width: '75px', height: '75px' };
      default:
        return { width: '50px', height: '50px' };
    }
  }
}
