import { Button } from '@mui/material';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { FC } from 'react';
import { SubQuery } from 'store/ruleForm/types';

interface QueryCardProps {
  subQuery: SubQuery;
  index: number;
}

export const QueryCard: FC<QueryCardProps> = (props) => {
  const { subQuery, index } = props;
  const { alias, table, where } = subQuery;
  const { toggleSubQueryModal } = useRuleForm();
  return (
    <>
      <Button onClick={() => toggleSubQueryModal(index)} variant="outlined">
        {alias || `Sub Query ${index + 1}`}
      </Button>
    </>
  );
};
