import { Box, Grid, Typography } from '@mui/material';
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
import { ReactComponent as OpportunityIcon } from 'assets/icons/graph/graphLeadIcon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit-no-bg.svg';
import { FC } from 'react';
import theme from 'core/theme';

export const Footer: FC = () => {
  return (
    <Box display={'flex'} alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
      <Grid item display={'flex'} alignItems={'center'}>
        <Box display={'flex'} alignItems={'center'} gap={0.5} sx={{ m: 'auto', cursor: 'pointer' }}>
          <Box
            width={'32px'}
            height={'32px'}
            sx={{ backgroundColor: theme.palette.primary.main, borderRadius: '100%' }}
          >
            <OpportunityIcon display={'inline'} style={{ stroke: 'white', fill: 'white' }} />
          </Box>
          <Typography sx={{ color: 'primary.main', display: 'inline' }}>Convert</Typography>
        </Box>
      </Grid>

      <Grid item display={'flex'} alignItems={'center'}>
        <Box display={'flex'} alignItems={'center'} gap={0.5} sx={{ m: 'auto', cursor: 'pointer' }}>
          <BellIcon display={'inline'} />
          <Typography sx={{ color: 'primary.main', display: 'inline' }}>Set Reminder</Typography>
        </Box>
      </Grid>

      <Grid item sx={{ height: '100%' }} display={'flex'} alignItems={'center'}>
        <Box display={'flex'} alignItems={'center'} gap={0.5} sx={{ m: 'auto', cursor: 'pointer' }}>
          <EditIcon display={'inline'} />
          <Typography sx={{ color: 'primary.main', display: 'inline' }}>All Reminders</Typography>
        </Box>
      </Grid>
    </Box>
  );
};
