import { createAction } from '@reduxjs/toolkit';

const RESET = 'createTaskForm/RESET';
const SET_SUBJECT = 'createTaskForm/SET_SUBJECT';
const SET_NOTES = 'createTaskForm/SET_NOTES';
const SET_DUE_DATE = 'createTaskForm/SET_DUE_DATE';
const SET_TASK_TYPE = 'createTaskForm/SET_TASK_TYPE';
const SET_ASSIGN_TO = 'createTaskForm/SET_ASSIGN_TO';
const SET_STATUS = 'createTaskForm/SET_STATUS';
const SET_AI_DETAIL = 'createTaskForm/SET_AI_DETAIL';
const SET_REQUEST_DETAILS = 'createTaskForm/SET_REQUEST_DETAILS';
const SET_REQUEST_RECORD = 'createTaskForm/SET_REQUEST_RECORD';
const SET_REQUEST_TABLE_NAME = 'createTaskForm/SET_REQUEST_TABLE_NAME';
const SET_REQUEST_RECORD_ID = 'createTaskForm/SET_REQUEST_RECORD_ID';

export const reset = createAction<void>(RESET);
export const setSubject = createAction<string>(SET_SUBJECT);
export const setNotes = createAction<string>(SET_NOTES);
export const setDueDate = createAction<string>(SET_DUE_DATE);
export const setTaskType = createAction<string>(SET_TASK_TYPE);
export const setAssignTo = createAction<string>(SET_ASSIGN_TO);
export const setStatus = createAction<string>(SET_STATUS);
export const setAIDetail = createAction<string>(SET_AI_DETAIL);
export const setRequestDetails = createAction<string[]>(SET_REQUEST_DETAILS);
export const setRequestRecord = createAction<any>(SET_REQUEST_RECORD);
export const setRequestTableName = createAction<any>(SET_REQUEST_TABLE_NAME);
export const setRequestRecordId = createAction<string>(SET_REQUEST_RECORD_ID);
