import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  createChat as createChatApi,
  getChats as getChatsApi,
  getChat as getChatApi,
  updateChat as updateChatApi,
  deleteChat as deleteChatApi,
} from '../../http/chat';
import { Chat, Thread } from './types';

const SET_ERROR = 'chat/SET_ERROR';
const SET_SUCCESS = 'chat/SET_SUCCESS';
const CREATE_CHAT = 'chat/CREATE_CHAT';
const GET_CHATS = 'chat/GET_CHATS';
const GET_CHAT = 'chat/GET_CHAT';
const UPDATE_CHAT = 'chat/UPDATE_CHAT';
const DELETE_CHAT = 'chat/DELETE_CHAT';

export const setError = createAction<string | boolean>(SET_ERROR);

export const setSuccess = createAction<string | boolean>(SET_SUCCESS);

export const createChat = createAsyncThunk<Chat, Partial<Chat>>(CREATE_CHAT, async (data) => {
  const chats = await createChatApi(data);
  return chats;
});

export const getChats = createAsyncThunk<Chat[]>(GET_CHATS, async () => {
  const chats = await getChatsApi();
  return chats;
});

export const getChat = createAsyncThunk<Chat, number>(GET_CHAT, async (id) => {
  const chat = await getChatApi(id);
  return chat;
});

export const updateChat = createAsyncThunk<Chat, [number, Partial<Chat>]>(UPDATE_CHAT, async (arg) => {
  const [id, data] = arg;
  const chat = await updateChatApi(id, data);
  return chat;
});

export const deleteChat = createAsyncThunk<void, number>(DELETE_CHAT, async (id) => {
  await deleteChatApi(id);
});

//thread
import {
  createThread as createThreadApi,
  getThreads as getThreadsApi,
  getThread as getThreadApi,
  updateThread as updateThreadApi,
  deleteThread as deleteThreadApi,
} from '../../http/chat';

const SET_THREAD_ERROR = 'thread/SET_ERROR';
const SET_THREAD_SUCCESS = 'thread/SET_SUCCESS';
const CREATE_THREAD = 'thread/CREATE_THREAD';
const GET_THREADS = 'thread/GET_THREADS';
const GET_THREAD = 'thread/GET_THREAD';
const UPDATE_THREAD = 'thread/UPDATE_THREAD';
const DELETE_THREAD = 'thread/DELETE_THREAD';

export const setThreadError = createAction<string | boolean>(SET_THREAD_ERROR);

export const setThreadSuccess = createAction<string | boolean>(SET_THREAD_SUCCESS);

export const createThread = createAsyncThunk<Thread, [number, Partial<Thread>]>(
  CREATE_THREAD,
  async ([chatId, data]) => {
    const threads = await createThreadApi(chatId, data);
    return threads;
  },
);

export const getThreads = createAsyncThunk<Thread[], number>(GET_THREADS, async (chatID) => {
  const threads = await getThreadsApi(chatID);
  return threads;
});

export const getThread = createAsyncThunk<Thread, [number, number]>(GET_THREAD, async ([chatID, threadId]) => {
  const thread = await getThreadApi(chatID, threadId);
  return thread;
});

export const updateThread = createAsyncThunk<Thread, [number, number, Partial<Thread>]>(
  UPDATE_THREAD,
  async ([chatID, threadId, data]) => {
    const thread = await updateThreadApi(chatID, threadId, data);
    return thread;
  },
);

export const deleteThread = createAsyncThunk<void, [number, number]>(DELETE_THREAD, async ([chatID, threadId]) => {
  await deleteThreadApi(chatID, threadId);
});
