import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { RuleComponent, RuleComponentType } from 'store/dataRaptorRule/dto/Enums';

import {
  FunctionValue,
  RootCondition,
  PrimitiveValue,
  FieldReference,
  LogicalOperator,
} from 'store/dataRaptorRule/dto/front-end-rule.dto';

export const whereConditionComponent: Record<
  RuleComponentType,
  (condition: RuleComponent, tableName?: string) => JSX.Element
> = {
  ROOT_CONDITIONAL: (condition, tableName) => {
    const { field, operator, value } = condition as RootCondition;

    const fieldType = field.type as RuleComponentType;
    const valueType = value.type as RuleComponentType;

    return (
      <Box display="flex" paddingLeft={1} borderLeft="3px solid #6781FF" fontStyle={'italic'}>
        <span>{whereConditionComponent[fieldType](field, tableName)}</span>
        <Typography variant="subtitle2" paddingX={1}>
          {operator.toLowerCase()}
        </Typography>
        <span>{whereConditionComponent[valueType](value, tableName)}</span>
      </Box>
    );
  },
  LOGICAL_OPERATOR: (condition) => {
    const { type, value } = condition as LogicalOperator;

    return (
      <Tooltip arrow title={type}>
        <Chip
          size="small"
          label={value}
          sx={{
            borderRadius: 2,
            color: '#5777FF',
            fontWeight: 'bold',
            backgroundColor: '#DAE9FF',
          }}
        />
      </Tooltip>
    );
  },
  FIELD_REFERENCE: (condition, tableName) => {
    const { value } = condition as FieldReference;

    return (
      <Tooltip arrow title={condition.type}>
        <span>
          <strong>
            {tableName}.{value}
          </strong>
        </span>
      </Tooltip>
    );
  },
  FUNCTION_VALUE: (condition, tableName) => {
    const { type, value: params, function: funcName } = condition as FunctionValue;

    const paramComponents = (
      <>
        {params.map((arg, index) => {
          const argType = arg.type as RuleComponentType;

          return (
            <>
              <span key={index}>{whereConditionComponent[argType](arg, tableName)}</span>
              {index < params.length - 1 ? ', ' : ''}
            </>
          );
        })}
      </>
    );

    return (
      <span>
        <Tooltip arrow title={type}>
          <strong>{funcName}</strong>
        </Tooltip>
        ({paramComponents})
      </span>
    );
  },
  PRIMITIVE_VALUE: (condition) => {
    const { type, format, value } = condition as PrimitiveValue;

    return (
      <Tooltip arrow title={`${type}, ${format}`}>
        <Typography color="grey" variant="subtitle2" component="span">
          {value}
        </Typography>
      </Tooltip>
    );
  },
  OPEN_PARENTHESIS: (condition) => {
    return <span>{JSON.stringify(condition)}</span>;
  },
  CLOSED_PARENTHESIS: (condition) => {
    return <span>{JSON.stringify(condition)}</span>;
  },
  LOOKUP_VALUE: (condition) => {
    return <span>{JSON.stringify(condition)}</span>;
  },
  LOOKUP_TABLE: (condition) => {
    return <span>{JSON.stringify(condition)}</span>;
  },
  SUB_QUERY_VALUE: (condition) => {
    return <span>{JSON.stringify(condition)}</span>;
  },
  REGEX: (condition) => {
    return <span>{JSON.stringify(condition)}</span>;
  },
  SERVICE: (condition) => {
    return <span>{JSON.stringify(condition)}</span>;
  },
};
