import { Grid, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { getLabelAndField } from './utils';

interface DetailMenuViewProps {
  node: any;
  referencedUsers: any;
}

const DetailMenuView: FC<DetailMenuViewProps> = (props: DetailMenuViewProps) => {
  const { node, referencedUsers } = props;
  const title = node.Title;
  const company = node.Company;
  const industry = node.Industry;
  const numberOfEmployees = node.NumberOfEmployees;
  const website = node.Website;
  const phone = node.Phone || node.MobilePhone;
  const leadSource = node.LeadSource;
  const relationship = node.Relationship;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPropertiesList = useCallback(() => {
    const properties = [
      { label: 'Title', value: title },
      { label: 'Company', value: company },
      { label: 'Industry', value: industry },
      { label: 'Number of Employees', value: numberOfEmployees },
      { label: 'Website', value: website },
      { label: 'Phone', value: phone },
      { label: 'Lead Source', value: leadSource },
      { label: 'Relationship', value: relationship },
    ];

    return properties.map((property) => {
      if (!property.value) return null;
      return (
        <Grid key={`lead-panel-detail-${property.label}`} item xs={6}>
          {getLabelAndField(property.label, property.value, referencedUsers)}
        </Grid>
      );
    });
  }, [company, industry, leadSource, numberOfEmployees, phone, referencedUsers, relationship, title, website]);

  const PropertyList = getPropertiesList();

  return (
    <>
      <Grid item display={'flex'} flexWrap={'wrap'}>
        {PropertyList}
      </Grid>
    </>
  );
};

export default DetailMenuView;
