import { InteractionData } from './DataHealthPanel';
import { Box, Divider, LinearProgress, Paper, Typography } from '@mui/material';
import { getHumanReadableDate } from '../../../../../../../../../core/utils/time';
import { getConfidenceScoreColor } from 'core/utils';

type TooltipProps = {
  interactionData: InteractionData | null;
  width: number;
  height: number;
};

export const Tooltip = ({ interactionData, width, height }: TooltipProps) => {
  if (!interactionData) {
    return null;
  }

  return (
    <div
      style={{
        width,
        height,
        zIndex: 1,
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none',
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: interactionData.xPos,
          top: interactionData.yPos,
        }}
      >
        <Paper sx={{ width: 270, padding: 2, backgroundColor: '#0d2045' }}>
          <Typography color={'white'} fontSize={12} fontWeight={600}>
            {interactionData['Name'] ||
              ((interactionData['FirstName'] || interactionData['LastName']) &&
                interactionData['FirstName'] + ' ' + interactionData['LastName'])}
          </Typography>
          <Box display="flex" fontSize={12} color={'#9da1b5'} justifyContent="space-between" pt={2}>
            <Typography fontSize={12}>Id:</Typography>
            <Typography fontSize={12} fontWeight={600} color={'white'}>
              {interactionData.Id}
            </Typography>
          </Box>
          <Box display="flex" fontSize={12} color={'#9da1b5'} justifyContent="space-between" pt={2}>
            <Typography fontSize={12}>Created At:</Typography>
            <Typography fontSize={12} fontWeight={600} color={'white'}>
              {getHumanReadableDate(interactionData.CreatedDate)}
            </Typography>
          </Box>
          <Box display="flex" fontSize={12} color={'#9da1b5'} justifyContent="space-between" pt={2}>
            <Typography fontSize={12}>Last Updated:</Typography>
            <Typography fontSize={12} fontWeight={600} color={'white'}>
              {getHumanReadableDate(interactionData.LastModifiedDate)}
            </Typography>
          </Box>
          <Divider sx={{ backgroundColor: '#9da1b5' }} />
          <Box display="flex" color="#9da1b5" justifyContent="space-between" pt={1} alignItems="center">
            <Typography fontSize={12}>Confidence Score:</Typography>
            <Box position="relative" display="inline-flex" width="80px">
              <LinearProgress
                value={interactionData.confidence_score}
                variant="determinate"
                sx={{
                  borderRadius: '8px',
                  width: '100%',
                  height: '20px',
                  backgroundColor: '#F6F8FB',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: getConfidenceScoreColor(interactionData.confidence_score),
                  },
                }}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  sx={{ fontSize: '10px', color: 'black' }}
                >{`Average ${interactionData.confidence_score}%`}</Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </div>
    </div>
  );
};
