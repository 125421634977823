const ruleName =
  'Name your rule clearly and descriptively. For example, ‘Check for Valid Emails’ or ‘Find Duplicate Contacts.’';

const description =
  'Describe what the rule does and why it’s important. For example, ‘This rule checks that email addresses are valid to prevent bounce-backs.’';

const ruleType = 'Select the type of rule you want to create.';

const riskLevel =
  'Determine the risk level of this rule: Low, Medium, or High. Higher risk levels might require immediate action. For example, use ‘High’ for missing critical fields like email addresses, ‘Medium’ for incorrect formatting, and ‘Low’ for minor inconsistencies. Adding the risk level will also help you accurately filter and prioritize alerts and notifications.';

const department =
  'Specify the responsible department to ensure the right team can address any issues. For example, select ‘Sales’ for lead/contact data rules, or ‘Marketing’ for campaign data rules.';

const violationScore =
  'Assign a score to indicate the severity of a rule violation. Higher scores correspond to more significant issues. For instance, a score of 5 might represent missing required fields, while a score of 1 could denote minor formatting issues.';

const ruleTypeMap: Record<string, string> = {
  'data-validation':
    'Choose this option to ensure Salesforce records are accurate and complete. It helps identify duplicate leads, flag stale opportunities with no recent activity, detect accounts with missing critical fields (such as email or phone number), identify contacts not associated with any account, and flag incomplete addresses in account records.',
  'anomaly-detection':
    'Choose ‘Contact Data Validation’ here to verify and correct email, phone, and address details for your Salesforce contacts. This includes formatting checks, validation of active and deliverable email addresses and phone numbers, identification of temporary or dummy contact info, and accuracy validation for company addresses.',
};

export default { ruleName, description, ruleType, riskLevel, department, violationScore, ruleTypeMap };
