import { FC, useCallback, useMemo, useState } from 'react';

import { Box, Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { SecondaryButton } from 'components/ui';
import { useTemporalTableModalHook } from '../../../TemporalTableModal.hook';

import { useRuleFormStore } from 'store/ruleForm/hook';
import { getTemporalTableCheckNameAvailability } from 'http/dataRaptor/dataRaptorTemporalTables';

export interface TableSelectionFooterProps {
  handleNext: () => void;
  handleClose: () => void;
}

export const TableSelectionFooter: FC<TableSelectionFooterProps> = ({ handleNext, handleClose }) => {
  const {
    data: { table: tableName, alias, selectedMigration },
  } = useTemporalTableModalHook();

  const [loading, setLoading] = useState(false);
  const { temporalTable, setSubQueryFormWhere } = useRuleFormStore();

  const valid = useMemo(() => {
    return alias?.trim().length >= 3 && tableName;
  }, [alias, tableName]);

  const handleSubmit = useCallback(() => {
    setLoading(true);

    const aliasName = alias.trim();
    const isEditTemporalTableEqual = temporalTable?.table === tableName && temporalTable?.name === aliasName;

    const nextStepPromise = isEditTemporalTableEqual
      ? new Promise<void>((resolve) => {
          setSubQueryFormWhere({
            value: temporalTable.definition.where,
          });
          resolve();
        })
      : getTemporalTableCheckNameAvailability({ migrationId: selectedMigration, name: aliasName }).then(
          ({ available }) => {
            if (!available) {
              throw new Error('Table name already exists');
            }
          },
        );

    nextStepPromise
      .then(() => handleNext())
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => setLoading(false));
  }, [alias, handleNext, selectedMigration]);

  return (
    <Box
      display={'flex'}
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        justifyContent: 'space-between',
      }}
    >
      <SecondaryButton variant="text" sx={{ color: 'black', ':hover': { color: 'black' } }} onClick={handleClose}>
        Cancel
      </SecondaryButton>
      <Button variant="contained" disabled={!valid || loading} onClick={handleSubmit}>
        {!loading ? 'Next' : 'Checking Data...'}
      </Button>
    </Box>
  );
};
