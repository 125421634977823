import { FC } from 'react';
import { useDispatch } from 'react-redux';

import theme from 'core/theme';
import { Typography } from '@mui/material';

import { SecondaryButton } from 'components/ui';
import { ReactComponent as BoxIcon } from 'assets/icons/boxBlue.svg';

import { useRuleLibrary } from 'store/ruleLibrary/hook';
import { toggleLibraryListView } from 'store/ruleLibrary/reducers';

const ItemView: FC = () => {
  const dispatch = useDispatch();
  const {
    subPages: { library },
  } = useRuleLibrary();

  const gridView = library.data.gridView;
  const toggleView = () => dispatch(toggleLibraryListView());

  return (
    <>
      <SecondaryButton
        variant="outlined"
        onClick={toggleView}
        sx={{ border: 'none' }}
        startIcon={<BoxIcon />}
        color={gridView ? 'primary' : 'secondary'}
      >
        <Typography variant="labelMedium14" sx={{ color: 'neutral.main' }}>
          {gridView ? 'List' : 'Grid'}
        </Typography>
      </SecondaryButton>
    </>
  );
};

export default ItemView;
