import { RangeSlider } from 'components/RangeSlider';
import { FC, useEffect, useMemo, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useMigration } from 'store/migration/hooks';

export interface RangeSliderMinMaxProps {
  label: string;
  values: number[] | undefined;
  minDistance: number;
  migrationId: string;
  table: string;
  fieldName: string;
  onChange: (valueArray: number[]) => void;
  subLabelPrefix?: string;
  processNumberLabel?: (value: number) => string;
}

export const RangeSliderMinMax: FC<RangeSliderMinMaxProps> = (props) => {
  const { label, values, minDistance, onChange, migrationId, table, fieldName, subLabelPrefix, processNumberLabel } =
    props;
  const {
    getMigrationMinAndMaxFieldValue,
    data: { migrationTableMinMaxFieldValue },
  } = useMigration();
  const [minMax, setMinMax] = useState<number[]>([0, 100]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const key = `${migrationId}-${table}-${fieldName}`;
    if (migrationTableMinMaxFieldValue[key]?.data !== undefined) {
      const minMax = migrationTableMinMaxFieldValue[key].data;
      setMinMax([+minMax.min, +minMax.max]);
      setLoading(false);
    } else {
      getMigrationMinAndMaxFieldValue({
        migrationId,
        tableId: table,
        fieldName,
        onSuccess: (res) => {
          console.log(res);
          setMinMax([+res.min, +res.max]);
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
        },
      });
    }
  }, []);

  const subLabel = useMemo(() => {
    const functionToRun = processNumberLabel || ((value: number) => value);
    const min = values?.[0] || minMax[0];
    const max = values?.[1] || minMax[1];
    return `${subLabelPrefix || ''}${functionToRun(min)} - ${subLabelPrefix || ''}${functionToRun(max)}`;
  }, [minMax, processNumberLabel, subLabelPrefix, values]);

  return (
    <>
      {loading === true ? (
        <Box display={'flex'} justifyContent={'center'}>
          <CircularProgress></CircularProgress>
        </Box>
      ) : (
        <RangeSlider
          label={label}
          min={minMax[0] || 0}
          max={minMax[1] || 100}
          minDistance={minDistance || 1}
          subLabel={subLabel || ''}
          values={values || [minMax[0], minMax[1]]}
          onChange={onChange}
        />
      )}
    </>
  );
};
