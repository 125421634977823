import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { FC, useMemo } from 'react';
import { useGraphRender } from 'store/graphRender/hooks';
import { QueryOptions } from './components/QueryOptions';
import { LeadRecordList } from './components/LeadRecordList';
import theme from 'core/theme';

interface LeadViewPanelProps {
  toggleOpen: (value: boolean) => void;
  migrationId: string;
}

const LeadViewPanel: FC<LeadViewPanelProps> = (props) => {
  const { toggleOpen } = props;

  const {
    data: {
      leadViewState: { initialGroupData },
    },
  } = useGraphRender();

  const count = useMemo(() => {
    return initialGroupData?.reduce((acc, group) => acc + group.count, 0);
  }, [initialGroupData]);

  return (
    <Box
      position={'absolute'}
      sx={{
        width: '30vw',
        minWidth: '425px',
        maxWidth: '800px',
        height: '95%',
        right: 1,
        top: 1,
        marginRight: 3,
        marginTop: 2,
        background: 'white',
        paddingX: 2,
        paddingTop: 2,
        paddingBottom: 1,
        borderRadius: '4px',
      }}
      zIndex={2}
    >
      <Grid container display={'flex'} flexDirection={'column'} height={'92%'} flexWrap={'nowrap'}>
        <Grid container display={'flex'} justifyContent={'space-between'}>
          <Grid item xs={10}>
            <Grid container display={'flex'} flexDirection={'column'} gap={0.4}>
              <Grid item display={'flex'} gap={0.5}>
                <Typography variant="labelRegular12" sx={{ color: theme.palette.primary.main }}>
                  Accounts Universe
                </Typography>
                <Typography variant="labelRegular12">{'>'}</Typography>
                <Typography variant="labelRegular12">Leads</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Box display={'flex'} justifyContent={'end'}>
              <IconButton sx={{ textAlign: 'left', px: 0 }} onClick={() => toggleOpen(false)}>
                <CrossIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Grid container display={'flex'} justifyContent={'space-between'}>
          <Grid item>
            <Grid container display={'flex'} flexDirection={'column'} gap={0.4}>
              <Grid item>
                <Typography variant="h3"> Leads ({count})</Typography>
              </Grid>
            </Grid>
          </Grid>
          <QueryOptions />
        </Grid>
        <Grid
          container
          display={'flex'}
          flexDirection={'column'}
          sx={{ overflowY: 'scroll' }}
          flexWrap={'nowrap'}
          gap={2}
        >
          <LeadRecordList />
        </Grid>
      </Grid>

      <Grid container display={'flex'} height={'8%'} alignItems={'center'} sx={{ padding: '0.5rem' }}>
        <Button variant="outlined" sx={{ width: '100%' }}>
          + New Lead
        </Button>
      </Grid>
    </Box>
  );
};

export default LeadViewPanel;
