import { FC } from 'react';

import { Box } from '@mui/material';
import { LinkList } from 'components/LinkList';

import { ReactComponent as RocketIcon } from 'assets/icons/integration/resource.rocket.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/integration/resource.person.svg';
import { ReactComponent as WebsiteIcon } from 'assets/icons/integration/support.companywebsite.svg';

import { PropertyTitle } from '../../../ui';

const Resources: FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <PropertyTitle>Resources</PropertyTitle>
      <LinkList
        links={[
          {
            caption: 'Setup guide',
            href: '#',
            itemIcon: RocketIcon,
            withLaunchIcon: true,
          },
          {
            caption: 'Support website',
            href: '#',
            itemIcon: WebsiteIcon,
            withLaunchIcon: true,
          },
          {
            caption: 'HubSpot Community',
            href: '#',
            itemIcon: PersonIcon,
            withLaunchIcon: true,
          },
        ]}
      ></LinkList>
    </Box>
  );
};

export default Resources;
