import { FC, useCallback } from 'react';

import { Grid, Typography, Box, Button } from '@mui/material';

import { useUser } from 'store/user/hooks';
import { ReactComponent as AdminBackground } from 'assets/Dashboard/adminBackground.svg';
import { useNavigate } from 'react-router-dom';
import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';

export const HomePage: FC<{ onClickButton: () => void }> = ({ onClickButton }) => {
  const navigate = useNavigate();
  const { user } = useUser();

  const navigateToDataRaptor = useCallback(() => {
    navigate(PRIVATE_ABS_ROUTE_PATHS.dataRaptor);
  }, [navigate]);

  return (
    <Grid container spacing={2} display="flex" alignItems="center" justifyContent="center" style={{ height: '100%' }}>
      <Grid item xs={12} md={4}>
        <Box display="flex" justifyContent="center">
          <AdminBackground />
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="h2" fontSize={24}>
          Hi {user?.userName}, welcome to Customer City
        </Typography>
        <br />
        <Typography variant="h2" fontSize={48} lineHeight={1}>
          Control Your Data Chaos from <br />
          <span style={{ color: '#1554FF' }}>Your New Data Control Command Center</span>
        </Typography>
        <br />
        <Typography variant="p16" fontSize={16} color={'#7F8394'}>
          Customer City empowers your business with clean, enriched, and compliant data. Our AI-driven platform ensures
          data accuracy, continuous enrichment, and robust compliance, giving you reliable insights for smarter
          decision-making.
          <br />
          Start your data optimization journey today!
          <br />
        </Typography>
        <Button sx={{ my: 3 }} variant="contained" onClick={navigateToDataRaptor}>
          Get started Now
        </Button>
      </Grid>
    </Grid>
  );
};

export default HomePage;
