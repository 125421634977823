import React from 'react';
import { Stack, Typography, Grid, Avatar } from '@mui/material';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { parseEmailText } from '../../../../AccountPanel/components/MenuViews/Activity/utils/parseEmailText';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';
import { format } from 'date-fns';

interface IProps {
  nodeData: any;
  migrationId: string;
}
const EmailDetailsContainer = ({ nodeData = {}, migrationId }: IProps) => {
  const { Description, id } = nodeData;

  const emailData = parseEmailText(Description);

  const { data: emailsMessages = [] } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'EmailMessage',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'ActivityId',
          operator: '=',
          value: `'${id}'`,
        },
      ],
    },
    {
      skip: !id,
    },
  );

  return (
    <Grid direction="row" container columns={2} rowGap={2} p={1}>
      <Grid item xs={2}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            From
          </Typography>

          <Typography variant="p14">
            {emailsMessages[0]?.FromName} {`(${emailsMessages[0]?.FromAddress})`}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Date
          </Typography>
          {emailsMessages[0]?.MessageDate && (
            <Typography variant="p14">
              {format(new Date(emailsMessages[0]?.MessageDate), 'MMM dd, hh:mm aa')}
            </Typography>
          )}
        </Stack>
      </Grid>

      <Grid item xs={1}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Recipient
          </Typography>

          <Typography variant="p14"> {emailsMessages[0]?.ToAddress}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Description
          </Typography>

          <Typography variant="p14">{emailsMessages[0]?.TextBody}</Typography>
        </Stack>
      </Grid>
      {emailsMessages[0]?.HasAttachment && (
        <Grid item xs={2}>
          <Stack gap={0.5}>
            <Typography variant="p12" color="neutral.n400">
              Attachments
            </Typography>

            <Stack
              bgcolor="#00000015"
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ maxWidth: '200px' }}
              p={0.5}
            >
              <Stack direction="row" alignItems="center" gap={0.5}>
                <PictureAsPdfOutlinedIcon sx={{ fontSize: '16px' }} />
                <Typography variant="p12">{emailData.attachments}</Typography>
              </Stack>
              <Typography variant="p14" color="neutral.n400">
                2.1 MB
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default EmailDetailsContainer;
