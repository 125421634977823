import React, { useMemo } from 'react';
import { Box, Divider, LinearProgress, Paper, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { getConfidenceScoreColor } from 'core/utils';
import { TableStat, TableStatInterval } from 'http/migration/dto';
import _ from 'lodash';
import { CallMade, CallReceived } from '@mui/icons-material';

export interface Point {
  id: string;
  index: number;
  serieId: string | number;
  serieColor: string;
  x: number;
  y: number;
  color: string;
  borderColor: string;
  data: {
    x: string | number | Date;
    xFormatted: string | number;
    y: string | number | Date;
    yFormatted: string | number;
    yStacked?: number;
    row_count: number | undefined;
    row_affected_count: number | undefined;
  };
}

export type PointPayload = {
  point: Point;
};

export type CustomItemTooltipContentProps = {
  point: PointPayload;
  interval: TableStatInterval;
  prevPoint: TableStat | null;
};

export const CustomItemTooltipContent: React.FC<CustomItemTooltipContentProps> = (props) => {
  const { point, interval, prevPoint } = props;
  const xFormatted = point.point.data.xFormatted;
  const score = point.point.data.y as number;
  const rowCount = point.point.data.row_count;
  const rowAffectedCount = point.point.data.row_affected_count;
  const tableName = point.point.serieId as string;
  const scoreVSPrevPoint = useMemo(() => (prevPoint ? _.round(score - prevPoint.score, 2) : 0), [score, prevPoint]);
  const scoreVSPrevPointIcon = useMemo(() => {
    if (scoreVSPrevPoint > 0) {
      return <CallMade fontSize="small" color="success" />;
    }
    return <CallReceived fontSize="small" color="error" />;
  }, [scoreVSPrevPoint]);
  return (
    <Paper
      sx={{
        width: 270,
        padding: 2,
        backgroundColor: '#0d2045',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        overflow: 'clip',
      }}
    >
      <Typography color={'white'} fontSize={12} fontWeight={600}>
        {tableName} - {xFormatted}
      </Typography>
      <Box display="flex" fontSize={12} color={'#9da1b5'} justifyContent="space-between">
        <Typography fontSize={12}>Max record count:</Typography>
        <Typography fontSize={12} fontWeight={600} color={'white'}>
          {rowCount}
        </Typography>
      </Box>
      <Box display="flex" fontSize={12} color={'#9da1b5'} justifyContent="space-between">
        <Typography fontSize={12}>Max affected record count:</Typography>
        <Typography fontSize={12} fontWeight={600} color={'white'}>
          {rowAffectedCount}
        </Typography>
      </Box>
      <Box display="flex" color="#9da1b5" justifyContent="space-between" alignItems="center">
        <Typography fontSize={12}>Avg. Confidence Score:</Typography>
        <Box position="relative" display="inline-flex" width="80px">
          <LinearProgress
            value={score}
            variant="determinate"
            sx={{
              borderRadius: '8px',
              width: '100%',
              height: '20px',
              backgroundColor: '#F6F8FB',
              '& .MuiLinearProgress-bar': {
                backgroundColor: getConfidenceScoreColor(score),
              },
            }}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography sx={{ fontSize: '10px', color: 'black' }}>{`Average ${score}%`}</Typography>
          </Box>
        </Box>
      </Box>
      {prevPoint && (
        <Box display="flex" fontSize={12} color={'#9da1b5'} justifyContent="space-between">
          <Typography fontSize={12} textTransform={'capitalize'}>
            VS Previous {interval}:
          </Typography>
          <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', justifyContent: 'center' }}>
            {scoreVSPrevPointIcon}
            <Typography fontSize={12} fontWeight={600} color={'white'}>
              {scoreVSPrevPoint}
            </Typography>
          </Box>
        </Box>
      )}
      <Divider sx={{ backgroundColor: '#9da1b5' }} />
      <Box display="flex" alignItems="center" gap={1}>
        <ErrorIcon fontSize="small" sx={{ color: '#9da1b5' }} />
        <Typography color="#9da1b5" fontSize={12}>
          Click an object to view record details
        </Typography>
      </Box>
    </Paper>
  );
};
