import { createAsyncThunk } from '@reduxjs/toolkit';

import { getRecordAnomalies as getRecordAnomaliesApi } from 'http/dataRaptor/dataRaptorDataAnomalies';
import { getRuleByIds as getRuleByIdsApi } from 'http/dataRaptor/dataRaptorRules';
import { GetRecordAnomaliesResponse, getRecordAnomaliesArgs, getRuleByIdsArgs } from './types';
import { DataRaptorRule } from 'store/dataRaptorRule/types';

const GET_RECORD_ANOMALIES = 'dataAnomaly/GET_RECORD_ANOMALIES';
const GET_RULES_BY_IDS = 'dataAnomaly/GET_RULES_BY_IDS';

export const getRecordAnomalies = createAsyncThunk<GetRecordAnomaliesResponse, getRecordAnomaliesArgs>(
  GET_RECORD_ANOMALIES,
  async ({ migrationId, recordId, tableName, onError, onSuccess }: getRecordAnomaliesArgs) => {
    try {
      const res = await getRecordAnomaliesApi(migrationId, tableName, recordId);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const getRuleByIds = createAsyncThunk<DataRaptorRule[], getRuleByIdsArgs>(
  GET_RULES_BY_IDS,
  async ({ migrationId, rulesIds, fields, onError, onSuccess }: getRuleByIdsArgs) => {
    try {
      const res = await getRuleByIdsApi(migrationId, rulesIds, fields);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);
