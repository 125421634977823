import { useSelector } from 'react-redux';
import { useActionCreator } from 'hooks';

import { RootState } from 'store/types';

import {
  setCategory,
  setDepartment,
  setDescription,
  setRiskLevel,
  setRuleName,
  setViolationScore,
  setWhere,
  setFormError,
  resetAll,
  setFormErrorObject,
  setFieldValue,
  setHaving,
  setRuleTemplate,
  setFieldOptions,
  setFieldForeignRefOptions,
  setSubQueries,
  setShowSubQueryModal,
  setSubQueryModalIndex,
  setTableOptions,
  setSubQueriesWhere,
  setSubQueryFormWhere,
  setEditRuleTemporal,
  setRuleLibraryModalMenuIndex,
  setRuleLibraryModalOpen,
  setRuleLibraryModalLoading,
  setRuleLibraryModalLoadingMessage,
  setAiAssistantOpen,
  setAiAssistantTiledWindow,
  setAiAssistantSuggestion,
} from './actions';
import { RuleFormState } from './types';

export const useRuleFormStore = () => {
  const data: RuleFormState = useSelector((state: RootState) => state.ruleForm);

  return {
    ...data,
    setAiAssistantSuggestion: useActionCreator(setAiAssistantSuggestion),
    setAiAssistantOpen: useActionCreator(setAiAssistantOpen),
    setAiAssistantTiledWindow: useActionCreator(setAiAssistantTiledWindow),
    setRuleLibraryModalLoading: useActionCreator(setRuleLibraryModalLoading),
    setRuleLibraryModalLoadingMessage: useActionCreator(setRuleLibraryModalLoadingMessage),
    setRuleLibraryModalMenuIndex: useActionCreator(setRuleLibraryModalMenuIndex),
    setRuleLibraryModalOpen: useActionCreator(setRuleLibraryModalOpen),
    setRuleName: useActionCreator(setRuleName),
    setCategory: useActionCreator(setCategory),
    setDescription: useActionCreator(setDescription),
    setViolationScore: useActionCreator(setViolationScore),
    setRiskLevel: useActionCreator(setRiskLevel),
    setDepartment: useActionCreator(setDepartment),
    setWhere: useActionCreator(setWhere),
    setHaving: useActionCreator(setHaving),
    resetAll: useActionCreator(resetAll),
    setFormError: useActionCreator(setFormError),
    setFormErrorObject: useActionCreator(setFormErrorObject),
    setFieldValue: useActionCreator(setFieldValue),
    setRuleTemplate: useActionCreator(setRuleTemplate),
    setEditRuleTemporal: useActionCreator(setEditRuleTemporal),
    setFieldOptions: useActionCreator(setFieldOptions),
    setFieldForeignRefOptions: useActionCreator(setFieldForeignRefOptions),
    setSubQueries: useActionCreator(setSubQueries),
    setShowSubQueryModal: useActionCreator(setShowSubQueryModal),
    setSubQueryModalIndex: useActionCreator(setSubQueryModalIndex),
    setTableOptions: useActionCreator(setTableOptions),
    setSubQueriesWhere: useActionCreator(setSubQueriesWhere),
    setSubQueryFormWhere: useActionCreator(setSubQueryFormWhere),
  };
};
