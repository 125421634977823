import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  AIPoweredRuleTemplateResponse,
  DeleteRuleTemporalTableParams,
  GetAIPoweredRuleTemplateParams,
  GetAllRulesByMigrationIdParams,
  getAllTemporaryTableByMigrationIdParams,
  GetRuleTemplatesByDataSourceIdParams,
  PostEmbedDDLSchemaParams,
  SubPages,
  UpdateRulesByMigrationIdParams,
} from './types';
import { DataRaptorRule, RuleTemplate, RuleTemporalTable } from 'store/dataRaptorRule/types';
import { getRulesByMigrationId as getRulesByMigrationIdApi } from 'http/dataRaptor/dataRaptorRules';
import { getAIRuleTemplate as getAIRuleTemplateApi, postEmbedDDLSchema as postEmbedDDLSchemaApi } from 'http/ai';
import {
  getRuleTemporalTables as getRuleTemporalTablesApi,
  deleteRuleTemporalTable as deleteRuleTemporalTableApi,
} from 'http/dataRaptor/dataRaptorTemporalTables';
import { getRuleTemplatesByDataSourceId as getRuleTemplatesByDataSourceIdApi } from 'http/dataRaptor/dataRaptorRuleTemplate';

const SET_SELECTED_SUB_PAGE = 'ruleLibrary/SET_SELECTED_SUB_PAGE';
const GET_ALL_RULES_BY_MIGRATION_ID = 'ruleLibrary/GET_ALL_RULES_BY_MIGRATION_ID';
const GET_ALL_TEMPORARY_TABLES_BY_MIGRATION_ID = 'ruleLibrary/GET_ALL_TEMPORARY_TABLES_BY_MIGRATION_ID';
const UPDATE_RULES_BY_MIGRATION_ID = 'ruleLibrary/UPDATE_RULES_BY_MIGRATION_ID';
const GET_RULE_TEMPLATES_BY_DATA_SOURCE_ID = 'ruleLibrary/GET_RULE_TEMPLATED_BY_DATA_SOURCE_ID';
const SET_RENDERED_RULES = 'ruleLibrary/SET_RENDERED_RULES';
const SET_FILTERED_RULES = 'ruleLibrary/SET_FILTERED_RULES';
const SET_RENDERED_TEMPLATES = 'ruleLibrary/SET_RENDERED_TEMPLATES';
const SET_FILTERED_TEMPLATES = 'ruleLibrary/SET_FILTERED_TEMPLATES';
const SET_RENDERED_TEMP_TABLES = 'ruleLibrary/SET_RENDERED_TEMP_TABLES';
const SET_FILTERED_TEMP_TABLES = 'ruleLibrary/SET_FILTERED_TEMP_TABLES';
const SET_TEMPLATE_DEPARTMENT_FILTER_ALLOWED_VALUES = 'ruleLibrary/SET_TEMPLATE_DEPARTMENT_FILTER_ALLOWED_VALUES';
const SET_TEMPLATE_TYPE_FILTER_ALLOWED_VALUES = 'ruleLibrary/SET_TEMPLATE_TYPE_FILTER_ALLOWED_VALUES';
const SET_TEMPLATE_TABLE_FILTER_ALLOWED_VALUES = 'ruleLibrary/SET_TEMPLATE_TABLE_FILTER_ALLOWED_VALUES';
const SET_RULE_TYPE_FILTER_ALLOWED_VALUES = 'ruleLibrary/SET_RULE_TYPE_FILTER_ALLOWED_VALUES';
const SET_RULE_DEPARTMENT_FILTER_ALLOWED_VALUES = 'ruleLibrary/SET_RULE_DEPARTMENT_FILTER_ALLOWED_VALUES';
const SET_RULE_TABLE_FILTER_ALLOWED_VALUES = 'ruleLibrary/SET_RULE_TABLE_FILTER_ALLOWED_VALUES';
const SET_RULE_STATUS_FILTER_ALLOWED_VALUES = 'ruleLibrary/SET_RULE_STATUS_FILTER_ALLOWED_VALUES';
const POST_EMBED_DDL_SCHEMA = 'ruleLibrary/POST_EMBED_DDL_SCHEMA';
const GET_AI_POWERED_RULE_TEMPLATE = 'ruleLibrary/GET_AI_POWERED_RULE_TEMPLATE';
const SET_TEMPLATE_SORT_STRATEGY = 'ruleLibrary/SET_TEMPLATE_SORT_STRATEGY';
const SET_RULE_SORT_STRATEGY = 'ruleLibrary/SET_RULE_SORT_STRATEGY';

const ADD_RULE_TEMPORAL_TABLE = 'ruleLibrary/ADD_RULE_TEMPORAL_TABLE';
export const addRuleTemporalTable = createAction<RuleTemporalTable>(ADD_RULE_TEMPORAL_TABLE);

export const setSelectedSubPage = createAction<keyof SubPages>(SET_SELECTED_SUB_PAGE);

export const setRenderedRule = createAction<DataRaptorRule[]>(SET_RENDERED_RULES);

export const setFilteredRules = createAction<DataRaptorRule[]>(SET_FILTERED_RULES);

export const setRenderedTemplates = createAction<RuleTemplate[]>(SET_RENDERED_TEMPLATES);

export const setFilteredTemplates = createAction<RuleTemplate[]>(SET_FILTERED_TEMPLATES);

export const setRenderedTempTables = createAction<RuleTemporalTable[]>(SET_RENDERED_TEMP_TABLES);

export const setFilteredTempTable = createAction<RuleTemporalTable[]>(SET_FILTERED_TEMP_TABLES);

export const setTemplateDepartmentFilterAllowedValues = createAction<string[]>(
  SET_TEMPLATE_DEPARTMENT_FILTER_ALLOWED_VALUES,
);
export const setRuleTypeFilterAllowedValues = createAction<string[]>(SET_RULE_TYPE_FILTER_ALLOWED_VALUES);

export const setRuleSortStrategy = createAction<string>(SET_RULE_SORT_STRATEGY);

export const setTemplateSortStrategy = createAction<string>(SET_TEMPLATE_SORT_STRATEGY);

export const setTemplateTypeFilterAllowedValues = createAction<string[]>(SET_TEMPLATE_TYPE_FILTER_ALLOWED_VALUES);

export const setRuleDepartmentFilterAllowedValues = createAction<string[]>(SET_RULE_DEPARTMENT_FILTER_ALLOWED_VALUES);

export const setRuleTableFilterAllowedValues = createAction<string[]>(SET_RULE_TABLE_FILTER_ALLOWED_VALUES);

export const setTemplateTableFilterAllowedValues = createAction<string[]>(SET_TEMPLATE_TABLE_FILTER_ALLOWED_VALUES);

export const setRuleStatusFilterAllowedValues = createAction<string[]>(SET_RULE_STATUS_FILTER_ALLOWED_VALUES);

export const getAllRulesByMigrationId = createAsyncThunk<DataRaptorRule[], GetAllRulesByMigrationIdParams>(
  GET_ALL_RULES_BY_MIGRATION_ID,
  async ({ migrationId, onError, onSuccess }: GetAllRulesByMigrationIdParams) => {
    try {
      const res = await getRulesByMigrationIdApi(migrationId);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const postEmbedDDLSchema = createAsyncThunk<void, PostEmbedDDLSchemaParams>(
  POST_EMBED_DDL_SCHEMA,
  async ({ migrationId, onError, onSuccess }: PostEmbedDDLSchemaParams) => {
    try {
      const res = await postEmbedDDLSchemaApi(migrationId);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const getAIPoweredRuleTemplate = createAsyncThunk<AIPoweredRuleTemplateResponse, GetAIPoweredRuleTemplateParams>(
  GET_AI_POWERED_RULE_TEMPLATE,
  async ({ migrationId, templateId, onError, onSuccess }: GetAIPoweredRuleTemplateParams) => {
    try {
      const res = await getAIRuleTemplateApi(migrationId, templateId);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const updateRulesByMigrationId = createAsyncThunk<DataRaptorRule[], UpdateRulesByMigrationIdParams>(
  UPDATE_RULES_BY_MIGRATION_ID,
  async ({ migrationId, onError, onSuccess }: UpdateRulesByMigrationIdParams) => {
    try {
      const res = await getRulesByMigrationIdApi(migrationId);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const getAllTemporaryTableByMigrationId = createAsyncThunk<
  RuleTemporalTable[],
  getAllTemporaryTableByMigrationIdParams
>(
  GET_ALL_TEMPORARY_TABLES_BY_MIGRATION_ID,
  async ({ migrationId, onError, onSuccess }: getAllTemporaryTableByMigrationIdParams) => {
    try {
      const res = await getRuleTemporalTablesApi({ migrationId });
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

const DELETE_RULE_TEMPORAL_TABLE = 'ruleLibrary/DELETE_RULE_TEMPORAL_TABLE';

export const deleteRuleTemporalTable = createAsyncThunk<DeleteRuleTemporalTableParams, DeleteRuleTemporalTableParams>(
  DELETE_RULE_TEMPORAL_TABLE,
  async ({ tableId, migrationId, onError, onSuccess }) => {
    try {
      const res = await deleteRuleTemporalTableApi(migrationId, tableId);
      if (onSuccess) onSuccess(res);
      return { migrationId, tableId };
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const getRuleTemplatesByDataSourceId = createAsyncThunk<RuleTemplate[], GetRuleTemplatesByDataSourceIdParams>(
  GET_RULE_TEMPLATES_BY_DATA_SOURCE_ID,
  async ({ dataSourceId, onError, onSuccess }: GetRuleTemplatesByDataSourceIdParams) => {
    try {
      const res = await getRuleTemplatesByDataSourceIdApi(dataSourceId);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);
