import { Box, Typography } from '@mui/material';
import theme from 'core/theme';
import { FC, useMemo, useState } from 'react';
import { useTemporalTableForm } from 'store/temporalTableForm/hooks';
import { ReactComponent as CheckIcon } from 'assets/icons/checkBlue.svg';
import { useRuleFormStore } from 'store/ruleForm/hook';

export interface MenuItemProps {
  active: boolean;
  index: number;
  label: string;
  currentIndex: number;
}

export const MenuItem: FC<MenuItemProps> = ({ active, index, label, currentIndex }) => {
  const {
    setMenuIndex,
    data: { menuItems },
  } = useTemporalTableForm();

  const {
    subQueryForm: { table },
  } = useRuleFormStore();

  const [hovered, setHovered] = useState(false);

  const stepProcessed = useMemo(() => {
    return index < currentIndex;
  }, [currentIndex, index]);

  const borderColor = useMemo(() => {
    if (active || (hovered && stepProcessed)) return theme.palette.primary.main;
    return theme.palette.grey[300];
  }, [active, hovered, stepProcessed]);

  const indexNumberStyle = useMemo(() => {
    if (active || (hovered && stepProcessed)) {
      return { backgroundColor: theme.palette.primary.main, color: 'white' };
    } else {
      return { backgroundColor: theme.palette.grey[100], color: theme.palette.grey[600] };
    }
  }, [active, hovered, stepProcessed]);

  const mainBoxBorderStyle = useMemo(() => {
    if (active || (hovered && stepProcessed)) {
      return {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
      };
    } else {
      return {};
    }
  }, [active, hovered, stepProcessed]);

  const handleClick = () => {
    if (stepProcessed) {
      setMenuIndex(index);
    }
  };

  const customLabel = useMemo(() => {
    if (menuItems[index] === 'Table' && currentIndex > index) {
      return table || label;
    }
    return label;
  }, [currentIndex, index, label, menuItems, table]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        gap: 1,
        ':hover': stepProcessed ? { cursor: 'pointer' } : {},
      }}
      onClick={handleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box sx={{ border: `2px solid ${borderColor}`, borderRadius: '4px' }}></Box>
      <Box
        flexGrow={1}
        sx={{
          p: 1,
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: 1,
          ...mainBoxBorderStyle,
          backgroundColor: 'white',
        }}
      >
        <Box
          sx={{
            borderRadius: '100%',
            width: '30px',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...indexNumberStyle,
            ...(stepProcessed ? { border: '2px solid', borderColor: 'primary.main' } : {}),
          }}
        >
          {stepProcessed && hovered == false ? (
            <CheckIcon />
          ) : (
            <Typography
              sx={{
                userSelect: 'none',
              }}
            >
              {index + 1}
            </Typography>
          )}
        </Box>
        <Typography
          sx={{
            userSelect: 'none',
            color: stepProcessed || active == true ? theme.palette.neutral.main : theme.palette.neutral.n400,
          }}
        >
          {customLabel}
        </Typography>
      </Box>
    </Box>
  );
};
