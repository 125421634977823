import { Point } from '@nivo/line';
import { createAction } from '@reduxjs/toolkit';
import { TableStatInterval } from 'http/migration/dto';
import { Migration, MigrationTable, TableField } from 'store/migration/types';

const SET_SELECTED_MIGRATION_ID = 'dashboard/SET_SELECTED_MIGRATION_ID';
const SET_SELECTED_MIGRATION = 'dashboard/SET_SELECTED_MIGRATION';
const SET_SELECTED_TABLE = 'dashboard/SET_SELECTED_TABLE';
const SET_SELECTED_TABLE_FIELDS = 'dashboard/SET_SELECTED_TABLE_FIELDS';
const SET_MIGRATION_TABLE_INFO = 'dashboard/SET_MIGRATION_TABLE_INFO';
const SET_QUALITY_TIMELINE_INTERVAL = 'dashboard/SET_QUALITY_TIMELINE_INTERVAL';
const SET_QUALITY_TIMELINE_START_DATE = 'dashboard/SET_QUALITY_TIMELINE_START_DATE';
const SET_QUALITY_TIMELINE_ADDITIONAL_TABLES = 'dashboard/SET_QUALITY_TIMELINE_ADDITIONAL_TABLES';
const SET_QUALITY_TIMELINE_SELECTED_POINT = 'dashboard/SET_QUALITY_TIMELINE_SELECTED_POINT';

export const setSelectedMigrationId = createAction<string>(SET_SELECTED_MIGRATION_ID);
export const setSelectedMigration = createAction<Migration>(SET_SELECTED_MIGRATION);
export const setSelectedTable = createAction<string>(SET_SELECTED_TABLE);
export const setSelectedTableFields = createAction<TableField[]>(SET_SELECTED_TABLE_FIELDS);
export const setMigrationTableInfo = createAction<MigrationTable[]>(SET_MIGRATION_TABLE_INFO);
export const setQualityTimelineInterval = createAction<TableStatInterval>(SET_QUALITY_TIMELINE_START_DATE);
export const setQualityTimelineStartDate = createAction<string>(SET_QUALITY_TIMELINE_INTERVAL);
export const setQualityTimelineAdditionalTables = createAction<string[]>(SET_QUALITY_TIMELINE_ADDITIONAL_TABLES);
export const setQualityTimelineSelectedPoint = createAction<Point | null>(SET_QUALITY_TIMELINE_SELECTED_POINT);
