import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import FilterChips from './components/FilterChips';
import MappingCard from './components/MappingCard';

interface SavedSchemaMappingProps {
  onMappingCardSelect: (selectedCards: { [key: string]: boolean }) => void;
}

const SavedSchemaMapping = ({ onMappingCardSelect }: SavedSchemaMappingProps) => {
  const objects = [
    'Accounts',
    'Contacts',
    'Leads',
    'Opportunities',
    'Call',
    'Cases',
    'Emails',
    'Meetings',
    'Notes',
    'Tasks',
  ];

  const [selectedObjects, setSelectedObjects] = useState<string[]>([]);
  const [selectedCards, setSelectedCards] = useState<{ [key: string]: boolean }>({});
  const [cardsData, setCardsData] = useState([
    {
      name: 'Name of the saved mapping here 1',
      owner: 'Jane Smith',
      date: 'Jan 14, 2023',
      chips: ['Accounts', 'Contacts', 'Leads'],
    },
    {
      name: 'Name of the saved mapping here 2',
      owner: 'Jane Smith',
      date: 'Jan 24, 2023',
      chips: ['Accounts', 'Leads'],
    },
    {
      name: 'Name of the saved mapping here 3',
      owner: 'Jane Smith',
      date: 'Feb 14, 2023',
      chips: ['Accounts'],
    },
    {
      name: 'Name of the saved mapping here 4',
      owner: 'Jane Smith',
      date: 'Jul 14, 2023',
      chips: ['Accounts', 'Contacts', 'Leads', 'Opportunities'],
    },
  ]);

  const handleObjectSelection = (label: string) => {
    setSelectedObjects((prevSelected) =>
      prevSelected.includes(label) ? prevSelected.filter((item) => item !== label) : [...prevSelected, label],
    );
  };

  const handleCardSelection = (cardName: string) => {
    setSelectedCards((prevSelected) => {
      const updatedSelection = { ...prevSelected, [cardName]: !prevSelected[cardName] };
      onMappingCardSelect(updatedSelection);
      return updatedSelection;
    });
  };

  const handleDeleteCard = (cardName: string) => {
    setCardsData((prevData) => prevData.filter((card) => card.name !== cardName));
  };

  const filteredCards = cardsData.filter((card) => selectedObjects.every((object) => card.chips.includes(object)));

  return (
    <Box sx={{ mb: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="h2" mt={4} mb={2} textAlign="center">
        Saved Mapping
      </Typography>
      <Typography variant="labelMedium12" color={'neutral.n400'} mb={2} textAlign="center">
        Filter by object
      </Typography>

      <FilterChips objects={objects} selectedObjects={selectedObjects} onSelect={handleObjectSelection} />

      <Box sx={{ width: '780px', mt: 2, my: 4 }}>
        {filteredCards.map((cardData) => (
          <MappingCard
            key={cardData.name}
            cardData={cardData}
            isSelected={!!selectedCards[cardData.name]}
            onSelect={() => handleCardSelection(cardData.name)}
            onDelete={handleDeleteCard}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SavedSchemaMapping;
