import { IconButton } from '@mui/material';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { FC } from 'react';
import { RuleWhereArray, RuleHavingArray } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';

type DeleteComponentButtonProps = {
  index: number;
  where: RuleWhereArray | RuleHavingArray;
  setWhere: (where: { value: any }) => void;
};

const DeleteComponentButton: FC<DeleteComponentButtonProps> = (props) => {
  const { index, where, setWhere } = props;

  const handleConditionComponentDelete = (index: number) => {
    let indexToDelete = index;
    let countToDelete = 1;
    const whereCopy = JSON.parse(JSON.stringify(where)) as RuleWhereArray | RuleHavingArray;
    if (index > 0) {
      if (whereCopy[index - 1].type === RuleComponentType.LOGICAL_OPERATOR) {
        indexToDelete -= 1;
        countToDelete += 1;
      }
    }
    if (index == 0 && whereCopy.length > 1 && whereCopy[1].type === RuleComponentType.LOGICAL_OPERATOR) {
      countToDelete += 1;
    }
    whereCopy.splice(indexToDelete, countToDelete);
    setWhere({ value: whereCopy });
  };

  {
    return (
      <>
        <IconButton sx={{ width: 'fit-content' }} onClick={handleConditionComponentDelete.bind(this, index)}>
          <CrossIcon />
        </IconButton>
      </>
    );
  }
};

export { DeleteComponentButton };
