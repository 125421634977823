import React from 'react';
import { Button, Menu, MenuItem, Stack, Typography, Checkbox } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

const Filter = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [filtered, setFiltered] = React.useState<string[]>([]);

  const elements = [
    {
      value: 'email',
      label: 'Email',
    },
    {
      value: 'call',
      label: 'Call',
    },
    {
      value: 'meeting',
      label: 'Meeting',
    },
    {
      value: 'sms',
      label: 'SMS',
    },
    {
      value: 'demo',
      label: 'Demo',
    },
    {
      value: 'support',
      label: 'Support ticket',
    },
  ];

  const handleSelect = (key: string) => {
    setFiltered((prev) => {
      if (prev.includes(key)) return prev.filter((entry) => entry !== key);
      return [...prev, key];
    });
  };
  return (
    <>
      <Button onClick={(e) => setAnchorEl(e.currentTarget)}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <FilterListIcon sx={{ color: 'primary.main' }} />
          <Typography variant="p14" color="primary.main">
            Filter
          </Typography>
        </Stack>
      </Button>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        {elements.map((element) => (
          <MenuItem key={`filter_menu_item_${element.value}`}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Checkbox checked={!filtered.includes(element.value)} onChange={() => handleSelect(element.value)} />
              <Typography variant="p12">{element.label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Filter;
