import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DataGrid, GridColumns, GridRowParams } from '@mui/x-data-grid';
import { Box, Tooltip, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ColumnSortedAscendingIcon, ColumnSortedDescendingIcon, ColumnUnsortedIcon } from 'pages/DataRaptor/ui';
import { LoadingComponent } from 'pages/RuleLibrary/ui';
import { RecordStatScoreDiff } from 'http/migration/dto';
import { useGetMigrationTableDataByIdQuery, useLazyGetRecordAnomaliesQuery } from 'store/migration/api';
import { useDashboard } from 'store/dashboard/hook';
import { Show } from '../../../../../../../../../../../../../RuleLibrary/components/show';
import { AnomalySummaryCell } from './AnomalySummaryCell';
import qs from 'qs';
import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';
import theme from 'core/theme';

const supportedTablesForGraphRedirection = ['Account', 'Lead', 'Contact', 'Opportunity'];

export interface DataAnomalyData {
  id: string;
  name: any;
  recordId: string;
  tableName: string;
  summary?: string;
  detail?: string;
  record?: any;
}

export interface DataAnomalyTableProps {
  row: RecordStatScoreDiff;
  tableName: string;
}

const getDataAnomalyDataColumns: (
  width: number,
  options: { supportGraphRedirection: boolean },
) => GridColumns<DataAnomalyData> = (width, options) => {
  const columns: GridColumns<DataAnomalyData> = [
    {
      field: 'name',
      width: options?.supportGraphRedirection ? width * 0.5 : width * 0.6,
      headerName: 'Anomaly Name',
      renderCell: ({ row }) => {
        return (
          <Tooltip title={row.name}>
            <Typography variant="labelRegular12" sx={{ color: theme.palette.primary.main }}>
              {row.name}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'summary',
      width: width * 0.6,
      headerName: 'Anomaly Summary',
      renderCell: AnomalySummaryCell,
    },
  ];
  if (options?.supportGraphRedirection === true) {
    columns.push({
      headerName: '',
      field: 'actions',
      width: width * 0.1,
      disableColumnMenu: true,
      renderCell: () => {
        return (
          <Tooltip title={`View in graph`}>
            <OpenInNewIcon fontSize="small" htmlColor="#1959FF" sx={{ p: 0.2 }} />
          </Tooltip>
        );
      },
    });
  }
  return columns;
};

const pageSize = 6;

export const DataAnomalyTable = ({ row, tableName }: DataAnomalyTableProps) => {
  const ref = useRef<HTMLDivElement>();
  const {
    data: { selectedMigrationId, selectedMigration },
  } = useDashboard();

  const isTableSupportedOnGraph = useMemo(() => {
    return supportedTablesForGraphRedirection.includes(tableName);
  }, [tableName]);

  const [getRecordAnomalyQuery, { isSuccess, isFetching, isLoading, data }] = useLazyGetRecordAnomaliesQuery();
  const [columns, setColumns] = useState(getDataAnomalyDataColumns(0, { supportGraphRedirection: false }));

  const recordData = useGetMigrationTableDataByIdQuery({
    migrationId: selectedMigrationId,
    tableId: tableName,
    skip: 0,
    take: 1,
    conditions: [{ field: 'Id', operator: '=', value: `'${row.record_id}'` }],
  });

  const schemaName = useMemo(() => {
    if (selectedMigration) {
      return `mig_${selectedMigration.tenantId}_${selectedMigration.dataSourceId}`.replace(/-/g, '_');
    }
  }, [selectedMigration]);

  const label = useMemo(() => {
    if (recordData.data && Array.isArray(recordData.data) && recordData.data.length > 0) {
      const record = recordData.data[0];
      const label =
        record['Name'] || `${record['FirstName'] || ''} ${record['LastName'] || ''}`.trim() || record['Email'];
      if (label && label?.trim() !== '') {
        return label;
      }
      return row.record_id;
    }
    return row.record_id;
  }, [recordData.data, row.record_id]);

  const rows: DataAnomalyData[] = useMemo(() => {
    const rulesApplied = recordData.data?.[0].rules_applied || {};
    const rulesIds = Object.keys(rulesApplied);

    return rulesIds.map((ruleId) => {
      const anomaly = data?.anomalies.find((a) => a.rule_id === ruleId);
      const record = anomaly?.record;
      const aiRecommendation = data?.aiRecommendations.find((a) => a.aux_compose_key === ruleId);
      const aiSummary = aiRecommendation?.payload?.summary || '';
      const anomalyDetail = anomaly?.details?.details?.map((d) => d.description).join(', ') || '';
      const ruleDetail = rulesApplied[ruleId];
      const ruleName = ruleDetail?.rule_name || '';
      return {
        id: ruleId,
        name: ruleName || '',
        summary: aiSummary,
        detail: anomalyDetail,
        recordId: row.record_id,
        tableName: tableName,
        record,
      };
    });
  }, [data?.aiRecommendations, data?.anomalies, recordData.data, row.record_id, tableName]);

  useEffect(() => {
    if (row.record_id) {
      getRecordAnomalyQuery({ migrationId: selectedMigrationId, tableName, recordId: row.record_id }, true);
    }
  }, [getRecordAnomalyQuery, row.record_id, selectedMigrationId, tableName]);

  const handleResize = useCallback(() => {
    const width = ref.current?.clientHeight ?? 100;
    setColumns(getDataAnomalyDataColumns(width, { supportGraphRedirection: isTableSupportedOnGraph }));
  }, [isTableSupportedOnGraph]);

  useEffect(() => {
    handleResize();
    ref.current?.addEventListener('resize', handleResize);
    const refTmp = ref.current;
    return () => refTmp?.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const handleGraphRedirection = useCallback(
    (row: GridRowParams<DataAnomalyData>) => {
      if (isTableSupportedOnGraph && schemaName && selectedMigrationId) {
        const openRecordInNewTabParameters = qs.stringify({
          nodeId: `${schemaName}.${row.row.recordId}`,
          migrationId: selectedMigrationId,
          searchTerm: label,
          schemaName: schemaName,
        });
        console.log(openRecordInNewTabParameters);
        open(`${window.location.origin}${PRIVATE_ABS_ROUTE_PATHS.dataGraph}?${openRecordInNewTabParameters}`, '_blank');
        // navigate(`${PRIVATE_ABS_ROUTE_PATHS.dataGraph}?${openRecordInNewTabParameters}`, {target});
      }
    },
    [isTableSupportedOnGraph, label, schemaName, selectedMigrationId],
  );

  return (
    <Box ref={ref} width={'100%'} height={'100%'} display={'flex'} alignContent={'center'}>
      <Show when={isSuccess && !isFetching && !isLoading} fallback={<LoadingComponent />}>
        <DataGrid
          rowHeight={40}
          headerHeight={30}
          disableColumnFilter
          disableColumnSelector
          disableSelectionOnClick
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          getRowId={(r) => r.name}
          rowsPerPageOptions={[pageSize]}
          density="comfortable"
          onRowClick={handleGraphRedirection}
          components={{
            ColumnUnsortedIcon,
            ColumnSortedAscendingIcon,
            ColumnSortedDescendingIcon,
          }}
        />
      </Show>
    </Box>
  );
};
