import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  IconButtonProps,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import DeleteFieldMappingModal from './DeleteFIeldMappingModal';
import SelectFieldMenu from './SelectFieldMenu';
import { styled } from '@mui/material/styles';

interface TableMappingProps {
  tableData: Array<{
    mapped: boolean;
    objectField: string;
    schemaField: string;
    fieldGroup: string;
  }>;
  onDeleteField: (objectField: string) => void;
  onUpdateFieldMapping: (objectField: string, schemaField: string, fieldGroup: string) => void;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ expand, theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
}));

const SchemaFieldMenu = styled('div')<{ selected: boolean }>(({ selected }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '4px 12px',
  borderRadius: '4px',
  border: selected ? '1px solid blue' : '1px solid transparent',
  cursor: 'pointer',
  '&:hover': {
    border: '1px solid blue',
  },
  width: 'fit-content',
}));

const fieldTable = ['Mapped', 'Object Fields', 'Identified Schema Fields', 'Field Group'];

const TableMapping = ({ tableData, onDeleteField, onUpdateFieldMapping }: TableMappingProps) => {
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedField, setSelectedField] = useState<{ objectField: string; schemaField: string } | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleOpenDeleteModal = (objectField: string, schemaField: string) => {
    setSelectedField({ objectField, schemaField });
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedField(null);
  };

  const handleDeleteFieldMapping = () => {
    if (selectedField) {
      onDeleteField(selectedField.objectField);
    }
    handleCloseDeleteModal();
  };

  const handleSelectField = (schemaField: string, fieldGroup: string) => {
    if (selectedRow !== null && selectedField) {
      onUpdateFieldMapping(selectedField.objectField, schemaField, fieldGroup);
    }
    setAnchorEl(null);
  };

  const schemaFieldOptions = [
    {
      groupField: 'Personal Information',
      fields: [
        { name: 'Full Name', type: 'input_text' },
        { name: 'Date Birth', type: 'date' },
      ],
    },
    {
      groupField: 'Contact Information',
      fields: [
        { name: 'Phone Number', type: 'number' },
        { name: 'Email', type: 'input_text' },
      ],
    },
    { groupField: 'Location Information', fields: [{ name: 'Address', type: 'text_area' }] },
  ];

  return (
    <>
      <Table>
        <TableHead sx={{ backgroundColor: '#F6F8FB' }}>
          <TableRow>
            {fieldTable.map((field) => (
              <TableCell key={field}>
                <Typography color="#9DA1B5" fontWeight="bold">
                  {field}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => (
            <TableRow
              key={index}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              sx={{ position: 'relative' }}
            >
              <TableCell>
                {row.mapped ? <CheckCircleIcon sx={{ color: '#60C67C' }} /> : <WarningIcon sx={{ color: '#FF9960' }} />}
              </TableCell>
              <TableCell>{row.objectField}</TableCell>
              <TableCell>
                <SchemaFieldMenu
                  selected={!!anchorEl && selectedRow === index}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget as HTMLElement);
                    setSelectedRow(index);
                    setSelectedField({ objectField: row.objectField, schemaField: row.schemaField });
                  }}
                >
                  <Typography>{row.schemaField}</Typography>
                  <ExpandMoreIcon />
                </SchemaFieldMenu>
              </TableCell>
              <TableCell>{row.fieldGroup}</TableCell>

              {hoveredRow === index && (
                <TableCell align="right" sx={{ position: 'absolute', right: 0, borderBottom: 'none' }}>
                  <IconButton onClick={() => handleOpenDeleteModal(row.objectField, row.schemaField)}>
                    <CloseIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <SelectFieldMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        items={schemaFieldOptions}
        onSelect={(schemaField, fieldGroup) => handleSelectField(schemaField, fieldGroup)}
      />

      {selectedField && (
        <DeleteFieldMappingModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          objectField={selectedField.objectField}
          schemaField={selectedField.schemaField}
          onDelete={handleDeleteFieldMapping}
        />
      )}
    </>
  );
};

export default TableMapping;
