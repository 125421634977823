import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from 'store/types';

const reducerPath = 'backendApi';
const backendApiBaseUrl = process.env.REACT_APP_API_URL;

export default createApi({
  reducerPath,
  baseQuery: fetchBaseQuery({
    baseUrl: backendApiBaseUrl,
    prepareHeaders: (headers, api) => {
      const { auth } = api.getState() as RootState;
      headers.append('Authorization', `Bearer ${auth.accessToken}`);
    },
    responseHandler: async (res) => {
      const data = await res.json();
      console.warn(data?.message, res.url);

      return data?.data ?? data;
    },
  }),
  endpoints: (builder) => ({
    // add global endpoints
  }),
});
