import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { useEffect } from 'react';
import { useDataRaptor } from 'store/dataRaptor/hooks';
import { RuleCategory } from 'store/dataRaptorRule/types';
import { useMigration } from 'store/migration/hooks';
import { useGetRuleDepartmentsQuery, useGetRuleRisksQuery, useGetRuleTypesQuery } from 'store/ruleForm/api';
import { useRuleLibrary } from 'store/ruleLibrary/hook';

export const useRuleFormModalEffect = () => {
  const { selectedTable, setCategory, setTableOptions, ruleValidationPatterns, getRuleValidationPatterns } =
    useRuleForm();

  const { getAllTemporaryTableByMigrationId } = useRuleLibrary();

  const {
    data: { migrationId, migratedTables },
    getMigrationTableFieldsAndLookUpsById,
    getMigrationTableForeignReferencesById,
    getMigrationTablesById,
    getMigrationTableFieldsById,
  } = useMigration();

  const { setSelectedMigration } = useDataRaptor();

  useGetRuleRisksQuery();
  useGetRuleTypesQuery();
  useGetRuleDepartmentsQuery();

  useEffect(() => {
    setCategory(RuleCategory.DataValidation.toString());
  }, [setCategory]);

  useEffect(() => {
    if (migrationId) {
      getMigrationTablesById(migrationId);
      setSelectedMigration(migrationId);
      getAllTemporaryTableByMigrationId({ migrationId });
    }
  }, [getAllTemporaryTableByMigrationId, getMigrationTablesById, migrationId, setSelectedMigration]);

  useEffect(() => {
    const options = migratedTables.map((table) => {
      return { value: table.table_name, label: table.table_name };
    });
    setTableOptions(options);
  }, [migratedTables, setTableOptions]);

  useEffect(() => {
    if (!ruleValidationPatterns || (ruleValidationPatterns && ruleValidationPatterns.length === 0)) {
      getRuleValidationPatterns();
    }
  }, [getRuleValidationPatterns, ruleValidationPatterns]);

  useEffect(() => {
    if (migrationId && selectedTable) {
      getMigrationTableFieldsAndLookUpsById({ migrationId, tableId: selectedTable });
      getMigrationTableForeignReferencesById({ migrationId, tableId: selectedTable });
      getMigrationTableFieldsById({ migrationId, tableId: selectedTable });
    }
  }, [
    getMigrationTableFieldsAndLookUpsById,
    getMigrationTableFieldsById,
    getMigrationTableForeignReferencesById,
    migrationId,
    selectedTable,
  ]);
};
