import { ChartOptions } from 'chart.js';

export const chartOptions = (
  yLabels: string[],
  xLabels: string[],
  getTooltip?: (position: any, index: number, id: string, hide?: boolean) => void,
): ChartOptions<'bubble'> => {
  return {
    plugins: {
      title: {
        text: 'Engagement history',
        align: 'start',
        position: 'top',
        font: {
          size: 12,
        },
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          const { tooltip, chart } = context;
          if (tooltip.opacity === 0) {
            if (getTooltip) {
              getTooltip({ x: 0, y: 0 }, 0, '', true);
            }
            return;
          }
          let left = tooltip.x + 10;
          let top = tooltip.y + 20;
          if (left + 250 > chart.width) {
            left -= left + 250 - chart.width;
          }
          if (top + 120 > chart.height) {
            top -= 140;
          }
          if (getTooltip)
            getTooltip(
              { x: left, y: top },
              tooltip?.dataPoints[0]?.datasetIndex,
              // @ts-expect-error
              tooltip?.dataPoints[0]?.raw?.id || '',
            );
        },
      },
      legend: {
        // display: false,
        align: 'end',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
          font: {
            size: 12,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        labels: xLabels,
        // stacked: true,
        grid: {
          display: false,
        },
        type: 'category',
        ticks: {
          maxRotation: 0,
          callback: function (_: any, index: any) {
            const label = this.getLabelForValue(index);
            return label.split(' ').join('\n');
          },
          font: {
            size: 10,
          },
        },
      },
      y: {
        labels: yLabels,
        grid: {
          display: true,
        },
        type: 'category',
        ticks: {
          callback: function (_: any, index: any) {
            const label = this.getLabelForValue(index);
            return label.length > 6 ? label.slice(0, 5) + '..' : label;
          },
        },
      },
    },
  };
};
