import _ from 'lodash';
import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import {
  setRuleName,
  setDescription,
  setViolationScore,
  setCategory,
  setRiskLevel,
  setDepartment,
  setWhere,
  setFormError,
  resetAll,
  setFormErrorObject,
  setFieldValue,
  setHaving,
  setRuleTemplate,
  setFieldOptions,
  setShowSubQueryModal,
  setFieldForeignRefOptions,
  setSubQueries,
  setSubQueryModalIndex,
  setTableOptions,
  setSubQueriesWhere,
  setSubQueryFormWhere,
  setRuleLibraryModalMenuIndex,
  setRuleLibraryModalOpen,
  setEditRuleTemporal,
  setRuleLibraryModalLoading,
  setRuleLibraryModalLoadingMessage,
  setAiAssistantOpen,
  setAiAssistantTiledWindow,
  setAiAssistantSuggestion,
} from './actions';

import { ConditionType } from 'pages/RuleForm/types';
import { RuleFormModalCreateMenuItem, RuleFormState } from './types';

import { resetApp } from 'store/actions';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';

export const initialState: RuleFormState = {
  aiAssistant: {
    open: false,
    tiledWindow: false,
    suggestions: {},
  },
  ruleFormLibraryModal: {
    open: false,
    menuIndex: 0,
    loading: false,
    loadingMessage: '',
    menuItems: [
      RuleFormModalCreateMenuItem.RecordType,
      RuleFormModalCreateMenuItem.RuleDetails,
      RuleFormModalCreateMenuItem.Dataset,
      RuleFormModalCreateMenuItem.Conditions,
    ],
  },
  subQueries: [],
  subQueryForm: { alias: '', table: '', where: [] },
  ruleName: '',
  violationScore: 0,
  category: '',
  riskLevel: '',
  department: '',
  description: '',
  where: [
    {
      field: { type: RuleComponentType.FIELD_REFERENCE, value: '' },
      operator: '',
      value: { type: RuleComponentType.PRIMITIVE_VALUE, value: '' },
      type: RuleComponentType.ROOT_CONDITIONAL,
    },
  ],
  having: [],
  formError: {
    ruleName: '',
    violationScore: '',
    category: '',
    riskLevel: '',
    department: '',
    description: '',
    where: [],
    error: '',
  },
  showLookUpSelector: false,
  lookUpComponents: [],
  lookUpIndex: undefined,
  lookUpConditionType: ConditionType.WHERE,
  lookUpTargetPath: '',
  fieldOptions: undefined,
  showSubQueryModal: false,
  subQueryModalIndex: 0,
  tableOptions: [],
};

const ruleFormReducer = createSlice({
  name: 'rule-form',
  initialState: _.cloneDeep(initialState),
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<RuleFormState>): void => {
    builder.addCase(setAiAssistantSuggestion, (state, { payload }) => {
      const tmp = state.aiAssistant.suggestions;
      _.merge(tmp, payload);
      state.aiAssistant.suggestions = tmp;
    });
    builder.addCase(setAiAssistantOpen, (state, { payload }) => {
      state.aiAssistant.open = payload;
    });
    builder.addCase(setAiAssistantTiledWindow, (state, { payload }) => {
      state.aiAssistant.tiledWindow = payload;
    });
    builder.addCase(setRuleLibraryModalLoading, (state, { payload }) => {
      state.ruleFormLibraryModal.loading = payload;
    });
    builder.addCase(setRuleLibraryModalLoadingMessage, (state, { payload }) => {
      state.ruleFormLibraryModal.loadingMessage = payload;
    });
    builder.addCase(setEditRuleTemporal, (state, { payload }) => {
      state.temporalTable = payload;
    });
    builder.addCase(setRuleLibraryModalMenuIndex, (state, { payload }) => {
      state.ruleFormLibraryModal.menuIndex = payload;
    });
    builder.addCase(setRuleLibraryModalOpen, (state, { payload }) => {
      state.ruleFormLibraryModal.open = payload;
    });
    builder.addCase(setSubQueryFormWhere, (state, { payload }) => {
      state.subQueryForm.where = payload.value;
    });
    builder.addCase(setRuleName, (state, { payload }) => {
      state.ruleName = payload;
    });
    builder.addCase(setTableOptions, (state, { payload }) => {
      state.tableOptions = payload;
    });
    builder.addCase(setSubQueriesWhere, (state, { payload }) => {
      state.subQueries[state.subQueryModalIndex].where = payload.value;
    });
    builder.addCase(setFieldOptions, (state, { payload }) => {
      state.fieldOptions = payload;
    });
    builder.addCase(setSubQueryModalIndex, (state, { payload }) => {
      state.subQueryModalIndex = payload;
    });
    builder.addCase(setFieldForeignRefOptions, (state, { payload }) => {
      state.fieldForeignRefOptions = payload;
    });
    builder.addCase(setShowSubQueryModal, (state, { payload }) => {
      state.showSubQueryModal = payload;
    });
    builder.addCase(setDescription, (state, { payload }) => {
      state.description = payload;
    });

    builder.addCase(setRuleTemplate, (state, { payload }) => {
      state.ruleTemplate = payload;
    });

    builder.addCase(setViolationScore, (state, { payload }) => {
      state.violationScore = payload;
    });

    builder.addCase(setCategory, (state, { payload }) => {
      state.category = payload;
    });

    builder.addCase(setRiskLevel, (state, { payload }) => {
      state.riskLevel = payload;
    });

    builder.addCase(setDepartment, (state, { payload }) => {
      state.department = payload;
    });

    builder.addCase(setWhere, (state, { payload }) => {
      state.where = payload.value;
    });

    builder.addCase(setSubQueries, (state, { payload }) => {
      state.subQueries = payload.value;
    });

    builder.addCase(setHaving, (state, { payload }) => {
      state.having = payload.value;
    });

    builder.addCase(setFieldValue, (state, { payload }) => {
      const pathComponents = payload.path.split('[');
      let copy: any;
      if (payload.path.slice(0, 12) === 'subQueryForm') {
        copy = { subQueryForm: state.subQueryForm };
      } else if (pathComponents[0] === 'having') {
        copy = { having: state.having };
      } else if (pathComponents[0] === 'subQueries') {
        copy = { subQueries: state.subQueries };
      } else {
        copy = { where: state.where };
      }
      _.set(copy, payload.path, payload.value);
    });

    builder.addCase(setFormError, (state, { payload }) => {
      _.set(state.formError, payload.path, payload.value);
    });

    builder.addCase(setFormErrorObject, (state, { payload }) => {
      state.formError = payload;
    });

    builder.addCase(resetAll, () => {
      return initialState;
    });
    builder.addCase(resetApp, (state) => {
      const initialStateTmp = _.cloneDeep(initialState);
      Object.keys(state).forEach((key) => {
        // @ts-ignore
        state[key] = initialStateTmp[key];
      });
    });
  },
});

export default ruleFormReducer.reducer;
