import React from 'react';
import { Box, Typography, Stack, Divider, Checkbox } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import format from 'date-fns/format';
import ExpandableTypography from 'components/ExpandableTypography';

interface EventTaskCardProps {
  type: 'task' | 'event';
  activity: any;
  node: any;
}
const EventTaskCard = ({ type, activity, node }: EventTaskCardProps) => {
  return (
    <Stack direction="row">
      <Box sx={{ width: '1px', border: '1px solid #00000020', mx: 3, position: 'relative' }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            borderRadius: '100%',
            width: '30px',
            height: '30px',
            bgcolor: type === 'event' ? 'green.main' : 'blue.main',
            position: 'absolute',
            top: -1,
            zIndex: 5,
            transform: 'translateX(-50%)',
          }}
        >
          {type === 'event' ? (
            <VideocamOutlinedIcon sx={{ fontSize: '16px', color: 'white' }} />
          ) : (
            <CheckBoxOutlinedIcon sx={{ fontSize: '16px', color: 'white' }} />
          )}
        </Stack>
      </Box>
      <Stack gap={1} sx={{ border: '1px solid #00000020', backgroundColor: 'white', flex: '1' }} p={1}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap">
          <Stack direction="row" gap={'5px'} alignItems="baseline">
            <ExpandableTypography width={180}>{node.Name}</ExpandableTypography>
            <Typography variant="p12" color="neutral.n400">
              Created {type === 'event' ? ' Event' : ' Task'}
            </Typography>
          </Stack>

          <Typography variant="p12" color="neutral.n400">
            {activity?.ActivityDate ? format(new Date(activity?.ActivityDate), 'dd MMM, hh:mm aa') : null}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            {type === 'task' && <Checkbox />}
            <ExpandableTypography width={240} variant="b16">
              {activity?.Subject}
            </ExpandableTypography>
          </Stack>
          <ChevronRightIcon />
        </Stack>

        <Divider />

        <Stack direction="row" alignItems="center" spacing={2}>
          {type === 'task' && (
            <Stack spacing={0.5}>
              <Typography variant="p14" color="neutral.n400">
                Status
              </Typography>
              <Box sx={{ backgroundColor: 'primary.subtone1', borderRadius: '20px', px: 2, py: '4px' }}>
                <Typography variant="p14" color="neutral.white">
                  {activity?.Status}
                </Typography>
              </Box>
            </Stack>
          )}
          <Stack gap={0.5}>
            <Typography variant="p14" color="neutral.n400">
              Start
            </Typography>
            <Typography variant="p14">
              {activity?.StartDateTime ? format(new Date(activity?.StartDateTime), 'dd MMM, hh:mm aa') : null}
              {type === 'task' && activity?.ActivityDate
                ? format(new Date(activity?.ActivityDate), 'dd MMM, hh:mm aa')
                : null}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EventTaskCard;
