import { Option } from 'store/ruleForm/types';
import { FC, HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldReference, LookUpTable } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { useMigration } from 'store/migration/hooks';
import { TableField, TableLookup } from 'store/migration/types';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { ConditionType } from 'pages/RuleLibrary/types';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { Box, CircularProgress, InputAdornment, Typography } from '@mui/material';
import { VerticalDivider } from 'components/VerticalDivider';
import { ReactComponent as RelationshipIcon } from 'assets/icons/relationship.svg';
import theme from 'core/theme';

type FieldSelectorProps = {
  migrationId: string;
  tableId: string;
  index: number;
  value: string | undefined;
  ignoreRelationshipFields?: boolean;
  handleSelectComponent: (component: LookUpTable | FieldReference, value: string) => void;
};

export const FieldSelector: FC<FieldSelectorProps> = (props) => {
  const { migrationId, tableId, value, handleSelectComponent, index, ignoreRelationshipFields } = props;
  const fieldsKey = `${migrationId}-${tableId}`;
  const {
    getMigrationTableFieldsAndLookUpsById,
    data: { migrationTableFieldsAndLookUps, migrationTableForeignReferences },
  } = useMigration();
  const { lookUpConditionType, fieldForeignRefOptions } = useRuleForm();
  const [fields, setFields] = useState<Option[]>();

  const handleFieldsUpdate = useCallback(() => {
    if (lookUpConditionType === ConditionType.HAVING && index === 0) {
      return setFields(fieldForeignRefOptions);
    }
    if (migrationTableFieldsAndLookUps[fieldsKey]?.data?.fields?.length > 0) {
      const fields: TableField[] = migrationTableFieldsAndLookUps[fieldsKey].data.fields || [];
      const lookUps: TableLookup[] = migrationTableFieldsAndLookUps[fieldsKey].data.lookUps || [];
      const mergeFields = [...fields, ...lookUps].map((item: any) => {
        if (
          (lookUpConditionType === ConditionType.HAVING && index === 1 && item.relationshipName) ||
          (ignoreRelationshipFields && item.relationshipName)
        ) {
          return null;
        }
        const label = item.relationshipName ? `${item.relationshipName}` : item.fieldName;
        const fieldName = item.relationshipName ? item.relationshipName : item.fieldName;
        return { fieldName: fieldName, label, relationshipName: item.relationshipName, xsd_type: item.xsd_type };
      });
      const filteredMergeField = mergeFields
        .filter((x) => x)
        .filter((obj, index, selfArray) => index === selfArray.findIndex((t) => t?.label === obj?.label));
      const arrayCopy = JSON.parse(JSON.stringify(filteredMergeField));
      const sorted = arrayCopy?.sort((a: any, b: any) => a.label.localeCompare(b.label));
      const options: Option[] = sorted?.map((field: any) => {
        return {
          value: field.fieldName,
          label: field.label,
          xsd_type: field.xsd_type,
          relationshipName: field.relationshipName,
        };
      });
      setFields(options);
    }
  }, [
    fieldForeignRefOptions,
    fieldsKey,
    ignoreRelationshipFields,
    index,
    lookUpConditionType,
    migrationTableFieldsAndLookUps,
  ]);

  useEffect(() => {
    if (!migrationTableFieldsAndLookUps[fieldsKey]) {
      getMigrationTableFieldsAndLookUpsById({ migrationId, tableId });
    }
  }, [fieldsKey, getMigrationTableFieldsAndLookUpsById, migrationId, migrationTableFieldsAndLookUps, tableId]);

  useEffect(() => {
    handleFieldsUpdate();
  }, [fieldsKey, handleFieldsUpdate, migrationTableFieldsAndLookUps]);

  const handleComponentSelection = (value: string) => {
    if (!fields) return;
    const option = fields.find((item) => item.value === value);
    if (!option) return;

    if (lookUpConditionType === ConditionType.HAVING && migrationTableForeignReferences[fieldsKey]?.data?.length > 0) {
      const foreignReferences = migrationTableForeignReferences[fieldsKey]?.data ?? [];
      const reference = foreignReferences.find(
        (foreignReference) => value === `${foreignReference.tableId}(${foreignReference.relationshipName})`,
      );
      if (reference) {
        const component: LookUpTable = {
          type: RuleComponentType.LOOKUP_TABLE,
          table: reference.referenceTo,
          relationShipName: reference.relationshipName,
          referenceTable: reference.tableId,
          joinField: 'Id',
          referenceJoinField: reference.fieldName,
        };
        return handleSelectComponent(component, value);
      }
    }

    if (
      lookUpConditionType === ConditionType.WHERE &&
      migrationTableFieldsAndLookUps[fieldsKey]?.data?.fields?.length > 0
    ) {
      const lookUps: TableLookup[] = migrationTableFieldsAndLookUps[fieldsKey].data.lookUps || [];
      if (option.relationshipName) {
        const componentOption = lookUps.find((item) => item.relationshipName === value);
        if (!componentOption) return;
        const component: LookUpTable = {
          type: RuleComponentType.LOOKUP_TABLE,
          table: componentOption.tableId,
          relationShipName: componentOption.relationshipName,
          referenceTable: componentOption.referenceTo,
          joinField: componentOption.fieldName,
          referenceJoinField: 'Id',
        };
        return handleSelectComponent(component, value);
      }
    }

    const component: FieldReference = {
      value: value,
      type: RuleComponentType.FIELD_REFERENCE,
    };
    return handleSelectComponent(component, value);
  };

  const isCurrentValueATableReference = useMemo(() => {
    if (lookUpConditionType === ConditionType.HAVING) {
      return false;
    }
    const option = fields?.find((item) => item.value === value);
    if (option?.relationshipName) {
      return true;
    }
    return false;
  }, [fields, lookUpConditionType, value]);

  const renderOption = useCallback((props: HTMLAttributes<HTMLLIElement>, option: Option) => {
    return (
      <Box component="li" {...props} display={'flex'} gap={1}>
        {option.relationshipName && <RelationshipIcon />}
        {option.label}
      </Box>
    );
  }, []);

  return (
    <>
      <Box display={'flex'} width={'100%'} gap={1} sx={{ paddingLeft: `${index * 10}px` }}>
        {(fields == null || fields.length == 0) && <CircularProgress />}
        {index > 0 && <VerticalDivider color={theme.palette.primary.main} sx={{ height: '2.5em' }} />}
        <Box flex={1}>
          <ControlledCustomDropdown
            value={value || null}
            options={fields || []}
            fallbackOption={{ label: 'Select a Value', value: '' }}
            placeholder={'Select a Value'}
            renderOption={renderOption}
            id={`${migrationId}-${tableId}-${index}-lookup-field-selector`}
            onSelect={handleComponentSelection}
            sx={{ backgroundColor: 'neutral.white' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box display={'flex'} gap={1} alignItems={'center'}>
                    <Typography variant="labelRegular12">{tableId + ':'}</Typography>
                    {isCurrentValueATableReference && <RelationshipIcon />}
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </>
  );
};
