import { ActionPromiseArgs } from 'store/types';
import { DataRaptorRule, RuleTemplate, RuleTemporalTable } from 'store/dataRaptorRule/types';

import { RuleWhereArray } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { FrontEndRuleDto } from 'store/dataRaptorRule/dto/front-end-rule.dto';

export type RuleWhere = RuleWhereArray;

export const RULE_TYPE_FILTER_OPTIONS = [
  { label: 'Data Validation', value: 'data-validation' },
  { label: 'Anomaly Detection', value: 'anomaly-detection' },
];

interface SortOption {
  label: string;
  value: string;
  field: string;
  asc: boolean;
  viewAvailable: string[];
}

interface SortOptionMap {
  [key: string]: SortOption;
}

export const SORT_OPTIONS: SortOptionMap = {
  'newest-first': {
    label: 'Newest first',
    value: 'newest-first',
    field: 'createdAt',
    asc: false,
    viewAvailable: ['library', 'template'],
  },
  'last-updated-first': {
    label: 'Last Updated First',
    value: 'last-updated-first',
    field: 'updatedAt',
    asc: false,
    viewAvailable: ['library', 'template'],
  },
  'active-first': {
    label: 'Active First',
    value: 'active-first',
    field: 'active',
    asc: false,
    viewAvailable: ['library'],
  },
  'inactive-first': {
    label: 'Inactive First',
    value: 'inactive-first',
    field: 'active',
    asc: true,
    viewAvailable: ['library'],
  },
};

export interface SubPageState<T> {
  name: string;
  label: string;

  error: string;
  loading: boolean;
  success: boolean;

  counter: number;
  data: T;
}

export interface LibrarySubPageData {
  gridView: boolean;
  rules: DataRaptorRule[];
  renderedRules: DataRaptorRule[];
  filteredRules: DataRaptorRule[];
  filters: {
    department: RuleLibraryFilter<string>;
    status: RuleLibraryFilter<string>;
    type: RuleLibraryFilter<string>;
    table: RuleLibraryFilter<string>;
  };
  sortStrategy: string;
}

export interface TemplateSubPageData {
  templates: RuleTemplate[];
  filteredTemplates: RuleTemplate[];
  renderedTemplates: RuleTemplate[];
  filters: {
    department: RuleLibraryFilter<string>;
    type: RuleLibraryFilter<string>;
    table: RuleLibraryFilter<string>;
  };
  sortStrategy: string;
}

export interface RuleLibraryFilter<T> {
  allowedValues: T[];
  options: { label: string; value: T }[];
  label: string;
}

export interface TemporaryTablesSubPageData {
  tables: RuleTemporalTable[];
  renderedTables: RuleTemporalTable[];
  filteredTables: RuleTemporalTable[];
}

export interface SubPages {
  library: SubPageState<LibrarySubPageData>;
  template: SubPageState<TemplateSubPageData>;
  temporalTable: SubPageState<TemporaryTablesSubPageData>;
}

export interface ActionState<T> {
  loading: boolean;
  error: string;
  success: boolean;
  data?: T;
}

export interface RuleLibraryState {
  selectedSubPage: keyof SubPages;
  subPages: SubPages;
  action: {
    postEmbedDDLSchema: ActionState<void>;
    getAIPoweredRuleTemplate: ActionState<AIPoweredRuleTemplateResponse>;
  };
}

export interface GetAllRulesByMigrationIdParams extends ActionPromiseArgs<any[]> {
  migrationId: string;
}

export interface UpdateRulesByMigrationIdParams extends ActionPromiseArgs<any[]> {
  migrationId: string;
}

export interface getAllTemporaryTableByMigrationIdParams extends ActionPromiseArgs<any[]> {
  migrationId: string;
}

export interface PostEmbedDDLSchemaParams extends ActionPromiseArgs<void> {
  migrationId: string;
  forceUpdate: boolean;
}

export interface GetAIPoweredRuleTemplateParams extends ActionPromiseArgs<AIPoweredRuleTemplateResponse> {
  migrationId: string;
  templateId: string;
}

export interface GetRuleTemplatesByDataSourceIdParams extends ActionPromiseArgs<RuleTemplate[]> {
  dataSourceId: string;
}

export interface AIPoweredRuleTemplateResponse {
  rule: FrontEndRuleDto;
  table: string;
}

export interface DeleteRuleTemporalTableParams extends ActionPromiseArgs<any> {
  tableId: string;
  migrationId: string;
}

export interface RuleLibraryHook extends RuleLibraryState {
  setSelectedSubPage: (value: keyof SubPages) => void;

  addRuleTemporalTable: (value: RuleTemporalTable) => void;
  postEmbedDDLSchema: (value: PostEmbedDDLSchemaParams) => void;
  deleteRuleTemporaltable: (value: DeleteRuleTemporalTableParams) => void;
  updateRulesByMigrationId: (value: UpdateRulesByMigrationIdParams) => void;
  setFilteredTempTable: (value: RuleTemporalTable[]) => void;
  setRenderedTempTables: (value: RuleTemporalTable[]) => void;

  getAIPoweredRuleTemplate: (value: GetAIPoweredRuleTemplateParams) => void;
  getRuleTemplatesByDataSourceId: (value: GetRuleTemplatesByDataSourceIdParams) => void;

  getAllRulesByMigrationId: (value: GetAllRulesByMigrationIdParams) => void;
  getAllTemporaryTableByMigrationId: (value: getAllTemporaryTableByMigrationIdParams) => void;

  setRenderedRule: (value: DataRaptorRule[]) => void;
  setRenderedTemplates: (value: RuleTemplate[]) => void;

  setFilteredRules: (value: DataRaptorRule[]) => void;
  setFilteredTemplates: (value: RuleTemplate[]) => void;

  setRuleTypeFilterAllowedValues: (value: string[]) => void;
  setRuleStatusFilterAllowedValues: (value: string[]) => void;
  setRuleDepartmentFilterAllowedValues: (value: string[]) => void;

  setTemplateTypeFilterAllowedValues: (value: string[]) => void;
  setTemplateDepartmentFilterAllowedValues: (value: string[]) => void;

  setRuleTableFilterAllowedValues: (value: string[]) => void;
  setTemplateTableFilterAllowedValues: (value: string[]) => void;
  setRuleSortStrategy: (value: string) => void;
  setTemplateSortStrategy: (value: string) => void;
}
