import React from 'react';
import { Box, Chip } from '@mui/material';

interface FilterChipsProps {
  objects: string[];
  selectedObjects: string[];
  onSelect: (label: string) => void;
}

const FilterChips: React.FC<FilterChipsProps> = ({ objects, selectedObjects, onSelect }) => {
  return (
    <Box
      sx={{
        width: '780px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
      }}
    >
      {objects.map((label) => (
        <Chip
          key={label}
          label={label}
          sx={{
            backgroundColor: selectedObjects.includes(label) ? 'gray' : 'white',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
          onClick={() => onSelect(label)}
        />
      ))}
    </Box>
  );
};

export default FilterChips;
