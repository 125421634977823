import { FC } from 'react';

import { Box } from '@mui/material';

import { LinkList } from 'components/LinkList';

import { ReactComponent as PhoneIcon } from 'assets/icons/integration/support.phone.svg';
import { ReactComponent as LinkedInIcon } from 'assets/icons/integration/support.linkedin.svg';
import { ReactComponent as WebsiteIcon } from 'assets/icons/integration/support.companywebsite.svg';

import { PropertyTitle } from '../../../ui';

const Support: FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <PropertyTitle>CustomerCity Support</PropertyTitle>
      <LinkList
        links={[
          {
            caption: 'Company website',
            href: '#',
            itemIcon: WebsiteIcon,
            withLaunchIcon: true,
          },
          {
            caption: 'Linkedin',
            href: '#',
            itemIcon: LinkedInIcon,
            withLaunchIcon: true,
          },
          {
            caption: '+1 (888) 111-2233',
            href: '#',
            itemIcon: PhoneIcon,
            withLaunchIcon: true,
          },
        ]}
      ></LinkList>
    </Box>
  );
};
export default Support;
