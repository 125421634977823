export enum FunctionValueEnum {
  getYear = 'getYear',
  getMonth = 'getMonth',
  getDay = 'getDay',
}

export interface FunctionValue {
  function: FunctionValueEnum;
  field: string;
  label?: string;
}

export interface Condition {
  field: string | FunctionValue;
  operator: string;
  value: any;
}

export interface GroupCountResponse {
  count: number;
  [x: string]: any;
}

export enum TableStatType {
  SCORE = 'score',
}

export enum TableStatInterval {
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export type GetTableStatsParams = {
  migrationId: string;
  tableId: string;
  type: TableStatType;
  interval: TableStatInterval;
  startDate?: Date;
};

export type TableStat = {
  date_start: string;
  score: number;
  row_count: number;
  row_affected_count: number;
};

export type RecordStatScoreDiff = {
  record_id: string;
  score1: number;
  score2: number;
  score_diff: number;
};

export type GetRuleStatParams = {
  migrationId: string;
  tableId: string;
  date: string;
  interval: TableStatInterval;
};

export type RuleStat = {
  rule_id: string;
  avg_affected_row_count: number;
  name: string;
};

export type GetRecordStatsScoreDiffParams = {
  migrationId: string;
  tableId: string;
  type: TableStatType;
  interval: TableStatInterval;
  startDate1: Date;
  startDate2: Date;
};
