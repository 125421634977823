import React from 'react';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';
import { format } from 'date-fns';

interface IProps {
  migrationId: string;
  node: any;
}
const useAccountInsightsData = ({ migrationId, node }: IProps) => {
  const { data: eventsData = [], isFetching: eventsLoading } = useGetMigrationTableDataByIdQuery({
    migrationId: migrationId,
    tableId: 'Event',
    skip: 0,
    take: 0,
    conditions: [{ field: 'AccountId', operator: '=', value: `'${node.id}'` }],
  });

  const { data: tasksData = [], isFetching: tasksLoading } = useGetMigrationTableDataByIdQuery({
    migrationId: migrationId,
    tableId: 'Task',
    skip: 0,
    take: 0,
    conditions: [{ field: 'AccountId', operator: '=', value: `'${node.id}'` }],
  });

  const { data: users = [], isFetching: usersLoading } = useGetMigrationTableDataByIdQuery(
    {
      migrationId: migrationId,
      tableId: 'User',
      skip: 0,
      take: 0,
      conditions: [
        {
          field: 'Id',
          operator: 'IN',
          value: `(${[...eventsData, ...tasksData]
            .filter((e) => !!e.OwnerId)
            .map((e) => `'${e.OwnerId}'`)
            .join(', ')})`,
        },
      ],
    },
    {
      skip: [...eventsData, ...tasksData].length === 0,
    },
  );

  const getEngagementHistoryTooltipInfos = React.useCallback(
    (activityId: string, type: 'email' | 'call' | 'meeting') => {
      if (type === 'email') {
        const email = tasksData.find((task) => task.Id === activityId);
        if (email) {
          const to = node.Name;
          const attendee = users.find((user) => user.Id === email.OwnerId)?.Name;
          const date = email.CreatedDate;
          const type = 'sent';
          const title = email.Subject;
          return {
            title,
            type,
            attendee,
            date,
            to,
          };
        }
      }
      if (type === 'call') {
        const call = tasksData.find((task) => task.Id === activityId);
        if (call) {
          const to = node.Name;
          const attendee = users.find((user) => user.Id === call.OwnerId)?.Name;
          const date = call.CreatedDate;
          const type = 'sent';
          const title = call.Subject;
          return {
            title,
            type,
            attendee,
            date,
            to,
          };
        }
      }

      if (type === 'meeting') {
        const meeting = eventsData.find((event) => event.Id === activityId);
        if (meeting) {
          const to = node.Name;
          const attendee = users.find((user) => user.Id === meeting.OwnerId)?.Name;
          const date = meeting.CreatedDate;
          const type = 'sent';
          const title = meeting.Subject;
          return {
            title,
            type,
            attendee,
            date,
            to,
          };
        }
      }
      return {
        title: '',
        type: '',
        attendee: '',
        date: '',
        to: '',
      };
    },
    [eventsData, tasksData, users],
  );

  const isLoading = eventsLoading || tasksLoading || usersLoading;

  const engagementHistoryChartData = React.useMemo(() => {
    const emails = [...eventsData, ...tasksData].filter(
      (singleActivity) => singleActivity.EventSubtype === 'Email' || singleActivity.TaskSubtype === 'Email',
    );
    const calls = [...eventsData, ...tasksData].filter(
      (singleActivity) => singleActivity.EventSubtype === 'Call' || singleActivity.TaskSubtype === 'Call',
    );
    const meetings = eventsData;

    const emailsData = emails.map((singleActivity) => {
      const contact = users.find((singleContact) => singleContact.Id === singleActivity.OwnerId);
      return {
        x: format(new Date(singleActivity.ActivityDate), 'dd-MM-yyyy'),
        y: contact?.Name,
        r: 8,
        id: singleActivity.Id,
      };
    });
    const callsData = calls.map((singleActivity) => {
      const contact = users.find((singleContact) => singleContact.Id === singleActivity.OwnerId);
      return {
        x: format(new Date(singleActivity.ActivityDate), 'dd-MM-yyyy'),
        y: contact?.Name,
        r: 8,
        id: singleActivity.Id,
      };
    });
    const meetingsData = meetings.map((singleActivity) => {
      const contact = users.find((singleContact) => singleContact.Id === singleActivity.OwnerId);
      return {
        x: format(new Date(singleActivity.ActivityDate), 'dd-MM-yyyy'),
        y: contact?.Name,
        r: 8,
        id: singleActivity.Id,
      };
    });

    const xLabels = [...callsData, ...emailsData, ...meetingsData]
      .map((activity) => activity.x)
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

    return {
      yLabels: users.map((contact) => contact?.Name),
      xLabels,
      meetings: meetingsData,
      calls: callsData,
      emails: emailsData,
    };
  }, [users, tasksData, eventsData]);
  return {
    isLoading,
    engagementHistoryChartData,
    getEngagementHistoryTooltipInfos,
  };
};

export default useAccountInsightsData;
