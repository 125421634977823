import React, { FC, useState } from 'react';
import { Typography, Button, Box, TextField, InputAdornment, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import CustomDialog from 'components/CustomDialog/CustomDialog';
import ShortTextIcon from '@mui/icons-material/ShortText';
import TextareaIcon from '@mui/icons-material/Subject';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NumbersIcon from '@mui/icons-material/Numbers';
import LinkIcon from '@mui/icons-material/Link';

interface CreateCustomFieldModalProps {
  open: boolean;
  groupFields: string[];
  onClose: () => void;
  onCreateField: (newField: { schemaField: string; type: string; fieldGroup: string }) => void;
}

const fieldTypes = [
  { value: 'Short Text', label: 'Short Text Input', icon: <ShortTextIcon sx={{ marginRight: 1 }} /> },
  { value: 'Text Area', label: 'Text Area', icon: <TextareaIcon sx={{ marginRight: 1 }} /> },
  { value: 'Date and Time', label: 'Date and Time', icon: <AccessTimeIcon sx={{ marginRight: 1 }} /> },
  { value: 'Date Range', label: 'Date Range', icon: <DateRangeIcon sx={{ marginRight: 1 }} /> },
  { value: 'Number', label: 'Number', icon: <NumbersIcon sx={{ marginRight: 1 }} /> },
  { value: 'URL', label: 'URL', icon: <LinkIcon sx={{ marginRight: 1 }} /> },
];

const CreateCustomFieldModal: FC<CreateCustomFieldModalProps> = ({ open, groupFields, onClose, onCreateField }) => {
  const [formData, setFormData] = useState({ name: '', type: '', fieldGroup: '' });
  const [errors, setErrors] = useState({ name: false });

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'name' && value.length > 40) {
      setErrors({ name: true });
    } else {
      setErrors({ name: false });
    }
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name as string]: value });
  };

  const handleCreateField = () => {
    if (!errors.name && formData.name.trim() && formData.fieldGroup) {
      onCreateField({ schemaField: formData.name, type: formData.type, fieldGroup: formData.fieldGroup });
      onClose();
    }
  };

  const selectedFieldType = fieldTypes.find((type) => type.value === formData.type);

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title="New Custom Field"
      actions={
        <>
          <Button onClick={onClose} color="inherit">
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: 'primary.main' }}
            onClick={handleCreateField}
            disabled={!formData.name.trim() || !formData.fieldGroup}
          >
            Create Field
          </Button>
        </>
      }
    >
      <Typography color={'neutral.n400'}>
        The created field will be added in the schema fields list and you will be able to use it for mapping.
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography>Field Name</Typography>
          <TextField
            name="name"
            value={formData.name}
            onChange={handleTextFieldChange}
            fullWidth
            error={errors.name}
            helperText={errors.name ? 'Maximum 40 characters allowed' : ''}
            inputProps={{ maxLength: 40 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography variant="caption">{`${formData.name.length}/40`}</Typography>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography>Type</Typography>
          <Select
            name="type"
            value={formData.type}
            onChange={handleSelectChange}
            fullWidth
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return <Typography color="neutral.n400">Select field type</Typography>;
              }
              return (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {selectedFieldType?.icon}
                  <Typography>{selectedFieldType?.label}</Typography>
                </Box>
              );
            }}
          >
            <MenuItem value="" disabled>
              <em>Select field type</em>
            </MenuItem>
            {fieldTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.icon}
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography>Field Group</Typography>
          <Select
            name="fieldGroup"
            value={formData.fieldGroup}
            onChange={handleSelectChange}
            fullWidth
            displayEmpty
            renderValue={(selected) => {
              if (!selected) {
                return <Typography color="neutral.n400">Select Field Group</Typography>;
              }
              return <Typography>{selected}</Typography>;
            }}
          >
            <MenuItem value="" disabled>
              <em>Select Field Group</em>
            </MenuItem>
            {groupFields.map((groupField) => (
              <MenuItem key={groupField} value={groupField}>
                {groupField}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    </CustomDialog>
  );
};

export default CreateCustomFieldModal;
