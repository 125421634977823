import React, { useState } from 'react';

import { Menu, MenuItem, Checkbox, Button, Typography, Box } from '@mui/material';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface FilterTabProps {
  checkedItems: {
    active: boolean;
    archived: boolean;
  };
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FilterTab: React.FC<FilterTabProps> = ({ checkedItems, handleCheckboxChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getSelectedFilterText = () => {
    const { active, archived } = checkedItems;
    if (active && archived) return 'All';
    if (active) return 'Active';
    if (archived) return 'Archived';
    return 'None';
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
      <Typography variant="labelMedium14" sx={{ color: 'neutral.n400' }}>
        Filter:{' '}
      </Typography>
      <Button
        variant="text"
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        aria-controls={anchorEl ? 'checkbox-menu' : undefined}
        sx={{
          color: 'black',
          fontSize: '14px',
          '&:hover': {
            color: 'black',
            boxShadow: 'none',
            backgroundColor: 'initial',
          },
        }}
        onClick={handleClick}
        endIcon={anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {getSelectedFilterText()}
      </Button>
      <Menu id="checkbox-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem>
          <Checkbox checked={checkedItems.active} onChange={handleCheckboxChange} name="active" />
          <Typography variant="body1">Active</Typography>
        </MenuItem>
        <MenuItem>
          <Checkbox checked={checkedItems.archived} onChange={handleCheckboxChange} name="archived" />
          <Typography variant="body1">Archived</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
