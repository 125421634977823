import { Box, Grid, IconButton, Typography } from '@mui/material';
import { ReactComponent as SortIcon } from 'assets/icons/graph/sort.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filterBlue.svg';

import { useCallback, useState } from 'react';
import { SortMenu } from './SortMenu';
import { CategorizeMenu } from './CategorizeMenu';

export const QueryOptions = () => {
  const [categorizeMenuAnchorEl, setCategorizeMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleCategorizeClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setCategorizeMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCategorizeClose = useCallback(() => {
    setCategorizeMenuAnchorEl(null);
  }, []);

  const handleSortClick = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setSortMenuAnchorEl(event.currentTarget);
  }, []);

  const handleSortClose = useCallback(() => {
    setSortMenuAnchorEl(null);
  }, []);

  return (
    <>
      <Grid item display={'flex'}>
        <Box display={'flex'} onClick={handleCategorizeClick}>
          <IconButton>
            <FilterIcon />
            <Typography variant="labelRegular12">Categorize</Typography>
          </IconButton>
        </Box>
        <Box display={'flex'} onClick={handleSortClick}>
          <IconButton>
            <SortIcon />
            <Typography variant="labelRegular12">Sort</Typography>
          </IconButton>
        </Box>
      </Grid>

      {/* Menus */}
      <CategorizeMenu categorizeMenuAnchorEl={categorizeMenuAnchorEl} handleCategorizeClose={handleCategorizeClose} />
      <SortMenu sortMenuAnchorEl={sortMenuAnchorEl} handleSortClose={handleSortClose} />
    </>
  );
};
