import { Point } from '@nivo/line';
import { TableStatInterval } from 'http/migration/dto';
import { Migration, MigrationTable, TableField } from 'store/migration/types';

export const DataQualityColors: string[] = [
  '#0052D2',
  '#8CB9FF',
  '#00CEDB',
  '#9C27B0',
  '#607D8B',
  '#FFEB3B',
  '#FF9800',
  '#8BC34A',
  '#E91E63',
  '#9E9E9E',
  '#CDDC39',
  '#795548',
];

export interface DashboardState {
  data: {
    selectedMigrationId: string;
    selectedMigration?: Migration;
    selectedTable: string;
    selectedTableFields?: TableField[];
    migrationTableInfo: MigrationTable[];
    dataQualityTimeline: {
      interval: TableStatInterval;
      startDate: string;
      additionalTables: string[];
      selectedPoint: Point | null;
    };
  };
}

export interface DashboardStateHook extends DashboardState {
  methods: {
    setSelectedMigrationId: (args: string) => void;
    setSelectedTable: (args: string) => void;
    setMigrationTableInfo: (args: MigrationTable[]) => void;
    setSelectedTableFields: (args: TableField[]) => void;
    setQualityTimelineInterval: (args: TableStatInterval) => void;
    setQualityTimelineStartDate: (args: string) => void;
    setQualityTimelineAdditionalTables: (args: string[]) => void;
    setQualityTimelineSelectedPoint: (args: Point | null) => void;
    setSelectedMigration: (args: Migration) => void;
  };
}
