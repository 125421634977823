import { useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';

import { Show } from 'components/show';
import { useGraphSpace } from 'store/graphSpace/hooks';
import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';

import HomeGraphSpace from './HomeGraphSpace';
import EmptyGraphSpace from './EmptyGraphSpace';

import { SortTab } from './components/SortTab';
import { FilterTab } from './components/FilterTab';

import { Container, PrimaryButton } from './ui';

export default function GraphSpace() {
  const navigate = useNavigate();

  const { data, resetGraphSpace } = useGraphSpace();
  const isEmpty = !data.graphSpaces.length;

  const [checkedItems, setCheckedItems] = useState({
    active: true,
    archived: false,
  });

  const handleCreateGraphSpace = () => {
    resetGraphSpace();
    navigate(PRIVATE_ABS_ROUTE_PATHS.graphSpaceSetUp);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Container>
      <Grid
        container
        spacing={2}
        justifyContent={isEmpty ? 'flex-start' : 'space-between'}
        sx={{ backgroundColor: 'neutral.white', padding: '24px 32px' }}
      >
        <Grid item xs={isEmpty ? 12 : 6}>
          <Grid sx={{ display: 'flex', alignItems: 'baseline' }}>
            <Typography variant="h2" sx={{ color: 'neutral.main', marginRight: 2 }}>
              Graph Space
            </Typography>
            <Typography sx={{ color: 'neutral.n400' }}>Your collaborative teamspace</Typography>
          </Grid>
        </Grid>
        {!isEmpty && (
          <Grid item xs={6}>
            <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FilterTab checkedItems={checkedItems} handleCheckboxChange={handleCheckboxChange} />
              <SortTab />
              <PrimaryButton sx={{ ml: 2 }} onClick={handleCreateGraphSpace}>
                + Create Graph Space
              </PrimaryButton>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Show when={!isEmpty} fallback={<EmptyGraphSpace />}>
        <HomeGraphSpace />
      </Show>
      <Outlet />
    </Container>
  );
}
