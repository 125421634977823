import _ from 'lodash';
import { useCallback } from 'react';
import { MarkerType } from 'reactflow';
import { FormattedEdge, GraphObject } from 'store/graphData/types';
import { useGraphRender } from 'store/graphRender/hooks';
import { BridgeNode, BridgeNodeTypeEnum } from '../../classes/BridgeNode';
import { NodeRecordTypeEnum, RecordNode } from '../../classes/RecordNode';
import { Edge, EdgeType } from '../../classes/Edge';
import { getEventTypeClassification } from '../../utils/EnumerationUtils';
import {
  nodeFilterFn,
  nodePostProcessingFn,
  edgeFilterFn,
  edgePostProcessingFn,
} from '../../utils/ComponentPostProcessing/PostProcessing.Interface';

export const useGraphRenderDataHandlerUtils = () => {
  const {
    setRenderComponents,
    data: { renderNodes, renderEdges },
    addToEdgesHashMap,
    addToNodesHashMap,
  } = useGraphRender();

  const _getNodeTypeByLabel = useCallback((label: string) => {
    switch (label) {
      case 'Account':
        return NodeRecordTypeEnum.AccountNode;
      case 'Event':
        return NodeRecordTypeEnum.EventRecordNode;
      case 'Task':
        return NodeRecordTypeEnum.EventRecordNode;
      default:
        return NodeRecordTypeEnum.RecordNode;
    }
  }, []);

  const _returnNodeObject = useCallback(
    (node: GraphObject, defaultHidden?: boolean) => {
      const nodeProperties = { ...node };
      const label = node.label.split('.')[1];
      const type = _getNodeTypeByLabel(label);
      const draggable = label === 'Account' ? false : true;

      let hidden;
      if (defaultHidden !== undefined) {
        hidden = defaultHidden;
      } else {
        hidden = label === 'Account' ? false : true;
      }
      const nodeTmp = new RecordNode(node.id, node.label, {
        data: nodeProperties,
        properties: { hidden, draggable, type },
      });

      return { data: nodeTmp.data, properties: nodeTmp.properties };
    },
    [_getNodeTypeByLabel],
  );

  const _getFinalNodes = useCallback((nodeHashMap: any) => {
    const finalNodes = Object.keys(nodeHashMap).map((key) => {
      const id = nodeHashMap[key].data.id;
      const data = nodeHashMap[key].data;
      const properties = nodeHashMap[key].properties;
      const labelComponents = data.label.split('.');
      const label = labelComponents[1] ? labelComponents[1] : labelComponents[0];
      data.id = data.id.split('.')[1];
      return {
        id: id,
        data: { ...data, label: label },
        position: { x: 0, y: 0 },
        ...properties,
      };
    });

    const finalNodeHashMap: any = {};
    finalNodes.forEach((node) => (finalNodeHashMap[node.id] = _.cloneDeep(node)));
    return finalNodeHashMap;
  }, []);

  const _getFinalEdges = useCallback((edgesHashMap: any) => {
    const finalEdges = Object.keys(edgesHashMap).map((key) => {
      const edge = edgesHashMap[key];
      return {
        id: edge.data.id,
        type: edge.data.type || 'floating',
        markerEnd: {
          type: MarkerType.Arrow,
        },
        ...edge.properties,
        data: { ...edge.data },
      };
    });

    const finalEdgeHashMap: any = {};
    finalEdges.forEach((edge) => (finalEdgeHashMap[edge.id] = _.cloneDeep(edge)));
    return finalEdgeHashMap;
  }, []);

  const _processPaths = useCallback(
    (paths: GraphObject[][], hideExtendedNodes: boolean | undefined, edgesHashMap: any, nodeHashMap: any) => {
      paths.forEach((path, pathsIndex) => {
        path.forEach((object, objectIndex) => {
          if (object.relationship) {
            if (edgesHashMap[object.id] === undefined) {
              const edge = { ...object };
              const source = paths[pathsIndex][objectIndex - 1].id;
              const target = paths[pathsIndex][objectIndex + 1].id;
              edgesHashMap[object.id] = {
                data: { ...edge },
                properties: { source, target, hidden: hideExtendedNodes === false ? false : true },
              };
            }
          } else {
            const nodePayload = _returnNodeObject(object, hideExtendedNodes === false ? false : undefined);
            nodeHashMap[object.id] = nodePayload;
          }
        });
      });
    },
    [_returnNodeObject],
  );

  const _addEventBridgeNodeAndEdges = useCallback(
    ({ fromId, toId, edge, nodeHashMap, edgesToAdd, bridgeEdges }: any) => {
      const eventNode = nodeHashMap[toId];
      const eventTypeClassification = getEventTypeClassification(eventNode.data.Type || eventNode.data.TaskSubtype);
      const bridgeId = `${fromId}-EventBridge-${eventTypeClassification}`;

      // Create the Bridge Node
      if (nodeHashMap[bridgeId] === undefined) {
        const newBridgeNode = new BridgeNode(bridgeId, bridgeId, {
          data: { connectionsCount: 1 },
          bridgeLabel: eventTypeClassification,
          properties: { hidden: true, draggable: true, type: BridgeNodeTypeEnum.EventBridgeNode },
          metaData: { eventType: eventTypeClassification },
        });
        nodeHashMap[bridgeId] = newBridgeNode;
      } else {
        nodeHashMap[bridgeId].data.connectionsCount += 1;
      }

      // Create the Edge from the Record Node to the Bridge Node
      if (!bridgeEdges.has(`${fromId}-${bridgeId}`)) {
        const newFromEdge = new Edge(`${fromId}-${bridgeId}-edge-bridge`, EdgeType.EventEdge, fromId, bridgeId, {
          hidden: true,
        });
        edgesToAdd.push(newFromEdge);
        bridgeEdges.add(`${fromId}-${bridgeId}`);
      }

      // Create the Edge from the Bridge Node to the Event Node
      if (!bridgeEdges.has(`${bridgeId}-${toId}`)) {
        const newToEdge = new Edge(edge.data.id + '_2', EdgeType.EventEdge, bridgeId, toId, { hidden: true });
        edgesToAdd.push(newToEdge);
        bridgeEdges.add(`${bridgeId}-${toId}`);
      }

      edge.data.type = EdgeType.EventEdge;
      edge.data.metaData = { directConnection: true };
    },
    [],
  );

  const _addMainBridgeNodeAndEdges = useCallback(
    ({
      fromId,
      toId,
      edge,
      nodeHashMap,
      edgesToAdd,
      edgesToDelete,
      bridgeEdges,
      hideExtendedNodes,
      toNodeTable,
    }: any) => {
      const bridgeId = `${fromId}-${toNodeTable}-bridge`;
      const label = `${fromId}-${toNodeTable}-bridge`;
      if (!nodeHashMap[bridgeId]) {
        const newBridgeNode = new BridgeNode(bridgeId, label, {
          data: { connectionsCount: 1 },
          bridgeLabel: toNodeTable,
        });
        nodeHashMap[bridgeId] = newBridgeNode;
      } else {
        nodeHashMap[bridgeId].data.connectionsCount += 1;
      }

      if (!bridgeEdges.has(`${fromId}-${bridgeId}`)) {
        const newFromEdge = {
          data: {
            id: `${fromId}-${bridgeId}-edge-bridge`,
          },
          properties: {
            source: fromId,
            target: bridgeId,
            hidden: false,
          },
        };
        edgesToAdd.push(newFromEdge);
        bridgeEdges.add(`${fromId}-${bridgeId}`);
      }

      if (!bridgeEdges.has(`${bridgeId}-${toId}`)) {
        const newToEdge = {
          data: {
            id: edge.data.id + '_2',
          },
          properties: {
            source: bridgeId,
            target: toId,
            hidden: hideExtendedNodes === false ? false : true,
          },
        };
        edgesToAdd.push(newToEdge);
        bridgeEdges.add(`${bridgeId}-${toId}`);
      }
      edgesToDelete.push(edge.data.id);
    },
    [],
  );

  const _processEdges = useCallback(
    (edgesHashMap: any, nodeHashMap: any, hideExtendedNodes: boolean | undefined) => {
      const edgesToDelete: any[] = [];
      const edgesToAdd: any[] = [];
      const bridgeEdges = new Set<string>();

      Object.keys(edgesHashMap).forEach((key) => {
        const edge = edgesHashMap[key];
        const relationship = edge.data.relationship;
        let fromId = edge.properties.source;
        let toId = edge.properties.target;
        let fromNode = nodeHashMap[fromId];
        let toNode = nodeHashMap[toId];
        let toNodeTable = toNode.data.label.split('.')[1];
        let fromNodeTable = fromNode.data.label.split('.')[1];
        if (
          (fromNodeTable === 'Account' || toNodeTable === 'Account') &&
          relationship &&
          toNodeTable &&
          fromNodeTable
        ) {
          if (toNodeTable === 'Account') {
            //switch up let values
            fromId = edge.properties.target;
            toId = edge.properties.source;
            fromNode = nodeHashMap[fromId];
            toNode = nodeHashMap[toId];
            toNodeTable = toNode.data.label.split('.')[1];
            fromNodeTable = fromNode.data.label.split('.')[1];
          }
          _addMainBridgeNodeAndEdges({
            fromId,
            toId,
            edge,
            nodeHashMap,
            edgesToAdd,
            edgesToDelete,
            bridgeEdges,
            hideExtendedNodes,
            fromNodeTable,
            toNodeTable,
          });
        }
        if (
          (((fromNodeTable === 'Event' || fromNodeTable === 'Task') && toNodeTable !== 'Account') ||
            (fromNodeTable !== 'Account' && (toNodeTable === 'Event' || toNodeTable === 'Task'))) &&
          relationship &&
          toNodeTable &&
          fromNodeTable
        ) {
          if (toNodeTable !== 'Event' && toNodeTable !== 'Task') {
            //switch up let values
            fromId = edge.properties.target;
            toId = edge.properties.source;
            fromNode = nodeHashMap[fromId];
            toNode = nodeHashMap[toId];
            toNodeTable = toNode.data.label.split('.')[1];
            fromNodeTable = fromNode.data.label.split('.')[1];
          }
          _addEventBridgeNodeAndEdges({
            fromId,
            toId,
            edge,
            nodeHashMap,
            edgesHashMap,
            edgesToAdd,
            edgesToDelete,
            bridgeEdges,
            hideExtendedNodes,
            fromNodeTable,
            toNodeTable,
          });
        }
      });

      edgesToAdd.forEach((edge: any) => {
        edgesHashMap[edge.data.id] = edge;
      });

      edgesToDelete.forEach((id) => {
        delete edgesHashMap[id];
      });
    },
    [_addEventBridgeNodeAndEdges, _addMainBridgeNodeAndEdges],
  );

  const addComponentsToGraph = useCallback(
    (
      nodes: GraphObject[] = [],
      edges: FormattedEdge[] = [],
      bridgeNodes: BridgeNode[] = [],
      formattedNodes: any[] = [],
    ) => {
      const nodeHashMap: any = _.cloneDeep(renderNodes);
      const edgesHashMap: any = _.cloneDeep(renderEdges);
      const newNodes: any = {};
      const newEdges: any = {};

      nodes.forEach((node) => {
        if (nodeHashMap[node.id] === undefined) {
          const nodePayload = _returnNodeObject(node, false);
          newNodes[node.id] = nodePayload;
        }
      });

      formattedNodes.forEach((node) => {
        if (nodeHashMap[node.data.id] === undefined) {
          newNodes[node.data.id] = node;
        }
      });

      edges?.forEach((edge) => {
        if (edgesHashMap[edge.data.id] === undefined) {
          newEdges[edge.data.id] = edge;
        }
      });

      bridgeNodes.forEach((node) => {
        if (nodeHashMap[node.data.id] === undefined) {
          newNodes[node.data.id] = node;
        }
      });

      let finalNodeHashMap;
      let finalEdgeHashMap;
      if (Object.keys(newNodes).length > 0) {
        const newNodeHashMap = _getFinalNodes(newNodes);
        finalNodeHashMap = { ...renderNodes, ...newNodeHashMap };
      }
      if (Object.keys(newEdges).length > 0) {
        const newEdgesHashMap = _getFinalEdges(newEdges);
        finalEdgeHashMap = { ...renderEdges, ...newEdgesHashMap };
      }

      setRenderComponents({
        nodes: finalNodeHashMap,
        edges: finalEdgeHashMap,
      });
    },
    [_getFinalEdges, _getFinalNodes, _returnNodeObject, renderEdges, renderNodes, setRenderComponents],
  );

  const handleGraphComponentsLoad = useCallback(
    (
      paths: GraphObject[][],
      accounts: GraphObject[],
      individualNodes: GraphObject[],
      options: {
        formattedAdditionalEdges?: FormattedEdge[];
        formattedAdditionalNode?: any[];
        hideExtendedNodes?: boolean;
        nodePostProcessing?: {
          filterFn: nodeFilterFn;
          postProcessingFn: nodePostProcessingFn;
        };
        edgePostProcessing?: {
          filterFn: edgeFilterFn;
          postProcessingFn: edgePostProcessingFn;
        };
      } = {},
    ) => {
      const {
        formattedAdditionalEdges,
        hideExtendedNodes,
        formattedAdditionalNode,
        nodePostProcessing,
        edgePostProcessing,
      } = options;
      const nodeHashMap: any = {};
      const edgesHashMap: any = {};
      const orphanNodes = [...accounts, ...individualNodes];

      orphanNodes.forEach((node) => {
        if (nodeHashMap[node.id] === undefined) {
          const nodePayload = _returnNodeObject(node, false);
          nodeHashMap[node.id] = nodePayload;
        }
      });

      _processPaths(paths, hideExtendedNodes, edgesHashMap, nodeHashMap);

      formattedAdditionalEdges?.forEach((edge) => {
        edgesHashMap[edge.data.id] = edge;
      });

      formattedAdditionalNode?.forEach((node) => {
        nodeHashMap[node.data.id] = node;
      });

      _processEdges(edgesHashMap, nodeHashMap, hideExtendedNodes);
      const finalNodeHashMap = _getFinalNodes(nodeHashMap);
      const finalEdgeHashMap = _getFinalEdges(edgesHashMap);

      if (nodePostProcessing) {
        Object.keys(finalNodeHashMap).forEach((id) => {
          if (nodePostProcessing.filterFn(finalNodeHashMap[id], id, finalNodeHashMap)) {
            nodePostProcessing.postProcessingFn(finalNodeHashMap[id], id, finalNodeHashMap);
          }
        });
      }

      if (edgePostProcessing) {
        Object.keys(finalEdgeHashMap).forEach((id) => {
          if (edgePostProcessing.filterFn(finalEdgeHashMap[id], id, finalEdgeHashMap, finalNodeHashMap)) {
            edgePostProcessing.postProcessingFn(finalEdgeHashMap[id], id, finalEdgeHashMap, finalNodeHashMap);
          }
        });
      }

      setRenderComponents({
        nodes: finalNodeHashMap,
        edges: finalEdgeHashMap,
      });
      return { nodes: finalNodeHashMap, edges: finalEdgeHashMap };
    },
    [_getFinalEdges, _getFinalNodes, _processEdges, _processPaths, _returnNodeObject, setRenderComponents],
  );

  const addComponentsToHashMap = useCallback(
    (nodes: any = {}, edges: any = {}) => {
      const finalNodeHashMap = _getFinalNodes(nodes);
      const finalEdgeHashMap = _getFinalEdges(edges);
      addToNodesHashMap(finalNodeHashMap);
      addToEdgesHashMap(finalEdgeHashMap);
    },
    [_getFinalEdges, _getFinalNodes, addToEdgesHashMap, addToNodesHashMap],
  );

  return {
    handleGraphComponentsLoad,
    addComponentsToGraph,
    addComponentsToHashMap,
  };
};
