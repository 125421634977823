import { JSXElementConstructor, HTMLAttributes, ReactNode } from 'react';
import { Autocomplete, TextField, InputLabel, SxProps, Theme, InputProps } from '@mui/material';

import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { ReactComponent as TraingleDownIcon } from 'assets/icons/triangleDown.svg';
import { Paper } from './ui';

type OptionValue = string | number;

interface Option<T extends OptionValue> {
  label: string;
  value: T;
}

interface ControlledCustomDropdownProps<T extends OptionValue> {
  value: T | null;
  options: Option<T>[];
  fallbackOption: Option<T>;
  placeholder: string;
  id: string;
  label?: string;
  labelSx?: SxProps<Theme>;
  listBoxProps?: React.CSSProperties;
  sx?: SxProps<Theme>;
  withPopupIcon?: boolean;
  InputProps?: Partial<InputProps>;
  renderOption?: (props: HTMLAttributes<HTMLLIElement>, option: Option<T>, ...other: any) => ReactNode | undefined;
  labelComponent?: ReactNode;
  onSelect: (value: T) => void;
  PaperComponent?: JSXElementConstructor<HTMLAttributes<HTMLElement>>;
  disableClearable?: boolean;
  disabled?: boolean;
}

const ControlledCustomDropdown = <T extends OptionValue>({
  value,
  options,
  placeholder,
  id,
  label,
  labelSx = {},
  sx = {},
  withPopupIcon = true,
  onSelect,
  InputProps,
  PaperComponent,
  disableClearable = true,
  disabled = false,
  fallbackOption,
  renderOption,
  labelComponent,
  listBoxProps = {},
}: ControlledCustomDropdownProps<T>): JSX.Element => {
  let selectedOption = options.find((options) => options?.value === value);
  let calculatedOptions = options;
  if (!selectedOption) {
    selectedOption = fallbackOption;
    calculatedOptions = [...options, fallbackOption];
  }
  return (
    <div>
      {label && (
        <InputLabel htmlFor={id} sx={{ marginBottom: 1, ...labelSx }}>
          {label}
        </InputLabel>
      )}

      {!!labelComponent && labelComponent}

      <Autocomplete
        disablePortal
        id={id}
        sx={{ ...sx }}
        options={calculatedOptions}
        disabled={disabled}
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder={placeholder}
            InputProps={{ ...params.InputProps, ...InputProps }}
          />
        )}
        disableClearable={disableClearable}
        PaperComponent={PaperComponent || Paper}
        ListboxProps={{ style: { maxHeight: 300, ...listBoxProps } }}
        value={selectedOption}
        onChange={(e, value) => onSelect((value?.value ?? '') as T)}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        popupIcon={withPopupIcon ? <TraingleDownIcon /> : null}
        clearIcon={<CrossIcon />}
        data-testid={id}
      />
    </div>
  );
};

export default ControlledCustomDropdown;
