export const data = [
  {
    id: 10,
    icons: ['https://cdn-icons-png.flaticon.com/128/5968/5968914.png'],
    statusIcon: 'good',
    image: 'https://i.ibb.co/hRNGtBJ/Screenshot-2024-10-04-120623.png',
    invitedMembers: [
      {
        name: 'John Doe',
        photo:
          'https://cdn.discordapp.com/attachments/1257774543884259331/1291835338905489550/image.png?ex=67018ae9&is=67003969&hm=b17a8e2d5dfb3376face7f66ef7535d4521468bf4cd8327c133d59e287fb4d5d&',
        isOnline: true,
      },
      {
        name: 'Carly Rae',
        photo:
          'https://cdn.discordapp.com/attachments/1257774543884259331/1291835398586368133/image.png?ex=67018af8&is=67003978&hm=7efeb311d3997c471c10133d30ef9c1d67ad2b38fcbbea52b78f6b76ce980ac1&',
        isOnline: true,
      },
      {
        name: 'Jane Smith',
        photo:
          'https://cdn.discordapp.com/attachments/1257774543884259331/1291835434460119060/image.png?ex=67018b00&is=67003980&hm=8273826f4c551295a8ecf2bf2e5781656eeb17834662aac128fa170d94a142c0&',
        isOnline: false,
      },
    ],
    projectName: 'Deals at risk',
    createdBy: 'Mike Flemming',
    description: 'All deals that are at risk',
    lastModified: '1 minute ago',
    modifiedBy: 'Amanda Lee',
    isArchived: false,
  },
  {
    id: 11,
    icons: [
      'https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png',
      'https://logowik.com/content/uploads/images/hubspot5453.logowik.com.webp',
    ],
    statusIcon: 'warning',
    image: 'https://i.ibb.co/4jQ56Tx/Screenshot-2024-10-04-143818.png',
    invitedMembers: [
      {
        name: 'Alice Brown',
        photo:
          'https://cdn.discordapp.com/attachments/1257774543884259331/1291835545034424480/image.png?ex=67018b1b&is=6700399b&hm=c2bea613b86fb26925b88811db85086f5d2d62e7827bd4fd2041e2a0a08d445e&',
        isOnline: true,
      },
      {
        name: 'Bob White',
        photo:
          'https://cdn.discordapp.com/attachments/1257774543884259331/1291835584452497479/image.png?ex=67018b24&is=670039a4&hm=9e326bb937d1d1cca59a1cd73f6aefe709a361c3def0da97d42be13edc3ebb11&',
        isOnline: false,
      },
      {
        name: 'Bob White',
        photo:
          'https://cdn.discordapp.com/attachments/1257774543884259331/1291835398586368133/image.png?ex=67018af8&is=67003978&hm=7efeb311d3997c471c10133d30ef9c1d67ad2b38fcbbea52b78f6b76ce980ac1&',
        isOnline: false,
      },
      {
        name: 'Bob White',
        photo:
          'https://cdn.discordapp.com/attachments/1257774543884259331/1291835338905489550/image.png?ex=67018ae9&is=67003969&hm=b17a8e2d5dfb3376face7f66ef7535d4521468bf4cd8327c133d59e287fb4d5d&',
        isOnline: false,
      },
    ],
    projectName: 'Project Alpha',
    createdBy: 'Alice Brown',
    description: 'This project is about Alpha phase.',
    lastModified: '5 minutes ago',
    modifiedBy: 'Bob White',
    isArchived: false,
  },
  {
    id: 12,
    icons: [
      'https://cdn.iconscout.com/icon/free/png-256/free-marketo-logo-icon-download-in-svg-png-gif-file-formats--technology-social-media-vol-1-pack-logos-icons-3030046.png?f=webp&w=256',
    ],
    statusIcon: 'bad',
    image: 'https://i.ibb.co/FH6zv9z/Screenshot-2024-10-04-143721.png',
    invitedMembers: [
      {
        name: 'Alice Brown',
        photo:
          'https://cdn.discordapp.com/attachments/1257774543884259331/1291835545034424480/image.png?ex=67018b1b&is=6700399b&hm=c2bea613b86fb26925b88811db85086f5d2d62e7827bd4fd2041e2a0a08d445e&',
        isOnline: true,
      },
      {
        name: 'Bob White',
        photo:
          'https://cdn.discordapp.com/attachments/1257774543884259331/1291835584452497479/image.png?ex=67018b24&is=670039a4&hm=9e326bb937d1d1cca59a1cd73f6aefe709a361c3def0da97d42be13edc3ebb11&',
        isOnline: false,
      },
    ],
    projectName: 'Marketing Campaign',
    createdBy: 'Eve Davis',
    description: 'Important marketing efforts for Q4.',
    lastModified: '10 minutes ago',
    modifiedBy: 'Eve Davis',
    isArchived: false,
  },
  // {
  //     id: 13,
  //     icons: [
  //         'https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png',
  //         'https://logowik.com/content/uploads/images/hubspot5453.logowik.com.webp',
  //         'https://cdn-icons-png.flaticon.com/128/5968/5968914.png',
  //     ],
  //     statusIcon: 'good',
  //     image: 'https://i.ibb.co/6r9TzqB/Screenshot-2024-10-04-120930.png',
  //     invitedMembers: [
  //         {
  //             name: 'Bob White',
  //             photo:
  //                 'https://cdn.discordapp.com/attachments/1257774543884259331/1291835584452497479/image.png?ex=67018b24&is=670039a4&hm=9e326bb937d1d1cca59a1cd73f6aefe709a361c3def0da97d42be13edc3ebb11&',
  //             isOnline: false,
  //         },
  //     ],
  //     projectName: 'Website Redesign',
  //     createdBy: 'Charlie Johnson',
  //     description: 'Redesigning the main company website.',
  //     lastModified: '15 minutes ago',
  //     modifiedBy: 'Dana Lee',
  //     isArchived: false,
  // },
  {
    id: 14,
    icons: [],
    statusIcon: 'good',
    image: 'https://i.ibb.co/hRNGtBJ/Screenshot-2024-10-04-120623.png',
    invitedMembers: [],
    projectName: 'Website Redesign',
    createdBy: 'Charlie Johnson',
    description: 'Redesigning the main company website.',
    lastModified: '15 minutes ago',
    modifiedBy: 'Dana Lee',
    isArchived: true,
  },
];
