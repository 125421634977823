import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { RootCondition, FieldReference, LookUpTable } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { RuleCategory } from '../../store/dataRaptorRule/types';
import { format } from 'path';

export const getLastLookUp = (lookUpComponents: (FieldReference | LookUpTable)[]) => {
  const nonEmptyLookup = lookUpComponents?.filter((lookup) => Object.keys(lookup).length > 0);
  const isLookUp =
    nonEmptyLookup[nonEmptyLookup.length - 1] &&
    nonEmptyLookup[nonEmptyLookup.length - 1].type === RuleComponentType.LOOKUP_TABLE;
  if (isLookUp) {
    return nonEmptyLookup[nonEmptyLookup.length - 1] as LookUpTable;
  }
  return null;
};

export const getEmptyRootConditional = (ruleCategory: RuleCategory): RootCondition => {
  if (ruleCategory === RuleCategory.DataValidation) {
    return getDataValidationEmptyRootConditional();
  }
  return getAnomalyEmptyRootConditional();
};

export const getDataValidationEmptyRootConditional = (): RootCondition => {
  return {
    field: { type: RuleComponentType.FIELD_REFERENCE, value: '' },
    operator: '',
    value: { type: RuleComponentType.PRIMITIVE_VALUE, value: '' },
    type: RuleComponentType.ROOT_CONDITIONAL,
  };
};

export const getAnomalyEmptyRootConditional = (): RootCondition => {
  return {
    field: { type: RuleComponentType.REGEX, value: '' },
    operator: '===',
    value: { type: RuleComponentType.PRIMITIVE_VALUE, value: 'false', format: 'boolean' },
    type: RuleComponentType.ROOT_CONDITIONAL,
  };
};
