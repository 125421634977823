import { Box, Typography } from '@mui/material';
import ExpandableSection from './components/ExpandableSection';
import ErrorIcon from '@mui/icons-material/Error';

const dataReviews = [
  {
    name: 'Contacts',
    fields: [
      { name: 'Total records', value: 124 },
      { name: 'Mapped fields', value: 122 },
      { name: 'Unmapped fields', value: 2, icon: <ErrorIcon sx={{ fontSize: '16px', color: 'neutral.n300' }} /> },
    ],
  },
  {
    name: 'Leads',
    fields: [
      { name: 'Total records', value: 76 },
      { name: 'Mapped fields', value: 24 },
      { name: 'Unmapped fields', value: 52, icon: <ErrorIcon sx={{ fontSize: '16px', color: 'neutral.n300' }} /> },
    ],
  },
];

const ReviewData = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h3" color="neutral.main" my={4}>
        Review Data Integration
      </Typography>
      <Box
        sx={{
          width: '700px',
          backgroundColor: 'neutral.white',
          p: 4,
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {dataReviews.map((review) => (
          <ExpandableSection key={review.name} title={review.name} fields={review.fields} />
        ))}
      </Box>
    </Box>
  );
};

export default ReviewData;
