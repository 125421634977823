import { FC, useEffect, useState } from 'react';
import { AccountPanel } from './AccountPanel';
import { GraphObject } from 'store/graphData/types';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { ACCOUNT_ID_FIELDS } from './constants';
import { useGraphData } from 'store/graphData/hooks';
import { useGraphRender } from 'store/graphRender/hooks';
import _ from 'lodash';
import { GraphRenderView } from 'store/graphRender/types';
import { LeadPanel } from './LeadPanel';
import { ActivityPanel } from './ActivityPanel';

interface NodePanelProps {
  node: any;
  toggleOpen: (value: boolean) => void;
  open: boolean;
  migrationId: string;
}

export const NodePanel: FC<NodePanelProps> = (props) => {
  const [referencedUsers, setReferencesUsers] = useState<any>();
  const [nodeDataCache, setNodeDataCache] = useState<{ [x: string]: GraphObject }>({});
  const [nodeData, setNodeData] = useState<GraphObject>();
  const { node, toggleOpen, open, migrationId } = props;
  const { id, label } = node.data;

  const {
    data: { tableRecords, view: GraphView },
    getMigrationTableRecord,
  } = useGraphRender();
  const { getDataByVertexId } = useGraphData();

  useEffect(() => {
    const vertexId = `${label}.${id}`;
    if (!nodeDataCache[vertexId] && !_.isEmpty(migrationId)) {
      getDataByVertexId({
        migrationId: migrationId,
        vertexId: id,
        onSuccess: (data) => {
          setNodeData({ ...data.vertex, id, label });
          setNodeDataCache((previous) => {
            previous[vertexId] = data.vertex;
            return previous;
          });
        },
      });
    }
    if (nodeDataCache[vertexId]) {
      setNodeData({ ...nodeDataCache[vertexId], id, label });
    }
  }, [getDataByVertexId, id, label, migrationId, nodeDataCache]);

  useEffect(() => {
    const recordDataKey = `${migrationId}-User`;
    const missingKeys: string[] = [];
    if (nodeData && nodeData.id === id) {
      if (!tableRecords[recordDataKey] || tableRecords[recordDataKey]?.loading === false) {
        ACCOUNT_ID_FIELDS.forEach((key) => {
          if (!tableRecords[recordDataKey]?.data[nodeData[key]] && nodeData[key]) {
            missingKeys.push(`'${nodeData[key]}'`);
          }
        });
        if (missingKeys.length > 0) {
          if (!_.isEmpty(migrationId)) {
            getMigrationTableRecord({
              migrationId,
              tableId: 'User',
              skip: 0,
              take: 0,
              conditions: [
                {
                  field: 'Id',
                  operator: 'in',
                  value: `(${missingKeys.join(',')})`,
                  type: RuleComponentType.ROOT_CONDITIONAL,
                },
              ],
            });
          }
        } else {
          const referencedUserTmp: any = {};
          ACCOUNT_ID_FIELDS.forEach((key) => {
            if (tableRecords[recordDataKey]?.data[nodeData[key]]) {
              referencedUserTmp[nodeData[key]] = tableRecords[recordDataKey]?.data[nodeData[key]];
            }
          });
          setReferencesUsers(referencedUserTmp);
        }
      }
    }
  }, [getMigrationTableRecord, id, migrationId, nodeData, tableRecords]);

  if (GraphView === GraphRenderView.LEADS) {
    return (
      <LeadPanel
        node={node}
        toggleOpen={toggleOpen}
        open={open}
        migrationId={migrationId}
        nodeData={nodeData}
        referencedUsers={referencedUsers}
      />
    );
  }

  if (['Event', 'Task', 'Email', 'Call', 'Note'].includes(nodeData?.label ?? ''))
    return (
      <ActivityPanel
        node={node}
        toggleOpen={toggleOpen}
        open={open}
        migrationId={migrationId}
        nodeData={nodeData}
        referencedUsers={referencedUsers}
      />
    );

  return (
    <AccountPanel
      node={node}
      toggleOpen={toggleOpen}
      open={open}
      migrationId={migrationId}
      nodeData={nodeData}
      referencedUsers={referencedUsers}
    />
  );
  /* 
  return (
    <EmailThreadPanel node={node}
    toggleOpen={toggleOpen}
    open={true}
    migrationId={migrationId}
    nodeData={nodeData}
    referencedUsers={referencedUsers} />
  ) */
};
