import React from 'react';
import { Stack, Typography, Grid } from '@mui/material';
import { format } from 'date-fns';

interface IProps {
  nodeData: any;
}

const CallDetailsContainer = ({ nodeData = {} }: IProps) => {
  const { ActivityDate, CallDurationInSeconds = 0, Description } = nodeData;
  return (
    <Grid direction="row" container columns={2} rowGap={2} p={1}>
      <Grid item xs={1}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Start
          </Typography>

          {/* <Typography variant="p14">Feb 18, 12:30 PM</Typography> */}
          <Typography variant="p14">{format(new Date(ActivityDate), 'MMM dd, hh:mm aa')}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={1}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Duration
          </Typography>

          <Typography variant="p14">{Math.floor(CallDurationInSeconds / 60)} min</Typography>
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack gap={0.5}>
          <Typography variant="p12" color="neutral.n400">
            Notes
          </Typography>

          <Typography variant="p14">{Description}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CallDetailsContainer;
