import { Box } from '@mui/material';
import AIRobotIcon from 'assets/icons/aiRobot.jpg';
import theme from 'core/theme';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Show } from '../../../../../../components/show';
import { useMigration } from 'store/migration/hooks';
import { useDataRaptor } from 'store/dataRaptor/hooks';
import { useAIAssistant } from './useAIAssistant';
import { useRuleFormStore } from 'store/ruleForm/hook';
import { AIAssistantContent } from './AIAssistantContact';
import Draggable from 'react-draggable';

export const AIAssistant = () => {
  const { aiAssistant: aiAssistantStore, setAiAssistantOpen, setAiAssistantTiledWindow } = useRuleFormStore();
  const draggableRef = useRef(null);
  const [userInput, setUserInput] = useState('');

  const open = useMemo(() => aiAssistantStore.open, [aiAssistantStore.open]);
  const tiledWindow = useMemo(() => aiAssistantStore.tiledWindow, [aiAssistantStore.tiledWindow]);

  const {
    data: { migrationId },
  } = useMigration();

  const { selectedMigration } = useDataRaptor();

  const { data: aiAssistantData, actions: aiAssistantActions } = useAIAssistant({
    initialAIMessage:
      '👋 Welcome! I’m Mark Bot, your guide for creating rules. Add details for the rule you want, like “creating a data validation rule for the Account table to ensure international phone numbers”. Let’s get started! How can I help you?',
    migrationId: migrationId || selectedMigration,
  });

  const handleUserInputUpdate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault();
      if (aiAssistantData.thinking) {
        aiAssistantActions.signalAbort();
      } else {
        aiAssistantActions.requestCompletion(userInput);
        setUserInput('');
      }
    },
    [aiAssistantActions, aiAssistantData.thinking, userInput],
  );

  const handleToggle = useCallback(() => {
    setAiAssistantOpen(!open);
    if (!open === false) {
      setAiAssistantTiledWindow(false);
    }
  }, [open, setAiAssistantOpen, setAiAssistantTiledWindow]);

  const handleTileWindowToggle = useCallback(() => {
    setAiAssistantTiledWindow(!tiledWindow);
    if (draggableRef && draggableRef.current) {
      console.log(draggableRef);
      // draggableRef.current.style.transform = 'translate(0, 0)';
    }
  }, [tiledWindow, setAiAssistantTiledWindow]);

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          left: '5vh',
          bottom: '5vh',
          zIndex: 1,
          width: '56px',
          height: '56px',
          borderRadius: '4px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${AIRobotIcon})`,
          ':hover': { cursor: 'pointer', outline: `2px solid ${theme.palette.primary.main}` },
        }}
        onClick={handleToggle}
      />

      <Show when={aiAssistantStore.open}>
        {!tiledWindow && (
          <Draggable handle="#dragIndicatorIcon">
            <div style={{ zIndex: 2, position: 'absolute', height: '100%' }}>
              <AIAssistantContent
                tiledWindow={tiledWindow}
                aiAssistantData={aiAssistantData}
                aiAssistantActions={aiAssistantActions}
                userInput={userInput}
                handleToggle={handleToggle}
                handleTitleWindowToggle={handleTileWindowToggle}
                handleUserInputUpdate={handleUserInputUpdate}
                handleSubmit={handleSubmit}
              />
            </div>
          </Draggable>
        )}
        {tiledWindow && (
          <AIAssistantContent
            tiledWindow={tiledWindow}
            aiAssistantData={aiAssistantData}
            aiAssistantActions={aiAssistantActions}
            userInput={userInput}
            handleToggle={handleToggle}
            handleTitleWindowToggle={handleTileWindowToggle}
            handleUserInputUpdate={handleUserInputUpdate}
            handleSubmit={handleSubmit}
          />
        )}
      </Show>
    </>
  );
};
