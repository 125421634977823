import { Grid, IconButton, Stack, Typography, Backdrop, CircularProgress } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { VerticalDivider } from '../../ui';
import theme from 'core/theme';
import { PrimaryButton, SecondaryButton } from 'components/ui';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { useRuleFormSubmissionHook } from 'pages/RuleLibrary/hooks/RuleFormSubmissionHook';
import { RuleFormMode } from 'pages/RuleForm/types';
import { useMemo } from 'react';

interface HeaderInterface {
  handleBack: () => void;
  handleCancel?: () => void;
  handleSaveAsTemplate?: () => void;
  handleAddARule?: () => void;
}
const Header = ({ handleBack }: HeaderInterface) => {
  const { formError, loading, formMode, updateRuleByIdLoading } = useRuleForm();
  const { handleCreateOrUpdateRule } = useRuleFormSubmissionHook();

  const primaryActionButtonText = useMemo(() => {
    if (formMode === RuleFormMode.CREATE) {
      return 'Add rule';
    } else if (formMode === RuleFormMode.EDIT) {
      return 'Update rule';
    } else {
      return 'Apply Template';
    }
  }, [formMode]);

  const headerTitle = useMemo(() => {
    if (formMode === RuleFormMode.CREATE) {
      return 'New Rule';
    } else if (formMode === RuleFormMode.EDIT) {
      return 'Update rule';
    } else {
      return 'Details Rule Template';
    }
  }, [formMode]);

  return (
    <Grid container spacing={2} sx={{ backgroundColor: 'neutral.white', padding: '24px 32px 16px' }}>
      <Grid item xs={12} sm={6}>
        <Stack direction="row" alignItems="center">
          <IconButton
            onClick={handleBack}
            sx={{
              marginRight: 1,
              border: 1,
              color: theme.palette.blue.main,
              borderRadius: 2,
            }}
          >
            <KeyboardBackspaceIcon></KeyboardBackspaceIcon>
          </IconButton>
          <Typography variant="h2" sx={{ color: 'neutral.main' }}>
            {headerTitle}
          </Typography>
          <VerticalDivider />
          <Typography variant="labelRegular12" sx={{ color: 'neutral.n400' }} component="p">
            Data health and anomaly detection solution
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <SecondaryButton sx={{ mr: 1 }} onClick={handleBack}>
          Cancel
        </SecondaryButton>
        <SecondaryButton>Save as a template</SecondaryButton>
        <VerticalDivider></VerticalDivider>
        <PrimaryButton
          onClick={() => {
            handleCreateOrUpdateRule().catch((err) => console.log('err', err));
          }}
        >
          {primaryActionButtonText}
        </PrimaryButton>
      </Grid>
      {!!formError.error && (
        <Typography variant="caption" sx={{ color: 'red.main', display: 'block', ml: 'auto', mt: '0.5rem' }}>
          {formError.error}
        </Typography>
      )}

      <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={loading || updateRuleByIdLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default Header;
