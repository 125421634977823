import { Card, CardMedia, Typography } from '@mui/material';

import { Grid } from '@mui/material';

import PicShare from 'assets/Workspace/share.png';
import PicDataInt from 'assets/Workspace/data_int.png';
import PicGraphSpace from 'assets/Workspace/graph_space.png';

import { PrimaryButton } from './ui';
import { useNavigate } from 'react-router-dom';
import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';

export default function EmptyGraphSpace() {
  const navigate = useNavigate();

  return (
    <Grid container p={4}>
      <Grid item xs={12}>
        <Card sx={{ p: 4 }} elevation={0}>
          <Typography variant="h4" sx={{ color: 'neutral.main' }}>
            Welcome, John Smith!
          </Typography>
          <Typography sx={{ color: 'neutral.n400' }}>
            Create your first workspace. We’ll guide you every step of the way.
          </Typography>
        </Card>
      </Grid>
      <Grid container spacing={2} py={4}>
        <Grid item xs={4}>
          <Card sx={{ p: 4 }} elevation={0}>
            <Typography variant="h4" sx={{ color: 'neutral.main', mb: 2 }}>
              Data Integration
            </Typography>
            <Typography sx={{ color: 'neutral.n400', mb: 2 }}>
              Choose from the multitude of data sources on which you can connect. Quickly integrate and map fields.
            </Typography>
            <CardMedia
              component="img"
              sx={{
                width: 'auto',
                height: '256px',
                objectFit: 'cover',
                display: 'block',
                margin: '0 auto',
              }}
              image={PicDataInt}
              alt="Data Integration"
            />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ p: 4 }} elevation={0}>
            <Typography variant="h4" sx={{ color: 'neutral.main', mb: 2 }}>
              Visualize and Collaborate
            </Typography>
            <Typography sx={{ color: 'neutral.n400', mb: 2 }}>
              Customize your graph views, depending on your team’s needs. Collaborate on the Smart Notes feature.
            </Typography>
            <CardMedia
              component="img"
              sx={{
                width: 'auto',
                height: '256px',
                objectFit: 'cover',
                display: 'block',
                margin: '0 auto',
              }}
              image={PicGraphSpace}
              alt="Graph Space"
            />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ p: 4 }} elevation={0}>
            <Typography variant="h4" sx={{ color: 'neutral.main', mb: 2 }}>
              Create Space and Share{' '}
            </Typography>
            <Typography sx={{ color: 'neutral.n400', mb: 2 }}>
              Create a personalized workspace for each team. Establish your team’s user roles and permissions.
            </Typography>
            <CardMedia
              component="img"
              sx={{
                width: 'auto%',
                height: '256px',
                objectFit: 'cover',
                display: 'block',
                margin: '0 auto',
              }}
              image={PicShare}
              alt="Share"
            />
          </Card>
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: 'center' }}>
        <PrimaryButton
          onClick={() => navigate(PRIVATE_ABS_ROUTE_PATHS.graphSpaceSetUp)}
          sx={{ px: '36px', py: '32px' }}
        >
          <Typography variant="labelMedium14">Create Graph Space</Typography>
        </PrimaryButton>
      </Grid>
    </Grid>
  );
}
