import { GraphObject } from 'store/graphData/types';

export type NodeTmp = {
  nodeData: GraphObject;
  nodeProperties?: any;
};

export enum ComponentDesign {
  STANDARD = 'standard-design',
  SEARCHING = 'searching-design',
  HIGHLIGH = 'highligh-design',
  DUPLICATE_INACTIVE = 'duplicate-inactive-design',
  DUPLICATE_ACTIVE = 'duplicate-active-design',
}
