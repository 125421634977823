import { shallowEqual, useSelector } from 'react-redux';

import { useActionCreator } from 'hooks';
import { RootState } from 'store/types';
import { GraphDataHook } from './types';
import {
  setError,
  setSuccess,
  setLoading,
  getDataByLabel,
  getDataByVertexId,
  cleanState,
  getMigrationStatus,
  getDataByVertexIdArray,
} from './actions';

export const useGraphData = (): GraphDataHook => {
  const graphDataState = useSelector((state: RootState) => state.graphData, shallowEqual);

  return {
    ...graphDataState,
    setError: useActionCreator(setError),
    setSuccess: useActionCreator(setSuccess),
    setLoading: useActionCreator(setLoading),
    getDataByLabel: useActionCreator(getDataByLabel),
    getDataByVertexId: useActionCreator(getDataByVertexId),
    getDataByVertexIdArray: useActionCreator(getDataByVertexIdArray),
    cleanState: useActionCreator(cleanState),
    getMigrationStatus: useActionCreator(getMigrationStatus),
  };
};
