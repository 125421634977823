import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';

import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ContactsIcon from '@mui/icons-material/Contacts';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ErrorIcon from '@mui/icons-material/Error';

import { FieldCard } from './components/FieldCard';
import { useFieldMapping } from './useFieldMapping';
import NewFieldMappingModal from './components/NewFieldMappingModal';
import SaveMappingModal from './components/SaveMappingModal';

interface ExtraData {
  step: string;
  extraMessage?: React.ReactNode;
  extraButton?: React.ReactNode;
}

type FieldMappingProps = {
  onExtraData: (data: ExtraData) => void;
};

const initialFields = [
  {
    title: 'Contacts',
    icon: <ContactsIcon color="primary" />,
    tableData: [
      { mapped: true, objectField: 'Name', schemaField: 'Full Name', fieldGroup: 'Personal Information' },
      { mapped: false, objectField: 'Phone', schemaField: 'Phone Number', fieldGroup: 'Contact Information' },
    ],
  },
  {
    title: 'Leads',
    icon: <LightbulbIcon color="primary" />,
    tableData: [
      { mapped: true, objectField: 'Year', schemaField: 'Birth Year', fieldGroup: 'Personal Information' },
      { mapped: false, objectField: 'Phone', schemaField: 'Phone Number', fieldGroup: 'Contact Information' },
    ],
  },
];

export const FieldMapping: React.FC<FieldMappingProps> = ({ onExtraData }) => {
  const {
    fieldData,
    expanded,
    removeUnmapped,
    handleExpandClick,
    handleCreateNewField,
    handleDeleteField,
    handleRemoveUnmapped,
    handleUpdateFieldMapping,
  } = useFieldMapping(initialFields);

  const [openNewFieldModal, setOpenNewFieldModal] = useState(false);
  const [openSaveMappingModal, setOpenSaveMappingModal] = useState(false);
  const [currentFieldTitle, setCurrentFieldTitle] = useState<string | null>(null);

  const unmappedFieldsCount = fieldData.reduce((count, field) => {
    return count + field.tableData.filter((item) => !item.mapped).length;
  }, 0);

  useEffect(() => {
    onExtraData({
      step: 'Field Mapping',
      extraMessage:
        unmappedFieldsCount > 0 ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <ErrorIcon sx={{ color: 'orange.n200' }} />
            <Typography variant="p14" color="primary.gray">
              You have {unmappedFieldsCount} unmapped objects
            </Typography>
          </Box>
        ) : null,
      extraButton: (
        <IconButton
          sx={{ border: '1px solid blue', borderRadius: 1, color: 'primary.main' }}
          onClick={() => setOpenSaveMappingModal(!openSaveMappingModal)}
        >
          <SaveOutlinedIcon />
        </IconButton>
      ),
    });
  }, [unmappedFieldsCount, onExtraData]);

  return (
    <Box sx={{ mb: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h2" mt={4} mb={1} textAlign="center">
        Field Mapping
      </Typography>
      <Typography variant="body2" color="text.secondary" textAlign="center">
        Fields from selected objects have been mapped automatically to the identified schemas.
      </Typography>
      <Typography variant="body2" color="text.secondary" textAlign="center" mb={3}>
        In some cases, object fields can’t be mapped as they do not have a corresponding field in the identified schema
        and you can choose to create custom fields.
      </Typography>

      {fieldData.map((field) => (
        <FieldCard
          key={field.title}
          title={field.title}
          icon={field.icon}
          tableData={field.tableData}
          expanded={expanded === field.title}
          removeUnmapped={removeUnmapped[field.title] || false}
          onExpand={() => handleExpandClick(field.title)}
          onNewFieldMapping={() => {
            setCurrentFieldTitle(field.title);
            setOpenNewFieldModal(true);
          }}
          onRemoveUnmapped={() => handleRemoveUnmapped(field.title)}
          onDeleteField={(objectField) => handleDeleteField(field.title, objectField)}
          onUpdateFieldMapping={(objectField, schemaField, fieldGroup) =>
            handleUpdateFieldMapping(field.title, objectField, schemaField, fieldGroup)
          }
        />
      ))}

      <NewFieldMappingModal
        open={openNewFieldModal}
        onClose={() => setOpenNewFieldModal(false)}
        onCreate={(objectField) => handleCreateNewField(objectField, currentFieldTitle!)}
      />

      <SaveMappingModal open={openSaveMappingModal} onClose={() => setOpenSaveMappingModal(false)} />
    </Box>
  );
};

export default FieldMapping;
