import { useMemo } from 'react';
import { BaseEdge } from 'reactflow';

import theme from 'core/theme';
import { generateCustomEdgePath2 } from './utils';
import { useReactFlow } from 'reactflow';

export function EventEdge({ source, target, style, data, id }: any) {
  const reactFlow = useReactFlow();

  const sourceNode = reactFlow.getNode(source);
  const targetNode = reactFlow.getNode(target);

  const shouldRenderEdge = useMemo(() => {
    if (sourceNode && sourceNode.hidden === false && targetNode && targetNode.hidden === false) {
      return true;
    }
    return false;
  }, [sourceNode, targetNode]);

  const [confidenceEdgeScoreColor] = useMemo(() => [theme.palette.primary.darkBlueMedium], []);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const sourceNodePosition = sourceNode.position;
  const targetNodePosition = targetNode.position;

  const { width: sourceWidth, height: sourceHeight } = sourceNode;
  const { width: targetWidth, height: targetHeight } = targetNode;

  const sourceCenterNodePosition = [
    sourceNodePosition.x + (sourceWidth || 0) / 2,
    sourceNodePosition.y + (sourceHeight || 0) / 2,
  ];
  const targetCenterNodePosition = [
    targetNodePosition.x + (targetWidth || 0) / 2,
    targetNodePosition.y + (targetHeight || 0) / 2,
  ];

  // const path = generatePath(sx, sy, tx, ty);
  const [path, rad] = generateCustomEdgePath2(
    sourceCenterNodePosition[0],
    sourceCenterNodePosition[1],
    targetCenterNodePosition[0],
    targetCenterNodePosition[1],
    sourceWidth || 0,
    targetWidth || 0,
  );

  return (
    <>
      {shouldRenderEdge === true && (
        <>
          <BaseEdge
            interactionWidth={0}
            path={path}
            markerEnd={undefined}
            style={{
              ...style,
              strokeWidth: 2,
              fill: confidenceEdgeScoreColor,
              stroke: confidenceEdgeScoreColor,
              transform: `rotate(${rad}rad)`,
              transformOrigin: `${sourceCenterNodePosition[0]}px ${sourceCenterNodePosition[1]}px`,
            }}
          />
        </>
      )}
    </>
  );
}
