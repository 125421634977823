import { Box, Typography } from '@mui/material';
import { ReactComponent as RecommendationIcon } from 'assets/icons/recommendationStart.svg';
import theme from 'core/theme';
import { FC } from 'react';

export interface SummaryBoxProps {
  value: string;
}

export const SummaryBox: FC<SummaryBoxProps> = (props) => {
  const { value } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '4px',
      }}
    >
      <Box
        flex={1}
        sx={{
          backgroundColor: theme.palette.primary.main,
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'center',
          pt: 0.5,
        }}
      >
        <RecommendationIcon width="26" height="26" />
      </Box>
      <Box
        flex={20}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          padding: 1,
          backgroundColor: theme.palette.primary.subtone3,
        }}
      >
        <Typography variant="p16">{value}</Typography>
      </Box>
    </Box>
  );
};
