import { shallowEqual, useSelector } from 'react-redux';

import { useActionCreator } from 'hooks';
import { RootState } from 'store/types';
import { DataAnomalyStateHook } from './types';
import { getRecordAnomalies, getRuleByIds } from './actions';

export const useDataAnomaly = (): DataAnomalyStateHook => {
  const dataAnomalyState = useSelector((state: RootState) => state.dataAnomaly, shallowEqual);

  return {
    ...dataAnomalyState,
    getRecordAnomalies: useActionCreator(getRecordAnomalies),
    getRuleByIds: useActionCreator(getRuleByIds),
  };
};
