import {
  Box,
  Typography,
  Card,
  CardContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  SxProps,
  Collapse,
  Divider,
} from '@mui/material';
import React, { useState } from 'react';

interface CardRadioGroupsProps {
  boxStyle: SxProps;
  cardStyle: SxProps;
  items: {
    id: string;
    title: string;
    subtitle: string;
    content?: React.ReactNode;
  }[];
  onChangePolicy: (policyId: string) => void;
}

const CardRadioGroups: React.FC<CardRadioGroupsProps> = ({ boxStyle, cardStyle, items, onChangePolicy }) => {
  const [selectedItem, setSelectedItem] = useState('');

  const handleItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedItem(event.target.value);
    onChangePolicy(event.target.value);
  };

  return (
    <Box sx={{ ...boxStyle, width: '100%' }}>
      <RadioGroup sx={{ width: '100%' }} value={selectedItem} onChange={handleItemChange}>
        {items.map((item) => (
          <Box key={item.id} sx={{ width: '100%' }}>
            <Card
              sx={{
                ...cardStyle,
                border: selectedItem === item.id ? '1px solid #3f51b5' : '',
                backgroundColor: selectedItem === item.id ? 'primary.subtone3' : 'lightBg.main',
                boxShadow: 0,
              }}
            >
              <CardContent sx={{ p: 2, '&:last-child': { paddingBottom: 2 } }}>
                <FormControlLabel
                  value={item.id}
                  control={<Radio />}
                  label={
                    <Box>
                      <Typography variant="p14" mr={1}>
                        {item.title}
                      </Typography>
                      <Typography variant="p12" color="neutral.n400">
                        {item.subtitle}
                      </Typography>
                    </Box>
                  }
                  sx={{ width: '100%' }}
                />
                {item.content && (
                  <Collapse in={selectedItem === item.id} timeout="auto" unmountOnExit>
                    <Box
                      sx={{
                        mt: 1,
                        backgroundColor: 'primary.subtone3',
                      }}
                    >
                      <Divider />
                      {item.content}
                    </Box>
                  </Collapse>
                )}
              </CardContent>
            </Card>
          </Box>
        ))}
      </RadioGroup>
    </Box>
  );
};

export default CardRadioGroups;
