import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  setSelectedSubPage,
  getAllRulesByMigrationId,
  getRuleTemplatesByDataSourceId,
  setRenderedRule,
  setRenderedTemplates,
  setTemplateDepartmentFilterAllowedValues,
  setRuleDepartmentFilterAllowedValues,
  setFilteredTemplates,
  updateRulesByMigrationId,
  setFilteredRules,
  setRuleStatusFilterAllowedValues,
  getAIPoweredRuleTemplate,
  postEmbedDDLSchema,
  setTemplateTypeFilterAllowedValues,
  setRuleTypeFilterAllowedValues,
  getAllTemporaryTableByMigrationId,
  setRuleTableFilterAllowedValues,
  setTemplateTableFilterAllowedValues,
  setTemplateSortStrategy,
  setRuleSortStrategy,
  deleteRuleTemporalTable,
  addRuleTemporalTable,
  setFilteredTempTable,
  setRenderedTempTables,
} from './actions';
import { RULE_TYPE_FILTER_OPTIONS, RuleLibraryState, SubPages } from './types';
import { getDepartmentOptions, getStatusOptions, getTableOptions, getTypeOptions } from './utils';
import { resetApp } from 'store/actions';
import _ from 'lodash';
import { RuleTemporalTable } from 'store/dataRaptorRule/types';

const subPageInitialState: SubPages = {
  library: {
    loading: true,
    error: '',
    success: false,
    name: 'library',
    label: 'Rule Library',
    counter: 0,
    data: {
      gridView: true,
      sortStrategy: '',
      rules: [],
      renderedRules: [],
      filteredRules: [],
      filters: {
        department: {
          allowedValues: [],
          options: [],
          label: 'Filter by department',
        },
        status: {
          allowedValues: [],
          options: [],
          label: 'Filter by status',
        },
        type: {
          allowedValues: [],
          options: [],
          label: 'Filter by type',
        },
        table: {
          allowedValues: [],
          options: [],
          label: 'Filter by table',
        },
      },
    },
  },
  template: {
    loading: true,
    error: '',
    success: false,
    name: 'template',
    label: 'Rule Templates',
    counter: 0,
    data: {
      sortStrategy: '',
      templates: [],
      renderedTemplates: [],
      filteredTemplates: [],
      filters: {
        department: {
          allowedValues: [],
          options: [],
          label: 'Filter by Department',
        },
        type: {
          allowedValues: RULE_TYPE_FILTER_OPTIONS.map((option) => option.value),
          options: RULE_TYPE_FILTER_OPTIONS,
          label: 'Filter by Type',
        },
        table: {
          allowedValues: [],
          options: [],
          label: 'Filter by table',
        },
      },
    },
  },
  temporalTable: {
    loading: true,
    error: '',
    success: false,
    name: 'temporalTable',
    label: 'Dataset',
    counter: 0,
    data: {
      tables: [],
      renderedTables: [],
      filteredTables: [],
    },
  },
};

export const initialState: RuleLibraryState = {
  selectedSubPage: subPageInitialState.library.name as keyof SubPages,
  subPages: subPageInitialState,
  action: {
    getAIPoweredRuleTemplate: {
      loading: false,
      error: '',
      success: true,
    },
    postEmbedDDLSchema: {
      loading: false,
      error: '',
      success: true,
    },
  },
};

const ruleLibraryReducer = createSlice({
  name: 'rule-library',
  initialState: _.cloneDeep(initialState),
  reducers: {
    toggleLibraryListView: (state) => {
      const gridView = state.subPages.library.data.gridView;
      state.subPages.library.data.gridView = !gridView;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<RuleLibraryState>): void => {
    builder.addCase(addRuleTemporalTable, (state, { payload }) => {
      state.subPages.temporalTable.data.tables.push(payload);
      state.subPages.temporalTable.counter++;
    }),
      builder.addCase(setTemplateSortStrategy, (state, { payload }) => {
        state.subPages.template.data.sortStrategy = payload;
      });
    builder.addCase(setRuleSortStrategy, (state, { payload }) => {
      state.subPages.library.data.sortStrategy = payload;
    });
    builder.addCase(setFilteredRules, (state, { payload }) => {
      state.subPages.library.data.filteredRules = payload;
    });
    builder.addCase(setFilteredTemplates, (state, { payload }) => {
      state.subPages.template.data.filteredTemplates = payload;
    });
    builder.addCase(setTemplateDepartmentFilterAllowedValues, (state, { payload }) => {
      state.subPages.template.data.filters.department.allowedValues = payload;
    });
    builder.addCase(setRuleTypeFilterAllowedValues, (state, { payload }) => {
      state.subPages.library.data.filters.type.allowedValues = payload;
    });
    builder.addCase(setTemplateTypeFilterAllowedValues, (state, { payload }) => {
      state.subPages.template.data.filters.type.allowedValues = payload;
    });
    builder.addCase(setRuleTableFilterAllowedValues, (state, { payload }) => {
      state.subPages.library.data.filters.table.allowedValues = payload;
    });
    builder.addCase(setTemplateTableFilterAllowedValues, (state, { payload }) => {
      state.subPages.template.data.filters.table.allowedValues = payload;
    });
    builder.addCase(setRuleDepartmentFilterAllowedValues, (state, { payload }) => {
      state.subPages.library.data.filters.department.allowedValues = payload;
    });
    builder.addCase(setRuleStatusFilterAllowedValues, (state, { payload }) => {
      state.subPages.library.data.filters.status.allowedValues = payload;
    });
    builder.addCase(setRenderedRule, (state, { payload }) => {
      state.subPages.library.data.renderedRules = payload;
    });
    builder.addCase(setRenderedTemplates, (state, { payload }) => {
      state.subPages.template.data.renderedTemplates = payload;
    });
    builder.addCase(setSelectedSubPage, (state, { payload }) => {
      state.selectedSubPage = payload as keyof SubPages;
    });
    builder.addCase(getAllRulesByMigrationId.pending, (state) => {
      state.subPages.library.loading = true;
      state.subPages.library.error = '';
      state.subPages.library.success = false;
    });
    builder.addCase(getAllRulesByMigrationId.rejected, (state, { error }) => {
      state.subPages.library.loading = false;
      state.subPages.library.error = error as string;
      state.subPages.library.success = false;
    });
    builder.addCase(getAllRulesByMigrationId.fulfilled, (state, { payload }) => {
      state.subPages.library.loading = false;
      state.subPages.library.error = '';
      state.subPages.library.success = true;
      state.subPages.library.data.rules = payload;
      state.subPages.library.counter = payload?.length || 0;

      const { departmentAllowedValues, departmentFilterOptions } = getDepartmentOptions(payload);
      state.subPages.library.data.filters.department.allowedValues = departmentAllowedValues;
      state.subPages.library.data.filters.department.options = departmentFilterOptions;

      const { statusAllowedValues, statusFilterOptions } = getStatusOptions(payload);
      state.subPages.library.data.filters.status.allowedValues = statusAllowedValues;
      state.subPages.library.data.filters.status.options = statusFilterOptions;

      const { typeAllowedValues, typeFilterOptions } = getTypeOptions(payload);
      state.subPages.library.data.filters.type.allowedValues = typeAllowedValues;
      state.subPages.library.data.filters.type.options = typeFilterOptions;

      const { tableAllowedValues, tableFilterOptions } = getTableOptions(payload);
      state.subPages.library.data.filters.table.allowedValues = tableAllowedValues;
      state.subPages.library.data.filters.table.options = tableFilterOptions;
    });

    builder.addCase(updateRulesByMigrationId.fulfilled, (state, { payload }) => {
      state.subPages.library.data.rules = payload;
      state.subPages.library.counter = payload?.length || 0;

      const { departmentAllowedValues, departmentFilterOptions } = getDepartmentOptions(payload);
      state.subPages.library.data.filters.department.allowedValues = departmentAllowedValues;
      state.subPages.library.data.filters.department.options = departmentFilterOptions;

      const { statusAllowedValues, statusFilterOptions } = getStatusOptions(payload);
      state.subPages.library.data.filters.status.allowedValues = statusAllowedValues;
      state.subPages.library.data.filters.status.options = statusFilterOptions;

      const { typeAllowedValues, typeFilterOptions } = getTypeOptions(payload);
      state.subPages.library.data.filters.type.allowedValues = typeAllowedValues;
      state.subPages.library.data.filters.type.options = typeFilterOptions;

      const { tableAllowedValues, tableFilterOptions } = getTableOptions(payload);
      state.subPages.library.data.filters.table.allowedValues = tableAllowedValues;
      state.subPages.library.data.filters.table.options = tableFilterOptions;
    });
    builder.addCase(getRuleTemplatesByDataSourceId.pending, (state) => {
      state.subPages.template.loading = true;
      state.subPages.template.error = '';
      state.subPages.template.success = false;
    });
    builder.addCase(getRuleTemplatesByDataSourceId.rejected, (state, { error }) => {
      state.subPages.template.loading = false;
      state.subPages.template.error = error as string;
      state.subPages.template.success = false;
    });
    builder.addCase(getRuleTemplatesByDataSourceId.fulfilled, (state, { payload }) => {
      state.subPages.template.loading = false;
      state.subPages.template.error = '';
      state.subPages.template.success = true;
      state.subPages.template.data.templates = payload;
      state.subPages.template.counter = payload?.length || 0;
      const { departmentAllowedValues, departmentFilterOptions } = getDepartmentOptions(payload);
      state.subPages.template.data.filters.department.allowedValues = departmentAllowedValues;
      state.subPages.template.data.filters.department.options = departmentFilterOptions;

      const { tableAllowedValues, tableFilterOptions } = getTableOptions(payload);
      state.subPages.template.data.filters.table.allowedValues = tableAllowedValues;
      state.subPages.template.data.filters.table.options = tableFilterOptions;
    });
    builder.addCase(setFilteredTempTable, (state, { payload }) => {
      state.subPages.temporalTable.data.filteredTables = payload;
    });
    builder.addCase(setRenderedTempTables, (state, { payload }) => {
      state.subPages.temporalTable.data.renderedTables = payload;
    });
    builder.addCase(deleteRuleTemporalTable.pending, (state) => {
      state.subPages.temporalTable.error = '';
      state.subPages.temporalTable.loading = true;
      state.subPages.temporalTable.success = false;
    });
    builder.addCase(deleteRuleTemporalTable.rejected, (state, { error }) => {
      state.subPages.temporalTable.loading = false;
      state.subPages.temporalTable.success = false;
      state.subPages.temporalTable.error = error as string;
    });
    builder.addCase(deleteRuleTemporalTable.fulfilled, (state, { payload }) => {
      const { migrationId, tableId } = payload;
      const { tables } = state.subPages.temporalTable.data;

      state.subPages.temporalTable.error = '';
      state.subPages.temporalTable.success = true;
      state.subPages.temporalTable.loading = false;

      const updateRules = (rules: RuleTemporalTable[]) => {
        const index = rules.findIndex((r) => {
          return r.dataMigrationId === migrationId && r.ruleTempTableId === tableId;
        });

        index >= 0 && rules.splice(index, 1);
        return rules;
      };

      const updatedRules = updateRules(tables);
      state.subPages.temporalTable.data.tables = updatedRules;
      state.subPages.temporalTable.counter = updatedRules.length;
    });
    builder.addCase(getAllTemporaryTableByMigrationId.pending, (state) => {
      state.subPages.temporalTable.loading = true;
      state.subPages.temporalTable.error = '';
      state.subPages.temporalTable.success = false;
    });
    builder.addCase(getAllTemporaryTableByMigrationId.rejected, (state, { error }) => {
      state.subPages.temporalTable.loading = false;
      state.subPages.temporalTable.error = error as string;
      state.subPages.temporalTable.success = false;
    });
    builder.addCase(getAllTemporaryTableByMigrationId.fulfilled, (state, { payload }) => {
      state.subPages.temporalTable.loading = false;
      state.subPages.temporalTable.error = '';
      state.subPages.temporalTable.success = true;
      state.subPages.temporalTable.data.tables = payload;
      state.subPages.temporalTable.counter = payload?.length || 0;
    });
    builder.addCase(postEmbedDDLSchema.pending, (state) => {
      state.action.postEmbedDDLSchema.loading = true;
      state.action.postEmbedDDLSchema.error = '';
      state.action.postEmbedDDLSchema.success = false;
    });
    builder.addCase(postEmbedDDLSchema.rejected, (state, { error }) => {
      state.action.postEmbedDDLSchema.loading = false;
      state.action.postEmbedDDLSchema.error = error.message || 'Unexpected Error';
      state.action.postEmbedDDLSchema.success = false;
    });
    builder.addCase(postEmbedDDLSchema.fulfilled, (state, { payload }) => {
      state.action.postEmbedDDLSchema.loading = false;
      state.action.postEmbedDDLSchema.error = '';
      state.action.postEmbedDDLSchema.success = true;
      state.action.postEmbedDDLSchema.data = payload;
    });
    builder.addCase(getAIPoweredRuleTemplate.pending, (state) => {
      state.action.getAIPoweredRuleTemplate.loading = true;
      state.action.getAIPoweredRuleTemplate.error = '';
      state.action.getAIPoweredRuleTemplate.success = false;
    });
    builder.addCase(getAIPoweredRuleTemplate.rejected, (state, { error }) => {
      state.action.getAIPoweredRuleTemplate.loading = false;
      state.action.getAIPoweredRuleTemplate.error = error.message || 'Unexpected Error';
      state.action.getAIPoweredRuleTemplate.success = false;
    });
    builder.addCase(getAIPoweredRuleTemplate.fulfilled, (state, { payload }) => {
      state.action.getAIPoweredRuleTemplate.loading = false;
      state.action.getAIPoweredRuleTemplate.error = '';
      state.action.getAIPoweredRuleTemplate.success = true;
      state.action.getAIPoweredRuleTemplate.data = payload;
    });
    builder.addCase(resetApp, (state) => {
      const initialStateTmp = _.cloneDeep(initialState);
      Object.keys(state).forEach((key) => {
        // @ts-ignore
        state[key] = initialStateTmp[key];
      });
    });
  },
});

export const { toggleLibraryListView } = ruleLibraryReducer.actions;
export default ruleLibraryReducer.reducer;
