import { FC } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { Integration } from 'store/integration/types';
import { APPLICATION_STATUS } from 'store/integration-status/types';

import { ReactComponent as InstallIcon } from 'assets/icons/download.svg';
import { ReactComponent as InstalledIcon } from 'assets/icons/checkBlue.svg';

import { PrimaryButton } from 'components/ui';
import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';

import { CardContainer } from './ui';

type IntegrationCardProps = Integration;

const IntegrationCard: FC<IntegrationCardProps> = ({
  applicationId,
  applicationName,
  applicationIcon,
  applicationStatus,
  applicationDescription,
}) => {
  const navigate = useNavigate();
  const isInstalled = applicationStatus === APPLICATION_STATUS.INSTALLED;

  const openDetail = () => {
    const path = generatePath(PRIVATE_ABS_ROUTE_PATHS.integrationDetail, { id: String(applicationId) });
    navigate(path);
  };

  return (
    <CardContainer
      onClick={openDetail}
      sx={{
        height: '95%',
        backgroundColor: 'neutral.white',
      }}
    >
      <Box className="card-header">
        <img height="40" src={applicationIcon}></img>
        <PrimaryButton
          variant={isInstalled ? 'outlined' : 'contained'}
          startIcon={isInstalled ? <InstalledIcon /> : <InstallIcon />}
          sx={{ marginLeft: 2 }}
        >
          {isInstalled ? 'Uninstall' : 'Install'}
        </PrimaryButton>
      </Box>
      <Box className="card-content">
        <Typography variant="p14" sx={{ mr: 'auto' }}>
          {applicationName}
        </Typography>
        <Typography variant="p12" sx={{ color: 'neutral.n400' }}>
          {applicationDescription}
        </Typography>
      </Box>
    </CardContainer>
  );
};

export default IntegrationCard;
