import { shallowEqual, useSelector } from 'react-redux';

import { useActionCreator } from 'hooks';
import { RootState } from 'store/types';
import { DeduplicationResultHook } from './types';
import { getDeduplicationResultByIds, getDeduplicationResultByMigration } from './actions';

export const useDeduplicationResult = (): DeduplicationResultHook => {
  const deduplicationResultState = useSelector((state: RootState) => state.deduplicationResult, shallowEqual);

  return {
    ...deduplicationResultState,
    getDeduplicationResultByIds: useActionCreator(getDeduplicationResultByIds),
    getDeduplicationResultByMigration: useActionCreator(getDeduplicationResultByMigration),
  };
};
