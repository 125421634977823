import { Box, CircularProgress, Radio, TableCell, TableRow, Typography } from '@mui/material';
import theme from 'core/theme';
import _ from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import { SmartMergeToolTip } from './SmartMergeToolTip';
import { getRecordName } from 'core/utils';

interface MergeTableFieldsProps {
  records: any[];
  fieldNames: string[];
  aiRecommendation: any;
  formState: any;
  setFormState: React.Dispatch<React.SetStateAction<any>>;
  loadingAiRecommendation: boolean;
  idReferencesMap: { [x: string]: any };
}

export const MergeTableFields: FC<MergeTableFieldsProps> = (props: MergeTableFieldsProps) => {
  console.log('render MergeTableFields');
  const { records, aiRecommendation, fieldNames, formState, setFormState, loadingAiRecommendation, idReferencesMap } =
    props;

  const differentFields = useMemo(() => {
    const fields = [];
    for (const fieldName of fieldNames) {
      const values = records.map((record: any) => record[fieldName]);
      if (new Set(values).size > 1) {
        fields.push(fieldName);
      }
    }
    return fields;
  }, [fieldNames, records]);

  const handleSetUpFormState = useCallback(
    (fieldName: string, recordPostfix: string) => {
      const id = `${fieldName}-radio-value-${recordPostfix}`;
      setFormState((prevState: any) => {
        const newState = { ...prevState };
        newState[fieldName] = id;
        return newState;
      });
    },
    [setFormState],
  );

  const getFieldLabel = useCallback((fieldName: string) => {
    return (
      <TableCell key={`${fieldName}-table-label`} sx={{ overflowWrap: 'anywhere' }}>
        <Typography variant="labelRegular12" color={theme.palette.neutral.n400}>
          {fieldName.toUpperCase()}
        </Typography>
      </TableCell>
    );
  }, []);

  const getFieldValue = useCallback(
    (record: any, fieldName: string, recordIndex: number) => {
      let label = record[fieldName] || 'Null';
      let value = record[fieldName] || undefined;

      if (_.isObject(label)) {
        label = JSON.stringify(label);
      } else {
        if (idReferencesMap[value]) {
          label = `${getRecordName(idReferencesMap[value])} (${label})`;
        }
      }

      if (_.isObject(value)) {
        value = JSON.stringify(value);
      }
      const id = `${fieldName}-radio-value-${recordIndex}`;
      const checked = formState[fieldName] === id;
      const isDifferent = differentFields.includes(fieldName);
      const fieldStyle = isDifferent
        ? { backgroundColor: theme.palette.yellow.main, padding: '4px', borderRadius: '4px' }
        : {};
      return (
        <TableCell key={`${record.Id}-${fieldName}-value`} sx={{ overflowWrap: 'anywhere' }}>
          <Box>
            <Radio
              checked={checked}
              value={value}
              name={fieldName}
              id={id}
              onClick={() => handleSetUpFormState(fieldName, recordIndex.toString())}
            />
            <Typography variant="labelMedium12" sx={fieldStyle}>
              {label}
            </Typography>
          </Box>
        </TableCell>
      );
    },
    [differentFields, formState, handleSetUpFormState, idReferencesMap],
  );

  const getSmartMergeValue = useCallback(
    (fieldName: string) => {
      let label = aiRecommendation[fieldName] || records[0][fieldName] || 'Null';
      let value = aiRecommendation[fieldName] || records[0][fieldName] || undefined;
      const aiExplanation: string | undefined =
        aiRecommendation['__ai_explanation']?.[fieldName] || 'Field Selected from the potential master record';
      if (_.isObject(label)) {
        label = JSON.stringify(label);
      } else {
        if (idReferencesMap[value]) {
          label = `${getRecordName(idReferencesMap[value])} (${label})`;
        }
      }
      if (_.isObject(value)) {
        value = JSON.stringify(value);
      }
      const id = `${fieldName}-radio-value-smart`;
      const checked = formState[fieldName] === id;
      return (
        <TableCell
          key={`${fieldName}-smart-merge-suggestion`}
          sx={{ backgroundColor: theme.palette.green.subtone1, overflowWrap: 'anywhere' }}
        >
          {loadingAiRecommendation === true ? (
            <Box display={'flex'}>
              <CircularProgress sx={{ mx: 'auto' }} size={'20px'} />
            </Box>
          ) : (
            <SmartMergeToolTip title={fieldName} description={aiExplanation} show={!!aiExplanation}>
              <Box>
                <Radio
                  checked={checked}
                  value={value}
                  name={fieldName}
                  id={`${fieldName}-radio-value-smart`}
                  onClick={() => handleSetUpFormState(fieldName, 'smart')}
                />
                <Typography variant="labelMedium12">{label}</Typography>
              </Box>
            </SmartMergeToolTip>
          )}
        </TableCell>
      );
    },
    [aiRecommendation, formState, handleSetUpFormState, idReferencesMap, loadingAiRecommendation, records],
  );

  const processRecords = useCallback(
    (records: any[], fieldNames: string[]) => {
      const fields = fieldNames.map((fieldName: string) => {
        const components = [];
        components.push(getFieldLabel(fieldName));
        const fieldValues = records.map((record: any, index: number) => {
          return getFieldValue(record, fieldName, index);
        });
        components.push(...fieldValues);
        components.push(getSmartMergeValue(fieldName));
        return <TableRow key={`${fieldName}-row`}>{components}</TableRow>;
      });
      return fields;
    },
    [getFieldLabel, getFieldValue, getSmartMergeValue],
  );

  return <>{processRecords(records, fieldNames)}</>;
};
