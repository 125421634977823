import { useCallback } from 'react';
import { useReactFlow } from 'reactflow';
import { useDeduplicationResult } from 'store/deduplicationResult/hook';
import { DeduplicationResult } from 'store/deduplicationResult/types';
import { useGraphRender } from 'store/graphRender/hooks';
import { useMigration } from 'store/migration/hooks';
import { Edge, EdgeType } from '../../classes/Edge';

export const getMasterRecordIds = (records: DeduplicationResult[]) => {
  const comparisonMap: { [x: string]: string } = {};
  records.forEach((record) => {
    comparisonMap[record.id] = record.master_record_id;
  });
  let masterRecords = records.map((record) => record.master_record_id).filter((x) => x);
  const masterRecordsSet = new Set(masterRecords);
  let changes = true;
  let toDelete: string[] = [];
  let toMaintain: string[] = [];
  const maxCycles = 10;
  let cycle = 0;
  while (changes === true && cycle < maxCycles) {
    cycle++;
    changes = false;
    toDelete = [];
    toMaintain = [];
    for (let i = 0; i < masterRecords.length; i++) {
      for (let j = i + 1; j < masterRecords.length; j++) {
        if (
          comparisonMap[`${masterRecords[i]}-${masterRecords[j]}`] ||
          comparisonMap[`${masterRecords[j]}-${masterRecords[i]}`]
        ) {
          const masterId =
            comparisonMap[`${masterRecords[i]}-${masterRecords[j]}`] ||
            comparisonMap[`${masterRecords[j]}-${masterRecords[i]}`];
          toMaintain.push(masterId);
          toDelete.push(masterId === masterRecords[i] ? masterRecords[j] : masterRecords[i]);
        }
      }
    }
    if (toDelete.length > 0) {
      changes = true;
      toDelete.forEach((id) => {
        if (!toMaintain.includes(id)) {
          masterRecordsSet.delete(id);
        }
      });
      masterRecords = Array.from(masterRecordsSet);
    }
  }
  return masterRecords;
};

export const useDuplicationDataHandlerUtils = () => {
  const {
    setShowDuplicationSnackBar,
    data: { hoverNodeId, duplicateQuickFilter },
  } = useGraphRender();
  const { getDeduplicationResultByMigration, getDeduplicationResultByIds } = useDeduplicationResult();
  const {
    data: { migrationId },
  } = useMigration();
  const reactFlow = useReactFlow();

  const getDuplicationCountMap = useCallback((data: DeduplicationResult[]) => {
    const duplicationCounterMap: { [x: string]: number } = {};
    data.forEach((record) => {
      if (duplicationCounterMap[record.record_a_id]) {
        duplicationCounterMap[record.record_a_id] = duplicationCounterMap[record.record_a_id] + 1;
      } else {
        duplicationCounterMap[record.record_a_id] = 1;
      }
      if (duplicationCounterMap[record.record_b_id]) {
        duplicationCounterMap[record.record_b_id] = duplicationCounterMap[record.record_b_id] + 1;
      } else {
        duplicationCounterMap[record.record_b_id] = 1;
      }
    });
    return duplicationCounterMap;
  }, []);

  const getDuplicateEdges = useCallback((data: DeduplicationResult[], schemaName: string, hidden = false) => {
    return data.map((record) => {
      const id = `duplicate-edge-${record.record_a_id}-${record.record_b_id}`;
      const source = `${schemaName}.${record.record_a_id}`;
      const target = `${schemaName}.${record.record_b_id}`;
      return new Edge(id, EdgeType.duplicate, source, target, { hidden: hidden });
    });
  }, []);

  const getUniqueIdArray = useCallback((data: DeduplicationResult[]) => {
    const ids: string[] = data.map((record) => [record.record_a_id, record.record_b_id]).flat();
    const idsSet = new Set(ids);
    const uniqueIds = Array.from(idsSet);
    return uniqueIds;
  }, []);

  const checkDeduplicationResult = useCallback(() => {
    if (!(migrationId === undefined || migrationId.trim() === '')) {
      getDeduplicationResultByMigration({
        migrationId,
        onSuccess: (res) => {
          if (res.length > 0) {
            setShowDuplicationSnackBar(true);
          }
        },
        options: { limit: 40, tables: duplicateQuickFilter.selectedTables },
      });
    }
  }, [duplicateQuickFilter.selectedTables, getDeduplicationResultByMigration, migrationId, setShowDuplicationSnackBar]);

  const checkDeduplicationResultByIds = useCallback(
    (ids: string[]) => {
      if (!(migrationId === undefined || migrationId.trim() === '')) {
        getDeduplicationResultByIds({
          migrationId,
          data: { ids: ids },
          onSuccess: (res) => {
            if (res.length > 0) {
              setShowDuplicationSnackBar(true);
            }
          },
        });
      }
    },
    [getDeduplicationResultByIds, migrationId, setShowDuplicationSnackBar],
  );

  const getTraversedNodesAndEdgesOnHover = useCallback(
    (nodeId?: string) => {
      const duplicatedEdges = reactFlow.getEdges().filter((edge) => edge.data.id?.startsWith('duplicate-edge'));
      const visitedNode = new Set<string>();
      const traversedEdges = new Set<string>();
      const rootNode = nodeId || hoverNodeId;
      let nodesToProcess = [rootNode];
      const maxCycles = 10;
      let cycle = 0;
      while (nodesToProcess.length > 0 && cycle < maxCycles) {
        cycle++;
        const newNodeToProcess: string[] = [];
        nodesToProcess.forEach((nodeId) => {
          visitedNode.add(nodeId);
          const connectedEdges = duplicatedEdges.filter((edge) => edge.source === nodeId || edge.target === nodeId);
          connectedEdges.forEach((edge) => {
            traversedEdges.add(edge.data.id);
            if (edge.source !== nodeId && !visitedNode.has(edge.source)) {
              newNodeToProcess.push(edge.source);
            }
            if (edge.target !== nodeId && !visitedNode.has(edge.target)) {
              newNodeToProcess.push(edge.target);
            }
          });
        });
        nodesToProcess = newNodeToProcess;
      }
      return { traversedEdges: Array.from(traversedEdges), visitedNodes: Array.from(visitedNode) };
    },
    [hoverNodeId, reactFlow],
  );

  return {
    checkDeduplicationResult,
    getTraversedNodesAndEdgesOnHover,
    checkDeduplicationResultByIds,
    getDuplicationCountMap,
    getDuplicateEdges,
    getUniqueIdArray,
  };
};
