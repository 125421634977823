import React, { FC } from 'react';
import { Typography, Button, Box } from '@mui/material';
import CustomDialog from 'components/CustomDialog/CustomDialog';

interface DeleteFieldModalProps {
  open: boolean;
  onClose: () => void;
  objectField: string;
  schemaField: string;
  onDelete: () => void;
}

const DeleteFieldMappingModal: FC<DeleteFieldModalProps> = ({ open, onClose, objectField, schemaField, onDelete }) => {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title="Delete Field Mapping"
      actions={
        <>
          <Button onClick={onClose} color="inherit">
            Cancel Keep Mapping
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: 'red.main' }}
            onClick={() => {
              onDelete();
              onClose();
            }}
          >
            Delete Field Mapping
          </Button>
        </>
      }
    >
      <Typography color={'neutral.main'}>Are you sure you want to delete this field mapping?</Typography>
      <Box sx={{ mt: 2, color: 'neutral.n400' }}>
        <Typography>
          Field Object: {objectField} ⇄ Identified Schema: {schemaField}
        </Typography>
      </Box>
    </CustomDialog>
  );
};

export default DeleteFieldMappingModal;
