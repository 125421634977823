import { Box, Divider, Typography } from '@mui/material';
import { ConfidenceScore } from 'components/ConfidenceScore';
import theme from 'core/theme';
import { FC } from 'react';
import { Node } from 'reactflow';

interface LeadRecordCardProps {
  node: Node<any>;
}

export const LeadRecordCard: FC<LeadRecordCardProps> = ({ node }) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={1}
      sx={{ backgroundColor: theme.palette.neutral.subtone310, borderRadius: '4px', padding: '10px' }}
    >
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant="labelBold14">{node.data.Name}</Typography>
        <ConfidenceScore confidenceScore={node.data.confidence_score} />
      </Box>
      <Divider />
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant="labelRegular12" sx={{ color: theme.palette.neutral.darkGray }}>
          Title
        </Typography>
        <Typography variant="labelRegular12">{node.data.Title}</Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant="labelRegular12" sx={{ color: theme.palette.neutral.darkGray }}>
          Company
        </Typography>
        <Typography variant="labelRegular12">{node.data.Company}</Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography variant="labelRegular12" sx={{ color: theme.palette.neutral.darkGray }}>
          Phone
        </Typography>
        <Typography variant="labelRegular12">{node.data.Phone || node.data.MobilePhone}</Typography>
      </Box>
    </Box>
  );
};
