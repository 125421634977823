import React, { FC } from 'react';
import { Avatar, Box, Card, CardContent } from '@mui/material';

export type IconCardProps = {
  icon: React.ReactNode;
  children?: React.ReactNode;
  iconBorder?: React.CSSProperties['border'];
};

export const IconCard: FC<IconCardProps> = ({ icon, children, iconBorder }) => {
  return (
    <Box position={'relative'} width={'100%'} height={'100%'}>
      <Card elevation={0}>
        <Box top={-15} left={0} right={0} display={'flex'} position={'absolute'} justifyContent={'center'}>
          <Avatar
            sx={{
              border: iconBorder,
              transform: 'scale(.8)',
              backgroundColor: 'white',
            }}
          >
            {icon}
          </Avatar>
        </Box>
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {children}
        </CardContent>
      </Card>
    </Box>
  );
};
