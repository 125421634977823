import { useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TextRotateUpIcon from '@mui/icons-material/TextRotateUp';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TextRotationDownIcon from '@mui/icons-material/TextRotationDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const options = [
  { name: 'Last Updated', icon: AccessTimeIcon, value: { fieldName: 'LastModifiedDate' } },
  { name: 'Last Added', icon: CalendarTodayIcon, value: { fieldName: 'CreatedDate' } },
  { name: 'Alphabetical Dsc. (Z-A)', icon: TextRotateUpIcon },
  { name: 'Alphabetical Asc. (A-Z)', icon: TextRotationDownIcon },
];

export function SortTab() {
  const [selectedOrderByIndex, setSelectedOrderByIndex] = useState(0);
  const [anchorElOrderBy, setAnchorElOrderBy] = useState<null | HTMLElement>(null);

  const handleClickOrderByButton = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOrderBy(event.currentTarget);
  };

  const handleMenuCloseOrderByMenu = () => {
    setAnchorElOrderBy(null);
  };

  const handleChangeOrderBy = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedOrderByIndex(index);
    setAnchorElOrderBy(null);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="labelMedium14" sx={{ color: 'neutral.n400' }}>
        Sort:{' '}
      </Typography>
      <Button
        variant="text"
        aria-haspopup="true"
        aria-expanded={anchorElOrderBy ? 'true' : undefined}
        aria-controls={anchorElOrderBy ? 'basic-menu' : undefined}
        sx={{
          color: 'black',
          fontSize: '14px',
          '&:hover': {
            color: 'black',
            boxShadow: 'none',
            backgroundColor: 'initial',
          },
        }}
        onClick={handleClickOrderByButton}
        endIcon={anchorElOrderBy ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {options[selectedOrderByIndex].name}
      </Button>
      <Menu
        id="sort-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorElOrderBy}
        open={Boolean(anchorElOrderBy)}
        onClose={handleMenuCloseOrderByMenu}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.name}
            selected={index === selectedOrderByIndex}
            onClick={(event) => handleChangeOrderBy(event, index)}
            sx={{ marginBottom: '5px' }}
          >
            <option.icon sx={{ marginRight: '12px' }} />
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
