import { useState } from 'react';

interface FileData {
  name: string;
  size: string;
  content: any[];
  showPreview: boolean;
}

const mockFileContent = () => {
  return [
    { column1: 'Data1', column2: 'Data2', column3: 'Data3' },
    { column1: 'Data4', column2: 'Data5', column3: 'Data6' },
  ];
};

export const useFileUploader = () => {
  const [files, setFiles] = useState<FileData[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number[]>([]);

  const processFiles = (newFiles: File[]) => {
    const updatedFiles = newFiles.map((file) => ({
      name: file.name,
      size: `${(file.size / 1024).toFixed(2)} KB`,
      content: mockFileContent(),
      showPreview: false,
    }));

    setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
    setUploadProgress([...uploadProgress, ...newFiles.map(() => 0)]);

    newFiles.forEach((file, index) => {
      simulateUpload(index + files.length);
    });
  };

  const simulateUpload = (index: number) => {
    let progress = 0;
    const interval = setInterval(() => {
      progress += 10;
      setUploadProgress((prevProgress) => {
        const updatedProgress = [...prevProgress];
        updatedProgress[index] = progress;
        return updatedProgress;
      });
      if (progress >= 100) {
        clearInterval(interval);
      }
    }, 300);
  };

  const togglePreview = (index: number) => {
    setFiles((prevFiles) =>
      prevFiles.map((file, i) => (i === index ? { ...file, showPreview: !file.showPreview } : file)),
    );
  };

  const deleteFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setUploadProgress((prevProgress) => prevProgress.filter((_, i) => i !== index));
  };

  return {
    files,
    uploadProgress,
    processFiles,
    togglePreview,
    deleteFile,
  };
};
