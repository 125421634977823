import { Box, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/informativeExclamation.svg';
import theme from 'core/theme';

const defaultLabelSx: SxProps<Theme> = {
  paddingBottom: 0.5,
  color: theme.palette.neutral.main,
};

interface LabelComponentProps {
  label: string;
  toolTipText: string;
}

export const LabelComponent: FC<LabelComponentProps> = (props) => {
  const { label, toolTipText } = props;

  return (
    <Box display={'flex'} flexDirection={'row'} gap={0.5} alignItems={'start'}>
      <Typography sx={defaultLabelSx} variant="labelRegular12">
        {label}
      </Typography>
      <Tooltip title={toolTipText} placement={'right'}>
        <InfoIcon />
      </Tooltip>
    </Box>
  );
};
