import { FC, useMemo } from 'react';
import { useGetMigrationTableDataByIdQuery } from 'store/migration/api';

export type DataAnomalyTableTitleProps = {
  migrationId: string;
  table: string;
  recordId: string;
};

export const DataAnomalyTableTitle: FC<DataAnomalyTableTitleProps> = ({ recordId, table, migrationId }) => {
  const recordQuery = useGetMigrationTableDataByIdQuery({
    migrationId: migrationId,
    tableId: table,
    skip: 0,
    take: 1,
    conditions: [{ field: 'Id', operator: '=', value: `'${recordId}'` }],
  });

  const label = useMemo(() => {
    if (recordQuery.data && Array.isArray(recordQuery.data) && recordQuery.data.length > 0) {
      const record = recordQuery.data[0];
      const label =
        record['Name'] || `${record['FirstName'] || ''} ${record['LastName'] || ''}`.trim() || record['Email'];
      if (label && label?.trim() !== '') {
        return label;
      }
      return recordId;
    }
    return recordId;
  }, [recordId, recordQuery.data]);

  return (
    <>
      Data Anomalies <span style={{ fontSize: 14, paddingLeft: 5 }}>{label}</span>
    </>
  );
};
