import { FC, useEffect, useMemo } from 'react';
import { DataAnomalyData } from './DataAnomalyTable';
import { CircularProgress, Tooltip, Typography } from '@mui/material';
import { Show } from 'components/show';
import { useLazyGetDataAnomalySummaryQuery } from 'store/dataAnomaly/api';
import { useDashboard } from 'store/dashboard/hook';

export type AnomalySummaryCellProps = {
  row: DataAnomalyData;
};

export const AnomalySummaryCell: FC<AnomalySummaryCellProps> = ({ row }) => {
  const {
    data: { selectedMigrationId },
  } = useDashboard();
  const [getDataAnomalySummary, dataAnomalyQuery] = useLazyGetDataAnomalySummaryQuery();

  useEffect(() => {
    if (!row.summary) {
      const payload = {
        ruleId: row.id,
        details: row.detail ? [row.detail] : [],
        recordId: row.recordId,
        tableName: row.tableName,
        record: row.record,
      };
      getDataAnomalySummary(
        {
          migrationId: selectedMigrationId,
          payload,
        },
        true,
      );
    }
  }, [
    getDataAnomalySummary,
    row.detail,
    row.id,
    row.record,
    row.recordId,
    row.summary,
    row.tableName,
    selectedMigrationId,
  ]);

  const summaryValue = useMemo(() => {
    return dataAnomalyQuery.data?.summary || row.summary || row.detail;
  }, [dataAnomalyQuery.data?.summary, row.detail, row.summary]);

  return (
    <Tooltip title={summaryValue}>
      <Typography noWrap variant="p14" textAlign={'center'}>
        <Show when={!!summaryValue} fallback={<CircularProgress size={'20px'} />}>
          {summaryValue}
        </Show>
      </Typography>
    </Tooltip>
  );
};
