import { Box, Tooltip, TooltipProps, Typography, styled } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { FC, PropsWithChildren } from 'react';

export interface SmartMergeToolTipProps {
  title: string;
  description?: string;
  show?: boolean;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.blue.dark,
    color: theme.palette.neutral.white,
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
  },
}));

export const SmartMergeToolTip: FC<PropsWithChildren<SmartMergeToolTipProps>> = (props) => {
  const { title, description, show } = props;
  return (
    <>
      {show === true ? (
        <HtmlTooltip
          title={
            <>
              <Typography fontWeight={'bold'} variant="labelMedium12">
                {title}
              </Typography>
              <p>{description}</p>
            </>
          }
          placement="bottom"
        >
          <Box>{props.children}</Box>
        </HtmlTooltip>
      ) : (
        <Box>{props.children}</Box>
      )}
    </>
  );
};
