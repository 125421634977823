import moment from 'moment';

export const getDates = (startDate: Date, endDate: Date): Date[] => {
  const dates: Date[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export const getHumanReadableDate = (date: string): string => {
  // should be like this 12 Jan 2021, 12:00 PM
  if (!date) {
    return '';
  }
  try {
    return moment(date).format('DD MMM YYYY, hh:mm A');
  } catch (e) {
    return date;
  }
};

export const getMonthName = (month: number): string => {
  return moment()
    .month(month - 1)
    .format('MMMM');
};

export const addDaySuffix = (day: number) => {
  if (day >= 11 && day <= 13) {
    return day + 'th';
  }
  switch (day % 10) {
    case 1:
      return day + 'st';
    case 2:
      return day + 'nd';
    case 3:
      return day + 'rd';
    default:
      return day + 'th';
  }
};
