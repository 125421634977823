import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { styled } from '@mui/material/styles';
import React from 'react';

interface CustomPickerDayProps extends PickersDayProps<any> {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
}

export const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.subtone2,
    color: theme.palette.common.black,
    '&:hover, &:focus': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.subtone1,
      borderRadius: '5px',
    },
  }),
  ...(isFirstDay && {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    '&:hover, &:focus': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '5px',
    },
  }),
  ...(isLastDay && {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    '&:hover, &:focus': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '5px',
    },
  }),
  '&.MuiPickersDay-today': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderRadius: '5px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.common.white,
    },
  },
})) as React.ComponentType<CustomPickerDayProps>;
