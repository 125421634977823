import { Grid } from '@mui/material';
import { ControlledCustomDropdown, CustomDropdown } from 'components/CustomDropdown';
import { GridItem } from 'components/ui';
import { FC, useCallback, useMemo } from 'react';
import { ToggleLookUpValueSelectorButton } from '../ToggleLookUpValueSelectorButton';
import { LookUpValuePlaceholder } from '../LookUpValuePlaceholder';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import {
  ConditionalValue,
  FieldReference,
  FunctionValue,
  LookUpValue,
  PrimitiveValue,
  SubQueryValue,
} from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { ConditionType } from 'pages/RuleForm/types';
import {
  FUNCTION_ENUM,
  LOOK_UP_FUNCTION_OPTIONS,
  CONDITIONAL_VALUE_OPTIONS,
  PRIMITIVE_OPTIONS,
  BOOLEAN_OPTIONS,
  NULL_OPTIONS,
} from '../Constants';
import { MigrationFieldValuesDropDown } from 'components/MigrationComponents/MigrationFieldValuesDropDown';
import { CustomInput } from 'components/CustomInput';
import { Option } from 'store/ruleForm/types';

interface ConditionalValueProps {
  targetPath: string;
  rootConditionalPath: string;
  previousValueComponent?: ConditionalValue;
  previousOperator?: string | null;
  conditionalValueComponent: ConditionalValue;
  conditionType: ConditionType;
  index: number;
  allowedValueTypes: RuleComponentType[];
  allowedValueFunctions: FUNCTION_ENUM[];
  tableName: string;
  fieldOptions: Option[];
  xmlFilterType?: string[];
}

export const ConditionalValueInput: FC<ConditionalValueProps> = (props) => {
  const {
    setFieldValue,
    getFieldOptionsByMigrationAndTable,
    handleOnSelectLookUpField,
    fieldForeignRefOptions,
    subQueries,
    selectedMigration,
    showSubQueryModal,
    subQueryModalIndex,
  } = useRuleForm();
  const {
    targetPath,
    conditionalValueComponent,
    conditionType,
    index,
    allowedValueTypes,
    allowedValueFunctions,
    fieldOptions,
    xmlFilterType,
    previousValueComponent,
    previousOperator,
    tableName,
  } = props;

  const shouldRenderPickListComponent = useMemo(() => {
    if (
      previousValueComponent?.type !== RuleComponentType.FIELD_REFERENCE ||
      conditionalValueComponent.type !== RuleComponentType.PRIMITIVE_VALUE ||
      !['=', '!='].includes(previousOperator || '')
    ) {
      return false;
    }
    const fieldReferenceSelected = (previousValueComponent as FieldReference).value;
    const fieldOption = fieldOptions.find((option) => option.value === fieldReferenceSelected);
    if (!fieldOption) {
      return false;
    }
    return fieldOption.type === 'picklist';
  }, [conditionalValueComponent.type, fieldOptions, previousOperator, previousValueComponent]);

  const subQueryIdOptions = useMemo(() => {
    const aliasOptions = subQueries.map((subQuery) => ({ label: subQuery.alias, value: subQuery.alias }));
    if (showSubQueryModal) {
      aliasOptions.splice(subQueryModalIndex, aliasOptions.length);
      const openSubQuery = subQueries[subQueryModalIndex];
      const openAlias = openSubQuery.alias;
      const openAliasIndex = aliasOptions.findIndex((option) => option.label === openAlias);
      if (openAliasIndex != -1) {
        aliasOptions.splice(openAliasIndex, 1);
      }
    }
    return aliasOptions;
  }, [showSubQueryModal, subQueries, subQueryModalIndex]);

  const conditionalFunctionOptionsFiltered = useMemo(() => {
    let values = LOOK_UP_FUNCTION_OPTIONS;
    if (allowedValueFunctions.length !== 0) {
      values = LOOK_UP_FUNCTION_OPTIONS.filter((option) =>
        allowedValueFunctions.includes(option.value as FUNCTION_ENUM),
      );
    }
    if (xmlFilterType && xmlFilterType.length > 0) {
      values = values?.filter((values) => {
        return values?.resultType.some((type) => xmlFilterType?.includes(type));
      });
    }
    return values;
  }, [allowedValueFunctions, xmlFilterType]);

  const conditionalValuesOptionsFiltered = useMemo(() => {
    let values = CONDITIONAL_VALUE_OPTIONS;
    if (allowedValueTypes.length !== 0) {
      values = CONDITIONAL_VALUE_OPTIONS.filter((option) => allowedValueTypes.includes(option.value));
    }
    if (!conditionalFunctionOptionsFiltered || conditionalFunctionOptionsFiltered.length === 0) {
      values = values.filter((option) => option.value != RuleComponentType.FUNCTION_VALUE);
    }

    return values;
  }, [allowedValueTypes, conditionalFunctionOptionsFiltered]);

  const getConditionTypeDropdown = useCallback(() => {
    return (
      <CustomDropdown
        value={conditionalValueComponent.type}
        options={conditionalValuesOptionsFiltered || []}
        placeholder={'Value type'}
        id={`value-type-${targetPath}`}
        onSelect={function (value: RuleComponentType): void {
          if (value === RuleComponentType.PRIMITIVE_VALUE) {
            const initialPrimitiveValue = { type: RuleComponentType.PRIMITIVE_VALUE, value: '' };
            setFieldValue({ path: targetPath, value: initialPrimitiveValue });
          }
          if (value === RuleComponentType.FUNCTION_VALUE) {
            const initialFunctionValue = {
              type: RuleComponentType.FUNCTION_VALUE,
              function: null,
              numberOfParams: LOOK_UP_FUNCTION_OPTIONS[0].paramNumber,
              value: [],
            };
            setFieldValue({ path: targetPath, value: initialFunctionValue });
          }
          if (value === RuleComponentType.FIELD_REFERENCE) {
            const initialFieldReference: FieldReference = {
              type: RuleComponentType.FIELD_REFERENCE,
              value: 'Id',
            };
            setFieldValue({ path: targetPath, value: initialFieldReference });
          }
          if (value === RuleComponentType.SUB_QUERY_VALUE) {
            const initialSubQueryValue: SubQueryValue = {
              type: RuleComponentType.SUB_QUERY_VALUE,
              subQueryId: '',
              value: '',
            };
            setFieldValue({ path: targetPath, value: initialSubQueryValue });
          }
        }}
      />
    );
  }, [conditionalValueComponent.type, conditionalValuesOptionsFiltered, setFieldValue, targetPath]);

  if (conditionalValueComponent.type === RuleComponentType.FUNCTION_VALUE) {
    const lookUpValueComponent = conditionalValueComponent as FunctionValue;
    let options = conditionType === ConditionType.WHERE ? fieldOptions : fieldForeignRefOptions;
    const functionOption = LOOK_UP_FUNCTION_OPTIONS.find((option) => option.value === lookUpValueComponent.function);
    if (functionOption) {
      const functionAllowType = functionOption.acceptType;
      options = options?.filter(
        (option) => functionAllowType.includes(option.xsd_type || '') || functionAllowType.includes('*'),
      );
    }
    return (
      <Grid display={'flex'} width={'100%'} gap={1}>
        <GridItem flex={3}>{getConditionTypeDropdown()}</GridItem>
        <GridItem flex={3}>
          <ControlledCustomDropdown<string>
            id={`${targetPath}.function`}
            placeholder="Function"
            fallbackOption={{ label: 'Select a Value', value: '' }}
            options={conditionalFunctionOptionsFiltered.map((item) => ({ value: item.value, label: item.label }))}
            sx={{ backgroundColor: 'neutral.white', width: '100%' }}
            onSelect={async (value) => setFieldValue({ path: `${targetPath}.function`, value: value })}
            value={lookUpValueComponent.function || null}
          />
        </GridItem>
        <GridItem flex={5}>
          {lookUpValueComponent.value[0]?.value &&
          lookUpValueComponent.value[0]?.type === RuleComponentType.LOOKUP_VALUE ? (
            <Grid display={'flex'}>
              <GridItem flex={10}>
                <LookUpValuePlaceholder
                  lookUpComponents={lookUpValueComponent.value[0]?.value || []}
                  index={index}
                  conditionType={conditionType}
                />
              </GridItem>
              <GridItem flex={1}>
                <ToggleLookUpValueSelectorButton
                  index={index}
                  lookUpComponents={lookUpValueComponent.value[0]?.value || []}
                  conditionType={conditionType}
                  targetPath={`${targetPath}.value[0]`}
                />
              </GridItem>
            </Grid>
          ) : (
            <ControlledCustomDropdown<string>
              id={`${targetPath}.value[0]`}
              placeholder="Field Name"
              value={lookUpValueComponent?.value[0]?.value || ''}
              options={options || []}
              sx={{ backgroundColor: 'neutral.white' }}
              fallbackOption={{ label: 'Select a Value', value: '' }}
              onSelect={(value) => handleOnSelectLookUpField(`${targetPath}.value[0]`, value, conditionType)}
            />
          )}
        </GridItem>
      </Grid>
    );
  }
  if (conditionalValueComponent.type === RuleComponentType.PRIMITIVE_VALUE) {
    const primitiveValue = conditionalValueComponent as PrimitiveValue;
    let allowedPrimitiveValues = PRIMITIVE_OPTIONS;
    if (xmlFilterType) {
      allowedPrimitiveValues = allowedPrimitiveValues.filter((option) =>
        option.validTypes.some((type) => xmlFilterType.includes(type)),
      );
    }
    const isNullOrBooleanFormat = primitiveValue?.format === 'null' || primitiveValue?.format === 'boolean';
    const nullOrBooleanOptions =
      isNullOrBooleanFormat && primitiveValue?.format === 'boolean' ? BOOLEAN_OPTIONS : NULL_OPTIONS;

    if (shouldRenderPickListComponent) {
      return (
        <Grid display={'flex'} width={'100%'} gap={1}>
          <GridItem flex={1}>{getConditionTypeDropdown()}</GridItem>
          <GridItem flex={3}>
            <MigrationFieldValuesDropDown
              id={`${targetPath}.value`}
              value={primitiveValue?.value}
              migrationId={selectedMigration}
              table={tableName}
              fieldName={previousValueComponent?.value}
              placeholder={'Select a Value'}
              onChange={(value) => setFieldValue({ path: `${targetPath}.value`, value })}
            />
          </GridItem>
        </Grid>
      );
    }

    return (
      <Grid display={'flex'} width={'100%'} gap={1}>
        <GridItem flex={4}>{getConditionTypeDropdown()}</GridItem>
        <GridItem flex={5}>
          <ControlledCustomDropdown<string>
            id={`${targetPath}.format`}
            placeholder="Format"
            value={primitiveValue?.format || ''}
            options={allowedPrimitiveValues || []}
            sx={{ backgroundColor: 'neutral.white' }}
            fallbackOption={{ label: 'Format', value: '' }}
            onSelect={(value) => setFieldValue({ path: `${targetPath}.format`, value })}
          />
        </GridItem>
        <GridItem flex={7}>
          {isNullOrBooleanFormat ? (
            <ControlledCustomDropdown<string>
              id={`${targetPath}.value`}
              placeholder="value"
              value={primitiveValue?.value || ''}
              options={nullOrBooleanOptions || []}
              sx={{ backgroundColor: 'neutral.white' }}
              fallbackOption={{ label: 'Select Value', value: '' }}
              onSelect={(value) => setFieldValue({ path: `${targetPath}.value`, value })}
            />
          ) : (
            <CustomInput
              id={targetPath}
              name={targetPath}
              value={primitiveValue.value}
              type={primitiveValue?.format}
              onChange={(e) => setFieldValue({ path: `${targetPath}.value`, value: e.target.value })}
            />
          )}
        </GridItem>
      </Grid>
    );
  }
  if (conditionalValueComponent.type === RuleComponentType.FIELD_REFERENCE) {
    const fieldReference = conditionalValueComponent as PrimitiveValue;
    let options = conditionType === ConditionType.WHERE ? fieldOptions : fieldForeignRefOptions;
    if (xmlFilterType && options) {
      options = options.filter((option) => xmlFilterType.includes(option?.xsd_type || '') || option.relationshipName);
    }
    return (
      <Grid display={'flex'} width={'100%'} gap={1}>
        <GridItem flex={3}>{getConditionTypeDropdown()}</GridItem>
        <GridItem flex={10}>
          <ControlledCustomDropdown<string>
            id={`${targetPath}`}
            placeholder="Field Name"
            value={fieldReference?.value || ''}
            options={options || []}
            sx={{ backgroundColor: 'neutral.white' }}
            fallbackOption={{ label: 'Select a Value', value: '' }}
            onSelect={(value) => handleOnSelectLookUpField(`${targetPath}`, value, conditionType)}
          />
        </GridItem>
      </Grid>
    );
  }
  if (conditionalValueComponent.type === RuleComponentType.LOOKUP_VALUE) {
    const lookUpValue = conditionalValueComponent as LookUpValue;
    return (
      <Grid display={'flex'} width={'100%'} gap={1}>
        <GridItem flex={3}>{getConditionTypeDropdown()}</GridItem>
        <GridItem flex={10}>
          <LookUpValuePlaceholder
            lookUpComponents={lookUpValue.value || []}
            index={index}
            conditionType={conditionType}
          />
        </GridItem>
        <GridItem flex={1}>
          <ToggleLookUpValueSelectorButton
            index={index}
            lookUpComponents={lookUpValue.value || []}
            conditionType={conditionType}
            targetPath={`${targetPath}`}
          />
        </GridItem>
      </Grid>
    );
  }
  if (conditionalValueComponent.type === RuleComponentType.SUB_QUERY_VALUE) {
    const subQueryValue = conditionalValueComponent as SubQueryValue;
    const subQueryObject = subQueries.find((query) => query.alias === subQueryValue.subQueryId);
    let exportFieldOptions = getFieldOptionsByMigrationAndTable(selectedMigration, subQueryObject?.table || '', false);
    if (xmlFilterType && exportFieldOptions) {
      exportFieldOptions = exportFieldOptions.filter(
        (option) => xmlFilterType.includes(option?.xsd_type || '') || option.relationshipName,
      );
    }

    return (
      <Grid display={'flex'} width={'100%'} gap={1}>
        <GridItem flex={4}>{getConditionTypeDropdown()}</GridItem>
        <GridItem flex={5}>
          {
            <ControlledCustomDropdown<string>
              id={`${targetPath}.subQueryId`}
              placeholder="Alias"
              value={subQueryValue.subQueryId}
              options={subQueryIdOptions || []}
              sx={{ backgroundColor: 'neutral.white' }}
              fallbackOption={{ label: 'Select Alias', value: '' }}
              onSelect={(value: string) => setFieldValue({ path: `${targetPath}.subQueryId`, value })}
            />
          }
        </GridItem>
        <GridItem flex={7}>
          <ControlledCustomDropdown<string>
            id={`${targetPath}.value`}
            placeholder="Field"
            value={subQueryValue.value}
            options={exportFieldOptions || []}
            sx={{ backgroundColor: 'neutral.white' }}
            fallbackOption={{ label: 'Select Field', value: '' }}
            onSelect={(value) => setFieldValue({ path: `${targetPath}.value`, value })}
          />
        </GridItem>
      </Grid>
    );
  }
  return <></>;
};
