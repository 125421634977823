import { Box, Typography } from '@mui/material';
import { ReactComponent as NodeAccountDuplicateIcon } from 'assets/icons/graph/nodeAccountDuplicate.svg';
import { ReactComponent as NodeAccountMasterIcon } from 'assets/icons/graph/nodeAccountMaster.svg';
import { ReactComponent as NodeContactDuplicateIcon } from 'assets/icons/graph/nodeContactDuplicate.svg';
import { ReactComponent as NodeContactMasterIcon } from 'assets/icons/graph/nodeContactMaster.svg';
import { ReactComponent as NodeLeadDuplicateIcon } from 'assets/icons/graph/nodeLeadDuplicate.svg';
import { ReactComponent as NodeLeadMasterIcon } from 'assets/icons/graph/nodeLeadMaster.svg';
import { ReactComponent as NodeOpportunityDuplicateIcon } from 'assets/icons/graph/nodeOpportunityDuplicate.svg';
import { ReactComponent as NodeOpportunityIcon } from 'assets/icons/graph/nodeOpportunityMaster.svg';
import { FC, useMemo } from 'react';
import { useGraphRender } from 'store/graphRender/hooks';

export interface DuplicateNameLabelProps {
  nodeId: string;
  name: string;
  nodeLabel: string;
}

export const DuplicateNameLabel: FC<DuplicateNameLabelProps> = (props) => {
  const { nodeId, name, nodeLabel } = props;
  const {
    data: { masterRecordIds, schemaName },
  } = useGraphRender();

  const isItMasterRecord = useMemo(() => {
    const idWithoutSchema = nodeId.replace(`${schemaName}.`, '');
    return masterRecordIds.includes(`${idWithoutSchema}`);
  }, [masterRecordIds, nodeId, schemaName]);

  const Icon = useMemo(() => {
    if (nodeLabel.toLocaleLowerCase() === 'account') {
      return isItMasterRecord ? NodeAccountMasterIcon : NodeAccountDuplicateIcon;
    }
    if (nodeLabel.toLocaleLowerCase() === 'contact') {
      return isItMasterRecord ? NodeContactMasterIcon : NodeContactDuplicateIcon;
    }
    if (nodeLabel.toLocaleLowerCase() === 'lead') {
      return isItMasterRecord ? NodeLeadMasterIcon : NodeLeadDuplicateIcon;
    }
    if (nodeLabel.toLocaleLowerCase() === 'opportunity') {
      return isItMasterRecord ? NodeOpportunityIcon : NodeOpportunityDuplicateIcon;
    }
    return NodeAccountDuplicateIcon;
  }, [isItMasterRecord, nodeLabel]);
  return (
    <Box>
      <Icon style={{ marginRight: '2px' }} />
      <Typography variant="labelRegular10">
        <strong>{name}</strong>
      </Typography>
    </Box>
  );
};

export default DuplicateNameLabel;
