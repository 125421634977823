import { Box, Grid, Typography } from '@mui/material';
import { ReactComponent as DownIcon } from 'assets/icons/chevronDownBlue.svg';
import { ReactComponent as UpIcon } from 'assets/icons/chevronUpBlue.svg';
import { CustomInput } from 'components/CustomInput';
import theme from 'core/theme';
import { FC, useMemo, useState } from 'react';
import { RootCondition } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { useGraphRender } from 'store/graphRender/hooks';
import { buildPrimitiveCondition } from '../../../utils';
import { useMigration } from 'store/migration/hooks';
import { TableField } from 'store/migration/types';
import { MigrationFieldValuesDropDown } from '../../InputFields/MigrationFieldValuesDropDown';

const leadRootPath = 'lead.where';

export const LeadQuickFilter: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(!open);
  const {
    partialUpdateQuickGraphFilter,
    data: { quickFilterTmp },
  } = useGraphRender();

  const {
    data: { migrationTableFieldsAndLookUps, migrationId },
  } = useMigration();
  const fieldList = useMemo(() => {
    return migrationTableFieldsAndLookUps[`${migrationId}-Lead`]?.data?.['fields'] || [];
  }, [migrationId, migrationTableFieldsAndLookUps]);

  const fieldIndexMap = useMemo(() => {
    const fieldMap: { [x: string]: TableField } = {};
    fieldList.forEach((field) => {
      fieldMap[field.fieldName] = field;
    });
    return fieldMap;
  }, [fieldList]);

  return (
    <>
      <Grid item sx={{ backgroundColor: theme.palette.lightBg.main, borderRadius: '4px' }}>
        <Box
          onClick={handleOpen}
          sx={{ ':hover': { cursor: 'pointer' } }}
          display={'flex'}
          justifyContent={'space-between'}
          px={2}
          alignItems={'center'}
          height={'32px'}
        >
          <Typography
            variant="p12"
            sx={{ textTransform: 'uppercase', color: theme.palette.primary.main, userSelect: 'none' }}
          >
            Lead
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>{open ? <UpIcon /> : <DownIcon />}</Box>
        </Box>
      </Grid>
      {open && (
        <Grid container sx={{ display: 'flex', flexDirection: 'column', p: 1, gap: 1 }}>
          {fieldIndexMap['Status'] && (
            <MigrationFieldValuesDropDown
              label="Status"
              labelSx={{ color: theme.palette.neutral.main }}
              id={'status-lead-quick-filter'}
              value={(quickFilterTmp?.lead?.where?.[0] as RootCondition)?.value?.value || ''}
              onChange={(value: string) =>
                partialUpdateQuickGraphFilter({
                  path: `${leadRootPath}[0]`,
                  value: buildPrimitiveCondition('Status', '=', value, 'string'),
                })
              }
              migrationId={migrationId}
              table={'Lead'}
              fieldName={'Status'}
              placeholder={'Select a Status'}
            />
          )}
        </Grid>
      )}
    </>
  );
};
