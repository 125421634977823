import { createSlice, ActionReducerMapBuilder } from '@reduxjs/toolkit';

import {
  createDeduplicationConfig,
  updateDeduplicationConfig,
  getDeduplicationConfigByMigration,
  getDeduplicationConfigByMigrationAndTable,
  deleteDeduplicationConfigById,
} from './actions';
import { DeduplicationConfigState } from './types';
import { resetApp } from 'store/actions';
import _ from 'lodash';

export const initialState: DeduplicationConfigState = {
  loading: false,
  error: false,
  success: false,
  data: {
    createDeduplicationConfigState: { loading: false, error: false, success: false },
    updateDeduplicationConfigState: { loading: false, error: false, success: false },
    getDeduplicationConfigByMigrationState: { loading: false, error: false, success: false },
    getDeduplicationConfigByMigrationAndTableState: { loading: false, error: false, success: false },
    deleteDeduplicationConfigByIdState: { loading: false, error: false, success: false },
  },
};

const deduplicationConfigReducer = createSlice({
  name: 'deduplication-config',
  initialState: _.cloneDeep(initialState),
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<DeduplicationConfigState>): void => {
    builder.addCase(createDeduplicationConfig.pending, (state) => {
      state.data.createDeduplicationConfigState.loading = true;
      state.data.createDeduplicationConfigState.error = false;
      state.data.createDeduplicationConfigState.success = false;
      state.data.createDeduplicationConfigState.success = true;
    });

    builder.addCase(createDeduplicationConfig.fulfilled, (state, { payload }) => {
      state.data.createDeduplicationConfigState.loading = false;
      state.data.createDeduplicationConfigState.error = false;
      state.data.createDeduplicationConfigState.data = payload;
      state.data.createDeduplicationConfigState.success = true;
    });

    builder.addCase(createDeduplicationConfig.rejected, (state, { error }) => {
      state.data.createDeduplicationConfigState.loading = false;
      state.data.createDeduplicationConfigState.error = error?.message ?? true;
      state.data.createDeduplicationConfigState.success = false;
    });

    builder.addCase(updateDeduplicationConfig.pending, (state) => {
      state.data.updateDeduplicationConfigState.loading = true;
      state.data.updateDeduplicationConfigState.error = false;
      state.data.updateDeduplicationConfigState.success = false;
      state.data.updateDeduplicationConfigState.success = true;
    });

    builder.addCase(updateDeduplicationConfig.fulfilled, (state, { payload }) => {
      state.data.updateDeduplicationConfigState.loading = false;
      state.data.updateDeduplicationConfigState.error = false;
      state.data.updateDeduplicationConfigState.data = payload;
      state.data.updateDeduplicationConfigState.success = true;
    });

    builder.addCase(updateDeduplicationConfig.rejected, (state, { error }) => {
      state.data.updateDeduplicationConfigState.loading = false;
      state.data.updateDeduplicationConfigState.error = error?.message ?? true;
      state.data.updateDeduplicationConfigState.success = false;
    });

    builder.addCase(getDeduplicationConfigByMigration.pending, (state) => {
      state.data.getDeduplicationConfigByMigrationState.loading = true;
      state.data.getDeduplicationConfigByMigrationState.error = false;
      state.data.getDeduplicationConfigByMigrationState.success = false;
      state.data.getDeduplicationConfigByMigrationState.success = true;
    });

    builder.addCase(getDeduplicationConfigByMigration.fulfilled, (state, { payload }) => {
      state.data.getDeduplicationConfigByMigrationState.loading = false;
      state.data.getDeduplicationConfigByMigrationState.error = false;
      state.data.getDeduplicationConfigByMigrationState.data = payload;
      state.data.getDeduplicationConfigByMigrationState.success = true;
    });

    builder.addCase(getDeduplicationConfigByMigration.rejected, (state, { error }) => {
      state.data.getDeduplicationConfigByMigrationState.loading = false;
      state.data.getDeduplicationConfigByMigrationState.error = error?.message ?? true;
      state.data.getDeduplicationConfigByMigrationState.success = false;
    });

    builder.addCase(getDeduplicationConfigByMigrationAndTable.pending, (state) => {
      state.data.getDeduplicationConfigByMigrationAndTableState.loading = true;
      state.data.getDeduplicationConfigByMigrationAndTableState.error = false;
      state.data.getDeduplicationConfigByMigrationAndTableState.success = false;
      state.data.getDeduplicationConfigByMigrationAndTableState.success = true;
    });

    builder.addCase(getDeduplicationConfigByMigrationAndTable.fulfilled, (state, { payload }) => {
      state.data.getDeduplicationConfigByMigrationAndTableState.loading = false;
      state.data.getDeduplicationConfigByMigrationAndTableState.error = false;
      state.data.getDeduplicationConfigByMigrationAndTableState.data = payload;
      state.data.getDeduplicationConfigByMigrationAndTableState.success = true;
    });

    builder.addCase(getDeduplicationConfigByMigrationAndTable.rejected, (state, { error }) => {
      state.data.getDeduplicationConfigByMigrationAndTableState.loading = false;
      state.data.getDeduplicationConfigByMigrationAndTableState.error = error?.message ?? true;
      state.data.getDeduplicationConfigByMigrationAndTableState.success = false;
    });
    builder.addCase(deleteDeduplicationConfigById.pending, (state) => {
      state.data.deleteDeduplicationConfigByIdState.loading = true;
      state.data.deleteDeduplicationConfigByIdState.error = false;
      state.data.deleteDeduplicationConfigByIdState.success = false;
      state.data.deleteDeduplicationConfigByIdState.success = true;
    });

    builder.addCase(deleteDeduplicationConfigById.fulfilled, (state, { payload }) => {
      state.data.deleteDeduplicationConfigByIdState.loading = false;
      state.data.deleteDeduplicationConfigByIdState.error = false;
      state.data.deleteDeduplicationConfigByIdState.data = payload;
      state.data.deleteDeduplicationConfigByIdState.success = true;
    });

    builder.addCase(deleteDeduplicationConfigById.rejected, (state, { error }) => {
      state.data.deleteDeduplicationConfigByIdState.loading = false;
      state.data.deleteDeduplicationConfigByIdState.error = error?.message ?? true;
      state.data.deleteDeduplicationConfigByIdState.success = false;
    });

    builder.addCase(resetApp, (state) => {
      const initialStateTmp = _.cloneDeep(initialState);
      Object.keys(state).forEach((key) => {
        // @ts-ignore
        state[key] = initialStateTmp[key];
      });
    });
  },
});

export default deduplicationConfigReducer.reducer;
