import { Box, Grid } from '@mui/material';
import { GridItem } from 'components/ui';
import { FieldReference, LookUpTable, LookUpValue } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { FC, useCallback, useEffect, useState } from 'react';
import { ConditionType } from 'pages/RuleLibrary/types';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { FieldSelector } from './FieldSelector';

interface LookUpValueFormProps {
  targetPath: string;
  index: number;
  conditionType: ConditionType;
  conditionalValueComponent: LookUpValue;
  getConditionTypeDropdown: () => JSX.Element;
}

export const LookUpValueForm: FC<LookUpValueFormProps> = (props) => {
  const { conditionType, conditionalValueComponent, getConditionTypeDropdown, targetPath } = props;

  const { selectedMigration: migrationId, selectedTable: tableId, setFieldValue } = useRuleForm();

  const lookUpValue = conditionalValueComponent as LookUpValue;

  const [components, setComponents] = useState<(LookUpTable | FieldReference | any)[]>([]);
  const [values, setValuesSelected] = useState<string[]>([]);

  const handleOnSelectLookUp = useCallback(
    (value: (LookUpTable | FieldReference)[]) => {
      if (value[0].type === RuleComponentType.FIELD_REFERENCE) {
        setFieldValue({
          path: targetPath,
          value: { type: RuleComponentType.FIELD_REFERENCE, value: (value[0] as FieldReference).value },
        });
      } else {
        setFieldValue({
          path: targetPath,
          value: { type: RuleComponentType.LOOKUP_VALUE, value: value },
        });
      }
    },
    [setFieldValue, targetPath],
  );

  const handleSelectComponent = useCallback(
    (index: number, component: LookUpTable | FieldReference, value: string) => {
      const newComponents = [...components].slice(0, index);
      const newValues = [...values].slice(0, index);
      newValues.push(value);
      newComponents.push(component);

      if (component.type === RuleComponentType.LOOKUP_TABLE) {
        const currentComponent = component as LookUpTable;
        if (conditionType === ConditionType.HAVING && index == 0) {
          newValues.push(`${currentComponent.referenceTable}(${currentComponent.relationShipName})`);
          newComponents.push({ type: RuleComponentType.FIELD_REFERENCE, value: 'Id' });
        } else {
          newComponents.push({});
        }
      }
      setValuesSelected(newValues);
      setComponents(newComponents);
      handleOnSelectLookUp(newComponents);
    },
    [components, conditionType, handleOnSelectLookUp, values],
  );

  useEffect(() => {
    if (lookUpValue.value && lookUpValue.value.length > 0) {
      setComponents(lookUpValue.value);
      const valuesSelected = lookUpValue.value.map((component: LookUpTable | FieldReference, index: number) => {
        let labelValue = '';
        if (component.type === RuleComponentType.LOOKUP_TABLE) {
          const currentComponent = component as LookUpTable;
          if (conditionType === ConditionType.HAVING && index == 0) {
            labelValue = `${currentComponent.referenceTable}(${currentComponent.relationShipName})`;
          } else {
            labelValue = currentComponent.relationShipName;
          }
        } else if (component.type === RuleComponentType.FIELD_REFERENCE) {
          labelValue = (component as FieldReference).value;
        }
        return labelValue;
      });
      setValuesSelected(valuesSelected);
    }
  }, [conditionType, lookUpValue.value]);

  return (
    <Grid display={'flex'} flexDirection={'column'} width={'100%'} gap={1}>
      <Box display={'flex'}>
        <GridItem xs={6}>{getConditionTypeDropdown()}</GridItem>
      </Box>
      <Box display={'flex'} gap={1} flexDirection={'column'}>
        {components &&
          components.map((component, index) => {
            let tableName = tableId;
            if (index > 0) {
              const previous = components[index - 1] as LookUpTable;
              tableName = previous.referenceTable;
            }
            const currentValue = values[index];
            return (
              <GridItem key={`relationship-${conditionType}-selector-${targetPath}`} sx={{ textAlign: 'center' }}>
                <FieldSelector
                  migrationId={migrationId}
                  tableId={tableName}
                  index={index}
                  value={currentValue || undefined}
                  handleSelectComponent={(component: LookUpTable | FieldReference, value: string) =>
                    handleSelectComponent(index, component, value)
                  }
                />
              </GridItem>
            );
          })}
      </Box>
    </Grid>
  );
};
