import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { ReactComponent as CloseRowIcon } from 'assets/icons/close-row.svg';
import { FC, useCallback, useState, useMemo } from 'react';
import DataMenuView from './components/MenuViews/Data';
import DetailMenuView from './components/MenuViews/Detail';
import ActionMenuView from './components/MenuViews/Action';
import Activity from './components/MenuViews/Activity/Activity';

import { DataAnomaly } from './components/MenuViews/DataAnomaly/DataAnomaly';
import { useGraphRender } from 'store/graphRender/hooks';
import { getConfidenceScoreColor } from 'core/utils';
import { LoaderContainer } from 'components/Loader/ui';
import {
  AccountPanelMenuViews,
  DuplicateTablesQuickFilterOptions as NodeTypes,
  ContactPanelMenuViews,
  LeadPanelMenuViews,
  OpportunityPanelMenuViews,
} from 'store/graphRender/types';
import { Footer } from './components/Footer';
import ActivityFooter from './components/MenuViews/Activity/components/ActivityFooter';
import { HiddenNodePanelView } from '../components/HiddenNodePanelView';
import { Header } from './components/Header';
import EngagementMenuView from './components/MenuViews/ContactInsightsTab';
import OpportunityEngagementTab from './components/MenuViews/OpportunityInsightsTab';
import AccountInsightsTab from './components/MenuViews/AccountInsightsTab';

interface AccountPanelProps {
  node: any;
  toggleOpen: (value: boolean) => void;
  open: boolean;
  migrationId: string;
  nodeData: any;
  referencedUsers: any;
}

const selectedStyle = {
  color: 'primary.main',
  borderBottom: '3px solid',
  borderColor: 'primary.main',
  // paddingBottom: '4px',
};

const AccountPanel: FC<AccountPanelProps> = (props: AccountPanelProps) => {
  const { node, toggleOpen, open, migrationId, nodeData, referencedUsers } = props;
  const { confidence_score, id, label } = node.data;

  const confidenceScoreColor = getConfidenceScoreColor(confidence_score);

  const tabs = useMemo(() => {
    switch (nodeData?.label) {
      case NodeTypes.Account:
        return Object.values(AccountPanelMenuViews);
      case NodeTypes.Contact:
        return Object.values(ContactPanelMenuViews);
      case NodeTypes.Lead:
        return Object.values(LeadPanelMenuViews);
      case NodeTypes.Opportunity:
        return Object.values(OpportunityPanelMenuViews);
      default:
        return [];
    }
  }, [nodeData]);

  const {
    data: {
      AccountPanelView: { menu: menuOptionSelected },
    },
    setSelectedAccountPanelView,
  } = useGraphRender();

  const getMenuView = useCallback(() => {
    if (!nodeData) {
      return (
        <LoaderContainer>
          <CircularProgress sx={{ textAlign: 'center' }} />
        </LoaderContainer>
      );
    }
    if (!Object.values(AccountPanelMenuViews).includes(menuOptionSelected as any)) {
      setSelectedAccountPanelView(AccountPanelMenuViews.Details as any);
      return null;
    }
    /* if (menuOptionSelected === AccountPanelMenuViews.Data) {
      return <DataMenuView node={nodeData} referencedUsers={referencedUsers} />;
    } else */
    if (menuOptionSelected === AccountPanelMenuViews.Details) {
      return <DetailMenuView node={nodeData} referencedUsers={referencedUsers} />;
    } else if (menuOptionSelected === AccountPanelMenuViews.Action) {
      return <ActionMenuView node={nodeData} referencedUsers={referencedUsers}></ActionMenuView>;
    } /* else if (menuOptionSelected === AccountPanelMenuViews.DataAnomaly) {
      return <DataAnomaly node={nodeData} referencedUsers={referencedUsers} migrationId={migrationId}></DataAnomaly>;
    }  */ else if ((menuOptionSelected as any) === AccountPanelMenuViews.Activities) {
      return <Activity migrationId={migrationId} node={nodeData} />;
    } else if ((menuOptionSelected as any) === AccountPanelMenuViews.Insights) {
      if (nodeData.label === 'Account') return <AccountInsightsTab migrationId={migrationId} node={nodeData} />;
      if (nodeData.label === 'Contact') return <EngagementMenuView migrationId={migrationId} node={nodeData} />;
      return <OpportunityEngagementTab migrationId={migrationId} node={nodeData} />;
    } else {
      return <></>;
    }
  }, [menuOptionSelected, migrationId, nodeData, referencedUsers, setSelectedAccountPanelView]);

  const getMenuViewBarSelector = useCallback(() => {
    return tabs.map((key) => {
      return (
        <Grid
          item
          key={`menu-option-${key}`}
          sx={{
            color: 'neutral.n400',
            ':hover': { ...selectedStyle, cursor: 'pointer' },
            ...(menuOptionSelected === key && { ...selectedStyle }),
          }}
        >
          <Box
            onClick={() => setSelectedAccountPanelView(key as any)}
            sx={{
              paddingBottom: '8px',
            }}
          >
            <Typography variant="p14">{key}</Typography>
          </Box>
        </Grid>
      );
    });
  }, [menuOptionSelected, setSelectedAccountPanelView, tabs]);

  const getFooter = useCallback(() => {
    if ((menuOptionSelected as any) === AccountPanelMenuViews.Activities) {
      return <ActivityFooter />;
    } else {
      return <Footer />;
    }
  }, [menuOptionSelected]);

  return (
    <>
      {open ? (
        <Box
          position={'absolute'}
          sx={{
            width: '30vw',
            minWidth: '425px',
            maxWidth: '800px',
            height: '95%',
            right: 1,
            top: 1,
            marginRight: 3,
            marginTop: 2,
            background: 'white',
            paddingX: 2,
            paddingTop: 2,
            paddingBottom: 1,
            borderRight: '2px solid',
            borderLeft: '4px solid',
            borderRightColor: 'neutral.white',
            borderLeftColor: confidenceScoreColor,
            borderTopRightRadius: '3px',
            borderBottomRightRadius: '3px',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
          }}
          zIndex={2}
        >
          <Box position={'absolute'} onClick={() => toggleOpen(false)} sx={{ left: -38, cursor: 'pointer' }}>
            <CloseRowIcon />
          </Box>
          <Grid container display={'flex'} flexDirection={'column'} height={'100%'}>
            <Grid item flex={0}>
              <Header node={node} confidence_score={confidence_score} toggleOpen={toggleOpen} />
            </Grid>

            <Grid item sx={{ marginTop: 2 }} flex={0}>
              <Grid container display={'flex'} gap={2} sx={{ borderBottom: '1px solid', borderColor: 'neutral.n300' }}>
                {getMenuViewBarSelector()}
              </Grid>
            </Grid>

            <Grid item flex={1} width={'100%'} height={'1px'} sx={{ overflowY: 'scroll' }}>
              <Grid container display={'flex'} flexDirection={'column'}>
                {getMenuView()}
              </Grid>
            </Grid>
            <Grid container display={'flex'} maxHeight={'8%'} alignItems={'center'} sx={{ padding: '0.5rem' }}>
              {getFooter()}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <HiddenNodePanelView
          confidenceScoreColor={confidenceScoreColor}
          toggleOpen={toggleOpen}
          node={node}
          confidence_score={confidence_score}
        />
      )}
    </>
  );
};

export default AccountPanel;
