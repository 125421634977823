import { Grid } from '@mui/material';
import { CustomSelect } from 'components/CustomSelect';
import { FC } from 'react';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { LogicalOperator } from 'store/dataRaptorRule/dto/front-end-rule.dto';

type LogicalOperatorFormRowProps = {
  logicalOperatorRecord: LogicalOperator;
  targetPath: string;
};

const LogicalOperatorFormRow: FC<LogicalOperatorFormRowProps> = (props) => {
  const { logicalOperatorRecord, targetPath } = props;
  const { setFieldValue } = useRuleForm();
  const conditionComponent = logicalOperatorRecord as LogicalOperator;
  return (
    <Grid container>
      <Grid container display="flex" justifyContent="flex-start" width={'80px'} sx={{ my: '1rem', marginLeft: 1 }}>
        <CustomSelect<string>
          id={`${targetPath}.logicalOperator`}
          name={`${targetPath}.logicalOperator`}
          variant="outlined"
          placeholder="Operator"
          value={conditionComponent.value}
          options={[
            { value: 'AND', label: 'AND' },
            { value: 'OR', label: 'OR' },
          ]}
          sx={{
            backgroundColor: 'primary.subtone2',
            width: '100%',
            height: '100%',
            color: 'primary.dark',
            'svg path': {
              fill: '#1554FF',
              stroke: '#1554FF',
            },
            '& .MuiSelect-select': {
              py: 0,
            },
            p: 0,
          }}
          onSelect={async (value) => {
            setFieldValue({ path: `${targetPath}.value`, value: value });
          }}
        />
      </Grid>
    </Grid>
  );
};

export { LogicalOperatorFormRow };
