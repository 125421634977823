import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { eachMonthOfInterval, format, add } from 'date-fns';
import { chartOptions } from './utils';
import CustomTooltip from './components/CustomTooltip';
import PeriodSelector from './components/PeriodSelector';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend);

interface IProps {
  data: {
    date: string | Date;
    data: {
      opened: number;
      notOpened: number;
      received: number;
    };
  }[];
}
const EmailsChart = ({ data = [] }: IProps) => {
  const [tooltipData, setTooltipData] = React.useState<any>(null);

  const categoryPercentage = 0.6;
  const barPercentage = 0.6;

  const chartData: ChartData<'bar' | 'line'> = React.useMemo(
    () => ({
      labels: data.map((entry) => entry.date),

      datasets: [
        {
          type: 'bar',
          label: 'Opened sent emails',
          data: data.map((entry) => entry.data.opened),
          backgroundColor: '#60C67C',
          barPercentage,
          categoryPercentage,
        },
        {
          type: 'bar',
          label: 'Not opened sent emails',
          data: data.map((entry) => entry.data.notOpened),
          backgroundColor: '#ff9960',
          categoryPercentage,
          barPercentage,
        },

        {
          type: 'bar',
          label: 'Received emails',
          data: data.map((entry) => -entry.data.received),
          backgroundColor: '#5990E4',
          barPercentage,
          categoryPercentage,
          borderRadius: {
            topLeft: 3,
            topRight: 3,
          },
        },
        {
          type: 'line',
          label: 'Click through rate',
          data: data.map((entry) =>
            entry.data.opened + entry.data.notOpened === 0
              ? 0
              : entry.data.opened / (entry.data.opened + entry.data.notOpened),
          ),
          backgroundColor: '#CB6CFD',
          borderColor: '#CB6CFD',
          borderWidth: 2,
          pointRadius: 0,
          tension: 0.5,
        },
      ],
    }),
    [data],
  );

  const renderTooltip = (position: { x: number; y: number }, data: any, hide = false) => {
    if (hide) {
      setTooltipData(null);
      return;
    }
    setTooltipData({
      position,
      data,
    });
  };

  return (
    <Box p={1} bgcolor={'#F6F8FB'}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="labelMedium12" color="neutral.n400">
          Account engagement
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
          <PeriodSelector />
          <ExpandMoreIcon sx={{ fontSize: '12px' }} />
        </Stack>
      </Stack>
      <Box sx={{ height: '120px', width: '100%', my: 1, position: 'relative' }}>
        {
          // @ts-ignore
          <Chart type="bar" options={chartOptions(renderTooltip)} data={chartData} />
        }
        {tooltipData && <CustomTooltip position={tooltipData.position} data={tooltipData.data} />}
      </Box>
    </Box>
  );
};

export default EmailsChart;
