import { useState } from 'react';
import { Box, Divider, IconButton, Menu, MenuItem } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import ReplyIcon from '@mui/icons-material/Reply';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const options = [
  { name: 'Add new data source', icon: AddIcon },
  { name: 'Merge policies', icon: BorderColorIcon },
  { name: 'User roles and permissions', icon: ReplyIcon },
  { name: 'Archive graph space', icon: InsertDriveFileIcon },
];

interface WorkspaceSettingProps {
  onArchiveToggle: () => void;
}

export function WorkspaceSetting({ onArchiveToggle }: WorkspaceSettingProps) {
  const [selectedOrderByIndex, setSelectedByIndex] = useState(0);
  const [anchorElOrderBy, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedByIndex(index);
    setAnchorEl(null);

    if (index === options.length - 1) {
      onArchiveToggle();
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        aria-haspopup="true"
        onClick={handleClick}
        aria-expanded={anchorElOrderBy ? 'true' : undefined}
        aria-controls={anchorElOrderBy ? 'basic-menu' : undefined}
        sx={{
          color: 'gray',
          fontSize: '14px',
          '&:hover': {
            color: 'black',
            boxShadow: 'none',
            backgroundColor: 'initial',
          },
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="sort-menu"
        elevation={3}
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        onClose={handleMenuClose}
        anchorEl={anchorElOrderBy}
        open={Boolean(anchorElOrderBy)}
      >
        {options.map((option, index) => (
          <div key={option.name}>
            {index === options.length - 1 && <Divider variant="middle" sx={{ my: 1 }} />}
            <MenuItem
              sx={{ marginBottom: '5px' }}
              selected={index === selectedOrderByIndex}
              onClick={(event) => handleChange(event, index)}
            >
              <option.icon sx={{ marginRight: '12px', color: 'gray' }} />
              {option.name}
            </MenuItem>
          </div>
        ))}
      </Menu>
    </Box>
  );
}
