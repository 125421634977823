import { Grid, Popper, Typography } from '@mui/material';
import { SecondaryButton } from 'components/ui';
import { ReactComponent as FilterIcon } from 'assets/icons/filterBlue.svg';
import theme from 'core/theme';
import { FC, useState } from 'react';
import { SortOptionsList } from './components/SortOptionList';

const ItemSorter: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'rule-library-rule-sorter-popper' : undefined;

  return (
    <>
      <SecondaryButton sx={{ border: 'none' }} variant="outlined" onClick={handleClick} startIcon={<FilterIcon />}>
        <Typography variant="labelMedium14" sx={{ color: 'neutral.main' }}>
          Sort
        </Typography>
      </SecondaryButton>
      <Popper id={id} open={open} anchorEl={anchorEl} sx={{ maxHeight: '70vh' }}>
        <Grid
          container
          display={'flex'}
          flexDirection={'column'}
          sx={{
            p: '12px',
            mt: 1,
            backgroundColor: theme.palette.neutral.white,
            width: 'fit-content',
            borderRadius: '4px',
          }}
          gap={0.5}
        >
          <Grid item>
            <SortOptionsList />
          </Grid>
        </Grid>
      </Popper>
    </>
  );
};

export default ItemSorter;
