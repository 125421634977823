import { getRuleStatus } from 'core/utils';
import { DataRaptorRule, RuleTemplate } from 'store/dataRaptorRule/types';
import { SORT_OPTIONS } from './types';

export const getDepartmentOptions = (payload: (RuleTemplate | DataRaptorRule)[]) => {
  const departmentFilterOptionsMap: any = {};
  const departmentFilterOptions: any = [];
  const departmentAllowedValues: string[] = [];
  payload.forEach((rule) => {
    if (rule.DepartmentObject) {
      const departmentObject = rule.DepartmentObject;
      if (!departmentFilterOptionsMap[departmentObject.label]) {
        const payload = {
          label: departmentObject.label,
          value: departmentObject.ruleDepartmentId,
        };
        departmentFilterOptionsMap[departmentObject.label] = true;
        departmentFilterOptions.push(payload);
        departmentAllowedValues.push(departmentObject.ruleDepartmentId);
      }
    }
  });
  return { departmentFilterOptions, departmentAllowedValues };
};

export const getStatusOptions = (payload: DataRaptorRule[]) => {
  const statusFilterOptionsMap: any = {};
  const statusFilterOptions: any = [];
  const statusAllowedValues: string[] = [];
  payload.forEach((rule) => {
    const status = getRuleStatus(rule.status, rule.active);
    if (!statusFilterOptionsMap[status]) {
      const payload = {
        label: status,
        value: status,
      };
      statusFilterOptionsMap[status] = true;
      statusFilterOptions.push(payload);
      statusAllowedValues.push(status);
    }
  });
  return { statusFilterOptions, statusAllowedValues };
};

export const getTableOptions = (payload: (RuleTemplate | DataRaptorRule)[]) => {
  const tableFilterOptionsMap: any = {};
  const tableFilterOptions: any = [];
  const tableAllowedValues: string[] = [];
  payload.forEach((rule) => {
    const table = rule.table;
    if (!tableFilterOptionsMap[table]) {
      const payload = {
        label: table,
        value: table,
      };
      tableFilterOptionsMap[table] = true;
      tableFilterOptions.push(payload);
      tableAllowedValues.push(table);
    }
  });
  return { tableFilterOptions, tableAllowedValues };
};

export const getTypeOptions = (payload: DataRaptorRule[]) => {
  const typeFilterOptionsMap: any = {};
  const typeFilterOptions: any = [];
  const typeAllowedValues: string[] = [];
  payload.forEach((rule) => {
    const type = rule.type;
    if (!typeFilterOptionsMap[type]) {
      const payload = {
        label: type.replace(/-/g, ' '),
        value: type,
      };
      typeFilterOptionsMap[type] = true;
      typeFilterOptions.push(payload);
      typeAllowedValues.push(type);
    }
  });
  return { typeFilterOptions: typeFilterOptions, typeAllowedValues: typeAllowedValues };
};

export const getSortedArray = (array: (DataRaptorRule | RuleTemplate)[], sortStrategy: string) => {
  if (!SORT_OPTIONS[sortStrategy]) return array;
  const { field, asc } = SORT_OPTIONS[sortStrategy];
  const arrayClone = [...array];
  return arrayClone.sort((a: any, b: any) => {
    console.log(a[field], b[field]);
    if (a[field] < b[field]) return asc ? -1 : 1;
    if (a[field] > b[field]) return asc ? 1 : -1;
    return 0;
  });
};
