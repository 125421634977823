import { Grid, Snackbar, Typography } from '@mui/material';
import { ReactComponent as SeparatorIcon } from 'assets/icons/separator.svg';
import { ReactComponent as WarningRoundIcon } from 'assets/icons/warningRound.svg';
import Slide, { SlideProps } from '@mui/material/Slide';
import { FC, useCallback } from 'react';
import theme from 'core/theme';
import { useGraphRender } from 'store/graphRender/hooks';
import { GraphRenderView } from 'store/graphRender/types';

type TransitionProps = Omit<SlideProps, 'direction'>;

export interface DuplicationSnackBarProps {
  open: boolean;
  onClose: () => void;
}

function TransitionDown(props: TransitionProps) {
  return <Slide {...props} direction="down" />;
}

export const DuplicationSnackBar: FC<DuplicationSnackBarProps> = (props) => {
  const { open, onClose } = props;
  const { setGraphView } = useGraphRender();

  const handleSelectViewDuplicates = useCallback(() => {
    setGraphView(GraphRenderView.DUPLICATES);
    onClose();
  }, [onClose, setGraphView]);

  return (
    <Snackbar
      sx={{ position: 'relative' }}
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={TransitionDown}
      onClose={onClose}
    >
      <Grid
        container
        display={'flex'}
        alignItems={'center'}
        gap={2}
        justifyContent={'space-between'}
        px={2}
        height={36}
        sx={{ backgroundColor: theme.palette.blue.dark, borderRadius: '4px' }}
      >
        <WarningRoundIcon />
        <Typography sx={{ color: theme.palette.neutral.white }}>Duplicate Records identified</Typography>
        <SeparatorIcon stroke="#EDF0F5" />
        <Typography
          onClick={handleSelectViewDuplicates}
          sx={{ color: theme.palette.neutral.white, ':hover': { textDecoration: 'underline', cursor: 'pointer' } }}
        >
          View all
        </Typography>
      </Grid>
    </Snackbar>
  );
};
