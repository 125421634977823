import { Box, Grid, Typography } from '@mui/material';
import theme from 'core/theme';
import { FC } from 'react';

export interface TabSelectorProps {
  tabs: string[];
  selectedTab: string;
  onChange: (tab: string) => void;
}

const activeTabStyle = {
  color: theme.palette.neutral.white,
  backgroundColor: theme.palette.primary.main,
};

const inactiveTabStyle = {
  color: theme.palette.primary.main,
};

export const TabSelector: FC<TabSelectorProps> = (props) => {
  const { onChange, selectedTab, tabs } = props;

  const getTab = (tabName: string) => {
    return (
      <Grid
        key={`graph-filter-tab-selector-${tabName}`}
        flex={1}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        height={'32px'}
        item
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
            width: 'calc(100% - 7px)',
            height: 'calc(100% - 7px)',
            ':hover': {
              cursor: 'pointer',
            },
            ...(selectedTab === tabName ? activeTabStyle : inactiveTabStyle),
          }}
          onClick={() => onChange(tabName)}
        >
          <Typography variant="p14" sx={{ textTransform: 'capitalize', userSelect: 'none' }}>
            {tabName.toLocaleLowerCase()}
          </Typography>
        </Box>
      </Grid>
    );
  };

  return (
    <Grid
      container
      display={'flex'}
      flexDirection={'row'}
      sx={{ backgroundColor: theme.palette.primary.subtone2, borderRadius: '4px' }}
    >
      {tabs.map((tab) => getTab(tab))}
    </Grid>
  );
};
