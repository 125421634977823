import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { FC } from 'react';
import { ANOMALY_VALIDATION_OPTIONS } from '../shared/Constants';

export interface ValidationTypeDropdownProps {
  currentValue: string;
  targetPath: string;
  onChange: (value: string) => void;
}

export const ValidationTypeDropdown: FC<ValidationTypeDropdownProps> = (props: ValidationTypeDropdownProps) => {
  const { currentValue, targetPath, onChange } = props;
  return (
    <ControlledCustomDropdown<string>
      id={`${targetPath}.value`}
      label="Validation Type"
      placeholder="value"
      value={currentValue || ''}
      options={ANOMALY_VALIDATION_OPTIONS || []}
      sx={{ backgroundColor: 'neutral.white' }}
      fallbackOption={{ label: 'Select Value', value: '' }}
      onSelect={onChange}
    />
  );
};
