import { shallowEqual, useSelector } from 'react-redux';

import { useActionCreator } from 'hooks';
import { RootState } from '../types';
import { TemporalTableFormReturnHook } from './types';
import { setMenuIndex, setOpen, setTableOptions, setTable, setAlias } from './actions';

export const useTemporalTableForm = (): TemporalTableFormReturnHook => {
  const tenantState = useSelector((state: RootState) => state.temporalTableForm, shallowEqual);

  return {
    ...tenantState,
    setMenuIndex: useActionCreator(setMenuIndex),
    setOpen: useActionCreator(setOpen),
    setTableOptions: useActionCreator(setTableOptions),
    setTable: useActionCreator(setTable),
    setAlias: useActionCreator(setAlias),
  };
};
