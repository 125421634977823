import { CustomDropdown } from 'components/CustomDropdown';
import { FC, useCallback, useMemo } from 'react';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { ConditionalValue, FieldReference, SubQueryValue } from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { ConditionType } from 'pages/RuleLibrary/types';
import { FUNCTION_ENUM, LOOK_UP_FUNCTION_OPTIONS, CONDITIONAL_VALUE_OPTIONS } from '../Constants';
import { Option } from 'store/ruleForm/types';
import { FunctionValueForm } from './components/FunctionValueForm';
import { PrimitiveValueForm } from './components/PrimitiveValueForm';
import { FieldReferenceValueForm } from './components/FieldReferenceValueForm';
import { LookUpValueForm } from './components/LookUpValueForm/LookUpValueForm';
import { SubQueryValueForm } from './components/SubQueryValueForm';

interface ConditionalValueProps {
  targetPath: string;
  rootConditionalPath: string;
  previousValueComponent?: ConditionalValue;
  previousOperator?: string | null;
  conditionalValueComponent: ConditionalValue;
  conditionType: ConditionType;
  index: number;
  allowedValueTypes: RuleComponentType[];
  allowedValueFunctions: FUNCTION_ENUM[];
  tableName: string;
  fieldOptions: Option[];
  xmlFilterType?: string[];
}

export const ConditionalValueInput: FC<ConditionalValueProps> = (props) => {
  const {
    setFieldValue,
    getFieldOptionsByMigrationAndTable,
    handleOnSelectLookUpField,
    fieldForeignRefOptions,
    selectedMigration,
  } = useRuleForm();
  const {
    targetPath,
    conditionalValueComponent,
    conditionType,
    index,
    allowedValueTypes,
    allowedValueFunctions,
    fieldOptions,
    xmlFilterType,
    previousValueComponent,
    previousOperator,
    tableName,
  } = props;

  const shouldRenderPickListComponent = useMemo(() => {
    if (
      previousValueComponent?.type !== RuleComponentType.FIELD_REFERENCE ||
      conditionalValueComponent.type !== RuleComponentType.PRIMITIVE_VALUE ||
      !['=', '!='].includes(previousOperator || '')
    ) {
      return false;
    }
    const fieldReferenceSelected = (previousValueComponent as FieldReference).value;
    const fieldOption = fieldOptions.find((option) => option.value === fieldReferenceSelected);
    if (!fieldOption) {
      return false;
    }
    return fieldOption.type === 'picklist';
  }, [conditionalValueComponent.type, fieldOptions, previousOperator, previousValueComponent]);

  const conditionalFunctionOptionsFiltered = useMemo(() => {
    let values = LOOK_UP_FUNCTION_OPTIONS;
    if (allowedValueFunctions.length !== 0) {
      values = LOOK_UP_FUNCTION_OPTIONS.filter((option) =>
        allowedValueFunctions.includes(option.value as FUNCTION_ENUM),
      );
    }
    if (xmlFilterType && xmlFilterType.length > 0) {
      values = values?.filter((values) => {
        return values?.resultType.some((type) => xmlFilterType?.includes(type));
      });
    }
    return values;
  }, [allowedValueFunctions, xmlFilterType]);

  const conditionalValuesOptionsFiltered = useMemo(() => {
    let values = CONDITIONAL_VALUE_OPTIONS;
    if (allowedValueTypes.length !== 0) {
      values = CONDITIONAL_VALUE_OPTIONS.filter((option) => allowedValueTypes.includes(option.value));
    }
    if (!conditionalFunctionOptionsFiltered || conditionalFunctionOptionsFiltered.length === 0) {
      values = values.filter((option) => option.value != RuleComponentType.FUNCTION_VALUE);
    }

    return values;
  }, [allowedValueTypes, conditionalFunctionOptionsFiltered]);

  const getConditionTypeDropdown = useCallback(() => {
    return (
      <CustomDropdown
        value={conditionalValueComponent.type}
        options={conditionalValuesOptionsFiltered || []}
        placeholder={'Value type'}
        sx={{ backgroundColor: 'neutral.white' }}
        id={`value-type-${targetPath}`}
        onSelect={function (value: RuleComponentType): void {
          if (value === RuleComponentType.PRIMITIVE_VALUE) {
            const initialPrimitiveValue = { type: RuleComponentType.PRIMITIVE_VALUE, value: '' };
            setFieldValue({ path: targetPath, value: initialPrimitiveValue });
          }
          if (value === RuleComponentType.FUNCTION_VALUE) {
            const initialFunctionValue = {
              type: RuleComponentType.FUNCTION_VALUE,
              function: null,
              numberOfParams: LOOK_UP_FUNCTION_OPTIONS[0].paramNumber,
              value: [],
            };
            setFieldValue({ path: targetPath, value: initialFunctionValue });
          }
          if (value === RuleComponentType.FIELD_REFERENCE) {
            const initialFieldReference: FieldReference = {
              type: RuleComponentType.FIELD_REFERENCE,
              value: '',
            };
            setFieldValue({ path: targetPath, value: initialFieldReference });
          }
          if (value === RuleComponentType.SUB_QUERY_VALUE) {
            const initialSubQueryValue: SubQueryValue = {
              type: RuleComponentType.SUB_QUERY_VALUE,
              subQueryId: '',
              value: '',
            };
            setFieldValue({ path: targetPath, value: initialSubQueryValue });
          }
        }}
      />
    );
  }, [conditionalValueComponent.type, conditionalValuesOptionsFiltered, setFieldValue, targetPath]);

  if (conditionalValueComponent.type === RuleComponentType.FUNCTION_VALUE) {
    return (
      <FunctionValueForm
        targetPath={targetPath}
        index={index}
        conditionType={conditionType}
        conditionalValueComponent={conditionalValueComponent}
        fieldOptions={fieldOptions}
        fieldForeignRefOptions={fieldForeignRefOptions || []}
        setFieldValue={setFieldValue}
        getConditionTypeDropdown={getConditionTypeDropdown}
        conditionalFunctionOptionsFiltered={conditionalFunctionOptionsFiltered}
        handleOnSelectLookUpField={handleOnSelectLookUpField}
      />
    );
  }

  if (conditionalValueComponent.type === RuleComponentType.PRIMITIVE_VALUE) {
    return (
      <PrimitiveValueForm
        targetPath={targetPath}
        conditionalValueComponent={conditionalValueComponent}
        setFieldValue={setFieldValue}
        getConditionTypeDropdown={getConditionTypeDropdown}
        xmlFilterType={xmlFilterType}
        shouldRenderPickListComponent={shouldRenderPickListComponent}
        selectedMigration={selectedMigration}
        previousValueComponent={previousValueComponent}
        tableName={tableName}
      />
    );
  }
  if (conditionalValueComponent.type === RuleComponentType.FIELD_REFERENCE) {
    return (
      <FieldReferenceValueForm
        targetPath={targetPath}
        index={index}
        conditionalValueComponent={conditionalValueComponent}
        conditionType={conditionType}
        fieldOptions={fieldOptions}
        fieldForeignRefOptions={fieldForeignRefOptions || []}
        xmlFilterType={xmlFilterType}
        getConditionTypeDropdown={getConditionTypeDropdown}
        handleOnSelectLookUpField={handleOnSelectLookUpField}
      />
    );
  }
  if (conditionalValueComponent.type === RuleComponentType.LOOKUP_VALUE) {
    return (
      <LookUpValueForm
        targetPath={targetPath}
        index={index}
        conditionType={conditionType}
        conditionalValueComponent={conditionalValueComponent}
        getConditionTypeDropdown={getConditionTypeDropdown}
      />
    );
  }
  if (conditionalValueComponent.type === RuleComponentType.SUB_QUERY_VALUE) {
    return (
      <SubQueryValueForm
        targetPath={targetPath}
        conditionalValueComponent={conditionalValueComponent}
        setFieldValue={setFieldValue}
        selectedMigration={selectedMigration}
        getConditionTypeDropdown={getConditionTypeDropdown}
        getFieldOptionsByMigrationAndTable={getFieldOptionsByMigrationAndTable}
      />
    );
  }
  return <></>;
};
