import { useState } from 'react';

interface FieldData {
  title: string;
  icon: JSX.Element;
  tableData: Array<{ mapped: boolean; objectField: string; schemaField: string; fieldGroup: string }>;
}

export const useFieldMapping = (initialData: FieldData[]) => {
  const [fieldData, setFieldData] = useState(initialData);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [backupData, setBackupData] = useState<{ [key: string]: any[] }>({});
  const [removeUnmapped, setRemoveUnmapped] = useState<{ [key: string]: boolean }>({});

  const handleExpandClick = (title: string) => {
    setExpanded(expanded === title ? false : title);
  };

  const handleCreateNewField = (objectField: string, currentFieldTitle: string) => {
    setFieldData((prevState) =>
      prevState.map((field) =>
        field.title === currentFieldTitle
          ? {
              ...field,
              tableData: [
                ...field.tableData,
                { mapped: false, objectField, schemaField: 'Choose or create field', fieldGroup: '' },
              ],
            }
          : field,
      ),
    );
  };

  const handleDeleteField = (fieldTitle: string, objectField: string) => {
    setFieldData((prevState) =>
      prevState.map((field) =>
        field.title === fieldTitle
          ? {
              ...field,
              tableData: field.tableData.filter((data) => data.objectField !== objectField),
            }
          : field,
      ),
    );
  };

  const handleRemoveUnmapped = (fieldTitle: string) => {
    setRemoveUnmapped((prevState) => ({
      ...prevState,
      [fieldTitle]: !prevState[fieldTitle],
    }));

    setFieldData((prevState) =>
      prevState.map((field) => {
        if (field.title === fieldTitle) {
          if (!removeUnmapped[fieldTitle]) {
            setBackupData((prevBackup) => ({
              ...prevBackup,
              [fieldTitle]: field.tableData.filter((data) => !data.mapped),
            }));
            return {
              ...field,
              tableData: field.tableData.filter((data) => data.mapped),
            };
          } else {
            return {
              ...field,
              tableData: [...field.tableData, ...(backupData[fieldTitle] || [])],
            };
          }
        }
        return field;
      }),
    );
  };

  const handleUpdateFieldMapping = (title: string, objectField: string, schemaField: string, fieldGroup: string) => {
    setFieldData((prev) =>
      prev.map((field: any) =>
        field.title === title
          ? {
              ...field,
              tableData: field.tableData.map((data: any) =>
                data.objectField === objectField ? { ...data, schemaField, fieldGroup, mapped: true } : data,
              ),
            }
          : field,
      ),
    );
  };

  return {
    fieldData,
    expanded,
    removeUnmapped,
    handleExpandClick,
    handleCreateNewField,
    handleDeleteField,
    handleRemoveUnmapped,
    handleUpdateFieldMapping,
  };
};
