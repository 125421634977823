import theme from 'core/theme';
import { useMemo } from 'react';
import { getStraightPath, BaseEdge, EdgeProps, useReactFlow } from 'reactflow';
import { useGraphRender } from 'store/graphRender/hooks';

export default function DuplicateEdge(props: EdgeProps) {
  const { source, target, id } = props;
  const reactFlow = useReactFlow();
  const {
    data: { renderDuplicateEdgesOnHover, hoverNodeId, duplicatedNodeIds },
  } = useGraphRender();

  const sourceNode = reactFlow.getNode(source);
  const targetNode = reactFlow.getNode(target);

  const shouldRenderEdge = useMemo(() => {
    if (hoverNodeId != null && duplicatedNodeIds.includes(hoverNodeId) && !renderDuplicateEdgesOnHover.includes(id)) {
      return false;
    }
    if (sourceNode && sourceNode.hidden === false && targetNode && targetNode.hidden === false) {
      return true;
    }
    return false;
  }, [duplicatedNodeIds, hoverNodeId, id, renderDuplicateEdgesOnHover, sourceNode, targetNode]);

  if (!sourceNode || !targetNode) {
    return null;
  }
  const sourceNodePosition = sourceNode.position;
  const targetNodePosition = targetNode.position;

  const { width: sourceWidth, height: sourceHeight } = sourceNode;
  const { width: targetWidth, height: targetHeight } = targetNode;

  const sourceCenterNodePosition = [
    sourceNodePosition.x + (sourceWidth || 0) / 2,
    sourceNodePosition.y + (sourceHeight || 0) / 2,
  ];
  const targetCenterNodePosition = [
    targetNodePosition.x + (targetWidth || 0) / 2,
    targetNodePosition.y + (targetHeight || 0) / 2,
  ];

  const [edgePath] = getStraightPath({
    sourceX: sourceCenterNodePosition[0],
    sourceY: sourceCenterNodePosition[1],
    targetX: targetCenterNodePosition[0],
    targetY: targetCenterNodePosition[1],
  });

  return (
    <>
      {shouldRenderEdge ? (
        <BaseEdge
          path={edgePath}
          style={{
            strokeWidth: 2,
            fill: theme.palette.orange.main,
            stroke: theme.palette.orange.main,
            strokeDasharray: '3,3',
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}
