/* eslint-disable react/no-string-refs */
import theme from 'core/theme';
import React, { useState } from 'react';

import {
  Theme,
  SxProps,
  TextField,
  InputLabel,
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from '@mui/material';

const defaultLabelSx: SxProps<Theme> = {
  fontWeight: 'bold',
  paddingBottom: 0.5,
  color: theme.palette.neutral.main,
};

type AutocompleteSelectProps<Option> = {
  placeholder?: string;
  label?: React.ReactNode;
  renderInput?: (params: AutocompleteRenderInputParams, selectedValue?: Option | null) => React.ReactNode;
} & Omit<AutocompleteProps<Option, false, false, false>, 'renderInput'>;

export default function AutocompleteSelect<Option>({
  ref,
  label,
  onChange,
  placeholder,
  renderInput,
  ...autocompleteProps
}: AutocompleteSelectProps<Option>) {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  return (
    <>
      {typeof label !== 'string' ? (
        label
      ) : (
        <InputLabel size="small" sx={defaultLabelSx}>
          {label}
        </InputLabel>
      )}
      <Autocomplete
        autoHighlight
        {...autocompleteProps}
        onChange={(e, value, reason, details) => {
          onChange?.(e, value, reason, details);
          setSelectedOption(value);
        }}
        renderInput={(params: AutocompleteRenderInputParams) => {
          return (
            renderInput?.(params, selectedOption) ?? (
              <TextField hiddenLabel inputRef={ref} placeholder={placeholder} {...params} />
            )
          );
        }}
      />
    </>
  );
}
/* eslint-enable react/no-string-refs */
