import { Box, Typography } from '@mui/material';
import { useDashboard } from 'store/dashboard/hook';
import { DataQualityColors } from 'store/dashboard/types';

export type Legend = {
  name: string;
  color: string;
};

export const LegendRender = () => {
  const {
    data: {
      selectedTable,
      dataQualityTimeline: { additionalTables },
    },
  } = useDashboard();

  const tables = [selectedTable, ...additionalTables];

  const legends: Legend[] = tables.map((table, index) => {
    return { name: table, color: DataQualityColors[index] };
  });

  return (
    <Box display="flex" alignItems="flex-start" flexWrap={'wrap'}>
      {legends.map((legend, index) => (
        <Box key={index} marginRight={3} display="flex" alignItems="center" gap={1.5}>
          <div
            style={{
              width: '8px',
              height: '8px',
              borderRadius: '50%',
              backgroundColor: legend.color,
            }}
          />
          <Typography fontSize={'12px'} fontWeight={400}>
            {legend.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
