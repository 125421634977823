import React from 'react';
import { Grid, Box, Typography, CircularProgress, Stack, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import EngagamentHistoryChart from '../OpportunityInsightsTab/components/EngagementHistoryChart';
import useAccountInsightsData from './hooks/useAccountInsightsData';

interface IProps {
  migrationId: string;
  node: any;
}

const AccountInsightsTab = ({ migrationId, node }: IProps) => {
  const { isLoading, engagementHistoryChartData, getEngagementHistoryTooltipInfos } = useAccountInsightsData({
    migrationId,
    node,
  });
  return (
    <Grid display={'flex'} direction={'column'} gap={2} p={1}>
      <Stack sx={{ height: '100%' }} gap={2}>
        {isLoading ? (
          <Box display={'flex'} alignItems={'center'} height={'50px'} gap={1}>
            <CircularProgress size={'16px'} color="primary" />
            <Typography>Loading Engagement...</Typography>
          </Box>
        ) : (
          <>
            <Stack sx={{ p: 1, backgroundColor: 'orange.light' }} gap={1}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" my={1}>
                <Stack direction="row" alignItems="center" gap={0.5}>
                  <Typography variant="p12">SENTIMENT ANALYSIS</Typography>
                  <Tooltip
                    arrow
                    title="Our system calculated the probability of the deal closing successfully. 
The probability is based on the number of conversions, divided by the number of total interactions that can be tracked to a conversion during the same time period."
                  >
                    <ErrorIcon sx={{ color: 'neutral.n400' }} />
                  </Tooltip>
                </Stack>
                <Typography variant="h6">😊</Typography>
              </Stack>

              <Stack direction="row" alignItems="center" gap={0.5}>
                <WbIncandescentIcon sx={{ transform: 'rotate(180deg)', fontSize: '16px', color: 'neutral.n400' }} />
                <Typography variant="caption" color="neutral.n400">
                  Next best action: Lorem ipsum
                </Typography>
              </Stack>
            </Stack>

            <EngagamentHistoryChart
              data={engagementHistoryChartData}
              getTooltipInfos={getEngagementHistoryTooltipInfos}
            />
          </>
        )}
      </Stack>
    </Grid>
  );
};

export default AccountInsightsTab;
