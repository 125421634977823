import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import Resources from './Resources';
import TouchPoints from './TouchPoints';

export default function LeftPanel() {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Touchpoints" value="1" />
            <Tab label="Resources" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <TouchPoints />
        </TabPanel>
        <TabPanel value="2">
          <Resources />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
