import { styled, Box, Button } from '@mui/material';

export const Container = styled(Box)(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

export const PrimaryButton = styled(Button)(({ theme }) => ({
  ':hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  ':active': {
    backgroundColor: theme.palette.primary.main,
  },
  ':focused': {
    borderColor: theme.palette.primary.dark,
  },
  '& .MuiButton-startIcon': {
    marginRight: 8,
    '& svg, & svg path': {
      fill: theme.palette.neutral.white,
    },
  },
}));

PrimaryButton.defaultProps = { variant: 'contained' };
