import { createAction } from '@reduxjs/toolkit';
import { TableOption } from './types';

const SET_OPEN = 'temporalTableForm/SET_OPEN';
const SET_MENU_INDEX = 'temporalTableForm/SET_MENU_INDEX';
const SET_TABLE_OPTIONS = 'temporalTableForm/SET_TABLE_OPTIONS';
const SET_TABLE = 'temporalTableForm/SET_TABLE';
const SET_ALIAS = 'temporalTableForm/SET_ALIAS';

export const setOpen = createAction<boolean>(SET_OPEN);
export const setMenuIndex = createAction<number>(SET_MENU_INDEX);
export const setTableOptions = createAction<TableOption[]>(SET_TABLE_OPTIONS);
export const setTable = createAction<string>(SET_TABLE);
export const setAlias = createAction<string>(SET_ALIAS);
