import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { IconButton, Link, Tooltip, Typography, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { ConfidenceScoreBreakdownTable } from './components/ConfidenceScoreBrakdownTable/ConfidenceScoreBreakdownTable';
import { useDashboard } from 'store/dashboard/hook';

type DataQualityTableProps = {
  closeView: () => void;
};

interface DataQualityTableItem {
  title: JSX.Element;
  table: JSX.Element;
}

export const DataQualityTable: FC<DataQualityTableProps> = ({ closeView }) => {
  const [tables, setTables] = useState<DataQualityTableItem[]>([]);
  const {
    data: {
      dataQualityTimeline: { selectedPoint },
    },
  } = useDashboard();

  const prevRefSelectedPointTable = useRef<string>();

  const pushTable = (title: JSX.Element, table: JSX.Element) => {
    setTables((prevTables) => [...prevTables, { title, table }]);
  };

  const popTable = () => {
    setTables((prevTables) => prevTables.slice(0, -1));
  };

  const defaultTable: DataQualityTableItem = useMemo(
    () => ({
      title: (
        <>
          confidence score breakdown
          <span style={{ fontSize: 14, paddingLeft: 5 }}>record view</span>
        </>
      ),
      table: <ConfidenceScoreBreakdownTable pushTable={pushTable} />,
    }),
    [],
  );

  const currentTable = tables[tables.length - 1];

  useEffect(() => {
    if (selectedPoint && prevRefSelectedPointTable.current !== selectedPoint.serieId) {
      setTables([defaultTable]);
      prevRefSelectedPointTable.current = selectedPoint.serieId as string;
    }
  }, [defaultTable, selectedPoint]);

  useEffect(() => {
    setTables((prevTables) => {
      const updatedTables = [...prevTables];
      updatedTables[0] = {
        ...updatedTables[0],
        table: <ConfidenceScoreBreakdownTable pushTable={pushTable} />,
      };
      return updatedTables;
    });
  }, []);

  return (
    <Box height="100%">
      <Box display="flex" alignItems="center" justifyContent="space-between" textTransform="capitalize">
        <Typography variant="h2" fontSize={18} display="flex" alignItems="center">
          {tables.length > 1 && (
            <Tooltip title="back">
              <IconButton onClick={popTable}>
                <ArrowBackIcon fontSize="small" sx={{ mr: 3 }} htmlColor="#898EA1" />
              </IconButton>
            </Tooltip>
          )}
          {currentTable?.title}
        </Typography>
        <Link fontSize={14} underline="none" onClick={closeView} sx={{ cursor: 'pointer' }}>
          close view
        </Link>
      </Box>
      <Box height="95%" width="100%" paddingTop={2}>
        {currentTable?.table}
      </Box>
    </Box>
  );
};
