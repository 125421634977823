import { Box, Modal, Stack, Typography, IconButton, Divider, TextField, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PrimaryButton } from 'components/ui';
import { useUser } from 'store/user/hooks';
import { ReactComponent as UserAvatarIcon } from 'assets/icons/userAvatar.svg';

interface IProps {
  isModalOpen: boolean;
  closeModal: () => void;
}
const ShareModal = ({ isModalOpen, closeModal }: IProps) => {
  const { user } = useUser();

  return (
    <Modal open={isModalOpen} onClose={() => closeModal()}>
      <Box
        sx={{
          width: '50%',
          height: '60%',
          backgroundColor: 'white',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          px: 4,
          py: 1,
        }}
      >
        <Stack spacing={2} sx={{ position: 'relative' }}>
          <IconButton sx={{ position: 'absolute', top: '10px', right: '0px' }} onClick={closeModal}>
            <CloseIcon sx={{ fill: 'neutral.main' }} />
          </IconButton>
          <Stack spacing={1}>
            <Typography variant="h2" sx={{ color: 'neutral.main', marginRight: 2 }}>
              Share Space
            </Typography>
            <Typography sx={{ color: 'neutral.n400' }} variant="body2">
              Welcome to your new graph space! Invite team members to start the collaboration.
            </Typography>
          </Stack>
          <Divider />

          <Stack spacing={'3px'}>
            <Typography variant="body2">Invite by email</Typography>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
              <TextField
                placeholder="Enter Email"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={{ height: '30px', px: '15px', color: 'primary.main' }}>
                      <Typography variant="p14">Can view</Typography>
                      <KeyboardArrowDownIcon sx={{ ml: 1 }} />
                    </InputAdornment>
                  ),
                }}
              />

              <PrimaryButton disabled sx={{ minWidth: '150px' }}>
                Send Invite
              </PrimaryButton>
            </Stack>
          </Stack>

          <Stack spacing={'3px'}>
            <Typography variant="body2">Copy link</Typography>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
              <Box sx={{ width: '100%', position: 'relative' }}>
                <TextField
                  disabled
                  fullWidth
                  value={'qbsSrR4GY3wZQkd3Pu'}
                  sx={{
                    '& .MuiInputBase-root': {
                      paddingRight: '0px',
                    },
                    '& .MuiInputAdornment-root': {
                      marginLeft: '0px',
                    },
                  }}
                />
                <PrimaryButton
                  sx={{
                    height: '25px',
                    position: 'absolute',
                    right: 3,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 10,
                  }}
                >
                  <LinkIcon sx={{ fontSize: '12px', mr: 1 }} />
                  Copy Link
                </PrimaryButton>
              </Box>
              <Stack
                direction={'row'}
                alignItems="center"
                justifyContent={'end'}
                spacing={1}
                sx={{ color: 'primary.main', minWidth: '150px' }}
              >
                <Typography variant="p14">Can view</Typography>
                <KeyboardArrowDownIcon />
              </Stack>
            </Stack>
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
            <Typography variant="body2">Public View</Typography>
            <Stack
              direction={'row'}
              alignItems="center"
              justifyContent={'end'}
              spacing={1}
              sx={{ color: 'primary.main', minWidth: '150px' }}
            >
              <Typography variant="p14">No Access</Typography>
              <KeyboardArrowDownIcon />
            </Stack>
          </Stack>
          <Divider />

          <Stack>
            <Typography variant="body2" color="neutral.n400">
              Graph Space Members
            </Typography>

            <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} sx={{ p: 1 }}>
              <Stack direction="row" alignItems={'center'} spacing={1}>
                <Box sx={{ width: '35px' }}>
                  <UserAvatarIcon />
                </Box>
                <Typography variant="body1"> {user?.userName} </Typography>
                <Typography variant="body2"> {'(you)'} </Typography>
                <Typography variant="body2" color="neutral.n400">
                  {user?.userEmail}{' '}
                </Typography>
              </Stack>
              <Typography variant="body2"> Owner </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ShareModal;
