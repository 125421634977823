import { Box, CircularProgress, SxProps, Theme } from '@mui/material';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { FC, useEffect, useMemo, useState } from 'react';
import { useMigration } from 'store/migration/hooks';

export interface MigrationFieldValuesDropDownProps {
  label: string;
  id: string;
  labelSx?: SxProps<Theme>;
  value: string;
  migrationId: string;
  table: string;
  fieldName: string;
  placeholder: string;
  onChange: (value: string) => void;
}

export const MigrationFieldValuesDropDown: FC<MigrationFieldValuesDropDownProps> = (props) => {
  const { fieldName, label, migrationId, onChange, table, value, labelSx, placeholder, id } = props;

  const {
    getMigrationFieldValueOptions,
    data: { migrationTableFieldValueOptions },
  } = useMigration();
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fallBackOption = useMemo(() => ({ label: placeholder || 'Select a Value', value: '' }), [placeholder]);

  useEffect(() => {
    const key = `${migrationId}-${table}-${fieldName}`;
    if (migrationTableFieldValueOptions[key]?.data !== undefined) {
      const values: string[] = migrationTableFieldValueOptions[key].data;
      const options = values.filter((x) => x).map((value) => ({ label: value, value }));
      setOptions(options || []);
      setLoading(false);
    } else {
      getMigrationFieldValueOptions({
        migrationId,
        tableId: table,
        fieldName,
        onSuccess: (res) => {
          const options = res.filter((x) => x).map((value) => ({ label: value, value }));
          setOptions(options || []);
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
        },
      });
    }
  }, []);

  return (
    <>
      {loading === true ? (
        <Box display={'flex'} justifyContent={'center'}>
          <CircularProgress></CircularProgress>
        </Box>
      ) : (
        <ControlledCustomDropdown<string>
          label={label}
          labelSx={labelSx}
          id={id}
          value={value || ''}
          onSelect={onChange}
          listBoxProps={{ maxHeight: 200 }}
          options={options || []}
          fallbackOption={fallBackOption}
          placeholder={placeholder}
        />
      )}
    </>
  );
};
