import { FC, MouseEvent, useState } from 'react';
import { Box, Menu, Button, MenuItem } from '@mui/material';

import { useRuleLibrary } from 'store/ruleLibrary/hook';
import { useRuleFormStore } from 'store/ruleForm/hook';

import { RuleTemporalTable } from 'store/dataRaptorRule/types';
import { useTemporalTableForm } from 'store/temporalTableForm/hooks';

import { useTemporalTableModalHook } from '../../TemporalTableModal/TemporalTableModal.hook';
import { useSnackbar } from 'notistack';

export interface TableMenuOptionProps {
  task: RuleTemporalTable;
}

export const TableMenuButton: FC<TableMenuOptionProps> = ({ task: ruleTemporalTable }) => {
  const { setOpen } = useTemporalTableForm();
  const { setEditRuleTemporal } = useRuleFormStore();
  const { deleteRuleTemporaltable } = useRuleLibrary();
  const snackBack = useSnackbar();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { handleNameChange, handleUpdateTable } = useTemporalTableModalHook();

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
    setEditRuleTemporal(null);
  };

  const handleDeleteRuleTemporalTable = () => {
    setEditRuleTemporal(null);
    const { dataMigrationId, ruleTempTableId } = ruleTemporalTable;

    if (confirm('Are you sure?')) {
      deleteRuleTemporaltable({
        tableId: ruleTempTableId,
        migrationId: dataMigrationId,
        onError: (error) => snackBack.enqueueSnackbar(error.message, { variant: 'error' }),
        onSuccess: () => snackBack.enqueueSnackbar('Table deleted successfully', { variant: 'success' }),
      });
    }
  };

  const handleEditRuleTemporaltable = () => {
    setOpen(true);
    setEditRuleTemporal(ruleTemporalTable);
    handleUpdateTable(ruleTemporalTable.table);
    handleNameChange({ target: { value: ruleTemporalTable.name } });
  };

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Button variant="text" size="small" onClick={handleClick}>
        ...
      </Button>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleEditRuleTemporaltable}>Edit</MenuItem>
        <MenuItem onClick={handleDeleteRuleTemporalTable}>Delete</MenuItem>
      </Menu>
    </Box>
  );
};

export default TableMenuButton;
