import { Box, Button } from '@mui/material';
import { SecondaryButton } from 'components/ui';
import { useSnackbar } from 'notistack';
import { useRuleFormSubmissionHook } from 'pages/RuleLibrary/hooks/RuleFormSubmissionHook';
import { FC, useCallback } from 'react';
import { useRuleFormStore } from 'store/ruleForm/hook';
import { SubQuery } from 'store/ruleForm/types';
import { useRuleLibrary } from 'store/ruleLibrary/hook';

export interface RuleFormAdvFilterFooterProps {
  handleClose: () => void;
  handleNext: () => void;
}

export const RuleFormAdvFilterFooter: FC<RuleFormAdvFilterFooterProps> = (props) => {
  const { handleClose, handleNext } = props;
  const { setFormErrorObject, setFormError, subQueries } = useRuleFormStore();
  const {
    subPages: {
      temporalTable: {
        data: { tables },
      },
    },
  } = useRuleLibrary();
  const { _validateSubQueries, validateConditionalsRepeated, getWhereHash } = useRuleFormSubmissionHook();
  const { enqueueSnackbar } = useSnackbar();

  const showErrorSnackBar = useCallback(() => {
    enqueueSnackbar('Please review the errors encountered', { variant: 'error' });
  }, [enqueueSnackbar]);

  const handleSubmit = useCallback(() => {
    setFormErrorObject({});
    let valid = _validateSubQueries();
    if (!valid) {
      return showErrorSnackBar();
    }

    subQueries.forEach((subQuery, index) => {
      const currentValid = validateConditionalsRepeated(subQuery.where, `subQueries[${index}].where`);
      if (!currentValid) {
        valid = false;
      }
    });

    if (!valid) {
      return showErrorSnackBar();
    }

    const backendQueries: SubQuery[] = tables.map((table) => {
      return {
        table: table.table,
        alias: table.name,
        where: table.definition.where,
      };
    });

    valid = true;
    //Validate Duplicate Names
    for (let i = 0; i < subQueries.length; i++) {
      for (let j = 0; j < backendQueries.length; j++) {
        if (subQueries[i].alias.trim() === backendQueries[j].alias.trim()) {
          setFormError({
            path: `subQueries[${i}].alias`,
            value: 'Alias name already exists in the system. Please use a different name.',
          });
          valid = false;
          return;
        }
      }
    }

    if (!valid) {
      return showErrorSnackBar();
    }

    const globalQueries = [...subQueries, ...backendQueries];

    const whereHashArr = globalQueries.map((query) => getWhereHash(query.where, query.alias));
    console.log('whereHashArr', whereHashArr);
    valid = true;
    for (let i = 0; i < subQueries.length; i++) {
      for (let j = subQueries.length; j < whereHashArr.length; j++) {
        if (whereHashArr[i].trim() === whereHashArr[j].trim()) {
          setFormError({
            path: `subQueries[${i}].error`,
            value: `We detected that a similar dataset already exists "${globalQueries[j].alias}" You can clear this selection and use the existing one while adding conditions.`,
          });
          valid = false;
          return;
        }
      }
    }

    if (!valid) {
      return showErrorSnackBar();
    }

    handleNext();
  }, [
    setFormErrorObject,
    _validateSubQueries,
    subQueries,
    tables,
    handleNext,
    showErrorSnackBar,
    validateConditionalsRepeated,
    getWhereHash,
    setFormError,
  ]);

  return (
    <Box
      display={'flex'}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <SecondaryButton variant="text" sx={{ color: 'black', ':hover': { color: 'black' } }} onClick={handleClose}>
        Cancel
      </SecondaryButton>
      <Button variant="contained" onClick={handleSubmit}>
        Next
      </Button>
    </Box>
  );
};
