import { FC, useCallback } from 'react';
import { Grid } from '@mui/material';
import { LogicalOperatorFormRow } from './LogicalOperatorFormRow';
import { AddLogicalOperatorButton } from './inputFields/AddLogicalOperatorButton';
import { ConditionType } from 'pages/RuleForm/types';
import { Option } from 'store/ruleForm/types';
import {
  FieldReference,
  RootCondition,
  LogicalOperator,
  RuleWhereArray,
  RegexValue,
  ServiceValue,
} from 'store/dataRaptorRule/dto/front-end-rule.dto';
import { RuleComponent, RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { AnomalyConditionalRecordForm } from './AnomalyConditionalRecordForm';

interface AnomalyWhereListProps {
  wherePath: string;
  where: RuleWhereArray;
  setWhere: (payload: { value: RuleWhereArray }) => {
    payload: {
      value: RuleWhereArray;
    };
    type: string;
  };
  fieldOptions: Option[];
  tableName: string;
}

export const AnomalyWhereList: FC<AnomalyWhereListProps> = (props) => {
  const { wherePath, where, setWhere, fieldOptions, tableName } = props;

  const determineFieldOption = useCallback(
    (index: number) => {
      if (index > 0) {
        const initialField = (where[0] as RootCondition).field as RegexValue | ServiceValue;
        if (where[0].type === RuleComponentType.ROOT_CONDITIONAL && initialField.fieldName) {
          const options = [fieldOptions.find((option) => option.value === initialField.fieldName)];
          if (options[0]) {
            return options as Option[];
          }
          return fieldOptions;
        }
      }
      return fieldOptions;
    },
    [fieldOptions, where],
  );

  return (
    <>
      {fieldOptions &&
        where?.map((whereValue: RootCondition | LogicalOperator | RuleComponent, index) => {
          if (whereValue.type === RuleComponentType.ROOT_CONDITIONAL) {
            const conditionComponent = whereValue as RootCondition;
            let field;

            if (conditionComponent.field.type === RuleComponentType.FIELD_REFERENCE) {
              field = (conditionComponent.field as FieldReference).value || fieldOptions[0].value;
            }

            const filteredOptions = determineFieldOption(index);

            return (
              <Grid container display="flex" alignItems="center" key={`rule-form-record-${index}`}>
                <>
                  <AnomalyConditionalRecordForm
                    index={index}
                    field={field}
                    conditionComponent={conditionComponent}
                    where={where}
                    setWhere={setWhere}
                    conditionPath={`${wherePath}[${index}]`}
                    fieldOptions={filteredOptions}
                    tableName={tableName}
                  />
                </>
                <AddLogicalOperatorButton
                  index={index}
                  where={where}
                  type={ConditionType.WHERE}
                  wherePath={wherePath}
                />
              </Grid>
            );
          } else if (whereValue.type === RuleComponentType.LOGICAL_OPERATOR) {
            return (
              <>
                <LogicalOperatorFormRow
                  key={`logical-operator-form-row-where-${index}`}
                  logicalOperatorRecord={whereValue as LogicalOperator}
                  targetPath={`${wherePath}[${index}]`}
                />
              </>
            );
          }
        })}
    </>
  );
};
