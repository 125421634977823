import { Box, FormHelperText, Typography } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'components/ui';
import { openAuthWindow } from 'pages/IntegrationRedirect/popup';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { APPLICATION_STATUS, mapStatusLabel } from 'store/integration-status/types';
import { useIntegration } from 'store/integration/hooks';
import { Profile } from './ui';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import { useLazyGetIntegrationQuery } from 'store/integration/api';

const multipleEnvironmentIntegrations = ['salesforce', 'salesforce-user'];

interface Props {
  integrationId: string;
  applicationIcon: string;
  applicationName: string;
  applicationDescription: string;
  initialStatus: string;
}

const PropertyHead: FC<Props> = ({ applicationIcon, applicationName, applicationDescription, initialStatus }) => {
  const { id: appId } = useParams();
  const [integrationLoading, setIntegrationLoading] = useState<boolean>(false);
  const { authorizeRedirectUrl, authorize, uninstall, error, setError } = useIntegration();

  const isMultipleEnvironmentIntegration = useMemo(() => {
    return multipleEnvironmentIntegrations.includes(appId?.toLowerCase() || '');
  }, [appId]);

  const [getIntegration, integrationQuery] = useLazyGetIntegrationQuery();

  const integration = integrationQuery.data;

  console.log({ integration });

  const { applicationStatus } = integration || {};
  const statusLabel = mapStatusLabel(applicationStatus || '', integrationLoading);

  useEffect(() => {
    if (appId) {
      getIntegration(appId);
    }
  }, [appId, getIntegration]);

  const snackbar = useSnackbar();

  useEffect(() => {
    if (!appId) return;
    const bc = new BroadcastChannel('integration-events');
    bc.onmessage = (event) => {
      if (event.data.type === 'error' && event.data.data) {
        setError(event.data.data);
        snackbar.enqueueSnackbar(event.data.data, { variant: 'error' });
        setIntegrationLoading(false);
      }
      if (event.data.type === 'success') {
        snackbar.enqueueSnackbar('Integration completed successfully', { variant: 'success' });
        getIntegration(appId);
        setIntegrationLoading(false);
      }
    };
    return () => {
      bc.close();
    };
  }, [appId, getIntegration, initialStatus, setError, snackbar]);

  const installOrUninstall = useCallback(
    (bodyParams?: { env?: string }) => {
      if (!appId) return;
      if (applicationStatus === APPLICATION_STATUS.NOT_INSTALLED) {
        setIntegrationLoading(true);
        authorize({
          id: appId,
          body: bodyParams,
          onError: () => {
            enqueueSnackbar('Failed to install integration', { variant: 'error' });
            setIntegrationLoading(false);
          },
        });
      } else if (applicationStatus === APPLICATION_STATUS.INSTALLED) {
        setIntegrationLoading(true);
        uninstall({
          id: appId,
          onSuccess: () => {
            enqueueSnackbar('Integration uninstalled successfully', { variant: 'success' });
            getIntegration(appId);
            setIntegrationLoading(false);
          },
          onError: () => {
            enqueueSnackbar('Failed to uninstall integration', { variant: 'error' });
            setIntegrationLoading(false);
          },
        });
      } else {
        return;
      }
    },
    [appId, applicationStatus, authorize, getIntegration, uninstall],
  );

  useEffect(() => {
    if (applicationStatus === APPLICATION_STATUS.NOT_INSTALLED && authorizeRedirectUrl) {
      openAuthWindow(authorizeRedirectUrl, 'authpopup');
    }
  }, [applicationStatus, authorizeRedirectUrl]);

  const isInstalled = applicationStatus === APPLICATION_STATUS.INSTALLED;
  return (
    <Box>
      <Profile>
        <Box className="application-icon">{applicationIcon && <img width="72" src={applicationIcon}></img>}</Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="h2" sx={{ mr: 'auto', mb: '10px', weight: 600, lineHeight: '24px' }}>
            {applicationName}
          </Typography>
          <Typography component="p" variant="labelRegular12" sx={{ color: 'neutral.n400' }}>
            {applicationDescription}
          </Typography>
        </Box>
      </Profile>
      <Box sx={{ display: 'flex', gap: 2, marginLeft: '24px' }}>
        <SecondaryButton sx={{ width: '168px' }}>View Setup Guide</SecondaryButton>
        {isMultipleEnvironmentIntegration && !isInstalled ? (
          <>
            <PrimaryButton
              disabled={integrationLoading}
              variant={isInstalled ? 'outlined' : 'contained'}
              sx={{ width: '168px' }}
              onClick={() => installOrUninstall({ env: 'test' })}
            >
              {statusLabel} (SandBox)
            </PrimaryButton>
            <PrimaryButton
              disabled={integrationLoading}
              variant={isInstalled ? 'outlined' : 'contained'}
              sx={{ width: '168px' }}
              onClick={() => installOrUninstall({ env: 'prod' })}
            >
              {statusLabel} (Production)
            </PrimaryButton>
          </>
        ) : (
          <PrimaryButton
            disabled={integrationLoading}
            variant={isInstalled ? 'outlined' : 'contained'}
            sx={{ width: '168px' }}
            onClick={() => installOrUninstall()}
          >
            {statusLabel}
          </PrimaryButton>
        )}
      </Box>
      {!!error && (
        <FormHelperText sx={{ color: 'red.main', textAlign: 'center', marginTop: 2 }}>
          {typeof error === 'string' ? error : 'Something went wrong!'}
        </FormHelperText>
      )}
    </Box>
  );
};
export default PropertyHead;
