import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { useEffect, useMemo } from 'react';
import { useDataRaptor } from 'store/dataRaptor/hooks';
import { RuleCategory } from 'store/dataRaptorRule/types';
import { useMigration } from 'store/migration/hooks';

export const useTemporalTableModalEffect = () => {
  const { subQueryForm, setCategory } = useRuleForm();
  const table = useMemo(() => subQueryForm.table, [subQueryForm.table]);

  const {
    data: { migrationId },
    getMigrationTableFieldsAndLookUpsById,
    getMigrationTableForeignReferencesById,
    getMigrationTablesById,
    getMigrationTableFieldsById,
  } = useMigration();

  const { setSelectedMigration } = useDataRaptor();

  useEffect(() => {
    setCategory(RuleCategory.DataValidation.toString());
  }, [setCategory]);

  useEffect(() => {
    if (migrationId) {
      getMigrationTablesById(migrationId);
      setSelectedMigration(migrationId);
    }
  }, [getMigrationTablesById, migrationId, setSelectedMigration]);

  useEffect(() => {
    if (migrationId && table) {
      getMigrationTableFieldsAndLookUpsById({ migrationId, tableId: table });
      getMigrationTableForeignReferencesById({ migrationId, tableId: table });
      getMigrationTableFieldsById({ migrationId, tableId: table });
    }
  }, [
    getMigrationTableFieldsAndLookUpsById,
    getMigrationTableFieldsById,
    getMigrationTableForeignReferencesById,
    migrationId,
    table,
  ]);
};
