import { useSnackbar } from 'notistack';
import { FC, useCallback, useMemo, useState } from 'react';

import { Box, Button } from '@mui/material';

import { useMigration } from 'store/migration/hooks';
import { useRuleFormStore } from 'store/ruleForm/hook';
import { useRuleLibrary } from 'store/ruleLibrary/hook';

import { SecondaryButton } from 'components/ui';
import { useRuleFormSubmissionHook } from 'pages/RuleLibrary/hooks/RuleFormSubmissionHook';

export interface TableConditionFooterProps {
  handleClose: () => void;
}

export const TableConditionFooter: FC<TableConditionFooterProps> = ({ handleClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const { temporalTable } = useRuleFormStore();
  const {
    data: { migrationId },
  } = useMigration();

  const { handleCreateOrUpdateTemporalTable } = useRuleFormSubmissionHook();
  const { setSelectedSubPage, getAllTemporaryTableByMigrationId } = useRuleLibrary();

  const isEditing = !!temporalTable;

  const buttonText = useMemo(() => {
    let buttonText: string;

    if (isEditing) {
      buttonText = loading ? 'Editing' : 'Edit';
    } else {
      buttonText = loading ? 'Creating' : 'Create';
    }

    return buttonText + ' Temp Table';
  }, [isEditing, loading]);

  const handleTemporaryTableCreation = useCallback(() => {
    setLoading(true);
    handleCreateOrUpdateTemporalTable(temporalTable ?? undefined)
      .then(() => {
        setSelectedSubPage('temporalTable');
        enqueueSnackbar(`Temporary Table ${isEditing ? 'Updated' : 'Created'}`, {
          variant: 'success',
        });

        getAllTemporaryTableByMigrationId({ migrationId });
        handleClose();
      })
      .catch((err) => enqueueSnackbar(`Error: ${err.message}`, { variant: 'error' }))
      .finally(() => setLoading(false));
  }, [handleCreateOrUpdateTemporalTable]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <SecondaryButton variant="text" sx={{ color: 'black', ':hover': { color: 'black' } }} onClick={handleClose}>
        Cancel
      </SecondaryButton>
      <Button variant="contained" disabled={loading} onClick={handleTemporaryTableCreation}>
        {buttonText}
      </Button>
    </Box>
  );
};
