import { useState } from 'react';
import { Box } from '@mui/material';
import SchemaCard from './SchemaCard';
import SchemaTable from './SchemaTable';

const UserDefinedOptions = () => {
  const [expandedProfile, setExpandedProfile] = useState(false);
  const [expandedEvent, setExpandedEvent] = useState(false);

  const [profileSchema, setProfileSchema] = useState([
    {
      fieldCatergory: 'Personal Information',
      dataSource: '',
      fieldExamples: 'contactId, firstName, lastName, title, department, role, LinkedInProfile',
    },
    {
      fieldCatergory: 'Work Contact Details',
      dataSource: '',
      fieldExamples: 'workAddress, workEmail, workPhone, organizations',
    },
    {
      fieldCatergory: 'Demographic Details',
      dataSource: '',
      fieldExamples: 'birthDate, birthDayAndMonth, birthYear, gender, martialStatus, nationality',
    },
    {
      fieldCatergory: 'Personal Contact Details',
      dataSource: '',
      fieldExamples: 'faxPhone, homeAddress, homePhone, mobilePhone, personalEmail',
    },
    { fieldCatergory: 'Consent & Preferences', dataSource: '', fieldExamples: 'consents (various subfields)' },
    {
      fieldCatergory: 'Business Person Details',
      dataSource: '',
      fieldExamples: 'b2b.accountKey, b2b.personKey, b2b.accountID, b2b.isBlocked',
    },
  ]);

  const [eventSchema, setEventSchema] = useState([
    {
      fieldCatergory: 'Event Basic Information',
      dataSource: '',
      fieldExamples: 'eventId, eventName, eventDate, eventLocation',
    },
    {
      fieldCatergory: 'Attendee Details',
      dataSource: '',
      fieldExamples: 'attendeeId, attendeeName, attendeeEmail, attendeePhone, attendeeOrganization',
    },
    {
      fieldCatergory: 'Event Logistics',
      dataSource: '',
      fieldExamples: 'logisticsId, venue, catering, transportation, eventSchedule',
    },
    {
      fieldCatergory: 'Event Sponsors',
      dataSource: '',
      fieldExamples: 'sponsorId, sponsorName, sponsorContribution, sponsorLevel',
    },
    {
      fieldCatergory: 'Event Feedback',
      dataSource: '',
      fieldExamples: 'feedbackId, feedbackScore, feedbackComments, improvementSuggestions',
    },
  ]);

  const dataSources = [
    { icon: 'https://cdn-icons-png.flaticon.com/128/5968/5968914.png', name: 'Salesforce' },
    { icon: 'https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png', name: 'LinkedIn' },
    { icon: 'https://cdn.iconscout.com/icon/free/png-256/marketo-282193.png', name: 'Marketo' },
    { icon: 'https://cdn.iconscout.com/icon/free/png-256/hobspot-3521495.png', name: 'Hotspot' },
    { icon: 'https://cdn-icons-png.flaticon.com/512/732/732220.png', name: 'Google Analytics' },
    { icon: 'https://cdn.iconscout.com/icon/free/png-256/mailchimp-282193.png', name: 'Mailchimp' },
  ];

  return (
    <Box sx={{ mt: 2, display: 'flex', gap: 2, flexDirection: 'column', justifyContent: 'center' }}>
      <SchemaCard
        title="PROFILE SCHEMA"
        isExpanded={expandedProfile}
        onToggleExpand={() => setExpandedProfile(!expandedProfile)}
      >
        <SchemaTable schema={profileSchema} setSchema={setProfileSchema} dataSources={dataSources} />
      </SchemaCard>

      <SchemaCard
        title="EVENT SCHEMA"
        isExpanded={expandedEvent}
        onToggleExpand={() => setExpandedEvent(!expandedEvent)}
      >
        <SchemaTable schema={eventSchema} setSchema={setEventSchema} dataSources={dataSources} />
      </SchemaCard>
    </Box>
  );
};

export default UserDefinedOptions;
