import React from 'react';
import CallDetailsContainer from './CallDetailsTab';
import EmailDetailsContainer from './EmailDetailsTab';
import TaskEventDetailsContainer from './TaskEventDetailsTab';
import NoteDetailsContainer from './NoteDetailsTab';

interface IProps {
  type: 'call' | 'meeting' | 'event' | 'email' | 'task' | 'note';
  nodeData: any;
  migrationId: string;
}
const DetailsTab = ({ type, nodeData, migrationId }: IProps) => {
  if (type === 'call') return <CallDetailsContainer nodeData={nodeData} />;
  if (type === 'email') return <EmailDetailsContainer nodeData={nodeData} migrationId={migrationId} />;
  if (type === 'event' || type === 'meeting')
    return <TaskEventDetailsContainer type="event" nodeData={nodeData} migrationId={migrationId} />;
  if (type === 'task') return <TaskEventDetailsContainer type="task" nodeData={nodeData} migrationId={migrationId} />;
  if (type === 'note') return <NoteDetailsContainer nodeData={nodeData} />;

  return <></>;
};

export default DetailsTab;
