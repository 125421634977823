import React, { useState } from 'react';
import { Menu, Divider, Box } from '@mui/material';
import SearchableList from './SearchableList';
import CreateCustomField from './CreateCustomField';

interface SelectFieldMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  items: Array<{ groupField: string; fields: Array<{ name: string; type: string }> }>;
  onSelect: (schemaField: string, fieldGroup: string) => void;
}

const SelectFieldMenu: React.FC<SelectFieldMenuProps> = ({ anchorEl, open, onClose, items, onSelect }) => {
  const [fields, setFields] = useState(items);

  const groupFields = fields.map((group) => group.groupField);

  const handleCreateField = (newField: { schemaField: string; type: string; fieldGroup: string }) => {
    const groupIndex = fields.findIndex((group) => group.groupField === newField.fieldGroup);
    if (groupIndex >= 0) {
      const updatedFields = [...fields];
      updatedFields[groupIndex].fields.push({ name: newField.schemaField, type: newField.type });
      setFields(updatedFields);
    } else {
      const updatedFields = [
        ...fields,
        { groupField: newField.fieldGroup, fields: [{ name: newField.schemaField, type: newField.type }] },
      ];
      setFields(updatedFields);
    }

    onSelect(newField.schemaField, newField.fieldGroup);
    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        style: {
          width: '300px',
          maxHeight: '400px',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ overflowY: 'auto', maxHeight: '300px' }}>
        <SearchableList
          items={fields}
          onSelect={(schemaField, fieldGroup) => onSelect(schemaField, fieldGroup)}
          onClose={onClose}
        />
      </Box>

      <Divider />

      <Box sx={{ padding: '4px', position: 'sticky', bottom: 0, backgroundColor: 'white' }}>
        <CreateCustomField groupFields={groupFields} onCreateField={handleCreateField} />
      </Box>
    </Menu>
  );
};

export default SelectFieldMenu;
