import { FC, useCallback, useState } from 'react';
import { CustomFilterWhereList } from './components/CustomFilterWhereList';
import { Box, Button } from '@mui/material';
import { useGraphRender } from 'store/graphRender/hooks';
import { GraphRenderView } from 'store/graphRender/types';

const availableObjectTypes = [
  { label: 'Account', value: 'Account' },
  { label: 'Contact', value: 'Contact' },
  { label: 'Opportunity', value: 'Opportunity' },
  { label: 'Lead', value: 'Lead' },
  { label: 'Activities', value: 'Event' },
];

export const CustomFilterPanel: FC = () => {
  const {
    data: { customFilterTmp, view },
    partialUpdateCustomGraphFilter,
  } = useGraphRender();

  const initialActiveCustomFilters = Object.keys(customFilterTmp).filter(
    (key) => (customFilterTmp as any)?.[key]?.where?.length > 0,
  );
  const [activeCustomFilters, setActiveCustomFilters] = useState<(string | null)[]>(
    initialActiveCustomFilters.length > 0 ? initialActiveCustomFilters : [null],
  );

  const handleObjectTypeValueChanged = useCallback(
    (index: number, value: string): void => {
      let validatedValue: string | null = value;
      if (activeCustomFilters.includes(value)) {
        validatedValue = null;
      }
      const newActiveCustomFilters = [...activeCustomFilters];
      newActiveCustomFilters[index] = validatedValue;
      setActiveCustomFilters(newActiveCustomFilters);
    },
    [activeCustomFilters],
  );

  const handleAddObjectFilter = useCallback(() => {
    if (activeCustomFilters.length >= availableObjectTypes.length) {
      return;
    }
    if (activeCustomFilters[activeCustomFilters.length - 1] === null) {
      return;
    }
    setActiveCustomFilters((prev) => [...prev, null]);
  }, [activeCustomFilters]);

  const handleDeleteObjectFilter = useCallback(
    (index: number): void => {
      const tableName = activeCustomFilters[index];
      const newActiveCustomFilters = [...activeCustomFilters];
      newActiveCustomFilters.splice(index, 1);
      setActiveCustomFilters(newActiveCustomFilters);
      if (tableName) {
        partialUpdateCustomGraphFilter({ path: `${tableName}.where`, value: undefined });
      }
    },
    [activeCustomFilters, partialUpdateCustomGraphFilter],
  );

  const getObjectTypeFilterContainers = useCallback((): JSX.Element[] => {
    return activeCustomFilters.map((item, index) => {
      const calculateAvailableObjectTypes = availableObjectTypes.filter(
        (item) => !activeCustomFilters.includes(item.value),
      );
      if (item) {
        calculateAvailableObjectTypes.push({ label: item, value: item });
      }
      return (
        <CustomFilterWhereList
          key={`map-custom-object-type-selector-${index}`}
          availableObjectTypes={calculateAvailableObjectTypes}
          index={index}
          showCloseButton={index > 0}
          handleValueChange={handleObjectTypeValueChanged}
          objectTypeValue={item}
          handleDelete={handleDeleteObjectFilter}
        />
      );
    });
  }, [activeCustomFilters, handleObjectTypeValueChanged, handleDeleteObjectFilter]);

  return (
    <>
      {view === GraphRenderView.ACCOUNT && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'right' }}>
            <Button onClick={handleAddObjectFilter}>+ Object Filter</Button>
          </Box>
          <Box>{getObjectTypeFilterContainers()}</Box>
        </>
      )}
    </>
  );
};
