import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getDeduplicationResultByIds as getDeduplicationResultByIdsApi,
  getDeduplicationResultByMigration as getDeduplicationResultByMigrationApi,
} from 'http/deduplicationResult';
import { GetDeduplicationResultByIdsArgs, GetDeduplicationResultByMigrationArgs, DeduplicationResult } from './types';

const GET_DEDUPLICATION_RESULT_BY_IDS = 'deduplicationResult/GET_DEDUPLICATION_RESULT_BY_IDS';
const GET_DEDUPLICATION_RESULT_BY_MIGRATION = 'deduplicationResult/GET_DEDUPLICATION_RESULT_BY_MIGRATION';

export const getDeduplicationResultByIds = createAsyncThunk<DeduplicationResult[], GetDeduplicationResultByIdsArgs>(
  GET_DEDUPLICATION_RESULT_BY_IDS,
  async ({ migrationId, data, onError, onSuccess }: GetDeduplicationResultByIdsArgs) => {
    try {
      const res = await getDeduplicationResultByIdsApi(migrationId, data);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);

export const getDeduplicationResultByMigration = createAsyncThunk<
  DeduplicationResult[],
  GetDeduplicationResultByMigrationArgs
>(
  GET_DEDUPLICATION_RESULT_BY_MIGRATION,
  async ({ migrationId, options, onError, onSuccess }: GetDeduplicationResultByMigrationArgs) => {
    try {
      const res = await getDeduplicationResultByMigrationApi(migrationId, options);
      if (onSuccess) onSuccess(res);
      return res;
    } catch (err) {
      if (onError) onError(err);
      throw err;
    }
  },
);
