import { Box, InputAdornment } from '@mui/material';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import _ from 'lodash';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { FormErrorMessage } from 'pages/RuleLibrary/components/ui/FormErrorMessage';
import { FC, useMemo } from 'react';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorExclamation.svg';

export interface ServiceValidatorSelectorOption {
  label: string;
  value: string;
  patternId: string;
  fieldName: string;
}

export interface ServiceValidatorSelector {
  id: string;
  currentValue: string;
  targetPath: string;
  fieldOptions: ServiceValidatorSelectorOption[];
  onChange: (value: string) => void;
}

export const ServiceValidatorSelector: FC<ServiceValidatorSelector> = (props: ServiceValidatorSelector) => {
  const { id, currentValue, fieldOptions, onChange, targetPath } = props;
  const { formError } = useRuleForm();

  const error = useMemo(() => formError && _.get(formError, targetPath), [formError, targetPath]);

  return (
    <Box>
      <ControlledCustomDropdown<string>
        id={id}
        placeholder="Validation"
        label=""
        value={currentValue || ''}
        options={fieldOptions || []}
        sx={{ backgroundColor: 'neutral.white' }}
        fallbackOption={{ label: 'Select a Validation Service', value: '' }}
        onSelect={(value) => onChange(value)}
        InputProps={{
          startAdornment: error && <InputAdornment position="start">{<ErrorIcon />}</InputAdornment>,
        }}
      />
      <FormErrorMessage errorMessage={error} />
    </Box>
  );
};
