import backendApi from 'store/api/backendApi';

import { RuleRisk, RuleType, DataRaptorRule, RuleDepartment } from 'store/dataRaptorRule/types';
import {
  CircleIcon,
  RiskIcon,
  RiskLevel,
} from 'pages/RuleLibrary/components/RuleFormModal/components/RuleFormContent/RuleDetails/RuleFormRuleDetailsIcons';

export type WithIcon<T> = {
  icon?: JSX.Element;
} & T;

export const ruleFormApi = backendApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getRuleTypes: builder.query<WithIcon<RuleType>[], void>({
        query: () => ({
          method: 'get',
          url: '/data-raptor-rule-type',
        }),
        transformResponse(baseQueryReturnValue: WithIcon<RuleType>[]) {
          return baseQueryReturnValue.map((r) => ({
            ...r,
            icon: CircleIcon({ size: 7, color: r.color }),
          }));
        },
      }),
      getRuleRisks: builder.query<WithIcon<RuleRisk>[], void>({
        query: () => ({
          method: 'get',
          url: '/data-raptor-rule-risk',
        }),
        transformResponse(baseQueryReturnValue: WithIcon<RuleRisk>[]) {
          return baseQueryReturnValue.map((r) => {
            const level = r.name.toLowerCase() as RiskLevel;

            return {
              ...r,
              icon: RiskIcon({ level }),
            };
          });
        },
      }),
      getRuleDepartments: builder.query<RuleDepartment[], void>({
        query: () => ({
          method: 'get',
          url: '/data-raptor-rule-department',
        }),
      }),
      getRulesByMigrationAndTableName: builder.query<DataRaptorRule[], { migrationId: string; tableName: string }>({
        query: ({ migrationId, tableName }) => ({
          method: 'get',
          url: `/dataRaptorRule/migration/${migrationId}/table/${tableName}`,
        }),
        transformResponse(baseQueryReturnValue: DataRaptorRule[]) {
          return baseQueryReturnValue.map<DataRaptorRule>(({ violationScore, ...query }) => ({
            ...query,
            violationScore: isNaN(violationScore) ? 0 : Number(violationScore),
          }));
        },
      }),
    };
  },
});

export const {
  usePrefetch,
  useGetRuleTypesQuery,
  useGetRuleRisksQuery,
  useLazyGetRuleRisksQuery,
  useLazyGetRuleTypesQuery,
  useGetRuleDepartmentsQuery,
  useLazyGetRuleDepartmentsQuery,
  useGetRulesByMigrationAndTableNameQuery,
  useLazyGetRulesByMigrationAndTableNameQuery,
} = ruleFormApi;
