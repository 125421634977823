import { Outlet } from 'react-router-dom';

import { Container } from './ui';

export default function LayoutGraphSpace() {
  return (
    <Container>
      <Outlet />
    </Container>
  );
}
