import { FC, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { LookUpSelectorModal } from './components/shared/LookUpSelectorModal/LookUpSelectorModal';
import { HavingList } from './components/HavingList/HavingList';
import { WhereList } from './components/WhereList/WhereList';
import { SubQueryModal } from './components/SubQueryModal/SubQueryModal';

const DataValidation: FC = () => {
  const {
    selectedTable,
    selectedMigration,
    fieldOptions,
    showSubQueryModal,
    toggleSubQueryModal,
    setFieldOptions,
    where,
    setWhere,
    getFieldOptionsByMigrationAndTable,
  } = useRuleForm();

  useEffect(() => {
    const options = getFieldOptionsByMigrationAndTable(selectedMigration, selectedTable);
    if (options.length > 0) {
      setFieldOptions(options);
    }
  }, [getFieldOptionsByMigrationAndTable, selectedMigration, selectedTable, setFieldOptions]);

  return (
    <>
      {fieldOptions && (
        <Box sx={{ width: '100%', marginBottom: '1rem' }}>
          <WhereList
            wherePath={'where'}
            where={where}
            setWhere={setWhere}
            fieldOptions={fieldOptions}
            tableName={selectedTable}
          />
          <HavingList />
          <SubQueryModal open={showSubQueryModal} handleToggleShow={toggleSubQueryModal} />
        </Box>
      )}
    </>
  );
};

export { DataValidation };
